import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function ContactUsModal() {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {/*<Button variant="contained" color="primary" onClick={handleOpen} style={{ marginTop: "1.25em" }}>
                Contact Us
    </Button>*/}
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpen} style={{ margin: "1.0em", marginLeft: "0.5em" }}>
                Contact Us
            </Button>

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
            >
                <div style={modalStyle} className={classes.paper}>
                    <h2>For more information:</h2>
                    <GridContainer>
                        {/*<Row>*/}
                        <GridItem xs={6} md={6}>
                            <strong style={{ fontFamily: 'Franklin Gothic Book', color: 'black' }}>Casey Wells</strong> <br />
                            <a style={{ fontFamily: 'Franklin Gothic Book', color: 'black' }}>Texas Department of Transportation (TxDOT)</a> <br />
                            <a style={{ fontFamily: 'Franklin Gothic Book', color: 'blue' }} href={"mailto:Casey.Wells@txdot.gov"}>Casey.Wells@txdot.gov</a>
                        </GridItem>
                        <GridItem xs={6} md={6}>
                            <strong style={{ fontFamily: 'Franklin Gothic Book', color: 'black' }}>L.D. White</strong> <br />
                            <a style={{ fontFamily: 'Franklin Gothic Book', color: 'black' }}>Texas A&M Transportation Institute (TTI)</a> <br />
                            <a style={{ fontFamily: 'Franklin Gothic Book', color: 'blue' }} href={"mailto:l-white@tti.tamu.edu"}>l-white@tti.tamu.edu</a>
                        </GridItem>
                        {/*</Row>*/}
                    </GridContainer>
                    <Button onClick={handleClose} style={{ marginTop: "1em" }}>Close</Button>

                </div>
            </Modal>
        </div>
    );
}