import React, { useState } from "react";
import fetchDataValue from "utils/fetchDataValue.js"

export default function fetchTruckData(data, areaCode, currYear) {

    //function fetchTruckData(data, areaCode) {
    //console.log("data");
    //console.log(data);

    //let areaData = data.filter((a) => a.di === areaCode);
    // console.log("areaData");
    // console.log(areaData);

    let currValue = fetchDataValue(data, currYear, 'trkdelay')
    //onsole.log("currValue");
    //console.log(currValue);
    let priorValue = fetchDataValue(data, (currYear - 1), 'trkdelay')
    //console.log("priorValue");
    //console.log(priorValue);
    let diffValue = (currValue - priorValue)
    let trend = 'none'
    if (diffValue > 0) {
        trend = 'up'
    } else {
        trend = 'down'
    }
    let tempDataArray = [{ "metric": "Annual Person-Hours of Delay", "value": currValue.toLocaleString(), "diff": diffValue.toLocaleString(), "trend": trend }]

    currValue = fetchDataValue(data, currYear, 'trktti').toFixed(2)
    priorValue = fetchDataValue(data, (currYear - 1), 'trktti').toFixed(2)
    diffValue = (currValue - priorValue).toFixed(2)
    if (diffValue > 0) {
        trend = 'up'
    } else {
        trend = 'down'
    }
    tempDataArray = [...tempDataArray, { "metric": "TTI", "value": currValue, "diff": diffValue, "trend": trend }]

    currValue = fetchDataValue(data, currYear, 'trkpti95').toFixed(2)
    priorValue = fetchDataValue(data, (currYear - 1), 'trkpti95').toFixed(2)
    diffValue = (currValue - priorValue).toFixed(2)
    if (diffValue > 0) {
        trend = 'up'
    } else {
        trend = 'down'
    }
    tempDataArray = [...tempDataArray, { "metric": "PTI - 95th Percentile", "value": currValue, "diff": diffValue, "trend": trend }]

    currValue = fetchDataValue(data, currYear, 'trkpti80').toFixed(2)
    priorValue = fetchDataValue(data, (currYear - 1), 'trkpti80').toFixed(2)
    diffValue = (currValue - priorValue).toFixed(2)
    if (diffValue > 0) {
        trend = 'up'
    } else {
        trend = 'down'
    }
    tempDataArray = [...tempDataArray, { "metric": "PTI - 80th Percentile", "value": currValue, "diff": diffValue, "trend": trend }]

    return (tempDataArray)

};