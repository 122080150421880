import React, { useState } from "react";
import fetchDataValue from "utils/fetchDataValue.js"

export default function fetchAllVehData(data, areaCode, currYear) {


    //function fetchAllVehData(data, areaCode) {
    let currValue = fetchDataValue(data, currYear, 'delay')
    let priorValue = fetchDataValue(data, (currYear - 1), 'delay')
    let diffValue = (currValue - priorValue)
    let trend = 'none'
    if (diffValue > 0) {
        trend = 'up'
    } else {
        trend = 'down'
    }
    let tempDataArray = [{ "metric": "Annual Person-Hours of Delay", "value": currValue.toLocaleString(), "diff": diffValue.toLocaleString(), "trend": trend }]

    currValue = fetchDataValue(data, currYear, 'tti').toFixed(2)
    priorValue = fetchDataValue(data, (currYear - 1), 'tti').toFixed(2)
    diffValue = (currValue - priorValue).toFixed(2)
    if (diffValue > 0) {
        trend = 'up'
    } else {
        trend = 'down'
    }
    tempDataArray = [...tempDataArray, { "metric": "TTI", "value": currValue, "diff": diffValue, "trend": trend }]

    currValue = fetchDataValue(data, currYear, 'pti95').toFixed(2)
    priorValue = fetchDataValue(data, (currYear - 1), 'pti95').toFixed(2)
    diffValue = (currValue - priorValue).toFixed(2)
    if (diffValue > 0) {
        trend = 'up'
    } else {
        trend = 'down'
    }
    tempDataArray = [...tempDataArray, { "metric": "PTI - 95th Percentile", "value": currValue, "diff": diffValue, "trend": trend }]

    currValue = fetchDataValue(data, currYear, 'pti80').toFixed(2)
    priorValue = fetchDataValue(data, (currYear - 1), 'pti80').toFixed(2)
    diffValue = (currValue - priorValue).toFixed(2)
    if (diffValue > 0) {
        trend = 'up'
    } else {
        trend = 'down'
    }
    tempDataArray = [...tempDataArray, { "metric": "PTI - 80th Percentile", "value": currValue, "diff": diffValue, "trend": trend }]

    currValue = fetchDataValue(data, currYear, 'ghg')
    priorValue = fetchDataValue(data, (currYear - 1), 'ghg')
    diffValue = (currValue - priorValue)
    if (diffValue > 0) {
        trend = 'up'
    } else {
        trend = 'down'
    }
    tempDataArray = [...tempDataArray, { "metric": "GHG (lbs)", "value": currValue.toLocaleString(), "diff": diffValue.toLocaleString(), "trend": trend }]

    currValue = fetchDataValue(data, currYear, 'congcost')
    priorValue = fetchDataValue(data, (currYear - 1), 'congcost')
    diffValue = (currValue - priorValue)
    if (diffValue > 0) {
        trend = 'up'
    } else {
        trend = 'down'
    }
    tempDataArray = [...tempDataArray, { "metric": "Congestion Cost ($)", "value": currValue.toLocaleString(), "diff": diffValue.toLocaleString(), "trend": trend }]

    return (tempDataArray)

};