export const congestionDataTrkTop100 = [
  {
    tx100id: 3000004,
    year: 2017,
    rank: 2,
    trank: 1,
    dly: 1.0355829e7,
    tdly: 866129.0,
    pti: 4.72,
    tpti: 5.06,
    tci: 2.72,
    ttci: 2.71,
    co2: 8.5219803e7,
    tco2: 3.2080698e7,
    cost: 2.18128087e8,
    tcost: 4.3773189e7,
    fuel: 4160263.0,
    tfuel: 1447914.0,
    spd: 34.4,
    tspd: 35.5,
    vmt_tx100seg: 1387737.11325,
    trkvmt_tx100seg: 162010.46821,
  },
  {
    tx100id: 1200184,
    year: 2017,
    rank: 1,
    trank: 2,
    dly: 5278451.0,
    tdly: 263803.0,
    pti: 3.75,
    tpti: 4.32,
    tci: 2.48,
    ttci: 2.58,
    co2: 3.6434809e7,
    tco2: 1.0229512e7,
    cost: 1.05317063e8,
    tcost: 1.3371238e7,
    fuel: 1801606.0,
    tfuel: 464079.0,
    spd: 30.6,
    tspd: 30.7,
    vmt_tx100seg: 753377.4211,
    trkvmt_tx100seg: 48213.85345,
  },
  {
    tx100id: 1200140,
    year: 2017,
    rank: 5,
    trank: 3,
    dly: 3169783.0,
    tdly: 181770.0,
    pti: 3.4,
    tpti: 3.9,
    tci: 2.32,
    ttci: 2.51,
    co2: 2.3113685e7,
    tco2: 6989434.0,
    cost: 6.4049499e7,
    tcost: 9211595.0,
    fuel: 1137742.0,
    tfuel: 314705.0,
    spd: 36.5,
    tspd: 35.9,
    vmt_tx100seg: 561773.86022,
    trkvmt_tx100seg: 41523.57287,
  },
  {
    tx100id: 1200139,
    year: 2017,
    rank: 3,
    trank: 4,
    dly: 6957188.0,
    tdly: 324776.0,
    pti: 3.49,
    tpti: 3.98,
    tci: 2.23,
    ttci: 2.28,
    co2: 4.569212e7,
    tco2: 1.1364917e7,
    cost: 1.37929823e8,
    tcost: 1.6354444e7,
    fuel: 2265412.0,
    tfuel: 513067.0,
    spd: 39.5,
    tspd: 40.3,
    vmt_tx100seg: 1429506.89801,
    trkvmt_tx100seg: 90734.61463,
  },
  {
    tx100id: 8000117,
    year: 2017,
    rank: 22,
    trank: 5,
    dly: 1018346.0,
    tdly: 122997.0,
    pti: 3.16,
    tpti: 3.34,
    tci: 1.84,
    ttci: 1.92,
    co2: 9937386.0,
    tco2: 5196237.0,
    cost: 2.2700222e7,
    tcost: 6277356.0,
    fuel: 474973.0,
    tfuel: 232991.0,
    spd: 40.2,
    tspd: 39.4,
    vmt_tx100seg: 366177.08716,
    trkvmt_tx100seg: 58218.31047,
  },
  {
    tx100id: 8000020,
    year: 2017,
    rank: 6,
    trank: 6,
    dly: 4359898.0,
    tdly: 224836.0,
    pti: 2.75,
    tpti: 2.9,
    tci: 1.8,
    ttci: 1.79,
    co2: 3.0933801e7,
    tco2: 8572532.0,
    cost: 8.7280006e7,
    tcost: 1.1386222e7,
    fuel: 1527321.0,
    tfuel: 386038.0,
    spd: 41.4,
    tspd: 41.2,
    vmt_tx100seg: 1169943.32763,
    trkvmt_tx100seg: 84550.62706,
  },
  {
    tx100id: 3000003,
    year: 2017,
    rank: 15,
    trank: 7,
    dly: 2103665.0,
    tdly: 159118.0,
    pti: 3.15,
    tpti: 3.3,
    tci: 1.8,
    ttci: 1.82,
    co2: 1.2314313e7,
    tco2: 5052400.0,
    cost: 4.3331217e7,
    tcost: 7968660.0,
    fuel: 601148.0,
    tfuel: 230500.0,
    spd: 45.6,
    tspd: 45.5,
    vmt_tx100seg: 634652.11753,
    trkvmt_tx100seg: 69230.65887,
  },
  {
    tx100id: 1200169,
    year: 2017,
    rank: 17,
    trank: 8,
    dly: 2794656.0,
    tdly: 213341.0,
    pti: 2.37,
    tpti: 2.42,
    tci: 1.69,
    ttci: 1.7,
    co2: 1.83553e7,
    tco2: 7793144.0,
    cost: 5.7815488e7,
    tcost: 1.0773834e7,
    fuel: 899908.0,
    tfuel: 357560.0,
    spd: 42.1,
    tspd: 41.9,
    vmt_tx100seg: 1202629.49547,
    trkvmt_tx100seg: 126924.11689,
  },
  {
    tx100id: 1200171,
    year: 2017,
    rank: 7,
    trank: 9,
    dly: 2194389.0,
    tdly: 119869.0,
    pti: 2.97,
    tpti: 3.0,
    tci: 1.78,
    ttci: 1.76,
    co2: 1.1556175e7,
    tco2: 3923948.0,
    cost: 4.3733265e7,
    tcost: 6017526.0,
    fuel: 572409.0,
    tfuel: 182289.0,
    spd: 42.4,
    tspd: 43.0,
    vmt_tx100seg: 889052.89751,
    trkvmt_tx100seg: 72399.40522,
  },
  {
    tx100id: 1200143,
    year: 2017,
    rank: 8,
    trank: 10,
    dly: 5216407.0,
    tdly: 271090.0,
    pti: 2.3,
    tpti: 2.42,
    tci: 1.68,
    ttci: 1.71,
    co2: 3.3841345e7,
    tco2: 1.147338e7,
    cost: 1.04159607e8,
    tcost: 1.3834388e7,
    fuel: 1658992.0,
    tfuel: 517301.0,
    spd: 40.7,
    tspd: 40.7,
    vmt_tx100seg: 1645348.63427,
    trkvmt_tx100seg: 115079.49915,
  },
  {
    tx100id: 8000135,
    year: 2017,
    rank: 20,
    trank: 11,
    dly: 3086278.0,
    tdly: 222210.0,
    pti: 2.72,
    tpti: 2.83,
    tci: 1.75,
    ttci: 1.77,
    co2: 1.7315412e7,
    tco2: 6940872.0,
    cost: 6.3179802e7,
    tcost: 1.1127307e7,
    fuel: 850379.0,
    tfuel: 320712.0,
    spd: 46.0,
    tspd: 45.9,
    vmt_tx100seg: 1235469.62321,
    trkvmt_tx100seg: 124436.29861,
  },
  {
    tx100id: 1200170,
    year: 2017,
    rank: 14,
    trank: 12,
    dly: 3525563.0,
    tdly: 212016.0,
    pti: 2.18,
    tpti: 2.23,
    tci: 1.54,
    ttci: 1.53,
    co2: 1.698602e7,
    tco2: 6649055.0,
    cost: 7.069392e7,
    tcost: 1.0633689e7,
    fuel: 851289.0,
    tfuel: 320344.0,
    spd: 45.6,
    tspd: 45.8,
    vmt_tx100seg: 2070508.82457,
    trkvmt_tx100seg: 180822.21917,
  },
  {
    tx100id: 8000010,
    year: 2017,
    rank: 24,
    trank: 13,
    dly: 1416057.0,
    tdly: 105924.0,
    pti: 2.17,
    tpti: 2.22,
    tci: 1.69,
    ttci: 1.68,
    co2: 1.234974e7,
    tco2: 4948306.0,
    cost: 2.95252e7,
    tcost: 5447589.0,
    fuel: 601513.0,
    tfuel: 223781.0,
    spd: 36.4,
    tspd: 36.2,
    vmt_tx100seg: 459341.03094,
    trkvmt_tx100seg: 48934.93944,
  },
  {
    tx100id: 1200168,
    year: 2017,
    rank: 39,
    trank: 14,
    dly: 503250.0,
    tdly: 47062.0,
    pti: 2.06,
    tpti: 2.11,
    tci: 1.61,
    ttci: 1.65,
    co2: 4795122.0,
    tco2: 2145567.0,
    cost: 1.0806716e7,
    tcost: 2415624.0,
    fuel: 232123.0,
    tfuel: 96903.0,
    spd: 39.1,
    tspd: 38.7,
    vmt_tx100seg: 219947.76078,
    trkvmt_tx100seg: 27775.914,
  },
  {
    tx100id: 1200141,
    year: 2017,
    rank: 12,
    trank: 15,
    dly: 1845684.0,
    tdly: 92121.0,
    pti: 2.39,
    tpti: 2.47,
    tci: 1.61,
    ttci: 1.66,
    co2: 1.1672536e7,
    tco2: 4043672.0,
    cost: 3.671313e7,
    tcost: 4714645.0,
    fuel: 571945.0,
    tfuel: 182336.0,
    spd: 42.4,
    tspd: 41.7,
    vmt_tx100seg: 728733.91104,
    trkvmt_tx100seg: 47896.48384,
  },
  {
    tx100id: 1200142,
    year: 2017,
    rank: 9,
    trank: 16,
    dly: 5898587.0,
    tdly: 272565.0,
    pti: 2.07,
    tpti: 2.11,
    tci: 1.58,
    ttci: 1.59,
    co2: 2.7461841e7,
    tco2: 9201497.0,
    cost: 1.15739616e8,
    tcost: 1.3701753e7,
    fuel: 1354606.0,
    tfuel: 421409.0,
    spd: 43.1,
    tspd: 43.2,
    vmt_tx100seg: 2268182.58919,
    trkvmt_tx100seg: 146451.17921,
  },
  {
    tx100id: 1900036,
    year: 2017,
    rank: 31,
    trank: 17,
    dly: 1375109.0,
    tdly: 108976.0,
    pti: 2.31,
    tpti: 2.29,
    tci: 1.6,
    ttci: 1.54,
    co2: 1.0280129e7,
    tco2: 4658651.0,
    cost: 2.8682571e7,
    tcost: 5566630.0,
    fuel: 497114.0,
    tfuel: 209745.0,
    spd: 42.2,
    tspd: 43.6,
    vmt_tx100seg: 662479.30927,
    trkvmt_tx100seg: 80619.38393,
  },
  {
    tx100id: 1900019,
    year: 2017,
    rank: 36,
    trank: 18,
    dly: 1349589.0,
    tdly: 115998.0,
    pti: 2.13,
    tpti: 2.21,
    tci: 1.53,
    ttci: 1.54,
    co2: 1.2137987e7,
    tco2: 5340222.0,
    cost: 2.8614186e7,
    tcost: 5960498.0,
    fuel: 586832.0,
    tfuel: 239907.0,
    spd: 43.9,
    tspd: 43.7,
    vmt_tx100seg: 761377.64905,
    trkvmt_tx100seg: 91528.1449,
  },
  {
    tx100id: 8000017,
    year: 2017,
    rank: 19,
    trank: 19,
    dly: 4763361.0,
    tdly: 284505.0,
    pti: 2.65,
    tpti: 2.78,
    tci: 1.83,
    ttci: 1.87,
    co2: 3.3940469e7,
    tco2: 1.15779e7,
    cost: 9.6494481e7,
    tcost: 1.4476214e7,
    fuel: 1663871.0,
    tfuel: 521821.0,
    spd: 39.3,
    tspd: 39.6,
    vmt_tx100seg: 1615281.03152,
    trkvmt_tx100seg: 132750.52404,
  },
  {
    tx100id: 1200183,
    year: 2017,
    rank: 13,
    trank: 20,
    dly: 3433198.0,
    tdly: 166675.0,
    pti: 2.91,
    tpti: 2.97,
    tci: 1.82,
    ttci: 1.77,
    co2: 2.2025978e7,
    tco2: 6403253.0,
    cost: 6.8193355e7,
    tcost: 8443669.0,
    fuel: 1089527.0,
    tfuel: 291088.0,
    spd: 40.2,
    tspd: 41.0,
    vmt_tx100seg: 1186698.13487,
    trkvmt_tx100seg: 90179.27549,
  },
  {
    tx100id: 8000112,
    year: 2017,
    rank: 10,
    trank: 21,
    dly: 5792825.0,
    tdly: 242475.0,
    pti: 2.76,
    tpti: 2.88,
    tci: 1.86,
    ttci: 1.87,
    co2: 2.6760892e7,
    tco2: 7831891.0,
    cost: 1.12923318e8,
    tcost: 1.2167173e7,
    fuel: 1329971.0,
    tfuel: 363265.0,
    spd: 38.9,
    tspd: 39.2,
    vmt_tx100seg: 1901259.56835,
    trkvmt_tx100seg: 113043.18899,
  },
  {
    tx100id: 8000134,
    year: 2017,
    rank: 32,
    trank: 22,
    dly: 2249644.0,
    tdly: 161521.0,
    pti: 2.47,
    tpti: 2.58,
    tci: 1.59,
    ttci: 1.65,
    co2: 1.2184866e7,
    tco2: 4788123.0,
    cost: 4.6004056e7,
    tcost: 8069420.0,
    fuel: 602467.0,
    tfuel: 224215.0,
    spd: 47.1,
    tspd: 46.8,
    vmt_tx100seg: 1170657.29671,
    trkvmt_tx100seg: 114111.14198,
  },
  {
    tx100id: 8000009,
    year: 2017,
    rank: 28,
    trank: 23,
    dly: 2582077.0,
    tdly: 164702.0,
    pti: 1.92,
    tpti: 1.88,
    tci: 1.6,
    ttci: 1.56,
    co2: 2.1190034e7,
    tco2: 7815424.0,
    cost: 5.2881611e7,
    tcost: 8483496.0,
    fuel: 1034677.0,
    tfuel: 352104.0,
    spd: 38.0,
    tspd: 38.4,
    vmt_tx100seg: 798535.17882,
    trkvmt_tx100seg: 75871.55996,
  },
  {
    tx100id: 3000005,
    year: 2017,
    rank: 44,
    trank: 24,
    dly: 1864472.0,
    tdly: 164916.0,
    pti: 2.59,
    tpti: 2.67,
    tci: 1.61,
    ttci: 1.65,
    co2: 1.1574165e7,
    tco2: 4794137.0,
    cost: 3.9175071e7,
    tcost: 8224054.0,
    fuel: 568911.0,
    tfuel: 222759.0,
    spd: 48.7,
    tspd: 48.7,
    vmt_tx100seg: 1018382.12924,
    trkvmt_tx100seg: 124556.7394,
  },
  {
    tx100id: 1200196,
    year: 2017,
    rank: 11,
    trank: 25,
    dly: 2915420.0,
    tdly: 119099.0,
    pti: 3.07,
    tpti: 3.25,
    tci: 1.91,
    ttci: 1.9,
    co2: 1.9240419e7,
    tco2: 4534333.0,
    cost: 5.7311759e7,
    tcost: 6029950.0,
    fuel: 956404.0,
    tfuel: 205472.0,
    spd: 40.1,
    tspd: 40.4,
    vmt_tx100seg: 806711.12843,
    trkvmt_tx100seg: 46313.10305,
  },
  {
    tx100id: 8000021,
    year: 2017,
    rank: 26,
    trank: 26,
    dly: 1899795.0,
    tdly: 112599.0,
    pti: 2.26,
    tpti: 2.38,
    tci: 1.55,
    ttci: 1.63,
    co2: 1.3054436e7,
    tco2: 4298886.0,
    cost: 3.8413533e7,
    tcost: 5702905.0,
    fuel: 640769.0,
    tfuel: 193733.0,
    spd: 45.4,
    tspd: 44.1,
    vmt_tx100seg: 889557.97985,
    trkvmt_tx100seg: 66689.3593,
  },
  {
    tx100id: 8000136,
    year: 2017,
    rank: 35,
    trank: 27,
    dly: 2634178.0,
    tdly: 184784.0,
    pti: 1.77,
    tpti: 1.8,
    tci: 1.44,
    ttci: 1.44,
    co2: 1.1582845e7,
    tco2: 4898039.0,
    cost: 5.3475335e7,
    tcost: 9177218.0,
    fuel: 573514.0,
    tfuel: 231173.0,
    spd: 47.6,
    tspd: 47.4,
    vmt_tx100seg: 1569604.63188,
    trkvmt_tx100seg: 151605.94963,
  },
  {
    tx100id: 1400055,
    year: 2017,
    rank: 369,
    trank: 28,
    dly: 207500.0,
    tdly: 59410.0,
    pti: 1.62,
    tpti: 1.53,
    tci: 1.35,
    ttci: 1.27,
    co2: 3101708.0,
    tco2: 2229309.0,
    cost: 5731353.0,
    tcost: 3005491.0,
    fuel: 145145.0,
    tfuel: 100276.0,
    spd: 35.8,
    tspd: 33.2,
    vmt_tx100seg: 58353.62879,
    trkvmt_tx100seg: 25925.7942,
  },
  {
    tx100id: 1200167,
    year: 2017,
    rank: 57,
    trank: 29,
    dly: 1126590.0,
    tdly: 100479.0,
    pti: 2.19,
    tpti: 2.32,
    tci: 1.39,
    ttci: 1.44,
    co2: 7574857.0,
    tco2: 3222532.0,
    cost: 2.374939e7,
    tcost: 5035922.0,
    fuel: 370926.0,
    tfuel: 148372.0,
    spd: 49.3,
    tspd: 48.5,
    vmt_tx100seg: 784224.68862,
    trkvmt_tx100seg: 93170.97037,
  },
  {
    tx100id: 8000155,
    year: 2017,
    rank: 4,
    trank: 30,
    dly: 1570512.0,
    tdly: 30034.0,
    pti: 3.15,
    tpti: 3.63,
    tci: 2.12,
    ttci: 2.35,
    co2: 9162977.0,
    tco2: 990059.0,
    cost: 2.9762768e7,
    tcost: 1505156.0,
    fuel: 462783.0,
    tfuel: 45678.0,
    spd: 27.6,
    tspd: 25.5,
    vmt_tx100seg: 255968.38199,
    trkvmt_tx100seg: 5886.22384,
  },
  {
    tx100id: 8000196,
    year: 2017,
    rank: 186,
    trank: 31,
    dly: 419498.0,
    tdly: 75127.0,
    pti: 1.48,
    tpti: 1.59,
    tci: 1.34,
    ttci: 1.34,
    co2: 6294049.0,
    tco2: 3689500.0,
    cost: 1.0277577e7,
    tcost: 3882485.0,
    fuel: 298194.0,
    tfuel: 165270.0,
    spd: 33.5,
    tspd: 33.5,
    vmt_tx100seg: 104097.64351,
    trkvmt_tx100seg: 21783.48808,
  },
  {
    tx100id: 8000051,
    year: 2017,
    rank: 25,
    trank: 32,
    dly: 3314217.0,
    tdly: 159608.0,
    pti: 2.47,
    tpti: 2.5,
    tci: 1.72,
    ttci: 1.74,
    co2: 2.1445419e7,
    tco2: 6160700.0,
    cost: 6.5808794e7,
    tcost: 8088390.0,
    fuel: 1058998.0,
    tfuel: 278888.0,
    spd: 40.7,
    tspd: 40.9,
    vmt_tx100seg: 1164149.96684,
    trkvmt_tx100seg: 79360.4971,
  },
  {
    tx100id: 1200172,
    year: 2017,
    rank: 29,
    trank: 34,
    dly: 3832078.0,
    tdly: 191505.0,
    pti: 2.65,
    tpti: 2.74,
    tci: 1.63,
    ttci: 1.67,
    co2: 1.9019754e7,
    tco2: 5611349.0,
    cost: 7.5739405e7,
    tcost: 9552185.0,
    fuel: 942742.0,
    tfuel: 258167.0,
    spd: 48.1,
    tspd: 48.2,
    vmt_tx100seg: 2034300.24323,
    trkvmt_tx100seg: 144256.99391,
  },
  {
    tx100id: 8000113,
    year: 2017,
    rank: 16,
    trank: 35,
    dly: 3316691.0,
    tdly: 129972.0,
    pti: 2.71,
    tpti: 2.85,
    tci: 1.76,
    ttci: 1.75,
    co2: 1.4646327e7,
    tco2: 3998013.0,
    cost: 6.4331798e7,
    tcost: 6505103.0,
    fuel: 730297.0,
    tfuel: 186529.0,
    spd: 42.3,
    tspd: 42.6,
    vmt_tx100seg: 1413473.46511,
    trkvmt_tx100seg: 81858.1449,
  },
  {
    tx100id: 1200144,
    year: 2017,
    rank: 30,
    trank: 36,
    dly: 3014301.0,
    tdly: 156378.0,
    pti: 1.98,
    tpti: 2.05,
    tci: 1.51,
    ttci: 1.54,
    co2: 1.3590885e7,
    tco2: 4307470.0,
    cost: 5.9609935e7,
    tcost: 7776541.0,
    fuel: 674793.0,
    tfuel: 200792.0,
    spd: 49.2,
    tspd: 49.6,
    vmt_tx100seg: 1519364.47242,
    trkvmt_tx100seg: 112418.033,
  },
  {
    tx100id: 3000002,
    year: 2017,
    rank: 84,
    trank: 38,
    dly: 785382.0,
    tdly: 76659.0,
    pti: 2.62,
    tpti: 2.76,
    tci: 1.53,
    ttci: 1.61,
    co2: 5130050.0,
    tco2: 2184828.0,
    cost: 1.6738487e7,
    tcost: 3819860.0,
    fuel: 251809.0,
    tfuel: 101416.0,
    spd: 52.4,
    tspd: 51.5,
    vmt_tx100seg: 531972.79996,
    trkvmt_tx100seg: 70016.27247,
  },
  {
    tx100id: 1200188,
    year: 2017,
    rank: 27,
    trank: 39,
    dly: 3252464.0,
    tdly: 144719.0,
    pti: 2.3,
    tpti: 2.34,
    tci: 1.49,
    ttci: 1.48,
    co2: 1.3351893e7,
    tco2: 4384696.0,
    cost: 6.3491194e7,
    tcost: 7239644.0,
    fuel: 664519.0,
    tfuel: 206346.0,
    spd: 47.1,
    tspd: 47.6,
    vmt_tx100seg: 1896393.94695,
    trkvmt_tx100seg: 124544.35442,
  },
  {
    tx100id: 2200033,
    year: 2017,
    rank: 174,
    trank: 40,
    dly: 417398.0,
    tdly: 62511.0,
    pti: 1.6,
    tpti: 1.61,
    tci: 1.38,
    ttci: 1.36,
    co2: 6252919.0,
    tco2: 3572746.0,
    cost: 9867460.0,
    tcost: 3277458.0,
    fuel: 296768.0,
    tfuel: 159986.0,
    spd: 29.1,
    tspd: 29.7,
    vmt_tx100seg: 115031.6001,
    trkvmt_tx100seg: 22184.61937,
  },
  {
    tx100id: 3000007,
    year: 2017,
    rank: 106,
    trank: 41,
    dly: 908088.0,
    tdly: 97664.0,
    pti: 1.66,
    tpti: 1.63,
    tci: 1.32,
    ttci: 1.31,
    co2: 4061800.0,
    tco2: 2332136.0,
    cost: 1.9435523e7,
    tcost: 4827741.0,
    fuel: 199390.0,
    tfuel: 111000.0,
    spd: 53.2,
    tspd: 53.9,
    vmt_tx100seg: 712023.45131,
    trkvmt_tx100seg: 116394.11655,
  },
  {
    tx100id: 1900035,
    year: 2017,
    rank: 58,
    trank: 42,
    dly: 1103801.0,
    tdly: 82828.0,
    pti: 2.07,
    tpti: 2.07,
    tci: 1.4,
    ttci: 1.39,
    co2: 5369290.0,
    tco2: 2460255.0,
    cost: 2.261176e7,
    tcost: 4135609.0,
    fuel: 262311.0,
    tfuel: 113774.0,
    spd: 49.6,
    tspd: 50.1,
    vmt_tx100seg: 949335.45197,
    trkvmt_tx100seg: 110042.53019,
  },
  {
    tx100id: 1200148,
    year: 2017,
    rank: 43,
    trank: 51,
    dly: 1967890.0,
    tdly: 85860.0,
    pti: 2.05,
    tpti: 2.01,
    tci: 1.45,
    ttci: 1.41,
    co2: 6886519.0,
    tco2: 2067169.0,
    cost: 3.82507e7,
    tcost: 4246979.0,
    fuel: 345356.0,
    tfuel: 99281.0,
    spd: 49.8,
    tspd: 50.9,
    vmt_tx100seg: 1456618.63115,
    trkvmt_tx100seg: 100469.91095,
  },
  {
    tx100id: 1200182,
    year: 2017,
    rank: 81,
    trank: 53,
    dly: 1576839.0,
    tdly: 101398.0,
    pti: 1.72,
    tpti: 1.74,
    tci: 1.35,
    ttci: 1.37,
    co2: 8578562.0,
    tco2: 3161691.0,
    cost: 3.1903188e7,
    tcost: 5075232.0,
    fuel: 426609.0,
    tfuel: 147242.0,
    spd: 51.0,
    tspd: 50.9,
    vmt_tx100seg: 1287079.43888,
    trkvmt_tx100seg: 115587.5031,
  },
  {
    tx100id: 8000018,
    year: 2017,
    rank: 56,
    trank: 54,
    dly: 752542.0,
    tdly: 39463.0,
    pti: 1.48,
    tpti: 1.48,
    tci: 1.3,
    ttci: 1.3,
    co2: 3407517.0,
    tco2: 1253510.0,
    cost: 1.4898062e7,
    tcost: 1977874.0,
    fuel: 170084.0,
    tfuel: 58288.0,
    spd: 49.1,
    tspd: 49.1,
    vmt_tx100seg: 613200.39739,
    trkvmt_tx100seg: 45868.11393,
  },
  {
    tx100id: 8000008,
    year: 2017,
    rank: 88,
    trank: 55,
    dly: 935435.0,
    tdly: 62096.0,
    pti: 2.18,
    tpti: 2.15,
    tci: 1.52,
    ttci: 1.48,
    co2: 6026198.0,
    tco2: 2384217.0,
    cost: 1.9067239e7,
    tcost: 3146814.0,
    fuel: 293640.0,
    tfuel: 107729.0,
    spd: 47.7,
    tspd: 48.2,
    vmt_tx100seg: 371701.22805,
    trkvmt_tx100seg: 35789.47898,
  },
  {
    tx100id: 1900012,
    year: 2017,
    rank: 59,
    trank: 56,
    dly: 1890139.0,
    tdly: 96986.0,
    pti: 2.19,
    tpti: 2.17,
    tci: 1.63,
    ttci: 1.58,
    co2: 8782698.0,
    tco2: 2773978.0,
    cost: 3.737647e7,
    tcost: 4835691.0,
    fuel: 438622.0,
    tfuel: 131654.0,
    spd: 45.8,
    tspd: 47.1,
    vmt_tx100seg: 847779.42971,
    trkvmt_tx100seg: 67007.21995,
  },
  {
    tx100id: 8000084,
    year: 2017,
    rank: 63,
    trank: 58,
    dly: 1684923.0,
    tdly: 88826.0,
    pti: 2.5,
    tpti: 2.53,
    tci: 1.62,
    ttci: 1.62,
    co2: 9546644.0,
    tco2: 3095489.0,
    cost: 3.355527e7,
    tcost: 4478086.0,
    fuel: 474277.0,
    tfuel: 143974.0,
    spd: 45.2,
    tspd: 45.3,
    vmt_tx100seg: 851031.33536,
    trkvmt_tx100seg: 65602.81087,
  },
  {
    tx100id: 1200185,
    year: 2017,
    rank: 38,
    trank: 59,
    dly: 2571418.0,
    tdly: 93180.0,
    pti: 2.02,
    tpti: 1.99,
    tci: 1.47,
    ttci: 1.43,
    co2: 1.1802707e7,
    tco2: 3023746.0,
    cost: 4.9703961e7,
    tcost: 4677080.0,
    fuel: 593869.0,
    tfuel: 142364.0,
    spd: 47.7,
    tspd: 49.1,
    vmt_tx100seg: 1474680.04849,
    trkvmt_tx100seg: 85623.73839,
  },
  {
    tx100id: 8000114,
    year: 2017,
    rank: 60,
    trank: 63,
    dly: 2664302.0,
    tdly: 121482.0,
    pti: 1.82,
    tpti: 1.84,
    tci: 1.4,
    ttci: 1.4,
    co2: 9654352.0,
    tco2: 3064391.0,
    cost: 5.1970859e7,
    tcost: 6020647.0,
    fuel: 482392.0,
    tfuel: 146053.0,
    spd: 49.8,
    tspd: 50.2,
    vmt_tx100seg: 1907635.05439,
    trkvmt_tx100seg: 128374.89777,
  },
  {
    tx100id: 8000077,
    year: 2017,
    rank: 40,
    trank: 65,
    dly: 813941.0,
    tdly: 27433.0,
    pti: 2.56,
    tpti: 2.61,
    tci: 1.74,
    ttci: 1.71,
    co2: 4256730.0,
    tco2: 983151.0,
    cost: 1.5722718e7,
    tcost: 1385356.0,
    fuel: 213036.0,
    tfuel: 45699.0,
    spd: 42.6,
    tspd: 43.1,
    vmt_tx100seg: 379583.94503,
    trkvmt_tx100seg: 19842.9659,
  },
  {
    tx100id: 1200187,
    year: 2017,
    rank: 83,
    trank: 66,
    dly: 1165472.0,
    tdly: 64736.0,
    pti: 1.76,
    tpti: 1.75,
    tci: 1.38,
    ttci: 1.34,
    co2: 6071810.0,
    tco2: 2162566.0,
    cost: 2.3264589e7,
    tcost: 3261974.0,
    fuel: 308050.0,
    tfuel: 105768.0,
    spd: 47.4,
    tspd: 48.7,
    vmt_tx100seg: 952543.53846,
    trkvmt_tx100seg: 83771.14113,
  },
  {
    tx100id: 1200186,
    year: 2017,
    rank: 66,
    trank: 67,
    dly: 1288016.0,
    tdly: 60533.0,
    pti: 1.86,
    tpti: 1.89,
    tci: 1.38,
    ttci: 1.38,
    co2: 5478536.0,
    tco2: 2105392.0,
    cost: 2.5267887e7,
    tcost: 3052825.0,
    fuel: 280717.0,
    tfuel: 101228.0,
    spd: 48.4,
    tspd: 48.5,
    vmt_tx100seg: 973104.03089,
    trkvmt_tx100seg: 66638.23079,
  },
  {
    tx100id: 8000062,
    year: 2017,
    rank: 47,
    trank: 70,
    dly: 1198591.0,
    tdly: 46992.0,
    pti: 2.15,
    tpti: 2.21,
    tci: 1.68,
    ttci: 1.69,
    co2: 5845141.0,
    tco2: 1547669.0,
    cost: 2.3303848e7,
    tcost: 2361047.0,
    fuel: 292480.0,
    tfuel: 72768.0,
    spd: 42.9,
    tspd: 43.3,
    vmt_tx100seg: 500333.70002,
    trkvmt_tx100seg: 27533.54896,
  },
  {
    tx100id: 8000131,
    year: 2017,
    rank: 309,
    trank: 71,
    dly: 510359.0,
    tdly: 60428.0,
    pti: 1.48,
    tpti: 1.46,
    tci: 1.2,
    ttci: 1.2,
    co2: 3286563.0,
    tco2: 1302066.0,
    cost: 1.1181014e7,
    tcost: 2968027.0,
    fuel: 160357.0,
    tfuel: 58979.0,
    spd: 56.6,
    tspd: 56.8,
    vmt_tx100seg: 427960.90583,
    trkvmt_tx100seg: 63460.4731,
  },
  {
    tx100id: 8000039,
    year: 2017,
    rank: 97,
    trank: 74,
    dly: 1938808.0,
    tdly: 105134.0,
    pti: 1.74,
    tpti: 1.7,
    tci: 1.31,
    ttci: 1.29,
    co2: 6934943.0,
    tco2: 2158617.0,
    cost: 3.8300518e7,
    tcost: 5161611.0,
    fuel: 346408.0,
    tfuel: 102160.0,
    spd: 53.4,
    tspd: 53.9,
    vmt_tx100seg: 1859407.1242,
    trkvmt_tx100seg: 158753.42802,
  },
  {
    tx100id: 1200147,
    year: 2017,
    rank: 82,
    trank: 83,
    dly: 1790732.0,
    tdly: 84140.0,
    pti: 1.59,
    tpti: 1.6,
    tci: 1.28,
    ttci: 1.28,
    co2: 3791853.0,
    tco2: 1256342.0,
    cost: 3.4748477e7,
    tcost: 4098320.0,
    fuel: 196803.0,
    tfuel: 66976.0,
    spd: 52.8,
    tspd: 53.0,
    vmt_tx100seg: 1990009.68373,
    trkvmt_tx100seg: 138683.22438,
  },
  {
    tx100id: 3000006,
    year: 2017,
    rank: 183,
    trank: 84,
    dly: 587708.0,
    tdly: 45483.0,
    pti: 1.71,
    tpti: 1.72,
    tci: 1.29,
    ttci: 1.25,
    co2: 2465884.0,
    tco2: 1086581.0,
    cost: 1.2044966e7,
    tcost: 2249051.0,
    fuel: 122984.0,
    tfuel: 52484.0,
    spd: 53.5,
    tspd: 54.6,
    vmt_tx100seg: 721483.41076,
    trkvmt_tx100seg: 92999.06419,
  },
  {
    tx100id: 8000048,
    year: 2017,
    rank: 93,
    trank: 87,
    dly: 1594919.0,
    tdly: 77556.0,
    pti: 1.98,
    tpti: 2.01,
    tci: 1.46,
    ttci: 1.45,
    co2: 6598200.0,
    tco2: 2238373.0,
    cost: 3.1332122e7,
    tcost: 3868644.0,
    fuel: 329118.0,
    tfuel: 106156.0,
    spd: 48.7,
    tspd: 48.9,
    vmt_tx100seg: 1138903.6228,
    trkvmt_tx100seg: 83002.095,
  },
  {
    tx100id: 1200112,
    year: 2017,
    rank: 95,
    trank: 88,
    dly: 1289582.0,
    tdly: 62817.0,
    pti: 1.68,
    tpti: 1.75,
    tci: 1.29,
    ttci: 1.31,
    co2: 4070656.0,
    tco2: 1208720.0,
    cost: 2.5216103e7,
    tcost: 3078422.0,
    fuel: 205370.0,
    tfuel: 58673.0,
    spd: 53.9,
    tspd: 53.9,
    vmt_tx100seg: 1364287.7353,
    trkvmt_tx100seg: 89717.61151,
  },
  {
    tx100id: 1400201,
    year: 2017,
    rank: 405,
    trank: 95,
    dly: 172001.0,
    tdly: 20226.0,
    pti: 1.6,
    tpti: 1.55,
    tci: 1.34,
    ttci: 1.26,
    co2: 2182294.0,
    tco2: 909027.0,
    cost: 3867994.0,
    tcost: 1036882.0,
    fuel: 106108.0,
    tfuel: 41126.0,
    spd: 27.3,
    tspd: 28.8,
    vmt_tx100seg: 45699.9547,
    trkvmt_tx100seg: 7677.18833,
  },
  {
    tx100id: 1200156,
    year: 2017,
    rank: 69,
    trank: 97,
    dly: 1885047.0,
    tdly: 71571.0,
    pti: 2.31,
    tpti: 2.3,
    tci: 1.48,
    ttci: 1.44,
    co2: 7245927.0,
    tco2: 2102196.0,
    cost: 3.6391982e7,
    tcost: 3574574.0,
    fuel: 365211.0,
    tfuel: 101564.0,
    spd: 47.7,
    tspd: 49.2,
    vmt_tx100seg: 1261460.46649,
    trkvmt_tx100seg: 77592.94195,
  },
  {
    tx100id: 8000081,
    year: 2017,
    rank: 78,
    trank: 100,
    dly: 1128282.0,
    tdly: 43641.0,
    pti: 2.04,
    tpti: 2.08,
    tci: 1.46,
    ttci: 1.43,
    co2: 4669346.0,
    tco2: 1408492.0,
    cost: 2.1838915e7,
    tcost: 2191491.0,
    fuel: 235133.0,
    tfuel: 67637.0,
    spd: 48.5,
    tspd: 49.1,
    vmt_tx100seg: 751958.68809,
    trkvmt_tx100seg: 45262.03394,
  },
  {
    tx100id: 1000013,
    year: 2017,
    rank: 110,
    trank: 103,
    dly: 653873.0,
    tdly: 31762.0,
    pti: 1.33,
    tpti: 1.33,
    tci: 1.16,
    ttci: 1.14,
    co2: 4150464.0,
    tco2: 1029796.0,
    cost: 1.2985059e7,
    tcost: 1591446.0,
    fuel: 205902.0,
    tfuel: 46604.0,
    spd: 51.8,
    tspd: 52.5,
    vmt_tx100seg: 670904.4221,
    trkvmt_tx100seg: 47801.0247,
  },
  {
    tx100id: 1900034,
    year: 2017,
    rank: 280,
    trank: 104,
    dly: 361263.0,
    tdly: 31523.0,
    pti: 1.57,
    tpti: 1.57,
    tci: 1.2,
    ttci: 1.2,
    co2: 1513130.0,
    tco2: 740433.0,
    cost: 7507841.0,
    tcost: 1557488.0,
    fuel: 74830.0,
    tfuel: 35395.0,
    spd: 56.6,
    tspd: 56.7,
    vmt_tx100seg: 622386.21242,
    trkvmt_tx100seg: 80326.19068,
  },
  {
    tx100id: 8000019,
    year: 2017,
    rank: 142,
    trank: 105,
    dly: 514195.0,
    tdly: 28617.0,
    pti: 1.72,
    tpti: 1.7,
    tci: 1.3,
    ttci: 1.28,
    co2: 1870582.0,
    tco2: 664395.0,
    cost: 1.0185071e7,
    tcost: 1415138.0,
    fuel: 94818.0,
    tfuel: 32993.0,
    spd: 50.9,
    tspd: 51.5,
    vmt_tx100seg: 518745.29948,
    trkvmt_tx100seg: 43987.24285,
  },
  {
    tx100id: 8000038,
    year: 2017,
    rank: 165,
    trank: 109,
    dly: 1136819.0,
    tdly: 65548.0,
    pti: 1.52,
    tpti: 1.53,
    tci: 1.21,
    ttci: 1.21,
    co2: 2646809.0,
    tco2: 974095.0,
    cost: 2.2434297e7,
    tcost: 3186212.0,
    fuel: 134220.0,
    tfuel: 48556.0,
    spd: 56.0,
    tspd: 55.7,
    vmt_tx100seg: 1533611.60782,
    trkvmt_tx100seg: 130923.71505,
  },
  {
    tx100id: 1200159,
    year: 2017,
    rank: 111,
    trank: 114,
    dly: 938004.0,
    tdly: 41943.0,
    pti: 1.62,
    tpti: 1.61,
    tci: 1.37,
    ttci: 1.35,
    co2: 3493502.0,
    tco2: 1170801.0,
    cost: 1.8287289e7,
    tcost: 2091587.0,
    fuel: 178363.0,
    tfuel: 58133.0,
    spd: 48.8,
    tspd: 49.8,
    vmt_tx100seg: 696902.49116,
    trkvmt_tx100seg: 48807.15945,
  },
  {
    tx100id: 8000101,
    year: 2017,
    rank: 124,
    trank: 115,
    dly: 924573.0,
    tdly: 44149.0,
    pti: 2.2,
    tpti: 2.74,
    tci: 1.6,
    ttci: 1.82,
    co2: 6377145.0,
    tco2: 1639980.0,
    cost: 1.8388515e7,
    tcost: 2231866.0,
    fuel: 316943.0,
    tfuel: 75165.0,
    spd: 41.0,
    tspd: 41.0,
    vmt_tx100seg: 389911.41583,
    trkvmt_tx100seg: 23375.73497,
  },
  {
    tx100id: 8000052,
    year: 2017,
    rank: 233,
    trank: 117,
    dly: 859088.0,
    tdly: 60036.0,
    pti: 1.58,
    tpti: 1.57,
    tci: 1.24,
    ttci: 1.21,
    co2: 3062670.0,
    tco2: 1157785.0,
    cost: 1.7371546e7,
    tcost: 2948018.0,
    fuel: 156665.0,
    tfuel: 59000.0,
    spd: 54.2,
    tspd: 54.9,
    vmt_tx100seg: 1076831.0612,
    trkvmt_tx100seg: 120061.83049,
  },
  {
    tx100id: 8000011,
    year: 2017,
    rank: 201,
    trank: 123,
    dly: 594425.0,
    tdly: 37865.0,
    pti: 1.43,
    tpti: 1.43,
    tci: 1.2,
    ttci: 1.18,
    co2: 1791262.0,
    tco2: 778394.0,
    cost: 1.1884271e7,
    tcost: 1866629.0,
    fuel: 94791.0,
    tfuel: 41327.0,
    spd: 55.7,
    tspd: 56.0,
    vmt_tx100seg: 907053.2857,
    trkvmt_tx100seg: 89002.36133,
  },
  {
    tx100id: 8000387,
    year: 2017,
    rank: 404,
    trank: 124,
    dly: 383784.0,
    tdly: 35523.0,
    pti: 1.49,
    tpti: 1.51,
    tci: 1.31,
    ttci: 1.29,
    co2: 4777233.0,
    tco2: 2083625.0,
    cost: 8340703.0,
    tcost: 1867368.0,
    fuel: 230857.0,
    tfuel: 93389.0,
    spd: 33.7,
    tspd: 33.7,
    vmt_tx100seg: 142199.78601,
    trkvmt_tx100seg: 16979.85408,
  },
  {
    tx100id: 8000050,
    year: 2017,
    rank: 175,
    trank: 134,
    dly: 769653.0,
    tdly: 40397.0,
    pti: 1.51,
    tpti: 1.54,
    tci: 1.25,
    ttci: 1.24,
    co2: 3868458.0,
    tco2: 1465029.0,
    cost: 1.5270466e7,
    tcost: 2039091.0,
    fuel: 190446.0,
    tfuel: 66926.0,
    spd: 51.9,
    tspd: 51.5,
    vmt_tx100seg: 756151.1277,
    trkvmt_tx100seg: 57309.87493,
  },
  {
    tx100id: 1900072,
    year: 2017,
    rank: 156,
    trank: 145,
    dly: 465440.0,
    tdly: 20677.0,
    pti: 1.87,
    tpti: 2.01,
    tci: 1.49,
    ttci: 1.54,
    co2: 4342001.0,
    tco2: 858077.0,
    cost: 9324364.0,
    tcost: 1052872.0,
    fuel: 217021.0,
    tfuel: 39219.0,
    spd: 29.1,
    tspd: 27.6,
    vmt_tx100seg: 115082.64341,
    trkvmt_tx100seg: 6213.91161,
  },
  {
    tx100id: 1100023,
    year: 2017,
    rank: 710,
    trank: 151,
    dly: 222466.0,
    tdly: 28971.0,
    pti: 1.28,
    tpti: 1.29,
    tci: 1.17,
    ttci: 1.16,
    co2: 1303989.0,
    tco2: 680086.0,
    cost: 4939369.0,
    tcost: 1429392.0,
    fuel: 63665.0,
    tfuel: 31267.0,
    spd: 58.2,
    tspd: 58.2,
    vmt_tx100seg: 330371.59102,
    trkvmt_tx100seg: 62137.09925,
  },
  {
    tx100id: 1900032,
    year: 2017,
    rank: 188,
    trank: 155,
    dly: 227857.0,
    tdly: 11171.0,
    pti: 1.71,
    tpti: 1.61,
    tci: 1.26,
    ttci: 1.22,
    co2: 1050656.0,
    tco2: 335000.0,
    cost: 4490663.0,
    tcost: 558890.0,
    fuel: 52857.0,
    tfuel: 16005.0,
    spd: 51.3,
    tspd: 52.2,
    vmt_tx100seg: 275232.65412,
    trkvmt_tx100seg: 23708.53299,
  },
  {
    tx100id: 1200165,
    year: 2017,
    rank: 631,
    trank: 158,
    dly: 329939.0,
    tdly: 36165.0,
    pti: 1.23,
    tpti: 1.23,
    tci: 1.12,
    ttci: 1.11,
    co2: 656695.0,
    tco2: 321076.0,
    cost: 7007124.0,
    tcost: 1740958.0,
    fuel: 35769.0,
    tfuel: 18482.0,
    spd: 58.3,
    tspd: 58.7,
    vmt_tx100seg: 785089.09503,
    trkvmt_tx100seg: 121828.25184,
  },
  {
    tx100id: 1200200,
    year: 2017,
    rank: 265,
    trank: 163,
    dly: 627145.0,
    tdly: 35593.0,
    pti: 1.47,
    tpti: 1.45,
    tci: 1.22,
    ttci: 1.22,
    co2: 2129451.0,
    tco2: 792064.0,
    cost: 1.2430934e7,
    tcost: 1759609.0,
    fuel: 109804.0,
    tfuel: 40879.0,
    spd: 52.8,
    tspd: 53.0,
    vmt_tx100seg: 816906.67027,
    trkvmt_tx100seg: 67248.99648,
  },
  {
    tx100id: 8000012,
    year: 2017,
    rank: 426,
    trank: 168,
    dly: 544601.0,
    tdly: 38846.0,
    pti: 1.55,
    tpti: 1.54,
    tci: 1.2,
    ttci: 1.19,
    co2: 1951787.0,
    tco2: 849788.0,
    cost: 1.1032892e7,
    tcost: 1914086.0,
    fuel: 98405.0,
    tfuel: 41907.0,
    spd: 56.5,
    tspd: 57.0,
    vmt_tx100seg: 979327.24099,
    trkvmt_tx100seg: 109320.12411,
  },
  {
    tx100id: 3000012,
    year: 2017,
    rank: 64,
    trank: 178,
    dly: 879924.0,
    tdly: 18549.0,
    pti: 2.06,
    tpti: 2.11,
    tci: 1.51,
    ttci: 1.53,
    co2: 6596750.0,
    tco2: 740131.0,
    cost: 1.6871671e7,
    tcost: 941754.0,
    fuel: 332746.0,
    tfuel: 33847.0,
    spd: 41.1,
    tspd: 39.8,
    vmt_tx100seg: 240495.02465,
    trkvmt_tx100seg: 6666.87781,
  },
  {
    tx100id: 2400012,
    year: 2017,
    rank: 921,
    trank: 179,
    dly: 137138.0,
    tdly: 20967.0,
    pti: 1.08,
    tpti: 1.06,
    tci: 1.03,
    ttci: 1.03,
    co2: 7485.0,
    tco2: 4648.0,
    cost: 3062952.0,
    tcost: 993592.0,
    fuel: 3284.0,
    tfuel: 3104.0,
    spd: 61.9,
    tspd: 62.0,
    vmt_tx100seg: 527188.89139,
    trkvmt_tx100seg: 111496.62309,
  },
  {
    tx100id: 8000053,
    year: 2017,
    rank: 657,
    trank: 194,
    dly: 432979.0,
    tdly: 38648.0,
    pti: 1.38,
    tpti: 1.33,
    tci: 1.13,
    ttci: 1.11,
    co2: 851400.0,
    tco2: 356683.0,
    cost: 8936589.0,
    tcost: 1862899.0,
    fuel: 46792.0,
    tfuel: 21076.0,
    spd: 58.1,
    tspd: 58.8,
    vmt_tx100seg: 962399.43799,
    trkvmt_tx100seg: 141746.44982,
  },
  {
    tx100id: 8000198,
    year: 2017,
    rank: 416,
    trank: 198,
    dly: 496518.0,
    tdly: 29191.0,
    pti: 1.31,
    tpti: 1.33,
    tci: 1.19,
    ttci: 1.19,
    co2: 4622117.0,
    tco2: 1500391.0,
    cost: 1.0150612e7,
    tcost: 1513652.0,
    fuel: 227388.0,
    tfuel: 68071.0,
    spd: 38.6,
    tspd: 38.9,
    vmt_tx100seg: 243138.4437,
    trkvmt_tx100seg: 17517.55754,
  },
  {
    tx100id: 1010102,
    year: 2017,
    rank: 248,
    trank: 201,
    dly: 444129.0,
    tdly: 18853.0,
    pti: 1.41,
    tpti: 1.4,
    tci: 1.21,
    ttci: 1.19,
    co2: 1240032.0,
    tco2: 454480.0,
    cost: 8588095.0,
    tcost: 934298.0,
    fuel: 63339.0,
    tfuel: 22922.0,
    spd: 54.2,
    tspd: 54.8,
    vmt_tx100seg: 449227.83759,
    trkvmt_tx100seg: 28085.70543,
  },
  {
    tx100id: 8000130,
    year: 2017,
    rank: 675,
    trank: 209,
    dly: 326377.0,
    tdly: 28564.0,
    pti: 1.32,
    tpti: 1.32,
    tci: 1.14,
    ttci: 1.14,
    co2: 986272.0,
    tco2: 471654.0,
    cost: 6754600.0,
    tcost: 1398216.0,
    fuel: 52609.0,
    tfuel: 26039.0,
    spd: 55.4,
    tspd: 55.4,
    vmt_tx100seg: 523508.73157,
    trkvmt_tx100seg: 63512.37972,
  },
  {
    tx100id: 8000040,
    year: 2017,
    rank: 476,
    trank: 211,
    dly: 602542.0,
    tdly: 37681.0,
    pti: 1.26,
    tpti: 1.25,
    tci: 1.11,
    ttci: 1.1,
    co2: 732661.0,
    tco2: 303553.0,
    cost: 1.1918955e7,
    tcost: 1814992.0,
    fuel: 42232.0,
    tfuel: 20005.0,
    spd: 58.9,
    tspd: 59.2,
    vmt_tx100seg: 1577412.12647,
    trkvmt_tx100seg: 153755.47447,
  },
  {
    tx100id: 8000133,
    year: 2017,
    rank: 569,
    trank: 215,
    dly: 375778.0,
    tdly: 26253.0,
    pti: 1.26,
    tpti: 1.25,
    tci: 1.1,
    ttci: 1.1,
    co2: 193135.0,
    tco2: 90012.0,
    cost: 7490810.0,
    tcost: 1255126.0,
    fuel: 14816.0,
    tfuel: 9414.0,
    spd: 59.6,
    tspd: 59.8,
    vmt_tx100seg: 1025461.63306,
    trkvmt_tx100seg: 111463.13086,
  },
  {
    tx100id: 8000060,
    year: 2017,
    rank: 251,
    trank: 235,
    dly: 648598.0,
    tdly: 24062.0,
    pti: 1.51,
    tpti: 1.5,
    tci: 1.26,
    ttci: 1.26,
    co2: 3064302.0,
    tco2: 798514.0,
    cost: 1.2559556e7,
    tcost: 1208499.0,
    fuel: 152778.0,
    tfuel: 37003.0,
    spd: 53.9,
    tspd: 53.9,
    vmt_tx100seg: 717132.79342,
    trkvmt_tx100seg: 39819.0973,
  },
  {
    tx100id: 1900038,
    year: 2017,
    rank: 632,
    trank: 237,
    dly: 278136.0,
    tdly: 19807.0,
    pti: 1.43,
    tpti: 1.34,
    tci: 1.19,
    ttci: 1.15,
    co2: 1325939.0,
    tco2: 377415.0,
    cost: 5670563.0,
    tcost: 973254.0,
    fuel: 68884.0,
    tfuel: 19912.0,
    spd: 54.8,
    tspd: 56.2,
    vmt_tx100seg: 387399.09174,
    trkvmt_tx100seg: 51669.91311,
  },
  {
    tx100id: 8000061,
    year: 2017,
    rank: 292,
    trank: 240,
    dly: 585827.0,
    tdly: 23578.0,
    pti: 1.51,
    tpti: 1.56,
    tci: 1.25,
    ttci: 1.25,
    co2: 3047101.0,
    tco2: 796851.0,
    cost: 1.1427572e7,
    tcost: 1186356.0,
    fuel: 152902.0,
    tfuel: 37385.0,
    spd: 53.7,
    tspd: 53.6,
    vmt_tx100seg: 642415.99816,
    trkvmt_tx100seg: 36922.97173,
  },
  {
    tx100id: 1100022,
    year: 2017,
    rank: 1045,
    trank: 270,
    dly: 84864.0,
    tdly: 11276.0,
    pti: 1.33,
    tpti: 1.19,
    tci: 1.14,
    ttci: 1.06,
    co2: 955534.0,
    tco2: 381119.0,
    cost: 1934910.0,
    tcost: 566551.0,
    fuel: 46425.0,
    tfuel: 17110.0,
    spd: 26.6,
    tspd: 28.1,
    vmt_tx100seg: 31228.28517,
    trkvmt_tx100seg: 7036.12524,
  },
  {
    tx100id: 7000061,
    year: 2017,
    rank: 86,
    trank: 304,
    dly: 603720.0,
    tdly: 9504.0,
    pti: 1.47,
    tpti: 1.29,
    tci: 1.35,
    ttci: 1.13,
    co2: 5454591.0,
    tco2: 339259.0,
    cost: 1.1576204e7,
    tcost: 478940.0,
    fuel: 276514.0,
    tfuel: 15453.0,
    spd: 24.2,
    tspd: 25.0,
    vmt_tx100seg: 121434.60118,
    trkvmt_tx100seg: 6181.00513,
  },
  {
    tx100id: 1200094,
    year: 2017,
    rank: 753,
    trank: 363,
    dly: 310516.0,
    tdly: 19699.0,
    pti: 1.27,
    tpti: 1.25,
    tci: 1.11,
    ttci: 1.09,
    co2: 584576.0,
    tco2: 205341.0,
    cost: 6173208.0,
    tcost: 955503.0,
    fuel: 33170.0,
    tfuel: 13753.0,
    spd: 58.4,
    tspd: 58.8,
    vmt_tx100seg: 683118.56129,
    trkvmt_tx100seg: 64657.62715,
  },
  {
    tx100id: 1010103,
    year: 2017,
    rank: 778,
    trank: 576,
    dly: 239323.0,
    tdly: 10601.0,
    pti: 1.28,
    tpti: 1.27,
    tci: 1.11,
    ttci: 1.1,
    co2: 288209.0,
    tco2: 110966.0,
    cost: 4606622.0,
    tcost: 515033.0,
    fuel: 17034.0,
    tfuel: 7877.0,
    spd: 59.6,
    tspd: 59.6,
    vmt_tx100seg: 638045.94924,
    trkvmt_tx100seg: 38684.565,
  },
  {
    tx100id: 3000058,
    year: 2017,
    rank: 1273,
    trank: 699,
    dly: 99037.0,
    tdly: 10903.0,
    pti: 1.02,
    tpti: 1.02,
    tci: 1.01,
    ttci: 1.01,
    co2: 487129.0,
    tco2: 274176.0,
    cost: 2131408.0,
    tcost: 539939.0,
    fuel: 23859.0,
    tfuel: 12794.0,
    spd: 63.7,
    tspd: 63.8,
    vmt_tx100seg: 825625.18875,
    trkvmt_tx100seg: 127622.88961,
  },
  {
    tx100id: 2400013,
    year: 2017,
    rank: 1392,
    trank: 718,
    dly: 17684.0,
    tdly: 3187.0,
    pti: 1.03,
    tpti: 1.03,
    tci: 1.02,
    ttci: 1.01,
    co2: 199.0,
    tco2: 166.0,
    cost: 408789.0,
    tcost: 150607.0,
    fuel: 252.0,
    tfuel: 250.0,
    spd: 63.3,
    tspd: 63.5,
    vmt_tx100seg: 193431.31839,
    trkvmt_tx100seg: 48030.10912,
  },
  {
    tx100id: 2400030,
    year: 2017,
    rank: 1233,
    trank: 746,
    dly: 73243.0,
    tdly: 6510.0,
    pti: 1.23,
    tpti: 1.24,
    tci: 1.14,
    ttci: 1.14,
    co2: 737922.0,
    tco2: 381696.0,
    cost: 1566513.0,
    tcost: 342174.0,
    fuel: 35302.0,
    tfuel: 17122.0,
    spd: 42.9,
    tspd: 43.0,
    vmt_tx100seg: 67459.68471,
    trkvmt_tx100seg: 7618.7941,
  },
  {
    tx100id: 3000059,
    year: 2017,
    rank: 1309,
    trank: 804,
    dly: 144630.0,
    tdly: 15747.0,
    pti: 1.05,
    tpti: 1.05,
    tci: 1.02,
    ttci: 1.02,
    co2: 82916.0,
    tco2: 21879.0,
    cost: 3053337.0,
    tcost: 749591.0,
    fuel: 8243.0,
    tfuel: 4008.0,
    spd: 63.7,
    tspd: 63.7,
    vmt_tx100seg: 1685205.51586,
    trkvmt_tx100seg: 236063.96471,
  },
  {
    tx100id: 2400010,
    year: 2017,
    rank: 1498,
    trank: 1013,
    dly: 19555.0,
    tdly: 4050.0,
    pti: 1.01,
    tpti: 1.02,
    tci: 1.0,
    ttci: 1.0,
    co2: 899.0,
    tco2: 877.0,
    cost: 467825.0,
    tcost: 191630.0,
    fuel: 471.0,
    tfuel: 439.0,
    spd: 64.6,
    tspd: 64.5,
    vmt_tx100seg: 434892.27251,
    trkvmt_tx100seg: 102449.58569,
  },
  {
    tx100id: 1400800,
    year: 2017,
    rank: 1000,
    trank: 1189,
    dly: 37401.0,
    tdly: 914.0,
    pti: 1.31,
    tpti: 1.32,
    tci: 1.19,
    ttci: 1.18,
    co2: 356200.0,
    tco2: 36778.0,
    cost: 728390.0,
    tcost: 46425.0,
    fuel: 17972.0,
    tfuel: 1671.0,
    spd: 24.8,
    tspd: 25.0,
    vmt_tx100seg: 11225.12531,
    trkvmt_tx100seg: 359.87878,
  },
  {
    tx100id: 8000149,
    year: 2017,
    rank: 1312,
    trank: 1481,
    dly: 113179.0,
    tdly: 3562.0,
    pti: 1.08,
    tpti: 1.06,
    tci: 1.04,
    ttci: 1.04,
    co2: 353263.0,
    tco2: 138362.0,
    cost: 2155619.0,
    tcost: 180607.0,
    fuel: 18046.0,
    tfuel: 6503.0,
    spd: 60.3,
    tspd: 60.2,
    vmt_tx100seg: 349664.83312,
    trkvmt_tx100seg: 14333.52996,
  },
  {
    tx100id: 1900086,
    year: 2017,
    rank: 1705,
    trank: 1621,
    dly: 1623.0,
    tdly: 684.0,
    pti: 1.01,
    tpti: 1.01,
    tci: 1.0,
    ttci: 1.0,
    co2: 0.0,
    tco2: 0.0,
    cost: 48996.0,
    tcost: 32282.0,
    fuel: 26.0,
    tfuel: 26.0,
    spd: 64.9,
    tspd: 64.9,
    vmt_tx100seg: 226859.89441,
    trkvmt_tx100seg: 73295.1007,
  },
  {
    tx100id: 3000004,
    year: 2018,
    rank: 3,
    trank: 1,
    dly: 1.0791257e7,
    tdly: 732699.0,
    pti: 4.36,
    tpti: 4.39,
    tci: 2.67,
    ttci: 2.54,
    co2: 8.5151579e7,
    tco2: 2.8086472e7,
    cost: 2.26765796e8,
    tcost: 3.8184799e7,
    fuel: 4178114.0,
    tfuel: 1265712.0,
    spd: 34.2,
    tspd: 35.7,
    vmt_tx100seg: 1387266.12289,
    trkvmt_tx100seg: 138096.25122,
  },
  {
    tx100id: 1400055,
    year: 2018,
    rank: 132,
    trank: 2,
    dly: 405890.0,
    tdly: 214749.0,
    pti: 1.99,
    tpti: 2.07,
    tci: 1.62,
    ttci: 1.62,
    co2: 8697564.0,
    tco2: 7462906.0,
    cost: 1.4715101e7,
    tcost: 1.111405e7,
    fuel: 398790.0,
    tfuel: 335389.0,
    spd: 30.6,
    tspd: 29.4,
    vmt_tx100seg: 64752.83116,
    trkvmt_tx100seg: 39411.03586,
  },
  {
    tx100id: 1200140,
    year: 2018,
    rank: 5,
    trank: 3,
    dly: 2914675.0,
    tdly: 156836.0,
    pti: 3.35,
    tpti: 3.43,
    tci: 2.4,
    ttci: 2.4,
    co2: 2.0772169e7,
    tco2: 6137017.0,
    cost: 5.9777691e7,
    tcost: 8184489.0,
    fuel: 1025278.0,
    tfuel: 278206.0,
    spd: 34.1,
    tspd: 34.4,
    vmt_tx100seg: 435140.44126,
    trkvmt_tx100seg: 32925.23516,
  },
  {
    tx100id: 1200184,
    year: 2018,
    rank: 1,
    trank: 4,
    dly: 6058201.0,
    tdly: 182930.0,
    pti: 4.36,
    tpti: 4.32,
    tci: 2.74,
    ttci: 2.56,
    co2: 3.7773039e7,
    tco2: 6945476.0,
    cost: 1.19397248e8,
    tcost: 9522086.0,
    fuel: 1890831.0,
    tfuel: 316977.0,
    spd: 29.8,
    tspd: 31.8,
    vmt_tx100seg: 754187.57198,
    trkvmt_tx100seg: 33945.26006,
  },
  {
    tx100id: 8000010,
    year: 2018,
    rank: 6,
    trank: 5,
    dly: 2971246.0,
    tdly: 153993.0,
    pti: 2.81,
    tpti: 2.79,
    tci: 2.12,
    ttci: 2.08,
    co2: 2.2204254e7,
    tco2: 6594710.0,
    cost: 6.0873988e7,
    tcost: 8096677.0,
    fuel: 1093289.0,
    tfuel: 296656.0,
    spd: 30.1,
    tspd: 30.9,
    vmt_tx100seg: 463818.93744,
    trkvmt_tx100seg: 33824.93175,
  },
  {
    tx100id: 1200168,
    year: 2018,
    rank: 16,
    trank: 6,
    dly: 857629.0,
    tdly: 68301.0,
    pti: 2.89,
    tpti: 2.88,
    tci: 1.99,
    ttci: 1.98,
    co2: 7746315.0,
    tco2: 3002554.0,
    cost: 1.842662e7,
    tcost: 3598648.0,
    fuel: 377505.0,
    tfuel: 135407.0,
    spd: 35.4,
    tspd: 36.1,
    vmt_tx100seg: 226426.65229,
    trkvmt_tx100seg: 26201.34615,
  },
  {
    tx100id: 8000051,
    year: 2018,
    rank: 26,
    trank: 7,
    dly: 3351234.0,
    tdly: 320862.0,
    pti: 2.21,
    tpti: 2.19,
    tci: 1.66,
    ttci: 1.63,
    co2: 2.7446104e7,
    tco2: 1.3332897e7,
    cost: 7.329306e7,
    tcost: 1.6819607e7,
    fuel: 1327502.0,
    tfuel: 607173.0,
    spd: 40.3,
    tspd: 41.2,
    vmt_tx100seg: 1167238.59533,
    trkvmt_tx100seg: 166934.96264,
  },
  {
    tx100id: 3000003,
    year: 2018,
    rank: 19,
    trank: 8,
    dly: 1992309.0,
    tdly: 154782.0,
    pti: 2.65,
    tpti: 2.7,
    tci: 1.66,
    ttci: 1.65,
    co2: 1.194837e7,
    tco2: 5273820.0,
    cost: 4.2038542e7,
    tcost: 8003343.0,
    fuel: 579608.0,
    tfuel: 238962.0,
    spd: 46.3,
    tspd: 46.7,
    vmt_tx100seg: 624592.58198,
    trkvmt_tx100seg: 69249.07784,
  },
  {
    tx100id: 1200143,
    year: 2018,
    rank: 11,
    trank: 9,
    dly: 5580272.0,
    tdly: 303535.0,
    pti: 2.52,
    tpti: 2.49,
    tci: 1.87,
    ttci: 1.82,
    co2: 3.973083e7,
    tco2: 1.3458349e7,
    cost: 1.14527998e8,
    tcost: 1.6003938e7,
    fuel: 1948219.0,
    tfuel: 607399.0,
    spd: 37.1,
    tspd: 38.1,
    vmt_tx100seg: 1208156.3193,
    trkvmt_tx100seg: 94580.62411,
  },
  {
    tx100id: 1200139,
    year: 2018,
    rank: 2,
    trank: 10,
    dly: 7471370.0,
    tdly: 205480.0,
    pti: 3.52,
    tpti: 3.59,
    tci: 2.25,
    ttci: 2.14,
    co2: 4.5431476e7,
    tco2: 7215198.0,
    cost: 1.46531409e8,
    tcost: 1.063971e7,
    fuel: 2279358.0,
    tfuel: 328316.0,
    spd: 39.2,
    tspd: 40.4,
    vmt_tx100seg: 1379834.63338,
    trkvmt_tx100seg: 56761.94193,
  },
  {
    tx100id: 8000112,
    year: 2018,
    rank: 9,
    trank: 11,
    dly: 6666606.0,
    tdly: 338650.0,
    pti: 2.6,
    tpti: 2.61,
    tci: 1.85,
    ttci: 1.82,
    co2: 3.3238872e7,
    tco2: 1.1189715e7,
    cost: 1.34585654e8,
    tcost: 1.7478803e7,
    fuel: 1642160.0,
    tfuel: 515764.0,
    spd: 39.1,
    tspd: 39.8,
    vmt_tx100seg: 1911077.9767,
    trkvmt_tx100seg: 140531.5722,
  },
  {
    tx100id: 1200171,
    year: 2018,
    rank: 7,
    trank: 12,
    dly: 2447754.0,
    tdly: 117965.0,
    pti: 2.84,
    tpti: 2.85,
    tci: 1.89,
    ttci: 1.8,
    co2: 1.2847872e7,
    tco2: 3788404.0,
    cost: 4.9300076e7,
    tcost: 6079880.0,
    fuel: 638157.0,
    tfuel: 175294.0,
    spd: 40.4,
    tspd: 41.9,
    vmt_tx100seg: 823152.48845,
    trkvmt_tx100seg: 62549.89443,
  },
  {
    tx100id: 8000020,
    year: 2018,
    rank: 8,
    trank: 13,
    dly: 3945400.0,
    tdly: 186839.0,
    pti: 2.44,
    tpti: 2.42,
    tci: 1.68,
    ttci: 1.64,
    co2: 2.6660033e7,
    tco2: 7758540.0,
    cost: 7.9994267e7,
    tcost: 9795769.0,
    fuel: 1316039.0,
    tfuel: 351353.0,
    spd: 40.3,
    tspd: 40.9,
    vmt_tx100seg: 1228241.87908,
    trkvmt_tx100seg: 86242.62455,
  },
  {
    tx100id: 8000117,
    year: 2018,
    rank: 23,
    trank: 14,
    dly: 1036318.0,
    tdly: 78469.0,
    pti: 2.89,
    tpti: 2.77,
    tci: 1.74,
    ttci: 1.73,
    co2: 8262295.0,
    tco2: 3195513.0,
    cost: 2.2025963e7,
    tcost: 4108582.0,
    fuel: 402885.0,
    tfuel: 144287.0,
    spd: 41.7,
    tspd: 42.7,
    vmt_tx100seg: 379795.32786,
    trkvmt_tx100seg: 43890.48438,
  },
  {
    tx100id: 1200142,
    year: 2018,
    rank: 10,
    trank: 15,
    dly: 6550084.0,
    tdly: 302267.0,
    pti: 2.19,
    tpti: 2.18,
    tci: 1.64,
    ttci: 1.62,
    co2: 3.2161081e7,
    tco2: 1.0674105e7,
    cost: 1.31253543e8,
    tcost: 1.5662181e7,
    fuel: 1585792.0,
    tfuel: 488596.0,
    spd: 41.6,
    tspd: 42.3,
    vmt_tx100seg: 2183932.85604,
    trkvmt_tx100seg: 145720.71141,
  },
  {
    tx100id: 8000009,
    year: 2018,
    rank: 22,
    trank: 16,
    dly: 2988427.0,
    tdly: 192601.0,
    pti: 2.04,
    tpti: 2.05,
    tci: 1.64,
    ttci: 1.65,
    co2: 2.5189934e7,
    tco2: 9032982.0,
    cost: 6.265721e7,
    tcost: 1.0207527e7,
    fuel: 1230497.0,
    tfuel: 405926.0,
    spd: 38.7,
    tspd: 38.8,
    vmt_tx100seg: 852696.37916,
    trkvmt_tx100seg: 73525.58438,
  },
  {
    tx100id: 1200183,
    year: 2018,
    rank: 18,
    trank: 17,
    dly: 3343938.0,
    tdly: 186444.0,
    pti: 2.6,
    tpti: 2.6,
    tci: 1.77,
    ttci: 1.74,
    co2: 2.0793056e7,
    tco2: 7215756.0,
    cost: 6.8443034e7,
    tcost: 9723292.0,
    fuel: 1023346.0,
    tfuel: 329357.0,
    spd: 40.4,
    tspd: 41.0,
    vmt_tx100seg: 1146055.14659,
    trkvmt_tx100seg: 95299.8969,
  },
  {
    tx100id: 1200169,
    year: 2018,
    rank: 17,
    trank: 19,
    dly: 3069194.0,
    tdly: 162001.0,
    pti: 2.22,
    tpti: 2.18,
    tci: 1.67,
    ttci: 1.64,
    co2: 1.874574e7,
    tco2: 6054706.0,
    cost: 6.2511138e7,
    tcost: 8426302.0,
    fuel: 923994.0,
    tfuel: 275832.0,
    spd: 42.1,
    tspd: 42.5,
    vmt_tx100seg: 1258990.72755,
    trkvmt_tx100seg: 97294.91229,
  },
  {
    tx100id: 1200196,
    year: 2018,
    rank: 12,
    trank: 20,
    dly: 3016093.0,
    tdly: 136681.0,
    pti: 3.0,
    tpti: 2.96,
    tci: 1.91,
    ttci: 1.82,
    co2: 2.1446288e7,
    tco2: 5337214.0,
    cost: 6.1090679e7,
    tcost: 7131366.0,
    fuel: 1064346.0,
    tfuel: 242062.0,
    spd: 40.2,
    tspd: 41.5,
    vmt_tx100seg: 797776.40529,
    trkvmt_tx100seg: 55485.05686,
  },
  {
    tx100id: 8000113,
    year: 2018,
    rank: 14,
    trank: 21,
    dly: 3930739.0,
    tdly: 179056.0,
    pti: 2.88,
    tpti: 2.88,
    tci: 1.88,
    ttci: 1.81,
    co2: 1.9353533e7,
    tco2: 5259087.0,
    cost: 7.8737389e7,
    tcost: 9193704.0,
    fuel: 971640.0,
    tfuel: 251271.0,
    spd: 40.8,
    tspd: 42.1,
    vmt_tx100seg: 1412886.1504,
    trkvmt_tx100seg: 102271.45273,
  },
  {
    tx100id: 1900019,
    year: 2018,
    rank: 31,
    trank: 22,
    dly: 1543390.0,
    tdly: 108750.0,
    pti: 2.29,
    tpti: 2.26,
    tci: 1.61,
    ttci: 1.58,
    co2: 1.284196e7,
    tco2: 5014320.0,
    cost: 3.2616879e7,
    tcost: 5754285.0,
    fuel: 625182.0,
    tfuel: 225684.0,
    spd: 41.7,
    tspd: 42.6,
    vmt_tx100seg: 749321.84741,
    trkvmt_tx100seg: 80803.58342,
  },
  {
    tx100id: 1200170,
    year: 2018,
    rank: 13,
    trank: 25,
    dly: 3991982.0,
    tdly: 168173.0,
    pti: 2.48,
    tpti: 2.42,
    tci: 1.6,
    ttci: 1.54,
    co2: 1.9469119e7,
    tco2: 5359583.0,
    cost: 7.9530097e7,
    tcost: 8675346.0,
    fuel: 977241.0,
    tfuel: 254967.0,
    spd: 44.7,
    tspd: 45.7,
    vmt_tx100seg: 2000004.88635,
    trkvmt_tx100seg: 132274.73566,
  },
  {
    tx100id: 3000005,
    year: 2018,
    rank: 36,
    trank: 26,
    dly: 2092637.0,
    tdly: 161669.0,
    pti: 2.86,
    tpti: 2.84,
    tci: 1.65,
    ttci: 1.67,
    co2: 1.2375924e7,
    tco2: 4576411.0,
    cost: 4.4126213e7,
    tcost: 8273081.0,
    fuel: 610734.0,
    tfuel: 212582.0,
    spd: 48.6,
    tspd: 49.0,
    vmt_tx100seg: 1021683.74722,
    trkvmt_tx100seg: 114735.93182,
  },
  {
    tx100id: 1200141,
    year: 2018,
    rank: 20,
    trank: 27,
    dly: 1542577.0,
    tdly: 69040.0,
    pti: 2.38,
    tpti: 2.37,
    tci: 1.61,
    ttci: 1.61,
    co2: 9269192.0,
    tco2: 2857514.0,
    cost: 3.102857e7,
    tcost: 3617223.0,
    fuel: 458430.0,
    tfuel: 130847.0,
    spd: 42.3,
    tspd: 42.8,
    vmt_tx100seg: 647499.14681,
    trkvmt_tx100seg: 41101.41479,
  },
  {
    tx100id: 1900036,
    year: 2018,
    rank: 45,
    trank: 29,
    dly: 1056011.0,
    tdly: 72249.0,
    pti: 2.11,
    tpti: 2.08,
    tci: 1.5,
    ttci: 1.44,
    co2: 6911071.0,
    tco2: 2534217.0,
    cost: 2.2058587e7,
    tcost: 3746264.0,
    fuel: 340837.0,
    tfuel: 116613.0,
    spd: 47.6,
    tspd: 49.0,
    vmt_tx100seg: 621638.85449,
    trkvmt_tx100seg: 69962.3004,
  },
  {
    tx100id: 8000134,
    year: 2018,
    rank: 30,
    trank: 30,
    dly: 2321663.0,
    tdly: 114879.0,
    pti: 2.47,
    tpti: 2.41,
    tci: 1.6,
    ttci: 1.59,
    co2: 1.0986494e7,
    tco2: 3723933.0,
    cost: 4.6720214e7,
    tcost: 5927984.0,
    fuel: 544089.0,
    tfuel: 172981.0,
    spd: 45.8,
    tspd: 46.2,
    vmt_tx100seg: 1200536.55297,
    trkvmt_tx100seg: 89075.92232,
  },
  {
    tx100id: 1200172,
    year: 2018,
    rank: 37,
    trank: 31,
    dly: 3098559.0,
    tdly: 178279.0,
    pti: 2.28,
    tpti: 2.28,
    tci: 1.52,
    ttci: 1.52,
    co2: 1.3582889e7,
    tco2: 5094616.0,
    cost: 6.2990965e7,
    tcost: 9132630.0,
    fuel: 674825.0,
    tfuel: 240333.0,
    spd: 49.3,
    tspd: 49.6,
    vmt_tx100seg: 1860858.57718,
    trkvmt_tx100seg: 159023.77729,
  },
  {
    tx100id: 1900035,
    year: 2018,
    rank: 55,
    trank: 33,
    dly: 1223617.0,
    tdly: 84082.0,
    pti: 2.34,
    tpti: 2.31,
    tci: 1.5,
    ttci: 1.47,
    co2: 6383093.0,
    tco2: 2622160.0,
    cost: 2.539237e7,
    tcost: 4327338.0,
    fuel: 313497.0,
    tfuel: 121490.0,
    spd: 47.8,
    tspd: 48.7,
    vmt_tx100seg: 804481.98767,
    trkvmt_tx100seg: 89100.38812,
  },
  {
    tx100id: 1200167,
    year: 2018,
    rank: 64,
    trank: 34,
    dly: 1106747.0,
    tdly: 85518.0,
    pti: 2.0,
    tpti: 2.03,
    tci: 1.43,
    ttci: 1.46,
    co2: 7194385.0,
    tco2: 2948184.0,
    cost: 2.3406815e7,
    tcost: 4429383.0,
    fuel: 354211.0,
    tfuel: 136349.0,
    spd: 49.2,
    tspd: 49.0,
    vmt_tx100seg: 780601.70464,
    trkvmt_tx100seg: 85347.52798,
  },
  {
    tx100id: 8000084,
    year: 2018,
    rank: 48,
    trank: 36,
    dly: 2069030.0,
    tdly: 133462.0,
    pti: 2.69,
    tpti: 2.69,
    tci: 1.72,
    ttci: 1.71,
    co2: 1.4168215e7,
    tco2: 5098845.0,
    cost: 4.3034218e7,
    tcost: 6955557.0,
    fuel: 695570.0,
    tfuel: 231906.0,
    spd: 43.3,
    tspd: 43.7,
    vmt_tx100seg: 916770.96743,
    trkvmt_tx100seg: 87956.85092,
  },
  {
    tx100id: 8000196,
    year: 2018,
    rank: 187,
    trank: 37,
    dly: 443979.0,
    tdly: 65215.0,
    pti: 1.57,
    tpti: 1.52,
    tci: 1.37,
    ttci: 1.31,
    co2: 6341449.0,
    tco2: 3442102.0,
    cost: 1.0680596e7,
    tcost: 3496314.0,
    fuel: 302120.0,
    tfuel: 154152.0,
    spd: 32.3,
    tspd: 33.4,
    vmt_tx100seg: 108654.7428,
    trkvmt_tx100seg: 22230.22448,
  },
  {
    tx100id: 8000021,
    year: 2018,
    rank: 33,
    trank: 38,
    dly: 1632829.0,
    tdly: 80476.0,
    pti: 2.27,
    tpti: 2.24,
    tci: 1.53,
    ttci: 1.52,
    co2: 9798396.0,
    tco2: 3089267.0,
    cost: 3.3071369e7,
    tcost: 4197503.0,
    fuel: 485435.0,
    tfuel: 142010.0,
    spd: 46.3,
    tspd: 46.7,
    vmt_tx100seg: 901051.82094,
    trkvmt_tx100seg: 67595.0409,
  },
  {
    tx100id: 3000002,
    year: 2018,
    rank: 78,
    trank: 39,
    dly: 865211.0,
    tdly: 71611.0,
    pti: 2.72,
    tpti: 2.69,
    tci: 1.55,
    ttci: 1.57,
    co2: 5161448.0,
    tco2: 2043575.0,
    cost: 1.839104e7,
    tcost: 3665653.0,
    fuel: 253418.0,
    tfuel: 94270.0,
    spd: 51.0,
    tspd: 51.1,
    vmt_tx100seg: 536998.10984,
    trkvmt_tx100seg: 65297.73749,
  },
  {
    tx100id: 3000007,
    year: 2018,
    rank: 87,
    trank: 40,
    dly: 1085163.0,
    tdly: 93054.0,
    pti: 1.6,
    tpti: 1.59,
    tci: 1.33,
    ttci: 1.32,
    co2: 4142518.0,
    tco2: 2104336.0,
    cost: 2.2911704e7,
    tcost: 4708818.0,
    fuel: 203232.0,
    tfuel: 99144.0,
    spd: 52.4,
    tspd: 53.0,
    vmt_tx100seg: 709076.19171,
    trkvmt_tx100seg: 89770.43484,
  },
  {
    tx100id: 1200182,
    year: 2018,
    rank: 50,
    trank: 41,
    dly: 2242463.0,
    tdly: 136115.0,
    pti: 1.88,
    tpti: 1.89,
    tci: 1.47,
    ttci: 1.48,
    co2: 1.35251e7,
    tco2: 4734695.0,
    cost: 4.6186774e7,
    tcost: 7044418.0,
    fuel: 665893.0,
    tfuel: 216433.0,
    spd: 47.8,
    tspd: 47.9,
    vmt_tx100seg: 1325050.56284,
    trkvmt_tx100seg: 114624.1844,
  },
  {
    tx100id: 8000101,
    year: 2018,
    rank: 166,
    trank: 42,
    dly: 808288.0,
    tdly: 99661.0,
    pti: 1.7,
    tpti: 1.69,
    tci: 1.36,
    ttci: 1.34,
    co2: 9059017.0,
    tco2: 5005765.0,
    cost: 1.8604931e7,
    tcost: 5315712.0,
    fuel: 431992.0,
    tfuel: 225123.0,
    spd: 42.2,
    tspd: 42.4,
    vmt_tx100seg: 359465.30267,
    trkvmt_tx100seg: 55634.03791,
  },
  {
    tx100id: 8000135,
    year: 2018,
    rank: 28,
    trank: 43,
    dly: 2657323.0,
    tdly: 109825.0,
    pti: 2.47,
    tpti: 2.46,
    tci: 1.64,
    ttci: 1.62,
    co2: 1.175558e7,
    tco2: 3420152.0,
    cost: 5.2735784e7,
    tcost: 5651852.0,
    fuel: 584230.0,
    tfuel: 158758.0,
    spd: 46.9,
    tspd: 47.4,
    vmt_tx100seg: 1281488.62174,
    trkvmt_tx100seg: 80231.09247,
  },
  {
    tx100id: 8000017,
    year: 2018,
    rank: 46,
    trank: 44,
    dly: 2964260.0,
    tdly: 166179.0,
    pti: 1.95,
    tpti: 1.93,
    tci: 1.49,
    ttci: 1.45,
    co2: 1.798816e7,
    tco2: 6548580.0,
    cost: 6.0650384e7,
    tcost: 8679197.0,
    fuel: 883865.0,
    tfuel: 298627.0,
    spd: 45.6,
    tspd: 46.6,
    vmt_tx100seg: 1550699.69607,
    trkvmt_tx100seg: 133957.62049,
  },
  {
    tx100id: 1200186,
    year: 2018,
    rank: 42,
    trank: 48,
    dly: 1711666.0,
    tdly: 85040.0,
    pti: 2.05,
    tpti: 2.06,
    tci: 1.5,
    ttci: 1.48,
    co2: 8411417.0,
    tco2: 3008399.0,
    cost: 3.4492966e7,
    tcost: 4416859.0,
    fuel: 419578.0,
    tfuel: 142329.0,
    spd: 44.6,
    tspd: 45.5,
    vmt_tx100seg: 995507.93455,
    trkvmt_tx100seg: 75581.33728,
  },
  {
    tx100id: 8000114,
    year: 2018,
    rank: 47,
    trank: 50,
    dly: 3162146.0,
    tdly: 163700.0,
    pti: 1.79,
    tpti: 1.75,
    tci: 1.44,
    ttci: 1.41,
    co2: 1.3697457e7,
    tco2: 4503373.0,
    cost: 6.3719399e7,
    tcost: 8368477.0,
    fuel: 683639.0,
    tfuel: 214017.0,
    spd: 50.6,
    tspd: 51.7,
    vmt_tx100seg: 1893312.36506,
    trkvmt_tx100seg: 153615.01345,
  },
  {
    tx100id: 1200188,
    year: 2018,
    rank: 29,
    trank: 52,
    dly: 2992773.0,
    tdly: 107150.0,
    pti: 2.19,
    tpti: 2.15,
    tci: 1.48,
    ttci: 1.43,
    co2: 1.1741503e7,
    tco2: 3109577.0,
    cost: 5.874626e7,
    tcost: 5501946.0,
    fuel: 591805.0,
    tfuel: 150865.0,
    spd: 47.3,
    tspd: 48.9,
    vmt_tx100seg: 1674541.43416,
    trkvmt_tx100seg: 101860.042,
  },
  {
    tx100id: 1200144,
    year: 2018,
    rank: 27,
    trank: 53,
    dly: 3134592.0,
    tdly: 104683.0,
    pti: 1.75,
    tpti: 1.72,
    tci: 1.47,
    ttci: 1.45,
    co2: 1.2779163e7,
    tco2: 3340608.0,
    cost: 6.1337919e7,
    tcost: 5388586.0,
    fuel: 637848.0,
    tfuel: 155181.0,
    spd: 48.8,
    tspd: 49.9,
    vmt_tx100seg: 1480876.36995,
    trkvmt_tx100seg: 75510.22343,
  },
  {
    tx100id: 8000155,
    year: 2018,
    rank: 4,
    trank: 54,
    dly: 1797139.0,
    tdly: 18769.0,
    pti: 3.14,
    tpti: 3.17,
    tci: 2.29,
    ttci: 2.27,
    co2: 1.0169801e7,
    tco2: 694713.0,
    cost: 3.424837e7,
    tcost: 974980.0,
    fuel: 515340.0,
    tfuel: 31779.0,
    spd: 26.0,
    tspd: 26.1,
    vmt_tx100seg: 254175.29623,
    trkvmt_tx100seg: 3813.03324,
  },
  {
    tx100id: 1900012,
    year: 2018,
    rank: 39,
    trank: 55,
    dly: 2593352.0,
    tdly: 104870.0,
    pti: 2.27,
    tpti: 2.21,
    tci: 1.73,
    ttci: 1.66,
    co2: 1.2244919e7,
    tco2: 3097063.0,
    cost: 5.1482852e7,
    tcost: 5378154.0,
    fuel: 611127.0,
    tfuel: 144124.0,
    spd: 44.8,
    tspd: 46.1,
    vmt_tx100seg: 951663.15946,
    trkvmt_tx100seg: 60096.14133,
  },
  {
    tx100id: 2200033,
    year: 2018,
    rank: 288,
    trank: 56,
    dly: 324620.0,
    tdly: 44326.0,
    pti: 1.54,
    tpti: 1.51,
    tci: 1.34,
    ttci: 1.29,
    co2: 4555816.0,
    tco2: 2549115.0,
    cost: 7699082.0,
    tcost: 2397935.0,
    fuel: 216570.0,
    tfuel: 114158.0,
    spd: 30.4,
    tspd: 31.6,
    vmt_tx100seg: 108366.02156,
    trkvmt_tx100seg: 21480.3508,
  },
  {
    tx100id: 8000062,
    year: 2018,
    rank: 44,
    trank: 58,
    dly: 1318539.0,
    tdly: 58758.0,
    pti: 2.15,
    tpti: 2.15,
    tci: 1.74,
    ttci: 1.7,
    co2: 7062860.0,
    tco2: 2033087.0,
    cost: 2.6430695e7,
    tcost: 3042284.0,
    fuel: 353717.0,
    tfuel: 95109.0,
    spd: 42.6,
    tspd: 43.3,
    vmt_tx100seg: 491921.31198,
    trkvmt_tx100seg: 33143.49487,
  },
  {
    tx100id: 1200187,
    year: 2018,
    rank: 65,
    trank: 60,
    dly: 1410393.0,
    tdly: 73740.0,
    pti: 1.93,
    tpti: 1.89,
    tci: 1.49,
    ttci: 1.45,
    co2: 8046661.0,
    tco2: 2758832.0,
    cost: 2.8655158e7,
    tcost: 3843987.0,
    fuel: 401911.0,
    tfuel: 129551.0,
    spd: 44.6,
    tspd: 46.0,
    vmt_tx100seg: 887820.84522,
    trkvmt_tx100seg: 73431.13189,
  },
  {
    tx100id: 8000019,
    year: 2018,
    rank: 79,
    trank: 61,
    dly: 770673.0,
    tdly: 43625.0,
    pti: 2.03,
    tpti: 1.97,
    tci: 1.49,
    ttci: 1.45,
    co2: 3661129.0,
    tco2: 1367950.0,
    cost: 1.5678444e7,
    tcost: 2249484.0,
    fuel: 183208.0,
    tfuel: 65759.0,
    spd: 45.6,
    tspd: 46.9,
    vmt_tx100seg: 513251.83834,
    trkvmt_tx100seg: 46130.34207,
  },
  {
    tx100id: 1900032,
    year: 2018,
    rank: 150,
    trank: 63,
    dly: 277159.0,
    tdly: 22699.0,
    pti: 1.72,
    tpti: 1.68,
    tci: 1.29,
    ttci: 1.25,
    co2: 1579976.0,
    tco2: 705572.0,
    cost: 5876705.0,
    tcost: 1168992.0,
    fuel: 77714.0,
    tfuel: 33057.0,
    spd: 49.9,
    tspd: 51.0,
    vmt_tx100seg: 309344.79755,
    trkvmt_tx100seg: 42258.91153,
  },
  {
    tx100id: 8000018,
    year: 2018,
    rank: 72,
    trank: 66,
    dly: 675340.0,
    tdly: 34827.0,
    pti: 1.7,
    tpti: 1.68,
    tci: 1.33,
    ttci: 1.31,
    co2: 2636057.0,
    tco2: 912295.0,
    cost: 1.3582269e7,
    tcost: 1782464.0,
    fuel: 136045.0,
    tfuel: 46779.0,
    spd: 49.2,
    tspd: 50.0,
    vmt_tx100seg: 592641.00046,
    trkvmt_tx100seg: 45984.41872,
  },
  {
    tx100id: 1900034,
    year: 2018,
    rank: 180,
    trank: 67,
    dly: 497691.0,
    tdly: 44341.0,
    pti: 1.91,
    tpti: 1.81,
    tci: 1.3,
    ttci: 1.29,
    co2: 2265519.0,
    tco2: 1106231.0,
    cost: 1.0600451e7,
    tcost: 2257355.0,
    fuel: 112284.0,
    tfuel: 53120.0,
    spd: 53.3,
    tspd: 54.0,
    vmt_tx100seg: 526334.01603,
    trkvmt_tx100seg: 73372.36582,
  },
  {
    tx100id: 1200185,
    year: 2018,
    rank: 34,
    trank: 68,
    dly: 2840639.0,
    tdly: 84497.0,
    pti: 1.89,
    tpti: 1.85,
    tci: 1.46,
    ttci: 1.41,
    co2: 1.3586042e7,
    tco2: 2900003.0,
    cost: 5.5496912e7,
    tcost: 4369356.0,
    fuel: 679661.0,
    tfuel: 133000.0,
    spd: 47.1,
    tspd: 48.3,
    vmt_tx100seg: 1457126.45818,
    trkvmt_tx100seg: 67867.04,
  },
  {
    tx100id: 1900072,
    year: 2018,
    rank: 77,
    trank: 70,
    dly: 742828.0,
    tdly: 36924.0,
    pti: 2.09,
    tpti: 2.01,
    tci: 1.68,
    ttci: 1.65,
    co2: 7434568.0,
    tco2: 1491872.0,
    cost: 1.5379936e7,
    tcost: 1930826.0,
    fuel: 371445.0,
    tfuel: 68160.0,
    spd: 21.5,
    tspd: 22.1,
    vmt_tx100seg: 106180.32364,
    trkvmt_tx100seg: 7618.19823,
  },
  {
    tx100id: 8000052,
    year: 2018,
    rank: 116,
    trank: 71,
    dly: 1346884.0,
    tdly: 86345.0,
    pti: 1.93,
    tpti: 1.87,
    tci: 1.37,
    ttci: 1.33,
    co2: 6051431.0,
    tco2: 2261493.0,
    cost: 2.7668232e7,
    tcost: 4406077.0,
    fuel: 303249.0,
    tfuel: 109149.0,
    spd: 51.5,
    tspd: 52.7,
    vmt_tx100seg: 1101991.40726,
    trkvmt_tx100seg: 116114.99033,
  },
  {
    tx100id: 8000077,
    year: 2018,
    rank: 40,
    trank: 73,
    dly: 833689.0,
    tdly: 24835.0,
    pti: 2.57,
    tpti: 2.54,
    tci: 1.74,
    ttci: 1.67,
    co2: 4377372.0,
    tco2: 884423.0,
    cost: 1.6333058e7,
    tcost: 1289240.0,
    fuel: 219618.0,
    tfuel: 41100.0,
    spd: 42.3,
    tspd: 43.8,
    vmt_tx100seg: 374092.28384,
    trkvmt_tx100seg: 18278.77021,
  },
  {
    tx100id: 8000008,
    year: 2018,
    rank: 176,
    trank: 75,
    dly: 635158.0,
    tdly: 47209.0,
    pti: 2.18,
    tpti: 2.13,
    tci: 1.46,
    ttci: 1.42,
    co2: 3532677.0,
    tco2: 1534027.0,
    cost: 1.3310076e7,
    tcost: 2435082.0,
    fuel: 172482.0,
    tfuel: 70475.0,
    spd: 48.1,
    tspd: 49.0,
    vmt_tx100seg: 367857.24781,
    trkvmt_tx100seg: 41753.16767,
  },
  {
    tx100id: 3000006,
    year: 2018,
    rank: 154,
    trank: 77,
    dly: 692290.0,
    tdly: 47061.0,
    pti: 1.78,
    tpti: 1.78,
    tci: 1.33,
    ttci: 1.28,
    co2: 2764268.0,
    tco2: 1170614.0,
    cost: 1.4260984e7,
    tcost: 2392951.0,
    fuel: 136573.0,
    tfuel: 55241.0,
    spd: 52.8,
    tspd: 54.4,
    vmt_tx100seg: 723018.77563,
    trkvmt_tx100seg: 87409.64796,
  },
  {
    tx100id: 8000136,
    year: 2018,
    rank: 68,
    trank: 78,
    dly: 1775267.0,
    tdly: 73526.0,
    pti: 1.49,
    tpti: 1.49,
    tci: 1.29,
    ttci: 1.3,
    co2: 6101560.0,
    tco2: 2071218.0,
    cost: 3.5048636e7,
    tcost: 3764726.0,
    fuel: 306118.0,
    tfuel: 99497.0,
    spd: 53.4,
    tspd: 53.3,
    vmt_tx100seg: 1578679.44262,
    trkvmt_tx100seg: 93628.86187,
  },
  {
    tx100id: 8000048,
    year: 2018,
    rank: 81,
    trank: 79,
    dly: 1765302.0,
    tdly: 79655.0,
    pti: 1.96,
    tpti: 1.94,
    tci: 1.47,
    ttci: 1.46,
    co2: 7521992.0,
    tco2: 2369839.0,
    cost: 3.5204326e7,
    tcost: 4089512.0,
    fuel: 374600.0,
    tfuel: 111524.0,
    spd: 46.9,
    tspd: 47.4,
    vmt_tx100seg: 1139726.92976,
    trkvmt_tx100seg: 79008.86519,
  },
  {
    tx100id: 8000081,
    year: 2018,
    rank: 61,
    trank: 80,
    dly: 1294148.0,
    tdly: 50306.0,
    pti: 2.25,
    tpti: 2.24,
    tci: 1.53,
    ttci: 1.49,
    co2: 5848168.0,
    tco2: 1649285.0,
    cost: 2.5602332e7,
    tcost: 2598115.0,
    fuel: 292492.0,
    tfuel: 77838.0,
    spd: 47.0,
    tspd: 48.0,
    vmt_tx100seg: 749595.64278,
    trkvmt_tx100seg: 46933.28661,
  },
  {
    tx100id: 1200156,
    year: 2018,
    rank: 54,
    trank: 82,
    dly: 2248512.0,
    tdly: 77033.0,
    pti: 2.32,
    tpti: 2.29,
    tci: 1.53,
    ttci: 1.49,
    co2: 9100845.0,
    tco2: 2455811.0,
    cost: 4.4058315e7,
    tcost: 3970123.0,
    fuel: 458664.0,
    tfuel: 117192.0,
    spd: 46.2,
    tspd: 47.4,
    vmt_tx100seg: 1306143.38518,
    trkvmt_tx100seg: 71345.93285,
  },
  {
    tx100id: 1400201,
    year: 2018,
    rank: 160,
    trank: 84,
    dly: 320023.0,
    tdly: 21394.0,
    pti: 1.87,
    tpti: 1.8,
    tci: 1.43,
    ttci: 1.31,
    co2: 3316091.0,
    tco2: 845087.0,
    cost: 6801947.0,
    tcost: 1117133.0,
    fuel: 164440.0,
    tfuel: 38332.0,
    spd: 26.4,
    tspd: 28.2,
    vmt_tx100seg: 64569.02666,
    trkvmt_tx100seg: 6393.75741,
  },
  {
    tx100id: 1200200,
    year: 2018,
    rank: 260,
    trank: 87,
    dly: 689270.0,
    tdly: 59754.0,
    pti: 1.49,
    tpti: 1.48,
    tci: 1.25,
    ttci: 1.23,
    co2: 3485743.0,
    tco2: 1645014.0,
    cost: 1.4671268e7,
    tcost: 3059910.0,
    fuel: 174212.0,
    tfuel: 79965.0,
    spd: 51.7,
    tspd: 52.5,
    vmt_tx100seg: 784507.39501,
    trkvmt_tx100seg: 104888.39546,
  },
  {
    tx100id: 8000387,
    year: 2018,
    rank: 469,
    trank: 88,
    dly: 358770.0,
    tdly: 44463.0,
    pti: 1.44,
    tpti: 1.43,
    tci: 1.29,
    ttci: 1.25,
    co2: 4988599.0,
    tco2: 2575304.0,
    cost: 8369467.0,
    tcost: 2406961.0,
    fuel: 238685.0,
    tfuel: 115522.0,
    spd: 33.1,
    tspd: 34.0,
    vmt_tx100seg: 139022.9473,
    trkvmt_tx100seg: 24588.73747,
  },
  {
    tx100id: 8000039,
    year: 2018,
    rank: 105,
    trank: 89,
    dly: 1880847.0,
    tdly: 91985.0,
    pti: 1.74,
    tpti: 1.62,
    tci: 1.33,
    ttci: 1.27,
    co2: 6153263.0,
    tco2: 1755094.0,
    cost: 3.7526379e7,
    tcost: 4624788.0,
    fuel: 309529.0,
    tfuel: 84920.0,
    spd: 53.0,
    tspd: 54.5,
    vmt_tx100seg: 1774910.76285,
    trkvmt_tx100seg: 156256.98407,
  },
  {
    tx100id: 8000038,
    year: 2018,
    rank: 126,
    trank: 93,
    dly: 1355415.0,
    tdly: 69471.0,
    pti: 1.53,
    tpti: 1.53,
    tci: 1.24,
    ttci: 1.23,
    co2: 3052555.0,
    tco2: 1033666.0,
    cost: 2.6987675e7,
    tcost: 3462722.0,
    fuel: 154126.0,
    tfuel: 51068.0,
    spd: 55.0,
    tspd: 55.2,
    vmt_tx100seg: 1530648.47627,
    trkvmt_tx100seg: 121734.45827,
  },
  {
    tx100id: 7000061,
    year: 2018,
    rank: 80,
    trank: 94,
    dly: 672054.0,
    tdly: 24909.0,
    pti: 1.68,
    tpti: 1.62,
    tci: 1.43,
    ttci: 1.36,
    co2: 6601862.0,
    tco2: 913456.0,
    cost: 1.3649308e7,
    tcost: 1293090.0,
    fuel: 332076.0,
    tfuel: 41768.0,
    spd: 22.8,
    tspd: 24.6,
    vmt_tx100seg: 112404.91909,
    trkvmt_tx100seg: 6369.9519,
  },
  {
    tx100id: 1200112,
    year: 2018,
    rank: 100,
    trank: 96,
    dly: 1279376.0,
    tdly: 54077.0,
    pti: 1.61,
    tpti: 1.63,
    tci: 1.28,
    ttci: 1.28,
    co2: 3602800.0,
    tco2: 967058.0,
    cost: 2.52104e7,
    tcost: 2715433.0,
    fuel: 183987.0,
    tfuel: 49171.0,
    spd: 54.0,
    tspd: 54.5,
    vmt_tx100seg: 1434589.92148,
    trkvmt_tx100seg: 89584.52153,
  },
  {
    tx100id: 1100023,
    year: 2018,
    rank: 632,
    trank: 99,
    dly: 287055.0,
    tdly: 38183.0,
    pti: 1.29,
    tpti: 1.29,
    tci: 1.16,
    ttci: 1.15,
    co2: 1959517.0,
    tco2: 981410.0,
    cost: 6559592.0,
    tcost: 1941424.0,
    fuel: 94548.0,
    tfuel: 44498.0,
    spd: 57.3,
    tspd: 57.3,
    vmt_tx100seg: 367276.30589,
    trkvmt_tx100seg: 68651.81557,
  },
  {
    tx100id: 1200147,
    year: 2018,
    rank: 153,
    trank: 101,
    dly: 1274519.0,
    tdly: 67550.0,
    pti: 1.36,
    tpti: 1.35,
    tci: 1.18,
    ttci: 1.16,
    co2: 1155029.0,
    tco2: 503132.0,
    cost: 2.529175e7,
    tcost: 3347712.0,
    fuel: 76373.0,
    tfuel: 42375.0,
    spd: 56.2,
    tspd: 57.1,
    vmt_tx100seg: 1944169.71213,
    trkvmt_tx100seg: 164986.004,
  },
  {
    tx100id: 1200165,
    year: 2018,
    rank: 308,
    trank: 102,
    dly: 594033.0,
    tdly: 46806.0,
    pti: 1.45,
    tpti: 1.36,
    tci: 1.21,
    ttci: 1.18,
    co2: 1928927.0,
    tco2: 851635.0,
    cost: 1.2391195e7,
    tcost: 2355088.0,
    fuel: 99789.0,
    tfuel: 44327.0,
    spd: 55.8,
    tspd: 56.9,
    vmt_tx100seg: 837506.35714,
    trkvmt_tx100seg: 106086.4879,
  },
  {
    tx100id: 8000040,
    year: 2018,
    rank: 362,
    trank: 103,
    dly: 760017.0,
    tdly: 66030.0,
    pti: 1.39,
    tpti: 1.37,
    tci: 1.16,
    ttci: 1.14,
    co2: 1908660.0,
    tco2: 944810.0,
    cost: 1.5976986e7,
    tcost: 3294912.0,
    fuel: 99435.0,
    tfuel: 50110.0,
    spd: 57.3,
    tspd: 58.2,
    vmt_tx100seg: 1420192.94046,
    trkvmt_tx100seg: 202730.08944,
  },
  {
    tx100id: 1200148,
    year: 2018,
    rank: 138,
    trank: 105,
    dly: 967939.0,
    tdly: 46277.0,
    pti: 1.52,
    tpti: 1.49,
    tci: 1.23,
    ttci: 1.2,
    co2: 2093568.0,
    tco2: 706625.0,
    cost: 1.9167662e7,
    tcost: 2313518.0,
    fuel: 108129.0,
    tfuel: 37334.0,
    spd: 55.0,
    tspd: 56.3,
    vmt_tx100seg: 1462857.41651,
    trkvmt_tx100seg: 118870.04797,
  },
  {
    tx100id: 1000013,
    year: 2018,
    rank: 114,
    trank: 114,
    dly: 650414.0,
    tdly: 26585.0,
    pti: 1.49,
    tpti: 1.48,
    tci: 1.21,
    ttci: 1.19,
    co2: 4088237.0,
    tco2: 1154826.0,
    cost: 1.3028203e7,
    tcost: 1399171.0,
    fuel: 202148.0,
    tfuel: 52270.0,
    spd: 50.7,
    tspd: 51.5,
    vmt_tx100seg: 679748.76195,
    trkvmt_tx100seg: 42511.26974,
  },
  {
    tx100id: 1010102,
    year: 2018,
    rank: 75,
    trank: 116,
    dly: 971102.0,
    tdly: 29821.0,
    pti: 1.9,
    tpti: 1.86,
    tci: 1.45,
    ttci: 1.41,
    co2: 5690585.0,
    tco2: 1270209.0,
    cost: 1.9112745e7,
    tcost: 1567097.0,
    fuel: 283089.0,
    tfuel: 57445.0,
    spd: 46.3,
    tspd: 47.4,
    vmt_tx100seg: 475080.962,
    trkvmt_tx100seg: 22262.91052,
  },
  {
    tx100id: 8000198,
    year: 2018,
    rank: 244,
    trank: 124,
    dly: 741353.0,
    tdly: 41803.0,
    pti: 1.35,
    tpti: 1.32,
    tci: 1.23,
    ttci: 1.21,
    co2: 6872489.0,
    tco2: 2157547.0,
    cost: 1.5432338e7,
    tcost: 2234332.0,
    fuel: 338589.0,
    tfuel: 97962.0,
    spd: 38.3,
    tspd: 38.8,
    vmt_tx100seg: 287454.57803,
    trkvmt_tx100seg: 22503.56743,
  },
  {
    tx100id: 8000133,
    year: 2018,
    rank: 331,
    trank: 132,
    dly: 578207.0,
    tdly: 38242.0,
    pti: 1.44,
    tpti: 1.42,
    tci: 1.16,
    ttci: 1.15,
    co2: 882198.0,
    tco2: 361214.0,
    cost: 1.1734377e7,
    tcost: 1892356.0,
    fuel: 48950.0,
    tfuel: 22289.0,
    spd: 57.5,
    tspd: 58.1,
    vmt_tx100seg: 1052347.55413,
    trkvmt_tx100seg: 115351.94997,
  },
  {
    tx100id: 3000012,
    year: 2018,
    rank: 53,
    trank: 134,
    dly: 1065848.0,
    tdly: 21872.0,
    pti: 2.06,
    tpti: 2.03,
    tci: 1.63,
    ttci: 1.62,
    co2: 7951955.0,
    tco2: 822485.0,
    cost: 2.0843904e7,
    tcost: 1137482.0,
    fuel: 401489.0,
    tfuel: 37627.0,
    spd: 40.8,
    tspd: 40.6,
    vmt_tx100seg: 227671.95644,
    trkvmt_tx100seg: 6368.25099,
  },
  {
    tx100id: 8000050,
    year: 2018,
    rank: 224,
    trank: 141,
    dly: 707306.0,
    tdly: 34404.0,
    pti: 1.51,
    tpti: 1.58,
    tci: 1.21,
    ttci: 1.2,
    co2: 3379703.0,
    tco2: 1003003.0,
    cost: 1.4219419e7,
    tcost: 1763200.0,
    fuel: 168055.0,
    tfuel: 46749.0,
    spd: 55.8,
    tspd: 56.1,
    vmt_tx100seg: 741445.92479,
    trkvmt_tx100seg: 54736.70018,
  },
  {
    tx100id: 1200159,
    year: 2018,
    rank: 157,
    trank: 142,
    dly: 789974.0,
    tdly: 31469.0,
    pti: 1.5,
    tpti: 1.49,
    tci: 1.31,
    ttci: 1.3,
    co2: 2271649.0,
    tco2: 754961.0,
    cost: 1.5516122e7,
    tcost: 1602124.0,
    fuel: 116884.0,
    tfuel: 38591.0,
    spd: 51.8,
    tspd: 52.3,
    vmt_tx100seg: 690630.50844,
    trkvmt_tx100seg: 40844.8822,
  },
  {
    tx100id: 8000011,
    year: 2018,
    rank: 174,
    trank: 149,
    dly: 688421.0,
    tdly: 28257.0,
    pti: 1.49,
    tpti: 1.46,
    tci: 1.22,
    ttci: 1.2,
    co2: 2098277.0,
    tco2: 533753.0,
    cost: 1.3558033e7,
    tcost: 1422320.0,
    fuel: 106984.0,
    tfuel: 27013.0,
    spd: 55.0,
    tspd: 55.8,
    vmt_tx100seg: 902364.78389,
    trkvmt_tx100seg: 60126.87459,
  },
  {
    tx100id: 1900038,
    year: 2018,
    rank: 816,
    trank: 153,
    dly: 232861.0,
    tdly: 28157.0,
    pti: 1.41,
    tpti: 1.39,
    tci: 1.17,
    ttci: 1.16,
    co2: 1286387.0,
    tco2: 732809.0,
    cost: 5204572.0,
    tcost: 1433661.0,
    fuel: 62292.0,
    tfuel: 33869.0,
    spd: 54.4,
    tspd: 54.6,
    vmt_tx100seg: 391451.93857,
    trkvmt_tx100seg: 67765.35026,
  },
  {
    tx100id: 8000053,
    year: 2018,
    rank: 591,
    trank: 166,
    dly: 531232.0,
    tdly: 44056.0,
    pti: 1.57,
    tpti: 1.47,
    tci: 1.21,
    ttci: 1.16,
    co2: 2011288.0,
    tco2: 837442.0,
    cost: 1.11734e7,
    tcost: 2215515.0,
    fuel: 101174.0,
    tfuel: 41143.0,
    spd: 56.7,
    tspd: 57.6,
    vmt_tx100seg: 868913.32351,
    trkvmt_tx100seg: 134698.11799,
  },
  {
    tx100id: 8000061,
    year: 2018,
    rank: 220,
    trank: 178,
    dly: 749444.0,
    tdly: 30786.0,
    pti: 1.81,
    tpti: 1.75,
    tci: 1.31,
    ttci: 1.27,
    co2: 4262137.0,
    tco2: 1126463.0,
    cost: 1.4971442e7,
    tcost: 1599596.0,
    fuel: 213500.0,
    tfuel: 52316.0,
    spd: 53.5,
    tspd: 54.5,
    vmt_tx100seg: 652345.07534,
    trkvmt_tx100seg: 44514.13497,
  },
  {
    tx100id: 8000012,
    year: 2018,
    rank: 325,
    trank: 199,
    dly: 683409.0,
    tdly: 33627.0,
    pti: 1.61,
    tpti: 1.59,
    tci: 1.24,
    ttci: 1.21,
    co2: 2140517.0,
    tco2: 730085.0,
    cost: 1.3631099e7,
    tcost: 1700803.0,
    fuel: 107801.0,
    tfuel: 35800.0,
    spd: 55.6,
    tspd: 56.5,
    vmt_tx100seg: 991628.335,
    trkvmt_tx100seg: 81065.80008,
  },
  {
    tx100id: 1100022,
    year: 2018,
    rank: 1267,
    trank: 202,
    dly: 58961.0,
    tdly: 14657.0,
    pti: 1.17,
    tpti: 1.18,
    tci: 1.11,
    ttci: 1.1,
    co2: 882619.0,
    tco2: 520326.0,
    cost: 1602629.0,
    tcost: 759693.0,
    fuel: 41865.0,
    tfuel: 23367.0,
    spd: 24.9,
    tspd: 25.8,
    vmt_tx100seg: 28969.2954,
    trkvmt_tx100seg: 8225.16452,
  },
  {
    tx100id: 8000060,
    year: 2018,
    rank: 348,
    trank: 259,
    dly: 564786.0,
    tdly: 24412.0,
    pti: 1.47,
    tpti: 1.45,
    tci: 1.21,
    ttci: 1.19,
    co2: 2557437.0,
    tco2: 830415.0,
    cost: 1.1246132e7,
    tcost: 1262648.0,
    fuel: 127490.0,
    tfuel: 39050.0,
    spd: 53.9,
    tspd: 54.6,
    vmt_tx100seg: 760493.4596,
    trkvmt_tx100seg: 51709.02297,
  },
  {
    tx100id: 2400012,
    year: 2018,
    rank: 1078,
    trank: 261,
    dly: 122394.0,
    tdly: 15825.0,
    pti: 1.21,
    tpti: 1.06,
    tci: 1.05,
    ttci: 1.04,
    co2: 124152.0,
    tco2: 53413.0,
    cost: 2709272.0,
    tcost: 770401.0,
    fuel: 7083.0,
    tfuel: 3473.0,
    spd: 61.8,
    tspd: 62.5,
    vmt_tx100seg: 534653.64198,
    trkvmt_tx100seg: 116059.17208,
  },
  {
    tx100id: 1200094,
    year: 2018,
    rank: 1045,
    trank: 352,
    dly: 231491.0,
    tdly: 22586.0,
    pti: 1.27,
    tpti: 1.23,
    tci: 1.09,
    ttci: 1.07,
    co2: 601111.0,
    tco2: 265083.0,
    cost: 4946331.0,
    tcost: 1123769.0,
    fuel: 32894.0,
    tfuel: 15744.0,
    spd: 59.7,
    tspd: 60.4,
    vmt_tx100seg: 654233.45614,
    trkvmt_tx100seg: 100414.59466,
  },
  {
    tx100id: 2400013,
    year: 2018,
    rank: 1310,
    trank: 569,
    dly: 34450.0,
    tdly: 4551.0,
    pti: 1.14,
    tpti: 1.05,
    tci: 1.04,
    ttci: 1.03,
    co2: 19491.0,
    tco2: 6763.0,
    cost: 763961.0,
    tcost: 220785.0,
    fuel: 1307.0,
    tfuel: 655.0,
    spd: 62.6,
    tspd: 63.2,
    vmt_tx100seg: 231591.66552,
    trkvmt_tx100seg: 53820.24452,
  },
  {
    tx100id: 1010103,
    year: 2018,
    rank: 836,
    trank: 661,
    dly: 252242.0,
    tdly: 10340.0,
    pti: 1.25,
    tpti: 1.24,
    tci: 1.1,
    ttci: 1.1,
    co2: 225440.0,
    tco2: 94879.0,
    cost: 4911825.0,
    tcost: 513752.0,
    fuel: 13867.0,
    tfuel: 7043.0,
    spd: 59.8,
    tspd: 60.0,
    vmt_tx100seg: 671262.50023,
    trkvmt_tx100seg: 40987.09776,
  },
  {
    tx100id: 8000130,
    year: 2018,
    rank: 609,
    trank: 747,
    dly: 405186.0,
    tdly: 10404.0,
    pti: 1.46,
    tpti: 1.4,
    tci: 1.19,
    ttci: 1.17,
    co2: 1104335.0,
    tco2: 229523.0,
    cost: 7779814.0,
    tcost: 528375.0,
    fuel: 57279.0,
    tfuel: 12126.0,
    spd: 54.0,
    tspd: 54.4,
    vmt_tx100seg: 520954.83218,
    trkvmt_tx100seg: 21664.28176,
  },
  {
    tx100id: 3000059,
    year: 2018,
    rank: 1280,
    trank: 769,
    dly: 213078.0,
    tdly: 18630.0,
    pti: 1.17,
    tpti: 1.08,
    tci: 1.04,
    ttci: 1.04,
    co2: 375244.0,
    tco2: 136082.0,
    cost: 4465982.0,
    tcost: 916033.0,
    fuel: 20459.0,
    tfuel: 8226.0,
    spd: 62.7,
    tspd: 63.2,
    vmt_tx100seg: 1365815.87756,
    trkvmt_tx100seg: 218291.59195,
  },
  {
    tx100id: 8000149,
    year: 2018,
    rank: 1339,
    trank: 871,
    dly: 135620.0,
    tdly: 12453.0,
    pti: 1.14,
    tpti: 1.09,
    tci: 1.06,
    ttci: 1.06,
    co2: 542238.0,
    tco2: 370754.0,
    cost: 2892423.0,
    tcost: 639058.0,
    fuel: 27181.0,
    tfuel: 17239.0,
    spd: 59.6,
    tspd: 59.6,
    vmt_tx100seg: 342514.1065,
    trkvmt_tx100seg: 44068.79079,
  },
  {
    tx100id: 2400030,
    year: 2018,
    rank: 1319,
    trank: 977,
    dly: 70020.0,
    tdly: 5138.0,
    pti: 1.22,
    tpti: 1.22,
    tci: 1.13,
    ttci: 1.13,
    co2: 625158.0,
    tco2: 290747.0,
    cost: 1490057.0,
    tcost: 277354.0,
    fuel: 30163.0,
    tfuel: 13097.0,
    spd: 42.9,
    tspd: 43.3,
    vmt_tx100seg: 66063.94079,
    trkvmt_tx100seg: 6834.6384,
  },
  {
    tx100id: 2400010,
    year: 2018,
    rank: 1568,
    trank: 1164,
    dly: 16910.0,
    tdly: 3572.0,
    pti: 1.02,
    tpti: 1.01,
    tci: 1.01,
    ttci: 1.0,
    co2: 0.0,
    tco2: 0.0,
    cost: 414117.0,
    tcost: 172427.0,
    fuel: 131.0,
    tfuel: 131.0,
    spd: 64.7,
    tspd: 64.7,
    vmt_tx100seg: 434513.09776,
    trkvmt_tx100seg: 137924.67955,
  },
  {
    tx100id: 8000131,
    year: 2018,
    rank: 789,
    trank: 1234,
    dly: 261295.0,
    tdly: 3990.0,
    pti: 1.58,
    tpti: 1.56,
    tci: 1.21,
    ttci: 1.19,
    co2: 659918.0,
    tco2: 95413.0,
    cost: 4927719.0,
    tcost: 202785.0,
    fuel: 33539.0,
    tfuel: 4702.0,
    spd: 56.5,
    tspd: 57.4,
    vmt_tx100seg: 425150.43968,
    trkvmt_tx100seg: 11053.33336,
  },
  {
    tx100id: 1400800,
    year: 2018,
    rank: 1187,
    trank: 1341,
    dly: 30369.0,
    tdly: 759.0,
    pti: 1.23,
    tpti: 1.23,
    tci: 1.15,
    ttci: 1.14,
    co2: 301027.0,
    tco2: 30540.0,
    cost: 606190.0,
    tcost: 39698.0,
    fuel: 15191.0,
    tfuel: 1387.0,
    spd: 24.4,
    tspd: 24.5,
    vmt_tx100seg: 11204.74764,
    trkvmt_tx100seg: 359.22547,
  },
  {
    tx100id: 3000058,
    year: 2018,
    rank: 1629,
    trank: 1565,
    dly: 16834.0,
    tdly: 1841.0,
    pti: 1.01,
    tpti: 1.01,
    tci: 1.0,
    ttci: 1.0,
    co2: 0.0,
    tco2: 0.0,
    cost: 360634.0,
    tcost: 88974.0,
    fuel: 111.0,
    tfuel: 111.0,
    spd: 64.9,
    tspd: 64.9,
    vmt_tx100seg: 828001.64349,
    trkvmt_tx100seg: 130913.30629,
  },
  {
    tx100id: 1900086,
    year: 2018,
    rank: 1693,
    trank: 1635,
    dly: 4044.0,
    tdly: 666.0,
    pti: 1.02,
    tpti: 1.02,
    tci: 1.01,
    ttci: 1.01,
    co2: 0.0,
    tco2: 0.0,
    cost: 93367.0,
    tcost: 32153.0,
    fuel: 36.0,
    tfuel: 36.0,
    spd: 64.6,
    tspd: 64.7,
    vmt_tx100seg: 235575.81174,
    trkvmt_tx100seg: 63841.28033,
  },
  {
    tx100id: 3000004,
    year: 2019,
    rank: 2,
    trank: 1,
    dly: 9704195.0,
    tdly: 696586.0,
    pti: 4.15,
    tpti: 4.33,
    tci: 2.49,
    ttci: 2.43,
    co2: 7.7786435e7,
    tco2: 2.6392471e7,
    cost: 2.12697534e8,
    tcost: 3.8436842e7,
    fuel: 3812794.0,
    tfuel: 1189476.0,
    spd: 36.1,
    tspd: 36.8,
    vmt_tx100seg: 1381150.107,
    trkvmt_tx100seg: 137659.958,
  },
  {
    tx100id: 1200140,
    year: 2019,
    rank: 4,
    trank: 2,
    dly: 2909371.0,
    tdly: 180960.0,
    pti: 3.63,
    tpti: 3.7,
    tci: 2.4,
    ttci: 2.43,
    co2: 2.1334116e7,
    tco2: 6859501.0,
    cost: 6.2612621e7,
    tcost: 9974806.0,
    fuel: 1049863.0,
    tfuel: 310722.0,
    spd: 34.2,
    tspd: 32.0,
    vmt_tx100seg: 451623.368,
    trkvmt_tx100seg: 33644.34,
  },
  {
    tx100id: 1400055,
    year: 2019,
    rank: 191,
    trank: 3,
    dly: 301437.0,
    tdly: 157109.0,
    pti: 2.08,
    tpti: 1.82,
    tci: 1.47,
    ttci: 1.35,
    co2: 5794783.0,
    tco2: 4883765.0,
    cost: 1.1338781e7,
    tcost: 8531772.0,
    fuel: 267397.0,
    tfuel: 219716.0,
    spd: 31.8,
    tspd: 30.4,
    vmt_tx100seg: 54122.401,
    trkvmt_tx100seg: 35064.98,
  },
  {
    tx100id: 1200184,
    year: 2019,
    rank: 1,
    trank: 4,
    dly: 5091869.0,
    tdly: 196818.0,
    pti: 3.39,
    tpti: 3.82,
    tci: 2.32,
    ttci: 2.44,
    co2: 3.225157e7,
    tco2: 7512788.0,
    cost: 1.05116332e8,
    tcost: 1.0841408e7,
    fuel: 1606933.0,
    tfuel: 342723.0,
    spd: 34.1,
    tspd: 32.1,
    vmt_tx100seg: 791522.165,
    trkvmt_tx100seg: 35621.569,
  },
  {
    tx100id: 1200143,
    year: 2019,
    rank: 6,
    trank: 5,
    dly: 6077917.0,
    tdly: 427921.0,
    pti: 2.31,
    tpti: 2.53,
    tci: 1.75,
    ttci: 1.83,
    co2: 4.7359606e7,
    tco2: 1.8302707e7,
    cost: 1.32756587e8,
    tcost: 2.3878897e7,
    fuel: 2308595.0,
    tfuel: 825410.0,
    spd: 41.2,
    tspd: 37.4,
    vmt_tx100seg: 1561456.602,
    trkvmt_tx100seg: 125542.89,
  },
  {
    tx100id: 3000003,
    year: 2019,
    rank: 10,
    trank: 6,
    dly: 2529972.0,
    tdly: 194586.0,
    pti: 2.88,
    tpti: 2.84,
    tci: 1.84,
    ttci: 1.8,
    co2: 1.7032579e7,
    tco2: 7123124.0,
    cost: 5.5435234e7,
    tcost: 1.0710524e7,
    fuel: 827719.0,
    tfuel: 320789.0,
    spd: 44.3,
    tspd: 43.6,
    vmt_tx100seg: 622330.592,
    trkvmt_tx100seg: 69051.202,
  },
  {
    tx100id: 1200168,
    year: 2019,
    rank: 13,
    trank: 7,
    dly: 855105.0,
    tdly: 74161.0,
    pti: 2.62,
    tpti: 2.56,
    tci: 1.83,
    ttci: 1.82,
    co2: 7729948.0,
    tco2: 3149814.0,
    cost: 1.9260277e7,
    tcost: 4135575.0,
    fuel: 375761.0,
    tfuel: 142014.0,
    spd: 37.0,
    tspd: 34.6,
    vmt_tx100seg: 259733.362,
    trkvmt_tx100seg: 29072.048,
  },
  {
    tx100id: 1200171,
    year: 2019,
    rank: 9,
    trank: 8,
    dly: 2127898.0,
    tdly: 128095.0,
    pti: 2.77,
    tpti: 2.81,
    tci: 1.75,
    ttci: 1.71,
    co2: 1.1882357e7,
    tco2: 4617685.0,
    cost: 4.518967e7,
    tcost: 7039384.0,
    fuel: 587169.0,
    tfuel: 209241.0,
    spd: 43.1,
    tspd: 40.6,
    vmt_tx100seg: 823154.73,
    trkvmt_tx100seg: 67692.501,
  },
  {
    tx100id: 8000020,
    year: 2019,
    rank: 8,
    trank: 9,
    dly: 3639734.0,
    tdly: 209201.0,
    pti: 2.42,
    tpti: 2.53,
    tci: 1.66,
    ttci: 1.68,
    co2: 2.6938995e7,
    tco2: 9121211.0,
    cost: 7.7821217e7,
    tcost: 1.1690354e7,
    fuel: 1321588.0,
    tfuel: 412248.0,
    spd: 39.9,
    tspd: 39.0,
    vmt_tx100seg: 1204652.156,
    trkvmt_tx100seg: 91557.386,
  },
  {
    tx100id: 1200139,
    year: 2019,
    rank: 3,
    trank: 10,
    dly: 5959656.0,
    tdly: 201357.0,
    pti: 3.0,
    tpti: 3.36,
    tci: 1.99,
    ttci: 2.08,
    co2: 3.5618078e7,
    tco2: 7509291.0,
    cost: 1.21852281e8,
    tcost: 1.1081278e7,
    fuel: 1778804.0,
    tfuel: 340853.0,
    spd: 40.8,
    tspd: 39.0,
    vmt_tx100seg: 1381208.704,
    trkvmt_tx100seg: 56819.389,
  },
  {
    tx100id: 1200183,
    year: 2019,
    rank: 11,
    trank: 11,
    dly: 3767383.0,
    tdly: 216328.0,
    pti: 2.79,
    tpti: 2.81,
    tci: 1.85,
    ttci: 1.8,
    co2: 2.4851825e7,
    tco2: 8766566.0,
    cost: 8.0159388e7,
    tcost: 1.2006989e7,
    fuel: 1222634.0,
    tfuel: 396096.0,
    spd: 40.8,
    tspd: 38.8,
    vmt_tx100seg: 1173254.293,
    trkvmt_tx100seg: 89843.027,
  },
  {
    tx100id: 8000010,
    year: 2019,
    rank: 16,
    trank: 12,
    dly: 1655772.0,
    tdly: 114196.0,
    pti: 2.03,
    tpti: 2.21,
    tci: 1.62,
    ttci: 1.71,
    co2: 1.2997051e7,
    tco2: 4944151.0,
    cost: 3.6107359e7,
    tcost: 6384009.0,
    fuel: 633327.0,
    tfuel: 222347.0,
    spd: 38.6,
    tspd: 33.8,
    vmt_tx100seg: 472229.001,
    trkvmt_tx100seg: 34201.0,
  },
  {
    tx100id: 1200142,
    year: 2019,
    rank: 12,
    trank: 13,
    dly: 5356624.0,
    tdly: 290643.0,
    pti: 1.99,
    tpti: 2.03,
    tci: 1.51,
    ttci: 1.53,
    co2: 2.6877603e7,
    tco2: 1.1398153e7,
    cost: 1.12311334e8,
    tcost: 1.6070743e7,
    fuel: 1322152.0,
    tfuel: 518097.0,
    spd: 44.8,
    tspd: 40.8,
    vmt_tx100seg: 2306663.897,
    trkvmt_tx100seg: 149297.74,
  },
  {
    tx100id: 1200169,
    year: 2019,
    rank: 18,
    trank: 14,
    dly: 2637794.0,
    tdly: 170377.0,
    pti: 2.1,
    tpti: 2.11,
    tci: 1.58,
    ttci: 1.59,
    co2: 1.6423366e7,
    tco2: 6521728.0,
    cost: 5.6588702e7,
    tcost: 9402820.0,
    fuel: 806212.0,
    tfuel: 296220.0,
    spd: 43.8,
    tspd: 42.2,
    vmt_tx100seg: 1258991.65,
    trkvmt_tx100seg: 103453.653,
  },
  {
    tx100id: 8000112,
    year: 2019,
    rank: 7,
    trank: 15,
    dly: 6349489.0,
    tdly: 259521.0,
    pti: 2.68,
    tpti: 2.65,
    tci: 1.86,
    ttci: 1.82,
    co2: 2.8251118e7,
    tco2: 8757984.0,
    cost: 1.29993668e8,
    tcost: 1.4188898e7,
    fuel: 1412033.0,
    tfuel: 403223.0,
    spd: 38.7,
    tspd: 39.1,
    vmt_tx100seg: 1922981.878,
    trkvmt_tx100seg: 107108.023,
  },
  {
    tx100id: 1200170,
    year: 2019,
    rank: 14,
    trank: 16,
    dly: 3373944.0,
    tdly: 185319.0,
    pti: 2.11,
    tpti: 2.16,
    tci: 1.49,
    ttci: 1.5,
    co2: 1.4763486e7,
    tco2: 6121946.0,
    cost: 7.0600572e7,
    tcost: 1.0114659e7,
    fuel: 756365.0,
    tfuel: 284406.0,
    spd: 47.0,
    tspd: 44.2,
    vmt_tx100seg: 1999997.091,
    trkvmt_tx100seg: 139409.683,
  },
  {
    tx100id: 8000051,
    year: 2019,
    rank: 21,
    trank: 17,
    dly: 3398642.0,
    tdly: 219678.0,
    pti: 2.25,
    tpti: 2.28,
    tci: 1.67,
    ttci: 1.66,
    co2: 2.4646923e7,
    tco2: 9312243.0,
    cost: 7.3376807e7,
    tcost: 1.2252742e7,
    fuel: 1202227.0,
    tfuel: 419375.0,
    spd: 41.1,
    tspd: 40.0,
    vmt_tx100seg: 1158774.585,
    trkvmt_tx100seg: 100145.26,
  },
  {
    tx100id: 8000196,
    year: 2019,
    rank: 110,
    trank: 18,
    dly: 554712.0,
    tdly: 99221.0,
    pti: 1.61,
    tpti: 1.74,
    tci: 1.41,
    ttci: 1.42,
    co2: 7540073.0,
    tco2: 4176512.0,
    cost: 1.4448705e7,
    tcost: 5534212.0,
    fuel: 359018.0,
    tfuel: 187358.0,
    spd: 31.7,
    tspd: 30.1,
    vmt_tx100seg: 104521.858,
    trkvmt_tx100seg: 21482.825,
  },
  {
    tx100id: 8000117,
    year: 2019,
    rank: 23,
    trank: 19,
    dly: 963200.0,
    tdly: 62155.0,
    pti: 2.6,
    tpti: 2.55,
    tci: 1.66,
    ttci: 1.63,
    co2: 7152711.0,
    tco2: 2647614.0,
    cost: 2.0814058e7,
    tcost: 3467747.0,
    fuel: 349317.0,
    tfuel: 119320.0,
    spd: 42.1,
    tspd: 42.9,
    vmt_tx100seg: 383303.75,
    trkvmt_tx100seg: 36629.823,
  },
  {
    tx100id: 8000135,
    year: 2019,
    rank: 22,
    trank: 20,
    dly: 2826347.0,
    tdly: 180033.0,
    pti: 2.41,
    tpti: 2.59,
    tci: 1.62,
    ttci: 1.66,
    co2: 1.3481126e7,
    tco2: 5653257.0,
    cost: 6.0045789e7,
    tcost: 9794744.0,
    fuel: 677072.0,
    tfuel: 265465.0,
    spd: 47.2,
    tspd: 44.7,
    vmt_tx100seg: 1327703.086,
    trkvmt_tx100seg: 103885.945,
  },
  {
    tx100id: 1200196,
    year: 2019,
    rank: 19,
    trank: 21,
    dly: 2203480.0,
    tdly: 124199.0,
    pti: 2.43,
    tpti: 2.72,
    tci: 1.66,
    ttci: 1.76,
    co2: 1.609623e7,
    tco2: 4971298.0,
    cost: 4.7006805e7,
    tcost: 6879752.0,
    fuel: 793274.0,
    tfuel: 225211.0,
    spd: 42.5,
    tspd: 39.5,
    vmt_tx100seg: 800145.138,
    trkvmt_tx100seg: 52790.388,
  },
  {
    tx100id: 1900019,
    year: 2019,
    rank: 28,
    trank: 23,
    dly: 1371272.0,
    tdly: 106128.0,
    pti: 2.2,
    tpti: 2.14,
    tci: 1.52,
    ttci: 1.5,
    co2: 1.1350605e7,
    tco2: 4598793.0,
    cost: 3.0342964e7,
    tcost: 5930140.0,
    fuel: 551990.0,
    tfuel: 207336.0,
    spd: 43.3,
    tspd: 43.1,
    vmt_tx100seg: 756809.481,
    trkvmt_tx100seg: 83884.072,
  },
  {
    tx100id: 8000134,
    year: 2019,
    rank: 25,
    trank: 24,
    dly: 2420038.0,
    tdly: 155829.0,
    pti: 2.4,
    tpti: 2.45,
    tci: 1.58,
    ttci: 1.62,
    co2: 1.2142024e7,
    tco2: 5232072.0,
    cost: 5.1545101e7,
    tcost: 8521493.0,
    fuel: 606893.0,
    tfuel: 243925.0,
    spd: 45.6,
    tspd: 44.1,
    vmt_tx100seg: 1219191.353,
    trkvmt_tx100seg: 100461.088,
  },
  {
    tx100id: 8000009,
    year: 2019,
    rank: 41,
    trank: 25,
    dly: 1812916.0,
    tdly: 158047.0,
    pti: 1.77,
    tpti: 1.9,
    tci: 1.42,
    ttci: 1.5,
    co2: 1.5817165e7,
    tco2: 7380481.0,
    cost: 4.0786536e7,
    tcost: 8906457.0,
    fuel: 762443.0,
    tfuel: 331794.0,
    spd: 46.1,
    tspd: 38.2,
    vmt_tx100seg: 885651.08,
    trkvmt_tx100seg: 74612.366,
  },
  {
    tx100id: 8000021,
    year: 2019,
    rank: 26,
    trank: 26,
    dly: 1752075.0,
    tdly: 113106.0,
    pti: 2.22,
    tpti: 2.31,
    tci: 1.56,
    ttci: 1.57,
    co2: 1.1814084e7,
    tco2: 4333697.0,
    cost: 3.7701621e7,
    tcost: 6242460.0,
    fuel: 579537.0,
    tfuel: 196604.0,
    spd: 44.1,
    tspd: 41.9,
    vmt_tx100seg: 877694.698,
    trkvmt_tx100seg: 71755.371,
  },
  {
    tx100id: 1200172,
    year: 2019,
    rank: 38,
    trank: 27,
    dly: 2870567.0,
    tdly: 211144.0,
    pti: 2.19,
    tpti: 2.31,
    tci: 1.48,
    ttci: 1.51,
    co2: 1.3499828e7,
    tco2: 6278295.0,
    cost: 6.1839601e7,
    tcost: 1.1445252e7,
    fuel: 664937.0,
    tfuel: 291451.0,
    spd: 49.8,
    tspd: 48.1,
    vmt_tx100seg: 1860863.306,
    trkvmt_tx100seg: 176956.243,
  },
  {
    tx100id: 8000136,
    year: 2019,
    rank: 15,
    trank: 28,
    dly: 4050985.0,
    tdly: 174499.0,
    pti: 1.97,
    tpti: 2.01,
    tci: 1.58,
    ttci: 1.58,
    co2: 1.6323682e7,
    tco2: 6524762.0,
    cost: 8.3015324e7,
    tcost: 9612454.0,
    fuel: 820011.0,
    tfuel: 296142.0,
    spd: 43.8,
    tspd: 41.0,
    vmt_tx100seg: 1721504.664,
    trkvmt_tx100seg: 93602.588,
  },
  {
    tx100id: 1900035,
    year: 2019,
    rank: 42,
    trank: 30,
    dly: 1331349.0,
    tdly: 100845.0,
    pti: 2.25,
    tpti: 2.18,
    tci: 1.48,
    ttci: 1.45,
    co2: 7383626.0,
    tco2: 3265320.0,
    cost: 2.892912e7,
    tcost: 5506462.0,
    fuel: 366557.0,
    tfuel: 153671.0,
    spd: 48.5,
    tspd: 47.3,
    vmt_tx100seg: 873232.258,
    trkvmt_tx100seg: 96347.763,
  },
  {
    tx100id: 1200141,
    year: 2019,
    rank: 17,
    trank: 31,
    dly: 1502114.0,
    tdly: 66230.0,
    pti: 2.31,
    tpti: 2.39,
    tci: 1.58,
    ttci: 1.6,
    co2: 9113784.0,
    tco2: 2942281.0,
    cost: 3.1216268e7,
    tcost: 3705577.0,
    fuel: 449708.0,
    tfuel: 133370.0,
    spd: 42.8,
    tspd: 39.5,
    vmt_tx100seg: 649101.21,
    trkvmt_tx100seg: 32125.152,
  },
  {
    tx100id: 1200186,
    year: 2019,
    rank: 34,
    trank: 32,
    dly: 1835026.0,
    tdly: 118570.0,
    pti: 1.96,
    tpti: 2.07,
    tci: 1.51,
    ttci: 1.55,
    co2: 1.0469948e7,
    tco2: 3700825.0,
    cost: 3.9257534e7,
    tcost: 6451335.0,
    fuel: 523664.0,
    tfuel: 175966.0,
    spd: 44.7,
    tspd: 44.2,
    vmt_tx100seg: 1014402.846,
    trkvmt_tx100seg: 81346.816,
  },
  {
    tx100id: 1200167,
    year: 2019,
    rank: 58,
    trank: 34,
    dly: 1059875.0,
    tdly: 96099.0,
    pti: 1.92,
    tpti: 1.94,
    tci: 1.38,
    ttci: 1.41,
    co2: 7187268.0,
    tco2: 3504831.0,
    cost: 2.3697776e7,
    tcost: 5284360.0,
    fuel: 352408.0,
    tfuel: 159833.0,
    spd: 49.7,
    tspd: 48.4,
    vmt_tx100seg: 832047.149,
    trkvmt_tx100seg: 92502.528,
  },
  {
    tx100id: 8000113,
    year: 2019,
    rank: 24,
    trank: 35,
    dly: 2604952.0,
    tdly: 126761.0,
    pti: 2.12,
    tpti: 2.38,
    tci: 1.53,
    ttci: 1.61,
    co2: 1.0186573e7,
    tco2: 4074146.0,
    cost: 5.3823555e7,
    tcost: 6905337.0,
    fuel: 520584.0,
    tfuel: 188482.0,
    spd: 45.3,
    tspd: 42.3,
    vmt_tx100seg: 1422927.179,
    trkvmt_tx100seg: 77726.65,
  },
  {
    tx100id: 1900036,
    year: 2019,
    rank: 80,
    trank: 38,
    dly: 676048.0,
    tdly: 68785.0,
    pti: 1.73,
    tpti: 1.78,
    tci: 1.32,
    ttci: 1.34,
    co2: 4612591.0,
    tco2: 2661713.0,
    cost: 1.540395e7,
    tcost: 3801175.0,
    fuel: 242211.0,
    tfuel: 120605.0,
    spd: 56.1,
    tspd: 50.1,
    vmt_tx100seg: 625377.182,
    trkvmt_tx100seg: 72980.476,
  },
  {
    tx100id: 3000007,
    year: 2019,
    rank: 65,
    trank: 39,
    dly: 1113167.0,
    tdly: 101426.0,
    pti: 1.72,
    tpti: 1.69,
    tci: 1.36,
    ttci: 1.34,
    co2: 4426812.0,
    tco2: 2288930.0,
    cost: 2.449614e7,
    tcost: 5408318.0,
    fuel: 218982.0,
    tfuel: 108839.0,
    spd: 51.9,
    tspd: 52.1,
    vmt_tx100seg: 720988.131,
    trkvmt_tx100seg: 90535.818,
  },
  {
    tx100id: 8000017,
    year: 2019,
    rank: 60,
    trank: 40,
    dly: 2311837.0,
    tdly: 193241.0,
    pti: 1.68,
    tpti: 1.81,
    tci: 1.35,
    ttci: 1.4,
    co2: 1.3672951e7,
    tco2: 8455036.0,
    cost: 5.0943053e7,
    tcost: 1.0801168e7,
    fuel: 678327.0,
    tfuel: 382372.0,
    spd: 50.5,
    tspd: 43.6,
    vmt_tx100seg: 1639924.926,
    trkvmt_tx100seg: 141727.994,
  },
  {
    tx100id: 8000084,
    year: 2019,
    rank: 44,
    trank: 41,
    dly: 1874972.0,
    tdly: 135522.0,
    pti: 2.35,
    tpti: 2.49,
    tci: 1.59,
    ttci: 1.62,
    co2: 1.2804273e7,
    tco2: 5218694.0,
    cost: 4.083294e7,
    tcost: 7486390.0,
    fuel: 626452.0,
    tfuel: 235738.0,
    spd: 44.1,
    tspd: 41.5,
    vmt_tx100seg: 942289.84,
    trkvmt_tx100seg: 81462.529,
  },
  {
    tx100id: 1200182,
    year: 2019,
    rank: 51,
    trank: 42,
    dly: 1962712.0,
    tdly: 147420.0,
    pti: 1.71,
    tpti: 1.89,
    tci: 1.39,
    ttci: 1.47,
    co2: 1.288545e7,
    tco2: 5410576.0,
    cost: 4.2845478e7,
    tcost: 8109395.0,
    fuel: 629013.0,
    tfuel: 245754.0,
    spd: 48.4,
    tspd: 46.6,
    vmt_tx100seg: 1331611.937,
    trkvmt_tx100seg: 115235.074,
  },
  {
    tx100id: 1900032,
    year: 2019,
    rank: 119,
    trank: 45,
    dly: 288678.0,
    tdly: 34407.0,
    pti: 1.74,
    tpti: 1.74,
    tci: 1.3,
    ttci: 1.32,
    co2: 2047153.0,
    tco2: 1156734.0,
    cost: 6731328.0,
    tcost: 1884242.0,
    fuel: 101262.0,
    tfuel: 54599.0,
    spd: 50.0,
    tspd: 48.8,
    vmt_tx100seg: 308753.848,
    trkvmt_tx100seg: 46502.618,
  },
  {
    tx100id: 3000002,
    year: 2019,
    rank: 78,
    trank: 46,
    dly: 754580.0,
    tdly: 70122.0,
    pti: 2.52,
    tpti: 2.57,
    tci: 1.47,
    ttci: 1.52,
    co2: 4464313.0,
    tco2: 1910875.0,
    cost: 1.6847364e7,
    tcost: 3781546.0,
    fuel: 221684.0,
    tfuel: 89139.0,
    spd: 52.6,
    tspd: 50.4,
    vmt_tx100seg: 545616.212,
    trkvmt_tx100seg: 65598.979,
  },
  {
    tx100id: 3000005,
    year: 2019,
    rank: 83,
    trank: 48,
    dly: 1137150.0,
    tdly: 106849.0,
    pti: 1.91,
    tpti: 2.12,
    tci: 1.35,
    ttci: 1.4,
    co2: 5834516.0,
    tco2: 2708412.0,
    cost: 2.530316e7,
    tcost: 5736422.0,
    fuel: 289614.0,
    tfuel: 128499.0,
    spd: 52.2,
    tspd: 51.9,
    vmt_tx100seg: 1023271.074,
    trkvmt_tx100seg: 114708.173,
  },
  {
    tx100id: 1200188,
    year: 2019,
    rank: 32,
    trank: 49,
    dly: 2522398.0,
    tdly: 125850.0,
    pti: 2.05,
    tpti: 2.14,
    tci: 1.42,
    ttci: 1.45,
    co2: 1.0265326e7,
    tco2: 3787973.0,
    cost: 5.2252109e7,
    tcost: 6829340.0,
    fuel: 519734.0,
    tfuel: 179465.0,
    spd: 48.8,
    tspd: 47.0,
    vmt_tx100seg: 1699885.174,
    trkvmt_tx100seg: 102406.917,
  },
  {
    tx100id: 1900012,
    year: 2019,
    rank: 37,
    trank: 50,
    dly: 2431242.0,
    tdly: 127320.0,
    pti: 2.18,
    tpti: 2.28,
    tci: 1.61,
    ttci: 1.63,
    co2: 1.1799512e7,
    tco2: 4259709.0,
    cost: 5.078582e7,
    tcost: 6950168.0,
    fuel: 586453.0,
    tfuel: 195278.0,
    spd: 46.7,
    tspd: 44.7,
    vmt_tx100seg: 970696.522,
    trkvmt_tx100seg: 63724.3,
  },
  {
    tx100id: 8000077,
    year: 2019,
    rank: 36,
    trank: 51,
    dly: 782772.0,
    tdly: 40884.0,
    pti: 2.39,
    tpti: 2.59,
    tci: 1.65,
    ttci: 1.72,
    co2: 4510141.0,
    tco2: 1439576.0,
    cost: 1.6448342e7,
    tcost: 2240873.0,
    fuel: 227739.0,
    tfuel: 66825.0,
    spd: 43.7,
    tspd: 40.9,
    vmt_tx100seg: 378782.767,
    trkvmt_tx100seg: 22662.731,
  },
  {
    tx100id: 8000018,
    year: 2019,
    rank: 57,
    trank: 52,
    dly: 711606.0,
    tdly: 49651.0,
    pti: 1.6,
    tpti: 1.74,
    tci: 1.32,
    ttci: 1.35,
    co2: 3224010.0,
    tco2: 1741415.0,
    cost: 1.525448e7,
    tcost: 2720804.0,
    fuel: 169563.0,
    tfuel: 80377.0,
    spd: 49.4,
    tspd: 46.0,
    vmt_tx100seg: 598486.022,
    trkvmt_tx100seg: 49763.513,
  },
  {
    tx100id: 8000019,
    year: 2019,
    rank: 52,
    trank: 53,
    dly: 862276.0,
    tdly: 56924.0,
    pti: 2.09,
    tpti: 2.06,
    tci: 1.52,
    ttci: 1.49,
    co2: 4454342.0,
    tco2: 1803585.0,
    cost: 1.8426989e7,
    tcost: 3104334.0,
    fuel: 223339.0,
    tfuel: 86750.0,
    spd: 44.8,
    tspd: 45.3,
    vmt_tx100seg: 514594.875,
    trkvmt_tx100seg: 49809.158,
  },
  {
    tx100id: 1900034,
    year: 2019,
    rank: 109,
    trank: 54,
    dly: 609005.0,
    tdly: 60812.0,
    pti: 2.03,
    tpti: 1.94,
    tci: 1.38,
    ttci: 1.36,
    co2: 3004274.0,
    tco2: 1535711.0,
    cost: 1.3653406e7,
    tcost: 3268477.0,
    fuel: 149857.0,
    tfuel: 74001.0,
    spd: 51.0,
    tspd: 50.6,
    vmt_tx100seg: 521109.48,
    trkvmt_tx100seg: 72993.43,
  },
  {
    tx100id: 2200033,
    year: 2019,
    rank: 239,
    trank: 55,
    dly: 321537.0,
    tdly: 51278.0,
    pti: 1.51,
    tpti: 1.62,
    tci: 1.3,
    ttci: 1.32,
    co2: 4889888.0,
    tco2: 2824325.0,
    cost: 8246809.0,
    tcost: 2948152.0,
    fuel: 232032.0,
    tfuel: 126616.0,
    spd: 30.4,
    tspd: 31.3,
    vmt_tx100seg: 119848.711,
    trkvmt_tx100seg: 22309.909,
  },
  {
    tx100id: 1200144,
    year: 2019,
    rank: 30,
    trank: 57,
    dly: 2636185.0,
    tdly: 115708.0,
    pti: 1.71,
    tpti: 1.83,
    tci: 1.41,
    ttci: 1.46,
    co2: 1.2147138e7,
    tco2: 3553731.0,
    cost: 5.4255864e7,
    tcost: 6267760.0,
    fuel: 605606.0,
    tfuel: 165439.0,
    spd: 50.8,
    tspd: 48.1,
    vmt_tx100seg: 1486732.08,
    trkvmt_tx100seg: 75823.544,
  },
  {
    tx100id: 1200187,
    year: 2019,
    rank: 63,
    trank: 58,
    dly: 1239265.0,
    tdly: 85714.0,
    pti: 1.74,
    tpti: 1.83,
    tci: 1.39,
    ttci: 1.41,
    co2: 8104337.0,
    tco2: 3383691.0,
    cost: 2.6821474e7,
    tcost: 4743826.0,
    fuel: 400015.0,
    tfuel: 154888.0,
    spd: 45.9,
    tspd: 45.8,
    vmt_tx100seg: 928754.693,
    trkvmt_tx100seg: 80294.22,
  },
  {
    tx100id: 8000062,
    year: 2019,
    rank: 47,
    trank: 59,
    dly: 1122476.0,
    tdly: 63986.0,
    pti: 1.97,
    tpti: 2.13,
    tci: 1.58,
    ttci: 1.65,
    co2: 6483567.0,
    tco2: 2466603.0,
    cost: 2.374634e7,
    tcost: 3526520.0,
    fuel: 323398.0,
    tfuel: 113108.0,
    spd: 44.4,
    tspd: 41.0,
    vmt_tx100seg: 498558.459,
    trkvmt_tx100seg: 33591.349,
  },
  {
    tx100id: 1400201,
    year: 2019,
    rank: 84,
    trank: 60,
    dly: 424091.0,
    tdly: 31254.0,
    pti: 1.81,
    tpti: 1.84,
    tci: 1.44,
    ttci: 1.36,
    co2: 4079259.0,
    tco2: 1089265.0,
    cost: 9408775.0,
    tcost: 1708544.0,
    fuel: 202236.0,
    tfuel: 49642.0,
    spd: 26.5,
    tspd: 26.6,
    vmt_tx100seg: 77129.724,
    trkvmt_tx100seg: 7404.684,
  },
  {
    tx100id: 8000039,
    year: 2019,
    rank: 71,
    trank: 63,
    dly: 2150553.0,
    tdly: 140387.0,
    pti: 1.74,
    tpti: 1.73,
    tci: 1.34,
    ttci: 1.32,
    co2: 7403722.0,
    tco2: 2998489.0,
    cost: 4.5418248e7,
    tcost: 7470314.0,
    fuel: 373061.0,
    tfuel: 145283.0,
    spd: 51.8,
    tspd: 51.5,
    vmt_tx100seg: 1828839.37,
    trkvmt_tx100seg: 171746.351,
  },
  {
    tx100id: 1400800,
    year: 2019,
    rank: 722,
    trank: 68,
    dly: 62412.0,
    tdly: 17034.0,
    pti: 1.33,
    tpti: 1.4,
    tci: 1.2,
    ttci: 1.2,
    co2: 953975.0,
    tco2: 569262.0,
    cost: 1823540.0,
    tcost: 928742.0,
    fuel: 45481.0,
    tfuel: 25837.0,
    spd: 22.0,
    tspd: 22.1,
    vmt_tx100seg: 17252.691,
    trkvmt_tx100seg: 5210.742,
  },
  {
    tx100id: 8000114,
    year: 2019,
    rank: 50,
    trank: 70,
    dly: 2762054.0,
    tdly: 133725.0,
    pti: 1.77,
    tpti: 1.77,
    tci: 1.4,
    ttci: 1.41,
    co2: 1.1211294e7,
    tco2: 3365598.0,
    cost: 5.7070846e7,
    tcost: 7176355.0,
    fuel: 565061.0,
    tfuel: 161962.0,
    spd: 52.6,
    tspd: 50.7,
    vmt_tx100seg: 1869989.793,
    trkvmt_tx100seg: 115345.701,
  },
  {
    tx100id: 8000155,
    year: 2019,
    rank: 5,
    trank: 71,
    dly: 1357930.0,
    tdly: 16856.0,
    pti: 2.93,
    tpti: 3.07,
    tci: 2.05,
    ttci: 2.12,
    co2: 7614451.0,
    tco2: 594418.0,
    cost: 2.6780581e7,
    tcost: 921187.0,
    fuel: 385537.0,
    tfuel: 27269.0,
    spd: 28.8,
    tspd: 26.1,
    vmt_tx100seg: 254149.616,
    trkvmt_tx100seg: 3812.648,
  },
  {
    tx100id: 1200185,
    year: 2019,
    rank: 40,
    trank: 73,
    dly: 2316039.0,
    tdly: 92704.0,
    pti: 1.75,
    tpti: 1.93,
    tci: 1.38,
    ttci: 1.44,
    co2: 1.1456011e7,
    tco2: 3119096.0,
    cost: 4.7493752e7,
    tcost: 5059933.0,
    fuel: 571507.0,
    tfuel: 144031.0,
    spd: 48.8,
    tspd: 47.1,
    vmt_tx100seg: 1435257.641,
    trkvmt_tx100seg: 67017.811,
  },
  {
    tx100id: 8000008,
    year: 2019,
    rank: 127,
    trank: 74,
    dly: 698232.0,
    tdly: 58567.0,
    pti: 2.05,
    tpti: 2.03,
    tci: 1.51,
    ttci: 1.46,
    co2: 3623396.0,
    tco2: 1819955.0,
    cost: 1.5330431e7,
    tcost: 3182345.0,
    fuel: 183376.0,
    tfuel: 83334.0,
    spd: 46.1,
    tspd: 46.2,
    vmt_tx100seg: 393689.521,
    trkvmt_tx100seg: 43222.174,
  },
  {
    tx100id: 8000101,
    year: 2019,
    rank: 327,
    trank: 78,
    dly: 497840.0,
    tdly: 70037.0,
    pti: 1.62,
    tpti: 1.7,
    tci: 1.26,
    ttci: 1.3,
    co2: 5451168.0,
    tco2: 3312057.0,
    cost: 1.2188591e7,
    tcost: 3952805.0,
    fuel: 258009.0,
    tfuel: 148809.0,
    spd: 46.8,
    tspd: 42.9,
    vmt_tx100seg: 322794.156,
    trkvmt_tx100seg: 48522.902,
  },
  {
    tx100id: 8000048,
    year: 2019,
    rank: 64,
    trank: 79,
    dly: 1771243.0,
    tdly: 97928.0,
    pti: 1.95,
    tpti: 2.03,
    tci: 1.46,
    ttci: 1.48,
    co2: 8610879.0,
    tco2: 3333470.0,
    cost: 3.7176747e7,
    tcost: 5358605.0,
    fuel: 430995.0,
    tfuel: 154204.0,
    spd: 46.4,
    tspd: 45.0,
    vmt_tx100seg: 1146205.102,
    trkvmt_tx100seg: 80513.076,
  },
  {
    tx100id: 8000038,
    year: 2019,
    rank: 96,
    trank: 80,
    dly: 1441952.0,
    tdly: 100695.0,
    pti: 1.57,
    tpti: 1.6,
    tci: 1.26,
    ttci: 1.28,
    co2: 3606729.0,
    tco2: 1614551.0,
    cost: 3.048397e7,
    tcost: 5287677.0,
    fuel: 185464.0,
    tfuel: 80623.0,
    spd: 54.0,
    tspd: 52.8,
    vmt_tx100seg: 1541611.165,
    trkvmt_tx100seg: 137180.412,
  },
  {
    tx100id: 3000006,
    year: 2019,
    rank: 167,
    trank: 85,
    dly: 593604.0,
    tdly: 52501.0,
    pti: 1.59,
    tpti: 1.69,
    tci: 1.26,
    ttci: 1.26,
    co2: 2258725.0,
    tco2: 1038208.0,
    cost: 1.3009133e7,
    tcost: 2786752.0,
    fuel: 116459.0,
    tfuel: 52319.0,
    spd: 54.1,
    tspd: 54.2,
    vmt_tx100seg: 723988.871,
    trkvmt_tx100seg: 87377.01,
  },
  {
    tx100id: 1200147,
    year: 2019,
    rank: 129,
    trank: 86,
    dly: 1282204.0,
    tdly: 96388.0,
    pti: 1.35,
    tpti: 1.42,
    tci: 1.19,
    ttci: 1.22,
    co2: 1880530.0,
    tco2: 1308667.0,
    cost: 2.7207886e7,
    tcost: 5062101.0,
    fuel: 123806.0,
    tfuel: 80723.0,
    spd: 56.0,
    tspd: 52.8,
    vmt_tx100seg: 2053428.653,
    trkvmt_tx100seg: 155664.329,
  },
  {
    tx100id: 8000040,
    year: 2019,
    rank: 303,
    trank: 88,
    dly: 793317.0,
    tdly: 94119.0,
    pti: 1.35,
    tpti: 1.39,
    tci: 1.15,
    ttci: 1.16,
    co2: 1708504.0,
    tco2: 1093114.0,
    cost: 1.7985699e7,
    tcost: 4903870.0,
    fuel: 96232.0,
    tfuel: 61733.0,
    spd: 57.8,
    tspd: 56.2,
    vmt_tx100seg: 1521590.155,
    trkvmt_tx100seg: 220382.44,
  },
  {
    tx100id: 1200159,
    year: 2019,
    rank: 192,
    trank: 91,
    dly: 638755.0,
    tdly: 57373.0,
    pti: 1.46,
    tpti: 1.6,
    tci: 1.27,
    ttci: 1.35,
    co2: 2060549.0,
    tco2: 1580345.0,
    cost: 1.400485e7,
    tcost: 3097490.0,
    fuel: 118844.0,
    tfuel: 78809.0,
    spd: 53.9,
    tspd: 50.4,
    vmt_tx100seg: 698111.151,
    trkvmt_tx100seg: 57792.225,
  },
  {
    tx100id: 8000052,
    year: 2019,
    rank: 155,
    trank: 93,
    dly: 1061669.0,
    tdly: 81253.0,
    pti: 1.65,
    tpti: 1.76,
    tci: 1.28,
    ttci: 1.3,
    co2: 4335974.0,
    tco2: 2051402.0,
    cost: 2.289734e7,
    tcost: 4361328.0,
    fuel: 219136.0,
    tfuel: 97505.0,
    spd: 53.1,
    tspd: 51.5,
    vmt_tx100seg: 1100005.653,
    trkvmt_tx100seg: 105514.865,
  },
  {
    tx100id: 1200200,
    year: 2019,
    rank: 360,
    trank: 94,
    dly: 521467.0,
    tdly: 65028.0,
    pti: 1.35,
    tpti: 1.38,
    tci: 1.19,
    ttci: 1.21,
    co2: 2607230.0,
    tco2: 1475206.0,
    cost: 1.212482e7,
    tcost: 3477004.0,
    fuel: 138044.0,
    tfuel: 74477.0,
    spd: 53.5,
    tspd: 52.3,
    vmt_tx100seg: 765632.735,
    trkvmt_tx100seg: 103204.536,
  },
  {
    tx100id: 1200156,
    year: 2019,
    rank: 66,
    trank: 105,
    dly: 1730183.0,
    tdly: 77088.0,
    pti: 2.02,
    tpti: 2.13,
    tci: 1.44,
    ttci: 1.47,
    co2: 6692571.0,
    tco2: 2409205.0,
    cost: 3.5503291e7,
    tcost: 4191725.0,
    fuel: 345149.0,
    tfuel: 118129.0,
    spd: 48.3,
    tspd: 46.8,
    vmt_tx100seg: 1238415.003,
    trkvmt_tx100seg: 69246.483,
  },
  {
    tx100id: 1200148,
    year: 2019,
    rank: 105,
    trank: 106,
    dly: 1053756.0,
    tdly: 60049.0,
    pti: 1.48,
    tpti: 1.51,
    tci: 1.23,
    ttci: 1.23,
    co2: 2316934.0,
    tco2: 940096.0,
    cost: 2.1820278e7,
    tcost: 3163575.0,
    fuel: 126552.0,
    tfuel: 52930.0,
    spd: 54.9,
    tspd: 54.4,
    vmt_tx100seg: 1545072.742,
    trkvmt_tx100seg: 115609.254,
  },
  {
    tx100id: 1900072,
    year: 2019,
    rank: 97,
    trank: 109,
    dly: 568056.0,
    tdly: 31251.0,
    pti: 1.77,
    tpti: 1.84,
    tci: 1.44,
    ttci: 1.44,
    co2: 5440817.0,
    tco2: 1155049.0,
    cost: 1.2264827e7,
    tcost: 1715743.0,
    fuel: 271519.0,
    tfuel: 52795.0,
    spd: 26.5,
    tspd: 26.7,
    vmt_tx100seg: 114170.733,
    trkvmt_tx100seg: 7985.067,
  },
  {
    tx100id: 8000387,
    year: 2019,
    rank: 849,
    trank: 110,
    dly: 198078.0,
    tdly: 46082.0,
    pti: 1.22,
    tpti: 1.43,
    tci: 1.15,
    ttci: 1.24,
    co2: 3895292.0,
    tco2: 2635666.0,
    cost: 5653294.0,
    tcost: 2660140.0,
    fuel: 182771.0,
    tfuel: 118486.0,
    spd: 34.8,
    tspd: 33.5,
    vmt_tx100seg: 135190.335,
    trkvmt_tx100seg: 23486.41,
  },
  {
    tx100id: 1200112,
    year: 2019,
    rank: 126,
    trank: 112,
    dly: 986379.0,
    tdly: 61871.0,
    pti: 1.44,
    tpti: 1.5,
    tci: 1.19,
    ttci: 1.22,
    co2: 2559182.0,
    tco2: 1444142.0,
    cost: 2.0672364e7,
    tcost: 3309717.0,
    fuel: 142361.0,
    tfuel: 71910.0,
    spd: 56.1,
    tspd: 52.3,
    vmt_tx100seg: 1442755.641,
    trkvmt_tx100seg: 99830.597,
  },
  {
    tx100id: 1000013,
    year: 2019,
    rank: 134,
    trank: 116,
    dly: 550345.0,
    tdly: 34460.0,
    pti: 1.42,
    tpti: 1.47,
    tci: 1.19,
    ttci: 1.19,
    co2: 3972352.0,
    tco2: 1510128.0,
    cost: 1.1844154e7,
    tcost: 1927232.0,
    fuel: 194350.0,
    tfuel: 68199.0,
    spd: 51.5,
    tspd: 50.1,
    vmt_tx100seg: 671656.806,
    trkvmt_tx100seg: 50167.051,
  },
  {
    tx100id: 2400012,
    year: 2019,
    rank: 770,
    trank: 117,
    dly: 185499.0,
    tdly: 37190.0,
    pti: 1.2,
    tpti: 1.18,
    tci: 1.06,
    ttci: 1.06,
    co2: 173593.0,
    tco2: 125632.0,
    cost: 4662110.0,
    tcost: 1895524.0,
    fuel: 13851.0,
    tfuel: 9664.0,
    spd: 61.0,
    tspd: 59.8,
    vmt_tx100seg: 539998.437,
    trkvmt_tx100seg: 116679.166,
  },
  {
    tx100id: 8000061,
    year: 2019,
    rank: 90,
    trank: 124,
    dly: 1122323.0,
    tdly: 55365.0,
    pti: 1.78,
    tpti: 1.89,
    tci: 1.41,
    ttci: 1.42,
    co2: 5971674.0,
    tco2: 2231477.0,
    cost: 2.3404534e7,
    tcost: 3062856.0,
    fuel: 298998.0,
    tfuel: 102194.0,
    spd: 48.9,
    tspd: 45.4,
    vmt_tx100seg: 679007.575,
    trkvmt_tx100seg: 46335.287,
  },
  {
    tx100id: 8000081,
    year: 2019,
    rank: 54,
    trank: 129,
    dly: 1269030.0,
    tdly: 44172.0,
    pti: 2.11,
    tpti: 2.2,
    tci: 1.48,
    ttci: 1.49,
    co2: 5649044.0,
    tco2: 1474394.0,
    cost: 2.5741327e7,
    tcost: 2415633.0,
    fuel: 285837.0,
    tfuel: 69933.0,
    spd: 47.5,
    tspd: 45.8,
    vmt_tx100seg: 770338.242,
    trkvmt_tx100seg: 35683.084,
  },
  {
    tx100id: 1100022,
    year: 2019,
    rank: 1036,
    trank: 130,
    dly: 99646.0,
    tdly: 27503.0,
    pti: 1.34,
    tpti: 1.44,
    tci: 1.2,
    ttci: 1.18,
    co2: 1651298.0,
    tco2: 1096894.0,
    cost: 2940953.0,
    tcost: 1526013.0,
    fuel: 77548.0,
    tfuel: 49212.0,
    spd: 27.1,
    tspd: 28.1,
    vmt_tx100seg: 32410.171,
    trkvmt_tx100seg: 9652.584,
  },
  {
    tx100id: 1100023,
    year: 2019,
    rank: 673,
    trank: 133,
    dly: 243114.0,
    tdly: 40672.0,
    pti: 1.25,
    tpti: 1.31,
    tci: 1.16,
    ttci: 1.17,
    co2: 1752923.0,
    tco2: 1100280.0,
    cost: 6041900.0,
    tcost: 2190469.0,
    fuel: 84456.0,
    tfuel: 50213.0,
    spd: 58.1,
    tspd: 55.8,
    vmt_tx100seg: 381387.923,
    trkvmt_tx100seg: 78097.115,
  },
  {
    tx100id: 1200165,
    year: 2019,
    rank: 383,
    trank: 134,
    dly: 477335.0,
    tdly: 51031.0,
    pti: 1.25,
    tpti: 1.27,
    tci: 1.12,
    ttci: 1.14,
    co2: 1211110.0,
    tco2: 680882.0,
    cost: 1.0676802e7,
    tcost: 2677852.0,
    fuel: 70880.0,
    tfuel: 41140.0,
    spd: 58.2,
    tspd: 55.9,
    vmt_tx100seg: 825325.156,
    trkvmt_tx100seg: 110267.899,
  },
  {
    tx100id: 3000012,
    year: 2019,
    rank: 29,
    trank: 138,
    dly: 1296967.0,
    tdly: 29032.0,
    pti: 2.23,
    tpti: 2.47,
    tci: 1.65,
    ttci: 1.69,
    co2: 1.0083036e7,
    tco2: 1049758.0,
    cost: 2.6361606e7,
    tcost: 1590713.0,
    fuel: 509105.0,
    tfuel: 48020.0,
    spd: 39.9,
    tspd: 37.7,
    vmt_tx100seg: 244791.033,
    trkvmt_tx100seg: 6739.219,
  },
  {
    tx100id: 8000133,
    year: 2019,
    rank: 238,
    trank: 143,
    dly: 632359.0,
    tdly: 49375.0,
    pti: 1.41,
    tpti: 1.42,
    tci: 1.17,
    ttci: 1.17,
    co2: 984962.0,
    tco2: 493342.0,
    cost: 1.3472353e7,
    tcost: 2568937.0,
    fuel: 59611.0,
    tfuel: 32396.0,
    spd: 57.2,
    tspd: 56.3,
    vmt_tx100seg: 1059341.745,
    trkvmt_tx100seg: 108679.682,
  },
  {
    tx100id: 8000011,
    year: 2019,
    rank: 151,
    trank: 147,
    dly: 709726.0,
    tdly: 39088.0,
    pti: 1.56,
    tpti: 1.61,
    tci: 1.23,
    ttci: 1.25,
    co2: 2259109.0,
    tco2: 790577.0,
    cost: 1.474184e7,
    tcost: 2077584.0,
    fuel: 117811.0,
    tfuel: 40315.0,
    spd: 54.3,
    tspd: 53.0,
    vmt_tx100seg: 917641.806,
    trkvmt_tx100seg: 61144.302,
  },
  {
    tx100id: 8000198,
    year: 2019,
    rank: 169,
    trank: 169,
    dly: 814280.0,
    tdly: 46381.0,
    pti: 1.33,
    tpti: 1.45,
    tci: 1.23,
    ttci: 1.26,
    co2: 7356308.0,
    tco2: 2114120.0,
    cost: 1.7572838e7,
    tcost: 2601666.0,
    fuel: 363404.0,
    tfuel: 95869.0,
    spd: 36.7,
    tspd: 36.8,
    vmt_tx100seg: 292063.042,
    trkvmt_tx100seg: 18610.068,
  },
  {
    tx100id: 7000061,
    year: 2019,
    rank: 93,
    trank: 172,
    dly: 525165.0,
    tdly: 21084.0,
    pti: 1.56,
    tpti: 1.57,
    tci: 1.34,
    ttci: 1.28,
    co2: 5134098.0,
    tco2: 747352.0,
    cost: 1.1107151e7,
    tcost: 1152546.0,
    fuel: 258194.0,
    tfuel: 34316.0,
    spd: 23.1,
    tspd: 24.3,
    vmt_tx100seg: 110763.285,
    trkvmt_tx100seg: 6748.901,
  },
  {
    tx100id: 8000012,
    year: 2019,
    rank: 219,
    trank: 177,
    dly: 779921.0,
    tdly: 50226.0,
    pti: 1.67,
    tpti: 1.66,
    tci: 1.27,
    ttci: 1.26,
    co2: 2519108.0,
    tco2: 844287.0,
    cost: 1.643143e7,
    tcost: 2645177.0,
    fuel: 129912.0,
    tfuel: 43022.0,
    spd: 54.6,
    tspd: 54.4,
    vmt_tx100seg: 1011060.695,
    trkvmt_tx100seg: 85968.53,
  },
  {
    tx100id: 8000053,
    year: 2019,
    rank: 503,
    trank: 184,
    dly: 556551.0,
    tdly: 56526.0,
    pti: 1.56,
    tpti: 1.52,
    tci: 1.21,
    ttci: 1.18,
    co2: 2152935.0,
    tco2: 1122970.0,
    cost: 1.2438204e7,
    tcost: 2998322.0,
    fuel: 111899.0,
    tfuel: 55084.0,
    spd: 56.8,
    tspd: 53.3,
    vmt_tx100seg: 879852.44,
    trkvmt_tx100seg: 123873.129,
  },
  {
    tx100id: 8000050,
    year: 2019,
    rank: 279,
    trank: 197,
    dly: 559608.0,
    tdly: 37110.0,
    pti: 1.52,
    tpti: 1.58,
    tci: 1.19,
    ttci: 1.2,
    co2: 2391023.0,
    tco2: 1103026.0,
    cost: 1.1906553e7,
    tcost: 2009086.0,
    fuel: 122941.0,
    tfuel: 50907.0,
    spd: 57.0,
    tspd: 51.3,
    vmt_tx100seg: 766049.362,
    trkvmt_tx100seg: 56057.282,
  },
  {
    tx100id: 8000060,
    year: 2019,
    rank: 359,
    trank: 227,
    dly: 515522.0,
    tdly: 36283.0,
    pti: 1.43,
    tpti: 1.55,
    tci: 1.18,
    ttci: 1.23,
    co2: 2238784.0,
    tco2: 1188395.0,
    cost: 1.1036356e7,
    tcost: 1975303.0,
    fuel: 115775.0,
    tfuel: 56161.0,
    spd: 55.5,
    tspd: 52.3,
    vmt_tx100seg: 816272.553,
    trkvmt_tx100seg: 55270.972,
  },
  {
    tx100id: 1010102,
    year: 2019,
    rank: 142,
    trank: 228,
    dly: 605992.0,
    tdly: 24483.0,
    pti: 1.56,
    tpti: 1.63,
    tci: 1.29,
    ttci: 1.29,
    co2: 3637166.0,
    tco2: 1008644.0,
    cost: 1.2518162e7,
    tcost: 1360220.0,
    fuel: 180128.0,
    tfuel: 45716.0,
    spd: 50.0,
    tspd: 46.5,
    vmt_tx100seg: 470903.397,
    trkvmt_tx100seg: 22221.525,
  },
  {
    tx100id: 1900086,
    year: 2019,
    rank: 1211,
    trank: 265,
    dly: 87548.0,
    tdly: 21073.0,
    pti: 1.1,
    tpti: 1.16,
    tci: 1.04,
    ttci: 1.07,
    co2: 955775.0,
    tco2: 710100.0,
    cost: 2421578.0,
    tcost: 1152786.0,
    fuel: 45004.0,
    tfuel: 32196.0,
    spd: 63.0,
    tspd: 59.5,
    vmt_tx100seg: 238803.702,
    trkvmt_tx100seg: 50626.194,
  },
  {
    tx100id: 1200094,
    year: 2019,
    rank: 1119,
    trank: 358,
    dly: 196254.0,
    tdly: 29623.0,
    pti: 1.21,
    tpti: 1.23,
    tci: 1.07,
    ttci: 1.08,
    co2: 486816.0,
    tco2: 319674.0,
    cost: 4674739.0,
    tcost: 1550480.0,
    fuel: 33235.0,
    tfuel: 22488.0,
    spd: 59.4,
    tspd: 55.6,
    vmt_tx100seg: 643862.786,
    trkvmt_tx100seg: 99814.37,
  },
  {
    tx100id: 3000059,
    year: 2019,
    rank: 1067,
    trank: 364,
    dly: 358658.0,
    tdly: 48449.0,
    pti: 1.2,
    tpti: 1.19,
    tci: 1.07,
    ttci: 1.07,
    co2: 665706.0,
    tco2: 376436.0,
    cost: 8309776.0,
    tcost: 2503864.0,
    fuel: 41353.0,
    tfuel: 25298.0,
    spd: 61.6,
    tspd: 58.5,
    vmt_tx100seg: 1294974.123,
    trkvmt_tx100seg: 212001.661,
  },
  {
    tx100id: 2400013,
    year: 2019,
    rank: 1353,
    trank: 439,
    dly: 31068.0,
    tdly: 7770.0,
    pti: 1.1,
    tpti: 1.11,
    tci: 1.04,
    ttci: 1.04,
    co2: 263.0,
    tco2: 2049.0,
    cost: 826666.0,
    tcost: 393622.0,
    fuel: 1279.0,
    tfuel: 1217.0,
    spd: 62.9,
    tspd: 60.5,
    vmt_tx100seg: 233911.067,
    trkvmt_tx100seg: 54031.264,
  },
  {
    tx100id: 1010103,
    year: 2019,
    rank: 867,
    trank: 579,
    dly: 229808.0,
    tdly: 16605.0,
    pti: 1.21,
    tpti: 1.27,
    tci: 1.1,
    ttci: 1.13,
    co2: 262414.0,
    tco2: 217486.0,
    cost: 4850101.0,
    tcost: 873676.0,
    fuel: 20335.0,
    tfuel: 14588.0,
    spd: 60.0,
    tspd: 56.1,
    vmt_tx100seg: 681329.576,
    trkvmt_tx100seg: 41602.786,
  },
  {
    tx100id: 1900038,
    year: 2019,
    rank: 573,
    trank: 655,
    dly: 301440.0,
    tdly: 13413.0,
    pti: 1.61,
    tpti: 1.56,
    tci: 1.24,
    ttci: 1.23,
    co2: 1238930.0,
    tco2: 484516.0,
    cost: 6199593.0,
    tcost: 735882.0,
    fuel: 65056.0,
    tfuel: 22409.0,
    spd: 52.9,
    tspd: 50.9,
    vmt_tx100seg: 384882.366,
    trkvmt_tx100seg: 21015.21,
  },
  {
    tx100id: 8000130,
    year: 2019,
    rank: 492,
    trank: 797,
    dly: 437898.0,
    tdly: 13998.0,
    pti: 1.42,
    tpti: 1.41,
    tci: 1.18,
    ttci: 1.18,
    co2: 1413414.0,
    tco2: 460226.0,
    cost: 8780057.0,
    tcost: 762390.0,
    fuel: 74789.0,
    tfuel: 20944.0,
    spd: 53.4,
    tspd: 52.0,
    vmt_tx100seg: 514840.701,
    trkvmt_tx100seg: 21700.461,
  },
  {
    tx100id: 8000149,
    year: 2019,
    rank: 1318,
    trank: 859,
    dly: 159781.0,
    tdly: 19133.0,
    pti: 1.15,
    tpti: 1.2,
    tci: 1.07,
    ttci: 1.09,
    co2: 976476.0,
    tco2: 637247.0,
    cost: 3705708.0,
    tcost: 1045171.0,
    fuel: 47162.0,
    tfuel: 29180.0,
    spd: 59.3,
    tspd: 56.3,
    vmt_tx100seg: 347939.197,
    trkvmt_tx100seg: 42729.091,
  },
  {
    tx100id: 2400010,
    year: 2019,
    rank: 1576,
    trank: 862,
    dly: 19557.0,
    tdly: 8652.0,
    pti: 1.02,
    tpti: 1.02,
    tci: 1.01,
    ttci: 1.01,
    co2: 114.0,
    tco2: 192.0,
    cost: 639051.0,
    tcost: 436431.0,
    fuel: 649.0,
    tfuel: 649.0,
    spd: 64.8,
    tspd: 64.1,
    vmt_tx100seg: 438859.658,
    trkvmt_tx100seg: 133708.466,
  },
  {
    tx100id: 2400030,
    year: 2019,
    rank: 1351,
    trank: 1064,
    dly: 67719.0,
    tdly: 7035.0,
    pti: 1.22,
    tpti: 1.31,
    tci: 1.14,
    ttci: 1.16,
    co2: 720186.0,
    tco2: 388330.0,
    cost: 1576090.0,
    tcost: 404047.0,
    fuel: 34429.0,
    tfuel: 17493.0,
    spd: 43.4,
    tspd: 42.8,
    vmt_tx100seg: 64427.802,
    trkvmt_tx100seg: 6711.586,
  },
  {
    tx100id: 3000058,
    year: 2019,
    rank: 1647,
    trank: 1191,
    dly: 19078.0,
    tdly: 9296.0,
    pti: 1.01,
    tpti: 1.03,
    tci: 1.0,
    ttci: 1.01,
    co2: 3720.0,
    tco2: 7937.0,
    cost: 655040.0,
    tcost: 473305.0,
    fuel: 2336.0,
    tfuel: 2336.0,
    spd: 64.9,
    tspd: 61.5,
    vmt_tx100seg: 773512.577,
    trkvmt_tx100seg: 150312.728,
  },
  {
    tx100id: 8000131,
    year: 2019,
    rank: 749,
    trank: 1413,
    dly: 257975.0,
    tdly: 5110.0,
    pti: 1.6,
    tpti: 1.65,
    tci: 1.23,
    ttci: 1.23,
    co2: 631381.0,
    tco2: 84992.0,
    cost: 5042062.0,
    tcost: 269967.0,
    fuel: 32844.0,
    tfuel: 4755.0,
    spd: 56.1,
    tspd: 55.7,
    vmt_tx100seg: 407151.099,
    trkvmt_tx100seg: 9056.732,
  },
  {
    tx100id: 3000004,
    year: 2020,
    rank: 1,
    trank: 1,
    dly: 1.3032206e7,
    tdly: 781637.0,
    pti: 5.14,
    tpti: 5.17,
    tci: 2.88,
    ttci: 2.77,
    co2: 1.00142903e8,
    tco2: 2.8410525e7,
    cost: 2.8834973e8,
    tcost: 4.5195958e7,
    fuel: 4944271.0,
    tfuel: 1282721.0,
    spd: 35.1,
    tspd: 35.6,
    vmt_tx100seg: 1374647,
    trkvmt_tx100seg: 112668,
  },
  {
    tx100id: 1200140,
    year: 2020,
    rank: 5,
    trank: 2,
    dly: 3039017.0,
    tdly: 191078.0,
    pti: 3.99,
    tpti: 4.29,
    tci: 2.54,
    ttci: 2.68,
    co2: 2.2774892e7,
    tco2: 7312939.0,
    cost: 6.7464382e7,
    tcost: 1.10836e7,
    fuel: 1120826.0,
    tfuel: 331276.0,
    spd: 32.9,
    tspd: 31.4,
    vmt_tx100seg: 448562,
    trkvmt_tx100seg: 33531,
  },
  {
    tx100id: 1200184,
    year: 2020,
    rank: 2,
    trank: 3,
    dly: 5889294.0,
    tdly: 205144.0,
    pti: 3.74,
    tpti: 4.01,
    tci: 2.48,
    ttci: 2.49,
    co2: 3.7803589e7,
    tco2: 7321096.0,
    cost: 1.24306026e8,
    tcost: 1.1821582e7,
    fuel: 1891081.0,
    tfuel: 334197.0,
    spd: 34.0,
    tspd: 34.3,
    vmt_tx100seg: 799418,
    trkvmt_tx100seg: 35387,
  },
  {
    tx100id: 1200168,
    year: 2020,
    rank: 10,
    trank: 4,
    dly: 1116591.0,
    tdly: 86948.0,
    pti: 2.95,
    tpti: 3.01,
    tci: 2.12,
    ttci: 2.14,
    co2: 9960932.0,
    tco2: 3807885.0,
    cost: 2.5556267e7,
    tcost: 5104669.0,
    fuel: 485754.0,
    tfuel: 171733.0,
    spd: 34.2,
    tspd: 34.6,
    vmt_tx100seg: 262216,
    trkvmt_tx100seg: 27309,
  },
  {
    tx100id: 1200143,
    year: 2020,
    rank: 7,
    trank: 5,
    dly: 6644425.0,
    tdly: 418981.0,
    pti: 2.52,
    tpti: 2.57,
    tci: 1.84,
    ttci: 1.84,
    co2: 4.6849556e7,
    tco2: 1.6027669e7,
    cost: 1.47204103e8,
    tcost: 2.4319492e7,
    fuel: 2297420.0,
    tfuel: 723740.0,
    spd: 40.5,
    tspd: 40.7,
    vmt_tx100seg: 1575800,
    trkvmt_tx100seg: 126102,
  },
  {
    tx100id: 1200183,
    year: 2020,
    rank: 6,
    trank: 6,
    dly: 5325765.0,
    tdly: 315011.0,
    pti: 3.24,
    tpti: 3.27,
    tci: 2.24,
    ttci: 2.16,
    co2: 3.6859556e7,
    tco2: 1.1769507e7,
    cost: 1.17193926e8,
    tcost: 1.8238887e7,
    fuel: 1821105.0,
    tfuel: 534515.0,
    spd: 38.8,
    tspd: 37.6,
    vmt_tx100seg: 1186579,
    trkvmt_tx100seg: 97101,
  },
  {
    tx100id: 8000020,
    year: 2020,
    rank: 8,
    trank: 7,
    dly: 4141007.0,
    tdly: 249598.0,
    pti: 2.58,
    tpti: 2.6,
    tci: 1.78,
    ttci: 1.77,
    co2: 3.0395712e7,
    tco2: 1.0636369e7,
    cost: 9.1478884e7,
    tcost: 1.4607069e7,
    fuel: 1490006.0,
    tfuel: 481464.0,
    spd: 39.5,
    tspd: 38.8,
    vmt_tx100seg: 1185016,
    trkvmt_tx100seg: 93913,
  },
  {
    tx100id: 8000010,
    year: 2020,
    rank: 16,
    trank: 8,
    dly: 1834421.0,
    tdly: 147301.0,
    pti: 2.56,
    tpti: 2.62,
    tci: 1.83,
    ttci: 1.86,
    co2: 1.4632922e7,
    tco2: 6100720.0,
    cost: 4.1930045e7,
    tcost: 8605873.0,
    fuel: 711008.0,
    tfuel: 275276.0,
    spd: 38.5,
    tspd: 38.4,
    vmt_tx100seg: 495433,
    trkvmt_tx100seg: 48535,
  },
  {
    tx100id: 1400055,
    year: 2020,
    rank: 287,
    trank: 9,
    dly: 226466.0,
    tdly: 116229.0,
    pti: 1.8,
    tpti: 1.8,
    tci: 1.4,
    ttci: 1.36,
    co2: 4619455.0,
    tco2: 3964403.0,
    cost: 8884465.0,
    tcost: 6694575.0,
    fuel: 211942.0,
    tfuel: 178211.0,
    spd: 29.5,
    tspd: 28.4,
    vmt_tx100seg: 54215,
    trkvmt_tx100seg: 36404,
  },
  {
    tx100id: 1200139,
    year: 2020,
    rank: 3,
    trank: 10,
    dly: 6597307.0,
    tdly: 221220.0,
    pti: 3.32,
    tpti: 3.45,
    tci: 2.13,
    ttci: 2.08,
    co2: 3.9798528e7,
    tco2: 7306212.0,
    cost: 1.38668566e8,
    tcost: 1.2692944e7,
    fuel: 1993809.0,
    tfuel: 332495.0,
    spd: 40.9,
    tspd: 40.1,
    vmt_tx100seg: 1365346,
    trkvmt_tx100seg: 56861,
  },
  {
    tx100id: 3000003,
    year: 2020,
    rank: 12,
    trank: 11,
    dly: 2486065.0,
    tdly: 152858.0,
    pti: 2.74,
    tpti: 2.82,
    tci: 1.77,
    ttci: 1.76,
    co2: 1.3912917e7,
    tco2: 5359760.0,
    cost: 5.4507889e7,
    tcost: 8815555.0,
    fuel: 679879.0,
    tfuel: 243276.0,
    spd: 45.1,
    tspd: 44.7,
    vmt_tx100seg: 588232,
    trkvmt_tx100seg: 49884,
  },
  {
    tx100id: 2400012,
    year: 2020,
    rank: 53,
    trank: 12,
    dly: 1049106.0,
    tdly: 168882.0,
    pti: 1.88,
    tpti: 1.39,
    tci: 1.18,
    ttci: 1.14,
    co2: 1.1480633e7,
    tco2: 7493886.0,
    cost: 2.7272045e7,
    tcost: 9941614.0,
    fuel: 539855.0,
    tfuel: 336075.0,
    spd: 55.4,
    tspd: 56.0,
    vmt_tx100seg: 550408,
    trkvmt_tx100seg: 134337,
  },
  {
    tx100id: 1200169,
    year: 2020,
    rank: 11,
    trank: 13,
    dly: 3879530.0,
    tdly: 213821.0,
    pti: 2.53,
    tpti: 2.58,
    tci: 1.86,
    ttci: 1.85,
    co2: 2.3819334e7,
    tco2: 8316221.0,
    cost: 8.4468539e7,
    tcost: 1.242224e7,
    fuel: 1172066.0,
    tfuel: 376862.0,
    spd: 39.8,
    tspd: 38.9,
    vmt_tx100seg: 1259081,
    trkvmt_tx100seg: 89257,
  },
  {
    tx100id: 1200171,
    year: 2020,
    rank: 14,
    trank: 14,
    dly: 1836411.0,
    tdly: 101844.0,
    pti: 2.67,
    tpti: 2.63,
    tci: 1.66,
    ttci: 1.63,
    co2: 8299577.0,
    tco2: 2773260.0,
    cost: 3.9665051e7,
    tcost: 5795685.0,
    fuel: 414808.0,
    tfuel: 132217.0,
    spd: 44.0,
    tspd: 44.3,
    vmt_tx100seg: 889771,
    trkvmt_tx100seg: 70644,
  },
  {
    tx100id: 8000051,
    year: 2020,
    rank: 19,
    trank: 15,
    dly: 3699264.0,
    tdly: 238955.0,
    pti: 2.39,
    tpti: 2.42,
    tci: 1.71,
    ttci: 1.72,
    co2: 2.6281149e7,
    tco2: 1.018001e7,
    cost: 8.2180067e7,
    tcost: 1.4000895e7,
    fuel: 1280879.0,
    tfuel: 458576.0,
    spd: 40.9,
    tspd: 40.3,
    vmt_tx100seg: 1183583,
    trkvmt_tx100seg: 101362,
  },
  {
    tx100id: 1200196,
    year: 2020,
    rank: 13,
    trank: 16,
    dly: 2738799.0,
    tdly: 142508.0,
    pti: 2.79,
    tpti: 2.84,
    tci: 1.8,
    ttci: 1.79,
    co2: 1.9523584e7,
    tco2: 4832058.0,
    cost: 5.9663362e7,
    tcost: 8193201.0,
    fuel: 969226.0,
    tfuel: 219369.0,
    spd: 42.3,
    tspd: 42.5,
    vmt_tx100seg: 795079,
    trkvmt_tx100seg: 50744,
  },
  {
    tx100id: 3000007,
    year: 2020,
    rank: 34,
    trank: 17,
    dly: 1774730.0,
    tdly: 158065.0,
    pti: 1.86,
    tpti: 1.86,
    tci: 1.47,
    ttci: 1.47,
    co2: 8876279.0,
    tco2: 4386880.0,
    cost: 4.0474457e7,
    tcost: 8988093.0,
    fuel: 430550.0,
    tfuel: 201267.0,
    spd: 49.4,
    tspd: 49.7,
    vmt_tx100seg: 811190,
    trkvmt_tx100seg: 97568,
  },
  {
    tx100id: 1200142,
    year: 2020,
    rank: 15,
    trank: 18,
    dly: 5148646.0,
    tdly: 257508.0,
    pti: 1.94,
    tpti: 1.98,
    tci: 1.5,
    ttci: 1.5,
    co2: 2.3314169e7,
    tco2: 8804612.0,
    cost: 1.10223734e8,
    tcost: 1.4817791e7,
    fuel: 1162659.0,
    tfuel: 407574.0,
    spd: 45.1,
    tspd: 44.6,
    vmt_tx100seg: 2306935,
    trkvmt_tx100seg: 146536,
  },
  {
    tx100id: 8000135,
    year: 2020,
    rank: 25,
    trank: 19,
    dly: 2976139.0,
    tdly: 189415.0,
    pti: 2.57,
    tpti: 2.66,
    tci: 1.69,
    ttci: 1.71,
    co2: 1.4464492e7,
    tco2: 5532030.0,
    cost: 6.5257395e7,
    tcost: 1.0826484e7,
    fuel: 729197.0,
    tfuel: 268017.0,
    spd: 46.4,
    tspd: 44.9,
    vmt_tx100seg: 1299438,
    trkvmt_tx100seg: 104236,
  },
  {
    tx100id: 1900019,
    year: 2020,
    rank: 36,
    trank: 20,
    dly: 1318164.0,
    tdly: 112178.0,
    pti: 2.23,
    tpti: 2.21,
    tci: 1.52,
    ttci: 1.5,
    co2: 1.0159807e7,
    tco2: 3747313.0,
    cost: 3.0307844e7,
    tcost: 6449367.0,
    fuel: 498290.0,
    tfuel: 170904.0,
    spd: 44.9,
    tspd: 46.1,
    vmt_tx100seg: 739788,
    trkvmt_tx100seg: 83415,
  },
  {
    tx100id: 1900035,
    year: 2020,
    rank: 29,
    trank: 21,
    dly: 1636086.0,
    tdly: 126865.0,
    pti: 2.21,
    tpti: 2.2,
    tci: 1.52,
    ttci: 1.51,
    co2: 8822845.0,
    tco2: 3805583.0,
    cost: 3.6750758e7,
    tcost: 7253822.0,
    fuel: 435910.0,
    tfuel: 178333.0,
    spd: 48.0,
    tspd: 47.9,
    vmt_tx100seg: 890269,
    trkvmt_tx100seg: 97337,
  },
  {
    tx100id: 1200186,
    year: 2020,
    rank: 22,
    trank: 22,
    dly: 2580945.0,
    tdly: 150962.0,
    pti: 2.17,
    tpti: 2.18,
    tci: 1.71,
    ttci: 1.7,
    co2: 1.585688e7,
    tco2: 5054580.0,
    cost: 5.649652e7,
    tcost: 8673847.0,
    fuel: 785492.0,
    tfuel: 231569.0,
    spd: 44.8,
    tspd: 45.6,
    vmt_tx100seg: 1028621,
    trkvmt_tx100seg: 80837,
  },
  {
    tx100id: 8000134,
    year: 2020,
    rank: 27,
    trank: 23,
    dly: 2388182.0,
    tdly: 157795.0,
    pti: 2.42,
    tpti: 2.44,
    tci: 1.58,
    ttci: 1.61,
    co2: 1.204356e7,
    tco2: 5072571.0,
    cost: 5.261949e7,
    tcost: 9060618.0,
    fuel: 605154.0,
    tfuel: 238368.0,
    spd: 45.8,
    tspd: 44.5,
    vmt_tx100seg: 1220536,
    trkvmt_tx100seg: 101237,
  },
  {
    tx100id: 1900036,
    year: 2020,
    rank: 40,
    trank: 24,
    dly: 1058914.0,
    tdly: 84182.0,
    pti: 2.04,
    tpti: 2.07,
    tci: 1.49,
    ttci: 1.45,
    co2: 6524899.0,
    tco2: 2159126.0,
    cost: 2.3956612e7,
    tcost: 4771774.0,
    fuel: 324999.0,
    tfuel: 102016.0,
    spd: 54.3,
    tspd: 54.7,
    vmt_tx100seg: 629248,
    trkvmt_tx100seg: 73279,
  },
  {
    tx100id: 1200182,
    year: 2020,
    rank: 37,
    trank: 25,
    dly: 2482466.0,
    tdly: 182757.0,
    pti: 1.86,
    tpti: 1.92,
    tci: 1.51,
    ttci: 1.54,
    co2: 1.584614e7,
    tco2: 6333028.0,
    cost: 5.5697311e7,
    tcost: 1.0522306e7,
    fuel: 777476.0,
    tfuel: 288623.0,
    spd: 47.0,
    tspd: 46.2,
    vmt_tx100seg: 1350742,
    trkvmt_tx100seg: 121302,
  },
  {
    tx100id: 1900034,
    year: 2020,
    rank: 77,
    trank: 26,
    dly: 797973.0,
    tdly: 90209.0,
    pti: 2.17,
    tpti: 2.04,
    tci: 1.41,
    ttci: 1.41,
    co2: 4010222.0,
    tco2: 2098079.0,
    cost: 1.8874842e7,
    tcost: 5096138.0,
    fuel: 200969.0,
    tfuel: 103039.0,
    spd: 50.0,
    tspd: 50.1,
    vmt_tx100seg: 528387,
    trkvmt_tx100seg: 80567,
  },
  {
    tx100id: 8000136,
    year: 2020,
    rank: 18,
    trank: 27,
    dly: 4197773.0,
    tdly: 177052.0,
    pti: 1.94,
    tpti: 1.97,
    tci: 1.61,
    ttci: 1.63,
    co2: 1.7484581e7,
    tco2: 6276951.0,
    cost: 8.8543351e7,
    tcost: 1.0218765e7,
    fuel: 865482.0,
    tfuel: 288277.0,
    spd: 43.0,
    tspd: 41.8,
    vmt_tx100seg: 1640846,
    trkvmt_tx100seg: 87518,
  },
  {
    tx100id: 1200141,
    year: 2020,
    rank: 17,
    trank: 28,
    dly: 1650100.0,
    tdly: 68583.0,
    pti: 2.58,
    tpti: 2.62,
    tci: 1.68,
    ttci: 1.72,
    co2: 9394805.0,
    tco2: 2924863.0,
    cost: 3.5069655e7,
    tcost: 4011783.0,
    fuel: 465072.0,
    tfuel: 132742.0,
    spd: 42.1,
    tspd: 40.2,
    vmt_tx100seg: 649663,
    trkvmt_tx100seg: 31789,
  },
  {
    tx100id: 8000021,
    year: 2020,
    rank: 31,
    trank: 29,
    dly: 1528576.0,
    tdly: 98294.0,
    pti: 2.11,
    tpti: 2.11,
    tci: 1.49,
    ttci: 1.49,
    co2: 1.0844703e7,
    tco2: 4336062.0,
    cost: 3.3931657e7,
    tcost: 5768360.0,
    fuel: 528873.0,
    tfuel: 196509.0,
    spd: 44.7,
    tspd: 44.6,
    vmt_tx100seg: 876841,
    trkvmt_tx100seg: 74320,
  },
  {
    tx100id: 8000008,
    year: 2020,
    rank: 42,
    trank: 30,
    dly: 1419185.0,
    tdly: 106865.0,
    pti: 2.84,
    tpti: 2.86,
    tci: 1.92,
    ttci: 1.9,
    co2: 8911352.0,
    tco2: 3554224.0,
    cost: 3.1916517e7,
    tcost: 6149895.0,
    fuel: 436596.0,
    tfuel: 162833.0,
    spd: 40.6,
    tspd: 40.6,
    vmt_tx100seg: 421957,
    trkvmt_tx100seg: 44696,
  },
  {
    tx100id: 8000084,
    year: 2020,
    rank: 41,
    trank: 31,
    dly: 2114954.0,
    tdly: 155551.0,
    pti: 2.77,
    tpti: 2.77,
    tci: 1.72,
    ttci: 1.71,
    co2: 1.2705222e7,
    tco2: 4860382.0,
    cost: 4.7384254e7,
    tcost: 8915593.0,
    fuel: 637873.0,
    tfuel: 230414.0,
    spd: 44.6,
    tspd: 44.1,
    vmt_tx100seg: 924484,
    trkvmt_tx100seg: 91904,
  },
  {
    tx100id: 8000117,
    year: 2020,
    rank: 24,
    trank: 32,
    dly: 1023547.0,
    tdly: 49300.0,
    pti: 2.54,
    tpti: 2.51,
    tci: 1.65,
    ttci: 1.67,
    co2: 7175420.0,
    tco2: 2131713.0,
    cost: 2.2146901e7,
    tcost: 2889760.0,
    fuel: 353757.0,
    tfuel: 96349.0,
    spd: 41.0,
    tspd: 41.9,
    vmt_tx100seg: 396428,
    trkvmt_tx100seg: 26568,
  },
  {
    tx100id: 8000009,
    year: 2020,
    rank: 56,
    trank: 33,
    dly: 1495650.0,
    tdly: 130589.0,
    pti: 1.51,
    tpti: 1.54,
    tci: 1.33,
    ttci: 1.34,
    co2: 8849538.0,
    tco2: 4571926.0,
    cost: 3.4181124e7,
    tcost: 7532031.0,
    fuel: 428708.0,
    tfuel: 208525.0,
    spd: 50.5,
    tspd: 49.8,
    vmt_tx100seg: 925313,
    trkvmt_tx100seg: 98241,
  },
  {
    tx100id: 1200170,
    year: 2020,
    rank: 21,
    trank: 34,
    dly: 2981058.0,
    tdly: 131693.0,
    pti: 1.85,
    tpti: 1.85,
    tci: 1.42,
    ttci: 1.41,
    co2: 9918587.0,
    tco2: 3094959.0,
    cost: 6.2823426e7,
    tcost: 7451017.0,
    fuel: 511061.0,
    tfuel: 158506.0,
    spd: 48.4,
    tspd: 48.3,
    vmt_tx100seg: 2089125,
    trkvmt_tx100seg: 124354,
  },
  {
    tx100id: 8000112,
    year: 2020,
    rank: 9,
    trank: 35,
    dly: 6700840.0,
    tdly: 179470.0,
    pti: 2.67,
    tpti: 2.76,
    tci: 1.89,
    ttci: 1.92,
    co2: 2.6576371e7,
    tco2: 5687473.0,
    cost: 1.3765719e8,
    tcost: 1.0287516e7,
    fuel: 1342477.0,
    tfuel: 266252.0,
    spd: 38.6,
    tspd: 38.1,
    vmt_tx100seg: 1957642,
    trkvmt_tx100seg: 66933,
  },
  {
    tx100id: 1900032,
    year: 2020,
    rank: 101,
    trank: 37,
    dly: 304178.0,
    tdly: 37782.0,
    pti: 1.86,
    tpti: 1.9,
    tci: 1.32,
    ttci: 1.32,
    co2: 1971058.0,
    tco2: 1015553.0,
    cost: 7370204.0,
    tcost: 2152017.0,
    fuel: 100684.0,
    tfuel: 50318.0,
    spd: 49.8,
    tspd: 50.1,
    vmt_tx100seg: 301309,
    trkvmt_tx100seg: 45730,
  },
  {
    tx100id: 1200172,
    year: 2020,
    rank: 39,
    trank: 38,
    dly: 2799944.0,
    tdly: 177993.0,
    pti: 2.17,
    tpti: 2.17,
    tci: 1.46,
    ttci: 1.44,
    co2: 1.2422627e7,
    tco2: 4489852.0,
    cost: 6.122957e7,
    tcost: 1.0083937e7,
    fuel: 619535.0,
    tfuel: 213842.0,
    spd: 51.2,
    tspd: 50.5,
    vmt_tx100seg: 2078540,
    trkvmt_tx100seg: 185230,
  },
  {
    tx100id: 8000017,
    year: 2020,
    rank: 48,
    trank: 39,
    dly: 2623244.0,
    tdly: 191184.0,
    pti: 1.83,
    tpti: 1.89,
    tci: 1.42,
    ttci: 1.44,
    co2: 1.2079385e7,
    tco2: 5455752.0,
    cost: 5.8253181e7,
    tcost: 1.0891536e7,
    fuel: 601708.0,
    tfuel: 259084.0,
    spd: 50.2,
    tspd: 49.2,
    vmt_tx100seg: 1580918,
    trkvmt_tx100seg: 140501,
  },
  {
    tx100id: 1200167,
    year: 2020,
    rank: 63,
    trank: 40,
    dly: 1026727.0,
    tdly: 85836.0,
    pti: 1.87,
    tpti: 1.95,
    tci: 1.36,
    ttci: 1.39,
    co2: 6612420.0,
    tco2: 2930731.0,
    cost: 2.3410251e7,
    tcost: 4941826.0,
    fuel: 328042.0,
    tfuel: 134042.0,
    spd: 50.2,
    tspd: 49.7,
    vmt_tx100seg: 832833,
    trkvmt_tx100seg: 86323,
  },
  {
    tx100id: 2200033,
    year: 2020,
    rank: 275,
    trank: 41,
    dly: 292160.0,
    tdly: 60123.0,
    pti: 1.38,
    tpti: 1.63,
    tci: 1.24,
    ttci: 1.33,
    co2: 5302613.0,
    tco2: 3430881.0,
    cost: 8298609.0,
    tcost: 3631038.0,
    fuel: 249208.0,
    tfuel: 153684.0,
    spd: 30.7,
    tspd: 31.0,
    vmt_tx100seg: 114470,
    trkvmt_tx100seg: 21519,
  },
  {
    tx100id: 1100022,
    year: 2020,
    rank: 802,
    trank: 42,
    dly: 129940.0,
    tdly: 59332.0,
    pti: 1.28,
    tpti: 1.36,
    tci: 1.17,
    ttci: 1.18,
    co2: 2380131.0,
    tco2: 1839851.0,
    cost: 4814170.0,
    tcost: 3396312.0,
    fuel: 110602.0,
    tfuel: 82583.0,
    spd: 23.7,
    tspd: 23.4,
    vmt_tx100seg: 36169,
    trkvmt_tx100seg: 16057,
  },
  {
    tx100id: 8000061,
    year: 2020,
    rank: 60,
    trank: 44,
    dly: 1528021.0,
    tdly: 109886.0,
    pti: 2.04,
    tpti: 2.1,
    tci: 1.53,
    ttci: 1.53,
    co2: 8459617.0,
    tco2: 3765312.0,
    cost: 3.4049687e7,
    tcost: 6324944.0,
    fuel: 418597.0,
    tfuel: 173179.0,
    spd: 47.0,
    tspd: 46.3,
    vmt_tx100seg: 717123,
    trkvmt_tx100seg: 66099,
  },
  {
    tx100id: 1900012,
    year: 2020,
    rank: 35,
    trank: 45,
    dly: 2574548.0,
    tdly: 129713.0,
    pti: 2.14,
    tpti: 2.15,
    tci: 1.62,
    ttci: 1.59,
    co2: 1.2151378e7,
    tco2: 3811940.0,
    cost: 5.5209518e7,
    tcost: 7397734.0,
    fuel: 607802.0,
    tfuel: 178414.0,
    spd: 47.6,
    tspd: 47.5,
    vmt_tx100seg: 970556,
    trkvmt_tx100seg: 61532,
  },
  {
    tx100id: 8000018,
    year: 2020,
    rank: 58,
    trank: 46,
    dly: 732879.0,
    tdly: 51033.0,
    pti: 1.58,
    tpti: 1.68,
    tci: 1.31,
    ttci: 1.36,
    co2: 2471975.0,
    tco2: 1307199.0,
    cost: 1.6105912e7,
    tcost: 2898208.0,
    fuel: 132692.0,
    tfuel: 66371.0,
    spd: 50.5,
    tspd: 48.5,
    vmt_tx100seg: 585361,
    trkvmt_tx100seg: 45753,
  },
  {
    tx100id: 3000002,
    year: 2020,
    rank: 45,
    trank: 47,
    dly: 1068123.0,
    tdly: 65719.0,
    pti: 2.91,
    tpti: 2.83,
    tci: 1.6,
    ttci: 1.61,
    co2: 5708838.0,
    tco2: 1926777.0,
    cost: 2.33951e7,
    tcost: 3750575.0,
    fuel: 283270.0,
    tfuel: 89800.0,
    spd: 49.6,
    tspd: 49.6,
    vmt_tx100seg: 550959,
    trkvmt_tx100seg: 49193,
  },
  {
    tx100id: 8000019,
    year: 2020,
    rank: 75,
    trank: 49,
    dly: 732075.0,
    tdly: 56604.0,
    pti: 2.05,
    tpti: 2.01,
    tci: 1.5,
    ttci: 1.47,
    co2: 3589403.0,
    tco2: 1678246.0,
    cost: 1.6410465e7,
    tcost: 3240997.0,
    fuel: 184739.0,
    tfuel: 83078.0,
    spd: 45.9,
    tspd: 46.2,
    vmt_tx100seg: 478214,
    trkvmt_tx100seg: 49441,
  },
  {
    tx100id: 1200188,
    year: 2020,
    rank: 30,
    trank: 50,
    dly: 2623992.0,
    tdly: 119144.0,
    pti: 2.04,
    tpti: 1.93,
    tci: 1.44,
    ttci: 1.4,
    co2: 1.0271907e7,
    tco2: 2706160.0,
    cost: 5.5597372e7,
    tcost: 6733862.0,
    fuel: 528722.0,
    tfuel: 140228.0,
    spd: 49.0,
    tspd: 50.3,
    vmt_tx100seg: 1631909,
    trkvmt_tx100seg: 110310,
  },
  {
    tx100id: 8000155,
    year: 2020,
    rank: 4,
    trank: 51,
    dly: 1588464.0,
    tdly: 21818.0,
    pti: 3.02,
    tpti: 3.22,
    tci: 2.17,
    ttci: 2.25,
    co2: 9071391.0,
    tco2: 814102.0,
    cost: 3.2244105e7,
    tcost: 1259759.0,
    fuel: 458797.0,
    tfuel: 37387.0,
    spd: 27.9,
    tspd: 27.5,
    vmt_tx100seg: 254150,
    trkvmt_tx100seg: 4299,
  },
  {
    tx100id: 1200144,
    year: 2020,
    rank: 28,
    trank: 52,
    dly: 2939202.0,
    tdly: 115767.0,
    pti: 1.68,
    tpti: 1.73,
    tci: 1.43,
    ttci: 1.44,
    co2: 1.2666983e7,
    tco2: 3559427.0,
    cost: 6.1763169e7,
    tcost: 6614755.0,
    fuel: 635559.0,
    tfuel: 168103.0,
    spd: 50.6,
    tspd: 50.4,
    vmt_tx100seg: 1495586,
    trkvmt_tx100seg: 76601,
  },
  {
    tx100id: 8000113,
    year: 2020,
    rank: 23,
    trank: 53,
    dly: 2892586.0,
    tdly: 92164.0,
    pti: 2.39,
    tpti: 2.39,
    tci: 1.62,
    ttci: 1.62,
    co2: 9900191.0,
    tco2: 2423073.0,
    cost: 5.9757038e7,
    tcost: 5240960.0,
    fuel: 507089.0,
    tfuel: 120591.0,
    spd: 44.2,
    tspd: 43.8,
    vmt_tx100seg: 1442778,
    trkvmt_tx100seg: 59372,
  },
  {
    tx100id: 3000005,
    year: 2020,
    rank: 70,
    trank: 54,
    dly: 1307791.0,
    tdly: 87083.0,
    pti: 2.19,
    tpti: 2.15,
    tci: 1.42,
    ttci: 1.41,
    co2: 6504456.0,
    tco2: 2239175.0,
    cost: 2.8817927e7,
    tcost: 4936366.0,
    fuel: 324027.0,
    tfuel: 105797.0,
    spd: 50.8,
    tspd: 51.7,
    vmt_tx100seg: 1012538,
    trkvmt_tx100seg: 101297,
  },
  {
    tx100id: 1200200,
    year: 2020,
    rank: 178,
    trank: 55,
    dly: 736198.0,
    tdly: 92924.0,
    pti: 1.53,
    tpti: 1.55,
    tci: 1.27,
    ttci: 1.26,
    co2: 4630144.0,
    tco2: 2646242.0,
    cost: 1.7866342e7,
    tcost: 5303053.0,
    fuel: 232636.0,
    tfuel: 126718.0,
    spd: 51.2,
    tspd: 51.3,
    vmt_tx100seg: 734443,
    trkvmt_tx100seg: 119233,
  },
  {
    tx100id: 8000077,
    year: 2020,
    rank: 38,
    trank: 57,
    dly: 774898.0,
    tdly: 34092.0,
    pti: 2.17,
    tpti: 2.2,
    tci: 1.61,
    ttci: 1.59,
    co2: 3379922.0,
    tco2: 1177327.0,
    cost: 1.6421881e7,
    tcost: 1965210.0,
    fuel: 172260.0,
    tfuel: 55106.0,
    spd: 44.7,
    tspd: 44.0,
    vmt_tx100seg: 414777,
    trkvmt_tx100seg: 23858,
  },
  {
    tx100id: 1200187,
    year: 2020,
    rank: 71,
    trank: 58,
    dly: 1231852.0,
    tdly: 79812.0,
    pti: 1.76,
    tpti: 1.78,
    tci: 1.37,
    ttci: 1.36,
    co2: 7212856.0,
    tco2: 3031066.0,
    cost: 2.7203098e7,
    tcost: 4626392.0,
    fuel: 361175.0,
    tfuel: 138960.0,
    spd: 47.3,
    tspd: 47.3,
    vmt_tx100seg: 947359,
    trkvmt_tx100seg: 79168,
  },
  {
    tx100id: 8000040,
    year: 2020,
    rank: 177,
    trank: 59,
    dly: 1010701.0,
    tdly: 119619.0,
    pti: 1.44,
    tpti: 1.41,
    tci: 1.19,
    ttci: 1.18,
    co2: 2719952.0,
    tco2: 1543246.0,
    cost: 2.3822513e7,
    tcost: 6621414.0,
    fuel: 146283.0,
    tfuel: 84766.0,
    spd: 56.5,
    tspd: 56.4,
    vmt_tx100seg: 1553148,
    trkvmt_tx100seg: 251369,
  },
  {
    tx100id: 1200185,
    year: 2020,
    rank: 32,
    trank: 60,
    dly: 2649097.0,
    tdly: 101261.0,
    pti: 1.84,
    tpti: 1.88,
    tci: 1.44,
    ttci: 1.44,
    co2: 1.3481788e7,
    tco2: 3530679.0,
    cost: 5.5810012e7,
    tcost: 5829553.0,
    fuel: 673998.0,
    tfuel: 161391.0,
    spd: 48.8,
    tspd: 49.1,
    vmt_tx100seg: 1434706,
    trkvmt_tx100seg: 67513,
  },
  {
    tx100id: 1200148,
    year: 2020,
    rank: 72,
    trank: 63,
    dly: 1342358.0,
    tdly: 84322.0,
    pti: 1.74,
    tpti: 1.78,
    tci: 1.31,
    ttci: 1.31,
    co2: 4192840.0,
    tco2: 1579497.0,
    cost: 2.9126642e7,
    tcost: 4728624.0,
    fuel: 219283.0,
    tfuel: 84595.0,
    spd: 53.3,
    tspd: 52.8,
    vmt_tx100seg: 1543330,
    trkvmt_tx100seg: 115580,
  },
  {
    tx100id: 8000039,
    year: 2020,
    rank: 82,
    trank: 66,
    dly: 1971062.0,
    tdly: 126493.0,
    pti: 1.63,
    tpti: 1.57,
    tci: 1.29,
    ttci: 1.27,
    co2: 5978651.0,
    tco2: 2186751.0,
    cost: 4.2828309e7,
    tcost: 7061365.0,
    fuel: 307983.0,
    tfuel: 113089.0,
    spd: 54.2,
    tspd: 54.2,
    vmt_tx100seg: 1864792,
    trkvmt_tx100seg: 173487,
  },
  {
    tx100id: 8000196,
    year: 2020,
    rank: 112,
    trank: 68,
    dly: 535833.0,
    tdly: 41166.0,
    pti: 1.61,
    tpti: 1.85,
    tci: 1.42,
    ttci: 1.51,
    co2: 5645267.0,
    tco2: 1850466.0,
    cost: 1.234891e7,
    tcost: 2421998.0,
    fuel: 277192.0,
    tfuel: 83525.0,
    spd: 31.7,
    tspd: 33.0,
    vmt_tx100seg: 98999,
    trkvmt_tx100seg: 8687,
  },
  {
    tx100id: 8000048,
    year: 2020,
    rank: 81,
    trank: 69,
    dly: 1616070.0,
    tdly: 97387.0,
    pti: 1.9,
    tpti: 1.92,
    tci: 1.43,
    ttci: 1.43,
    co2: 6762343.0,
    tco2: 2657637.0,
    cost: 3.5115063e7,
    tcost: 5545149.0,
    fuel: 343525.0,
    tfuel: 131574.0,
    spd: 47.9,
    tspd: 47.9,
    vmt_tx100seg: 1157263,
    trkvmt_tx100seg: 92952,
  },
  {
    tx100id: 8000052,
    year: 2020,
    rank: 107,
    trank: 70,
    dly: 1249958.0,
    tdly: 91980.0,
    pti: 1.79,
    tpti: 1.82,
    tci: 1.32,
    ttci: 1.31,
    co2: 5132115.0,
    tco2: 2004449.0,
    cost: 2.7725007e7,
    tcost: 5178599.0,
    fuel: 259376.0,
    tfuel: 97892.0,
    spd: 52.5,
    tspd: 52.1,
    vmt_tx100seg: 1099683,
    trkvmt_tx100seg: 105490,
  },
  {
    tx100id: 1900038,
    year: 2020,
    rank: 363,
    trank: 72,
    dly: 401910.0,
    tdly: 57825.0,
    pti: 1.82,
    tpti: 1.82,
    tci: 1.32,
    ttci: 1.31,
    co2: 2723524.0,
    tco2: 1656136.0,
    cost: 1.0019548e7,
    tcost: 3303491.0,
    fuel: 135964.0,
    tfuel: 81000.0,
    spd: 51.3,
    tspd: 51.4,
    vmt_tx100seg: 388732,
    trkvmt_tx100seg: 77998,
  },
  {
    tx100id: 8000038,
    year: 2020,
    rank: 116,
    trank: 73,
    dly: 1294140.0,
    tdly: 91322.0,
    pti: 1.52,
    tpti: 1.55,
    tci: 1.22,
    ttci: 1.23,
    co2: 3172001.0,
    tco2: 1305573.0,
    cost: 2.8312087e7,
    tcost: 5063382.0,
    fuel: 164770.0,
    tfuel: 69091.0,
    spd: 55.7,
    tspd: 55.0,
    vmt_tx100seg: 1556351,
    trkvmt_tx100seg: 137732,
  },
  {
    tx100id: 1200159,
    year: 2020,
    rank: 119,
    trank: 75,
    dly: 822603.0,
    tdly: 58308.0,
    pti: 1.65,
    tpti: 1.71,
    tci: 1.37,
    ttci: 1.4,
    co2: 3045547.0,
    tco2: 1604600.0,
    cost: 1.8135823e7,
    tcost: 3321347.0,
    fuel: 158706.0,
    tfuel: 80282.0,
    spd: 51.5,
    tspd: 50.3,
    vmt_tx100seg: 704254,
    trkvmt_tx100seg: 58193,
  },
  {
    tx100id: 1200156,
    year: 2020,
    rank: 46,
    trank: 77,
    dly: 2158128.0,
    tdly: 84382.0,
    pti: 2.35,
    tpti: 2.22,
    tci: 1.55,
    ttci: 1.5,
    co2: 8794390.0,
    tco2: 2565323.0,
    cost: 4.5287948e7,
    tcost: 4822322.0,
    fuel: 451862.0,
    tfuel: 125220.0,
    spd: 46.6,
    tspd: 47.6,
    vmt_tx100seg: 1247950,
    trkvmt_tx100seg: 69384,
  },
  {
    tx100id: 1200147,
    year: 2020,
    rank: 111,
    trank: 80,
    dly: 1355130.0,
    tdly: 88945.0,
    pti: 1.36,
    tpti: 1.37,
    tci: 1.2,
    ttci: 1.2,
    co2: 2068426.0,
    tco2: 1027040.0,
    cost: 2.9298676e7,
    tcost: 4928836.0,
    fuel: 125375.0,
    tfuel: 68387.0,
    spd: 55.8,
    tspd: 55.0,
    vmt_tx100seg: 2053337,
    trkvmt_tx100seg: 155717,
  },
  {
    tx100id: 3000006,
    year: 2020,
    rank: 118,
    trank: 84,
    dly: 714795.0,
    tdly: 47528.0,
    pti: 1.88,
    tpti: 1.87,
    tci: 1.34,
    ttci: 1.3,
    co2: 2918687.0,
    tco2: 1193900.0,
    cost: 1.5671666e7,
    tcost: 2690977.0,
    fuel: 144665.0,
    tfuel: 56393.0,
    spd: 52.5,
    tspd: 53.9,
    vmt_tx100seg: 714797,
    trkvmt_tx100seg: 79360,
  },
  {
    tx100id: 8000011,
    year: 2020,
    rank: 102,
    trank: 88,
    dly: 818464.0,
    tdly: 48524.0,
    pti: 1.71,
    tpti: 1.7,
    tci: 1.28,
    ttci: 1.27,
    co2: 2882671.0,
    tco2: 1030953.0,
    cost: 1.7696684e7,
    tcost: 2733736.0,
    fuel: 149377.0,
    tfuel: 53893.0,
    spd: 54.2,
    tspd: 54.2,
    vmt_tx100seg: 941040,
    trkvmt_tx100seg: 77414,
  },
  {
    tx100id: 8000101,
    year: 2020,
    rank: 252,
    trank: 89,
    dly: 554880.0,
    tdly: 55172.0,
    pti: 1.63,
    tpti: 1.68,
    tci: 1.27,
    ttci: 1.29,
    co2: 5264971.0,
    tco2: 2670515.0,
    cost: 1.3147015e7,
    tcost: 3268413.0,
    fuel: 253116.0,
    tfuel: 120548.0,
    spd: 45.9,
    tspd: 44.8,
    vmt_tx100seg: 360548,
    trkvmt_tx100seg: 40987,
  },
  {
    tx100id: 8000062,
    year: 2020,
    rank: 65,
    trank: 92,
    dly: 957088.0,
    tdly: 40302.0,
    pti: 1.92,
    tpti: 1.92,
    tci: 1.51,
    ttci: 1.51,
    co2: 5082650.0,
    tco2: 1487572.0,
    cost: 2.0317498e7,
    tcost: 2329562.0,
    fuel: 254441.0,
    tfuel: 68507.0,
    spd: 45.4,
    tspd: 45.3,
    vmt_tx100seg: 513399,
    trkvmt_tx100seg: 27544,
  },
  {
    tx100id: 1200112,
    year: 2020,
    rank: 91,
    trank: 93,
    dly: 1140978.0,
    tdly: 61783.0,
    pti: 1.59,
    tpti: 1.59,
    tci: 1.25,
    ttci: 1.25,
    co2: 3196587.0,
    tco2: 1345478.0,
    cost: 2.4382463e7,
    tcost: 3486484.0,
    fuel: 172673.0,
    tfuel: 71329.0,
    spd: 55.0,
    tspd: 53.7,
    vmt_tx100seg: 1381448,
    trkvmt_tx100seg: 95342,
  },
  {
    tx100id: 1000013,
    year: 2020,
    rank: 142,
    trank: 97,
    dly: 495457.0,
    tdly: 34265.0,
    pti: 1.38,
    tpti: 1.4,
    tci: 1.16,
    ttci: 1.15,
    co2: 3507640.0,
    tco2: 1451736.0,
    cost: 1.1082479e7,
    tcost: 2004908.0,
    fuel: 171297.0,
    tfuel: 65661.0,
    spd: 52.4,
    tspd: 52.0,
    vmt_tx100seg: 686399,
    trkvmt_tx100seg: 56646,
  },
  {
    tx100id: 8000050,
    year: 2020,
    rank: 210,
    trank: 99,
    dly: 632729.0,
    tdly: 52315.0,
    pti: 1.58,
    tpti: 1.65,
    tci: 1.21,
    ttci: 1.23,
    co2: 3139569.0,
    tco2: 1392839.0,
    cost: 1.4304428e7,
    tcost: 2970465.0,
    fuel: 161485.0,
    tfuel: 66618.0,
    spd: 57.3,
    tspd: 55.5,
    vmt_tx100seg: 768020,
    trkvmt_tx100seg: 71866,
  },
  {
    tx100id: 8000060,
    year: 2020,
    rank: 245,
    trank: 100,
    dly: 616736.0,
    tdly: 55612.0,
    pti: 1.53,
    tpti: 1.56,
    tci: 1.22,
    ttci: 1.22,
    co2: 2775567.0,
    tco2: 1421687.0,
    cost: 1.4070275e7,
    tcost: 3156474.0,
    fuel: 144086.0,
    tfuel: 70710.0,
    spd: 55.5,
    tspd: 54.1,
    vmt_tx100seg: 863571,
    trkvmt_tx100seg: 89038,
  },
  {
    tx100id: 1200165,
    year: 2020,
    rank: 301,
    trank: 108,
    dly: 531670.0,
    tdly: 51077.0,
    pti: 1.24,
    tpti: 1.26,
    tci: 1.15,
    ttci: 1.19,
    co2: 1174702.0,
    tco2: 648870.0,
    cost: 1.2096123e7,
    tcost: 2827380.0,
    fuel: 66276.0,
    tfuel: 36662.0,
    spd: 58.2,
    tspd: 56.6,
    vmt_tx100seg: 822512,
    trkvmt_tx100seg: 76354,
  },
  {
    tx100id: 1400201,
    year: 2020,
    rank: 165,
    trank: 110,
    dly: 263997.0,
    tdly: 18433.0,
    pti: 1.9,
    tpti: 1.83,
    tci: 1.44,
    ttci: 1.34,
    co2: 2577066.0,
    tco2: 679065.0,
    cost: 5994597.0,
    tcost: 1064935.0,
    fuel: 127803.0,
    tfuel: 30938.0,
    spd: 27.1,
    tspd: 28.8,
    vmt_tx100seg: 48923,
    trkvmt_tx100seg: 4696,
  },
  {
    tx100id: 8000198,
    year: 2020,
    rank: 128,
    trank: 112,
    dly: 921202.0,
    tdly: 53338.0,
    pti: 1.39,
    tpti: 1.58,
    tci: 1.26,
    ttci: 1.35,
    co2: 8824223.0,
    tco2: 2590621.0,
    cost: 2.0521772e7,
    tcost: 3156885.0,
    fuel: 435662.0,
    tfuel: 117530.0,
    spd: 35.3,
    tspd: 36.3,
    vmt_tx100seg: 292063,
    trkvmt_tx100seg: 17246,
  },
  {
    tx100id: 8000012,
    year: 2020,
    rank: 201,
    trank: 119,
    dly: 803573.0,
    tdly: 57207.0,
    pti: 1.66,
    tpti: 1.68,
    tci: 1.27,
    ttci: 1.26,
    co2: 2963412.0,
    tco2: 1087525.0,
    cost: 1.7715404e7,
    tcost: 3202466.0,
    fuel: 150913.0,
    tfuel: 54920.0,
    spd: 55.1,
    tspd: 54.8,
    vmt_tx100seg: 1036017,
    trkvmt_tx100seg: 93804,
  },
  {
    tx100id: 8000053,
    year: 2020,
    rank: 354,
    trank: 121,
    dly: 667576.0,
    tdly: 65163.0,
    pti: 1.63,
    tpti: 1.58,
    tci: 1.24,
    ttci: 1.22,
    co2: 2444498.0,
    tco2: 1033156.0,
    cost: 1.5322883e7,
    tcost: 3620492.0,
    fuel: 123308.0,
    tfuel: 50649.0,
    spd: 56.0,
    tspd: 55.9,
    vmt_tx100seg: 914908,
    trkvmt_tx100seg: 131090,
  },
  {
    tx100id: 1400800,
    year: 2020,
    rank: 930,
    trank: 123,
    dly: 44450.0,
    tdly: 9795.0,
    pti: 1.35,
    tpti: 1.37,
    tci: 1.21,
    ttci: 1.19,
    co2: 615959.0,
    tco2: 325465.0,
    cost: 1260999.0,
    tcost: 562551.0,
    fuel: 29543.0,
    tfuel: 14716.0,
    spd: 21.3,
    tspd: 21.8,
    vmt_tx100seg: 12060,
    trkvmt_tx100seg: 3217,
  },
  {
    tx100id: 8000081,
    year: 2020,
    rank: 79,
    trank: 124,
    dly: 1043612.0,
    tdly: 38450.0,
    pti: 1.92,
    tpti: 1.92,
    tci: 1.4,
    ttci: 1.37,
    co2: 4074588.0,
    tco2: 1130679.0,
    cost: 2.1808158e7,
    tcost: 2197400.0,
    fuel: 212727.0,
    tfuel: 55783.0,
    spd: 50.8,
    tspd: 50.1,
    vmt_tx100seg: 786791,
    trkvmt_tx100seg: 38081,
  },
  {
    tx100id: 8000133,
    year: 2020,
    rank: 223,
    trank: 125,
    dly: 641894.0,
    tdly: 46627.0,
    pti: 1.43,
    tpti: 1.44,
    tci: 1.17,
    ttci: 1.17,
    co2: 1115047.0,
    tco2: 443525.0,
    cost: 1.4042304e7,
    tcost: 2565803.0,
    fuel: 63365.0,
    tfuel: 28309.0,
    spd: 57.3,
    tspd: 57.2,
    vmt_tx100seg: 1079595,
    trkvmt_tx100seg: 109700,
  },
  {
    tx100id: 3000012,
    year: 2020,
    rank: 43,
    trank: 131,
    dly: 1095732.0,
    tdly: 26309.0,
    pti: 2.0,
    tpti: 2.24,
    tci: 1.5,
    ttci: 1.6,
    co2: 8673777.0,
    tco2: 1005973.0,
    cost: 2.2919296e7,
    tcost: 1523079.0,
    fuel: 437338.0,
    tfuel: 46004.0,
    spd: 40.9,
    tspd: 40.0,
    vmt_tx100seg: 240998,
    trkvmt_tx100seg: 6568,
  },
  {
    tx100id: 1200094,
    year: 2020,
    rank: 710,
    trank: 133,
    dly: 340122.0,
    tdly: 52037.0,
    pti: 1.43,
    tpti: 1.41,
    tci: 1.15,
    ttci: 1.14,
    co2: 1279825.0,
    tco2: 693671.0,
    cost: 8479749.0,
    tcost: 2894281.0,
    fuel: 72779.0,
    tfuel: 42607.0,
    spd: 58.3,
    tspd: 57.8,
    vmt_tx100seg: 615699,
    trkvmt_tx100seg: 115813,
  },
  {
    tx100id: 7000061,
    year: 2020,
    rank: 125,
    trank: 140,
    dly: 428277.0,
    tdly: 20597.0,
    pti: 1.45,
    tpti: 1.62,
    tci: 1.26,
    ttci: 1.31,
    co2: 4442080.0,
    tco2: 820303.0,
    cost: 9435676.0,
    tcost: 1194625.0,
    fuel: 222578.0,
    tfuel: 37740.0,
    spd: 25.7,
    tspd: 27.5,
    vmt_tx100seg: 113334,
    trkvmt_tx100seg: 5703,
  },
  {
    tx100id: 3000059,
    year: 2020,
    rank: 544,
    trank: 142,
    dly: 719455.0,
    tdly: 82946.0,
    pti: 1.23,
    tpti: 1.17,
    tci: 1.08,
    ttci: 1.08,
    co2: 1728274.0,
    tco2: 880411.0,
    cost: 1.6861831e7,
    tcost: 4574876.0,
    fuel: 97661.0,
    tfuel: 53707.0,
    spd: 61.0,
    tspd: 61.1,
    vmt_tx100seg: 1574322,
    trkvmt_tx100seg: 249384,
  },
  {
    tx100id: 8000387,
    year: 2020,
    rank: 888,
    trank: 155,
    dly: 176835.0,
    tdly: 30825.0,
    pti: 1.19,
    tpti: 1.27,
    tci: 1.12,
    ttci: 1.14,
    co2: 2959914.0,
    tco2: 1802999.0,
    cost: 4799474.0,
    tcost: 1864919.0,
    fuel: 140109.0,
    tfuel: 81066.0,
    spd: 35.2,
    tspd: 35.0,
    vmt_tx100seg: 132756,
    trkvmt_tx100seg: 22624,
  },
  {
    tx100id: 1010102,
    year: 2020,
    rank: 106,
    trank: 161,
    dly: 680985.0,
    tdly: 25836.0,
    pti: 1.74,
    tpti: 1.79,
    tci: 1.35,
    ttci: 1.34,
    co2: 3824791.0,
    tco2: 1020738.0,
    cost: 1.4382756e7,
    tcost: 1502463.0,
    fuel: 190073.0,
    tfuel: 46300.0,
    spd: 49.4,
    tspd: 49.4,
    vmt_tx100seg: 480321,
    trkvmt_tx100seg: 22648,
  },
  {
    tx100id: 8000114,
    year: 2020,
    rank: 136,
    trank: 164,
    dly: 1429810.0,
    tdly: 63401.0,
    pti: 1.54,
    tpti: 1.54,
    tci: 1.24,
    ttci: 1.23,
    co2: 3579480.0,
    tco2: 1042025.0,
    cost: 3.0000994e7,
    tcost: 3538266.0,
    fuel: 187937.0,
    tfuel: 57280.0,
    spd: 55.6,
    tspd: 55.5,
    vmt_tx100seg: 1895585,
    trkvmt_tx100seg: 106899,
  },
  {
    tx100id: 8000130,
    year: 2020,
    rank: 511,
    trank: 178,
    dly: 403755.0,
    tdly: 37172.0,
    pti: 1.42,
    tpti: 1.43,
    tci: 1.18,
    ttci: 1.18,
    co2: 1351048.0,
    tco2: 634975.0,
    cost: 9187071.0,
    tcost: 2080153.0,
    fuel: 74047.0,
    tfuel: 35860.0,
    spd: 55.1,
    tspd: 54.6,
    vmt_tx100seg: 513090,
    trkvmt_tx100seg: 54989,
  },
  {
    tx100id: 2400010,
    year: 2020,
    rank: 951,
    trank: 179,
    dly: 145684.0,
    tdly: 24863.0,
    pti: 1.2,
    tpti: 1.08,
    tci: 1.03,
    ttci: 1.02,
    co2: 1169095.0,
    tco2: 764181.0,
    cost: 3785253.0,
    tcost: 1423531.0,
    fuel: 55409.0,
    tfuel: 34646.0,
    spd: 62.9,
    tspd: 63.0,
    vmt_tx100seg: 447343,
    trkvmt_tx100seg: 135303,
  },
  {
    tx100id: 8000131,
    year: 2020,
    rank: 523,
    trank: 187,
    dly: 339828.0,
    tdly: 30546.0,
    pti: 1.69,
    tpti: 1.69,
    tci: 1.26,
    ttci: 1.25,
    co2: 1259914.0,
    tco2: 529309.0,
    cost: 7717716.0,
    tcost: 1709009.0,
    fuel: 66430.0,
    tfuel: 28857.0,
    spd: 55.9,
    tspd: 56.0,
    vmt_tx100seg: 403846,
    trkvmt_tx100seg: 46851,
  },
  {
    tx100id: 1100023,
    year: 2020,
    rank: 433,
    trank: 202,
    dly: 325004.0,
    tdly: 24612.0,
    pti: 1.31,
    tpti: 1.34,
    tci: 1.16,
    ttci: 1.18,
    co2: 1827425.0,
    tco2: 722981.0,
    cost: 7287022.0,
    tcost: 1403597.0,
    fuel: 90271.0,
    tfuel: 33765.0,
    spd: 58.2,
    tspd: 57.5,
    vmt_tx100seg: 470556,
    trkvmt_tx100seg: 39789,
  },
  {
    tx100id: 1900072,
    year: 2020,
    rank: 126,
    trank: 249,
    dly: 469059.0,
    tdly: 14449.0,
    pti: 1.39,
    tpti: 1.55,
    tci: 1.23,
    ttci: 1.3,
    co2: 4333649.0,
    tco2: 648424.0,
    cost: 9995652.0,
    tcost: 848666.0,
    fuel: 217537.0,
    tfuel: 29462.0,
    spd: 32.5,
    tspd: 33.9,
    vmt_tx100seg: 136049,
    trkvmt_tx100seg: 4754,
  },
  {
    tx100id: 2400013,
    year: 2020,
    rank: 1261,
    trank: 306,
    dly: 40399.0,
    tdly: 8537.0,
    pti: 1.11,
    tpti: 1.05,
    tci: 1.03,
    ttci: 1.03,
    co2: 21735.0,
    tco2: 11893.0,
    cost: 1071680.0,
    tcost: 460565.0,
    fuel: 2010.0,
    tfuel: 1472.0,
    spd: 63.2,
    tspd: 63.2,
    vmt_tx100seg: 238529,
    trkvmt_tx100seg: 62794,
  },
  {
    tx100id: 1010103,
    year: 2020,
    rank: 817,
    trank: 433,
    dly: 228919.0,
    tdly: 17500.0,
    pti: 1.19,
    tpti: 1.23,
    tci: 1.09,
    ttci: 1.11,
    co2: 198648.0,
    tco2: 184768.0,
    cost: 5029099.0,
    tcost: 972657.0,
    fuel: 18971.0,
    tfuel: 14800.0,
    spd: 60.2,
    tspd: 58.6,
    vmt_tx100seg: 688164,
    trkvmt_tx100seg: 46199,
  },
  {
    tx100id: 2400030,
    year: 2020,
    rank: 927,
    trank: 464,
    dly: 160642.0,
    tdly: 13466.0,
    pti: 1.46,
    tpti: 1.42,
    tci: 1.22,
    ttci: 1.24,
    co2: 1609551.0,
    tco2: 796487.0,
    cost: 3730217.0,
    tcost: 814919.0,
    fuel: 77427.0,
    tfuel: 35932.0,
    spd: 42.3,
    tspd: 43.9,
    vmt_tx100seg: 106075,
    trkvmt_tx100seg: 9441,
  },
  {
    tx100id: 8000149,
    year: 2020,
    rank: 1150,
    trank: 477,
    dly: 234048.0,
    tdly: 27164.0,
    pti: 1.23,
    tpti: 1.21,
    tci: 1.1,
    ttci: 1.11,
    co2: 1604191.0,
    tco2: 1020573.0,
    cost: 5608334.0,
    tcost: 1574871.0,
    fuel: 77705.0,
    tfuel: 46610.0,
    spd: 58.6,
    tspd: 57.8,
    vmt_tx100seg: 371459,
    trkvmt_tx100seg: 49705,
  },
  {
    tx100id: 1900086,
    year: 2020,
    rank: 1600,
    trank: 1157,
    dly: 15610.0,
    tdly: 4467.0,
    pti: 1.03,
    tpti: 1.04,
    tci: 1.01,
    ttci: 1.02,
    co2: 0.0,
    tco2: 0.0,
    cost: 453486.0,
    tcost: 240215.0,
    fuel: 455.0,
    tfuel: 455.0,
    spd: 64.3,
    tspd: 63.8,
    vmt_tx100seg: 241191,
    trkvmt_tx100seg: 56833,
  },
  {
    tx100id: 3000058,
    year: 2020,
    rank: 1633,
    trank: 1430,
    dly: 22321.0,
    tdly: 4879.0,
    pti: 1.01,
    tpti: 1.01,
    tci: 1.0,
    ttci: 1.0,
    co2: 0.0,
    tco2: 0.0,
    cost: 596265.0,
    tcost: 262420.0,
    fuel: 518.0,
    tfuel: 518.0,
    spd: 64.8,
    tspd: 64.6,
    vmt_tx100seg: 1000084,
    trkvmt_tx100seg: 157553,
  },
  {
    tx100id: 3000004,
    year: 2021,
    rank: 2,
    trank: 1,
    dly: 5016646.0,
    tdly: 465907.0,
    pti: 2.52,
    tpti: 2.6,
    tci: 1.76,
    ttci: 1.74,
    co2: 4.1791813e7,
    tco2: 1.8736901e7,
    cost: 1.18786829e8,
    tcost: 2.458505e7,
    fuel: 2022716.0,
    tfuel: 845459.0,
    spd: 43.8,
    tspd: 43.9,
    vmt_tx100seg: 1214413.866,
    trkvmt_tx100seg: 150700.62,
  },
  {
    tx100id: 8000010,
    year: 2021,
    rank: 9,
    trank: 2,
    dly: 1162287.0,
    tdly: 110593.0,
    pti: 2.1,
    tpti: 2.19,
    tci: 1.59,
    ttci: 1.59,
    co2: 1.0001277e7,
    tco2: 4790926.0,
    cost: 2.7627427e7,
    tcost: 5868867.0,
    fuel: 482740.0,
    tfuel: 216446.0,
    spd: 42.1,
    tspd: 41.9,
    vmt_tx100seg: 449148.908,
    trkvmt_tx100seg: 54245.768,
  },
  {
    tx100id: 1200184,
    year: 2021,
    rank: 1,
    trank: 3,
    dly: 3324650.0,
    tdly: 108170.0,
    pti: 2.92,
    tpti: 3.24,
    tci: 2.0,
    ttci: 2.08,
    co2: 1.9642001e7,
    tco2: 4054585.0,
    cost: 7.2162479e7,
    tcost: 5654759.0,
    fuel: 980897.0,
    tfuel: 185124.0,
    spd: 37.2,
    tspd: 36.9,
    vmt_tx100seg: 687329.28,
    trkvmt_tx100seg: 26338.944,
  },
  {
    tx100id: 1200140,
    year: 2021,
    rank: 5,
    trank: 4,
    dly: 1137261.0,
    tdly: 86800.0,
    pti: 2.2,
    tpti: 2.46,
    tci: 1.63,
    ttci: 1.7,
    co2: 8663427.0,
    tco2: 3603715.0,
    cost: 2.6302792e7,
    tcost: 4584551.0,
    fuel: 421953.0,
    tfuel: 163207.0,
    spd: 41.8,
    tspd: 38.9,
    vmt_tx100seg: 383682.61,
    trkvmt_tx100seg: 31169.56,
  },
  {
    tx100id: 1200139,
    year: 2021,
    rank: 3,
    trank: 5,
    dly: 3327910.0,
    tdly: 128795.0,
    pti: 2.44,
    tpti: 2.75,
    tci: 1.7,
    ttci: 1.77,
    co2: 1.8585449e7,
    tco2: 4361768.0,
    cost: 7.2721231e7,
    tcost: 6692387.0,
    fuel: 931952.0,
    tfuel: 198932.0,
    spd: 46.4,
    tspd: 43.9,
    vmt_tx100seg: 1156175.89,
    trkvmt_tx100seg: 47706.926,
  },
  {
    tx100id: 3000003,
    year: 2021,
    rank: 22,
    trank: 6,
    dly: 785337.0,
    tdly: 90339.0,
    pti: 1.57,
    tpti: 1.6,
    tci: 1.29,
    ttci: 1.32,
    co2: 4544971.0,
    tco2: 2590508.0,
    cost: 1.8877921e7,
    tcost: 4655308.0,
    fuel: 218397.0,
    tfuel: 118633.0,
    spd: 54.4,
    tspd: 53.2,
    vmt_tx100seg: 519621.646,
    trkvmt_tx100seg: 74367.794,
  },
  {
    tx100id: 8000020,
    year: 2021,
    rank: 8,
    trank: 7,
    dly: 1857305.0,
    tdly: 114018.0,
    pti: 1.81,
    tpti: 1.84,
    tci: 1.41,
    ttci: 1.39,
    co2: 1.3464983e7,
    tco2: 5136239.0,
    cost: 4.2097099e7,
    tcost: 6063562.0,
    fuel: 657786.0,
    tfuel: 232700.0,
    spd: 46.6,
    tspd: 45.9,
    vmt_tx100seg: 1033159.832,
    trkvmt_tx100seg: 85507.898,
  },
  {
    tx100id: 1400201,
    year: 2021,
    rank: 37,
    trank: 8,
    dly: 286332.0,
    tdly: 45193.0,
    pti: 1.96,
    tpti: 1.91,
    tci: 1.44,
    ttci: 1.34,
    co2: 3480117.0,
    tco2: 1523388.0,
    cost: 7418127.0,
    tcost: 2349638.0,
    fuel: 168883.0,
    tfuel: 69021.0,
    spd: 23.9,
    tspd: 24.3,
    vmt_tx100seg: 50108.382,
    trkvmt_tx100seg: 9817.894,
  },
  {
    tx100id: 1200196,
    year: 2021,
    rank: 17,
    trank: 9,
    dly: 1141173.0,
    tdly: 95360.0,
    pti: 1.73,
    tpti: 1.88,
    tci: 1.39,
    ttci: 1.47,
    co2: 8589539.0,
    tco2: 3211541.0,
    cost: 2.661445e7,
    tcost: 4957193.0,
    fuel: 421207.0,
    tfuel: 146470.0,
    spd: 47.8,
    tspd: 46.9,
    vmt_tx100seg: 683637.508,
    trkvmt_tx100seg: 51694.224,
  },
  {
    tx100id: 1200183,
    year: 2021,
    rank: 14,
    trank: 10,
    dly: 1682971.0,
    tdly: 112687.0,
    pti: 1.78,
    tpti: 1.87,
    tci: 1.42,
    ttci: 1.45,
    co2: 1.0382971e7,
    tco2: 4555642.0,
    cost: 3.8229544e7,
    tcost: 5939572.0,
    fuel: 508826.0,
    tfuel: 207680.0,
    spd: 49.1,
    tspd: 46.6,
    vmt_tx100seg: 959469.105,
    trkvmt_tx100seg: 78956.417,
  },
  {
    tx100id: 8000135,
    year: 2021,
    rank: 23,
    trank: 11,
    dly: 1203646.0,
    tdly: 113958.0,
    pti: 1.74,
    tpti: 2.03,
    tci: 1.32,
    ttci: 1.4,
    co2: 5629559.0,
    tco2: 3158125.0,
    cost: 2.8126035e7,
    tcost: 5857450.0,
    fuel: 288888.0,
    tfuel: 147635.0,
    spd: 52.8,
    tspd: 51.3,
    vmt_tx100seg: 1140046.261,
    trkvmt_tx100seg: 85012.169,
  },
  {
    tx100id: 1200168,
    year: 2021,
    rank: 21,
    trank: 12,
    dly: 320872.0,
    tdly: 28700.0,
    pti: 1.69,
    tpti: 1.74,
    tci: 1.38,
    ttci: 1.4,
    co2: 3179688.0,
    tco2: 1500804.0,
    cost: 7620566.0,
    tcost: 1551810.0,
    fuel: 153288.0,
    tfuel: 67606.0,
    spd: 44.4,
    tspd: 43.6,
    vmt_tx100seg: 220046.332,
    trkvmt_tx100seg: 24103.464,
  },
  {
    tx100id: 8000136,
    year: 2021,
    rank: 10,
    trank: 13,
    dly: 2726018.0,
    tdly: 141344.0,
    pti: 1.64,
    tpti: 1.68,
    tci: 1.41,
    ttci: 1.41,
    co2: 1.0516655e7,
    tco2: 4660656.0,
    cost: 6.0110508e7,
    tcost: 7341122.0,
    fuel: 527715.0,
    tfuel: 217222.0,
    spd: 47.6,
    tspd: 46.7,
    vmt_tx100seg: 1591448.364,
    trkvmt_tx100seg: 111036.636,
  },
  {
    tx100id: 3000007,
    year: 2021,
    rank: 31,
    trank: 14,
    dly: 848052.0,
    tdly: 92378.0,
    pti: 1.58,
    tpti: 1.56,
    tci: 1.27,
    ttci: 1.26,
    co2: 3468897.0,
    tco2: 1965117.0,
    cost: 2.0096434e7,
    tcost: 4696900.0,
    fuel: 173380.0,
    tfuel: 96513.0,
    spd: 54.1,
    tspd: 54.3,
    vmt_tx100seg: 687716.164,
    trkvmt_tx100seg: 103001.158,
  },
  {
    tx100id: 8000051,
    year: 2021,
    rank: 13,
    trank: 15,
    dly: 2250662.0,
    tdly: 136338.0,
    pti: 1.81,
    tpti: 1.83,
    tci: 1.46,
    ttci: 1.48,
    co2: 1.5100075e7,
    tco2: 6001161.0,
    cost: 5.0844293e7,
    tcost: 7245693.0,
    fuel: 735800.0,
    tfuel: 271076.0,
    spd: 44.5,
    tspd: 43.6,
    vmt_tx100seg: 1059653.496,
    trkvmt_tx100seg: 84027.857,
  },
  {
    tx100id: 8000134,
    year: 2021,
    rank: 15,
    trank: 16,
    dly: 1666298.0,
    tdly: 105172.0,
    pti: 1.83,
    tpti: 1.81,
    tci: 1.38,
    ttci: 1.39,
    co2: 6635533.0,
    tco2: 3020612.0,
    cost: 3.7309786e7,
    tcost: 5431527.0,
    fuel: 336844.0,
    tfuel: 146437.0,
    spd: 49.3,
    tspd: 48.1,
    vmt_tx100seg: 1180532.155,
    trkvmt_tx100seg: 97587.623,
  },
  {
    tx100id: 8000008,
    year: 2021,
    rank: 24,
    trank: 17,
    dly: 861703.0,
    tdly: 71245.0,
    pti: 2.26,
    tpti: 2.31,
    tci: 1.67,
    ttci: 1.66,
    co2: 4863742.0,
    tco2: 2198166.0,
    cost: 1.9916079e7,
    tcost: 3693144.0,
    fuel: 238668.0,
    tfuel: 102385.0,
    spd: 43.3,
    tspd: 43.4,
    vmt_tx100seg: 331817.163,
    trkvmt_tx100seg: 37605.242,
  },
  {
    tx100id: 8000009,
    year: 2021,
    rank: 34,
    trank: 18,
    dly: 959733.0,
    tdly: 101253.0,
    pti: 1.37,
    tpti: 1.38,
    tci: 1.24,
    ttci: 1.25,
    co2: 5158195.0,
    tco2: 3081582.0,
    cost: 2.2767588e7,
    tcost: 5233680.0,
    fuel: 248426.0,
    tfuel: 141913.0,
    spd: 53.1,
    tspd: 52.5,
    vmt_tx100seg: 829229.264,
    trkvmt_tx100seg: 104021.41,
  },
  {
    tx100id: 8000117,
    year: 2021,
    rank: 12,
    trank: 19,
    dly: 681947.0,
    tdly: 37195.0,
    pti: 1.91,
    tpti: 1.95,
    tci: 1.42,
    ttci: 1.43,
    co2: 4556053.0,
    tco2: 1462039.0,
    cost: 1.5284957e7,
    tcost: 1956612.0,
    fuel: 224052.0,
    tfuel: 66146.0,
    spd: 41.4,
    tspd: 44.2,
    vmt_tx100seg: 355338.65,
    trkvmt_tx100seg: 24668.766,
  },
  {
    tx100id: 1200142,
    year: 2021,
    rank: 11,
    trank: 20,
    dly: 2816260.0,
    tdly: 134497.0,
    pti: 1.55,
    tpti: 1.59,
    tci: 1.31,
    ttci: 1.32,
    co2: 9815133.0,
    tco2: 3797308.0,
    cost: 6.1671974e7,
    tcost: 6921312.0,
    fuel: 504079.0,
    tfuel: 182379.0,
    spd: 50.0,
    tspd: 49.2,
    vmt_tx100seg: 1983720.41,
    trkvmt_tx100seg: 117089.259,
  },
  {
    tx100id: 1200169,
    year: 2021,
    rank: 16,
    trank: 22,
    dly: 1390803.0,
    tdly: 78069.0,
    pti: 1.69,
    tpti: 1.71,
    tci: 1.37,
    ttci: 1.37,
    co2: 7325985.0,
    tco2: 2866884.0,
    cost: 3.1039029e7,
    tcost: 4087658.0,
    fuel: 362584.0,
    tfuel: 131587.0,
    spd: 48.6,
    tspd: 47.4,
    vmt_tx100seg: 1048166.004,
    trkvmt_tx100seg: 75006.308,
  },
  {
    tx100id: 1900035,
    year: 2021,
    rank: 38,
    trank: 23,
    dly: 628919.0,
    tdly: 63092.0,
    pti: 1.59,
    tpti: 1.55,
    tci: 1.25,
    ttci: 1.23,
    co2: 2950458.0,
    tco2: 1512729.0,
    cost: 1.479062e7,
    tcost: 3226963.0,
    fuel: 147380.0,
    tfuel: 73759.0,
    spd: 54.2,
    tspd: 54.3,
    vmt_tx100seg: 774049.202,
    trkvmt_tx100seg: 115316.182,
  },
  {
    tx100id: 2200033,
    year: 2021,
    rank: 139,
    trank: 25,
    dly: 172128.0,
    tdly: 39635.0,
    pti: 1.31,
    tpti: 1.57,
    tci: 1.22,
    ttci: 1.29,
    co2: 3265133.0,
    tco2: 2267860.0,
    cost: 4946088.0,
    tcost: 2169355.0,
    fuel: 152489.0,
    tfuel: 101593.0,
    spd: 31.3,
    tspd: 32.2,
    vmt_tx100seg: 85882.915,
    trkvmt_tx100seg: 17502.787,
  },
  {
    tx100id: 1200188,
    year: 2021,
    rank: 18,
    trank: 27,
    dly: 957357.0,
    tdly: 49903.0,
    pti: 1.45,
    tpti: 1.51,
    tci: 1.23,
    ttci: 1.25,
    co2: 3361254.0,
    tco2: 1007688.0,
    cost: 2.1090223e7,
    tcost: 2535084.0,
    fuel: 176366.0,
    tfuel: 53544.0,
    spd: 53.7,
    tspd: 53.7,
    vmt_tx100seg: 823787.469,
    trkvmt_tx100seg: 51977.945,
  },
  {
    tx100id: 1200143,
    year: 2021,
    rank: 6,
    trank: 28,
    dly: 2813346.0,
    tdly: 88870.0,
    pti: 1.77,
    tpti: 1.86,
    tci: 1.43,
    ttci: 1.45,
    co2: 1.5597258e7,
    tco2: 3454493.0,
    cost: 6.0881111e7,
    tcost: 4656492.0,
    fuel: 778573.0,
    tfuel: 158117.0,
    spd: 45.7,
    tspd: 45.7,
    vmt_tx100seg: 1275394.248,
    trkvmt_tx100seg: 47638.516,
  },
  {
    tx100id: 2400010,
    year: 2021,
    rank: 155,
    trank: 29,
    dly: 250003.0,
    tdly: 54656.0,
    pti: 1.24,
    tpti: 1.09,
    tci: 1.07,
    ttci: 1.06,
    co2: 1696550.0,
    tco2: 1217893.0,
    cost: 6778587.0,
    tcost: 2788309.0,
    fuel: 82294.0,
    tfuel: 57842.0,
    spd: 61.9,
    tspd: 61.8,
    vmt_tx100seg: 368167.818,
    trkvmt_tx100seg: 107918.703,
  },
  {
    tx100id: 8000018,
    year: 2021,
    rank: 40,
    trank: 30,
    dly: 410775.0,
    tdly: 36342.0,
    pti: 1.34,
    tpti: 1.37,
    tci: 1.2,
    ttci: 1.22,
    co2: 889402.0,
    tco2: 695212.0,
    cost: 9435397.0,
    tcost: 1846993.0,
    fuel: 57539.0,
    tfuel: 39930.0,
    spd: 54.0,
    tspd: 52.3,
    vmt_tx100seg: 538318.895,
    trkvmt_tx100seg: 52315.419,
  },
  {
    tx100id: 1200171,
    year: 2021,
    rank: 20,
    trank: 31,
    dly: 337826.0,
    tdly: 18057.0,
    pti: 1.49,
    tpti: 1.5,
    tci: 1.23,
    ttci: 1.22,
    co2: 775905.0,
    tco2: 235250.0,
    cost: 7411525.0,
    tcost: 904756.0,
    fuel: 41171.0,
    tfuel: 13349.0,
    spd: 53.6,
    tspd: 53.6,
    vmt_tx100seg: 434621.57,
    trkvmt_tx100seg: 30797.22,
  },
  {
    tx100id: 8000112,
    year: 2021,
    rank: 7,
    trank: 32,
    dly: 3401267.0,
    tdly: 99184.0,
    pti: 1.99,
    tpti: 2.06,
    tci: 1.5,
    ttci: 1.53,
    co2: 1.0948453e7,
    tco2: 2536975.0,
    cost: 7.2587726e7,
    tcost: 5092356.0,
    fuel: 562910.0,
    tfuel: 127611.0,
    spd: 46.4,
    tspd: 45.6,
    vmt_tx100seg: 1801031.799,
    trkvmt_tx100seg: 64167.12,
  },
  {
    tx100id: 2400012,
    year: 2021,
    rank: 105,
    trank: 33,
    dly: 281049.0,
    tdly: 45999.0,
    pti: 1.55,
    tpti: 1.16,
    tci: 1.09,
    ttci: 1.08,
    co2: 2706376.0,
    tco2: 1655069.0,
    cost: 7263458.0,
    tcost: 2411228.0,
    fuel: 129019.0,
    tfuel: 74738.0,
    spd: 60.8,
    tspd: 60.7,
    vmt_tx100seg: 438643.391,
    trkvmt_tx100seg: 105988.483,
  },
  {
    tx100id: 8000017,
    year: 2021,
    rank: 42,
    trank: 34,
    dly: 1306108.0,
    tdly: 107405.0,
    pti: 1.44,
    tpti: 1.52,
    tci: 1.23,
    ttci: 1.26,
    co2: 4617058.0,
    tco2: 2442166.0,
    cost: 2.9898393e7,
    tcost: 5483538.0,
    fuel: 242045.0,
    tfuel: 126427.0,
    spd: 54.5,
    tspd: 52.9,
    vmt_tx100seg: 1446154.79,
    trkvmt_tx100seg: 133952.43,
  },
  {
    tx100id: 1100022,
    year: 2021,
    rank: 640,
    trank: 35,
    dly: 61051.0,
    tdly: 28834.0,
    pti: 1.23,
    tpti: 1.24,
    tci: 1.11,
    ttci: 1.1,
    co2: 1337684.0,
    tco2: 1089083.0,
    cost: 2192615.0,
    tcost: 1516786.0,
    fuel: 61473.0,
    tfuel: 48777.0,
    spd: 28.6,
    tspd: 29.8,
    vmt_tx100seg: 30633.023,
    trkvmt_tx100seg: 15828.82,
  },
  {
    tx100id: 3000002,
    year: 2021,
    rank: 59,
    trank: 36,
    dly: 381994.0,
    tdly: 39879.0,
    pti: 1.62,
    tpti: 1.64,
    tci: 1.22,
    ttci: 1.22,
    co2: 1771204.0,
    tco2: 957184.0,
    cost: 9025341.0,
    tcost: 2038922.0,
    fuel: 87821.0,
    tfuel: 45891.0,
    spd: 56.4,
    tspd: 56.3,
    vmt_tx100seg: 480104.792,
    trkvmt_tx100seg: 72311.068,
  },
  {
    tx100id: 8000084,
    year: 2021,
    rank: 43,
    trank: 37,
    dly: 879401.0,
    tdly: 68706.0,
    pti: 1.67,
    tpti: 1.7,
    tci: 1.32,
    ttci: 1.32,
    co2: 4344162.0,
    tco2: 1946432.0,
    cost: 2.0153648e7,
    tcost: 3545514.0,
    fuel: 221092.0,
    tfuel: 96006.0,
    spd: 51.4,
    tspd: 50.9,
    vmt_tx100seg: 836263.114,
    trkvmt_tx100seg: 85075.566,
  },
  {
    tx100id: 1900019,
    year: 2021,
    rank: 56,
    trank: 38,
    dly: 401696.0,
    tdly: 39312.0,
    pti: 1.43,
    tpti: 1.42,
    tci: 1.18,
    ttci: 1.18,
    co2: 2627540.0,
    tco2: 888015.0,
    cost: 9504854.0,
    tcost: 2013229.0,
    fuel: 136786.0,
    tfuel: 47838.0,
    spd: 52.2,
    tspd: 53.2,
    vmt_tx100seg: 643320.049,
    trkvmt_tx100seg: 72825.35,
  },
  {
    tx100id: 1200186,
    year: 2021,
    rank: 30,
    trank: 39,
    dly: 996451.0,
    tdly: 54348.0,
    pti: 1.56,
    tpti: 1.58,
    tci: 1.32,
    ttci: 1.32,
    co2: 5142731.0,
    tco2: 1675123.0,
    cost: 2.2179073e7,
    tcost: 2810958.0,
    fuel: 257192.0,
    tfuel: 78703.0,
    spd: 51.1,
    tspd: 51.4,
    vmt_tx100seg: 862707.312,
    trkvmt_tx100seg: 62119.836,
  },
  {
    tx100id: 8000040,
    year: 2021,
    rank: 91,
    trank: 40,
    dly: 667798.0,
    tdly: 87267.0,
    pti: 1.34,
    tpti: 1.32,
    tci: 1.13,
    ttci: 1.12,
    co2: 1173047.0,
    tco2: 717775.0,
    cost: 1.6089997e7,
    tcost: 4330752.0,
    fuel: 70482.0,
    tfuel: 46128.0,
    spd: 58.3,
    tspd: 58.2,
    vmt_tx100seg: 1376473.289,
    trkvmt_tx100seg: 256737.159,
  },
  {
    tx100id: 8000155,
    year: 2021,
    rank: 4,
    trank: 41,
    dly: 908486.0,
    tdly: 13005.0,
    pti: 2.27,
    tpti: 2.45,
    tci: 1.69,
    ttci: 1.76,
    co2: 5240971.0,
    tco2: 527891.0,
    cost: 1.9237368e7,
    tcost: 682427.0,
    fuel: 264776.0,
    tfuel: 24244.0,
    spd: 33.8,
    tspd: 33.6,
    vmt_tx100seg: 243954.252,
    trkvmt_tx100seg: 4152.45,
  },
  {
    tx100id: 1200170,
    year: 2021,
    rank: 29,
    trank: 42,
    dly: 774338.0,
    tdly: 39185.0,
    pti: 1.33,
    tpti: 1.35,
    tci: 1.18,
    ttci: 1.19,
    co2: 1011729.0,
    tco2: 507861.0,
    cost: 1.6856026e7,
    tcost: 1966072.0,
    fuel: 61222.0,
    tfuel: 31019.0,
    spd: 55.2,
    tspd: 54.1,
    vmt_tx100seg: 1203128.454,
    trkvmt_tx100seg: 71716.866,
  },
  {
    tx100id: 1900036,
    year: 2021,
    rank: 61,
    trank: 43,
    dly: 326828.0,
    tdly: 30108.0,
    pti: 1.5,
    tpti: 1.57,
    tci: 1.21,
    ttci: 1.21,
    co2: 1720716.0,
    tco2: 822880.0,
    cost: 7628220.0,
    tcost: 1549164.0,
    fuel: 84555.0,
    tfuel: 38600.0,
    spd: 57.9,
    tspd: 57.7,
    vmt_tx100seg: 508343.946,
    trkvmt_tx100seg: 64998.646,
  },
  {
    tx100id: 3000012,
    year: 2021,
    rank: 36,
    trank: 44,
    dly: 559837.0,
    tdly: 32708.0,
    pti: 1.51,
    tpti: 1.7,
    tci: 1.3,
    ttci: 1.37,
    co2: 5124891.0,
    tco2: 1352977.0,
    cost: 1.2747425e7,
    tcost: 1720513.0,
    fuel: 254505.0,
    tfuel: 61958.0,
    spd: 43.9,
    tspd: 42.2,
    vmt_tx100seg: 192570.343,
    trkvmt_tx100seg: 11448.53,
  },
  {
    tx100id: 1200167,
    year: 2021,
    rank: 62,
    trank: 46,
    dly: 421152.0,
    tdly: 39588.0,
    pti: 1.31,
    tpti: 1.33,
    tci: 1.16,
    ttci: 1.18,
    co2: 2340008.0,
    tco2: 1240280.0,
    cost: 9870294.0,
    tcost: 2052089.0,
    fuel: 117489.0,
    tfuel: 57951.0,
    spd: 54.3,
    tspd: 53.4,
    vmt_tx100seg: 691092.063,
    trkvmt_tx100seg: 75916.867,
  },
  {
    tx100id: 1400055,
    year: 2021,
    rank: 907,
    trank: 47,
    dly: 40875.0,
    tdly: 22119.0,
    pti: 1.14,
    tpti: 1.16,
    tci: 1.09,
    ttci: 1.09,
    co2: 1207164.0,
    tco2: 1122173.0,
    cost: 1581041.0,
    tcost: 1193678.0,
    fuel: 54815.0,
    tfuel: 50399.0,
    spd: 41.3,
    tspd: 40.6,
    vmt_tx100seg: 46889.959,
    trkvmt_tx100seg: 34536.937,
  },
  {
    tx100id: 1200141,
    year: 2021,
    rank: 25,
    trank: 48,
    dly: 587285.0,
    tdly: 24617.0,
    pti: 1.67,
    tpti: 1.71,
    tci: 1.28,
    ttci: 1.3,
    co2: 3021751.0,
    tco2: 982452.0,
    cost: 1.2865325e7,
    tcost: 1292717.0,
    fuel: 154454.0,
    tfuel: 45384.0,
    spd: 50.2,
    tspd: 48.4,
    vmt_tx100seg: 547818.899,
    trkvmt_tx100seg: 25264.833,
  },
  {
    tx100id: 1200182,
    year: 2021,
    rank: 45,
    trank: 49,
    dly: 927697.0,
    tdly: 64094.0,
    pti: 1.36,
    tpti: 1.39,
    tci: 1.22,
    ttci: 1.23,
    co2: 4437139.0,
    tco2: 2095249.0,
    cost: 2.0998319e7,
    tcost: 3327045.0,
    fuel: 220436.0,
    tfuel: 97313.0,
    spd: 52.8,
    tspd: 51.8,
    vmt_tx100seg: 1104653.005,
    trkvmt_tx100seg: 94838.202,
  },
  {
    tx100id: 1200144,
    year: 2021,
    rank: 27,
    trank: 50,
    dly: 1415968.0,
    tdly: 61868.0,
    pti: 1.38,
    tpti: 1.44,
    tci: 1.24,
    ttci: 1.25,
    co2: 5482085.0,
    tco2: 1666849.0,
    cost: 3.0889306e7,
    tcost: 3173569.0,
    fuel: 278616.0,
    tfuel: 81769.0,
    spd: 54.6,
    tspd: 54.2,
    vmt_tx100seg: 1256132.1,
    trkvmt_tx100seg: 67147.334,
  },
  {
    tx100id: 8000021,
    year: 2021,
    rank: 65,
    trank: 51,
    dly: 382090.0,
    tdly: 33444.0,
    pti: 1.33,
    tpti: 1.36,
    tci: 1.15,
    ttci: 1.16,
    co2: 3235427.0,
    tco2: 1531089.0,
    cost: 8991674.0,
    tcost: 1780346.0,
    fuel: 156867.0,
    tfuel: 69479.0,
    spd: 53.6,
    tspd: 52.9,
    vmt_tx100seg: 690353.924,
    trkvmt_tx100seg: 65356.096,
  },
  {
    tx100id: 8000198,
    year: 2021,
    rank: 44,
    trank: 54,
    dly: 865308.0,
    tdly: 51458.0,
    pti: 1.37,
    tpti: 1.59,
    tci: 1.25,
    ttci: 1.34,
    co2: 8343671.0,
    tco2: 2538410.0,
    cost: 1.9781037e7,
    tcost: 2758312.0,
    fuel: 411419.0,
    tfuel: 115147.0,
    spd: 35.7,
    tspd: 36.9,
    vmt_tx100seg: 293956.019,
    trkvmt_tx100seg: 17296.516,
  },
  {
    tx100id: 8000060,
    year: 2021,
    rank: 90,
    trank: 55,
    dly: 487477.0,
    tdly: 49687.0,
    pti: 1.3,
    tpti: 1.32,
    tci: 1.16,
    ttci: 1.18,
    co2: 2342106.0,
    tco2: 1472228.0,
    cost: 1.1488199e7,
    tcost: 2563959.0,
    fuel: 114515.0,
    tfuel: 68657.0,
    spd: 57.4,
    tspd: 56.0,
    vmt_tx100seg: 760793.182,
    trkvmt_tx100seg: 87630.047,
  },
  {
    tx100id: 1900034,
    year: 2021,
    rank: 136,
    trank: 57,
    dly: 222882.0,
    tdly: 28318.0,
    pti: 1.45,
    tpti: 1.31,
    tci: 1.15,
    ttci: 1.14,
    co2: 644629.0,
    tco2: 322377.0,
    cost: 5374644.0,
    tcost: 1415973.0,
    fuel: 36181.0,
    tfuel: 19443.0,
    spd: 57.7,
    tspd: 57.8,
    vmt_tx100seg: 445887.438,
    trkvmt_tx100seg: 81244.412,
  },
  {
    tx100id: 8000077,
    year: 2021,
    rank: 32,
    trank: 61,
    dly: 399889.0,
    tdly: 17351.0,
    pti: 1.72,
    tpti: 1.71,
    tci: 1.36,
    ttci: 1.33,
    co2: 1565758.0,
    tco2: 633510.0,
    cost: 8727977.0,
    tcost: 908300.0,
    fuel: 80502.0,
    tfuel: 29833.0,
    spd: 49.3,
    tspd: 48.4,
    vmt_tx100seg: 356919.296,
    trkvmt_tx100seg: 21593.128,
  },
  {
    tx100id: 1200147,
    year: 2021,
    rank: 96,
    trank: 62,
    dly: 644524.0,
    tdly: 63880.0,
    pti: 1.22,
    tpti: 1.35,
    tci: 1.11,
    ttci: 1.16,
    co2: 505119.0,
    tco2: 747969.0,
    cost: 1.4915886e7,
    tcost: 3197563.0,
    fuel: 50990.0,
    tfuel: 47702.0,
    spd: 59.4,
    tspd: 57.7,
    vmt_tx100seg: 1741626.527,
    trkvmt_tx100seg: 127750.159,
  },
  {
    tx100id: 2400013,
    year: 2021,
    rank: 239,
    trank: 63,
    dly: 85314.0,
    tdly: 13861.0,
    pti: 1.63,
    tpti: 1.08,
    tci: 1.11,
    ttci: 1.09,
    co2: 663137.0,
    tco2: 373453.0,
    cost: 2184542.0,
    tcost: 713003.0,
    fuel: 31790.0,
    tfuel: 16996.0,
    spd: 62.0,
    tspd: 62.3,
    vmt_tx100seg: 174101.567,
    trkvmt_tx100seg: 45033.947,
  },
  {
    tx100id: 1900012,
    year: 2021,
    rank: 35,
    trank: 64,
    dly: 1154793.0,
    tdly: 54086.0,
    pti: 1.68,
    tpti: 1.73,
    tci: 1.34,
    ttci: 1.34,
    co2: 3996071.0,
    tco2: 1405636.0,
    cost: 2.5261636e7,
    tcost: 2773978.0,
    fuel: 210000.0,
    tfuel: 70297.0,
    spd: 53.3,
    tspd: 52.5,
    vmt_tx100seg: 837186.348,
    trkvmt_tx100seg: 44897.915,
  },
  {
    tx100id: 8000011,
    year: 2021,
    rank: 76,
    trank: 66,
    dly: 396370.0,
    tdly: 32332.0,
    pti: 1.36,
    tpti: 1.39,
    tci: 1.18,
    ttci: 1.17,
    co2: 938577.0,
    tco2: 493277.0,
    cost: 9020563.0,
    tcost: 1628683.0,
    fuel: 52209.0,
    tfuel: 28193.0,
    spd: 56.9,
    tspd: 56.5,
    vmt_tx100seg: 760704.017,
    trkvmt_tx100seg: 81926.629,
  },
  {
    tx100id: 1200172,
    year: 2021,
    rank: 53,
    trank: 69,
    dly: 893570.0,
    tdly: 57200.0,
    pti: 1.45,
    tpti: 1.47,
    tci: 1.18,
    ttci: 1.18,
    co2: 2410401.0,
    tco2: 915680.0,
    cost: 1.9908181e7,
    tcost: 2879106.0,
    fuel: 125470.0,
    tfuel: 47669.0,
    spd: 57.1,
    tspd: 56.1,
    vmt_tx100seg: 1667092.936,
    trkvmt_tx100seg: 139134.726,
  },
  {
    tx100id: 8000149,
    year: 2021,
    rank: 141,
    trank: 70,
    dly: 568474.0,
    tdly: 64218.0,
    pti: 1.42,
    tpti: 1.46,
    tci: 1.23,
    ttci: 1.26,
    co2: 5534994.0,
    tco2: 2989709.0,
    cost: 1.3868387e7,
    tcost: 3430650.0,
    fuel: 265089.0,
    tfuel: 134896.0,
    spd: 56.2,
    tspd: 55.1,
    vmt_tx100seg: 356192.98,
    trkvmt_tx100seg: 46276.088,
  },
  {
    tx100id: 8000048,
    year: 2021,
    rank: 93,
    trank: 71,
    dly: 363170.0,
    tdly: 31822.0,
    pti: 1.37,
    tpti: 1.39,
    tci: 1.19,
    ttci: 1.2,
    co2: 1429342.0,
    tco2: 667653.0,
    cost: 8387440.0,
    tcost: 1621296.0,
    fuel: 76697.0,
    tfuel: 36284.0,
    spd: 55.2,
    tspd: 54.7,
    vmt_tx100seg: 584845.34,
    trkvmt_tx100seg: 59823.052,
  },
  {
    tx100id: 3000006,
    year: 2021,
    rank: 87,
    trank: 72,
    dly: 303872.0,
    tdly: 25218.0,
    pti: 1.51,
    tpti: 1.5,
    tci: 1.2,
    ttci: 1.17,
    co2: 1102829.0,
    tco2: 467030.0,
    cost: 6962822.0,
    tcost: 1275752.0,
    fuel: 55908.0,
    tfuel: 23410.0,
    spd: 56.3,
    tspd: 57.4,
    vmt_tx100seg: 516480.663,
    trkvmt_tx100seg: 72588.291,
  },
  {
    tx100id: 8000019,
    year: 2021,
    rank: 104,
    trank: 73,
    dly: 243042.0,
    tdly: 22324.0,
    pti: 1.35,
    tpti: 1.35,
    tci: 1.18,
    ttci: 1.19,
    co2: 795445.0,
    tco2: 439081.0,
    cost: 5630193.0,
    tcost: 1135043.0,
    fuel: 45582.0,
    tfuel: 24475.0,
    spd: 54.5,
    tspd: 54.0,
    vmt_tx100seg: 420118.793,
    trkvmt_tx100seg: 47625.229,
  },
  {
    tx100id: 1400800,
    year: 2021,
    rank: 478,
    trank: 75,
    dly: 37102.0,
    tdly: 8589.0,
    pti: 1.26,
    tpti: 1.3,
    tci: 1.16,
    ttci: 1.16,
    co2: 548518.0,
    tco2: 301545.0,
    cost: 1048500.0,
    tcost: 447547.0,
    fuel: 26294.0,
    tfuel: 13690.0,
    spd: 22.7,
    tspd: 22.9,
    vmt_tx100seg: 13691.877,
    trkvmt_tx100seg: 3785.294,
  },
  {
    tx100id: 8000052,
    year: 2021,
    rank: 101,
    trank: 76,
    dly: 437127.0,
    tdly: 38828.0,
    pti: 1.37,
    tpti: 1.38,
    tci: 1.16,
    ttci: 1.16,
    co2: 1233317.0,
    tco2: 694998.0,
    cost: 1.0056069e7,
    tcost: 1963516.0,
    fuel: 63910.0,
    tfuel: 35193.0,
    spd: 56.3,
    tspd: 55.5,
    vmt_tx100seg: 726433.448,
    trkvmt_tx100seg: 86026.79,
  },
  {
    tx100id: 1200185,
    year: 2021,
    rank: 46,
    trank: 78,
    dly: 898108.0,
    tdly: 47513.0,
    pti: 1.39,
    tpti: 1.42,
    tci: 1.19,
    ttci: 1.2,
    co2: 4068782.0,
    tco2: 1335813.0,
    cost: 1.9898492e7,
    tcost: 2443703.0,
    fuel: 210608.0,
    tfuel: 65094.0,
    spd: 54.5,
    tspd: 54.5,
    vmt_tx100seg: 1167219.881,
    trkvmt_tx100seg: 69559.944,
  },
  {
    tx100id: 3000005,
    year: 2021,
    rank: 121,
    trank: 79,
    dly: 398009.0,
    tdly: 37451.0,
    pti: 1.34,
    tpti: 1.34,
    tci: 1.14,
    ttci: 1.13,
    co2: 1034054.0,
    tco2: 382448.0,
    cost: 9206856.0,
    tcost: 1865233.0,
    fuel: 56917.0,
    tfuel: 23173.0,
    spd: 57.3,
    tspd: 58.1,
    vmt_tx100seg: 890477.655,
    trkvmt_tx100seg: 119045.226,
  },
  {
    tx100id: 1900038,
    year: 2021,
    rank: 267,
    trank: 81,
    dly: 216518.0,
    tdly: 32230.0,
    pti: 1.47,
    tpti: 1.48,
    tci: 1.19,
    ttci: 1.19,
    co2: 1274489.0,
    tco2: 789995.0,
    cost: 5420162.0,
    tcost: 1652215.0,
    fuel: 64222.0,
    tfuel: 39413.0,
    spd: 55.1,
    tspd: 55.1,
    vmt_tx100seg: 342033.902,
    trkvmt_tx100seg: 70613.35,
  },
  {
    tx100id: 8000039,
    year: 2021,
    rank: 67,
    trank: 85,
    dly: 935793.0,
    tdly: 61655.0,
    pti: 1.44,
    tpti: 1.36,
    tci: 1.16,
    ttci: 1.15,
    co2: 2488522.0,
    tco2: 1005207.0,
    cost: 2.0892277e7,
    tcost: 3105553.0,
    fuel: 127353.0,
    tfuel: 51567.0,
    spd: 58.1,
    tspd: 58.0,
    vmt_tx100seg: 1717893.578,
    trkvmt_tx100seg: 170356.469,
  },
  {
    tx100id: 8000113,
    year: 2021,
    rank: 26,
    trank: 89,
    dly: 1113693.0,
    tdly: 34154.0,
    pti: 1.52,
    tpti: 1.59,
    tci: 1.26,
    ttci: 1.3,
    co2: 2317331.0,
    tco2: 612955.0,
    cost: 2.3688866e7,
    tcost: 1730390.0,
    fuel: 124475.0,
    tfuel: 34632.0,
    spd: 53.2,
    tspd: 51.5,
    vmt_tx100seg: 1286425.079,
    trkvmt_tx100seg: 43435.462,
  },
  {
    tx100id: 1900032,
    year: 2021,
    rank: 113,
    trank: 99,
    dly: 113600.0,
    tdly: 8867.0,
    pti: 1.41,
    tpti: 1.39,
    tci: 1.17,
    ttci: 1.17,
    co2: 662922.0,
    tco2: 269714.0,
    cost: 2614167.0,
    tcost: 458612.0,
    fuel: 34043.0,
    tfuel: 13017.0,
    spd: 53.9,
    tspd: 53.6,
    vmt_tx100seg: 226468.501,
    trkvmt_tx100seg: 18570.174,
  },
  {
    tx100id: 8000050,
    year: 2021,
    rank: 134,
    trank: 100,
    dly: 363131.0,
    tdly: 31528.0,
    pti: 1.35,
    tpti: 1.37,
    tci: 1.13,
    ttci: 1.14,
    co2: 1907606.0,
    tco2: 825145.0,
    cost: 8425427.0,
    tcost: 1621079.0,
    fuel: 97226.0,
    tfuel: 40685.0,
    spd: 59.7,
    tspd: 58.2,
    vmt_tx100seg: 729397.499,
    trkvmt_tx100seg: 78078.683,
  },
  {
    tx100id: 1200187,
    year: 2021,
    rank: 79,
    trank: 101,
    dly: 491401.0,
    tdly: 30037.0,
    pti: 1.36,
    tpti: 1.38,
    tci: 1.18,
    ttci: 1.18,
    co2: 2289362.0,
    tco2: 948312.0,
    cost: 1.10147e7,
    tcost: 1556739.0,
    fuel: 119772.0,
    tfuel: 45511.0,
    spd: 52.7,
    tspd: 52.7,
    vmt_tx100seg: 744913.092,
    trkvmt_tx100seg: 58850.72,
  },
  {
    tx100id: 8000012,
    year: 2021,
    rank: 165,
    trank: 102,
    dly: 404395.0,
    tdly: 38295.0,
    pti: 1.37,
    tpti: 1.39,
    tci: 1.14,
    ttci: 1.15,
    co2: 1114135.0,
    tco2: 505318.0,
    cost: 9366656.0,
    tcost: 1918090.0,
    fuel: 59730.0,
    tfuel: 28350.0,
    spd: 58.6,
    tspd: 58.1,
    vmt_tx100seg: 956495.769,
    trkvmt_tx100seg: 115482.535,
  },
  {
    tx100id: 2400030,
    year: 2021,
    rank: 516,
    trank: 106,
    dly: 125487.0,
    tdly: 24043.0,
    pti: 1.52,
    tpti: 1.46,
    tci: 1.19,
    ttci: 1.24,
    co2: 1921243.0,
    tco2: 1270467.0,
    cost: 3413430.0,
    tcost: 1299226.0,
    fuel: 90674.0,
    tfuel: 57462.0,
    spd: 45.2,
    tspd: 46.6,
    vmt_tx100seg: 92660.613,
    trkvmt_tx100seg: 16105.894,
  },
  {
    tx100id: 1200156,
    year: 2021,
    rank: 54,
    trank: 108,
    dly: 860741.0,
    tdly: 41307.0,
    pti: 1.59,
    tpti: 1.6,
    tci: 1.26,
    ttci: 1.25,
    co2: 2651768.0,
    tco2: 1030247.0,
    cost: 1.8819158e7,
    tcost: 2114299.0,
    fuel: 139511.0,
    tfuel: 53239.0,
    spd: 53.6,
    tspd: 53.9,
    vmt_tx100seg: 1060636.964,
    trkvmt_tx100seg: 68243.042,
  },
  {
    tx100id: 1200200,
    year: 2021,
    rank: 355,
    trank: 109,
    dly: 223535.0,
    tdly: 32944.0,
    pti: 1.19,
    tpti: 1.19,
    tci: 1.09,
    ttci: 1.09,
    co2: 894252.0,
    tco2: 503143.0,
    cost: 5551166.0,
    tcost: 1662319.0,
    fuel: 51133.0,
    tfuel: 29363.0,
    spd: 57.2,
    tspd: 57.2,
    vmt_tx100seg: 624056.306,
    trkvmt_tx100seg: 109125.534,
  },
  {
    tx100id: 8000038,
    year: 2021,
    rank: 117,
    trank: 110,
    dly: 564686.0,
    tdly: 42961.0,
    pti: 1.26,
    tpti: 1.27,
    tci: 1.1,
    ttci: 1.11,
    co2: 986343.0,
    tco2: 376536.0,
    cost: 1.2721481e7,
    tcost: 2134378.0,
    fuel: 55111.0,
    tfuel: 23938.0,
    spd: 60.3,
    tspd: 59.5,
    vmt_tx100seg: 1398205.417,
    trkvmt_tx100seg: 132780.774,
  },
  {
    tx100id: 1200112,
    year: 2021,
    rank: 107,
    trank: 113,
    dly: 463345.0,
    tdly: 33185.0,
    pti: 1.28,
    tpti: 1.31,
    tci: 1.12,
    ttci: 1.13,
    co2: 724316.0,
    tco2: 453895.0,
    cost: 1.0392654e7,
    tcost: 1672004.0,
    fuel: 51222.0,
    tfuel: 29605.0,
    spd: 58.7,
    tspd: 56.5,
    vmt_tx100seg: 1188042.341,
    trkvmt_tx100seg: 87477.422,
  },
  {
    tx100id: 1200148,
    year: 2021,
    rank: 97,
    trank: 118,
    dly: 462473.0,
    tdly: 30908.0,
    pti: 1.28,
    tpti: 1.32,
    tci: 1.12,
    ttci: 1.13,
    co2: 619672.0,
    tco2: 261885.0,
    cost: 1.0282303e7,
    tcost: 1538852.0,
    fuel: 38236.0,
    tfuel: 19317.0,
    spd: 59.1,
    tspd: 58.2,
    vmt_tx100seg: 1318481.95,
    trkvmt_tx100seg: 100028.74,
  },
  {
    tx100id: 8000133,
    year: 2021,
    rank: 166,
    trank: 121,
    dly: 344754.0,
    tdly: 29818.0,
    pti: 1.29,
    tpti: 1.27,
    tci: 1.1,
    ttci: 1.11,
    co2: 248272.0,
    tco2: 113684.0,
    cost: 7838214.0,
    tcost: 1471625.0,
    fuel: 19763.0,
    tfuel: 12772.0,
    spd: 59.8,
    tspd: 59.3,
    vmt_tx100seg: 1000122.481,
    trkvmt_tx100seg: 112382.904,
  },
  {
    tx100id: 8000081,
    year: 2021,
    rank: 50,
    trank: 122,
    dly: 455265.0,
    tdly: 19784.0,
    pti: 1.55,
    tpti: 1.6,
    tci: 1.25,
    ttci: 1.25,
    co2: 1577984.0,
    tco2: 517797.0,
    cost: 9917018.0,
    tcost: 1017096.0,
    fuel: 83430.0,
    tfuel: 26715.0,
    spd: 54.3,
    tspd: 53.4,
    vmt_tx100seg: 541254.696,
    trkvmt_tx100seg: 28535.397,
  },
  {
    tx100id: 8000387,
    year: 2021,
    rank: 537,
    trank: 129,
    dly: 95358.0,
    tdly: 16784.0,
    pti: 1.13,
    tpti: 1.2,
    tci: 1.09,
    ttci: 1.1,
    co2: 1556653.0,
    tco2: 935609.0,
    cost: 2564285.0,
    tcost: 914466.0,
    fuel: 73760.0,
    tfuel: 42065.0,
    spd: 31.9,
    tspd: 32.0,
    vmt_tx100seg: 90096.82,
    trkvmt_tx100seg: 15921.824,
  },
  {
    tx100id: 8000053,
    year: 2021,
    rank: 311,
    trank: 135,
    dly: 296819.0,
    tdly: 35430.0,
    pti: 1.34,
    tpti: 1.31,
    tci: 1.13,
    ttci: 1.12,
    co2: 515086.0,
    tco2: 265094.0,
    cost: 7051031.0,
    tcost: 1751875.0,
    fuel: 28741.0,
    tfuel: 15599.0,
    spd: 58.7,
    tspd: 58.7,
    vmt_tx100seg: 755469.234,
    trkvmt_tx100seg: 137113.594,
  },
  {
    tx100id: 8000062,
    year: 2021,
    rank: 92,
    trank: 136,
    dly: 327417.0,
    tdly: 19069.0,
    pti: 1.36,
    tpti: 1.4,
    tci: 1.19,
    ttci: 1.2,
    co2: 1589912.0,
    tco2: 609100.0,
    cost: 7315875.0,
    tcost: 987087.0,
    fuel: 82347.0,
    tfuel: 28971.0,
    spd: 54.3,
    tspd: 54.3,
    vmt_tx100seg: 451421.535,
    trkvmt_tx100seg: 29478.54,
  },
  {
    tx100id: 8000131,
    year: 2021,
    rank: 174,
    trank: 137,
    dly: 235380.0,
    tdly: 19660.0,
    pti: 1.7,
    tpti: 1.7,
    tci: 1.26,
    ttci: 1.24,
    co2: 1032392.0,
    tco2: 415659.0,
    cost: 5416591.0,
    tcost: 1000885.0,
    fuel: 52348.0,
    tfuel: 20819.0,
    spd: 56.3,
    tspd: 56.5,
    vmt_tx100seg: 305919.936,
    trkvmt_tx100seg: 36329.776,
  },
  {
    tx100id: 1200159,
    year: 2021,
    rank: 193,
    trank: 144,
    dly: 196301.0,
    tdly: 17032.0,
    pti: 1.39,
    tpti: 1.41,
    tci: 1.19,
    ttci: 1.2,
    co2: 700815.0,
    tco2: 427054.0,
    cost: 4520328.0,
    tcost: 872589.0,
    fuel: 37309.0,
    tfuel: 21749.0,
    spd: 56.5,
    tspd: 55.1,
    vmt_tx100seg: 335824.725,
    trkvmt_tx100seg: 34896.105,
  },
  {
    tx100id: 8000196,
    year: 2021,
    rank: 291,
    trank: 146,
    dly: 84972.0,
    tdly: 9310.0,
    pti: 1.21,
    tpti: 1.43,
    tci: 1.14,
    ttci: 1.23,
    co2: 920529.0,
    tco2: 505077.0,
    cost: 2074482.0,
    tcost: 504911.0,
    fuel: 43999.0,
    tfuel: 22797.0,
    spd: 39.4,
    tspd: 41.4,
    vmt_tx100seg: 69356.041,
    trkvmt_tx100seg: 5785.559,
  },
  {
    tx100id: 8000101,
    year: 2021,
    rank: 246,
    trank: 152,
    dly: 250593.0,
    tdly: 23693.0,
    pti: 1.26,
    tpti: 1.26,
    tci: 1.13,
    ttci: 1.13,
    co2: 2078434.0,
    tco2: 1145873.0,
    cost: 5943979.0,
    tcost: 1269836.0,
    fuel: 99349.0,
    tfuel: 51749.0,
    spd: 50.6,
    tspd: 50.2,
    vmt_tx100seg: 304389.53,
    trkvmt_tx100seg: 35990.443,
  },
  {
    tx100id: 8000130,
    year: 2021,
    rank: 303,
    trank: 172,
    dly: 252588.0,
    tdly: 25012.0,
    pti: 1.29,
    tpti: 1.31,
    tci: 1.13,
    ttci: 1.14,
    co2: 598784.0,
    tco2: 304695.0,
    cost: 5876984.0,
    tcost: 1254070.0,
    fuel: 35036.0,
    tfuel: 19091.0,
    spd: 57.3,
    tspd: 56.3,
    vmt_tx100seg: 484763.717,
    trkvmt_tx100seg: 53438.897,
  },
  {
    tx100id: 1000013,
    year: 2021,
    rank: 258,
    trank: 192,
    dly: 163573.0,
    tdly: 13487.0,
    pti: 1.16,
    tpti: 1.16,
    tci: 1.07,
    ttci: 1.06,
    co2: 1014180.0,
    tco2: 419532.0,
    cost: 3790526.0,
    tcost: 698441.0,
    fuel: 51192.0,
    tfuel: 19561.0,
    spd: 56.5,
    tspd: 56.5,
    vmt_tx100seg: 603925.145,
    trkvmt_tx100seg: 61509.305,
  },
  {
    tx100id: 1200094,
    year: 2021,
    rank: 830,
    trank: 207,
    dly: 133004.0,
    tdly: 24793.0,
    pti: 1.11,
    tpti: 1.11,
    tci: 1.04,
    ttci: 1.04,
    co2: 209646.0,
    tco2: 143779.0,
    cost: 3418597.0,
    tcost: 1226957.0,
    fuel: 16113.0,
    tfuel: 11709.0,
    spd: 60.4,
    tspd: 59.8,
    vmt_tx100seg: 520588.513,
    trkvmt_tx100seg: 106836.896,
  },
  {
    tx100id: 3000059,
    year: 2021,
    rank: 721,
    trank: 228,
    dly: 251688.0,
    tdly: 37470.0,
    pti: 1.16,
    tpti: 1.1,
    tci: 1.04,
    ttci: 1.04,
    co2: 327694.0,
    tco2: 158987.0,
    cost: 6185706.0,
    tcost: 1847263.0,
    fuel: 23346.0,
    tfuel: 14727.0,
    spd: 63.0,
    tspd: 62.8,
    vmt_tx100seg: 1352760.354,
    trkvmt_tx100seg: 263208.939,
  },
  {
    tx100id: 8000114,
    year: 2021,
    rank: 420,
    trank: 233,
    dly: 330385.0,
    tdly: 32499.0,
    pti: 1.14,
    tpti: 1.2,
    tci: 1.06,
    ttci: 1.11,
    co2: 335591.0,
    tco2: 455453.0,
    cost: 7646103.0,
    tcost: 1632403.0,
    fuel: 28486.0,
    tfuel: 25880.0,
    spd: 62.0,
    tspd: 60.2,
    vmt_tx100seg: 1778185.914,
    trkvmt_tx100seg: 98898.261,
  },
  {
    tx100id: 1100023,
    year: 2021,
    rank: 360,
    trank: 254,
    dly: 164721.0,
    tdly: 13923.0,
    pti: 1.22,
    tpti: 1.25,
    tci: 1.11,
    ttci: 1.12,
    co2: 888001.0,
    tco2: 438514.0,
    cost: 3810367.0,
    tcost: 721222.0,
    fuel: 43641.0,
    tfuel: 20451.0,
    spd: 60.2,
    tspd: 59.8,
    vmt_tx100seg: 399460.428,
    trkvmt_tx100seg: 41030.904,
  },
  {
    tx100id: 1010102,
    year: 2021,
    rank: 195,
    trank: 258,
    dly: 171360.0,
    tdly: 9913.0,
    pti: 1.33,
    tpti: 1.39,
    tci: 1.14,
    ttci: 1.15,
    co2: 891596.0,
    tco2: 317358.0,
    cost: 3831429.0,
    tcost: 514353.0,
    fuel: 44222.0,
    tfuel: 14624.0,
    spd: 57.2,
    tspd: 56.6,
    vmt_tx100seg: 327634.086,
    trkvmt_tx100seg: 19877.298,
  },
  {
    tx100id: 1200165,
    year: 2021,
    rank: 556,
    trank: 271,
    dly: 154454.0,
    tdly: 16797.0,
    pti: 1.12,
    tpti: 1.12,
    tci: 1.07,
    ttci: 1.07,
    co2: 146143.0,
    tco2: 70728.0,
    cost: 3610791.0,
    tcost: 826267.0,
    fuel: 9666.0,
    tfuel: 5776.0,
    spd: 61.3,
    tspd: 61.0,
    vmt_tx100seg: 630068.025,
    trkvmt_tx100seg: 89186.189,
  },
  {
    tx100id: 1900072,
    year: 2021,
    rank: 511,
    trank: 397,
    dly: 86278.0,
    tdly: 6815.0,
    pti: 1.15,
    tpti: 1.35,
    tci: 1.1,
    ttci: 1.17,
    co2: 769267.0,
    tco2: 301817.0,
    cost: 2012948.0,
    tcost: 361277.0,
    fuel: 37595.0,
    tfuel: 13739.0,
    spd: 35.5,
    tspd: 38.1,
    vmt_tx100seg: 106088.706,
    trkvmt_tx100seg: 5519.361,
  },
  {
    tx100id: 7000061,
    year: 2021,
    rank: 701,
    trank: 467,
    dly: 64790.0,
    tdly: 5695.0,
    pti: 1.1,
    tpti: 1.27,
    tci: 1.05,
    ttci: 1.12,
    co2: 714830.0,
    tco2: 214737.0,
    cost: 1541537.0,
    tcost: 297266.0,
    fuel: 35377.0,
    tfuel: 9855.0,
    spd: 30.4,
    tspd: 34.8,
    vmt_tx100seg: 102439.861,
    trkvmt_tx100seg: 4495.897,
  },
  {
    tx100id: 1010103,
    year: 2021,
    rank: 922,
    trank: 702,
    dly: 84074.0,
    tdly: 7190.0,
    pti: 1.09,
    tpti: 1.11,
    tci: 1.05,
    ttci: 1.06,
    co2: 104846.0,
    tco2: 87649.0,
    cost: 1916025.0,
    tcost: 361287.0,
    fuel: 8029.0,
    tfuel: 6082.0,
    spd: 62.3,
    tspd: 61.0,
    vmt_tx100seg: 519077.33,
    trkvmt_tx100seg: 38893.604,
  },
  {
    tx100id: 1900086,
    year: 2021,
    rank: 1479,
    trank: 748,
    dly: 9420.0,
    tdly: 3232.0,
    pti: 1.02,
    tpti: 1.02,
    tci: 1.01,
    ttci: 1.01,
    co2: 0.0,
    tco2: 0.0,
    cost: 282008.0,
    tcost: 157204.0,
    fuel: 284.0,
    tfuel: 284.0,
    spd: 64.6,
    tspd: 64.2,
    vmt_tx100seg: 126128.82,
    trkvmt_tx100seg: 38072.79,
  },
  {
    tx100id: 3000058,
    year: 2021,
    rank: 1312,
    trank: 783,
    dly: 45518.0,
    tdly: 8083.0,
    pti: 1.03,
    tpti: 1.03,
    tci: 1.01,
    ttci: 1.01,
    co2: 0.0,
    tco2: 0.0,
    cost: 1149328.0,
    tcost: 394271.0,
    fuel: 1260.0,
    tfuel: 1260.0,
    spd: 64.4,
    tspd: 64.3,
    vmt_tx100seg: 794235.55,
    trkvmt_tx100seg: 164994.316,
  },
  {
    tx100id: 8000061,
    year: 2021,
    rank: 1430,
    trank: 999,
    dly: 22118.0,
    tdly: 4263.0,
    pti: 1.02,
    tpti: 1.06,
    tci: 1.01,
    ttci: 1.02,
    co2: 85905.0,
    tco2: 30437.0,
    cost: 578502.0,
    tcost: 211875.0,
    fuel: 5638.0,
    tfuel: 2476.0,
    spd: 64.6,
    tspd: 63.7,
    vmt_tx100seg: 484084.659,
    trkvmt_tx100seg: 52766.86,
  },
  {
    tx100id: 3000004,
    year: 2022,
    rank: 3,
    trank: 1,
    dly: 7925544.0,
    tdly: 620945.0,
    pti: 3.01,
    tpti: 3.05,
    tci: 1.96,
    ttci: 1.9,
    co2: 6.085339e7,
    tco2: 2.4452613e7,
    cost: 2.0351755e8,
    tcost: 3.7426709e7,
    fuel: 2960703.0,
    tfuel: 1102197.0,
    spd: 40.7,
    tspd: 41.2,
    vmt_tx100seg: 1379437.8189999999013,
    trkvmt_tx100seg: 149158.348999999987427,
  },
  {
    tx100id: 1200140,
    year: 2022,
    rank: 4,
    trank: 2,
    dly: 2941604.0,
    tdly: 208289.0,
    pti: 2.91,
    tpti: 3.14,
    tci: 2.08,
    ttci: 2.17,
    co2: 2.1496806e7,
    tco2: 8068488.0,
    cost: 7.4648909e7,
    tcost: 1.2523166e7,
    fuel: 1053393.0,
    tfuel: 366213.0,
    spd: 37.8,
    tspd: 37.0,
    vmt_tx100seg: 532859.5709999999963,
    trkvmt_tx100seg: 45376.614999999997963,
  },
  {
    tx100id: 8000010,
    year: 2022,
    rank: 6,
    trank: 3,
    dly: 2746127.0,
    tdly: 228190.0,
    pti: 2.59,
    tpti: 2.68,
    tci: 1.91,
    ttci: 1.89,
    co2: 2.1243172e7,
    tco2: 8865877.0,
    cost: 7.0960548e7,
    tcost: 1.3733145e7,
    fuel: 1033206.0,
    tfuel: 401215.0,
    spd: 37.5,
    tspd: 38.4,
    vmt_tx100seg: 590316.0350000000326,
    trkvmt_tx100seg: 68407.89100000000326,
  },
  {
    tx100id: 1200169,
    year: 2022,
    rank: 13,
    trank: 4,
    dly: 2732110.0,
    tdly: 261605.0,
    pti: 2.17,
    tpti: 2.39,
    tci: 1.57,
    ttci: 1.58,
    co2: 1.8674394e7,
    tco2: 9019034.0,
    cost: 7.1386142e7,
    tcost: 1.5597496e7,
    fuel: 905266.0,
    tfuel: 409563.0,
    spd: 46.5,
    tspd: 46.1,
    vmt_tx100seg: 1251258.3810000000522,
    trkvmt_tx100seg: 148843.330000000016298,
  },
  {
    tx100id: 8000009,
    year: 2022,
    rank: 14,
    trank: 5,
    dly: 3071908.0,
    tdly: 291273.0,
    pti: 1.74,
    tpti: 1.75,
    tci: 1.48,
    ttci: 1.46,
    co2: 2.1123074e7,
    tco2: 9698512.0,
    cost: 8.0171284e7,
    tcost: 1.7306186e7,
    fuel: 1024302.0,
    tfuel: 441149.0,
    spd: 47.4,
    tspd: 48.1,
    vmt_tx100seg: 1026831.4050000000279,
    trkvmt_tx100seg: 133418.706999999994878,
  },
  {
    tx100id: 1200183,
    year: 2022,
    rank: 8,
    trank: 6,
    dly: 3403482.0,
    tdly: 239679.0,
    pti: 2.42,
    tpti: 2.56,
    tci: 1.72,
    ttci: 1.75,
    co2: 2.2597021e7,
    tco2: 9483560.0,
    cost: 8.6003233e7,
    tcost: 1.4441384e7,
    fuel: 1106825.0,
    tfuel: 431584.0,
    spd: 43.6,
    tspd: 41.8,
    vmt_tx100seg: 1102909.4129999999423,
    trkvmt_tx100seg: 94600.732999999992899,
  },
  {
    tx100id: 1200184,
    year: 2022,
    rank: 1,
    trank: 7,
    dly: 4054169.0,
    tdly: 160917.0,
    pti: 2.99,
    tpti: 3.25,
    tci: 2.08,
    ttci: 2.12,
    co2: 2.2671668e7,
    tco2: 6136748.0,
    cost: 9.7766795e7,
    tcost: 9658016.0,
    fuel: 1127020.0,
    tfuel: 279701.0,
    spd: 38.1,
    tspd: 37.3,
    vmt_tx100seg: 729778.6700000000419,
    trkvmt_tx100seg: 35609.777999999998428,
  },
  {
    tx100id: 1200168,
    year: 2022,
    rank: 16,
    trank: 8,
    dly: 699058.0,
    tdly: 66636.0,
    pti: 2.16,
    tpti: 2.2,
    tci: 1.61,
    ttci: 1.61,
    co2: 6844787.0,
    tco2: 3222697.0,
    cost: 1.854868e7,
    tcost: 4099323.0,
    fuel: 330099.0,
    tfuel: 145246.0,
    spd: 40.5,
    tspd: 41.0,
    vmt_tx100seg: 254759.3919999999925,
    trkvmt_tx100seg: 33331.648000000001048,
  },
  {
    tx100id: 8000051,
    year: 2022,
    rank: 11,
    trank: 9,
    dly: 4115017.0,
    tdly: 337386.0,
    pti: 2.34,
    tpti: 2.34,
    tci: 1.8,
    ttci: 1.77,
    co2: 3.2192092e7,
    tco2: 1.4663207e7,
    cost: 1.06223744e8,
    tcost: 2.0520908e7,
    fuel: 1557103.0,
    tfuel: 662285.0,
    spd: 38.7,
    tspd: 39.4,
    vmt_tx100seg: 1023363.515000000014,
    trkvmt_tx100seg: 118192.955000000001746,
  },
  {
    tx100id: 8000117,
    year: 2022,
    rank: 7,
    trank: 10,
    dly: 1757205.0,
    tdly: 99829.0,
    pti: 2.94,
    tpti: 2.89,
    tci: 2.0,
    ttci: 1.9,
    co2: 1.3571116e7,
    tco2: 4019810.0,
    cost: 4.3901923e7,
    tcost: 6025972.0,
    fuel: 669237.0,
    tfuel: 181761.0,
    spd: 37.7,
    tspd: 38.6,
    vmt_tx100seg: 421238.4319999999716,
    trkvmt_tx100seg: 37655.110000000000582,
  },
  {
    tx100id: 1400055,
    year: 2022,
    rank: 197,
    trank: 11,
    dly: 200452.0,
    tdly: 113014.0,
    pti: 1.3,
    tpti: 1.47,
    tci: 1.17,
    ttci: 1.23,
    co2: 3646735.0,
    tco2: 3124480.0,
    cost: 8628294.0,
    tcost: 6626790.0,
    fuel: 167338.0,
    tfuel: 140486.0,
    spd: 39.2,
    tspd: 35.4,
    vmt_tx100seg: 72682.5559999999969,
    trkvmt_tx100seg: 39958.351000000002387,
  },
  {
    tx100id: 1200139,
    year: 2022,
    rank: 5,
    trank: 12,
    dly: 4662649.0,
    tdly: 211332.0,
    pti: 2.6,
    tpti: 2.92,
    tci: 1.85,
    ttci: 1.9,
    co2: 2.6529522e7,
    tco2: 6872784.0,
    cost: 1.13393166e8,
    tcost: 1.2521793e7,
    fuel: 1327429.0,
    tfuel: 312810.0,
    spd: 46.6,
    tspd: 46.4,
    vmt_tx100seg: 1223695.4239999998827,
    trkvmt_tx100seg: 65789.547999999995227,
  },
  {
    tx100id: 3000007,
    year: 2022,
    rank: 19,
    trank: 13,
    dly: 2311695.0,
    tdly: 199087.0,
    pti: 1.85,
    tpti: 1.89,
    tci: 1.46,
    ttci: 1.46,
    co2: 1.170633e7,
    tco2: 5497631.0,
    cost: 5.9079261e7,
    tcost: 1.1682381e7,
    fuel: 572512.0,
    tfuel: 255442.0,
    spd: 49.8,
    tspd: 49.8,
    vmt_tx100seg: 919014.8949999999022,
    trkvmt_tx100seg: 103313.961999999999534,
  },
  {
    tx100id: 8000020,
    year: 2022,
    rank: 12,
    trank: 14,
    dly: 2755741.0,
    tdly: 180125.0,
    pti: 2.04,
    tpti: 2.1,
    tci: 1.51,
    ttci: 1.49,
    co2: 2.0493823e7,
    tco2: 7980523.0,
    cost: 6.9487246e7,
    tcost: 1.0971154e7,
    fuel: 1000369.0,
    tfuel: 361565.0,
    spd: 44.3,
    tspd: 44.1,
    vmt_tx100seg: 1165467.8389999999199,
    trkvmt_tx100seg: 105983.067999999999302,
  },
  {
    tx100id: 8000135,
    year: 2022,
    rank: 18,
    trank: 15,
    dly: 2969650.0,
    tdly: 226077.0,
    pti: 2.4,
    tpti: 2.52,
    tci: 1.69,
    ttci: 1.69,
    co2: 2.0381984e7,
    tco2: 8580384.0,
    cost: 7.5686492e7,
    tcost: 1.357311e7,
    fuel: 995269.0,
    tfuel: 390169.0,
    spd: 43.5,
    tspd: 43.8,
    vmt_tx100seg: 1091699.2069999999367,
    trkvmt_tx100seg: 111456.548999999999069,
  },
  {
    tx100id: 8000008,
    year: 2022,
    rank: 32,
    trank: 16,
    dly: 1308057.0,
    tdly: 145842.0,
    pti: 2.16,
    tpti: 2.21,
    tci: 1.58,
    ttci: 1.55,
    co2: 1.0712119e7,
    tco2: 5640592.0,
    cost: 3.5097778e7,
    tcost: 8777721.0,
    fuel: 515779.0,
    tfuel: 256702.0,
    spd: 43.6,
    tspd: 45.1,
    vmt_tx100seg: 445044.7739999999758,
    trkvmt_tx100seg: 70384.038000000000466,
  },
  {
    tx100id: 1400201,
    year: 2022,
    rank: 40,
    trank: 17,
    dly: 512634.0,
    tdly: 68822.0,
    pti: 2.35,
    tpti: 2.2,
    tci: 1.53,
    ttci: 1.39,
    co2: 5367727.0,
    tco2: 1951002.0,
    cost: 1.4308294e7,
    tcost: 4038747.0,
    fuel: 262944.0,
    tfuel: 88572.0,
    spd: 23.6,
    tspd: 23.8,
    vmt_tx100seg: 75701.0720000000001,
    trkvmt_tx100seg: 12413.092999999998938,
  },
  {
    tx100id: 8000134,
    year: 2022,
    rank: 20,
    trank: 18,
    dly: 2268386.0,
    tdly: 176061.0,
    pti: 2.04,
    tpti: 2.1,
    tci: 1.48,
    ttci: 1.47,
    co2: 1.6093772e7,
    tco2: 6703595.0,
    cost: 5.7996855e7,
    tcost: 1.0575531e7,
    fuel: 783857.0,
    tfuel: 304491.0,
    spd: 44.7,
    tspd: 46.1,
    vmt_tx100seg: 1020027.841999999946,
    trkvmt_tx100seg: 112253.062000000005355,
  },
  {
    tx100id: 3000003,
    year: 2022,
    rank: 26,
    trank: 19,
    dly: 1127144.0,
    tdly: 111337.0,
    pti: 1.99,
    tpti: 2.08,
    tci: 1.49,
    ttci: 1.48,
    co2: 5713747.0,
    tco2: 3023490.0,
    cost: 2.9278744e7,
    tcost: 6532170.0,
    fuel: 279087.0,
    tfuel: 141568.0,
    spd: 49.7,
    tspd: 50.1,
    vmt_tx100seg: 593483.6310000000522,
    trkvmt_tx100seg: 82588.85899999999674,
  },
  {
    tx100id: 1200142,
    year: 2022,
    rank: 15,
    trank: 20,
    dly: 4392894.0,
    tdly: 252444.0,
    pti: 1.67,
    tpti: 1.7,
    tci: 1.39,
    ttci: 1.38,
    co2: 1.7646733e7,
    tco2: 7547590.0,
    cost: 1.07506512e8,
    tcost: 1.4891217e7,
    fuel: 880110.0,
    tfuel: 354115.0,
    spd: 48.2,
    tspd: 48.0,
    vmt_tx100seg: 2176193.4939999999478,
    trkvmt_tx100seg: 165166.157999999995809,
  },
  {
    tx100id: 2400012,
    year: 2022,
    rank: 46,
    trank: 21,
    dly: 822498.0,
    tdly: 116693.0,
    pti: 2.35,
    tpti: 1.72,
    tci: 1.3,
    ttci: 1.24,
    co2: 9078277.0,
    tco2: 5279785.0,
    cost: 2.3222652e7,
    tcost: 7132584.0,
    fuel: 430788.0,
    tfuel: 236871.0,
    spd: 50.9,
    tspd: 52.8,
    vmt_tx100seg: 395274.859999999986,
    trkvmt_tx100seg: 87865.096999999994296,
  },
  {
    tx100id: 1200143,
    year: 2022,
    rank: 10,
    trank: 22,
    dly: 4543358.0,
    tdly: 200392.0,
    pti: 2.02,
    tpti: 2.06,
    tci: 1.59,
    ttci: 1.58,
    co2: 2.6912853e7,
    tco2: 7783189.0,
    cost: 1.10424172e8,
    tcost: 1.2045775e7,
    fuel: 1332624.0,
    tfuel: 355678.0,
    spd: 44.1,
    tspd: 44.2,
    vmt_tx100seg: 1390621.3449999999721,
    trkvmt_tx100seg: 80898.100999999995111,
  },
  {
    tx100id: 8000136,
    year: 2022,
    rank: 17,
    trank: 23,
    dly: 3478870.0,
    tdly: 201095.0,
    pti: 1.72,
    tpti: 1.72,
    tci: 1.44,
    ttci: 1.42,
    co2: 1.3935943e7,
    tco2: 5834978.0,
    cost: 8.5171339e7,
    tcost: 1.1846945e7,
    fuel: 695293.0,
    tfuel: 276897.0,
    spd: 47.1,
    tspd: 47.5,
    vmt_tx100seg: 1661637.4129999999423,
    trkvmt_tx100seg: 134249.986000000004424,
  },
  {
    tx100id: 8000061,
    year: 2022,
    rank: 23,
    trank: 24,
    dly: 2247220.0,
    tdly: 160978.0,
    pti: 1.98,
    tpti: 2.03,
    tci: 1.55,
    ttci: 1.52,
    co2: 1.2821033e7,
    tco2: 4809772.0,
    cost: 5.6595566e7,
    tcost: 9496971.0,
    fuel: 638716.0,
    tfuel: 224482.0,
    spd: 46.9,
    tspd: 47.9,
    vmt_tx100seg: 965222.8460000000196,
    trkvmt_tx100seg: 101123.122000000003027,
  },
  {
    tx100id: 8000112,
    year: 2022,
    rank: 9,
    trank: 25,
    dly: 5773257.0,
    tdly: 219094.0,
    pti: 2.37,
    tpti: 2.38,
    tci: 1.62,
    ttci: 1.56,
    co2: 2.3792514e7,
    tco2: 6178331.0,
    cost: 1.37722067e8,
    tcost: 1.2899351e7,
    fuel: 1205307.0,
    tfuel: 298015.0,
    spd: 46.1,
    tspd: 47.5,
    vmt_tx100seg: 2172505.2469999999739,
    trkvmt_tx100seg: 118504.710000000006403,
  },
  {
    tx100id: 8000040,
    year: 2022,
    rank: 74,
    trank: 26,
    dly: 1334788.0,
    tdly: 214354.0,
    pti: 1.44,
    tpti: 1.46,
    tci: 1.21,
    ttci: 1.21,
    co2: 4088173.0,
    tco2: 2928730.0,
    cost: 3.7055904e7,
    tcost: 1.2221671e7,
    fuel: 223352.0,
    tfuel: 159668.0,
    spd: 55.4,
    tspd: 55.1,
    vmt_tx100seg: 1519887.4869999999646,
    trkvmt_tx100seg: 292774.543000000005122,
  },
  {
    tx100id: 1900035,
    year: 2022,
    rank: 31,
    trank: 27,
    dly: 1245261.0,
    tdly: 102296.0,
    pti: 1.64,
    tpti: 1.66,
    tci: 1.29,
    ttci: 1.27,
    co2: 4797040.0,
    tco2: 2302576.0,
    cost: 3.146795e7,
    tcost: 5950974.0,
    fuel: 242942.0,
    tfuel: 114756.0,
    spd: 52.9,
    tspd: 53.5,
    vmt_tx100seg: 1040243.7179999999935,
    trkvmt_tx100seg: 125144.120999999999185,
  },
  {
    tx100id: 8000060,
    year: 2022,
    rank: 30,
    trank: 28,
    dly: 1814009.0,
    tdly: 144341.0,
    pti: 1.82,
    tpti: 1.78,
    tci: 1.45,
    ttci: 1.42,
    co2: 1.3729636e7,
    tco2: 5207127.0,
    cost: 4.6625859e7,
    tcost: 8630982.0,
    fuel: 671092.0,
    tfuel: 235860.0,
    spd: 53.2,
    tspd: 53.8,
    vmt_tx100seg: 804350.1469999999972,
    trkvmt_tx100seg: 89850.080000000001746,
  },
  {
    tx100id: 1200196,
    year: 2022,
    rank: 29,
    trank: 29,
    dly: 1293273.0,
    tdly: 97059.0,
    pti: 1.66,
    tpti: 1.86,
    tci: 1.33,
    ttci: 1.4,
    co2: 8234547.0,
    tco2: 3103780.0,
    cost: 3.2829222e7,
    tcost: 5745439.0,
    fuel: 406209.0,
    tfuel: 141701.0,
    spd: 54.6,
    tspd: 52.8,
    vmt_tx100seg: 757848.0019999999786,
    trkvmt_tx100seg: 57736.171999999998661,
  },
  {
    tx100id: 3000002,
    year: 2022,
    rank: 44,
    trank: 30,
    dly: 795155.0,
    tdly: 80407.0,
    pti: 1.86,
    tpti: 1.92,
    tci: 1.35,
    ttci: 1.34,
    co2: 4222514.0,
    tco2: 2198415.0,
    cost: 2.0744054e7,
    tcost: 4719512.0,
    fuel: 206372.0,
    tfuel: 102895.0,
    spd: 53.1,
    tspd: 53.2,
    vmt_tx100seg: 555413.8660000000382,
    trkvmt_tx100seg: 81272.01600000000326,
  },
  {
    tx100id: 8000101,
    year: 2022,
    rank: 73,
    trank: 31,
    dly: 885287.0,
    tdly: 120296.0,
    pti: 1.6,
    tpti: 1.7,
    tci: 1.29,
    ttci: 1.3,
    co2: 1.0206718e7,
    tco2: 5479702.0,
    cost: 2.4883842e7,
    tcost: 7353504.0,
    fuel: 488814.0,
    tfuel: 247249.0,
    spd: 46.2,
    tspd: 44.9,
    vmt_tx100seg: 389689.4189999999944,
    trkvmt_tx100seg: 59434.391999999999825,
  },
  {
    tx100id: 1200141,
    year: 2022,
    rank: 22,
    trank: 32,
    dly: 1052507.0,
    tdly: 58942.0,
    pti: 1.86,
    tpti: 1.92,
    tci: 1.39,
    ttci: 1.41,
    co2: 5437306.0,
    tco2: 2392464.0,
    cost: 2.5882931e7,
    tcost: 3558871.0,
    fuel: 269804.0,
    tfuel: 109243.0,
    spd: 48.5,
    tspd: 47.0,
    vmt_tx100seg: 612557.310999999987,
    trkvmt_tx100seg: 41327.283999999999651,
  },
  {
    tx100id: 1900034,
    year: 2022,
    rank: 50,
    trank: 33,
    dly: 723398.0,
    tdly: 75704.0,
    pti: 1.62,
    tpti: 1.55,
    tci: 1.24,
    ttci: 1.21,
    co2: 2733414.0,
    tco2: 1319239.0,
    cost: 1.8813182e7,
    tcost: 4353953.0,
    fuel: 141174.0,
    tfuel: 68776.0,
    spd: 54.0,
    tspd: 54.9,
    vmt_tx100seg: 707180.0039999999572,
    trkvmt_tx100seg: 108358.584000000002561,
  },
  {
    tx100id: 1100022,
    year: 2022,
    rank: 637,
    trank: 34,
    dly: 108244.0,
    tdly: 63542.0,
    pti: 1.3,
    tpti: 1.57,
    tci: 1.23,
    ttci: 1.25,
    co2: 2103126.0,
    tco2: 1788753.0,
    cost: 4762135.0,
    tcost: 3731329.0,
    fuel: 96529.0,
    tfuel: 80198.0,
    spd: 24.7,
    tspd: 23.4,
    vmt_tx100seg: 25859.2900000000009,
    trkvmt_tx100seg: 14118.861000000000786,
  },
  {
    tx100id: 8000017,
    year: 2022,
    rank: 36,
    trank: 35,
    dly: 1732355.0,
    tdly: 133375.0,
    pti: 1.69,
    tpti: 1.68,
    tci: 1.36,
    ttci: 1.33,
    co2: 8069672.0,
    tco2: 4099557.0,
    cost: 4.3667093e7,
    tcost: 7888256.0,
    fuel: 400703.0,
    tfuel: 193363.0,
    spd: 50.2,
    tspd: 50.5,
    vmt_tx100seg: 1087247.4960000000428,
    trkvmt_tx100seg: 116942.841000000000349,
  },
  {
    tx100id: 1200188,
    year: 2022,
    rank: 24,
    trank: 36,
    dly: 2592127.0,
    tdly: 131333.0,
    pti: 1.48,
    tpti: 1.47,
    tci: 1.31,
    ttci: 1.29,
    co2: 1.2984641e7,
    tco2: 2766844.0,
    cost: 6.3230236e7,
    tcost: 7579582.0,
    fuel: 651099.0,
    tfuel: 129308.0,
    spd: 56.0,
    tspd: 56.2,
    vmt_tx100seg: 1458343.7269999999553,
    trkvmt_tx100seg: 109094.475999999995111,
  },
  {
    tx100id: 1200171,
    year: 2022,
    rank: 28,
    trank: 37,
    dly: 907744.0,
    tdly: 54619.0,
    pti: 1.53,
    tpti: 1.53,
    tci: 1.24,
    ttci: 1.23,
    co2: 2107631.0,
    tco2: 797659.0,
    cost: 2.2090406e7,
    tcost: 3126168.0,
    fuel: 112437.0,
    tfuel: 45024.0,
    spd: 53.5,
    tspd: 53.6,
    vmt_tx100seg: 975548.2440000000643,
    trkvmt_tx100seg: 82158.305999999996857,
  },
  {
    tx100id: 1200172,
    year: 2022,
    rank: 85,
    trank: 38,
    dly: 1252055.0,
    tdly: 155477.0,
    pti: 1.44,
    tpti: 1.48,
    tci: 1.18,
    ttci: 1.19,
    co2: 2045184.0,
    tco2: 918794.0,
    cost: 3.3020429e7,
    tcost: 8724901.0,
    fuel: 130107.0,
    tfuel: 71205.0,
    spd: 57.1,
    tspd: 56.3,
    vmt_tx100seg: 2140535.9580000001006,
    trkvmt_tx100seg: 265700.173999999999069,
  },
  {
    tx100id: 1900036,
    year: 2022,
    rank: 43,
    trank: 39,
    dly: 662300.0,
    tdly: 55414.0,
    pti: 2.0,
    tpti: 2.0,
    tci: 1.41,
    ttci: 1.36,
    co2: 3724911.0,
    tco2: 1476827.0,
    cost: 1.6932049e7,
    tcost: 3247619.0,
    fuel: 184168.0,
    tfuel: 69367.0,
    spd: 54.6,
    tspd: 55.8,
    vmt_tx100seg: 485631.1869999999763,
    trkvmt_tx100seg: 61279.525000000001455,
  },
  {
    tx100id: 1200182,
    year: 2022,
    rank: 42,
    trank: 40,
    dly: 1645399.0,
    tdly: 128648.0,
    pti: 1.62,
    tpti: 1.64,
    tci: 1.34,
    ttci: 1.35,
    co2: 1.0244392e7,
    tco2: 4783006.0,
    cost: 4.1893642e7,
    tcost: 7708018.0,
    fuel: 499492.0,
    tfuel: 217732.0,
    spd: 50.6,
    tspd: 50.3,
    vmt_tx100seg: 1207136.4930000000168,
    trkvmt_tx100seg: 121104.097000000008848,
  },
  {
    tx100id: 1200170,
    year: 2022,
    rank: 33,
    trank: 41,
    dly: 1586564.0,
    tdly: 100702.0,
    pti: 1.42,
    tpti: 1.43,
    tci: 1.2,
    ttci: 1.19,
    co2: 2889320.0,
    tco2: 1159641.0,
    cost: 3.8695143e7,
    tcost: 5740117.0,
    fuel: 167100.0,
    tfuel: 75355.0,
    spd: 55.0,
    tspd: 54.8,
    vmt_tx100seg: 1975059.5390000001062,
    trkvmt_tx100seg: 161971.075000000011642,
  },
  {
    tx100id: 8000052,
    year: 2022,
    rank: 63,
    trank: 42,
    dly: 1335924.0,
    tdly: 128309.0,
    pti: 1.72,
    tpti: 1.71,
    tci: 1.3,
    ttci: 1.27,
    co2: 7188360.0,
    tco2: 3412160.0,
    cost: 3.4646353e7,
    tcost: 7517206.0,
    fuel: 352670.0,
    tfuel: 159012.0,
    spd: 52.6,
    tspd: 53.5,
    vmt_tx100seg: 1066518.6990000000224,
    trkvmt_tx100seg: 147305.956000000005588,
  },
  {
    tx100id: 1200167,
    year: 2022,
    rank: 72,
    trank: 43,
    dly: 684351.0,
    tdly: 70802.0,
    pti: 1.59,
    tpti: 1.61,
    tci: 1.23,
    ttci: 1.25,
    co2: 3936205.0,
    tco2: 2105657.0,
    cost: 1.7960869e7,
    tcost: 4183480.0,
    fuel: 196212.0,
    tfuel: 99894.0,
    spd: 54.2,
    tspd: 53.5,
    vmt_tx100seg: 779825.0890000000363,
    trkvmt_tx100seg: 101992.487999999997555,
  },
  {
    tx100id: 1100023,
    year: 2022,
    rank: 157,
    trank: 44,
    dly: 422191.0,
    tdly: 75822.0,
    pti: 1.28,
    tpti: 1.34,
    tci: 1.14,
    ttci: 1.15,
    co2: 4248530.0,
    tco2: 2488511.0,
    cost: 1.2381938e7,
    tcost: 4501275.0,
    fuel: 201675.0,
    tfuel: 111833.0,
    spd: 52.1,
    tspd: 53.4,
    vmt_tx100seg: 441895.5769999999902,
    trkvmt_tx100seg: 78365.884000000005472,
  },
  {
    tx100id: 8000155,
    year: 2022,
    rank: 2,
    trank: 45,
    dly: 1471061.0,
    tdly: 21204.0,
    pti: 3.03,
    tpti: 3.1,
    tci: 2.13,
    ttci: 2.09,
    co2: 8184105.0,
    tco2: 716808.0,
    cost: 3.4260467e7,
    tcost: 1258452.0,
    fuel: 414097.0,
    tfuel: 33004.0,
    spd: 27.9,
    tspd: 28.5,
    vmt_tx100seg: 216498.9959999999846,
    trkvmt_tx100seg: 4375.028000000000247,
  },
  {
    tx100id: 2400010,
    year: 2022,
    rank: 172,
    trank: 46,
    dly: 372961.0,
    tdly: 69727.0,
    pti: 1.33,
    tpti: 1.28,
    tci: 1.09,
    ttci: 1.08,
    co2: 3755013.0,
    tco2: 2440228.0,
    cost: 1.1032291e7,
    tcost: 4165373.0,
    fuel: 178488.0,
    tfuel: 110983.0,
    spd: 59.3,
    tspd: 59.7,
    vmt_tx100seg: 384044.2770000000019,
    trkvmt_tx100seg: 97290.475000000005821,
  },
  {
    tx100id: 8000039,
    year: 2022,
    rank: 38,
    trank: 47,
    dly: 2436379.0,
    tdly: 158816.0,
    pti: 1.83,
    tpti: 1.79,
    tci: 1.36,
    ttci: 1.31,
    co2: 9520436.0,
    tco2: 3698112.0,
    cost: 6.0215112e7,
    tcost: 9245778.0,
    fuel: 477144.0,
    tfuel: 179578.0,
    spd: 53.0,
    tspd: 54.2,
    vmt_tx100seg: 1890306.8310000000056,
    trkvmt_tx100seg: 198609.051000000006752,
  },
  {
    tx100id: 8000018,
    year: 2022,
    rank: 48,
    trank: 48,
    dly: 574943.0,
    tdly: 44220.0,
    pti: 1.32,
    tpti: 1.34,
    tci: 1.19,
    ttci: 1.19,
    co2: 863248.0,
    tco2: 872892.0,
    cost: 1.4288068e7,
    tcost: 2566568.0,
    fuel: 64812.0,
    tfuel: 49086.0,
    spd: 54.7,
    tspd: 53.7,
    vmt_tx100seg: 637014.548000000068,
    trkvmt_tx100seg: 62563.484000000004016,
  },
  {
    tx100id: 3000059,
    year: 2022,
    rank: 112,
    trank: 49,
    dly: 1360752.0,
    tdly: 172039.0,
    pti: 1.38,
    tpti: 1.35,
    tci: 1.15,
    ttci: 1.13,
    co2: 4754259.0,
    tco2: 2851666.0,
    cost: 3.6307438e7,
    tcost: 9871919.0,
    fuel: 246860.0,
    tfuel: 148983.0,
    spd: 58.8,
    tspd: 59.1,
    vmt_tx100seg: 1818988.4469999999274,
    trkvmt_tx100seg: 313544.740999999979977,
  },
  {
    tx100id: 1200148,
    year: 2022,
    rank: 98,
    trank: 50,
    dly: 777745.0,
    tdly: 86516.0,
    pti: 1.46,
    tpti: 1.54,
    tci: 1.19,
    ttci: 1.22,
    co2: 1768459.0,
    tco2: 948130.0,
    cost: 2.0258062e7,
    tcost: 4925108.0,
    fuel: 106119.0,
    tfuel: 62694.0,
    spd: 57.2,
    tspd: 55.5,
    vmt_tx100seg: 1319353.3379999999888,
    trkvmt_tx100seg: 127911.747000000003027,
  },
  {
    tx100id: 1200144,
    year: 2022,
    rank: 25,
    trank: 51,
    dly: 2513818.0,
    tdly: 99992.0,
    pti: 1.53,
    tpti: 1.52,
    tci: 1.34,
    ttci: 1.3,
    co2: 9461228.0,
    tco2: 2858157.0,
    cost: 5.9971417e7,
    tcost: 5886886.0,
    fuel: 475967.0,
    tfuel: 137914.0,
    spd: 53.1,
    tspd: 54.0,
    vmt_tx100seg: 1482326.0879999999888,
    trkvmt_tx100seg: 89821.751999999993131,
  },
  {
    tx100id: 1900019,
    year: 2022,
    rank: 83,
    trank: 52,
    dly: 552068.0,
    tdly: 50284.0,
    pti: 1.54,
    tpti: 1.58,
    tci: 1.24,
    ttci: 1.23,
    co2: 3894993.0,
    tco2: 1620175.0,
    cost: 1.4363485e7,
    tcost: 2986540.0,
    fuel: 192748.0,
    tfuel: 76296.0,
    spd: 50.9,
    tspd: 52.5,
    vmt_tx100seg: 641102.51800000004,
    trkvmt_tx100seg: 80258.910000000003492,
  },
  {
    tx100id: 8000053,
    year: 2022,
    rank: 169,
    trank: 53,
    dly: 721964.0,
    tdly: 109612.0,
    pti: 1.48,
    tpti: 1.52,
    tci: 1.21,
    ttci: 1.22,
    co2: 2087752.0,
    tco2: 1122617.0,
    cost: 1.9807597e7,
    tcost: 6191271.0,
    fuel: 111771.0,
    tfuel: 61919.0,
    spd: 57.3,
    tspd: 56.1,
    vmt_tx100seg: 1012910.8179999999702,
    trkvmt_tx100seg: 175684.850000000005821,
  },
  {
    tx100id: 8000149,
    year: 2022,
    rank: 59,
    trank: 54,
    dly: 1695829.0,
    tdly: 124585.0,
    pti: 1.69,
    tpti: 1.58,
    tci: 1.39,
    ttci: 1.32,
    co2: 1.4994719e7,
    tco2: 4170111.0,
    cost: 4.357511e7,
    tcost: 7405522.0,
    fuel: 741043.0,
    tfuel: 188554.0,
    spd: 53.0,
    tspd: 53.6,
    vmt_tx100seg: 440146.4860000000335,
    trkvmt_tx100seg: 50438.218999999997322,
  },
  {
    tx100id: 1200187,
    year: 2022,
    rank: 78,
    trank: 55,
    dly: 854980.0,
    tdly: 72336.0,
    pti: 1.62,
    tpti: 1.71,
    tci: 1.29,
    ttci: 1.3,
    co2: 5045327.0,
    tco2: 2553738.0,
    cost: 2.1925506e7,
    tcost: 4319352.0,
    fuel: 252087.0,
    tfuel: 118301.0,
    spd: 50.2,
    tspd: 50.1,
    vmt_tx100seg: 807058.3699999999953,
    trkvmt_tx100seg: 82758.539999999993597,
  },
  {
    tx100id: 1200186,
    year: 2022,
    rank: 58,
    trank: 56,
    dly: 945474.0,
    tdly: 67526.0,
    pti: 1.47,
    tpti: 1.5,
    tci: 1.26,
    ttci: 1.28,
    co2: 3404398.0,
    tco2: 1428779.0,
    cost: 2.3536518e7,
    tcost: 3925337.0,
    fuel: 178140.0,
    tfuel: 75955.0,
    spd: 53.0,
    tspd: 53.0,
    vmt_tx100seg: 937501.5670000000391,
    trkvmt_tx100seg: 85768.932000000000698,
  },
  {
    tx100id: 1900012,
    year: 2022,
    rank: 27,
    trank: 57,
    dly: 2264775.0,
    tdly: 93526.0,
    pti: 2.0,
    tpti: 2.05,
    tci: 1.56,
    ttci: 1.52,
    co2: 1.0389777e7,
    tco2: 2946659.0,
    cost: 5.4410783e7,
    tcost: 5534889.0,
    fuel: 519196.0,
    tfuel: 136984.0,
    spd: 49.4,
    tspd: 50.0,
    vmt_tx100seg: 828396.0590000000084,
    trkvmt_tx100seg: 44590.254000000000815,
  },
  {
    tx100id: 8000019,
    year: 2022,
    rank: 93,
    trank: 58,
    dly: 434692.0,
    tdly: 42732.0,
    pti: 1.5,
    tpti: 1.46,
    tci: 1.23,
    ttci: 1.22,
    co2: 1399179.0,
    tco2: 771628.0,
    cost: 1.119759e7,
    tcost: 2470448.0,
    fuel: 79801.0,
    tfuel: 43936.0,
    spd: 53.3,
    tspd: 53.6,
    vmt_tx100seg: 455236.7820000000065,
    trkvmt_tx100seg: 55037.298000000002503,
  },
  {
    tx100id: 7000061,
    year: 2022,
    rank: 35,
    trank: 59,
    dly: 749682.0,
    tdly: 37323.0,
    pti: 1.71,
    tpti: 1.79,
    tci: 1.39,
    ttci: 1.3,
    co2: 7291957.0,
    tco2: 1450452.0,
    cost: 1.8781113e7,
    tcost: 2244648.0,
    fuel: 363940.0,
    tfuel: 65818.0,
    spd: 23.8,
    tspd: 27.0,
    vmt_tx100seg: 124992.9780000000028,
    trkvmt_tx100seg: 9457.837000000001353,
  },
  {
    tx100id: 1200185,
    year: 2022,
    rank: 34,
    trank: 60,
    dly: 1894187.0,
    tdly: 89512.0,
    pti: 1.7,
    tpti: 1.78,
    tci: 1.35,
    ttci: 1.35,
    co2: 7748438.0,
    tco2: 2388052.0,
    cost: 4.5771195e7,
    tcost: 5248569.0,
    fuel: 398366.0,
    tfuel: 115682.0,
    spd: 53.2,
    tspd: 52.8,
    vmt_tx100seg: 1336006.0789999999106,
    trkvmt_tx100seg: 81375.762000000002445,
  },
  {
    tx100id: 1200147,
    year: 2022,
    rank: 67,
    trank: 61,
    dly: 1381864.0,
    tdly: 103157.0,
    pti: 1.39,
    tpti: 1.43,
    tci: 1.19,
    ttci: 1.21,
    co2: 2512031.0,
    tco2: 1327187.0,
    cost: 3.4215968e7,
    tcost: 5892664.0,
    fuel: 148595.0,
    tfuel: 82472.0,
    spd: 56.9,
    tspd: 56.1,
    vmt_tx100seg: 1945191.1450000000186,
    trkvmt_tx100seg: 165672.182999999989988,
  },
  {
    tx100id: 2400013,
    year: 2022,
    rank: 176,
    trank: 62,
    dly: 183408.0,
    tdly: 25879.0,
    pti: 1.9,
    tpti: 1.25,
    tci: 1.19,
    ttci: 1.15,
    co2: 1314337.0,
    tco2: 673574.0,
    cost: 5074827.0,
    tcost: 1513971.0,
    fuel: 63836.0,
    tfuel: 31003.0,
    spd: 60.3,
    tspd: 61.1,
    vmt_tx100seg: 197449.9459999999963,
    trkvmt_tx100seg: 45347.881999999997788,
  },
  {
    tx100id: 8000084,
    year: 2022,
    rank: 76,
    trank: 63,
    dly: 1070016.0,
    tdly: 82668.0,
    pti: 1.61,
    tpti: 1.63,
    tci: 1.3,
    ttci: 1.29,
    co2: 4607539.0,
    tco2: 1974265.0,
    cost: 2.6947595e7,
    tcost: 4832091.0,
    fuel: 237310.0,
    tfuel: 101502.0,
    spd: 51.0,
    tspd: 51.6,
    vmt_tx100seg: 927966.9120000000112,
    trkvmt_tx100seg: 98837.171000000002095,
  },
  {
    tx100id: 8000050,
    year: 2022,
    rank: 52,
    trank: 64,
    dly: 1131647.0,
    tdly: 68928.0,
    pti: 1.68,
    tpti: 1.69,
    tci: 1.28,
    ttci: 1.26,
    co2: 5474044.0,
    tco2: 1634252.0,
    cost: 2.7964935e7,
    tcost: 4015778.0,
    fuel: 275880.0,
    tfuel: 79559.0,
    spd: 56.5,
    tspd: 56.4,
    vmt_tx100seg: 977878.9560000000056,
    trkvmt_tx100seg: 83453.381999999997788,
  },
  {
    tx100id: 8000021,
    year: 2022,
    rank: 150,
    trank: 65,
    dly: 394479.0,
    tdly: 48076.0,
    pti: 1.48,
    tpti: 1.62,
    tci: 1.23,
    ttci: 1.3,
    co2: 3927931.0,
    tco2: 2109479.0,
    cost: 1.081461e7,
    tcost: 2924554.0,
    fuel: 188599.0,
    tfuel: 95776.0,
    spd: 50.1,
    tspd: 48.2,
    vmt_tx100seg: 681733.7010000000009,
    trkvmt_tx100seg: 74084.637000000002445,
  },
  {
    tx100id: 8000038,
    year: 2022,
    rank: 79,
    trank: 66,
    dly: 1254573.0,
    tdly: 93012.0,
    pti: 1.53,
    tpti: 1.52,
    tci: 1.2,
    ttci: 1.19,
    co2: 4228657.0,
    tco2: 1789325.0,
    cost: 3.1280121e7,
    tcost: 5364453.0,
    fuel: 213926.0,
    tfuel: 89330.0,
    spd: 57.4,
    tspd: 57.8,
    vmt_tx100seg: 1567728.5079999999143,
    trkvmt_tx100seg: 166877.111999999993714,
  },
  {
    tx100id: 8000048,
    year: 2022,
    rank: 54,
    trank: 67,
    dly: 1515687.0,
    tdly: 89411.0,
    pti: 1.77,
    tpti: 1.82,
    tci: 1.39,
    ttci: 1.37,
    co2: 6051487.0,
    tco2: 2281389.0,
    cost: 3.7173505e7,
    tcost: 5234328.0,
    fuel: 305400.0,
    tfuel: 111909.0,
    spd: 49.9,
    tspd: 50.8,
    vmt_tx100seg: 1208121.5759999998845,
    trkvmt_tx100seg: 100258.020000000004075,
  },
  {
    tx100id: 8000011,
    year: 2022,
    rank: 77,
    trank: 68,
    dly: 770625.0,
    tdly: 55007.0,
    pti: 1.52,
    tpti: 1.55,
    tci: 1.24,
    ttci: 1.23,
    co2: 2484269.0,
    tco2: 1017418.0,
    cost: 1.9140741e7,
    tcost: 3177650.0,
    fuel: 130511.0,
    tfuel: 54821.0,
    spd: 55.4,
    tspd: 55.7,
    vmt_tx100seg: 996050.4939999999478,
    trkvmt_tx100seg: 100206.422999999995227,
  },
  {
    tx100id: 1900038,
    year: 2022,
    rank: 260,
    trank: 69,
    dly: 344447.0,
    tdly: 54957.0,
    pti: 1.66,
    tpti: 1.69,
    tci: 1.27,
    ttci: 1.26,
    co2: 2386524.0,
    tco2: 1537320.0,
    cost: 9731657.0,
    tcost: 3236156.0,
    fuel: 117660.0,
    tfuel: 73959.0,
    spd: 53.4,
    tspd: 53.4,
    vmt_tx100seg: 370220.9949999999953,
    trkvmt_tx100seg: 80812.072000000000116,
  },
  {
    tx100id: 1010103,
    year: 2022,
    rank: 62,
    trank: 70,
    dly: 983598.0,
    tdly: 60004.0,
    pti: 1.51,
    tpti: 1.59,
    tci: 1.29,
    ttci: 1.3,
    co2: 4345324.0,
    tco2: 2008118.0,
    cost: 2.4238541e7,
    tcost: 3566397.0,
    fuel: 214307.0,
    tfuel: 92558.0,
    spd: 54.5,
    tspd: 53.5,
    vmt_tx100seg: 616501.4660000000149,
    trkvmt_tx100seg: 44032.754000000000815,
  },
  {
    tx100id: 8000114,
    year: 2022,
    rank: 243,
    trank: 71,
    dly: 731521.0,
    tdly: 108512.0,
    pti: 1.23,
    tpti: 1.36,
    tci: 1.1,
    ttci: 1.16,
    co2: 1271731.0,
    tco2: 1460011.0,
    cost: 1.9924217e7,
    tcost: 6179134.0,
    fuel: 92765.0,
    tfuel: 79363.0,
    spd: 60.2,
    tspd: 59.3,
    vmt_tx100seg: 1923531.2939999999944,
    trkvmt_tx100seg: 170414.211000000010245,
  },
  {
    tx100id: 1200112,
    year: 2022,
    rank: 119,
    trank: 72,
    dly: 716633.0,
    tdly: 64005.0,
    pti: 1.42,
    tpti: 1.5,
    tci: 1.15,
    ttci: 1.19,
    co2: 1157499.0,
    tco2: 619988.0,
    cost: 1.8086261e7,
    tcost: 3639198.0,
    fuel: 76467.0,
    tfuel: 45691.0,
    spd: 58.6,
    tspd: 56.6,
    vmt_tx100seg: 1318649.530999999959,
    trkvmt_tx100seg: 114159.107999999992899,
  },
  {
    tx100id: 1200165,
    year: 2022,
    rank: 183,
    trank: 73,
    dly: 506654.0,
    tdly: 58391.0,
    pti: 1.37,
    tpti: 1.3,
    tci: 1.18,
    ttci: 1.15,
    co2: 1221686.0,
    tco2: 608276.0,
    cost: 1.325859e7,
    tcost: 3305382.0,
    fuel: 67178.0,
    tfuel: 35653.0,
    spd: 57.6,
    tspd: 58.7,
    vmt_tx100seg: 825364.704000000027,
    trkvmt_tx100seg: 143842.50800000000163,
  },
  {
    tx100id: 8000387,
    year: 2022,
    rank: 568,
    trank: 74,
    dly: 182837.0,
    tdly: 46262.0,
    pti: 1.21,
    tpti: 1.36,
    tci: 1.13,
    ttci: 1.18,
    co2: 3430631.0,
    tco2: 2292131.0,
    cost: 6027827.0,
    tcost: 2854689.0,
    fuel: 161145.0,
    tfuel: 103042.0,
    spd: 32.4,
    tspd: 31.9,
    vmt_tx100seg: 110998.2890000000043,
    trkvmt_tx100seg: 23307.640999999999622,
  },
  {
    tx100id: 1400800,
    year: 2022,
    rank: 585,
    trank: 75,
    dly: 47336.0,
    tdly: 12271.0,
    pti: 1.33,
    tpti: 1.32,
    tci: 1.17,
    ttci: 1.14,
    co2: 578766.0,
    tco2: 309528.0,
    cost: 1525911.0,
    tcost: 714634.0,
    fuel: 27838.0,
    tfuel: 14097.0,
    spd: 20.1,
    tspd: 20.9,
    vmt_tx100seg: 15946.4979999999996,
    trkvmt_tx100seg: 5436.625,
  },
  {
    tx100id: 8000198,
    year: 2022,
    rank: 64,
    trank: 76,
    dly: 1091554.0,
    tdly: 61059.0,
    pti: 1.44,
    tpti: 1.5,
    tci: 1.26,
    ttci: 1.24,
    co2: 1.0692331e7,
    tco2: 2709066.0,
    cost: 2.7569641e7,
    tcost: 3717211.0,
    fuel: 530719.0,
    tfuel: 123293.0,
    spd: 34.5,
    tspd: 36.4,
    vmt_tx100seg: 331694.7189999999828,
    trkvmt_tx100seg: 23652.937999999998283,
  },
  {
    tx100id: 2200033,
    year: 2022,
    rank: 208,
    trank: 77,
    dly: 240888.0,
    tdly: 29901.0,
    pti: 1.51,
    tpti: 1.5,
    tci: 1.29,
    ttci: 1.21,
    co2: 3429504.0,
    tco2: 1691572.0,
    cost: 6774025.0,
    tcost: 1875099.0,
    fuel: 164603.0,
    tfuel: 75907.0,
    spd: 29.3,
    tspd: 32.1,
    vmt_tx100seg: 111667.7010000000009,
    trkvmt_tx100seg: 22113.680000000000291,
  },
  {
    tx100id: 8000196,
    year: 2022,
    rank: 66,
    trank: 78,
    dly: 555867.0,
    tdly: 31356.0,
    pti: 1.45,
    tpti: 1.46,
    tci: 1.28,
    ttci: 1.21,
    co2: 5546667.0,
    tco2: 1415282.0,
    cost: 1.4065126e7,
    tcost: 1914442.0,
    fuel: 274724.0,
    tfuel: 63879.0,
    spd: 33.1,
    tspd: 35.4,
    vmt_tx100seg: 139107.8669999999984,
    trkvmt_tx100seg: 12199.123999999999796,
  },
  {
    tx100id: 3000012,
    year: 2022,
    rank: 39,
    trank: 79,
    dly: 856539.0,
    tdly: 32894.0,
    pti: 1.83,
    tpti: 1.85,
    tci: 1.44,
    ttci: 1.45,
    co2: 7162197.0,
    tco2: 1233431.0,
    cost: 2.0969322e7,
    tcost: 1970914.0,
    fuel: 359110.0,
    tfuel: 56278.0,
    spd: 39.5,
    tspd: 39.4,
    vmt_tx100seg: 211320.1749999999884,
    trkvmt_tx100seg: 10582.690999999998894,
  },
  {
    tx100id: 1200200,
    year: 2022,
    rank: 284,
    trank: 80,
    dly: 396566.0,
    tdly: 57816.0,
    pti: 1.3,
    tpti: 1.29,
    tci: 1.14,
    ttci: 1.13,
    co2: 1290577.0,
    tco2: 709449.0,
    cost: 1.0844251e7,
    tcost: 3303648.0,
    fuel: 75907.0,
    tfuel: 45525.0,
    spd: 56.4,
    tspd: 56.7,
    vmt_tx100seg: 704862.9790000000503,
    trkvmt_tx100seg: 135847.220999999990454,
  },
  {
    tx100id: 8000113,
    year: 2022,
    rank: 41,
    trank: 81,
    dly: 1328086.0,
    tdly: 54346.0,
    pti: 1.5,
    tpti: 1.59,
    tci: 1.23,
    ttci: 1.27,
    co2: 2269127.0,
    tco2: 905104.0,
    cost: 3.1378863e7,
    tcost: 3136815.0,
    fuel: 129956.0,
    tfuel: 54176.0,
    spd: 54.6,
    tspd: 52.8,
    vmt_tx100seg: 1457671.4420000000391,
    trkvmt_tx100seg: 64013.10899999999674,
  },
  {
    tx100id: 8000012,
    year: 2022,
    rank: 127,
    trank: 82,
    dly: 761382.0,
    tdly: 64190.0,
    pti: 1.5,
    tpti: 1.53,
    tci: 1.22,
    ttci: 1.22,
    co2: 2595857.0,
    tco2: 1164691.0,
    cost: 1.9246562e7,
    tcost: 3695365.0,
    fuel: 133034.0,
    tfuel: 59592.0,
    spd: 56.5,
    tspd: 56.2,
    vmt_tx100seg: 1111951.7280000001192,
    trkvmt_tx100seg: 128355.580000000001746,
  },
  {
    tx100id: 1010102,
    year: 2022,
    rank: 97,
    trank: 83,
    dly: 536915.0,
    tdly: 37720.0,
    pti: 1.5,
    tpti: 1.64,
    tci: 1.23,
    ttci: 1.29,
    co2: 2302648.0,
    tco2: 1409070.0,
    cost: 1.3421809e7,
    tcost: 2260997.0,
    fuel: 125587.0,
    tfuel: 64025.0,
    spd: 54.6,
    tspd: 51.2,
    vmt_tx100seg: 466725.679999999993,
    trkvmt_tx100seg: 28241.703000000001339,
  },
  {
    tx100id: 1200156,
    year: 2022,
    rank: 80,
    trank: 84,
    dly: 1183385.0,
    tdly: 69849.0,
    pti: 1.81,
    tpti: 1.85,
    tci: 1.32,
    ttci: 1.32,
    co2: 4049795.0,
    tco2: 1711988.0,
    cost: 2.8946692e7,
    tcost: 4090588.0,
    fuel: 213248.0,
    tfuel: 89686.0,
    spd: 53.0,
    tspd: 53.3,
    vmt_tx100seg: 1098639.0679999999702,
    trkvmt_tx100seg: 82523.940999999991618,
  },
  {
    tx100id: 1200159,
    year: 2022,
    rank: 143,
    trank: 85,
    dly: 517470.0,
    tdly: 47668.0,
    pti: 1.47,
    tpti: 1.56,
    tci: 1.25,
    ttci: 1.28,
    co2: 1706461.0,
    tco2: 1046101.0,
    cost: 1.3226814e7,
    tcost: 2779885.0,
    fuel: 95772.0,
    tfuel: 57401.0,
    spd: 56.0,
    tspd: 55.1,
    vmt_tx100seg: 692071.4159999999683,
    trkvmt_tx100seg: 65944.650999999998021,
  },
  {
    tx100id: 1000013,
    year: 2022,
    rank: 87,
    trank: 86,
    dly: 529410.0,
    tdly: 31924.0,
    pti: 1.47,
    tpti: 1.43,
    tci: 1.18,
    ttci: 1.15,
    co2: 2867303.0,
    tco2: 1068703.0,
    cost: 1.3119447e7,
    tcost: 1901600.0,
    fuel: 144322.0,
    tfuel: 50131.0,
    spd: 53.3,
    tspd: 54.7,
    vmt_tx100seg: 631248.8059999999823,
    trkvmt_tx100seg: 59616.150000000001455,
  },
  {
    tx100id: 8000131,
    year: 2022,
    rank: 147,
    trank: 87,
    dly: 525969.0,
    tdly: 46557.0,
    pti: 1.95,
    tpti: 2.02,
    tci: 1.38,
    ttci: 1.38,
    co2: 2654211.0,
    tco2: 993543.0,
    cost: 1.3495242e7,
    tcost: 2700548.0,
    fuel: 134797.0,
    tfuel: 49411.0,
    spd: 54.5,
    tspd: 53.8,
    vmt_tx100seg: 454662.3119999999763,
    trkvmt_tx100seg: 52705.572999999996682,
  },
  {
    tx100id: 3000058,
    year: 2022,
    rank: 459,
    trank: 88,
    dly: 322946.0,
    tdly: 59206.0,
    pti: 1.09,
    tpti: 1.12,
    tci: 1.04,
    ttci: 1.05,
    co2: 934078.0,
    tco2: 508910.0,
    cost: 9201099.0,
    tcost: 3335014.0,
    fuel: 52932.0,
    tfuel: 30930.0,
    spd: 63.0,
    tspd: 62.4,
    vmt_tx100seg: 857246.064000000013,
    trkvmt_tx100seg: 166246.073999999993248,
  },
  {
    tx100id: 1900086,
    year: 2022,
    rank: 1014,
    trank: 89,
    dly: 81041.0,
    tdly: 32607.0,
    pti: 1.17,
    tpti: 1.19,
    tci: 1.06,
    ttci: 1.08,
    co2: 171292.0,
    tco2: 152108.0,
    cost: 2897448.0,
    tcost: 1826539.0,
    fuel: 15644.0,
    tfuel: 13792.0,
    spd: 60.6,
    tspd: 58.5,
    vmt_tx100seg: 236246.7809999999881,
    trkvmt_tx100seg: 72892.198000000003958,
  },
  {
    tx100id: 8000081,
    year: 2022,
    rank: 56,
    trank: 90,
    dly: 929949.0,
    tdly: 40951.0,
    pti: 1.76,
    tpti: 1.83,
    tci: 1.37,
    ttci: 1.34,
    co2: 4087573.0,
    tco2: 1044549.0,
    cost: 2.2412299e7,
    tcost: 2403104.0,
    fuel: 208969.0,
    tfuel: 53372.0,
    spd: 54.3,
    tspd: 54.3,
    vmt_tx100seg: 708449.1589999999851,
    trkvmt_tx100seg: 44147.768000000003667,
  },
  {
    tx100id: 3000006,
    year: 2022,
    rank: 148,
    trank: 91,
    dly: 438083.0,
    tdly: 36767.0,
    pti: 1.31,
    tpti: 1.33,
    tci: 1.15,
    ttci: 1.14,
    co2: 1058992.0,
    tco2: 488662.0,
    cost: 1.1013952e7,
    tcost: 2098014.0,
    fuel: 58094.0,
    tfuel: 28096.0,
    spd: 58.2,
    tspd: 58.4,
    vmt_tx100seg: 773307.2950000000419,
    trkvmt_tx100seg: 84400.948999999993248,
  },
  {
    tx100id: 2400030,
    year: 2022,
    rank: 229,
    trank: 92,
    dly: 334557.0,
    tdly: 36492.0,
    pti: 2.32,
    tpti: 2.24,
    tci: 1.56,
    ttci: 1.45,
    co2: 3735263.0,
    tco2: 1779448.0,
    cost: 9092730.0,
    tcost: 2245855.0,
    fuel: 180286.0,
    tfuel: 80471.0,
    spd: 34.8,
    tspd: 39.4,
    vmt_tx100seg: 95688.2419999999984,
    trkvmt_tx100seg: 15679.134000000000015,
  },
  {
    tx100id: 8000077,
    year: 2022,
    rank: 91,
    trank: 93,
    dly: 321327.0,
    tdly: 18480.0,
    pti: 1.75,
    tpti: 1.8,
    tci: 1.49,
    ttci: 1.48,
    co2: 1342068.0,
    tco2: 538644.0,
    cost: 7875290.0,
    tcost: 1091437.0,
    fuel: 68195.0,
    tfuel: 26397.0,
    spd: 47.8,
    tspd: 47.3,
    vmt_tx100seg: 186477.8360000000102,
    trkvmt_tx100seg: 14795.043999999999869,
  },
  {
    tx100id: 8000062,
    year: 2022,
    rank: 55,
    trank: 94,
    dly: 787717.0,
    tdly: 32816.0,
    pti: 1.75,
    tpti: 1.75,
    tci: 1.42,
    ttci: 1.38,
    co2: 4046802.0,
    tco2: 1112238.0,
    cost: 1.9000153e7,
    tcost: 1954555.0,
    fuel: 203507.0,
    tfuel: 52551.0,
    spd: 47.0,
    tspd: 48.8,
    vmt_tx100seg: 576260.6230000000214,
    trkvmt_tx100seg: 34730.144999999996799,
  },
  {
    tx100id: 8000133,
    year: 2022,
    rank: 134,
    trank: 95,
    dly: 615697.0,
    tdly: 47374.0,
    pti: 1.4,
    tpti: 1.37,
    tci: 1.16,
    ttci: 1.14,
    co2: 997654.0,
    tco2: 460703.0,
    cost: 1.5263552e7,
    tcost: 2679717.0,
    fuel: 56441.0,
    tfuel: 28605.0,
    spd: 58.0,
    tspd: 58.9,
    vmt_tx100seg: 1078809.4340000001248,
    trkvmt_tx100seg: 141675.314000000013039,
  },
  {
    tx100id: 1900072,
    year: 2022,
    rank: 21,
    trank: 96,
    dly: 1229525.0,
    tdly: 24754.0,
    pti: 1.94,
    tpti: 1.89,
    tci: 1.56,
    ttci: 1.42,
    co2: 1.0122466e7,
    tco2: 930543.0,
    cost: 2.9348503e7,
    tcost: 1483126.0,
    fuel: 511642.0,
    tfuel: 42533.0,
    spd: 27.8,
    tspd: 31.5,
    vmt_tx100seg: 193262.2559999999939,
    trkvmt_tx100seg: 6431.807999999999993,
  },
  {
    tx100id: 1200094,
    year: 2022,
    rank: 782,
    trank: 97,
    dly: 193371.0,
    tdly: 51845.0,
    pti: 1.17,
    tpti: 1.19,
    tci: 1.06,
    ttci: 1.08,
    co2: 212371.0,
    tco2: 137856.0,
    cost: 6024636.0,
    tcost: 2899379.0,
    fuel: 24521.0,
    tfuel: 20491.0,
    spd: 61.8,
    tspd: 59.9,
    vmt_tx100seg: 640412.326999999932,
    trkvmt_tx100seg: 133898.380999999993946,
  },
  {
    tx100id: 3000005,
    year: 2022,
    rank: 175,
    trank: 98,
    dly: 502607.0,
    tdly: 44139.0,
    pti: 1.17,
    tpti: 1.18,
    tci: 1.11,
    ttci: 1.12,
    co2: 783376.0,
    tco2: 407733.0,
    cost: 1.2642483e7,
    tcost: 2498545.0,
    fuel: 47153.0,
    tfuel: 27280.0,
    spd: 59.0,
    tspd: 59.1,
    vmt_tx100seg: 951464.4129999999423,
    trkvmt_tx100seg: 106915.311000000001513,
  },
  {
    tx100id: 1900032,
    year: 2022,
    rank: 111,
    trank: 99,
    dly: 211566.0,
    tdly: 13769.0,
    pti: 1.61,
    tpti: 1.58,
    tci: 1.21,
    ttci: 1.16,
    co2: 849182.0,
    tco2: 309898.0,
    cost: 5238657.0,
    tcost: 804354.0,
    fuel: 45272.0,
    tfuel: 16728.0,
    spd: 54.2,
    tspd: 55.5,
    vmt_tx100seg: 297257.4660000000149,
    trkvmt_tx100seg: 29920.953000000001339,
  },
  {
    tx100id: 8000130,
    year: 2022,
    rank: 171,
    trank: 100,
    dly: 555870.0,
    tdly: 48117.0,
    pti: 1.6,
    tpti: 1.57,
    tci: 1.24,
    ttci: 1.22,
    co2: 2245432.0,
    tco2: 1047496.0,
    cost: 1.4151503e7,
    tcost: 2797340.0,
    fuel: 116854.0,
    tfuel: 53542.0,
    spd: 53.8,
    tspd: 54.1,
    vmt_tx100seg: 533030.4440000000177,
    trkvmt_tx100seg: 63947.470000000001164,
  },
];
