import React, { useState } from "react";
// react plugin for creating charts
//import ChartistGraph from "react-chartist";
//import * as tooltip from 'chartist-plugin-tooltips';
// @material-ui/core
import { withStyles, makeStyles } from "@material-ui/core/styles";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons
//import Store from "@material-ui/icons/Store";
//import Warning from "@material-ui/icons/Warning";
//import DateRange from "@material-ui/icons/DateRange";
//import LocalOffer from "@material-ui/icons/LocalOffer";
//import Update from "@material-ui/icons/Update";
//import ArrowUpward from "@material-ui/icons/ArrowUpward";
//import ArrowDownward from "@material-ui/icons/ArrowDownward";
//import AccessTime from "@material-ui/icons/AccessTime";
//import Accessibility from "@material-ui/icons/Accessibility";
//import BugReport from "@material-ui/icons/BugReport";
//import Code from "@material-ui/icons/Code";
//import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
//import Tasks from "components/Tasks/Tasks.js";
//import CustomTabs from "components/CustomTabs/CustomTabs.js";
//import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
//import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

//import { bugs, website, server } from "variables/general.js";

import {
  //dailySalesChart,
  //emailsSubscriptionChart,
  //completedTasksChart,
  Chartist,
  delays,
  durations,
  //delays2, durations2
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import districtGeom from "../../data/districtGeom.json";
//import { districtData } from '../../data/districtData';

import countyGeom from "../../data/countyGeom.json";
//import { countyData } from '../../data/countyData';

// import { congestionData } from "../../data/congData";
// import { congestionData } from "../../data/congData_2021";
import { congestionData } from "../../data/congData_2022";
//import { congestionDataTrkTop100 } from '../../data/congData_trktop100_2019';
// import { congestionDataTrkTop100 } from "../../data/congData_trktop100_2021";
import { congestionDataTrkTop100 } from "../../data/congData_trktop100_2022";

import stateGeom from "../../data/stateGeom.json";
import interstates_loopsGeom from "../../data/interstates_loopsGeom.json";
import portToPlainsGeom from "../../data/portToPlainsGeom.json";
import mpoGeom from "../../data/mpoGeom.json";
//import tx100_trktop100Geom from '../../data/tx100_trktop100Geom.json';
// import tx100_trktop100Geom from "../../data/geom_trktop100_2021.json";
import tx100_trktop100Geom from "../../data/geom_trktop100_2022.json";

import MapTemp from "../../components/leafletMap.js";

import "../../App.css";

import { styles2 } from "../../variables/constants.js";

import { primaryColor } from "../../assets/jss/material-dashboard-react";

//import Plot from 'react-plotly.js';

//import DialogSelect from "components/selectModal.js";

//import RegionSelect from "components/RegionSelect.js";

import {
  TXDOTdist_LIST,
  county_LIST,
  regions_LIST,
  MPO_LIST,
  corridors_LIST,
  state_LIST,
  truckRanking_LIST,
} from "../../variables/constants.js";

//import InputLabel from '@material-ui/core/InputLabel';
//import FormHelperText from '@material-ui/core/FormHelperText';
//import FormControl from '@material-ui/core/FormControl';
//import Select from '@material-ui/core/Select';
//import NativeSelect from '@material-ui/core/NativeSelect';

import TxDOTlogo from "../../assets/img/Transparent Background Color3lineLOGO_TxDOT_RGB_300dpi.png";
//import TxDOTlogo from '../logos/Transparent Background Color3lineLOGO_TxDOT_RGB_300dpi.svg'
import TTIlogo from "../../assets/img/TTI-Color.png";

//import fetchDataValue from "../../utils/fetchDataValue.js"
import fetchTruckData from "../../utils/fetchTruckData.js";
import fetchTruckDataYrDiff from "../../utils/fetchTruckDataYrDiff.js";
import SetPcDiffYrText from "../../utils/SetPcDiffYrText.js";
import SetPcDiffYrTextSpeed from "../../utils/SetPcDiffYrTextSpeed.js";
import SetTableDiffYrText from "../../utils/SetTableDiffYrText.js";
import fetchAllVehData from "../../utils/fetchAllVehData.js";

import fetchTruckDataTop100 from "../../utils/fetchTruckDataTop100.js";
import fetchAllVehDataTop100 from "../../utils/fetchAllVehDataTop100.js";
import fetchTruckDataYrDiffTop100 from "../../utils/fetchTruckDataYrDiffTop100.js";

//import printReportCard from "../../utils/printReportCard.js"

import PlotlyTrendPlot from "../../components/plotlyTrendPlot.js";

import portOfHoustonGeom from "../../data/portOfHoustonGeom.json";

//import MyDoc2 from "../../utils/myDocPDF2.js";

import ContactUsModal from "components/contactUsModal.js";
import DownloadModal from "components/downloadsModal.js";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
// HC_exporting(Highcharts);

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 100,
    maxWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  divCenter1: {
    display: "inline-block",
    width: "50%",
    float: "left",
    paddingBottom: "0",
    postition: "relative",
    msTransform: "translateY(50%)",
    webkitTranform: "translateY(50%)",
    transform: "translateY(50%)",
  },
  divCenter1Test: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export default function Dashboard(props) {
  const classes = useStyles();
  const classes2 = useStyles2();

  const [activeGeomCode, setactiveGeomCode] = useState(1);

  const [activeGeomName, setactiveGeomName] = useState("Statewide");

  const [activeGeom, setactiveGeom] = useState(
    stateGeom.filter((a) => a.properties.regioncode === 1)
  );

  const [activeData, setactiveData] = useState(
    congestionData.filter(
      (a) => (a.agglevel === "state") & (a.regioncode === 1)
    )
  );
  //console.log("activeData");
  //console.log(activeData);

  const [yearsList, setyearsList] = useState(
    congestionData
      .filter((a) => (a.agglevel === "state") & (a.regioncode === 1))
      .map((a) => a.year)
  );
  // console.log("yearsList");
  // console.log(yearsList);

  const [currYear, setcurrYear] = useState(Math.max.apply(null, yearsList));
  // console.log("currYear");
  // console.log(currYear);

  const [truckData, settruckData] = useState(
    fetchTruckData(
      congestionData.filter(
        (a) => (a.agglevel === "state") & (a.regioncode === 1)
      ),
      activeGeomCode,
      currYear
    )
  );
  //const [truckData, settruckData] = useState(fetchTruckData(countyData.filter((a) => a.co === activeGeomCode), activeGeomCode));

  const [truckDataYrDiff, settruckDataYrDiff] = useState(
    fetchTruckDataYrDiff(
      congestionData.filter(
        (a) => (a.agglevel === "state") & (a.regioncode === 1)
      ),
      activeGeomCode,
      currYear
    )
  );
  // console.log("truckDataYrDiff");
  // console.log(truckDataYrDiff);

  const [truckDelayData, settruckDelayData] = useState(
    congestionData
      .filter((a) => (a.agglevel === "state") & (a.regioncode === 1))
      .map((a) => a.trkdelay)
  );
  console.log("truckDelayData", truckDelayData);
  const [truckDelayChart, settruckDelayChart] = useState({
    data: {
      labels: yearsList,
      series: [
        congestionData
          .filter((a) => (a.agglevel === "state") & (a.regioncode === 1))
          .map((a) => a.trkdelay),
      ],
    },
    options: {
      //lineSmooth: Chartist.Interpolation.cardinal({
      //  tension: 0
      //}),
      low:
        Math.min.apply(
          null,
          congestionData
            .filter((a) => (a.agglevel === "state") & (a.regioncode === 1))
            .map((a) => a.trkdelay)
        ) * 0.95,
      high:
        Math.max.apply(
          null,
          congestionData
            .filter((a) => (a.agglevel === "state") & (a.regioncode === 1))
            .map((a) => a.trkdelay)
        ) * 1.05, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      classNames: {
        //line: 'ct-line-custom',
        //series: 'ct-series-custom',
        line: "ct-line",
        series: "ct-series",
        grid: "ct-grid-custom",
      },
      axisY: {
        // On the y-axis start means left and end means right
        //position: 'end',
        labelInterpolationFnc: function(value) {
          var temp = Number(value).toLocaleString();
          return temp;
        },
      },
      //plugins: [
      //Chartist.plugins.tooltip()
      //],
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 25,
      },
    },

    // for animation
    //animation: {
    //  draw: function (data) {
    //    if (data.type === "line" || data.type === "area") {
    //      data.element.animate({
    //        d: {
    //          begin: 600,
    //          dur: 700,
    //          from: data.path
    //            .clone()
    //            .scale(1, 0)
    //            .translate(0, data.chartRect.height())
    //            .stringify(),
    //          to: data.path.clone().stringify(),
    //          easing: Chartist.Svg.Easing.easeOutQuint
    //        }
    //      });
    //    } else if (data.type === "point") {
    //      data.element.animate({
    //        opacity: {
    //          begin: (data.index + 1) * delays,
    //          dur: durations,
    //          from: 0,
    //          to: 1,
    //          easing: "ease"
    //        }
    //      });
    //    }
    //  }
    //}
  });

  const [truckVmtData, settruckVmtData] = useState(
    congestionData
      .filter((a) => (a.agglevel === "state") & (a.regioncode === 1))
      .map((a) => a.trkttivmt)
  );

  const [truckVMTChart, settruckVMTChart] = useState({
    data: {
      labels: yearsList,
      series: [
        congestionData
          .filter((a) => (a.agglevel === "district") & (a.regioncode === 1))
          .map((a) => a.trkttivmt),
      ],
    },
    options: {
      //lineSmooth: Chartist.Interpolation.cardinal({
      //  tension: 0
      //}),
      low:
        Math.min.apply(
          null,
          congestionData
            .filter((a) => (a.agglevel === "state") & (a.regioncode === 1))
            .map((a) => a.trkttivmt)
        ) * 0.95,
      high:
        Math.max.apply(
          null,
          congestionData
            .filter((a) => (a.agglevel === "state") & (a.regioncode === 1))
            .map((a) => a.trkttivmt)
        ) * 1.05, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      classNames: {
        line: "ct-line",
        series: "ct-series",
        grid: "ct-grid-custom",
      },
      axisY: {
        // On the y-axis start means left and end means right
        //position: 'end',
        labelInterpolationFnc: function(value) {
          var temp = Number(value).toLocaleString();
          return temp;
        },
      },
      //plugins: [
      //Chartist.plugins.tooltip()
      //],
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 25,
      },
    },

    // for animation
    //animation: {
    //  draw: function (data) {
    //    if (data.type === "line" || data.type === "area") {
    //      data.element.animate({
    //        d: {
    //          begin: 600,
    //          dur: 700,
    //          from: data.path
    //            .clone()
    //            .scale(1, 0)
    //            .translate(0, data.chartRect.height())
    //            .stringify(),
    //          to: data.path.clone().stringify(),
    //          easing: Chartist.Svg.Easing.easeOutQuint
    //        }
    //      });
    //    } else if (data.type === "point") {
    //      data.element.animate({
    //        opacity: {
    //          begin: (data.index + 1) * delays,
    //          dur: durations,
    //          from: 0,
    //          to: 1,
    //          easing: "ease"
    //        }
    //      });
    //    }
    //  }
    //}
  });

  const [truckSPEEDData, settruckSPEEDData] = useState(
    congestionData
      .filter((a) => (a.agglevel === "state") & (a.regioncode === 1))
      .map((a) => a.trkspeed)
  );
  const [truckSPEEDChart, settruckSPEEDChart] = useState({
    data: {
      labels: yearsList,
      series: [
        congestionData
          .filter((a) => (a.agglevel === "district") & (a.regioncode === 1))
          .map((a) => a.trkspeed),
      ],
    },
    options: {
      //lineSmooth: Chartist.Interpolation.cardinal({
      //  tension: 0
      //}),
      low:
        Math.min.apply(
          null,
          congestionData
            .filter((a) => (a.agglevel === "state") & (a.regioncode === 1))
            .map((a) => a.trkspeed)
        ) * 0.95,
      high:
        Math.max.apply(
          null,
          congestionData
            .filter((a) => (a.agglevel === "state") & (a.regioncode === 1))
            .map((a) => a.trkspeed)
        ) * 1.05, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    // for animation
    animation: {
      draw: function(data) {
        if (data.type === "line" || data.type === "area") {
          data.element.animate({
            d: {
              begin: 600,
              dur: 700,
              from: data.path
                .clone()
                .scale(1, 0)
                .translate(0, data.chartRect.height())
                .stringify(),
              to: data.path.clone().stringify(),
              easing: Chartist.Svg.Easing.easeOutQuint,
            },
          });
        } else if (data.type === "point") {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays,
              dur: durations,
              from: 0,
              to: 1,
              easing: "ease",
            },
          });
        }
      },
    },
  });

  const [truckTtiData, settrucktruckTtiData] = useState(
    congestionData
      .filter((a) => (a.agglevel === "state") & (a.regioncode === 1))
      .map((a) => a.trktti)
  );

  const [truckTTIChart, settruckTTIChart] = useState({
    header: "Travel Time Index (TTI)",
    type: "TTI",
    data: {
      labels: yearsList,
      series: [
        congestionData
          .filter((a) => (a.agglevel === "district") & (a.regioncode === 1))
          .map((a) => a.trktti),
      ],
    },
    options: {
      //lineSmooth: Chartist.Interpolation.cardinal({
      //  tension: 0
      //}),
      //low: Math.min.apply(null, congestionData.filter((a) => a.agglevel === 'state' & a.regioncode === 1).map((a) => a.trktti)) * 0.975,
      //high: Math.max.apply(null, congestionData.filter((a) => a.agglevel === 'state' & a.regioncode === 1).map((a) => a.trktti)) * 1.025, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      low: 1,
      high:
        Math.max.apply(
          null,
          congestionData
            .filter((a) => (a.agglevel === "state") & (a.regioncode === 1))
            .map((a) => a.trktti)
        ) * 10.25, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      classNames: {
        line: "ct-line",
        series: "ct-series",
        grid: "ct-grid-custom",
      },
      axisY: {
        // On the y-axis start means left and end means right
        //position: 'end',
        labelInterpolationFnc: function(value) {
          var temp = Number(value).toLocaleString();
          return temp;
        },
      },
      //plugins: [
      //Chartist.plugins.tooltip()
      //]
    },
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },

    // for animation
    //animation: {
    //  draw: function (data) {
    //    if (data.type === "line" || data.type === "area") {
    //      data.element.animate({
    //        d: {
    //          begin: 600,
    //          dur: 700,
    //          from: data.path
    //            .clone()
    //            .scale(1, 0)
    //            .translate(0, data.chartRect.height())
    //            .stringify(),
    //          to: data.path.clone().stringify(),
    //          easing: Chartist.Svg.Easing.easeOutQuint
    //        }
    //      });
    //    } else if (data.type === "point") {
    //      data.element.animate({
    //        opacity: {
    //          begin: (data.index + 1) * delays,
    //          dur: durations,
    //          from: 0,
    //          to: 1,
    //          easing: "ease"
    //        }
    //      });
    //    }
    //  }
    //}
  });

  const [allVehData, setallVehData] = useState(
    fetchAllVehData(
      congestionData.filter(
        (a) => (a.agglevel === "state") & (a.regioncode === 1)
      ),
      activeGeomCode,
      currYear
    )
  );

  const [bboxBuffer, setallbboxBuffer] = useState(1.25);

  const Map = () => {
    return (
      <MapTemp
        activeGeom={activeGeom}
        bboxBuffer={bboxBuffer}
        mapColor={mapColor}
        lineWeight={lineWeight}
      />
    );
  };

  const [
    bottomBlockMarginTopPrint,
    setbottomBlockMarginTopPrint,
  ] = React.useState("25px");
  const [areaHeaderFontSizePrint, setareaHeaderFontSizePrint] = React.useState(
    "25px"
  );
  const [descTextFontSizePrint, setdescTextFontSizePrint] = React.useState(
    "20px"
  );

  function handleDistrictChange(event) {
    setbottomBlockMarginTopPrint("25px");
    setareaHeaderFontSizePrint("25px");
    //setdescTextFontSizePrint("20px");

    let codeTemp = Number(event.target.value);
    if (codeTemp) {
      setdistrictDropdownValue(codeTemp);
      setcountyDropdownValue(0);
      setregionDropdownValue(0);
      setmpoDropdownValue(0);
      setcorridorDropdownValue(0);
      setstateDropdownValue(0);
      settrkTop100DropdownValue(0);

      setactiveData(
        congestionData.filter(
          (a) => (a.agglevel === "district") & (a.regioncode === codeTemp)
        )
      );
      //console.log("activeData District Change");
      //console.log(activeData);

      setactiveGeomCode(codeTemp);

      let nameTemp = TXDOTdist_LIST.filter((a) => a.id === codeTemp).map(
        (a) => a.name
      )[0];
      setactiveGeomName(nameTemp);
      setactiveGeom(
        districtGeom.filter((a) => a.properties.dist_nbr === codeTemp)
      );
      setallbboxBuffer(1.25);
      setmapColor("grey");
      setLineWeight(0.5);

      if (nameTemp.length < 47) {
        setdescTextFontSizePrint("20px");
      } else {
        if (nameTemp.length < 94) {
          setdescTextFontSizePrint("19px");
        } else {
          setareaHeaderFontSizePrint("16px");
        }
      }

      updateDashboard(
        congestionData.filter(
          (a) => (a.agglevel === "district") & (a.regioncode === codeTemp)
        ),
        codeTemp
      );
    }
  }

  function handleCountyChange(event) {
    setbottomBlockMarginTopPrint("25px");
    setareaHeaderFontSizePrint("25px");
    //setdescTextFontSizePrint("20px");

    let codeTemp = Number(event.target.value);
    if (codeTemp) {
      setdistrictDropdownValue(0);
      setcountyDropdownValue(codeTemp);
      setregionDropdownValue(0);
      setmpoDropdownValue(0);
      setcorridorDropdownValue(0);
      setstateDropdownValue(0);
      settrkTop100DropdownValue(0);

      setactiveData(
        congestionData.filter(
          (a) => (a.agglevel === "county") & (a.regioncode === codeTemp)
        )
      );
      //console.log("activeData County Change");
      //console.log(activeData);

      setactiveGeomCode(codeTemp);

      let nameTemp = county_LIST
        .filter((a) => a.id === codeTemp)
        .map((a) => a.name)[0];
      setactiveGeomName(nameTemp + " County");
      setactiveGeom(
        countyGeom.filter((a) => a.properties.cnty_nbr === codeTemp)
      );
      setallbboxBuffer(0.625);
      if (nameTemp === "Harris") {
        setallbboxBuffer(0);
      } else {
        setallbboxBuffer(0);
      }
      setmapColor("grey");
      setLineWeight(0.5);

      if (nameTemp.length < 47) {
        setdescTextFontSizePrint("20px");
      } else {
        if (nameTemp.length < 94) {
          setdescTextFontSizePrint("19px");
        } else {
          setareaHeaderFontSizePrint("16px");
        }
      }

      updateDashboard(
        congestionData.filter(
          (a) => (a.agglevel === "county") & (a.regioncode === codeTemp)
        ),
        codeTemp
      );
    }
  }

  function handleRegionChange(event) {
    setbottomBlockMarginTopPrint("25px");
    setareaHeaderFontSizePrint("25px");
    //setdescTextFontSizePrint("20px");

    let codeTemp = Number(event.target.value);
    if (codeTemp) {
      setdistrictDropdownValue(0);
      setcountyDropdownValue(0);
      setregionDropdownValue(codeTemp);
      setmpoDropdownValue(0);
      setcorridorDropdownValue(0);
      setstateDropdownValue(0);
      settrkTop100DropdownValue(0);

      setactiveData(
        congestionData.filter(
          (a) => (a.agglevel === "county") & (a.regioncode === codeTemp)
        )
      );
      //console.log("activeData County Change");
      //console.log(activeData);

      setactiveGeomCode(codeTemp);

      let nameTemp = regions_LIST
        .filter((a) => a.id === codeTemp)
        .map((a) => a.name)[0];
      let brr = regions_LIST
        .filter((a) => a.id === codeTemp)
        .map((a) => a.counties)[0];
      //console.log("brr");
      //console.log(brr);
      setactiveGeomName(nameTemp);
      //portOfHoustonGeom
      if (codeTemp == 7) {
        setactiveGeom(
          portOfHoustonGeom.filter((a) => a.properties.regionid == codeTemp)
        );
      } else {
        setactiveGeom(
          countyGeom.filter((a) => brr.includes(a.properties.cnty_nbr))
        );
      }
      //res = arr.filter(f => !brr.includes(f));
      setallbboxBuffer(0);
      setmapColor("grey");
      setLineWeight(0.5);

      if (nameTemp.length < 47) {
        setdescTextFontSizePrint("20px");
      } else {
        if (nameTemp.length < 94) {
          setdescTextFontSizePrint("19px");
        } else {
          setareaHeaderFontSizePrint("16px");
        }
      }

      updateDashboard(
        congestionData.filter(
          (a) => (a.agglevel === "region") & (a.regioncode === codeTemp)
        ),
        codeTemp
      );
    }
  }

  function handleMpoChange(event) {
    setbottomBlockMarginTopPrint("25px");
    setdescTextFontSizePrint("20px");

    let codeTemp = Number(event.target.value);
    if (codeTemp) {
      //console.log("codeTemp");
      //console.log(codeTemp);

      setdistrictDropdownValue(0);
      setcountyDropdownValue(0);
      setregionDropdownValue(0);
      setmpoDropdownValue(codeTemp);
      setcorridorDropdownValue(0);
      setstateDropdownValue(0);
      settrkTop100DropdownValue(0);

      setactiveData(
        congestionData.filter(
          (a) => (a.agglevel === "mpo") & (a.regioncode === codeTemp)
        )
      );
      //console.log("activeData County Change");
      //console.log(activeData);

      setactiveGeomCode(codeTemp);

      let nameTemp = MPO_LIST.filter((a) => a.id === codeTemp).map(
        (a) => a.name
      )[0];
      setactiveGeomName(nameTemp);
      setactiveGeom(mpoGeom.filter((a) => a.properties.MPO_NBR === codeTemp));
      setallbboxBuffer(0.625);
      setmapColor("grey");
      setLineWeight(0.5);

      if (nameTemp.length > 30) {
        setareaHeaderFontSizePrint("25px");
      } else {
        setareaHeaderFontSizePrint("30px");
      }
      if (nameTemp.length < 47) {
        setdescTextFontSizePrint("20px");
      } else {
        if (nameTemp.length < 94) {
          setdescTextFontSizePrint("19px");
        } else {
          setareaHeaderFontSizePrint("16px");
        }
      }

      updateDashboard(
        congestionData.filter(
          (a) => (a.agglevel === "mpo") & (a.regioncode === codeTemp)
        ),
        codeTemp
      );
    }
  }

  function handleCorridorChange(event) {
    setbottomBlockMarginTopPrint("25px");
    setareaHeaderFontSizePrint("25px");
    //setdescTextFontSizePrint("20px");

    let codeTemp = Number(event.target.value);
    if (codeTemp) {
      setdistrictDropdownValue(0);
      setcountyDropdownValue(0);
      setregionDropdownValue(0);
      setmpoDropdownValue(0);
      setcorridorDropdownValue(codeTemp);
      setstateDropdownValue(0);
      settrkTop100DropdownValue(0);

      setactiveData(
        congestionData.filter(
          (a) => (a.agglevel === "county") & (a.regioncode === codeTemp)
        )
      );
      //console.log("activeData County Change");
      //console.log(activeData);

      setactiveGeomCode(codeTemp);

      let nameTemp = corridors_LIST
        .filter((a) => a.id === codeTemp)
        .map((a) => a.name)[0];
      setactiveGeomName(nameTemp);
      //setactiveGeom(countyGeom.filter((a) => a.properties.cnty_nbr === codeTemp));
      if (codeTemp === 1) {
        setactiveGeom(interstates_loopsGeom); //
      } else {
        if (codeTemp === 2) {
          setactiveGeom(portToPlainsGeom);
        } else {
          if (codeTemp === 3) {
            setactiveGeom(
              interstates_loopsGeom.filter(
                (a) => a.properties.ria_rte_id === "IH0010-KG"
              )
            );
          } else {
            if (codeTemp === 4) {
              setactiveGeom(
                interstates_loopsGeom.filter(
                  (a) =>
                    a.properties.ria_rte_id === "IH0035-KG" ||
                    a.properties.ria_rte_id === "IH0035E-KG" ||
                    a.properties.ria_rte_id === "IH0035W-KG"
                )
              );
            } else {
              if (codeTemp === 5) {
                setactiveGeom(
                  interstates_loopsGeom.filter(
                    (a) => a.properties.ria_rte_id === "IH0045-KG"
                  )
                );
              } else {
                if (codeTemp === 6) {
                  setactiveGeom(
                    interstates_loopsGeom.filter(
                      (a) => a.properties.ria_rte_id === "IH0020-KG"
                    )
                  );
                } else {
                  setactiveGeom(
                    interstates_loopsGeom.filter(
                      (a) => a.properties.ria_rte_id === "IH0030-KG"
                    )
                  );
                }
              }
            }
          }
        }
      }
      setallbboxBuffer(1.5);
      setmapColor("blue");
      setLineWeight(0.75);

      if (nameTemp.length < 47) {
        setdescTextFontSizePrint("20px");
      } else {
        if (nameTemp.length < 94) {
          setdescTextFontSizePrint("19px");
        } else {
          setareaHeaderFontSizePrint("16px");
        }
      }

      updateDashboard(
        congestionData.filter(
          (a) => (a.agglevel === "corridor") & (a.regioncode === codeTemp)
        ),
        codeTemp
      );
    }
  }

  function handlestateChange(event) {
    setbottomBlockMarginTopPrint("25px");
    setareaHeaderFontSizePrint("25px");
    //setdescTextFontSizePrint("20px");

    let codeTemp = 1;
    if (codeTemp) {
      setdistrictDropdownValue(0);
      setcountyDropdownValue(0);
      setregionDropdownValue(0);
      setmpoDropdownValue(0);
      setcorridorDropdownValue(0);
      setstateDropdownValue(codeTemp);
      settrkTop100DropdownValue(0);

      setactiveData(
        congestionData.filter(
          (a) => (a.agglevel === "state") & (a.regioncode === codeTemp)
        )
      );
      //console.log("activeData County Change");
      //console.log(activeData);

      setactiveGeomCode(codeTemp);

      let nameTemp = state_LIST
        .filter((a) => a.id === codeTemp)
        .map((a) => a.name)[0];
      setactiveGeomName(nameTemp);
      //setactiveGeom(countyGeom.filter((a) => a.properties.cnty_nbr === codeTemp));
      setactiveGeom(
        stateGeom.filter((a) => a.properties.regioncode === codeTemp)
      );
      setallbboxBuffer(1.025);
      setmapColor("grey");
      setLineWeight(0.5);

      if (nameTemp.length < 47) {
        setdescTextFontSizePrint("20px");
      } else {
        if (nameTemp.length < 94) {
          setdescTextFontSizePrint("19px");
        } else {
          setdescTextFontSizePrint("16px");
        }
      }

      updateDashboard(
        congestionData.filter(
          (a) => (a.agglevel === "state") & (a.regioncode === codeTemp)
        ),
        codeTemp
      );
    }
  }

  function handleTrkTop100Change(event) {
    setbottomBlockMarginTopPrint("0px");
    setareaHeaderFontSizePrint("20px");
    //setdescTextFontSizePrint("19px");
    //setdescTextFontSizePrint("16px");

    let codeTemp = Number(event.target.value);
    if (codeTemp) {
      //console.log("codeTemp");
      //console.log(codeTemp);

      setdistrictDropdownValue(0);
      setcountyDropdownValue(0);
      setregionDropdownValue(0);
      setmpoDropdownValue(codeTemp);
      setcorridorDropdownValue(0);
      setstateDropdownValue(0);
      settrkTop100DropdownValue(codeTemp);

      setactiveData(
        congestionDataTrkTop100.filter((a) => a.tx100id === codeTemp)
      );
      //console.log("activeData");
      //console.log(congestionDataTrkTop100.filter((a) => a.tx100id === codeTemp));

      setactiveGeomCode(codeTemp);

      let nameTemp = truckRanking_LIST
        .filter((a) => a.id === codeTemp)
        .map((a) => a.name)[0];
      setactiveGeomName(nameTemp);
      //setactiveGeom(mpoGeom.filter((a) => a.properties.MPO_NBR === codeTemp));
      setactiveGeom(
        tx100_trktop100Geom.filter((a) => a.properties.top100id == codeTemp)
      );
      setallbboxBuffer(0);
      setmapColor("blue");
      setLineWeight(2.0);

      if (nameTemp.length < 47) {
        setdescTextFontSizePrint("20px");
      } else {
        if (nameTemp.length < 94) {
          setdescTextFontSizePrint("19px");
        } else {
          setareaHeaderFontSizePrint("16px");
        }
      }

      updateDashboardTrkTop100(
        congestionDataTrkTop100.filter((a) => a.tx100id === codeTemp),
        codeTemp
      );
    }
  }

  function updateDashboardTrkTop100(activeData, regioncode) {
    setyearsList(activeData.map((a) => a.year));

    setyearsList(activeData.map((a) => a.year));

    settruckData(fetchTruckDataTop100(activeData, regioncode, currYear));

    setallVehData(fetchAllVehDataTop100(activeData, regioncode, currYear));

    settruckDataYrDiff(
      fetchTruckDataYrDiffTop100(activeData, regioncode, currYear)
    );

    settruckDelayData(activeData.map((a) => a.tdly));

    settruckDelayChart({
      data: {
        labels: yearsList,
        series: [activeData.map((a) => a.tdly)],
      },
      options: {
        //lineSmooth: Chartist.Interpolation.cardinal({
        //  tension: 0
        //}),
        low: Math.min.apply(null, activeData.map((a) => a.tdly)) * 0.95,
        high: Math.max.apply(null, activeData.map((a) => a.tdly)) * 1.05, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        classNames: {
          //line: 'ct-line-custom',
          //series: 'ct-series-custom',
          line: "ct-line",
          series: "ct-series",
          grid: "ct-grid-custom",
        },
        axisY: {
          // On the y-axis start means left and end means right
          //position: 'end',
          labelInterpolationFnc: function(value) {
            var temp = Number(value).toLocaleString();
            return temp;
          },
        },
        //plugins: [
        //Chartist.plugins.tooltip()
        //],
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 25,
        },
      },

      // for animation
      //animation: {
      //  draw: function (data) {
      //    if (data.type === "line" || data.type === "area") {
      //      data.element.animate({
      //        d: {
      //          begin: 600,
      //          dur: 700,
      //          from: data.path
      //            .clone()
      //            .scale(1, 0)
      //            .translate(0, data.chartRect.height())
      //            .stringify(),
      //          to: data.path.clone().stringify(),
      //          easing: Chartist.Svg.Easing.easeOutQuint
      //        }
      //      });
      //    } else if (data.type === "point") {
      //      data.element.animate({
      //        opacity: {
      //          begin: (data.index + 1) * delays,
      //          dur: durations,
      //          from: 0,
      //          to: 1,
      //          easing: "ease"
      //        }
      //      });
      //    }
      //  }
      //}
    });

    settruckVmtData(activeData.map((a) => a.trkvmt_tx100seg));

    settruckVMTChart({
      data: {
        labels: yearsList,
        series: [activeData.map((a) => a.trkvmt_tx100seg)],
      },
      options: {
        //lineSmooth: Chartist.Interpolation.cardinal({
        //  tension: 0
        //}),
        low:
          Math.min.apply(null, activeData.map((a) => a.trkvmt_tx100seg)) * 0.95,
        high:
          Math.max.apply(null, activeData.map((a) => a.trkvmt_tx100seg)) * 1.05, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        classNames: {
          line: "ct-line",
          series: "ct-series",
          grid: "ct-grid-custom",
        },
        axisY: {
          // On the y-axis start means left and end means right
          //position: 'end',
          labelInterpolationFnc: function(value) {
            var temp = Number(value).toLocaleString();
            return temp;
          },
        },
        //plugins: [
        //Chartist.plugins.tooltip()
        //],
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 25,
        },
      },

      // for animation
      //animation: {
      //  draw: function (data) {
      //    if (data.type === "line" || data.type === "area") {
      //      data.element.animate({
      //        d: {
      //          begin: 600,
      //          dur: 700,
      //          from: data.path
      //            .clone()
      //            .scale(1, 0)
      //            .translate(0, data.chartRect.height())
      //            .stringify(),
      //          to: data.path.clone().stringify(),
      //          easing: Chartist.Svg.Easing.easeOutQuint
      //        }
      //      });
      //    } else if (data.type === "point") {
      //      data.element.animate({
      //        opacity: {
      //          begin: (data.index + 1) * delays,
      //          dur: durations,
      //          from: 0,
      //          to: 1,
      //          easing: "ease"
      //        }
      //      });
      //    }
      //  }
      //}
    });

    settruckSPEEDData(activeData.map((a) => a.tspd));

    settruckSPEEDChart({
      data: {
        labels: yearsList,
        series: [activeData.map((a) => a.tspd)],
      },
      options: {
        //lineSmooth: Chartist.Interpolation.cardinal({
        //  tension: 0
        //}),
        low: Math.min.apply(null, activeData.map((a) => a.tspd)) * 0.95,
        high: Math.max.apply(null, activeData.map((a) => a.tspd)) * 1.05, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      // for animation
      animation: {
        draw: function(data) {
          if (data.type === "line" || data.type === "area") {
            data.element.animate({
              d: {
                begin: 600,
                dur: 700,
                from: data.path
                  .clone()
                  .scale(1, 0)
                  .translate(0, data.chartRect.height())
                  .stringify(),
                to: data.path.clone().stringify(),
                easing: Chartist.Svg.Easing.easeOutQuint,
              },
            });
          } else if (data.type === "point") {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * delays,
                dur: durations,
                from: 0,
                to: 1,
                easing: "ease",
              },
            });
          }
        },
      },
    });

    settrucktruckTtiData(activeData.map((a) => a.trank));

    //Using TTI chart for Truck Ranking
    settruckTTIChart({
      header: "Truck Ranking",
      type: "trank",
      data: {
        labels: yearsList,
        series: [activeData.map((a) => a.trank)],
      },
      options: {
        //lineSmooth: Chartist.Interpolation.cardinal({
        //  tension: 0
        //}),
        // low: Math.min.apply(null, activeData.map((a) => a.trank)) * 0.975,
        // high: Math.max.apply(null, activeData.map((a) => a.trank)) * 1.025, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        low: 1,
        high: Math.max.apply(null, activeData.map((a) => a.trank)) * 1.025, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        classNames: {
          line: "ct-line",
          series: "ct-series",
          grid: "ct-grid-custom",
        },
        axisY: {
          // On the y-axis start means left and end means right
          //position: 'end',
          labelInterpolationFnc: function(value) {
            var temp = Number(value).toLocaleString();
            return temp;
          },
        },
        //plugins: [
        //Chartist.plugins.tooltip()
        //]
      },
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },

      // for animation
      //animation: {
      //  draw: function (data) {
      //    if (data.type === "line" || data.type === "area") {
      //      data.element.animate({
      //        d: {
      //          begin: 600,
      //          dur: 700,
      //          from: data.path
      //            .clone()
      //            .scale(1, 0)
      //            .translate(0, data.chartRect.height())
      //            .stringify(),
      //          to: data.path.clone().stringify(),
      //          easing: Chartist.Svg.Easing.easeOutQuint
      //        }
      //      });
      //    } else if (data.type === "point") {
      //      data.element.animate({
      //        opacity: {
      //          begin: (data.index + 1) * delays,
      //          dur: durations,
      //          from: 0,
      //          to: 1,
      //          easing: "ease"
      //        }
      //      });
      //    }
      //  }
      //}
    });
  }

  function setHighChartsData(type) {
    let yData = [];
    //tx100_plot_vars.forEach((item, i) => yData.push(row[item.trank]));
    // tx100_plot_vars.forEach((item, i) => yData.push(row[item[type]]));
    activeData.map((a) => yData.push(a[type]));
    console.log("yData-check", yData);
    return yData;
  }

  function updateDashboard(activeData, regioncode) {
    setyearsList(activeData.map((a) => a.year));

    settruckData(fetchTruckData(activeData, regioncode, currYear));

    setallVehData(fetchAllVehData(activeData, regioncode, currYear));

    settruckDataYrDiff(fetchTruckDataYrDiff(activeData, regioncode, currYear));

    settruckDelayData(activeData.map((a) => a.trkdelay));
    console.log("activeData-check", activeData);

    settruckDelayChart({
      data: {
        labels: yearsList,
        series: [activeData.map((a) => a.trkdelay)],
      },
      options: {
        //lineSmooth: Chartist.Interpolation.cardinal({
        //  tension: 0
        //}),
        low: Math.min.apply(null, activeData.map((a) => a.trkdelay)) * 0.95,
        high: Math.max.apply(null, activeData.map((a) => a.trkdelay)) * 1.05, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        classNames: {
          //line: 'ct-line-custom',
          //series: 'ct-series-custom',
          line: "ct-line",
          series: "ct-series",
          grid: "ct-grid-custom",
        },
        axisY: {
          // On the y-axis start means left and end means right
          //position: 'end',
          labelInterpolationFnc: function(value) {
            var temp = Number(value).toLocaleString();
            return temp;
          },
        },
        //plugins: [
        //Chartist.plugins.tooltip()
        //],
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 25,
        },
      },

      // for animation
      //animation: {
      //  draw: function (data) {
      //    if (data.type === "line" || data.type === "area") {
      //      data.element.animate({
      //        d: {
      //          begin: 600,
      //          dur: 700,
      //          from: data.path
      //            .clone()
      //            .scale(1, 0)
      //            .translate(0, data.chartRect.height())
      //            .stringify(),
      //          to: data.path.clone().stringify(),
      //          easing: Chartist.Svg.Easing.easeOutQuint
      //        }
      //      });
      //    } else if (data.type === "point") {
      //      data.element.animate({
      //        opacity: {
      //          begin: (data.index + 1) * delays,
      //          dur: durations,
      //          from: 0,
      //          to: 1,
      //          easing: "ease"
      //        }
      //      });
      //    }
      //  }
      //}
    });

    settruckVmtData(activeData.map((a) => a.trkttivmt));

    settruckVMTChart({
      data: {
        labels: yearsList,
        series: [activeData.map((a) => a.trkttivmt)],
      },
      options: {
        //lineSmooth: Chartist.Interpolation.cardinal({
        //  tension: 0
        //}),
        low: Math.min.apply(null, activeData.map((a) => a.trkttivmt)) * 0.95,
        high: Math.max.apply(null, activeData.map((a) => a.trkttivmt)) * 1.05, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        classNames: {
          line: "ct-line",
          series: "ct-series",
          grid: "ct-grid-custom",
        },
        axisY: {
          // On the y-axis start means left and end means right
          //position: 'end',
          labelInterpolationFnc: function(value) {
            var temp = Number(value).toLocaleString();
            return temp;
          },
        },
        //plugins: [
        //Chartist.plugins.tooltip()
        //],
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 25,
        },
      },

      // for animation
      //animation: {
      //  draw: function (data) {
      //    if (data.type === "line" || data.type === "area") {
      //      data.element.animate({
      //        d: {
      //          begin: 600,
      //          dur: 700,
      //          from: data.path
      //            .clone()
      //            .scale(1, 0)
      //            .translate(0, data.chartRect.height())
      //            .stringify(),
      //          to: data.path.clone().stringify(),
      //          easing: Chartist.Svg.Easing.easeOutQuint
      //        }
      //      });
      //    } else if (data.type === "point") {
      //      data.element.animate({
      //        opacity: {
      //          begin: (data.index + 1) * delays,
      //          dur: durations,
      //          from: 0,
      //          to: 1,
      //          easing: "ease"
      //        }
      //      });
      //    }
      //  }
      //}
    });

    settruckSPEEDData(activeData.map((a) => a.trkspeed));

    settruckSPEEDChart({
      data: {
        labels: yearsList,
        series: [activeData.map((a) => a.trkspeed)],
      },
      options: {
        //lineSmooth: Chartist.Interpolation.cardinal({
        //  tension: 0
        //}),
        low: Math.min.apply(null, activeData.map((a) => a.trkspeed)) * 0.95,
        high: Math.max.apply(null, activeData.map((a) => a.trkspeed)) * 1.05, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      // for animation
      animation: {
        draw: function(data) {
          if (data.type === "line" || data.type === "area") {
            data.element.animate({
              d: {
                begin: 600,
                dur: 700,
                from: data.path
                  .clone()
                  .scale(1, 0)
                  .translate(0, data.chartRect.height())
                  .stringify(),
                to: data.path.clone().stringify(),
                easing: Chartist.Svg.Easing.easeOutQuint,
              },
            });
          } else if (data.type === "point") {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * delays,
                dur: durations,
                from: 0,
                to: 1,
                easing: "ease",
              },
            });
          }
        },
      },
    });

    settrucktruckTtiData(activeData.map((a) => a.trktti));

    settruckTTIChart({
      header: "Travel Time Index (TTI)",
      type: "TTI",
      data: {
        labels: yearsList,
        series: [activeData.map((a) => a.trktti)],
      },
      options: {
        //lineSmooth: Chartist.Interpolation.cardinal({
        //  tension: 0
        //}),
        //low: Math.min.apply(null, activeData.map((a) => a.trktti)) * 0.975,
        low: 1,
        high: Math.max.apply(null, activeData.map((a) => a.trktti)) * 1.025, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        classNames: {
          line: "ct-line",
          series: "ct-series",
          grid: "ct-grid-custom",
        },
        axisY: {
          // On the y-axis start means left and end means right
          //position: 'end',
          labelInterpolationFnc: function(value) {
            var temp = Number(value).toLocaleString();
            return temp;
          },
        },
        //plugins: [
        //Chartist.plugins.tooltip()
        //]
      },
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },

      // for animation
      //animation: {
      //  draw: function (data) {
      //    if (data.type === "line" || data.type === "area") {
      //      data.element.animate({
      //        d: {
      //          begin: 600,
      //          dur: 700,
      //          from: data.path
      //            .clone()
      //            .scale(1, 0)
      //            .translate(0, data.chartRect.height())
      //            .stringify(),
      //          to: data.path.clone().stringify(),
      //          easing: Chartist.Svg.Easing.easeOutQuint
      //        }
      //      });
      //    } else if (data.type === "point") {
      //      data.element.animate({
      //        opacity: {
      //          begin: (data.index + 1) * delays,
      //          dur: durations,
      //          from: 0,
      //          to: 1,
      //          easing: "ease"
      //        }
      //      });
      //    }
      //  }
      //}
    });
  }

  const [districtDropdownValue, setdistrictDropdownValue] = useState(0);
  const [countyDropdownValue, setcountyDropdownValue] = useState(0);
  const [regionDropdownValue, setregionDropdownValue] = useState(0);
  const [mpoDropdownValue, setmpoDropdownValue] = useState(0);
  const [corridorDropdownValue, setcorridorDropdownValue] = useState(0);
  const [stateDropdownValue, setstateDropdownValue] = useState(activeGeomCode);
  const [trkTop100DropdownValue, settrkTop100DropdownValue] = useState(0);

  const [mapColor, setmapColor] = useState("grey");
  const [lineWeight, setLineWeight] = useState(0.5);

  function setyrDiffPC(type) {
    if (type === "TTI") {
      return truckDataYrDiff
        .filter((a) => a.metric === "TTI")
        .map((a) => a.pcDiffValue)[0];
    } else {
      return truckDataYrDiff
        .filter((a) => a.metric === "Truck Top 100 Ranking")
        .map((a) => a.pcDiffValue)[0];
    }
  }

  function setyrDiff(type) {
    if (type === "TTI") {
      return (
        truckDataYrDiff
          .filter((a) => a.metric === "TTI")
          .map((a) => a.diff)[0] * 100
      ).toFixed(0);
    } else {
      return Math.round(
        truckDataYrDiff
          .filter((a) => a.metric === "Truck Top 100 Ranking")
          .map((a) => a.diff)[0]
      );
    }
  }

  function setyrDiffCurrValue(type) {
    if (type === "TTI") {
      return truckDataYrDiff
        .filter((a) => a.metric === "TTI")
        .map((a) => a.currValue)[0];
    } else {
      return Math.round(
        truckDataYrDiff
          .filter((a) => a.metric === "Truck Top 100 Ranking")
          .map((a) => a.currValue)[0]
      );
    }
  }

  function setyrDiffPriorValue(type) {
    if (type === "TTI") {
      return truckDataYrDiff
        .filter((a) => a.metric === "TTI")
        .map((a) => a.priorValue)[0];
    } else {
      return Math.round(
        truckDataYrDiff
          .filter((a) => a.metric === "Truck Top 100 Ranking")
          .map((a) => a.priorValue)[0]
      );
    }
  }

  //window.addEventListener("resize", console.log("This is a test"));
  //function resizeMapFunction() {
  //  //if (window.innerWidth >= 960) {
  //  //  setMobileOpen(false);
  //  //}
  //
  //  console.log("This is a test");
  //};

  const [anchorElDistrict, setAnchorElDistrict] = React.useState(null);
  const handleDistrictClick = (event) => {
    setAnchorElDistrict(event.currentTarget);
  };
  const handleDistrictClose = (event) => {
    handleDistrictChange(event);
    setAnchorElDistrict(null);
  };

  const [anchorElCounty, setAnchorElCounty] = React.useState(null);
  const handleCountyClick = (event) => {
    setAnchorElCounty(event.currentTarget);
  };
  const handleCountyClose = (event) => {
    handleCountyChange(event);
    setAnchorElCounty(null);
  };

  const [anchorElMPO, setAnchorElMPO] = React.useState(null);
  const handleMPOClick = (event) => {
    setAnchorElMPO(event.currentTarget);
  };
  const handleMPOClose = (event) => {
    handleMpoChange(event);
    setAnchorElMPO(null);
  };

  const [anchorElRegion, setAnchorElRegion] = React.useState(null);
  const handleRegionClick = (event) => {
    setAnchorElRegion(event.currentTarget);
  };
  const handleRegionClose = (event) => {
    handleRegionChange(event);
    setAnchorElRegion(null);
  };

  const [anchorElCorridor, setAnchorElCorridor] = React.useState(null);
  const handleCorridorClick = (event) => {
    setAnchorElCorridor(event.currentTarget);
  };
  const handleCorridorClose = (event) => {
    handleCorridorChange(event);
    setAnchorElCorridor(null);
  };

  const [anchorElTrkTop100, setAnchorElTrkTop100] = React.useState(null);
  const handleTrkTop100Click = (event) => {
    setAnchorElTrkTop100(event.currentTarget);
  };
  const handleTrkTop100Close = (event) => {
    handleTrkTop100Change(event);
    setAnchorElTrkTop100(null);
  };

  const [anchorElOtherLinks, setAnchorElOtherLinks] = React.useState(null);
  const handleOtherLinksClick = (event) => {
    setAnchorElOtherLinks(event.currentTarget);
  };
  const handleOtherLinksClose = (event) => {
    //handleOtherLinksChange(event);
    setAnchorElOtherLinks(null);
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      //backgroundColor: '#f5f5f9',
      backgroundColor: "#708090",
      //color: 'rgba(0, 0, 0, 0.87)',
      color: "rgba(255, 255, 255, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const [TX100chartOptions, setTX100ChartOptions] = useState({
    title: {
      text: "",
    },
    chart: {
      type: "line",
      // margin: [40, 0, 40, 40],
      marginBottom: 40,
    },
    // legend: {
    //   align: "right",
    //   verticalAlign: "top",
    //   layout: "vertical",
    //   x: 15,
    //   y: 30,
    // },
    xAxis: {
      categories: [2017, 2018, 2019, 2020, 2021, 2022],
      title: {
        text: "Year",
      },
    },
    yAxis: {
      title: false,
      // title: { text: "Ranking" },
      // reversed: true,
    },
    credits: {
      enabled: false,
    },
  });

  return (
    <div>
      <div style={{ textAlign: "center", marginBottom: "5px" }}>
        <GridContainer
          style={{
            borderBottom: "0.11em solid",
            background: "rgb(132, 157, 170)",
            textAlign: "left",
          }}
        >
          <GridItem
            xs={8}
            sm={8}
            md={8}
            lg={8}
            style={{ justifyContent: "left" }}
          >
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handlestateChange}
              style={{ margin: "1.0em", marginLeft: "0vw" }}
            >
              State
            </Button>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleDistrictClick}
              style={{ margin: "1.0em", marginLeft: "0.25em" }}
            >
              TxDOT District <ExpandMoreIcon fontSize="medium" />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElDistrict}
              keepMounted
              open={Boolean(anchorElDistrict)}
              onClose={handleDistrictClose}
            >
              {TXDOTdist_LIST.sort((a, b) => a.name.localeCompare(b.name)).map(
                (region) => (
                  //<option value={region.id}>{region.name}</option>
                  <MenuItem value={region.id} onClick={handleDistrictClose}>
                    {region.name}
                  </MenuItem>
                )
              )}
            </Menu>

            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleCountyClick}
              style={{ margin: "1.0em", marginLeft: "0.25em" }}
            >
              County <ExpandMoreIcon fontSize="medium" />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElCounty}
              keepMounted
              open={Boolean(anchorElCounty)}
              onClose={handleCountyClose}
            >
              {county_LIST
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((region) => (
                  <MenuItem value={region.id} onClick={handleCountyClose}>
                    {region.name}
                  </MenuItem>
                ))}
            </Menu>

            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleMPOClick}
              style={{ margin: "1.0em", marginLeft: "0.25em" }}
            >
              MPO <ExpandMoreIcon fontSize="medium" />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElMPO}
              keepMounted
              open={Boolean(anchorElMPO)}
              onClose={handleMPOClose}
            >
              {MPO_LIST.sort((a, b) => a.name.localeCompare(b.name)).map(
                (region) => (
                  <MenuItem value={region.id} onClick={handleMPOClose}>
                    {region.name}
                  </MenuItem>
                )
              )}
            </Menu>

            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleRegionClick}
              style={{ margin: "1.0em", marginLeft: "0.25em" }}
            >
              Region <ExpandMoreIcon fontSize="medium" />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElRegion}
              keepMounted
              open={Boolean(anchorElRegion)}
              onClose={handleRegionClose}
            >
              {regions_LIST
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((region) => (
                  <MenuItem value={region.id} onClick={handleRegionClose}>
                    {region.name}
                  </MenuItem>
                ))}
            </Menu>

            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleCorridorClick}
              style={{ margin: "1.0em", marginLeft: "0.25em" }}
            >
              Corridor <ExpandMoreIcon fontSize="medium" />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElCorridor}
              keepMounted
              open={Boolean(anchorElCorridor)}
              onClose={handleCorridorClose}
            >
              {corridors_LIST
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((region) => (
                  <MenuItem value={region.id} onClick={handleCorridorClose}>
                    {region.name}
                  </MenuItem>
                ))}
            </Menu>

            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleTrkTop100Click}
              style={{ margin: "1.0em", marginLeft: "0.25em" }}
            >
              <span>
                Truck Top 100 Congested <br /> Road Segments (Texas 100)
              </span>{" "}
              <ExpandMoreIcon fontSize="medium" />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElTrkTop100}
              keepMounted
              open={Boolean(anchorElTrkTop100)}
              onClose={handleTrkTop100Close}
            >
              {truckRanking_LIST.map((region) => (
                <MenuItem value={region.id} onClick={handleTrkTop100Close}>
                  {region.name}
                </MenuItem>
              ))}
            </Menu>
          </GridItem>
          {/* 
          <GridItem xs={10} sm={10} md={10} lg={10}>
            <FormControl className={classes2.formControl}>
              <InputLabel htmlFor="age-native-simple" style={{ background: "white" }}>State</InputLabel>
              <Select
                style={{ paddingTop: '15px', textOverflow: "ellipsis", overflow: "hidden" }}
                //native
                value={stateDropdownValue}
                onChange={handlestateChange}
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple',
                }}
              >
                <option aria-label="None" value="" />
                {state_LIST.map(region => (
                  <option value={region.id}>{region.name}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes2.formControl} >
              <InputLabel htmlFor="age-native-simple">TxDOT District</InputLabel>
              <Select
                style={{ paddingTop: '15px', textOverflow: "ellipsis", overflow: "hidden" }}
                //native
                value={districtDropdownValue}
                onChange={handleDistrictChange}
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple',
                }}
              >
                <option aria-label="None" value="" />
                {TXDOTdist_LIST.map(region => (
                  <option value={region.id}>{region.name}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes2.formControl} >
              <InputLabel htmlFor="age-native-simple">County:</InputLabel>
              <Select
                style={{ paddingTop: '15px', textOverflow: "ellipsis", overflow: "hidden" }}
                //native
                value={countyDropdownValue}
                onChange={handleCountyChange}
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple2',
                }}
              >
                <option aria-label="None" value="" />
                {county_LIST.map(region => (
                  <option value={region.id}>{region.name}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes2.formControl} >
              <InputLabel htmlFor="age-native-simple">MPO:</InputLabel>
              <Select
                style={{ paddingTop: '15px', textOverflow: "ellipsis", overflow: "hidden" }}
                //native
                value={mpoDropdownValue}
                onChange={handleMpoChange}  //handleCountyChange
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple2',
                }}
              >
                <option aria-label="None" value="" />
                {MPO_LIST.map(region => (
                  <option value={region.id}>{region.name}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes2.formControl} >
              <InputLabel htmlFor="age-native-simple">Region:</InputLabel>
              <Select
                style={{ paddingTop: '15px', textOverflow: "ellipsis", overflow: "hidden" }}
                //native
                value={regionDropdownValue}
                onChange={handleRegionChange}  //handleCountyChange
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple2',
                }}
              >
                <option aria-label="None" value="" />
                {regions_LIST.map(region => (
                  <option value={region.id}>{region.name}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes2.formControl}>
              <InputLabel htmlFor="age-native-simple">Corridor:</InputLabel>
              <Select
                style={{ paddingTop: '15px', textOverflow: "ellipsis", overflow: "hidden" }}
                //native
                value={corridorDropdownValue}
                onChange={handleCorridorChange}  //handleCountyChange
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple2',
                }}
              >
                <option aria-label="None" value="" />
                {corridors_LIST.map(region => (
                  <option value={region.id}>{region.name}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes2.formControl} >
              <InputLabel htmlFor="age-native-simple">Truck Top 100 Road Segments (Texas 100):</InputLabel>
              <Select
                style={{ paddingTop: '15px', textOverflow: "ellipsis", overflow: "hidden" }}
                //native
                value={trkTop100DropdownValue}
                onChange={handleTrkTop100Change}  //handleCountyChange
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple2',
                }}
              >
                <option aria-label="None" value="" />
                {truckRanking_LIST.map(region => (
                  <option value={region.id}>{region.name}</option>
                ))}
              </Select>
            </FormControl>
          </GridItem>

          {/*
        <button onClick={printReportCard}>Print</button>
        */}

          <GridItem xs={1} sm={1} md={1} lg={1}>
            <p style={{ visibility: "hidden" }}> Placeholder</p>
          </GridItem>
          <GridItem xs={1} sm={1} md={1} lg={1}>
            <DownloadModal
              truckData={truckData}
              allVehData={allVehData}
              activeGeomName={activeGeomName}
            />
          </GridItem>
          <GridItem xs={1} sm={1} md={1} lg={1}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOtherLinksClick}
              style={{ margin: "1.0em", marginLeft: "0.25em" }}
            >
              Other Links
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElOtherLinks}
              keepMounted
              open={Boolean(anchorElOtherLinks)}
              onClose={handleOtherLinksClose}
            >
              <MenuItem value="TCAT" onClick={handleOtherLinksClose}>
                <a
                  style={{ color: "black" }}
                  href={"https://tcatwebprod.z14.web.core.windows.net/"}
                  target="_blank"
                >
                  Truck Conestion Analysis Tool (TCAT)
                </a>
              </MenuItem>
              <MenuItem
                value="tx100ExecSummary"
                onClick={handleOtherLinksClose}
              >
                <a
                  style={{ color: "black" }}
                  href={
                    "https://static.tti.tamu.edu/tti.tamu.edu/documents/TTI-2020-9.pdf"
                  }
                  target="_blank"
                >
                  Texas 100 Executive Summary
                </a>
              </MenuItem>
              <MenuItem value="tx100Method" onClick={handleOtherLinksClose}>
                <a
                  style={{ color: "black" }}
                  href={
                    "https://static.tti.tamu.edu/tti.tamu.edu/documents/TTI-2020-8.pdf"
                  }
                  target="_blank"
                >
                  Texas 100 Methodology
                </a>
              </MenuItem>
            </Menu>
          </GridItem>
          <GridItem xs={1} sm={1} md={1} lg={1}>
            <ContactUsModal />
          </GridItem>
        </GridContainer>
      </div>

      <div id="section-to-print" style={{ margin: "5px" }}>
        {/*
        <GridContainer>
          <GridItem xs={12}>"
            <h2 id="headertest" style={{ marginTop: '0', marginRight: '0', paddingTop: '0' }}><center>
              2020 Texas Truck Congestion Report Card
            </center></h2>
          </GridItem>
        </GridContainer>
        */}
        <GridContainer>
          <GridItem xs={1}>
            <a href="https://www.txdot.gov/" target="_blank">
              <img style={{ width: "10vw", height: "10vh" }} src={TxDOTlogo} />
            </a>
          </GridItem>
          <GridItem xs={10}>
            "
            <h2
              id="headertest"
              style={{ marginTop: "0", marginRight: "0", paddingTop: "0" }}
            >
              <center>2022 Texas Truck Congestion Report Card</center>
            </h2>
          </GridItem>
          <GridItem xs={1} style={{ justifyContent: "right" }}>
            <a href="https://tti.tamu.edu/" target="_blank">
              <img
                style={{
                  width: "11vw",
                  height: "8.5vh",
                  marginTop: "1.6vh",
                  float: "right",
                }}
                src={TTIlogo}
              />
            </a>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={4} sm={4} md={4} lg={4}>
            <div id="testtrend1">
              <Card>
                {/*
                <CardHeader color="warning" stats icon>

                  <CardIcon color="info">
                    <div >    
                       
                      <ChartistGraph
                        className="ct-chart"
                        data={truckDelayChart.data}
                        type="Line"
                        options={truckDelayChart.options}
                      //listener={truckDelayChart.animation}                
                      />
                       
                    </div>
                  </CardIcon>
                </CardHeader>
                */}
                <CardBody style={{ paddingBottom: "0" }}>
                  <div id="delayboxes" style={{ paddingBottom: "0" }}>
                    <GridContainer
                      spacing={10}
                      direction="row"
                      alignItems="center"
                      justify="center"
                      margin={5}
                    >
                      <GridItem xs={6}>
                        <h3 style={{ fontWeight: 400 }}>
                          Annual Person-Hours of Truck Delay
                          <HtmlTooltip
                            /*title={
                               <React.Fragment>
                                 <Typography color="inherit">Tooltip with HTML</Typography>
                                 <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                                 {"It's very engaging. Right?"}
                               </React.Fragment>
                             }*/
                            title={
                              <React.Fragment>
                                <Typography color="inherit">
                                  Annual Person-Hours of Truck Delay
                                </Typography>
                                This measure combines elements of the TTI ( or
                                TCI, intensity of congestion on any section of
                                road) with a magnitude element (the amount of
                                people suffering that congestion). This
                                combination will prioritize highly traveled
                                sections above those that are less heavily
                                traveled. Truck vehicle hours of delay can be
                                calculated by dividing truck person-hours of
                                delay by 1.14 (see the documentation under the
                                "Other Links" tab for more information).
                              </React.Fragment>
                            }
                          >
                            <sup>
                              <HelpOutlineIcon
                                style={{ fontSize: 16, marginLeft: "0.25em" }}
                              />
                            </sup>
                          </HtmlTooltip>
                        </h3>
                        <h3>
                          <SetPcDiffYrText
                            yrDiffPC={
                              truckDataYrDiff
                                .filter(
                                  (a) =>
                                    a.metric === "Annual Person-Hours of Delay"
                                )
                                .map((a) => a.pcDiffValue)[0]
                            }
                            yrDiff={
                              truckDataYrDiff
                                .filter(
                                  (a) =>
                                    a.metric === "Annual Person-Hours of Delay"
                                )
                                .map((a) => a.diff)[0]
                            }
                          />
                        </h3>
                      </GridItem>

                      <GridItem xs={6}>
                        <div id="trendplot1">
                          {/* <PlotlyTrendPlot
                            yearsList={yearsList}
                            trendData={truckDelayData}
                          /> */}
                          <HighchartsReact
                            containerProps={{
                              style: {
                                height: "25vh",
                                width: "100%",
                                // justigy: "center",
                                // marginTop: 10,
                              },
                            }}
                            highcharts={Highcharts}
                            options={Object.assign({}, TX100chartOptions, {
                              series: [
                                {
                                  name: "Truck ranking",
                                  data: setHighChartsData("trkdelay"),
                                  color: "rgb(80,0,0)",
                                  showInLegend: false,
                                },
                                // {
                                //   name: "Overall ranking",
                                //   data: setTx100PlotData("arank", row),
                                //   color: "rgb(112,115,115)",
                                // },
                              ],
                              // title: { text: data.road },
                              // subtitle: {
                              //   text: data.frm_dfo + " to " + data.to_dfo,
                              // },
                            })}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>

                <CardFooter stats>
                  <div style={{ fontSize: "1.125rem", width: "50%" }}>
                    <a>
                      {currYear} -{" "}
                      {
                        truckDataYrDiff
                          .filter(
                            (a) => a.metric === "Annual Person-Hours of Delay"
                          )
                          .map((a) => a.currValue)[0]
                      }
                    </a>
                  </div>

                  <div
                    style={{
                      fontSize: "1.125rem",
                      width: "50%",
                      textAlign: "right",
                    }}
                  >
                    <a>
                      {currYear - 1} -{" "}
                      {truckDataYrDiff
                        .filter(
                          (a) => a.metric === "Annual Person-Hours of Delay"
                        )
                        .map((a) => a.priorValue)}
                    </a>
                  </div>
                </CardFooter>
              </Card>
            </div>

            <div id="testtrend2">
              <Card>
                {/*
                <CardHeader color="warning" stats icon>
                  <CardIcon color="info">
                    
                    <ChartistGraph
                      className="ct-chart"
                      data={truckSPEEDChart.data}
                      type="Line"
                      options={truckSPEEDChart.options}
                    //listener={truckSPEEDChart.animation}
                    />
                     

                  </CardIcon>                  
                </CardHeader>
                */}
                <CardBody style={{ paddingBottom: "0" }}>
                  <div id="speedboxes" style={{ paddingBottom: "0" }}>
                    <GridContainer
                      spacing={10}
                      direction="row"
                      alignItems="center"
                      justify="center"
                      margin={5}
                    >
                      <GridItem xs={6}>
                        <h3 style={{ fontWeight: 400 }}>
                          Average Speed (mph)
                          <HtmlTooltip
                            /*title={
                               <React.Fragment>
                                 <Typography color="inherit">Tooltip with HTML</Typography>
                                 <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                                 {"It's very engaging. Right?"}
                               </React.Fragment>
                             }*/
                            title={
                              <React.Fragment>
                                <Typography color="inherit">
                                  Average Speed (mph)
                                </Typography>
                                The average speed is a VMT weighted average
                                speed. Eeach roadway segment speed is multiplied
                                by the truck VMT for the segment. The total of
                                that multiplication is divided by the sum of the
                                VMT for the specified region/location.
                              </React.Fragment>
                            }
                          >
                            <sup>
                              <HelpOutlineIcon
                                style={{ fontSize: 16, marginLeft: "0.25em" }}
                              />
                            </sup>
                          </HtmlTooltip>
                        </h3>
                        <h3>
                          <SetPcDiffYrTextSpeed
                            yrDiffPC={
                              truckDataYrDiff
                                .filter((a) => a.metric === "Speed (mph)")
                                .map((a) => a.pcDiffValue)[0]
                            }
                            yrDiff={
                              truckDataYrDiff
                                .filter((a) => a.metric === "Speed (mph)")
                                .map((a) => a.diff)[0]
                            }
                          />
                        </h3>
                      </GridItem>

                      <GridItem xs={6}>
                        <div id="trendplot2">
                          {/* <PlotlyTrendPlot
                            yearsList={yearsList}
                            trendData={truckSPEEDData}
                          /> */}
                          <HighchartsReact
                            containerProps={{
                              style: {
                                height: "25vh",
                                width: "100%",
                                // justigy: "center",
                                // marginTop: 10,
                              },
                            }}
                            highcharts={Highcharts}
                            options={Object.assign({}, TX100chartOptions, {
                              series: [
                                {
                                  name: "Truck ranking",
                                  data: setHighChartsData("trkspeed"),
                                  color: "rgb(80,0,0)",
                                  showInLegend: false,
                                },
                                // {
                                //   name: "Overall ranking",
                                //   data: setTx100PlotData("arank", row),
                                //   color: "rgb(112,115,115)",
                                // },
                              ],
                              // title: { text: data.road },
                              // subtitle: {
                              //   text: data.frm_dfo + " to " + data.to_dfo,
                              // },
                            })}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>
                <CardFooter stats>
                  <div style={{ fontSize: "1.125rem", width: "50%" }}>
                    <a>
                      {currYear} -{" "}
                      {
                        truckDataYrDiff
                          .filter((a) => a.metric === "Speed (mph)")
                          .map((a) => a.currValue)[0]
                      }
                    </a>
                  </div>

                  <div
                    style={{
                      fontSize: "1.125rem",
                      width: "50%",
                      textAlign: "right",
                    }}
                  >
                    <a>
                      {currYear - 1} -{" "}
                      {truckDataYrDiff
                        .filter((a) => a.metric === "Speed (mph)")
                        .map((a) => a.priorValue)}
                    </a>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </GridItem>

          <GridItem xs={4} sm={4} md={4} lg={4}>
            <div id="maptest2">
              <Card>
                <CardHeader color="primary">
                  {" "}
                  {/*color="primary">*/}
                  <h3 className={classes.cardTitleWhite}>
                    <center>{activeGeomName}</center>
                  </h3>
                </CardHeader>
                <CardBody>
                  {/* <div style={styles2.map_div} > */}
                  <div id="maptest">
                    <Map />
                  </div>
                  {/* </div> */}
                </CardBody>
              </Card>

              <Card>
                <CardBody style={{ fontSize: "0.925rem" }}>
                  {/* 
                <p>
                  <strong>PTI</strong> - Planning Time Index
              </p>
                <p>
                  <strong>TTI</strong> - Travel Time Index
              </p>
                <p>
                  <strong>VMT</strong> - Vehicle Miles of Travel
              </p>
                <p>
                  <strong>GHG</strong> - Greenhouse Gas
              </p>
              */}
                  <p
                    style={{
                      fontSize: "1.25vw",
                      fontFamily: "Franklin Gothic Book",
                    }}
                  >
                    The years shown here represent reporting years and reflect
                    congestion from the year prior (i.e., 2021 is 2020 levels
                    during the COVID-19 pandemic data). NOTE: 2021 (2020 data)
                    was highly impacted by the COVID-19 pandemic and is not
                    reflective of a typical year. Changes in congestion are
                    primarliy due to two key parameters: speeds and volumes.
                    Slight changes in speed would indicate volume is driving the
                    change in congestion. Conversely, a slight change in volumes
                    would indicate speed is driving the change in congestion.
                    VMT was used to represent the traffic volumes to capture any
                    changes in the roadway segmentation.
                  </p>

                  {/*
                  <img style={{ width: "7.5vw", height: "7.5vh", paddingTop: "0.05vh" }} src={TxDOTlogo} />
                  
                  <img style={{ width: "10vw", height: "7.5vh", float: "right", paddingTop: "0.05vh" }} src={TTIlogo} />
                  */}
                </CardBody>
              </Card>
            </div>
          </GridItem>

          <GridItem xs={4} sm={4} md={4} lg={4}>
            <div id="testtrend3">
              <Card>
                {/*
                <CardHeader color="warning" stats icon>
                  <CardIcon color="info">
                    <div >    
                       <ChartistGraph
                      className="ct-chart"
                      data={truckTTIChart.data}
                      type="Line"
                      options={truckTTIChart.options}
                    //listener={truckTTIChart.animation}
                    />
                    
                    </div>
                  </CardIcon>              
                </CardHeader>
                */}
                <CardBody style={{ paddingBottom: "0" }}>
                  <div id="ttiboxes" style={{ paddingBottom: "0" }}>
                    <GridContainer
                      spacing={10}
                      direction="row"
                      alignItems="center"
                      justify="center"
                      margin={5}
                    >
                      <GridItem xs={6}>
                        <h3 style={{ fontWeight: 400 }}>
                          {truckTTIChart.header}
                        </h3>
                        <h3>
                          <SetPcDiffYrText
                            yrDiffPC={setyrDiffPC(truckTTIChart.type)}
                            yrDiff={setyrDiff(truckTTIChart.type)}
                            skipPClabel
                            type={truckTTIChart.type}
                          />
                        </h3>
                      </GridItem>

                      <GridItem xs={6}>
                        <div id="trendplot3">
                          {/* <PlotlyTrendPlot
                            yearsList={yearsList}
                            trendData={truckTtiData}
                            minData={1}
                            maxData={Math.max.apply(null, truckTtiData) * 1.05}
                          /> */}
                          <HighchartsReact
                            containerProps={{
                              style: {
                                height: "25vh",
                                width: "100%",
                                // justigy: "center",
                                // marginTop: 10,
                              },
                            }}
                            highcharts={Highcharts}
                            options={Object.assign({}, TX100chartOptions, {
                              series: [
                                {
                                  name: "Truck ranking",
                                  data: setHighChartsData("trktti"),
                                  color: "rgb(80,0,0)",
                                  showInLegend: false,
                                },
                                // {
                                //   name: "Overall ranking",
                                //   data: setTx100PlotData("arank", row),
                                //   color: "rgb(112,115,115)",
                                // },
                              ],
                              // title: { text: data.road },
                              // subtitle: {
                              //   text: data.frm_dfo + " to " + data.to_dfo,
                              // },
                            })}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>
                <CardFooter stats>
                  <div style={{ fontSize: "1.125rem", width: "50%" }}>
                    <a>
                      {currYear} - {setyrDiffCurrValue(truckTTIChart.type)}
                    </a>
                  </div>

                  <div
                    style={{
                      fontSize: "1.125rem",
                      width: "50%",
                      textAlign: "right",
                    }}
                  >
                    <a>
                      {currYear - 1} - {setyrDiffPriorValue(truckTTIChart.type)}
                    </a>
                  </div>
                </CardFooter>
              </Card>
            </div>

            <div id="testtrend4">
              <Card>
                {/* 
                <CardHeader color="warning" stats icon>
                  <CardIcon color="info">
                    <div >
                    <ChartistGraph
                      className="ct-chart"
                      data={truckVMTChart.data}
                      type="Line"
                      options={truckVMTChart.options}
                    //listener={truckVMTChart.animation}
                    />
                    </div>
                  </CardIcon>                  
                </CardHeader>
                    */}
                <CardBody style={{ paddingBottom: "0" }}>
                  <div id="delayboxes" style={{ paddingBottom: "0" }}>
                    <GridContainer
                      spacing={10}
                      direction="row"
                      alignItems="center"
                      justify="center"
                      margin={5}
                    >
                      <GridItem xs={6}>
                        <h3 style={{ fontWeight: 400 }}>
                          Annual Vehicle Miles of Travel (VMT)
                        </h3>
                        <h3>
                          <SetPcDiffYrText
                            yrDiffPC={
                              truckDataYrDiff
                                .filter((a) => a.metric === "VMT")
                                .map((a) => a.pcDiffValue)[0]
                            }
                            yrDiff={
                              truckDataYrDiff
                                .filter((a) => a.metric === "VMT")
                                .map((a) => a.diff)[0]
                            }
                          />
                        </h3>
                      </GridItem>

                      <GridItem xs={6}>
                        <div id="trendplot4">
                          {/* <PlotlyTrendPlot
                            yearsList={yearsList}
                            trendData={truckVmtData}
                          /> */}
                          <HighchartsReact
                            containerProps={{
                              style: {
                                height: "25vh",
                                width: "100%",
                                // justigy: "center",
                                // marginTop: 10,
                              },
                            }}
                            highcharts={Highcharts}
                            options={Object.assign({}, TX100chartOptions, {
                              series: [
                                {
                                  name: "Truck ranking",
                                  data: setHighChartsData("trkttivmt"),
                                  color: "rgb(80,0,0)",
                                  showInLegend: false,
                                },
                                // {
                                //   name: "Overall ranking",
                                //   data: setTx100PlotData("arank", row),
                                //   color: "rgb(112,115,115)",
                                // },
                              ],
                              // title: { text: data.road },
                              // subtitle: {
                              //   text: data.frm_dfo + " to " + data.to_dfo,
                              // },
                            })}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>
                <CardFooter stats>
                  <div style={{ fontSize: "1.125rem", width: "50%" }}>
                    <a>
                      {currYear} -{" "}
                      {
                        truckDataYrDiff
                          .filter((a) => a.metric === "VMT")
                          .map((a) => a.currValue)[0]
                      }
                    </a>
                  </div>

                  <div
                    style={{
                      fontSize: "1.125rem",
                      width: "50%",
                      textAlign: "right",
                    }}
                  >
                    <a>
                      {currYear - 1} -{" "}
                      {truckDataYrDiff
                        .filter((a) => a.metric === "VMT")
                        .map((a) => a.priorValue)}
                    </a>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer>
          {/* 
        <GridItem xs={6} sm={12} md={6} lg={4}>
          <CustomTabs
            title="Tasks:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Bugs",
                tabIcon: BugReport,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0, 3]}
                    tasksIndexes={[0, 1, 2, 3]}
                    tasks={bugs}
                  />
                )
              },
              {
                tabName: "Website",
                tabIcon: Code,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0]}
                    tasksIndexes={[0, 1]}
                    tasks={website}
                  />
                )
              },
              {
                tabName: "Server",
                tabIcon: Cloud,
                tabContent: (
                  <Tasks
                    checkedIndexes={[1]}
                    tasksIndexes={[0, 1, 2]}
                    tasks={server}
                  />
                )
              }
            ]}
          />
        </GridItem>
        */}

          <GridItem xs={4} sm={6} md={6} lg={6}>
            <div id="tablestest">
              <Card>
                <CardHeader color="info">
                  <h3
                    className={classes.cardTitleWhite}
                    style={{ textAlign: "center" }}
                  >
                    Truck Summary
                  </h3>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="success"
                    /*
                    tableHead={["ID", "Name", "Salary", "Country"]}
                    tableData={[
                      ["1", "Dakota Rice", "$36,738", "Niger"],
                      ["2", "Minerva Hooper", "$23,789", "Curaçao"],
                      ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
                      ["4", "Philip Chaney", "$38,735", "Korea, South"]
                    ]}
                    */
                    tableHead={["Metric", "Value", "Yearly Change"]}
                    //tableData={truckData.map((row) => ([row.metric, row.value, row.diff]))}
                    tableData={truckData.map((row) => [
                      row.metric,
                      row.value,
                      //row.diff
                      <SetTableDiffYrText
                        yrDiff={row.diff}
                        trend={row.trend}
                        metric={row.metric}
                      />,
                    ])}
                  />
                </CardBody>
              </Card>
              <p style={{ fontSize: "1.125rem" }}>
                NOTE: Truck vehicle hours of delay can be calculated by dividing
                truck person-hours of delay by 1.14
              </p>
            </div>
          </GridItem>

          <GridItem xs={6} sm={6} md={6} lg={6}>
            <div id="tablestest2">
              <Card style={{ marginBottom: "0" }}>
                <CardHeader color="info">
                  <h3
                    className={classes.cardTitleWhite}
                    style={{ textAlign: "center" }}
                  >
                    All Vehicles Summary
                  </h3>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="success"
                    /*
                    tableHead={["ID", "Name", "Salary", "Country"]}
                    tableData={[
                      ["1", "Dakota Rice", "$36,738", "Niger"],
                      ["2", "Minerva Hooper", "$23,789", "Curaçao"],
                      ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
                      ["4", "Philip Chaney", "$38,735", "Korea, South"]
                    ]}
                    */
                    tableHead={["Metric", "Value", "Yearly Change"]}
                    tableData={allVehData.map((row) => [
                      row.metric,
                      row.value,
                      //row.diff
                      <SetTableDiffYrText
                        yrDiff={row.diff}
                        trend={row.trend}
                        metric={row.metric}
                      />,
                    ])}
                  />
                </CardBody>
              </Card>
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div id="footertest">
              <Card style={{ marginBottom: "0" }}>
                <CardFooter stats>
                  <div style={{ width: "25%" }}>
                    <p>
                      <strong>PTI</strong> - Planning Time Index
                    </p>
                  </div>

                  <div style={{ width: "25%", textAlign: "center" }}>
                    <p>
                      <strong>TTI</strong> - Travel Time Index
                    </p>
                  </div>

                  <div style={{ width: "25%", textAlign: "center" }}>
                    <p>
                      <strong>VMT</strong> - Vehicle Miles of Travel
                    </p>
                  </div>

                  <div style={{ width: "25%", textAlign: "right" }}>
                    <p>
                      <strong>GHG</strong> - Greenhouse Gas
                    </p>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </GridItem>
        </GridContainer>
      </div>

      <div
        id="section-to-print2"
        style={{
          width: "1600px",
          margin: "5px",
          visibility: "hidden",
          height: "0",
          marginTop: "30px",
          fontWeight: "600",
        }}
      >
        {/*
        <GridContainer>
          <GridItem xs={12}>"
            <h2 id="headertest" style={{ marginTop: '0', marginRight: '0', paddingTop: '0' }}><center>
              2019 Texas Truck Congestion Report Card
            </center></h2>
          </GridItem>
        </GridContainer>
        */}
        <GridContainer id="test-container" style={{ height: "0" }}>
          <GridItem xs={1}>
            <a href="https://www.txdot.gov/" target="_blank">
              <img style={{ width: "130px", height: "90px" }} src={TxDOTlogo} />
            </a>
          </GridItem>
          <GridItem xs={10}>
            "
            <h2
              id="headertest"
              style={{ marginTop: "0", marginRight: "0", paddingTop: "0" }}
            >
              <center>2020 Texas Truck Congestion Report Card</center>
            </h2>
          </GridItem>
          <GridItem
            xs={1}
            style={{ justifyContent: "right", textAlign: "right" }}
          >
            <a href="https://tti.tamu.edu/" target="_blank">
              <img
                style={{
                  width: "185px",
                  height: "71px",
                  marginTop: "1.6vh",
                  float: "right",
                }}
                src={TTIlogo}
              />
            </a>
          </GridItem>
        </GridContainer>

        <GridContainer id="test-container2" style={{ height: "0" }}>
          {/* <GridItem style={{ width: "550px", height: "900px", paddingTop: "0.05vh", marginRight: "0.75em" }}> */}
          <GridItem
            id="test-container2b"
            style={{
              width: "550px",
              paddingTop: "0.05vh",
              marginRight: "0.75em",
            }}
          >
            <div id="testtrend1b" style={{ height: "0px" }}>
              <Card>
                {/*
                <CardHeader color="warning" stats icon>

                  <CardIcon color="info">
                    <div >    
                       
                      <ChartistGraph
                        className="ct-chart"
                        data={truckDelayChart.data}
                        type="Line"
                        options={truckDelayChart.options}
                      //listener={truckDelayChart.animation}                
                      />
                       
                    </div>
                  </CardIcon>
                </CardHeader>
                */}
                <CardBody style={{ paddingBottom: "0" }}>
                  <div id="delayboxesb" style={{ paddingBottom: "0" }}>
                    <GridContainer
                      spacing={10}
                      direction="row"
                      alignItems="center"
                      justify="center"
                      margin={5}
                    >
                      <GridItem xs={6}>
                        <h3 style={{ fontWeight: 400 }}>
                          Annual Person-Hours of Truck Delay
                        </h3>
                        <h3>
                          <SetPcDiffYrText
                            yrDiffPC={
                              truckDataYrDiff
                                .filter(
                                  (a) =>
                                    a.metric === "Annual Person-Hours of Delay"
                                )
                                .map((a) => a.pcDiffValue)[0]
                            }
                            yrDiff={
                              truckDataYrDiff
                                .filter(
                                  (a) =>
                                    a.metric === "Annual Person-Hours of Delay"
                                )
                                .map((a) => a.diff)[0]
                            }
                          />
                        </h3>
                      </GridItem>

                      <GridItem xs={6}>
                        <div id="trendplot1b">
                          <PlotlyTrendPlot
                            yearsList={yearsList}
                            trendData={truckDelayData}
                            height={"250px"}
                            width={"245px"}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>

                <CardFooter stats>
                  <div style={{ fontSize: "1.125rem", width: "50%" }}>
                    <a>
                      {currYear} -{" "}
                      {
                        truckDataYrDiff
                          .filter(
                            (a) => a.metric === "Annual Person-Hours of Delay"
                          )
                          .map((a) => a.currValue)[0]
                      }
                    </a>
                  </div>

                  <div
                    style={{
                      fontSize: "1.125rem",
                      width: "50%",
                      textAlign: "right",
                    }}
                  >
                    <a>
                      {currYear - 1} -{" "}
                      {truckDataYrDiff
                        .filter(
                          (a) => a.metric === "Annual Person-Hours of Delay"
                        )
                        .map((a) => a.priorValue)}
                    </a>
                  </div>
                </CardFooter>
              </Card>
            </div>

            <div id="testtrend2b" style={{ height: "0px" }}>
              <Card>
                {/*
                <CardHeader color="warning" stats icon>
                  <CardIcon color="info">
                    
                    <ChartistGraph
                      className="ct-chart"
                      data={truckSPEEDChart.data}
                      type="Line"
                      options={truckSPEEDChart.options}
                    //listener={truckSPEEDChart.animation}
                    />
                     

                  </CardIcon>                  
                </CardHeader>
                */}
                <CardBody style={{ paddingBottom: "0" }}>
                  <div id="speedboxesb" style={{ paddingBottom: "0" }}>
                    <GridContainer
                      spacing={10}
                      direction="row"
                      alignItems="center"
                      justify="center"
                      margin={5}
                    >
                      <GridItem xs={6}>
                        <h3 style={{ fontWeight: 400 }}>Average Speed (mph)</h3>
                        <h3>
                          <SetPcDiffYrTextSpeed
                            yrDiffPC={
                              truckDataYrDiff
                                .filter((a) => a.metric === "Speed (mph)")
                                .map((a) => a.pcDiffValue)[0]
                            }
                            yrDiff={
                              truckDataYrDiff
                                .filter((a) => a.metric === "Speed (mph)")
                                .map((a) => a.diff)[0]
                            }
                          />
                        </h3>
                      </GridItem>

                      <GridItem xs={6}>
                        <div id="trendplot2b">
                          <PlotlyTrendPlot
                            yearsList={yearsList}
                            trendData={truckSPEEDData}
                            height={"250px"}
                            width={"245px"}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>
                <CardFooter stats>
                  <div style={{ fontSize: "1.125rem", width: "50%" }}>
                    <a>
                      {currYear} -{" "}
                      {
                        truckDataYrDiff
                          .filter((a) => a.metric === "Speed (mph)")
                          .map((a) => a.currValue)[0]
                      }
                    </a>
                  </div>

                  <div
                    style={{
                      fontSize: "1.125rem",
                      width: "50%",
                      textAlign: "right",
                    }}
                  >
                    <a>
                      {currYear - 1} -{" "}
                      {truckDataYrDiff
                        .filter((a) => a.metric === "Speed (mph)")
                        .map((a) => a.priorValue)}
                    </a>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </GridItem>

          {/*<GridItem style={{ width: "500px", height: "900px", paddingTop: "0.05vh", marginRight: "0.75em" }}>*/}
          <GridItem
            style={{
              width: "500px",
              paddingTop: "0.05vh",
              marginRight: "0.75em",
            }}
          >
            <div id="maptest2b">
              <Card id="maptest2bCard1" style={{ height: "0px" }}>
                <CardHeader color="primary">
                  {" "}
                  {/*color="primary">*/}
                  <h3
                    className={classes.cardTitleWhite}
                    style={{ fontSize: areaHeaderFontSizePrint }}
                  >
                    <center>{activeGeomName}</center>
                  </h3>
                </CardHeader>
                <CardBody>
                  {/* <div style={styles2.map_div} > */}
                  <div id="maptestb">
                    <Map />
                  </div>
                  {/* </div> */}
                </CardBody>
              </Card>

              <Card id="maptest2bCard2" style={{ height: "0px" }}>
                <CardBody style={{ fontSize: "0.925rem" }}>
                  {/* 
                <p>
                  <strong>PTI</strong> - Planning Time Index
              </p>
                <p>
                  <strong>TTI</strong> - Travel Time Index
              </p>
                <p>
                  <strong>VMT</strong> - Vehicle Miles of Travel
              </p>
                <p>
                  <strong>GHG</strong> - Greenhouse Gas
              </p>
              */}
                  <p
                    style={{
                      fontSize: descTextFontSizePrint,
                      fontFamily: "Franklin Gothic Book",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    The years shown here represent reporting years and reflect
                    congestion from the year prior (i.e., 2020 is 2019 pre
                    COVID-19 pandemic data). Changes in congestion are primarliy
                    due to two key parameters: speeds and volumes. Slight
                    changes in speed would indicate volume is driving the change
                    in congestion. Conversely, a slight change in volumes would
                    indicate speed is driving the change in congestion. VMT was
                    used to represent the traffic volumes to capture any changes
                    in the roadway segmentation.
                  </p>

                  {/*
                  <img style={{ width: "7.5vw", height: "7.5vh", paddingTop: "0.05vh" }} src={TxDOTlogo} />
                  
                  <img style={{ width: "10vw", height: "7.5vh", float: "right", paddingTop: "0.05vh" }} src={TTIlogo} />
                  */}
                </CardBody>
              </Card>
            </div>
          </GridItem>

          <GridItem style={{ width: "550px", paddingTop: "0.05vh" }}>
            {/*<GridItem style={{ width: "550px", height: "900px", paddingTop: "0.05vh" }}>*/}
            <div id="testtrend3b" style={{ height: "0px" }}>
              <Card>
                {/*
                <CardHeader color="warning" stats icon>
                  <CardIcon color="info">
                    <div >    
                       <ChartistGraph
                      className="ct-chart"
                      data={truckTTIChart.data}
                      type="Line"
                      options={truckTTIChart.options}
                    //listener={truckTTIChart.animation}
                    />
                    
                    </div>
                  </CardIcon>              
                </CardHeader>
                */}
                <CardBody style={{ paddingBottom: "0" }}>
                  <div id="ttiboxesb" style={{ paddingBottom: "0" }}>
                    <GridContainer
                      spacing={10}
                      direction="row"
                      alignItems="center"
                      justify="center"
                      margin={5}
                    >
                      <GridItem xs={6}>
                        <h3 style={{ fontWeight: 400 }}>
                          {truckTTIChart.header}
                        </h3>
                        <h3>
                          <SetPcDiffYrText
                            yrDiffPC={setyrDiffPC(truckTTIChart.type)}
                            yrDiff={setyrDiff(truckTTIChart.type)}
                            skipPClabel
                            type={truckTTIChart.type}
                          />
                        </h3>
                      </GridItem>

                      <GridItem xs={6}>
                        <div id="trendplot3b">
                          <PlotlyTrendPlot
                            yearsList={yearsList}
                            trendData={truckTtiData}
                            height={"250px"}
                            width={"245px"}
                            minData={1}
                            maxData={Math.max.apply(null, truckTtiData) * 1.05}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>
                <CardFooter stats>
                  <div style={{ fontSize: "1.125rem", width: "50%" }}>
                    <a>
                      {currYear} - {setyrDiffCurrValue(truckTTIChart.type)}
                    </a>
                  </div>

                  <div
                    style={{
                      fontSize: "1.125rem",
                      width: "50%",
                      textAlign: "right",
                    }}
                  >
                    <a>
                      {currYear - 1} - {setyrDiffPriorValue(truckTTIChart.type)}
                    </a>
                  </div>
                </CardFooter>
              </Card>
            </div>

            <div id="testtrend4b" style={{ height: "0px" }}>
              <Card>
                {/* 
                <CardHeader color="warning" stats icon>
                  <CardIcon color="info">
                    <div >
                    <ChartistGraph
                      className="ct-chart"
                      data={truckVMTChart.data}
                      type="Line"
                      options={truckVMTChart.options}
                    //listener={truckVMTChart.animation}
                    />
                    </div>
                  </CardIcon>                  
                </CardHeader>
                    */}
                <CardBody style={{ paddingBottom: "0" }}>
                  <div id="delayboxes" style={{ paddingBottom: "0" }}>
                    <GridContainer
                      spacing={10}
                      direction="row"
                      alignItems="center"
                      justify="center"
                      margin={5}
                    >
                      <GridItem xs={6}>
                        <h3 style={{ fontWeight: 400 }}>
                          Annual Vehicle Miles of Travel (VMT)
                        </h3>
                        <h3>
                          <SetPcDiffYrText
                            yrDiffPC={
                              truckDataYrDiff
                                .filter((a) => a.metric === "VMT")
                                .map((a) => a.pcDiffValue)[0]
                            }
                            yrDiff={
                              truckDataYrDiff
                                .filter((a) => a.metric === "VMT")
                                .map((a) => a.diff)[0]
                            }
                          />
                        </h3>
                      </GridItem>

                      <GridItem xs={6}>
                        <div id="trendplot4">
                          <PlotlyTrendPlot
                            yearsList={yearsList}
                            trendData={truckVmtData}
                            height={"250px"}
                            width={"245px"}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>
                <CardFooter stats>
                  <div style={{ fontSize: "1.125rem", width: "50%" }}>
                    <a>
                      {currYear} -{" "}
                      {
                        truckDataYrDiff
                          .filter((a) => a.metric === "VMT")
                          .map((a) => a.currValue)[0]
                      }
                    </a>
                  </div>

                  <div
                    style={{
                      fontSize: "1.125rem",
                      width: "50%",
                      textAlign: "right",
                    }}
                  >
                    <a>
                      {currYear - 1} -{" "}
                      {truckDataYrDiff
                        .filter((a) => a.metric === "VMT")
                        .map((a) => a.priorValue)}
                    </a>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer id="test-container3" style={{ height: "0" }}>
          <GridItem
            style={{
              width: "800px",
              paddingTop: "0.005vh",
              marginRight: "0.75em",
            }}
          >
            {/* style={{ width: "800px", height: "475px", paddingTop: "0.005vh", marginRight: "0.75em" }}*/}
            <div id="tablestest">
              <Card>
                <CardHeader color="info">
                  <h3
                    className={classes.cardTitleWhite}
                    style={{ textAlign: "center" }}
                  >
                    Truck Summary
                  </h3>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="success"
                    /*
                    tableHead={["ID", "Name", "Salary", "Country"]}
                    tableData={[
                      ["1", "Dakota Rice", "$36,738", "Niger"],
                      ["2", "Minerva Hooper", "$23,789", "Curaçao"],
                      ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
                      ["4", "Philip Chaney", "$38,735", "Korea, South"]
                    ]}
                    */
                    tableHead={["Metric", "Value", "Yearly Change"]}
                    //tableData={truckData.map((row) => ([row.metric, row.value, row.diff]))}
                    tableData={truckData.map((row) => [
                      row.metric,
                      row.value,
                      //row.diff
                      <SetTableDiffYrText
                        yrDiff={row.diff}
                        trend={row.trend}
                        metric={row.metric}
                      />,
                    ])}
                  />
                </CardBody>
              </Card>
              <p style={{ fontSize: "17px" }}>
                NOTE: Truck vehicle hours of delay can be calculated by dividing
                truck person-hours of delay by 1.14
              </p>
            </div>
          </GridItem>

          <GridItem
            style={{ width: "800px", height: "175px", paddingTop: "0.05vh" }}
          >
            <div id="tablestest2">
              <Card style={{ marginBottom: "0" }}>
                <CardHeader color="info">
                  <h3
                    className={classes.cardTitleWhite}
                    style={{ textAlign: "center" }}
                  >
                    All Vehicles Summary
                  </h3>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="success"
                    /*
                    tableHead={["ID", "Name", "Salary", "Country"]}
                    tableData={[
                      ["1", "Dakota Rice", "$36,738", "Niger"],
                      ["2", "Minerva Hooper", "$23,789", "Curaçao"],
                      ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
                      ["4", "Philip Chaney", "$38,735", "Korea, South"]
                    ]}
                    */
                    tableHead={["Metric", "Value", "Yearly Change"]}
                    tableData={allVehData.map((row) => [
                      row.metric,
                      row.value,
                      //row.diff
                      <SetTableDiffYrText
                        yrDiff={row.diff}
                        trend={row.trend}
                        metric={row.metric}
                      />,
                    ])}
                  />
                </CardBody>
              </Card>
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer
          id="test-container4"
          style={{ height: "0", marginTop: "0.5px" }}
        >
          {/*<GridItem style={{ width: "1600px", height: "175px", paddingTop: "0.05vh", paddingBottom: "0.005vh" }}>*/}
          <GridItem
            style={{
              width: "1600px",
              height: "175px",
              marginTop: bottomBlockMarginTopPrint,
              paddingBottom: "0.005vh",
            }}
          >
            <div id="footertest2">
              <Card style={{ marginBottom: "0" }}>
                <CardFooter stats>
                  <div style={{ width: "25%" }}>
                    <p>
                      <strong>PTI</strong> - Planning Time Index
                    </p>
                  </div>

                  <div style={{ width: "25%", textAlign: "center" }}>
                    <p>
                      <strong>TTI</strong> - Travel Time Index
                    </p>
                  </div>

                  <div style={{ width: "25%", textAlign: "center" }}>
                    <p>
                      <strong>VMT</strong> - Vehicle Miles of Travel
                    </p>
                  </div>

                  <div style={{ width: "25%", textAlign: "right" }}>
                    <p>
                      <strong>GHG</strong> - Greenhouse Gas
                    </p>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
