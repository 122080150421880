/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";

import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";


const Plot = createPlotlyComponent(Plotly);


export default function PlotlyTrendPlot(props) {

    let plotHeight = '25vh';
    if (props.height) {
        plotHeight = props.height;
    }
    let plotWidth = '100%';
    if (props.width) {
        plotWidth = props.width;
    }
    return (
        <Plot
            data={[{
                //x: ['2017', '2018', '2019'],
                x: props.yearsList,
                //y: [100, 120, 75],
                y: props.trendData,
                type: 'scatter',
                name: 'test label',
                mode: 'lines+markers',
                line: {
                    shape: 'spline',
                    smoothing: 1.0,
                    width: 4,

                }
                // marker: {color: 'rgb(80,0,0)'}
            }]}
            //style={{ width: '100%', height: '25vh' }}
            style={{ width: plotWidth, height: plotHeight }}
            layout={{
                //xaxis: { title: 'Year', nticks: 3 }, yaxis: { title: props.trendDataDesc }, width: 650, height: 250, margin: { t: 25, b: 35 },
                xaxis: { title: 'Year', nticks: 3 }, yaxis: { title: props.trendDataDesc }, autosize: true, margin: {
                    l: 50,
                    r: 50,
                    b: 50,
                    t: 50,
                    pad: 4
                },
                yaxis: {range: [props.minData, props.maxData]}
                //plot_bgcolor: '#444',
                //paper_bgcolor: '#eee'
            }}
            config={{ modeBarButtonsToRemove: ['sendDataToCloud', 'autoScale2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'lasso2d', 'select2d', 'toggleSpikelines'], displaylogo: false, showTips: true }}

        />

    );
}
