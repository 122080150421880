import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import fetchDataValue from "utils/fetchDataValue.js"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles(styles);

export default function SetTableDiffYrText(props) {

    const classes = useStyles();

    //metric

    //function SetTableDiffYrText(props) {

    if (props.trend === 'up') {
        if (props.metric === 'TTI' | props.metric === 'PTI - 95th Percentile' | props.metric === 'PTI - 80th Percentile' | props.metric === 'TCI') {
            return (
                <span className={classes.dangerText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> {(props.yrDiff * 100).toFixed(0)} Points
                </span>
            )
        } else {
            return (
                <span className={classes.dangerText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> {props.yrDiff}
                </span>
            )
        }
    } else {
        if (props.metric === 'TTI' | props.metric === 'PTI - 95th Percentile' | props.metric === 'PTI - 80th Percentile' | props.metric === 'TCI') {
            return (
                <span className={classes.successText} color="warning">
                    <ArrowDownward className={classes.upArrowCardCategory} /> {(props.yrDiff * 100).toFixed(0)} Points
                </span>
            )
        } else {
            return (
                <span className={classes.successText} color="warning">
                    <ArrowDownward className={classes.upArrowCardCategory} /> {props.yrDiff}
                </span>
            )
        }
    }
};