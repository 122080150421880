import React, { useState } from "react";

export default function fetchDataValue(data, yearValue, fieldName) {

    //function fetchDataValue(data, yearValue, fieldName) {

        //const yearValue = 2018;
        //const fieldName = "trkdelay";

        // console.log("data");
        // console.log(data);
        // console.log("areaCode");
        // console.log(areaCode);
        // console.log("yearValue");
        // console.log(yearValue);

        const dataArray = data.filter((a) => a.year === yearValue)
        //console.log("dataArray");
        //console.log(dataArray);
        return (
            dataArray[0][fieldName]
        )
    //};
}