import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import fetchDataValue from "utils/fetchDataValue.js"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles(styles);

export default function SetPcDiffYrText(props) {

    const classes = useStyles();


    //function SetPcDiffYrText(props) {               //const SetPcDiffYrText = (yrDiffPC) => {
    // console.log("props.yrDiffPC");
    // console.log(props.yrDiffPC);

    // console.log("truckDataYrDiff");
    // console.log(truckDataYrDiff);
    const setText = (yrDiff, yrDiffPC) => {
        if (props.skipPClabel) {
            if (props.type === "TTI") {
                return (yrDiff + " Points")
            } else {
                return (yrDiff)
            }
        } else {
            //<ArrowUpward className={classes.upArrowCardCategory} /> { props.yrDiff } <br /> & ensp; ({ props.yrDiffPC } %)
            return (yrDiff + " (" + yrDiffPC + "%)")
        }
    };

    if (props.yrDiffPC > 0) {
        return (
            <span className={classes.dangerText}>
                <ArrowUpward className={classes.upArrowCardCategory} /> {setText(props.yrDiff, props.yrDiffPC)}
            </span>
        )
    } else {
        return (
            <span className={classes.successText}>
                <ArrowDownward className={classes.upArrowCardCategory} /> {setText(props.yrDiff, props.yrDiffPC)}
            </span>
        )
    }

    {/* 
    if (props.yrDiffPC > 0) {
        if (props.skipPClabel) {
            if (props.type === "TTI") {
                return (
                    <span className={classes.dangerText}>
                        <ArrowUpward className={classes.upArrowCardCategory} /> {props.yrDiff} Points
                    </span>
                )
            } else {
                return (
                    <span className={classes.dangerText}>
                        <ArrowUpward className={classes.upArrowCardCategory} /> {props.yrDiff}
                    </span>
                )
            }
        } else {
            return (
                <span className={classes.dangerText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> {props.yrDiff} ({props.yrDiffPC} %)
                </span>
            )
        }
    } else {
        if (props.skipPClabel) {
            if (props.type === "TTI") {
                return (
                    <span className={classes.successText} color="warning">
                        <ArrowDownward className={classes.upArrowCardCategory} /> {props.yrDiff} Points
                    </span>
                )
            } else {
                return (
                    <span className={classes.successText} color="warning">
                        <ArrowDownward className={classes.upArrowCardCategory} /> {props.yrDiff}
                    </span>
                )
            }
        } else {
            return (
                <span className={classes.successText} color="warning">
                    <ArrowDownward className={classes.upArrowCardCategory} /> {props.yrDiff} ({props.yrDiffPC} %)
                </span>
            )
        }
    }
    */}
};
