export const navLinksInfo = [
  {
    linkName: "About",
    linkID: "About",
    linkActive: false,
    eventKey: 0,
    toLink: "/about",
    bodyHeader: "About",
  },
  {
    linkName: "Statewide",
    linkID: "Statewide",
    linkActive: false,
    eventKey: 1,
    toLink: "/statewide",
    bodyHeader: "Statewide",
  },
  {
    linkName: "TxDOT District",
    linkID: "District",
    linkActive: false,
    eventKey: 2,
    toLink: "/district",
    bodyHeader: "TxDOT District",
  },
  {
    linkName: "County",
    linkID: "County",
    linkActive: false,
    eventKey: 3,
    toLink: "/county",
    bodyHeader: "County",
  },
  {
    linkName: "Regions",
    linkID: "Regions",
    linkActive: false,
    eventKey: 4,
    toLink: "/regions",
    bodyHeader: "Regions",
  },
  {
    linkName: "Truck Top 100",
    linkID: "TruckTop100",
    linkActive: false,
    eventKey: 5,
    toLink: "/trucktop100",
    bodyHeader: "Truck Top 100",
  },
  {
    linkName: "Truck Top 100 - 3D",
    linkID: "TruckTop100 - 3D",
    linkActive: false,
    eventKey: 6,
    toLink: "/trucktop100deckgl",
    bodyHeader: "Truck Top 100 - 3D",
  },
];

export const TXDOTdist_LIST = [
  {
    id: 8,
    name: "Abilene District",
  },
  {
    id: 4,
    name: "Amarillo District",
  },
  {
    id: 19,
    name: "Atlanta District",
  },
  {
    id: 14,
    name: "Austin District",
  },
  {
    id: 20,
    name: "Beaumont District",
  },
  {
    id: 23,
    name: "Brownwood District",
  },
  {
    id: 17,
    name: "Bryan District",
  },
  {
    id: 25,
    name: "Childress District",
  },
  {
    id: 16,
    name: "Corpus Christi District",
  },
  {
    id: 18,
    name: "Dallas District",
  },
  {
    id: 24,
    name: "El Paso District",
  },
  {
    id: 2,
    name: "Fort Worth District",
  },
  {
    id: 12,
    name: "Houston District",
  },
  {
    id: 22,
    name: "Laredo District",
  },
  {
    id: 5,
    name: "Lubbock District",
  },
  {
    id: 11,
    name: "Lufkin District",
  },
  {
    id: 6,
    name: "Odessa District",
  },
  {
    id: 1,
    name: "Paris District",
  },
  {
    id: 21,
    name: "Pharr District",
  },
  {
    id: 7,
    name: "San Angelo District",
  },
  {
    id: 15,
    name: "San Antonio District",
  },
  {
    id: 10,
    name: "Tyler District",
  },
  {
    id: 9,
    name: "Waco District",
  },
  {
    id: 3,
    name: "Wichita Falls District",
  },
  {
    id: 13,
    name: "Yoakum District",
  },
];

export const regions_LIST = [
  {
    name: "Texas Triangle Region",
    id: 1,
    counties: [
      8,
      14,
      15,
      18,
      28,
      43,
      45,
      46,
      50,
      57,
      61,
      71,
      74,
      76,
      80,
      82,
      90,
      95,
      98,
      102,
      106,
      110,
      127,
      145,
      147,
      154,
      161,
      170,
      175,
      220,
      227,
      236,
      237,
      246,
    ],
  },
  {
    name: "Border Region",
    id: 2,
    counties: [
      22,
      31,
      55,
      72,
      109,
      116,
      123,
      136,
      159,
      189,
      214,
      222,
      233,
      240,
      253,
    ],
  },
  {
    name: "Permian Basin Region",
    id: 3,
    counties: [
      2,
      17,
      22,
      40,
      41,
      48,
      51,
      52,
      53,
      54,
      55,
      58,
      63,
      69,
      70,
      77,
      78,
      84,
      86,
      88,
      96,
      111,
      115,
      116,
      119,
      123,
      132,
      134,
      135,
      138,
      140,
      151,
      152,
      153,
      156,
      164,
      165,
      168,
      173,
      177,
      186,
      189,
      192,
      195,
      200,
      207,
      208,
      216,
      217,
      218,
      221,
      222,
      223,
      226,
      231,
      233,
      238,
      248,
      251,
    ],
  },
  {
    name: "Houston Region",
    id: 4,
    counties: [8, 20, 36, 45, 80, 85, 102, 146, 158, 170, 236, 237, 241],
  },
  {
    name: "Dallas-Fort Worth Region",
    id: 5,
    counties: [
      43,
      57,
      61,
      71,
      73,
      112,
      117,
      127,
      130,
      175,
      182,
      184,
      199,
      213,
      220,
      249,
    ],
  },
  {
    name: "Rio Grande Valley Region",
    id: 6,
    counties: [24, 31, 66, 109, 125, 214, 245, 253],
  },
  {
    name: "Port of Houston Area",
    id: 7,
    counties: [24, 31, 66, 109, 125, 214, 245, 253],
  },
];

export const county_LIST = [
  {
    name: "Anderson",
    id: 1,
  },
  {
    name: "Andrews",
    id: 2,
  },
  {
    name: "Angelina",
    id: 3,
  },
  {
    name: "Aransas",
    id: 4,
  },
  {
    name: "Archer",
    id: 5,
  },
  {
    name: "Armstrong",
    id: 6,
  },
  {
    name: "Atascosa",
    id: 7,
  },
  {
    name: "Austin",
    id: 8,
  },
  {
    name: "Bailey",
    id: 9,
  },
  {
    name: "Bandera",
    id: 10,
  },
  {
    name: "Bastrop",
    id: 11,
  },
  {
    name: "Baylor",
    id: 12,
  },
  {
    name: "Bee",
    id: 13,
  },
  {
    name: "Bell",
    id: 14,
  },
  {
    name: "Bexar",
    id: 15,
  },
  {
    name: "Blanco",
    id: 16,
  },
  {
    name: "Borden",
    id: 17,
  },
  {
    name: "Bosque",
    id: 18,
  },
  {
    name: "Bowie",
    id: 19,
  },
  {
    name: "Brazoria",
    id: 20,
  },
  {
    name: "Brazos",
    id: 21,
  },
  {
    name: "Brewster",
    id: 22,
  },
  {
    name: "Briscoe",
    id: 23,
  },
  {
    name: "Brooks",
    id: 24,
  },
  {
    name: "Brown",
    id: 25,
  },
  {
    name: "Burleson",
    id: 26,
  },
  {
    name: "Burnet",
    id: 27,
  },
  {
    name: "Caldwell",
    id: 28,
  },
  {
    name: "Calhoun",
    id: 29,
  },
  {
    name: "Callahan",
    id: 30,
  },
  {
    name: "Cameron",
    id: 31,
  },
  {
    name: "Camp",
    id: 32,
  },
  {
    name: "Carson",
    id: 33,
  },
  {
    name: "Cass",
    id: 34,
  },
  {
    name: "Castro",
    id: 35,
  },
  {
    name: "Chambers",
    id: 36,
  },
  {
    name: "Cherokee",
    id: 37,
  },
  {
    name: "Childress",
    id: 38,
  },
  {
    name: "Clay",
    id: 39,
  },
  {
    name: "Cochran",
    id: 40,
  },
  {
    name: "Coke",
    id: 41,
  },
  {
    name: "Coleman",
    id: 42,
  },
  {
    name: "Collin",
    id: 43,
  },
  {
    name: "Collingsworth",
    id: 44,
  },
  {
    name: "Colorado",
    id: 45,
  },
  {
    name: "Comal",
    id: 46,
  },
  {
    name: "Comanche",
    id: 47,
  },
  {
    name: "Concho",
    id: 48,
  },
  {
    name: "Cooke",
    id: 49,
  },
  {
    name: "Coryell",
    id: 50,
  },
  {
    name: "Cottle",
    id: 51,
  },
  {
    name: "Crane",
    id: 52,
  },
  {
    name: "Crockett",
    id: 53,
  },
  {
    name: "Crosby",
    id: 54,
  },
  {
    name: "Culberson",
    id: 55,
  },
  {
    name: "Dallam",
    id: 56,
  },
  {
    name: "Dallas",
    id: 57,
  },
  {
    name: "Dawson",
    id: 58,
  },
  {
    name: "De Witt",
    id: 62,
  },
  {
    name: "Deaf Smith",
    id: 59,
  },
  {
    name: "Delta",
    id: 60,
  },
  {
    name: "Denton",
    id: 61,
  },
  {
    name: "Dickens",
    id: 63,
  },
  {
    name: "Dimmit",
    id: 64,
  },
  {
    name: "Donley",
    id: 65,
  },
  {
    name: "Duval",
    id: 67,
  },
  {
    name: "Eastland",
    id: 68,
  },
  {
    name: "Ector",
    id: 69,
  },
  {
    name: "Edwards",
    id: 70,
  },
  {
    name: "El Paso",
    id: 72,
  },
  {
    name: "Ellis",
    id: 71,
  },
  {
    name: "Erath",
    id: 73,
  },
  {
    name: "Falls",
    id: 74,
  },
  {
    name: "Fannin",
    id: 75,
  },
  {
    name: "Fayette",
    id: 76,
  },
  {
    name: "Fisher",
    id: 77,
  },
  {
    name: "Floyd",
    id: 78,
  },
  {
    name: "Foard",
    id: 79,
  },
  {
    name: "Fort Bend",
    id: 80,
  },
  {
    name: "Franklin",
    id: 81,
  },
  {
    name: "Freestone",
    id: 82,
  },
  {
    name: "Frio",
    id: 83,
  },
  {
    name: "Gaines",
    id: 84,
  },
  {
    name: "Galveston",
    id: 85,
  },
  {
    name: "Garza",
    id: 86,
  },
  {
    name: "Gillespie",
    id: 87,
  },
  {
    name: "Glasscock",
    id: 88,
  },
  {
    name: "Goliad",
    id: 89,
  },
  {
    name: "Gonzales",
    id: 90,
  },
  {
    name: "Gray",
    id: 91,
  },
  {
    name: "Grayson",
    id: 92,
  },
  {
    name: "Gregg",
    id: 93,
  },
  {
    name: "Grimes",
    id: 94,
  },
  {
    name: "Guadalupe",
    id: 95,
  },
  {
    name: "Hale",
    id: 96,
  },
  {
    name: "Hall",
    id: 97,
  },
  {
    name: "Hamilton",
    id: 98,
  },
  {
    name: "Hansford",
    id: 99,
  },
  {
    name: "Hardeman",
    id: 100,
  },
  {
    name: "Hardin",
    id: 101,
  },
  {
    name: "Harris",
    id: 102,
  },
  {
    name: "Harrison",
    id: 103,
  },
  {
    name: "Hartley",
    id: 104,
  },
  {
    name: "Haskell",
    id: 105,
  },
  {
    name: "Hays",
    id: 106,
  },
  {
    name: "Hemphill",
    id: 107,
  },
  {
    name: "Henderson",
    id: 108,
  },
  {
    name: "Hidalgo",
    id: 109,
  },
  {
    name: "Hill",
    id: 110,
  },
  {
    name: "Hockley",
    id: 111,
  },
  {
    name: "Hood",
    id: 112,
  },
  {
    name: "Hopkins",
    id: 113,
  },
  {
    name: "Houston",
    id: 114,
  },
  {
    name: "Howard",
    id: 115,
  },
  {
    name: "Hudspeth",
    id: 116,
  },
  {
    name: "Hunt",
    id: 117,
  },
  {
    name: "Hutchinson",
    id: 118,
  },
  {
    name: "Irion",
    id: 119,
  },
  {
    name: "Jack",
    id: 120,
  },
  {
    name: "Jackson",
    id: 121,
  },
  {
    name: "Jasper",
    id: 122,
  },
  {
    name: "Jeff Davis",
    id: 123,
  },
  {
    name: "Jefferson",
    id: 124,
  },
  {
    name: "Jim Hogg",
    id: 125,
  },
  {
    name: "Jim Wells",
    id: 126,
  },
  {
    name: "Johnson",
    id: 127,
  },
  {
    name: "Jones",
    id: 128,
  },
  {
    name: "Karnes",
    id: 129,
  },
  {
    name: "Kaufman",
    id: 130,
  },
  {
    name: "Kendall",
    id: 131,
  },
  {
    name: "Kenedy",
    id: 66,
  },
  {
    name: "Kent",
    id: 132,
  },
  {
    name: "Kerr",
    id: 133,
  },
  {
    name: "Kimble",
    id: 134,
  },
  {
    name: "King",
    id: 135,
  },
  {
    name: "Kinney",
    id: 136,
  },
  {
    name: "Kleberg",
    id: 137,
  },
  {
    name: "Knox",
    id: 138,
  },
  {
    name: "La Salle",
    id: 142,
  },
  {
    name: "Lamar",
    id: 139,
  },
  {
    name: "Lamb",
    id: 140,
  },
  {
    name: "Lampasas",
    id: 141,
  },
  {
    name: "Lavaca",
    id: 143,
  },
  {
    name: "Lee",
    id: 144,
  },
  {
    name: "Leon",
    id: 145,
  },
  {
    name: "Liberty",
    id: 146,
  },
  {
    name: "Limestone",
    id: 147,
  },
  {
    name: "Lipscomb",
    id: 148,
  },
  {
    name: "Live Oak",
    id: 149,
  },
  {
    name: "Llano",
    id: 150,
  },
  {
    name: "Loving",
    id: 151,
  },
  {
    name: "Lubbock",
    id: 152,
  },
  {
    name: "Lynn",
    id: 153,
  },
  {
    name: "Madison",
    id: 154,
  },
  {
    name: "Marion",
    id: 155,
  },
  {
    name: "Martin",
    id: 156,
  },
  {
    name: "Mason",
    id: 157,
  },
  {
    name: "Matagorda",
    id: 158,
  },
  {
    name: "Maverick",
    id: 159,
  },
  {
    name: "McCulloch",
    id: 160,
  },
  {
    name: "McLennan",
    id: 161,
  },
  {
    name: "McMullen",
    id: 162,
  },
  {
    name: "Medina",
    id: 163,
  },
  {
    name: "Menard",
    id: 164,
  },
  {
    name: "Midland",
    id: 165,
  },
  {
    name: "Milam",
    id: 166,
  },
  {
    name: "Mills",
    id: 167,
  },
  {
    name: "Mitchell",
    id: 168,
  },
  {
    name: "Montague",
    id: 169,
  },
  {
    name: "Montgomery",
    id: 170,
  },
  {
    name: "Moore",
    id: 171,
  },
  {
    name: "Morris",
    id: 172,
  },
  {
    name: "Motley",
    id: 173,
  },
  {
    name: "Nacogdoches",
    id: 174,
  },
  {
    name: "Navarro",
    id: 175,
  },
  {
    name: "Newton",
    id: 176,
  },
  {
    name: "Nolan",
    id: 177,
  },
  {
    name: "Nueces",
    id: 178,
  },
  {
    name: "Ochiltree",
    id: 179,
  },
  {
    name: "Oldham",
    id: 180,
  },
  {
    name: "Orange",
    id: 181,
  },
  {
    name: "Palo Pinto",
    id: 182,
  },
  {
    name: "Panola",
    id: 183,
  },
  {
    name: "Parker",
    id: 184,
  },
  {
    name: "Parmer",
    id: 185,
  },
  {
    name: "Pecos",
    id: 186,
  },
  {
    name: "Polk",
    id: 187,
  },
  {
    name: "Potter",
    id: 188,
  },
  {
    name: "Presidio",
    id: 189,
  },
  {
    name: "Rains",
    id: 190,
  },
  {
    name: "Randall",
    id: 191,
  },
  {
    name: "Reagan",
    id: 192,
  },
  {
    name: "Real",
    id: 193,
  },
  {
    name: "Red River",
    id: 194,
  },
  {
    name: "Reeves",
    id: 195,
  },
  {
    name: "Refugio",
    id: 196,
  },
  {
    name: "Roberts",
    id: 197,
  },
  {
    name: "Robertson",
    id: 198,
  },
  {
    name: "Rockwall",
    id: 199,
  },
  {
    name: "Runnels",
    id: 200,
  },
  {
    name: "Rusk",
    id: 201,
  },
  {
    name: "Sabine",
    id: 202,
  },
  {
    name: "San Augustine",
    id: 203,
  },
  {
    name: "San Jacinto",
    id: 204,
  },
  {
    name: "San Patricio",
    id: 205,
  },
  {
    name: "San Saba",
    id: 206,
  },
  {
    name: "Schleicher",
    id: 207,
  },
  {
    name: "Scurry",
    id: 208,
  },
  {
    name: "Shackelford",
    id: 209,
  },
  {
    name: "Shelby",
    id: 210,
  },
  {
    name: "Sherman",
    id: 211,
  },
  {
    name: "Smith",
    id: 212,
  },
  {
    name: "Somervell",
    id: 213,
  },
  {
    name: "Starr",
    id: 214,
  },
  {
    name: "Stephens",
    id: 215,
  },
  {
    name: "Sterling",
    id: 216,
  },
  {
    name: "Stonewall",
    id: 217,
  },
  {
    name: "Sutton",
    id: 218,
  },
  {
    name: "Swisher",
    id: 219,
  },
  {
    name: "Tarrant",
    id: 220,
  },
  {
    name: "Taylor",
    id: 221,
  },
  {
    name: "Terrell",
    id: 222,
  },
  {
    name: "Terry",
    id: 223,
  },
  {
    name: "Throckmorton",
    id: 224,
  },
  {
    name: "Titus",
    id: 225,
  },
  {
    name: "Tom Green",
    id: 226,
  },
  {
    name: "Travis",
    id: 227,
  },
  {
    name: "Trinity",
    id: 228,
  },
  {
    name: "Tyler",
    id: 229,
  },
  {
    name: "Upshur",
    id: 230,
  },
  {
    name: "Upton",
    id: 231,
  },
  {
    name: "Uvalde",
    id: 232,
  },
  {
    name: "Val Verde",
    id: 233,
  },
  {
    name: "Van Zandt",
    id: 234,
  },
  {
    name: "Victoria",
    id: 235,
  },
  {
    name: "Walker",
    id: 236,
  },
  {
    name: "Waller",
    id: 237,
  },
  {
    name: "Ward",
    id: 238,
  },
  {
    name: "Washington",
    id: 239,
  },
  {
    name: "Webb",
    id: 240,
  },
  {
    name: "Wharton",
    id: 241,
  },
  {
    name: "Wheeler",
    id: 242,
  },
  {
    name: "Wichita",
    id: 243,
  },
  {
    name: "Wilbarger",
    id: 244,
  },
  {
    name: "Willacy",
    id: 245,
  },
  {
    name: "Williamson",
    id: 246,
  },
  {
    name: "Wilson",
    id: 247,
  },
  {
    name: "Winkler",
    id: 248,
  },
  {
    name: "Wise",
    id: 249,
  },
  {
    name: "Wood",
    id: 250,
  },
  {
    name: "Yoakum",
    id: 251,
  },
  {
    name: "Young",
    id: 252,
  },
  {
    name: "Zapata",
    id: 253,
  },
  {
    name: "Zavala",
    id: 254,
  },
];

export const MPO_LIST = [
  {
    id: 166,
    name: "Abilene MPO",
  },
  {
    id: 120,
    name: "Amarillo MPO",
  },
  {
    id: 90,
    name: "Capital Area MPO",
  },
  {
    id: 135,
    name: "Beaumont - Port Arthur MPO",
  },
  /* Brownsville is now combined to make Rio Grande Valley MPO
    {
        "id": 230,
        "name": "Brownsville MPO"
    },
    */
  {
    id: 249,
    name: "Bryan College Station MPO",
  },
  {
    id: 96,
    name: "Corpus Christi MPO",
  },
  {
    id: 282,
    name: "North Central Texas COG",
  },
  {
    id: 66,
    name: "El Paso MPO",
  },
  {
    id: 230,
    name: "Rio Grande Valley MPO",
  },
  {
    id: 15,
    name: "Houston-Galveston Area Council",
  },
  {
    id: 277,
    name: "Killeen-Temple MPO",
  },
  {
    id: 205,
    name: "Laredo Urban Transportation Study",
  },
  {
    id: 361,
    name: "Longview MPO",
  },
  {
    id: 122,
    name: "Lubbock MPO",
  },
  {
    id: 174,
    name: "Permian Basin MPO",
  },
  {
    id: 208,
    name: "San Angelo MPO",
  },
  {
    id: 28,
    name: "Alamo Area MPO",
  },
  {
    id: 232,
    name: "Sherman-Denison MPO",
  },
  {
    id: 211,
    name: "Texarkana MPO",
  },
  {
    id: 213,
    name: "Tyler Area MPO",
  },
  {
    id: 363,
    name: "Victoria MPO",
  },
  {
    id: 140,
    name: "Waco MPO",
  },
  {
    id: 151,
    name: "Wichita Falls MPO",
  },
];

export const corridors_LIST = [
  {
    name: "Interstates (including loops)",
    id: 1,
  },
  {
    name: "Port-to-Plains Corridor",
    id: 2,
  },
  {
    name: "IH-10",
    id: 3,
  },
  {
    name: "IH-35",
    id: 4,
  },
  {
    name: "IH-45",
    id: 5,
  },
  {
    name: "IH-20",
    id: 6,
  },
  {
    name: "IH-30",
    id: 7,
  },
];

export const state_LIST = [
  {
    name: "Statewide",
    id: 1,
  },
];

export const truckRanking_LIST = [
  {
    name: "#1 IH 35 from US 290 N / SS 69 to Ben White Blvd / SH 71",
    id: 3000004,
    trank: 1,
  },
  {
    name: "#2 Eastex Fwy / IH 69 / US 59 from SH 288 to IH 10",
    id: 1200140,
    trank: 2,
  },
  {
    name: "#3 North Fwy / IH 35W / US 287 from SH 183 to IH 30",
    id: 8000010,
    trank: 3,
  },
  {
    name:
      "#4 Katy Fwy / IH 10 / US 90 from W Loop N Fwy / IH 610 to North Fwy / IH 45",
    id: 1200169,
    trank: 4,
  },
  {
    name:
      "#5 North Fwy / IH 35W / US 287 from US 81 / US 287 to 28th St / SH 183",
    id: 8000009,
    trank: 5,
  },
  {
    name:
      "#6 N Loop W Fwy / IH 610 from North Fwy / IH 45 to Katy Fwy / IH 10 / US 90",
    id: 1200183,
    trank: 6,
  },
  {
    name:
      "#7 W Loop Fwy / IH 610 from Katy Fwy / IH 10 / US 90 to Southwest Fwy / US 59 / IH 69",
    id: 1200184,
    trank: 7,
  },
  {
    name: "#8 IH 10 / US 90 from North Fwy / IH 45 to Eastex Fwy / US 59",
    id: 1200168,
    trank: 8,
  },
  {
    name:
      "#9 E R. L. Thornton Fwy / IH 30 / US 67 from Jefferson Viaduct to Buckner Blvd / SL 12 E",
    id: 8000051,
    trank: 9,
  },
  {
    name: "#10 IH 345 / US 75 / IH 45 from US 75 to S. M. Wright Fwy / US 175",
    id: 8000117,
    trank: 10,
  },
  {
    name:
      "#11 Bob Bullock Loop / SL 20 from US – Mexico Border to IH 35 / US 83",
    id: 1400055,
    trank: 11,
  },
  {
    name:
      "#12 Southwest Fwy / IH 69 / US 59 from W Loop Fwy / IH 610 to South Fwy / SH 288",
    id: 1200139,
    trank: 12,
  },
  {
    name: "#13 IH 35 from RM 1431 to SH 45 / Louis Henna Blvd",
    id: 3000007,
    trank: 13,
  },
  {
    name:
      "#14 Stemmons Fwy / IH 35E / US 77 from John W. Carpenter / SH 183 to Tom Landry Fwy / IH 30",
    id: 8000020,
    trank: 14,
  },
  {
    name:
      "#15 Lyndon B. Johnson Fwy / IH 635 from US 75 to Garland Ave / SH 78",
    id: 8000135,
    trank: 15,
  },
  {
    name: "#16 IH 35W from Alliance Gateway / SH 170 to US 81 / US 287",
    id: 8000008,
    trank: 16,
  },
  {
    name:
      "#17 Mines Rd / FM 1472 from Pan American Blvd to Bob Bullock Loop / SL 20",
    id: 1400201,
    trank: 17,
  },
  {
    name:
      "#18 Lyndon B. Johnson Fwy / IH 635 from Garland Ave / SH 78 to US 80",
    id: 8000134,
    trank: 18,
  },
  {
    name: "#19 IH 35 from Ben White Blvd / SH 71 to Slaughter Ln",
    id: 3000003,
    trank: 19,
  },
  {
    name:
      "#20 North Fwy / IH 45 from Sam Houston Tollway N to N Loop Fwy / IH 610",
    id: 1200142,
    trank: 20,
  },
  {
    name:
      "#21 IH 35 / US 77 from Waco Dr / SH 31 / US 84 to S Valley Mills Dr / SH 396",
    id: 2400012,
    trank: 21,
  },
  {
    name: "#22 Gulf Fwy / IH 45 from IH 10 / US 90 to S Loop E Fwy / IH 610",
    id: 1200143,
    trank: 22,
  },
  {
    name:
      "#23 Lyndon B. Johnson Fwy / IH 635 from Stemmons Fwy / IH 35E / US 77 to US 75",
    id: 8000136,
    trank: 23,
  },
  {
    name:
      "#24 NE Loop 820 / IH 820 from North Fwy / IH 35W to Baker Blvd / SH 183",
    id: 8000061,
    trank: 24,
  },
  {
    name:
      "#25 US 75 from Lyndon B. Johnson Fwy / IH 635 to Woodall Rodgers Fwy / SS 366",
    id: 8000112,
    trank: 25,
  },
  {
    name:
      "#26 IH 20 from Marvin D. Love Fwy / US 67 to S Central Expy / SH 310",
    id: 8000040,
    trank: 26,
  },
  {
    name:
      "#27 N PanAm Expy / IH 35 from Charles West Anderson Loop / SL 1604 NE to Connally Loop NE / IH 410",
    id: 1900035,
    trank: 27,
  },
  {
    name:
      "#28 N Loop 820 / Jim Wright Fwy / IH 820 from North Fwy / IH 35W to Jacksboro Hwy / SH 199",
    id: 8000060,
    trank: 28,
  },
  {
    name:
      "#29 South Fwy / SH 288 from Gulf Fwy / IH 45 to S Loop W Fwy / IH 610",
    id: 1200196,
    trank: 29,
  },
  {
    name: "#30 IH 35 from Slaughter Ln to SH 45",
    id: 3000002,
    trank: 30,
  },
  {
    name: "#31 SH 114 from IH 35W to Precinct Line Rd / FM 1938",
    id: 8000101,
    trank: 31,
  },
  {
    name: "#32 North Fwy / IH 45 from N Loop Fwy / IH 610 to IH 10 / US 90",
    id: 1200141,
    trank: 32,
  },
  {
    name:
      "#33 IH 35 from Natural Bridge Caverns Rd / FM 3009 to Charles West Anderson Loop / SL 1604 NE",
    id: 1900034,
    trank: 33,
  },
  {
    name: "#34 S Cage Blvd / US 281 from W Las Milpas Rd to US – Mexico Border",
    id: 1100022,
    trank: 34,
  },
  {
    name: "#35 IH 35E / US 77 from BS 121H to Lyndon B. Johnson Fwy / IH 635",
    id: 8000017,
    trank: 35,
  },
  {
    name:
      "#36 Southwest Fwy / IH 69 / US 59 from W Sam Houston Pkwy S / SL 8 to IH 610",
    id: 1200188,
    trank: 36,
  },
  {
    name:
      "#37 Katy Fwy / IH 10 / US 90 from N Eldridge Pkwy to Sam Houston Tollway W / SL 8",
    id: 1200171,
    trank: 37,
  },
  {
    name:
      "#38 Katy Fwy / IH 10 / US 90 from Grand Pkwy / SH 99 to N Eldridge Pkwy",
    id: 1200172,
    trank: 38,
  },
  {
    name:
      "#39 N PanAm Expy / IH 35 from Connally Loop NE / IH 410 to Connally Loop E / IH 410",
    id: 1900036,
    trank: 39,
  },
  {
    name:
      "#40 N Loop E Fwy / IH 610 from North Fwy / IH 45 to East Fwy / IH 10",
    id: 1200182,
    trank: 40,
  },
  {
    name:
      "#41 Katy Fwy / IH 10 / US 90 from Sam Houston Tollway W / SL 8 to W Loop N Fwy / IH 610",
    id: 1200170,
    trank: 41,
  },
  {
    name:
      "#42 IH 30 / US 67 from Buckner Blvd / SL 12 E to President George Bush Hwy",
    id: 8000052,
    trank: 42,
  },
  {
    name:
      "#43 East Fwy / IH 10 / US 90 from Eastex Fwy / US 59 to E Loop Fwy / IH 610",
    id: 1200167,
    trank: 43,
  },
  {
    name: "#44 IH 69C / US 281 from E Canton Rd to IH 2 / US 83",
    id: 1100023,
    trank: 44,
  },
  {
    name: "#45 Woodall Rodgers Fwy / SS 366 from US 75 to N Beckley Ave",
    id: 8000155,
    trank: 45,
  },
  {
    name:
      "#46 IH 35 / US 77 from New Dallas Hwy / SL 491 / BU 77 to Waco Dr / SH 31 / US 84",
    id: 2400010,
    trank: 46,
  },
  {
    name: "#47 IH 20 from SH 360 to Marvin D. Love Fwy / US 67",
    id: 8000039,
    trank: 47,
  },
  {
    name:
      "#48 Stemmons Fwy / IH 35E / US 77 from Lyndon B. Johnson Fwy / IH 635 to W Northwest Hwy / SL 12 N",
    id: 8000018,
    trank: 48,
  },
  {
    name: "#49 IH 35 from SH 46 to Natural Bridge Caverns Rd / FM 3009",
    id: 3000059,
    trank: 49,
  },
  {
    name:
      "#50 North Fwy / IH 45 from Lake Front Cir to Spring Cypress Rd / FM 2920",
    id: 1200148,
    trank: 50,
  },
  {
    name:
      "#51 Gulf Fwy / IH 45 from S Loop E Fwy / IH 610 to Sam Houston Tollway SE / SL 8",
    id: 1200144,
    trank: 51,
  },
  {
    name:
      "#52 PanAm Expy / IH 35 / IH 10 from Staff Sergeant William J. Bordelon Fwy / IH 37 / US 281 to Cleto Rodriguez Fwy / US 90",
    id: 1900019,
    trank: 52,
  },
  {
    name: "#53 IH 30 / US 67 from President George Bush Hwy to FM 3549",
    id: 8000053,
    trank: 53,
  },
  {
    name: "#54 US 287 from US 67 to IH 35E",
    id: 8000149,
    trank: 54,
  },
  {
    name: "#55 E Loop Fwy / IH 610 from East Fwy / IH 10 to Gulf Fwy / IH 45",
    id: 1200187,
    trank: 55,
  },
  {
    name:
      "#56 S Loop E Fwy / IH 610 from South Fwy / SH 288 to Gulf Fwy / IH 45",
    id: 1200186,
    trank: 56,
  },
  {
    name:
      "#57 Charles West Anderson Loop / SL 1604 N from US 281 to McDermott Fwy / IH 10 / US 87",
    id: 1900012,
    trank: 57,
  },
  {
    name:
      "#58 Stemmons Fwy / IH 35E / US 77 from W Northwest Hwy / SL 12 N to John W. Carpenter / SH 183",
    id: 8000019,
    trank: 58,
  },
  {
    name: "#59 S Staples St from S Padre Island Dr / SH 358 to Yorktown Blvd",
    id: 7000061,
    trank: 59,
  },
  {
    name:
      "#60 W Loop S Fwy / IH 610 from Southwest Fwy / US 59 / IH 69 to South Fwy / SH 288",
    id: 1200185,
    trank: 60,
  },
  {
    name:
      "#61 North Fwy / IH 45 from Spring Cypress Rd / FM 2920 to Sam Houston Tollway N / SL 8",
    id: 1200147,
    trank: 61,
  },
  {
    name: "#62 IH 35 from S Valley Mills Dr / SH 396 to Hwy 6 / SL 340",
    id: 2400013,
    trank: 62,
  },
  {
    name: "#63 Walton Walker Blvd / SL 12 W from IH 35E to IH 30",
    id: 8000084,
    trank: 63,
  },
  {
    name:
      "#64 Tom Landry Fwy / IH 30 from S Walton Walker Blvd / SL 12 W to Jefferson Viaduct",
    id: 8000050,
    trank: 64,
  },
  {
    name:
      "#65 IH 35E / US 77 / US 67 from Tom Landry Fwy / IH 30 to Marvin D. Love Fwy / US 67",
    id: 8000021,
    trank: 65,
  },
  {
    name: "#66 IH 20 from US 287 to SH 360",
    id: 8000038,
    trank: 66,
  },
  {
    name: "#67 Tom Landry Fwy / IH 30 from E Loop 820 / IH 820 to SH 360",
    id: 8000048,
    trank: 67,
  },
  {
    name: "#68 IH 35W from IH 30 to IH 20",
    id: 8000011,
    trank: 68,
  },
  {
    name:
      "#69 Connally Loop NE / IH 410 from N PanAm Expy / IH 35 to Rigsby Ave / US 87",
    id: 1900038,
    trank: 69,
  },
  {
    name: "#70 IH 2 from FM 1423 to US 281",
    id: 1010103,
    trank: 70,
  },
  {
    name:
      "#71 Sam Johnson Hwy / US 75 from Sam Rayburn Tollway / SH 121 / SH 399 to President George Bush Turnpike Toll Rd / SH 190",
    id: 8000114,
    trank: 71,
  },
  {
    name: "#72 Eastex Fwy / IH 69 / US 59 from IH 10 to Little York Rd",
    id: 1200112,
    trank: 72,
  },
  {
    name: "#73 East Fwy / IH 10 from E Sam Houston Pkwy / SL 8 to SS 330",
    id: 1200165,
    trank: 73,
  },
  {
    name: "#74 BU 287P from Old Decatur Rd to NE Loop 820 / IH 820",
    id: 8000387,
    trank: 74,
  },
  {
    name: "#75 Milo Rd / FM 3464 from Mines Rd / FM 1472 to IH 35 / US 83",
    id: 1400800,
    trank: 75,
  },
  {
    name:
      "#76 University Dr / US 380 from N Custer Rd / FM 2478 to N McDonald St / SH 5",
    id: 8000198,
    trank: 76,
  },
  {
    name:
      "#77 Southeast Loop 323 from E 5th St / SH 64 to S Broadway Ave / Us 69",
    id: 2200033,
    trank: 77,
  },
  {
    name:
      "#78 SL 288 from E University Dr / US 380 to IH 35E / US 77 (Lillian Miller Pkwy)",
    id: 8000196,
    trank: 78,
  },
  {
    name: "#79 US 290 / SH 71 from RM 1826 to S MoPac Expy / SL 1",
    id: 3000012,
    trank: 79,
  },
  {
    name:
      "#80 Pasadena Fwy / SS 225 from E Loop Fwy / IH 610 to Sam Houston Tollway SE / SL 8",
    id: 1200200,
    trank: 80,
  },
  {
    name:
      "#81 US 75 from President George Bush Turnpike Toll Rd / SH 190 to Lyndon B. Johnson Fwy / IH 635",
    id: 8000113,
    trank: 81,
  },
  {
    name: "#82 IH 35W from IH 20 to NE Wilshire Blvd / SH 174",
    id: 8000012,
    trank: 82,
  },
  {
    name: "#83 IH 2 from US 281 to S 23rd St",
    id: 1010102,
    trank: 83,
  },
  {
    name:
      "#84 Sam Houston Tollway W / SL 8 from Southwest Fwy / IH 69 to IH 10",
    id: 1200156,
    trank: 84,
  },
  {
    name:
      "#85 North Sam Houston Pkwy E / SL 8 from Hardy Toll Rd to Old Humble Rd",
    id: 1200159,
    trank: 85,
  },
  {
    name: "#86 Gateway Blvd / IH 10 from Patriot Fwy / US 54 to Hawkins Blvd",
    id: 1000013,
    trank: 86,
  },
  {
    name: "#87 US 80 from Collins Rd / SH 352 to Pinson Rd / FM 740",
    id: 8000131,
    trank: 87,
  },
  {
    name: "#88 IH 35 from FM 1102 to SH 46",
    id: 3000058,
    trank: 88,
  },
  {
    name:
      "#89 IH 10 / US 90 from Charles West Anderson Loop E / SL 1604 to FM 1518",
    id: 1900086,
    trank: 89,
  },
  {
    name: "#90 SH 360 from Airport Fwy / SH 183 to Tom Landry Fwy / IH 30",
    id: 8000081,
    trank: 90,
  },
  {
    name: "#91 IH 35 from SH 45 / Louis Henna Blvd to Parmer Ln / FM 734",
    id: 3000006,
    trank: 91,
  },
  {
    name: "#92 SL 340 from IH 35 / US 77 to Marlin Hwy / Spur 484",
    id: 2400030,
    trank: 92,
  },
  {
    name: "#93 Airport Fwy / SH 183 from SH 360 to SH 161",
    id: 8000077,
    trank: 93,
  },
  {
    name:
      "#94 E Loop 820 / IH 820 from Baker Blvd / SH 183 to Tom Landry Fwy / IH 30",
    id: 8000062,
    trank: 94,
  },
  {
    name: "#95 Lyndon B. Johnson Fwy / IH 635 from US 80 to IH 20",
    id: 8000133,
    trank: 95,
  },
  {
    name:
      "#96 Culebra Rd / FM 471 from Galm Rd to Charles West Anderson Loop NW / SL 1604",
    id: 1900072,
    trank: 96,
  },
  {
    name:
      "#97 Pasadena Fwy / SS 225 from Sam Houston Tollway E / SL 8 to SH 146",
    id: 1200094,
    trank: 97,
  },
  {
    name: "#98 IH 35 from Parmer Ln / FM 734 to US 290 N / SS 69",
    id: 3000005,
    trank: 98,
  },
  {
    name:
      "#99 Jose Lopez Fwy / IH 10 / US 90 from S PanAm Expy / IH 35 / US 87 to Staff Sergeant William J. Bordelon Fwy / IH 37 / US 281",
    id: 1900032,
    trank: 99,
  },
  {
    name:
      "#100 US 80 from E R. L. Thornton Fwy / IH 30 / US 67 to Collins Rd / SH 352",
    id: 8000130,
    trank: 100,
  },
];
