import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import domtoimage from "dom-to-image";

import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

import TruckCongRepCardPDF from "../utils/TruckCongRepCardPDF.js";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function DownloadModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    handleCloseSnackbar();
    setOpen(false);
  };

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageSnackbar, setMessageSnackbar] = React.useState("Message Test");

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const enablePrintVersion = () => {
    var node2 = document.querySelector("#section-to-print2");
    node2.style.display = "";
    node2.style.visibility = "visible";

    var nodeTestContainer = document.querySelector("#test-container");
    var nodeTestContainer2 = document.querySelector("#test-container2");
    var nodetesttrend1b = document.querySelector("#testtrend1b");
    var nodetesttrend2b = document.querySelector("#testtrend2b");
    var nodebmaptest2bCard1 = document.querySelector("#maptest2bCard1");
    var nodebmaptest2bCard2 = document.querySelector("#maptest2bCard2");
    var nodetesttrend3b = document.querySelector("#testtrend3b");
    var nodetesttrend4b = document.querySelector("#testtrend4b");
    var nodeTestContainer3 = document.querySelector("#test-container3");
    var nodeTestContainer4 = document.querySelector("#test-container4");
    nodeTestContainer.style.height = "";
    nodeTestContainer2.style.height = "";
    nodetesttrend1b.style.height = "";
    nodetesttrend2b.style.height = "";
    nodebmaptest2bCard1.style.height = "";
    nodebmaptest2bCard2.style.height = "";
    nodetesttrend3b.style.height = "";
    nodetesttrend4b.style.height = "";
    nodeTestContainer3.style.height = "";
    nodeTestContainer4.style.height = "";

    return null;
  };

  const disablePrintVersion = () => {
    var node2 = document.querySelector("#section-to-print2");
    var nodeTestContainer = document.querySelector("#test-container");
    var nodeTestContainer2 = document.querySelector("#test-container2");
    var nodetesttrend1b = document.querySelector("#testtrend1b");
    var nodetesttrend2b = document.querySelector("#testtrend2b");
    var nodebmaptest2bCard1 = document.querySelector("#maptest2bCard1");
    var nodebmaptest2bCard2 = document.querySelector("#maptest2bCard2");
    var nodetesttrend3b = document.querySelector("#testtrend3b");
    var nodetesttrend4b = document.querySelector("#testtrend4b");
    var nodeTestContainer3 = document.querySelector("#test-container3");
    var nodeTestContainer4 = document.querySelector("#test-container4");

    nodeTestContainer.style.height = "0px";
    nodeTestContainer2.style.height = "0px";
    nodetesttrend1b.style.height = "0px";
    nodetesttrend2b.style.height = "0px";
    nodebmaptest2bCard1.style.height = "0px";
    nodebmaptest2bCard2.style.height = "0px";
    nodetesttrend3b.style.height = "0px";
    nodetesttrend4b.style.height = "0px";
    nodeTestContainer3.style.height = "0px";
    nodeTestContainer4.style.height = "0px";

    node2.style.visibility = "hidden";

    return null;
  };

  const downloadPNGButton = async () => {
    const filename = props.activeGeomName + ` 2021TruckCongReportCard.png`;

    var node2 = document.querySelector("#section-to-print2");

    enablePrintVersion();

    await domtoimage.toBlob(node2).then(function(blob) {
      window.saveAs(blob, filename);
    });

    disablePrintVersion();

    //alert("PNG Downloaded!")
    setMessageSnackbar("PNG Downloaded!");
    handleClickSnackbar();

    return null;
  };

  const generatePdfButton = async () => {
    enablePrintVersion();

    await generateImages();

    disablePrintVersion();

    //node2.style.display = 'none';

    //generateImages();

    //alert("PDF generated and ready to download!")

    //PdfReadyModal();
    setMessageSnackbar("PDF generated and ready to download!");
    handleClickSnackbar();
    return null;
  };

  const [testFullImage, settestFullImage] = useState();

  const generateImages = async () => {
    //var node = document.querySelector("#section-to-print");
    var node2 = document.querySelector("#section-to-print2");
    //node.style.display = "none"; // or any other property, like opacity, visibility...
    //node2.style.display = ''; // or any other property, like opacity, visibility...
    node2.style.visibility = "visible";
    //node2.style.display = '';

    let testdataFull = await domtoimage.toPng(
      document.querySelector("#section-to-print2")
    ); // "#section-to-print"   "#maptest"
    testdataFull = await domtoimage.toPng(
      document.querySelector("#section-to-print2")
    );
    //console.log("testdata");
    //console.log(testdata);
    settestFullImage(testdataFull);

    //node.style.display = ''; // or any other property, like opacity, visibility...
    //node2.style.display = "none"; // or any other property, like opacity, visibility...
    node2.style.visibility = "hidden";
    //node2.style.display = 'none';

    //PdfReadyModal();

    return null;
  };

  const printPdfButton2 = async () => {
    //generateImages();
    //generateImages();

    const filename = props.activeGeomName + ` 2021TruckCongReportCard.pdf`;

    const blob = await pdf(
      <TruckCongRepCardPDF
        docImage={testFullImage}
        tableHead={["Metric", "Value", "Yearly Change"]}
        truckData={props.truckData}
        allVehData={props.allVehData}
      />
    ).toBlob();
    saveAs(blob, filename);

    //alert("PDF Downloaded!")
    setMessageSnackbar("PDF Downloaded!");
    handleClickSnackbar();

    return null;
  };

  //console.log("truckData")
  //console.log(props.truckData)
  return (
    <div>
      {/*<Button variant="contained" color="primary" onClick={handleOpen} style={{ marginTop: "2em" }}>
                Downloads
    </Button>*/}
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        style={{ margin: "1.0em", marginLeft: "0.5em" }}
      >
        Downloads
      </Button>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <h2>Download Options:</h2>
          <GridContainer>
            <GridItem xs={6} md={6} style={{ borderStyle: "solid" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={generatePdfButton}
                style={{ marginTop: "1em" }}
              >
                Generate PDF
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={printPdfButton2}
                style={{ marginTop: "1em" }}
              >
                Download PDF
              </Button>
              <p style={{ marginTop: "0" }}>
                (PDF must be generated prior to download.)
              </p>
            </GridItem>
            <GridItem xs={6} md={6} style={{ borderStyle: "solid" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={downloadPNGButton}
                title={"Download PNG"}
                style={{ marginTop: "1em" }}
              >
                Download PNG
              </Button>
              <p style={{ marginTop: "0" }}>(Best for presentations)</p>
            </GridItem>
          </GridContainer>
          <Button onClick={handleClose} style={{ marginTop: "1em" }}>
            Close
          </Button>
        </div>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={messageSnackbar}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
