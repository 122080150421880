export const congestionData = [
  {
    agglevel: "corridor",
    regioncode: 1,
    year: 2017,
    count: 9686,
    len_sec: 4736.48600000002,
    lanemiles: 19500.41600000005,
    delay: 1.78477054e8,
    trkdelay: 2.0482319e7,
    pti80: 1.41,
    trkpti80: 1.3,
    pti95: 1.53,
    trkpti95: 1.4,
    tti: 1.18,
    trktti: 1.12,
    ghg: 685101.0,
    congcost: 3.914657935e9,
    allspeed: 56.44,
    trkspeed: 59.84,
    ttivmt: 9.4454848e7,
    trkttivmt: 1.8807084e7,
  },
  {
    agglevel: "corridor",
    regioncode: 1,
    year: 2018,
    count: 5935,
    len_sec: 4531.536000000025,
    lanemiles: 19239.658999999927,
    delay: 1.9215932e8,
    trkdelay: 1.3056677e7,
    pti80: 1.46,
    trkpti80: 1.28,
    pti95: 1.59,
    trkpti95: 1.36,
    tti: 1.22,
    trktti: 1.12,
    ghg: 594568.0,
    congcost: 4.005889116e9,
    allspeed: 56.0,
    trkspeed: 59.56,
    ttivmt: 8.6309336e7,
    trkttivmt: 1.1631117e7,
  },
  {
    agglevel: "corridor",
    regioncode: 1,
    year: 2019,
    count: 5208,
    len_sec: 4826.651000000021,
    lanemiles: 19971.183000000015,
    delay: 1.88636063e8,
    trkdelay: 1.3567946e7,
    pti80: 1.41,
    trkpti80: 1.25,
    pti95: 1.53,
    trkpti95: 1.32,
    tti: 1.2,
    trktti: 1.11,
    ghg: 595513.0,
    congcost: 4.099228711e9,
    allspeed: 56.31,
    trkspeed: 58.46,
    ttivmt: 9.0506207e7,
    trkttivmt: 1.2291702e7,
  },
  {
    agglevel: "corridor",
    regioncode: 1,
    year: 2020,
    count: 13441,
    len_sec: 4861.556000000061,
    lanemiles: 20112.058000000223,
    delay: 2.06641451e8,
    trkdelay: 1.3267915e7,
    pti80: 1.44,
    trkpti80: 1.25,
    pti95: 1.56,
    trkpti95: 1.34,
    tti: 1.21,
    trktti: 1.1,
    ghg: 638149.0,
    congcost: 4.570130903e9,
    allspeed: 56.49,
    trkspeed: 60.58,
    ttivmt: 9.2579489e7,
    trkttivmt: 1.2641381e7,
  },
  {
    agglevel: "corridor",
    regioncode: 1,
    year: 2021,
    count: 13932,
    len_sec: 4849.168000000053,
    lanemiles: 20097.361000000103,
    delay: 9.2902886e7,
    trkdelay: 7050291.0,
    pti80: 1.23,
    trkpti80: 1.15,
    pti95: 1.3,
    trkpti95: 1.22,
    tti: 1.1,
    trktti: 1.05,
    ghg: 272979.0,
    congcost: 2.134432301e9,
    allspeed: 58.92,
    trkspeed: 61.82,
    ttivmt: 8.2085536e7,
    trkttivmt: 1.1921104e7,
  },
  {
    agglevel: "corridor",
    regioncode: 1,
    year: 2022,
    count: 16226,
    len_sec: 4978.0100000000975,
    lanemiles: 21055.762000000384,
    delay: 1.64938651e8,
    trkdelay: 1.3738059e7,
    pti80: 1.32,
    trkpti80: 1.2,
    pti95: 1.41,
    trkpti95: 1.27,
    tti: 1.15,
    trktti: 1.08,
    ghg: 491807.0,
    congcost: 4.227014873e9,
    allspeed: 58.09,
    trkspeed: 61.05,
    ttivmt: 9.197063e7,
    trkttivmt: 1.3645292e7,
  },
  {
    agglevel: "corridor",
    regioncode: 2,
    year: 2017,
    count: 1280,
    len_sec: 945.3019999999999,
    lanemiles: 2996.676999999999,
    delay: 2109005.0,
    trkdelay: 222563.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.01,
    ghg: 9065.0,
    congcost: 4.5895888e7,
    allspeed: 60.41,
    trkspeed: 63.27,
    ttivmt: 3219810.0,
    trkttivmt: 990906.0,
  },
  {
    agglevel: "corridor",
    regioncode: 2,
    year: 2018,
    count: 1171,
    len_sec: 951.0359999999977,
    lanemiles: 3118.7689999999943,
    delay: 2386818.0,
    trkdelay: 345771.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.03,
    ghg: 13249.0,
    congcost: 5.6538654e7,
    allspeed: 60.28,
    trkspeed: 60.53,
    ttivmt: 3060769.0,
    trkttivmt: 534262.0,
  },
  {
    agglevel: "corridor",
    regioncode: 2,
    year: 2019,
    count: 569,
    len_sec: 944.3499999999991,
    lanemiles: 3091.290999999998,
    delay: 2130262.0,
    trkdelay: 359396.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.03,
    ghg: 11993.0,
    congcost: 5.4278824e7,
    allspeed: 60.71,
    trkspeed: 59.86,
    ttivmt: 3218869.0,
    trkttivmt: 572947.0,
  },
  {
    agglevel: "corridor",
    regioncode: 2,
    year: 2020,
    count: 1916,
    len_sec: 943.4809999999981,
    lanemiles: 3204.806999999994,
    delay: 2026807.0,
    trkdelay: 281891.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 10431.0,
    congcost: 5.1107204e7,
    allspeed: 60.85,
    trkspeed: 62.09,
    ttivmt: 3262838.0,
    trkttivmt: 585791.0,
  },
  {
    agglevel: "corridor",
    regioncode: 2,
    year: 2021,
    count: 1891,
    len_sec: 947.9109999999972,
    lanemiles: 3223.309999999991,
    delay: 1626047.0,
    trkdelay: 256353.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 8036.0,
    congcost: 4.1891633e7,
    allspeed: 60.56,
    trkspeed: 62.18,
    ttivmt: 2877628.0,
    trkttivmt: 622588.0,
  },
  {
    agglevel: "corridor",
    regioncode: 2,
    year: 2022,
    count: 1952,
    len_sec: 948.1069999999969,
    lanemiles: 3230.723999999993,
    delay: 1703172.0,
    trkdelay: 327409.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 7886.0,
    congcost: 5.064553e7,
    allspeed: 60.69,
    trkspeed: 61.83,
    ttivmt: 3233279.0,
    trkttivmt: 641079.0,
  },
  {
    agglevel: "corridor",
    regioncode: 3,
    year: 2017,
    count: 1251,
    len_sec: 877.3669999999988,
    lanemiles: 4002.2719999999963,
    delay: 1.9737008e7,
    trkdelay: 2655747.0,
    pti80: 1.35,
    trkpti80: 1.21,
    pti95: 1.49,
    trkpti95: 1.3,
    tti: 1.14,
    trktti: 1.08,
    ghg: 71398.0,
    congcost: 4.43520523e8,
    allspeed: 58.75,
    trkspeed: 61.83,
    ttivmt: 1.6010791e7,
    trkttivmt: 4335212.0,
  },
  {
    agglevel: "corridor",
    regioncode: 3,
    year: 2018,
    count: 645,
    len_sec: 877.0279999999989,
    lanemiles: 4006.2110000000016,
    delay: 2.2757109e7,
    trkdelay: 1649492.0,
    pti80: 1.39,
    trkpti80: 1.21,
    pti95: 1.52,
    trkpti95: 1.28,
    tti: 1.18,
    trktti: 1.08,
    ghg: 63292.0,
    congcost: 4.76023384e8,
    allspeed: 57.67,
    trkspeed: 61.06,
    ttivmt: 1.467178e7,
    trkttivmt: 2486556.0,
  },
  {
    agglevel: "corridor",
    regioncode: 3,
    year: 2019,
    count: 497,
    len_sec: 877.0279999999993,
    lanemiles: 4006.2110000000007,
    delay: 2.0012852e7,
    trkdelay: 1781749.0,
    pti80: 1.34,
    trkpti80: 1.19,
    pti95: 1.45,
    trkpti95: 1.25,
    tti: 1.15,
    trktti: 1.07,
    ghg: 56355.0,
    congcost: 4.44108898e8,
    allspeed: 58.68,
    trkspeed: 60.07,
    ttivmt: 1.503667e7,
    trkttivmt: 2610617.0,
  },
  {
    agglevel: "corridor",
    regioncode: 3,
    year: 2020,
    count: 1555,
    len_sec: 877.0349999999995,
    lanemiles: 4017.8769999999995,
    delay: 2.1248792e7,
    trkdelay: 1620088.0,
    pti80: 1.35,
    trkpti80: 1.19,
    pti95: 1.46,
    trkpti95: 1.26,
    tti: 1.16,
    trktti: 1.07,
    ghg: 56163.0,
    congcost: 4.76621279e8,
    allspeed: 58.89,
    trkspeed: 61.99,
    ttivmt: 1.538645e7,
    trkttivmt: 2633358.0,
  },
  {
    agglevel: "corridor",
    regioncode: 3,
    year: 2021,
    count: 1521,
    len_sec: 877.0350000000002,
    lanemiles: 4030.204999999997,
    delay: 7218909.0,
    trkdelay: 620558.0,
    pti80: 1.15,
    trkpti80: 1.1,
    pti95: 1.2,
    trkpti95: 1.14,
    tti: 1.06,
    trktti: 1.03,
    ghg: 14954.0,
    congcost: 1.66679338e8,
    allspeed: 61.35,
    trkspeed: 63.05,
    ttivmt: 1.3388705e7,
    trkttivmt: 2341244.0,
  },
  {
    agglevel: "corridor",
    regioncode: 3,
    year: 2022,
    count: 1784,
    len_sec: 878.7609999999995,
    lanemiles: 4149.664000000001,
    delay: 1.3249418e7,
    trkdelay: 1910934.0,
    pti80: 1.21,
    trkpti80: 1.14,
    pti95: 1.29,
    trkpti95: 1.19,
    tti: 1.08,
    trktti: 1.05,
    ghg: 30355.0,
    congcost: 3.63699605e8,
    allspeed: 61.06,
    trkspeed: 62.18,
    ttivmt: 1.5416304e7,
    trkttivmt: 2905104.0,
  },
  {
    agglevel: "corridor",
    regioncode: 4,
    year: 2017,
    count: 1320,
    len_sec: 589.1049999999992,
    lanemiles: 2934.0269999999928,
    delay: 4.2796574e7,
    trkdelay: 5256563.0,
    pti80: 1.48,
    trkpti80: 1.36,
    pti95: 1.61,
    trkpti95: 1.47,
    tti: 1.21,
    trktti: 1.15,
    ghg: 174336.0,
    congcost: 9.50480535e8,
    allspeed: 57.31,
    trkspeed: 59.83,
    ttivmt: 2.0558819e7,
    trkttivmt: 4143977.0,
  },
  {
    agglevel: "corridor",
    regioncode: 4,
    year: 2018,
    count: 554,
    len_sec: 588.246,
    lanemiles: 2928.252999999997,
    delay: 4.4776318e7,
    trkdelay: 3537191.0,
    pti80: 1.52,
    trkpti80: 1.31,
    pti95: 1.66,
    trkpti95: 1.4,
    tti: 1.24,
    trktti: 1.14,
    ghg: 150789.0,
    congcost: 9.50743612e8,
    allspeed: 56.54,
    trkspeed: 59.53,
    ttivmt: 1.8406718e7,
    trkttivmt: 2865962.0,
  },
  {
    agglevel: "corridor",
    regioncode: 4,
    year: 2019,
    count: 576,
    len_sec: 588.4479999999992,
    lanemiles: 2931.7189999999982,
    delay: 3.922631e7,
    trkdelay: 3451512.0,
    pti80: 1.46,
    trkpti80: 1.28,
    pti95: 1.59,
    trkpti95: 1.37,
    tti: 1.21,
    trktti: 1.12,
    ghg: 131440.0,
    congcost: 8.74549107e8,
    allspeed: 57.57,
    trkspeed: 58.06,
    ttivmt: 1.8821788e7,
    trkttivmt: 2962831.0,
  },
  {
    agglevel: "corridor",
    regioncode: 4,
    year: 2020,
    count: 1696,
    len_sec: 588.431,
    lanemiles: 2926.2039999999984,
    delay: 4.718259e7,
    trkdelay: 3598870.0,
    pti80: 1.51,
    trkpti80: 1.29,
    pti95: 1.65,
    trkpti95: 1.4,
    tti: 1.23,
    trktti: 1.12,
    ghg: 152550.0,
    congcost: 1.064587639e9,
    allspeed: 57.69,
    trkspeed: 60.76,
    ttivmt: 1.9525868e7,
    trkttivmt: 3073434.0,
  },
  {
    agglevel: "corridor",
    regioncode: 4,
    year: 2021,
    count: 2062,
    len_sec: 588.8009999999992,
    lanemiles: 2928.022999999991,
    delay: 2.013469e7,
    trkdelay: 2024427.0,
    pti80: 1.26,
    trkpti80: 1.18,
    pti95: 1.35,
    trkpti95: 1.27,
    tti: 1.11,
    trktti: 1.07,
    ghg: 64371.0,
    congcost: 4.77806762e8,
    allspeed: 60.05,
    trkspeed: 61.73,
    ttivmt: 1.7139391e7,
    trkttivmt: 2921949.0,
  },
  {
    agglevel: "corridor",
    regioncode: 4,
    year: 2022,
    count: 2412,
    len_sec: 589.6589999999973,
    lanemiles: 3243.258999999991,
    delay: 3.7389839e7,
    trkdelay: 3796437.0,
    pti80: 1.34,
    trkpti80: 1.24,
    pti95: 1.45,
    trkpti95: 1.33,
    tti: 1.16,
    trktti: 1.1,
    ghg: 117448.0,
    congcost: 9.82085465e8,
    allspeed: 58.94,
    trkspeed: 60.68,
    ttivmt: 1.9330839e7,
    trkttivmt: 3072459.0,
  },
  {
    agglevel: "corridor",
    regioncode: 5,
    year: 2017,
    count: 437,
    len_sec: 284.9050000000002,
    lanemiles: 1496.3729999999991,
    delay: 2.2658498e7,
    trkdelay: 2387299.0,
    pti80: 1.46,
    trkpti80: 1.32,
    pti95: 1.58,
    trkpti95: 1.41,
    tti: 1.21,
    trktti: 1.14,
    ghg: 73130.0,
    congcost: 4.87999655e8,
    allspeed: 56.59,
    trkspeed: 59.77,
    ttivmt: 1.0718245e7,
    trkttivmt: 1880714.0,
  },
  {
    agglevel: "corridor",
    regioncode: 5,
    year: 2018,
    count: 214,
    len_sec: 284.46800000000025,
    lanemiles: 1495.8209999999997,
    delay: 2.1362145e7,
    trkdelay: 1389898.0,
    pti80: 1.42,
    trkpti80: 1.26,
    pti95: 1.51,
    trkpti95: 1.32,
    tti: 1.21,
    trktti: 1.13,
    ghg: 57639.0,
    congcost: 4.41685041e8,
    allspeed: 56.56,
    trkspeed: 59.55,
    ttivmt: 9074153.0,
    trkttivmt: 1094150.0,
  },
  {
    agglevel: "corridor",
    regioncode: 5,
    year: 2019,
    count: 214,
    len_sec: 284.4679999999999,
    lanemiles: 1495.821,
    delay: 1.9605837e7,
    trkdelay: 1333267.0,
    pti80: 1.39,
    trkpti80: 1.24,
    pti95: 1.49,
    trkpti95: 1.3,
    tti: 1.19,
    trktti: 1.11,
    ghg: 54365.0,
    congcost: 4.21643149e8,
    allspeed: 57.44,
    trkspeed: 59.12,
    ttivmt: 9435209.0,
    trkttivmt: 1097218.0,
  },
  {
    agglevel: "corridor",
    regioncode: 5,
    year: 2020,
    count: 590,
    len_sec: 284.4709999999999,
    lanemiles: 1559.219,
    delay: 2.133247e7,
    trkdelay: 1269919.0,
    pti80: 1.43,
    trkpti80: 1.24,
    pti95: 1.53,
    trkpti95: 1.3,
    tti: 1.22,
    trktti: 1.1,
    ghg: 55831.0,
    congcost: 4.65989489e8,
    allspeed: 57.1,
    trkspeed: 61.0,
    ttivmt: 9478776.0,
    trkttivmt: 1226397.0,
  },
  {
    agglevel: "corridor",
    regioncode: 5,
    year: 2021,
    count: 573,
    len_sec: 284.471,
    lanemiles: 1559.2189999999994,
    delay: 9772565.0,
    trkdelay: 526395.0,
    pti80: 1.23,
    trkpti80: 1.14,
    pti95: 1.29,
    trkpti95: 1.2,
    tti: 1.11,
    trktti: 1.05,
    ghg: 22343.0,
    congcost: 2.17055736e8,
    allspeed: 59.81,
    trkspeed: 62.24,
    ttivmt: 8434652.0,
    trkttivmt: 960927.0,
  },
  {
    agglevel: "corridor",
    regioncode: 5,
    year: 2022,
    count: 691,
    len_sec: 285.0309999999999,
    lanemiles: 1650.7850000000008,
    delay: 1.7257286e7,
    trkdelay: 1266926.0,
    pti80: 1.33,
    trkpti80: 1.19,
    pti95: 1.42,
    trkpti95: 1.25,
    tti: 1.16,
    trktti: 1.09,
    ghg: 41147.0,
    congcost: 4.33790449e8,
    allspeed: 58.73,
    trkspeed: 61.0,
    ttivmt: 9386893.0,
    trkttivmt: 1203866.0,
  },
  {
    agglevel: "corridor",
    regioncode: 6,
    year: 2017,
    count: 1022,
    len_sec: 634.7389999999978,
    lanemiles: 2781.5939999999937,
    delay: 4846498.0,
    trkdelay: 651946.0,
    pti80: 1.15,
    trkpti80: 1.1,
    pti95: 1.23,
    trkpti95: 1.15,
    tti: 1.05,
    trktti: 1.02,
    ghg: 12334.0,
    congcost: 1.07880846e8,
    allspeed: 62.44,
    trkspeed: 63.76,
    ttivmt: 1.0963015e7,
    trkttivmt: 2735647.0,
  },
  {
    agglevel: "corridor",
    regioncode: 6,
    year: 2018,
    count: 491,
    len_sec: 634.4779999999996,
    lanemiles: 2777.538999999999,
    delay: 5326608.0,
    trkdelay: 542538.0,
    pti80: 1.18,
    trkpti80: 1.11,
    pti95: 1.25,
    trkpti95: 1.15,
    tti: 1.06,
    trktti: 1.03,
    ghg: 8243.0,
    congcost: 1.14754537e8,
    allspeed: 62.01,
    trkspeed: 63.05,
    ttivmt: 9879703.0,
    trkttivmt: 2029277.0,
  },
  {
    agglevel: "corridor",
    regioncode: 6,
    year: 2019,
    count: 425,
    len_sec: 634.4779999999995,
    lanemiles: 2777.5389999999998,
    delay: 6525741.0,
    trkdelay: 906656.0,
    pti80: 1.19,
    trkpti80: 1.12,
    pti95: 1.27,
    trkpti95: 1.16,
    tti: 1.07,
    trktti: 1.04,
    ghg: 14272.0,
    congcost: 1.54154082e8,
    allspeed: 61.81,
    trkspeed: 61.2,
    ttivmt: 1.0278836e7,
    trkttivmt: 2276828.0,
  },
  {
    agglevel: "corridor",
    regioncode: 6,
    year: 2020,
    count: 1216,
    len_sec: 634.4779999999987,
    lanemiles: 2777.5419999999954,
    delay: 6630266.0,
    trkdelay: 789248.0,
    pti80: 1.18,
    trkpti80: 1.12,
    pti95: 1.25,
    trkpti95: 1.18,
    tti: 1.06,
    trktti: 1.03,
    ghg: 15105.0,
    congcost: 1.57952002e8,
    allspeed: 62.14,
    trkspeed: 63.21,
    ttivmt: 1.0622558e7,
    trkttivmt: 2297026.0,
  },
  {
    agglevel: "corridor",
    regioncode: 6,
    year: 2021,
    count: 1176,
    len_sec: 634.4779999999986,
    lanemiles: 2777.541999999997,
    delay: 2816498.0,
    trkdelay: 298580.0,
    pti80: 1.12,
    trkpti80: 1.09,
    pti95: 1.19,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.02,
    ghg: 3986.0,
    congcost: 6.6222741e7,
    allspeed: 63.35,
    trkspeed: 64.01,
    ttivmt: 9570714.0,
    trkttivmt: 2178195.0,
  },
  {
    agglevel: "corridor",
    regioncode: 6,
    year: 2022,
    count: 1354,
    len_sec: 634.4779999999987,
    lanemiles: 2776.0399999999945,
    delay: 6898038.0,
    trkdelay: 984628.0,
    pti80: 1.19,
    trkpti80: 1.11,
    pti95: 1.27,
    trkpti95: 1.16,
    tti: 1.06,
    trktti: 1.03,
    ghg: 14218.0,
    congcost: 1.88445275e8,
    allspeed: 62.41,
    trkspeed: 63.47,
    ttivmt: 1.079283e7,
    trkttivmt: 2593385.0,
  },
  {
    agglevel: "corridor",
    regioncode: 7,
    year: 2017,
    count: 414,
    len_sec: 223.3960000000001,
    lanemiles: 1039.859,
    delay: 8397986.0,
    trkdelay: 907715.0,
    pti80: 1.34,
    trkpti80: 1.2,
    pti95: 1.45,
    trkpti95: 1.27,
    tti: 1.14,
    trktti: 1.07,
    ghg: 26222.0,
    congcost: 1.8144749e8,
    allspeed: 58.4,
    trkspeed: 61.37,
    ttivmt: 6481011.0,
    trkttivmt: 1512381.0,
  },
  {
    agglevel: "corridor",
    regioncode: 7,
    year: 2018,
    count: 205,
    len_sec: 223.33400000000006,
    lanemiles: 1039.1399999999999,
    delay: 9654158.0,
    trkdelay: 903640.0,
    pti80: 1.41,
    trkpti80: 1.27,
    pti95: 1.53,
    trkpti95: 1.36,
    tti: 1.19,
    trktti: 1.12,
    ghg: 30379.0,
    congcost: 2.0882476e8,
    allspeed: 57.0,
    trkspeed: 59.85,
    ttivmt: 5493271.0,
    trkttivmt: 933964.0,
  },
  {
    agglevel: "corridor",
    regioncode: 7,
    year: 2019,
    count: 173,
    len_sec: 223.3340000000001,
    lanemiles: 1039.1399999999999,
    delay: 9058188.0,
    trkdelay: 747441.0,
    pti80: 1.38,
    trkpti80: 1.22,
    pti95: 1.49,
    trkpti95: 1.29,
    tti: 1.18,
    trktti: 1.09,
    ghg: 25805.0,
    congcost: 1.9918026e8,
    allspeed: 57.6,
    trkspeed: 59.63,
    ttivmt: 5544684.0,
    trkttivmt: 940296.0,
  },
  {
    agglevel: "corridor",
    regioncode: 7,
    year: 2020,
    count: 546,
    len_sec: 223.33299999999994,
    lanemiles: 1039.1399999999999,
    delay: 9672350.0,
    trkdelay: 728741.0,
    pti80: 1.39,
    trkpti80: 1.22,
    pti95: 1.49,
    trkpti95: 1.3,
    tti: 1.18,
    trktti: 1.08,
    ghg: 27692.0,
    congcost: 2.17129525e8,
    allspeed: 58.07,
    trkspeed: 61.58,
    ttivmt: 5859715.0,
    trkttivmt: 1022837.0,
  },
  {
    agglevel: "corridor",
    regioncode: 7,
    year: 2021,
    count: 541,
    len_sec: 223.3329999999999,
    lanemiles: 1039.1399999999996,
    delay: 4716469.0,
    trkdelay: 390016.0,
    pti80: 1.22,
    trkpti80: 1.15,
    pti95: 1.29,
    trkpti95: 1.21,
    tti: 1.09,
    trktti: 1.04,
    ghg: 12814.0,
    congcost: 1.09063153e8,
    allspeed: 60.49,
    trkspeed: 62.69,
    ttivmt: 5408410.0,
    trkttivmt: 1035801.0,
  },
  {
    agglevel: "corridor",
    regioncode: 7,
    year: 2022,
    count: 630,
    len_sec: 223.68600000000006,
    lanemiles: 1116.5609999999988,
    delay: 9968134.0,
    trkdelay: 895494.0,
    pti80: 1.33,
    trkpti80: 1.18,
    pti95: 1.42,
    trkpti95: 1.24,
    tti: 1.16,
    trktti: 1.08,
    ghg: 30177.0,
    congcost: 2.57640626e8,
    allspeed: 58.99,
    trkspeed: 61.96,
    ttivmt: 5950534.0,
    trkttivmt: 1177270.0,
  },
  {
    agglevel: "county",
    regioncode: 1,
    year: 2017,
    count: 861,
    len_sec: 577.2900000000013,
    lanemiles: 1252.277000000002,
    delay: 931444.0,
    trkdelay: 52611.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.06,
    trktti: 1.04,
    ghg: 3507.0,
    congcost: 1.8838329e7,
    allspeed: 51.0,
    trkspeed: 55.19,
    ttivmt: 579214.0,
    trkttivmt: 55688.0,
  },
  {
    agglevel: "county",
    regioncode: 1,
    year: 2018,
    count: 777,
    len_sec: 575.382999999999,
    lanemiles: 1251.1079999999974,
    delay: 832240.0,
    trkdelay: 74569.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.15,
    trkpti95: 1.18,
    tti: 1.06,
    trktti: 1.06,
    ghg: 3648.0,
    congcost: 1.8123898e7,
    allspeed: 51.09,
    trkspeed: 53.25,
    ttivmt: 517725.0,
    trkttivmt: 52629.0,
  },
  {
    agglevel: "county",
    regioncode: 1,
    year: 2019,
    count: 440,
    len_sec: 566.2169999999993,
    lanemiles: 1245.2619999999993,
    delay: 736158.0,
    trkdelay: 80932.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.14,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.06,
    ghg: 3619.0,
    congcost: 1.7232126e7,
    allspeed: 53.32,
    trkspeed: 53.63,
    ttivmt: 503071.0,
    trkttivmt: 52857.0,
  },
  {
    agglevel: "county",
    regioncode: 1,
    year: 2020,
    count: 1361,
    len_sec: 575.731999999999,
    lanemiles: 1273.6109999999965,
    delay: 869450.0,
    trkdelay: 78298.0,
    pti80: 1.12,
    trkpti80: 1.14,
    pti95: 1.15,
    trkpti95: 1.18,
    tti: 1.05,
    trktti: 1.06,
    ghg: 4008.0,
    congcost: 2.0341764e7,
    allspeed: 52.95,
    trkspeed: 54.79,
    ttivmt: 543080.0,
    trkttivmt: 51524.0,
  },
  {
    agglevel: "county",
    regioncode: 1,
    year: 2021,
    count: 1375,
    len_sec: 575.7809999999982,
    lanemiles: 1273.773999999996,
    delay: 483657.0,
    trkdelay: 60916.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.14,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.06,
    ghg: 2398.0,
    congcost: 1.2026951e7,
    allspeed: 53.54,
    trkspeed: 55.35,
    ttivmt: 480890.0,
    trkttivmt: 47653.0,
  },
  {
    agglevel: "county",
    regioncode: 1,
    year: 2022,
    count: 1373,
    len_sec: 575.7839999999984,
    lanemiles: 1273.7979999999998,
    delay: 787268.0,
    trkdelay: 72146.0,
    pti80: 1.13,
    trkpti80: 1.14,
    pti95: 1.21,
    trkpti95: 1.22,
    tti: 1.06,
    trktti: 1.06,
    ghg: 3109.0,
    congcost: 2.0631819e7,
    allspeed: 52.76,
    trkspeed: 55.12,
    ttivmt: 518079.0,
    trkttivmt: 54811.0,
  },
  {
    agglevel: "county",
    regioncode: 2,
    year: 2017,
    count: 165,
    len_sec: 254.54699999999985,
    lanemiles: 588.64,
    delay: 318272.0,
    trkdelay: 30152.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.01,
    ghg: 1489.0,
    congcost: 6854845.0,
    allspeed: 55.71,
    trkspeed: 63.51,
    ttivmt: 310659.0,
    trkttivmt: 129057.0,
  },
  {
    agglevel: "county",
    regioncode: 2,
    year: 2018,
    count: 183,
    len_sec: 265.7029999999998,
    lanemiles: 609.1219999999996,
    delay: 279399.0,
    trkdelay: 44338.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1483.0,
    congcost: 6725158.0,
    allspeed: 58.68,
    trkspeed: 60.39,
    ttivmt: 321064.0,
    trkttivmt: 50044.0,
  },
  {
    agglevel: "county",
    regioncode: 2,
    year: 2019,
    count: 155,
    len_sec: 269.13700000000006,
    lanemiles: 615.9900000000001,
    delay: 289319.0,
    trkdelay: 65416.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1848.0,
    congcost: 7974757.0,
    allspeed: 60.41,
    trkspeed: 60.43,
    ttivmt: 403622.0,
    trkttivmt: 72881.0,
  },
  {
    agglevel: "county",
    regioncode: 2,
    year: 2020,
    count: 201,
    len_sec: 269.1379999999998,
    lanemiles: 617.7359999999998,
    delay: 322739.0,
    trkdelay: 83869.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 2317.0,
    congcost: 9657026.0,
    allspeed: 60.51,
    trkspeed: 61.7,
    ttivmt: 425079.0,
    trkttivmt: 90307.0,
  },
  {
    agglevel: "county",
    regioncode: 2,
    year: 2021,
    count: 205,
    len_sec: 269.14699999999993,
    lanemiles: 617.7539999999999,
    delay: 300928.0,
    trkdelay: 90448.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.15,
    trkpti95: 1.2,
    tti: 1.03,
    trktti: 1.05,
    ghg: 2222.0,
    congcost: 9140101.0,
    allspeed: 60.07,
    trkspeed: 61.17,
    ttivmt: 353305.0,
    trkttivmt: 76575.0,
  },
  {
    agglevel: "county",
    regioncode: 2,
    year: 2022,
    count: 203,
    len_sec: 269.148,
    lanemiles: 617.7559999999999,
    delay: 248279.0,
    trkdelay: 43352.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1397.0,
    congcost: 7301609.0,
    allspeed: 59.74,
    trkspeed: 61.68,
    ttivmt: 398459.0,
    trkttivmt: 86966.0,
  },
  {
    agglevel: "county",
    regioncode: 3,
    year: 2017,
    count: 993,
    len_sec: 433.8849999999992,
    lanemiles: 1056.2399999999998,
    delay: 2069094.0,
    trkdelay: 143682.0,
    pti80: 1.14,
    trkpti80: 1.11,
    pti95: 1.18,
    trkpti95: 1.14,
    tti: 1.08,
    trktti: 1.05,
    ghg: 9526.0,
    congcost: 4.2950502e7,
    allspeed: 47.15,
    trkspeed: 53.16,
    ttivmt: 920335.0,
    trkttivmt: 114015.0,
  },
  {
    agglevel: "county",
    regioncode: 3,
    year: 2018,
    count: 891,
    len_sec: 433.01499999999953,
    lanemiles: 1050.8089999999988,
    delay: 1667497.0,
    trkdelay: 220441.0,
    pti80: 1.14,
    trkpti80: 1.19,
    pti95: 1.17,
    trkpti95: 1.24,
    tti: 1.07,
    trktti: 1.09,
    ghg: 9561.0,
    congcost: 3.8908339e7,
    allspeed: 49.76,
    trkspeed: 50.77,
    ttivmt: 831834.0,
    trkttivmt: 102679.0,
  },
  {
    agglevel: "county",
    regioncode: 3,
    year: 2019,
    count: 370,
    len_sec: 256.65300000000013,
    lanemiles: 695.5829999999996,
    delay: 1396958.0,
    trkdelay: 224563.0,
    pti80: 1.14,
    trkpti80: 1.17,
    pti95: 1.16,
    trkpti95: 1.21,
    tti: 1.07,
    trktti: 1.08,
    ghg: 9029.0,
    congcost: 3.5509003e7,
    allspeed: 51.61,
    trkspeed: 51.31,
    ttivmt: 729540.0,
    trkttivmt: 114397.0,
  },
  {
    agglevel: "county",
    regioncode: 3,
    year: 2020,
    count: 1299,
    len_sec: 437.29599999999965,
    lanemiles: 1065.452999999998,
    delay: 1761069.0,
    trkdelay: 193658.0,
    pti80: 1.13,
    trkpti80: 1.15,
    pti95: 1.16,
    trkpti95: 1.2,
    tti: 1.07,
    trktti: 1.07,
    ghg: 9540.0,
    congcost: 4.2721661e7,
    allspeed: 51.22,
    trkspeed: 53.58,
    ttivmt: 914888.0,
    trkttivmt: 111842.0,
  },
  {
    agglevel: "county",
    regioncode: 3,
    year: 2021,
    count: 1316,
    len_sec: 436.9859999999996,
    lanemiles: 1065.930999999997,
    delay: 1262952.0,
    trkdelay: 149941.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.15,
    trkpti95: 1.18,
    tti: 1.05,
    trktti: 1.06,
    ghg: 6955.0,
    congcost: 3.1251342e7,
    allspeed: 51.85,
    trkspeed: 54.37,
    ttivmt: 834469.0,
    trkttivmt: 106573.0,
  },
  {
    agglevel: "county",
    regioncode: 3,
    year: 2022,
    count: 1313,
    len_sec: 436.9999999999995,
    lanemiles: 1065.9449999999983,
    delay: 1355799.0,
    trkdelay: 162471.0,
    pti80: 1.13,
    trkpti80: 1.13,
    pti95: 1.18,
    trkpti95: 1.19,
    tti: 1.06,
    trktti: 1.05,
    ghg: 6582.0,
    congcost: 3.7105164e7,
    allspeed: 52.07,
    trkspeed: 55.7,
    ttivmt: 908519.0,
    trkttivmt: 142506.0,
  },
  {
    agglevel: "county",
    regioncode: 4,
    year: 2017,
    count: 220,
    len_sec: 104.45000000000003,
    lanemiles: 249.96700000000016,
    delay: 534433.0,
    trkdelay: 22698.0,
    pti80: 1.13,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.18,
    tti: 1.06,
    trktti: 1.03,
    ghg: 2429.0,
    congcost: 1.0666535e7,
    allspeed: 47.67,
    trkspeed: 52.3,
    ttivmt: 238253.0,
    trkttivmt: 19602.0,
  },
  {
    agglevel: "county",
    regioncode: 4,
    year: 2018,
    count: 204,
    len_sec: 104.20899999999993,
    lanemiles: 249.55899999999983,
    delay: 524963.0,
    trkdelay: 40178.0,
    pti80: 1.13,
    trkpti80: 1.19,
    pti95: 1.16,
    trkpti95: 1.26,
    tti: 1.06,
    trktti: 1.08,
    ghg: 2719.0,
    congcost: 1.1316479e7,
    allspeed: 50.27,
    trkspeed: 50.79,
    ttivmt: 221143.0,
    trkttivmt: 16394.0,
  },
  {
    agglevel: "county",
    regioncode: 4,
    year: 2019,
    count: 93,
    len_sec: 73.70000000000002,
    lanemiles: 188.561,
    delay: 315509.0,
    trkdelay: 36624.0,
    pti80: 1.12,
    trkpti80: 1.17,
    pti95: 1.14,
    trkpti95: 1.23,
    tti: 1.04,
    trktti: 1.07,
    ghg: 1869.0,
    congcost: 7531555.0,
    allspeed: 52.95,
    trkspeed: 51.47,
    ttivmt: 191131.0,
    trkttivmt: 15996.0,
  },
  {
    agglevel: "county",
    regioncode: 4,
    year: 2020,
    count: 279,
    len_sec: 104.09499999999996,
    lanemiles: 249.3530000000001,
    delay: 387574.0,
    trkdelay: 19821.0,
    pti80: 1.12,
    trkpti80: 1.14,
    pti95: 1.15,
    trkpti95: 1.2,
    tti: 1.05,
    trktti: 1.06,
    ghg: 1684.0,
    congcost: 8514984.0,
    allspeed: 52.31,
    trkspeed: 55.9,
    ttivmt: 219068.0,
    trkttivmt: 12728.0,
  },
  {
    agglevel: "county",
    regioncode: 4,
    year: 2021,
    count: 275,
    len_sec: 104.09500000000004,
    lanemiles: 249.35299999999998,
    delay: 196604.0,
    trkdelay: 19468.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.2,
    tti: 1.03,
    trktti: 1.05,
    ghg: 933.0,
    congcost: 4724680.0,
    allspeed: 53.15,
    trkspeed: 55.69,
    ttivmt: 209649.0,
    trkttivmt: 13345.0,
  },
  {
    agglevel: "county",
    regioncode: 4,
    year: 2022,
    count: 289,
    len_sec: 104.07899999999991,
    lanemiles: 249.32099999999983,
    delay: 314942.0,
    trkdelay: 14789.0,
    pti80: 1.12,
    trkpti80: 1.14,
    pti95: 1.17,
    trkpti95: 1.23,
    tti: 1.04,
    trktti: 1.05,
    ghg: 1250.0,
    congcost: 7786676.0,
    allspeed: 52.5,
    trkspeed: 55.3,
    ttivmt: 220945.0,
    trkttivmt: 11817.0,
  },
  {
    agglevel: "county",
    regioncode: 5,
    year: 2017,
    count: 303,
    len_sec: 267.4249999999997,
    lanemiles: 555.7279999999996,
    delay: 73481.0,
    trkdelay: 15567.0,
    pti80: 1.07,
    trkpti80: 1.08,
    pti95: 1.09,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 366.0,
    congcost: 1839622.0,
    allspeed: 60.31,
    trkspeed: 60.83,
    ttivmt: 138291.0,
    trkttivmt: 30013.0,
  },
  {
    agglevel: "county",
    regioncode: 5,
    year: 2018,
    count: 395,
    len_sec: 267.027,
    lanemiles: 578.2540000000001,
    delay: 80453.0,
    trkdelay: 18782.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.04,
    ghg: 421.0,
    congcost: 2119915.0,
    allspeed: 58.5,
    trkspeed: 56.59,
    ttivmt: 149513.0,
    trkttivmt: 22484.0,
  },
  {
    agglevel: "county",
    regioncode: 5,
    year: 2019,
    count: 154,
    len_sec: 266.3540000000001,
    lanemiles: 576.9080000000001,
    delay: 81857.0,
    trkdelay: 21260.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 484.0,
    congcost: 2333244.0,
    allspeed: 60.59,
    trkspeed: 59.76,
    ttivmt: 158282.0,
    trkttivmt: 23123.0,
  },
  {
    agglevel: "county",
    regioncode: 5,
    year: 2020,
    count: 524,
    len_sec: 267.2039999999998,
    lanemiles: 578.6080000000004,
    delay: 86609.0,
    trkdelay: 15268.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.03,
    ghg: 394.0,
    congcost: 2287492.0,
    allspeed: 60.62,
    trkspeed: 61.11,
    ttivmt: 158171.0,
    trkttivmt: 22499.0,
  },
  {
    agglevel: "county",
    regioncode: 5,
    year: 2021,
    count: 506,
    len_sec: 267.204,
    lanemiles: 578.6080000000002,
    delay: 72932.0,
    trkdelay: 14060.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.03,
    ghg: 316.0,
    congcost: 1949772.0,
    allspeed: 60.34,
    trkspeed: 61.29,
    ttivmt: 150613.0,
    trkttivmt: 22411.0,
  },
  {
    agglevel: "county",
    regioncode: 5,
    year: 2022,
    count: 508,
    len_sec: 267.204,
    lanemiles: 578.6079999999998,
    delay: 138098.0,
    trkdelay: 32081.0,
    pti80: 1.11,
    trkpti80: 1.16,
    pti95: 1.17,
    trkpti95: 1.27,
    tti: 1.03,
    trktti: 1.06,
    ghg: 680.0,
    congcost: 4305464.0,
    allspeed: 59.85,
    trkspeed: 60.21,
    ttivmt: 163028.0,
    trkttivmt: 25058.0,
  },
  {
    agglevel: "county",
    regioncode: 6,
    year: 2017,
    count: 138,
    len_sec: 152.73399999999998,
    lanemiles: 376.395,
    delay: 17371.0,
    trkdelay: 4588.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.09,
    trkpti95: 1.09,
    tti: 1.0,
    trktti: 1.0,
    ghg: 65.0,
    congcost: 457603.0,
    allspeed: 63.04,
    trkspeed: 64.39,
    ttivmt: 140329.0,
    trkttivmt: 89174.0,
  },
  {
    agglevel: "county",
    regioncode: 6,
    year: 2018,
    count: 110,
    len_sec: 153.79300000000006,
    lanemiles: 378.3980000000002,
    delay: 16115.0,
    trkdelay: 5148.0,
    pti80: 1.07,
    trkpti80: 1.04,
    pti95: 1.09,
    trkpti95: 1.04,
    tti: 1.0,
    trktti: 1.01,
    ghg: 89.0,
    congcost: 467215.0,
    allspeed: 62.33,
    trkspeed: 62.37,
    ttivmt: 123788.0,
    trkttivmt: 42884.0,
  },
  {
    agglevel: "county",
    regioncode: 6,
    year: 2019,
    count: 69,
    len_sec: 153.42600000000004,
    lanemiles: 377.664,
    delay: 15746.0,
    trkdelay: 7534.0,
    pti80: 1.08,
    trkpti80: 1.04,
    pti95: 1.11,
    trkpti95: 1.05,
    tti: 1.0,
    trktti: 1.01,
    ghg: 123.0,
    congcost: 566332.0,
    allspeed: 63.15,
    trkspeed: 61.54,
    ttivmt: 145846.0,
    trkttivmt: 48265.0,
  },
  {
    agglevel: "county",
    regioncode: 6,
    year: 2020,
    count: 185,
    len_sec: 153.91699999999994,
    lanemiles: 378.6759999999999,
    delay: 21223.0,
    trkdelay: 4331.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.0,
    trktti: 1.0,
    ghg: 101.0,
    congcost: 583036.0,
    allspeed: 62.97,
    trkspeed: 63.25,
    ttivmt: 157390.0,
    trkttivmt: 43097.0,
  },
  {
    agglevel: "county",
    regioncode: 6,
    year: 2021,
    count: 180,
    len_sec: 153.91599999999994,
    lanemiles: 378.6580000000002,
    delay: 22570.0,
    trkdelay: 6675.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 133.0,
    congcost: 676525.0,
    allspeed: 62.88,
    trkspeed: 63.12,
    ttivmt: 156798.0,
    trkttivmt: 49125.0,
  },
  {
    agglevel: "county",
    regioncode: 6,
    year: 2022,
    count: 181,
    len_sec: 153.91099999999992,
    lanemiles: 378.64599999999973,
    delay: 46281.0,
    trkdelay: 23661.0,
    pti80: 1.11,
    trkpti80: 1.07,
    pti95: 1.14,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.02,
    ghg: 319.0,
    congcost: 1891506.0,
    allspeed: 62.81,
    trkspeed: 62.48,
    ttivmt: 174456.0,
    trkttivmt: 56740.0,
  },
  {
    agglevel: "county",
    regioncode: 7,
    year: 2017,
    count: 543,
    len_sec: 425.89099999999974,
    lanemiles: 1008.0909999999999,
    delay: 782890.0,
    trkdelay: 109250.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.02,
    ghg: 4463.0,
    congcost: 1.8027942e7,
    allspeed: 57.3,
    trkspeed: 60.29,
    ttivmt: 825381.0,
    trkttivmt: 201445.0,
  },
  {
    agglevel: "county",
    regioncode: 7,
    year: 2018,
    count: 598,
    len_sec: 427.4639999999997,
    lanemiles: 1011.1019999999999,
    delay: 719404.0,
    trkdelay: 136365.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 4752.0,
    congcost: 1.8167608e7,
    allspeed: 58.4,
    trkspeed: 59.15,
    ttivmt: 778177.0,
    trkttivmt: 143168.0,
  },
  {
    agglevel: "county",
    regioncode: 7,
    year: 2019,
    count: 225,
    len_sec: 339.13800000000003,
    lanemiles: 835.1989999999994,
    delay: 554137.0,
    trkdelay: 132702.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 3985.0,
    congcost: 1.5601633e7,
    allspeed: 59.76,
    trkspeed: 58.85,
    ttivmt: 800418.0,
    trkttivmt: 152504.0,
  },
  {
    agglevel: "county",
    regioncode: 7,
    year: 2020,
    count: 794,
    len_sec: 427.4859999999999,
    lanemiles: 1011.1090000000007,
    delay: 730852.0,
    trkdelay: 128796.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.03,
    ghg: 4405.0,
    congcost: 1.9523096e7,
    allspeed: 59.0,
    trkspeed: 60.17,
    ttivmt: 865118.0,
    trkttivmt: 154719.0,
  },
  {
    agglevel: "county",
    regioncode: 7,
    year: 2021,
    count: 797,
    len_sec: 427.46299999999997,
    lanemiles: 1011.0860000000001,
    delay: 389502.0,
    trkdelay: 73141.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2041.0,
    congcost: 1.040022e7,
    allspeed: 58.79,
    trkspeed: 61.34,
    ttivmt: 738129.0,
    trkttivmt: 144711.0,
  },
  {
    agglevel: "county",
    regioncode: 7,
    year: 2022,
    count: 854,
    len_sec: 427.59599999999995,
    lanemiles: 1021.6660000000007,
    delay: 639752.0,
    trkdelay: 116518.0,
    pti80: 1.12,
    trkpti80: 1.09,
    pti95: 1.18,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 2763.0,
    congcost: 1.8750477e7,
    allspeed: 58.96,
    trkspeed: 61.01,
    ttivmt: 860429.0,
    trkttivmt: 159948.0,
  },
  {
    agglevel: "county",
    regioncode: 8,
    year: 2017,
    count: 449,
    len_sec: 309.1519999999998,
    lanemiles: 672.6489999999997,
    delay: 400459.0,
    trkdelay: 46942.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.01,
    ghg: 1578.0,
    congcost: 8829631.0,
    allspeed: 59.77,
    trkspeed: 62.28,
    ttivmt: 668094.0,
    trkttivmt: 146448.0,
  },
  {
    agglevel: "county",
    regioncode: 8,
    year: 2018,
    count: 520,
    len_sec: 309.64099999999985,
    lanemiles: 674.7350000000001,
    delay: 453201.0,
    trkdelay: 60368.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2079.0,
    congcost: 1.0484085e7,
    allspeed: 58.26,
    trkspeed: 60.61,
    ttivmt: 592940.0,
    trkttivmt: 104107.0,
  },
  {
    agglevel: "county",
    regioncode: 8,
    year: 2019,
    count: 211,
    len_sec: 241.98499999999993,
    lanemiles: 539.8149999999998,
    delay: 357123.0,
    trkdelay: 68511.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1940.0,
    congcost: 9346921.0,
    allspeed: 59.95,
    trkspeed: 59.1,
    ttivmt: 599224.0,
    trkttivmt: 112845.0,
  },
  {
    agglevel: "county",
    regioncode: 8,
    year: 2020,
    count: 738,
    len_sec: 309.8949999999998,
    lanemiles: 692.6229999999999,
    delay: 561749.0,
    trkdelay: 80489.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2581.0,
    congcost: 1.4168348e7,
    allspeed: 59.53,
    trkspeed: 61.42,
    ttivmt: 634140.0,
    trkttivmt: 113292.0,
  },
  {
    agglevel: "county",
    regioncode: 8,
    year: 2021,
    count: 735,
    len_sec: 309.81599999999963,
    lanemiles: 692.4990000000004,
    delay: 372429.0,
    trkdelay: 63755.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 1710.0,
    congcost: 9708165.0,
    allspeed: 59.39,
    trkspeed: 60.89,
    ttivmt: 587825.0,
    trkttivmt: 99953.0,
  },
  {
    agglevel: "county",
    regioncode: 8,
    year: 2022,
    count: 763,
    len_sec: 312.1479999999993,
    lanemiles: 701.8269999999991,
    delay: 603874.0,
    trkdelay: 132037.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.05,
    ghg: 2047.0,
    congcost: 1.8269377e7,
    allspeed: 58.55,
    trkspeed: 58.95,
    ttivmt: 687191.0,
    trkttivmt: 116937.0,
  },
  {
    agglevel: "county",
    regioncode: 9,
    year: 2017,
    count: 167,
    len_sec: 248.2380000000001,
    lanemiles: 536.4010000000003,
    delay: 54376.0,
    trkdelay: 7525.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.03,
    ghg: 230.0,
    congcost: 1235999.0,
    allspeed: 58.76,
    trkspeed: 59.58,
    ttivmt: 92796.0,
    trkttivmt: 14360.0,
  },
  {
    agglevel: "county",
    regioncode: 9,
    year: 2018,
    count: 129,
    len_sec: 248.1210000000001,
    lanemiles: 536.986,
    delay: 43564.0,
    trkdelay: 10745.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.03,
    ghg: 293.0,
    congcost: 1175647.0,
    allspeed: 56.08,
    trkspeed: 57.81,
    ttivmt: 86439.0,
    trkttivmt: 18777.0,
  },
  {
    agglevel: "county",
    regioncode: 9,
    year: 2019,
    count: 90,
    len_sec: 248.14900000000003,
    lanemiles: 537.0419999999999,
    delay: 45959.0,
    trkdelay: 13624.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.04,
    ghg: 339.0,
    congcost: 1380407.0,
    allspeed: 59.2,
    trkspeed: 58.88,
    ttivmt: 85480.0,
    trkttivmt: 17043.0,
  },
  {
    agglevel: "county",
    regioncode: 9,
    year: 2020,
    count: 203,
    len_sec: 248.15399999999994,
    lanemiles: 537.0519999999998,
    delay: 47693.0,
    trkdelay: 11880.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.03,
    ghg: 294.0,
    congcost: 1397758.0,
    allspeed: 58.7,
    trkspeed: 59.65,
    ttivmt: 89238.0,
    trkttivmt: 15723.0,
  },
  {
    agglevel: "county",
    regioncode: 9,
    year: 2021,
    count: 202,
    len_sec: 248.1539999999999,
    lanemiles: 537.0520000000002,
    delay: 36855.0,
    trkdelay: 12234.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 275.0,
    congcost: 1154307.0,
    allspeed: 58.97,
    trkspeed: 59.62,
    ttivmt: 90136.0,
    trkttivmt: 20020.0,
  },
  {
    agglevel: "county",
    regioncode: 9,
    year: 2022,
    count: 198,
    len_sec: 248.20400000000006,
    lanemiles: 537.176,
    delay: 62708.0,
    trkdelay: 14689.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 340.0,
    congcost: 1966168.0,
    allspeed: 59.17,
    trkspeed: 59.82,
    ttivmt: 101611.0,
    trkttivmt: 18027.0,
  },
  {
    agglevel: "county",
    regioncode: 10,
    year: 2017,
    count: 274,
    len_sec: 197.64600000000007,
    lanemiles: 419.485,
    delay: 187309.0,
    trkdelay: 25949.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.05,
    ghg: 901.0,
    congcost: 4274445.0,
    allspeed: 55.37,
    trkspeed: 54.49,
    ttivmt: 153527.0,
    trkttivmt: 20025.0,
  },
  {
    agglevel: "county",
    regioncode: 10,
    year: 2018,
    count: 276,
    len_sec: 197.33100000000005,
    lanemiles: 418.5140000000001,
    delay: 234154.0,
    trkdelay: 12291.0,
    pti80: 1.09,
    trkpti80: 1.15,
    pti95: 1.12,
    trkpti95: 1.21,
    tti: 1.04,
    trktti: 1.06,
    ghg: 777.0,
    congcost: 4789937.0,
    allspeed: 47.54,
    trkspeed: 38.92,
    ttivmt: 169210.0,
    trkttivmt: 8845.0,
  },
  {
    agglevel: "county",
    regioncode: 10,
    year: 2019,
    count: 76,
    len_sec: 159.458,
    lanemiles: 342.2300000000001,
    delay: 198312.0,
    trkdelay: 17841.0,
    pti80: 1.1,
    trkpti80: 1.18,
    pti95: 1.12,
    trkpti95: 1.27,
    tti: 1.04,
    trktti: 1.08,
    ghg: 719.0,
    congcost: 4453037.0,
    allspeed: 55.65,
    trkspeed: 53.36,
    ttivmt: 162660.0,
    trkttivmt: 10169.0,
  },
  {
    agglevel: "county",
    regioncode: 10,
    year: 2020,
    count: 421,
    len_sec: 197.34500000000003,
    lanemiles: 418.5399999999998,
    delay: 226658.0,
    trkdelay: 15117.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.05,
    ghg: 795.0,
    congcost: 5061259.0,
    allspeed: 55.08,
    trkspeed: 55.09,
    ttivmt: 177455.0,
    trkttivmt: 12427.0,
  },
  {
    agglevel: "county",
    regioncode: 10,
    year: 2021,
    count: 414,
    len_sec: 197.34500000000008,
    lanemiles: 418.53999999999974,
    delay: 166669.0,
    trkdelay: 11224.0,
    pti80: 1.12,
    trkpti80: 1.14,
    pti95: 1.16,
    trkpti95: 1.23,
    tti: 1.04,
    trktti: 1.05,
    ghg: 466.0,
    congcost: 3790845.0,
    allspeed: 54.93,
    trkspeed: 54.57,
    ttivmt: 180173.0,
    trkttivmt: 9834.0,
  },
  {
    agglevel: "county",
    regioncode: 10,
    year: 2022,
    count: 444,
    len_sec: 197.34600000000015,
    lanemiles: 418.12100000000015,
    delay: 327025.0,
    trkdelay: 15729.0,
    pti80: 1.12,
    trkpti80: 1.16,
    pti95: 1.19,
    trkpti95: 1.26,
    tti: 1.05,
    trktti: 1.07,
    ghg: 962.0,
    congcost: 8007175.0,
    allspeed: 53.4,
    trkspeed: 53.81,
    ttivmt: 193790.0,
    trkttivmt: 8797.0,
  },
  {
    agglevel: "county",
    regioncode: 11,
    year: 2017,
    count: 517,
    len_sec: 375.9439999999996,
    lanemiles: 902.6619999999997,
    delay: 1378075.0,
    trkdelay: 121620.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.06,
    trktti: 1.05,
    ghg: 4668.0,
    congcost: 2.9068475e7,
    allspeed: 55.89,
    trkspeed: 58.78,
    ttivmt: 991653.0,
    trkttivmt: 125044.0,
  },
  {
    agglevel: "county",
    regioncode: 11,
    year: 2018,
    count: 524,
    len_sec: 373.42599999999976,
    lanemiles: 897.6519999999994,
    delay: 1440619.0,
    trkdelay: 116521.0,
    pti80: 1.14,
    trkpti80: 1.17,
    pti95: 1.2,
    trkpti95: 1.25,
    tti: 1.07,
    trktti: 1.08,
    ghg: 4905.0,
    congcost: 3.0719517e7,
    allspeed: 56.0,
    trkspeed: 56.65,
    ttivmt: 947584.0,
    trkttivmt: 80024.0,
  },
  {
    agglevel: "county",
    regioncode: 11,
    year: 2019,
    count: 239,
    len_sec: 277.9219999999999,
    lanemiles: 704.6409999999997,
    delay: 1182824.0,
    trkdelay: 110962.0,
    pti80: 1.12,
    trkpti80: 1.16,
    pti95: 1.16,
    trkpti95: 1.22,
    tti: 1.05,
    trktti: 1.07,
    ghg: 3996.0,
    congcost: 2.664092e7,
    allspeed: 57.86,
    trkspeed: 56.27,
    ttivmt: 980892.0,
    trkttivmt: 82837.0,
  },
  {
    agglevel: "county",
    regioncode: 11,
    year: 2020,
    count: 916,
    len_sec: 373.85700000000014,
    lanemiles: 893.0929999999997,
    delay: 1419461.0,
    trkdelay: 131520.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.16,
    trkpti95: 1.2,
    tti: 1.05,
    trktti: 1.07,
    ghg: 4783.0,
    congcost: 3.2935517e7,
    allspeed: 57.71,
    trkspeed: 58.16,
    ttivmt: 1070107.0,
    trkttivmt: 93462.0,
  },
  {
    agglevel: "county",
    regioncode: 11,
    year: 2021,
    count: 907,
    len_sec: 371.32799999999946,
    lanemiles: 890.1549999999997,
    delay: 1008556.0,
    trkdelay: 124088.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.16,
    trkpti95: 1.21,
    tti: 1.05,
    trktti: 1.07,
    ghg: 3775.0,
    congcost: 2.4736356e7,
    allspeed: 57.74,
    trkspeed: 58.42,
    ttivmt: 906343.0,
    trkttivmt: 96389.0,
  },
  {
    agglevel: "county",
    regioncode: 11,
    year: 2022,
    count: 1046,
    len_sec: 375.5119999999993,
    lanemiles: 903.5769999999994,
    delay: 1625046.0,
    trkdelay: 143848.0,
    pti80: 1.14,
    trkpti80: 1.16,
    pti95: 1.21,
    trkpti95: 1.23,
    tti: 1.06,
    trktti: 1.07,
    ghg: 4759.0,
    congcost: 4.1948909e7,
    allspeed: 56.9,
    trkspeed: 58.46,
    ttivmt: 1076754.0,
    trkttivmt: 102867.0,
  },
  {
    agglevel: "county",
    regioncode: 12,
    year: 2017,
    count: 196,
    len_sec: 239.7869999999999,
    lanemiles: 555.9339999999999,
    delay: 33581.0,
    trkdelay: 8147.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 186.0,
    congcost: 875961.0,
    allspeed: 61.31,
    trkspeed: 63.64,
    ttivmt: 75531.0,
    trkttivmt: 39291.0,
  },
  {
    agglevel: "county",
    regioncode: 12,
    year: 2018,
    count: 218,
    len_sec: 238.7469999999999,
    lanemiles: 553.7279999999997,
    delay: 34795.0,
    trkdelay: 12413.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 273.0,
    congcost: 1064697.0,
    allspeed: 60.37,
    trkspeed: 60.39,
    ttivmt: 80512.0,
    trkttivmt: 23740.0,
  },
  {
    agglevel: "county",
    regioncode: 12,
    year: 2019,
    count: 107,
    len_sec: 243.59400000000002,
    lanemiles: 563.4219999999999,
    delay: 37684.0,
    trkdelay: 14144.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.02,
    ghg: 314.0,
    congcost: 1235832.0,
    allspeed: 61.94,
    trkspeed: 60.34,
    ttivmt: 93672.0,
    trkttivmt: 24540.0,
  },
  {
    agglevel: "county",
    regioncode: 12,
    year: 2020,
    count: 311,
    len_sec: 243.61499999999987,
    lanemiles: 563.2959999999998,
    delay: 47905.0,
    trkdelay: 8133.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.01,
    ghg: 211.0,
    congcost: 1252025.0,
    allspeed: 61.21,
    trkspeed: 63.24,
    ttivmt: 95036.0,
    trkttivmt: 22734.0,
  },
  {
    agglevel: "county",
    regioncode: 12,
    year: 2021,
    count: 304,
    len_sec: 243.61499999999995,
    lanemiles: 563.2959999999998,
    delay: 28974.0,
    trkdelay: 7194.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 137.0,
    congcost: 823525.0,
    allspeed: 61.59,
    trkspeed: 63.13,
    ttivmt: 95907.0,
    trkttivmt: 23510.0,
  },
  {
    agglevel: "county",
    regioncode: 12,
    year: 2022,
    count: 304,
    len_sec: 243.61499999999995,
    lanemiles: 563.2959999999995,
    delay: 54021.0,
    trkdelay: 11823.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 234.0,
    congcost: 1654060.0,
    allspeed: 60.99,
    trkspeed: 62.67,
    ttivmt: 99402.0,
    trkttivmt: 24111.0,
  },
  {
    agglevel: "county",
    regioncode: 13,
    year: 2017,
    count: 512,
    len_sec: 347.13199999999995,
    lanemiles: 784.5000000000003,
    delay: 452286.0,
    trkdelay: 54209.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.03,
    ghg: 2467.0,
    congcost: 1.0139196e7,
    allspeed: 51.88,
    trkspeed: 57.91,
    ttivmt: 316614.0,
    trkttivmt: 71947.0,
  },
  {
    agglevel: "county",
    regioncode: 13,
    year: 2018,
    count: 609,
    len_sec: 346.13400000000024,
    lanemiles: 782.2670000000004,
    delay: 270392.0,
    trkdelay: 48372.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.05,
    ghg: 1621.0,
    congcost: 6715890.0,
    allspeed: 54.68,
    trkspeed: 56.12,
    ttivmt: 255132.0,
    trkttivmt: 41654.0,
  },
  {
    agglevel: "county",
    regioncode: 13,
    year: 2019,
    count: 190,
    len_sec: 208.38699999999994,
    lanemiles: 507.38700000000006,
    delay: 225505.0,
    trkdelay: 61351.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.05,
    ghg: 1697.0,
    congcost: 6604889.0,
    allspeed: 56.81,
    trkspeed: 56.16,
    ttivmt: 221255.0,
    trkttivmt: 46386.0,
  },
  {
    agglevel: "county",
    regioncode: 13,
    year: 2020,
    count: 791,
    len_sec: 346.208,
    lanemiles: 787.5700000000005,
    delay: 310562.0,
    trkdelay: 53240.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.04,
    ghg: 1887.0,
    congcost: 8251415.0,
    allspeed: 56.13,
    trkspeed: 57.58,
    ttivmt: 270881.0,
    trkttivmt: 41922.0,
  },
  {
    agglevel: "county",
    regioncode: 13,
    year: 2021,
    count: 790,
    len_sec: 346.2509999999995,
    lanemiles: 787.6730000000002,
    delay: 216446.0,
    trkdelay: 46687.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1487.0,
    congcost: 6028114.0,
    allspeed: 56.31,
    trkspeed: 57.94,
    ttivmt: 252073.0,
    trkttivmt: 44871.0,
  },
  {
    agglevel: "county",
    regioncode: 13,
    year: 2022,
    count: 804,
    len_sec: 346.3000000000005,
    lanemiles: 787.7710000000011,
    delay: 268057.0,
    trkdelay: 42078.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.18,
    trkpti95: 1.21,
    tti: 1.04,
    trktti: 1.05,
    ghg: 1348.0,
    congcost: 7688261.0,
    allspeed: 55.79,
    trkspeed: 55.99,
    ttivmt: 257234.0,
    trkttivmt: 36225.0,
  },
  {
    agglevel: "county",
    regioncode: 14,
    year: 2017,
    count: 1635,
    len_sec: 881.4900000000009,
    lanemiles: 2238.8579999999997,
    delay: 8928445.0,
    trkdelay: 420410.0,
    pti80: 1.16,
    trkpti80: 1.11,
    pti95: 1.22,
    trkpti95: 1.2,
    tti: 1.07,
    trktti: 1.04,
    ghg: 35378.0,
    congcost: 1.78403904e8,
    allspeed: 48.02,
    trkspeed: 57.27,
    ttivmt: 4550990.0,
    trkttivmt: 485139.0,
  },
  {
    agglevel: "county",
    regioncode: 14,
    year: 2018,
    count: 1630,
    len_sec: 879.3769999999985,
    lanemiles: 2237.824,
    delay: 7054278.0,
    trkdelay: 416194.0,
    pti80: 1.16,
    trkpti80: 1.12,
    pti95: 1.23,
    trkpti95: 1.2,
    tti: 1.07,
    trktti: 1.05,
    ghg: 29687.0,
    congcost: 1.4687069e8,
    allspeed: 52.06,
    trkspeed: 57.8,
    ttivmt: 3436497.0,
    trkttivmt: 355724.0,
  },
  {
    agglevel: "county",
    regioncode: 14,
    year: 2019,
    count: 680,
    len_sec: 561.8550000000001,
    lanemiles: 1546.4799999999998,
    delay: 5526552.0,
    trkdelay: 443053.0,
    pti80: 1.14,
    trkpti80: 1.13,
    pti95: 1.19,
    trkpti95: 1.2,
    tti: 1.06,
    trktti: 1.05,
    ghg: 25260.0,
    congcost: 1.23630683e8,
    allspeed: 53.91,
    trkspeed: 55.3,
    ttivmt: 3177846.0,
    trkttivmt: 346520.0,
  },
  {
    agglevel: "county",
    regioncode: 14,
    year: 2020,
    count: 2564,
    len_sec: 883.3249999999975,
    lanemiles: 2262.5509999999936,
    delay: 6264877.0,
    trkdelay: 350258.0,
    pti80: 1.13,
    trkpti80: 1.11,
    pti95: 1.18,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.03,
    ghg: 27887.0,
    congcost: 1.38807499e8,
    allspeed: 52.41,
    trkspeed: 59.63,
    ttivmt: 3706573.0,
    trkttivmt: 408764.0,
  },
  {
    agglevel: "county",
    regioncode: 14,
    year: 2021,
    count: 2587,
    len_sec: 880.097999999999,
    lanemiles: 2258.9769999999935,
    delay: 3331429.0,
    trkdelay: 244344.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.17,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.03,
    ghg: 15409.0,
    congcost: 7.7471812e7,
    allspeed: 52.68,
    trkspeed: 59.48,
    ttivmt: 3200667.0,
    trkttivmt: 337359.0,
  },
  {
    agglevel: "county",
    regioncode: 14,
    year: 2022,
    count: 2682,
    len_sec: 883.809999999996,
    lanemiles: 2339.050999999998,
    delay: 6252930.0,
    trkdelay: 374375.0,
    pti80: 1.15,
    trkpti80: 1.12,
    pti95: 1.24,
    trkpti95: 1.24,
    tti: 1.06,
    trktti: 1.05,
    ghg: 27673.0,
    congcost: 1.5807249e8,
    allspeed: 52.14,
    trkspeed: 58.65,
    ttivmt: 3568053.0,
    trkttivmt: 337800.0,
  },
  {
    agglevel: "county",
    regioncode: 15,
    year: 2017,
    count: 5541,
    len_sec: 2163.075000000014,
    lanemiles: 6605.170999999981,
    delay: 7.0889494e7,
    trkdelay: 3405792.0,
    pti80: 1.43,
    trkpti80: 1.46,
    pti95: 1.56,
    trkpti95: 1.63,
    tti: 1.21,
    trktti: 1.21,
    ghg: 279351.0,
    congcost: 1.418130787e9,
    allspeed: 43.82,
    trkspeed: 48.96,
    ttivmt: 2.0893978e7,
    trkttivmt: 1409514.0,
  },
  {
    agglevel: "county",
    regioncode: 15,
    year: 2018,
    count: 4745,
    len_sec: 2180.9940000000047,
    lanemiles: 6680.452000000012,
    delay: 7.2700067e7,
    trkdelay: 3203732.0,
    pti80: 1.53,
    trkpti80: 1.54,
    pti95: 1.69,
    trkpti95: 1.74,
    tti: 1.27,
    trktti: 1.25,
    ghg: 282025.0,
    congcost: 1.475827675e9,
    allspeed: 43.99,
    trkspeed: 47.47,
    ttivmt: 1.7380729e7,
    trkttivmt: 977554.0,
  },
  {
    agglevel: "county",
    regioncode: 15,
    year: 2019,
    count: 2628,
    len_sec: 1884.9799999999982,
    lanemiles: 6047.963999999991,
    delay: 6.3659015e7,
    trkdelay: 3281045.0,
    pti80: 1.45,
    trkpti80: 1.46,
    pti95: 1.58,
    trkpti95: 1.64,
    tti: 1.23,
    trktti: 1.22,
    ghg: 251703.0,
    congcost: 1.355152799e9,
    allspeed: 45.23,
    trkspeed: 47.34,
    ttivmt: 1.7297479e7,
    trkttivmt: 1035304.0,
  },
  {
    agglevel: "county",
    regioncode: 15,
    year: 2020,
    count: 7549,
    len_sec: 2164.7489999999875,
    lanemiles: 6636.737000000012,
    delay: 6.6141635e7,
    trkdelay: 3163428.0,
    pti80: 1.46,
    trkpti80: 1.47,
    pti95: 1.59,
    trkpti95: 1.64,
    tti: 1.23,
    trktti: 1.22,
    ghg: 259072.0,
    congcost: 1.438406385e9,
    allspeed: 45.25,
    trkspeed: 49.56,
    ttivmt: 1.8135729e7,
    trkttivmt: 1066428.0,
  },
  {
    agglevel: "county",
    regioncode: 15,
    year: 2021,
    count: 7567,
    len_sec: 2170.1989999999932,
    lanemiles: 6648.327000000004,
    delay: 3.0843511e7,
    trkdelay: 1801918.0,
    pti80: 1.23,
    trkpti80: 1.25,
    pti95: 1.3,
    trkpti95: 1.38,
    tti: 1.11,
    trktti: 1.11,
    ghg: 123239.0,
    congcost: 6.99857657e8,
    allspeed: 47.65,
    trkspeed: 52.4,
    ttivmt: 1.6127683e7,
    trkttivmt: 960607.0,
  },
  {
    agglevel: "county",
    regioncode: 15,
    year: 2022,
    count: 8034,
    len_sec: 2214.126999999996,
    lanemiles: 6761.913999999997,
    delay: 4.6714118e7,
    trkdelay: 2379004.0,
    pti80: 1.28,
    trkpti80: 1.3,
    pti95: 1.36,
    trkpti95: 1.43,
    tti: 1.13,
    trktti: 1.13,
    ghg: 180776.0,
    congcost: 1.159543359e9,
    allspeed: 47.41,
    trkspeed: 51.96,
    ttivmt: 1.8119448e7,
    trkttivmt: 1056330.0,
  },
  {
    agglevel: "county",
    regioncode: 16,
    year: 2017,
    count: 178,
    len_sec: 213.35400000000013,
    lanemiles: 521.1080000000004,
    delay: 147058.0,
    trkdelay: 20793.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.09,
    trkpti95: 1.09,
    tti: 1.02,
    trktti: 1.02,
    ghg: 514.0,
    congcost: 3334549.0,
    allspeed: 60.28,
    trkspeed: 61.23,
    ttivmt: 256422.0,
    trkttivmt: 34695.0,
  },
  {
    agglevel: "county",
    regioncode: 16,
    year: 2018,
    count: 248,
    len_sec: 212.93,
    lanemiles: 519.8320000000001,
    delay: 136382.0,
    trkdelay: 17846.0,
    pti80: 1.08,
    trkpti80: 1.12,
    pti95: 1.1,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.05,
    ghg: 439.0,
    congcost: 3110524.0,
    allspeed: 58.5,
    trkspeed: 57.43,
    ttivmt: 252106.0,
    trkttivmt: 20002.0,
  },
  {
    agglevel: "county",
    regioncode: 16,
    year: 2019,
    count: 69,
    len_sec: 122.94099999999997,
    lanemiles: 339.7389999999999,
    delay: 115562.0,
    trkdelay: 18805.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.1,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.04,
    ghg: 406.0,
    congcost: 2865440.0,
    allspeed: 60.79,
    trkspeed: 58.77,
    ttivmt: 256611.0,
    trkttivmt: 22288.0,
  },
  {
    agglevel: "county",
    regioncode: 16,
    year: 2020,
    count: 411,
    len_sec: 213.1770000000001,
    lanemiles: 521.3259999999999,
    delay: 151433.0,
    trkdelay: 15539.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.1,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.04,
    ghg: 499.0,
    congcost: 3569952.0,
    allspeed: 60.21,
    trkspeed: 58.03,
    ttivmt: 289566.0,
    trkttivmt: 17571.0,
  },
  {
    agglevel: "county",
    regioncode: 16,
    year: 2021,
    count: 430,
    len_sec: 213.18999999999983,
    lanemiles: 521.3980000000003,
    delay: 167343.0,
    trkdelay: 25031.0,
    pti80: 1.08,
    trkpti80: 1.11,
    pti95: 1.1,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.04,
    ghg: 685.0,
    congcost: 4251823.0,
    allspeed: 60.05,
    trkspeed: 58.24,
    ttivmt: 316417.0,
    trkttivmt: 27927.0,
  },
  {
    agglevel: "county",
    regioncode: 16,
    year: 2022,
    count: 439,
    len_sec: 213.26799999999997,
    lanemiles: 521.66,
    delay: 220310.0,
    trkdelay: 24617.0,
    pti80: 1.08,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 697.0,
    congcost: 5876966.0,
    allspeed: 59.87,
    trkspeed: 59.05,
    ttivmt: 302837.0,
    trkttivmt: 23464.0,
  },
  {
    agglevel: "county",
    regioncode: 17,
    year: 2017,
    count: 103,
    len_sec: 192.90699999999995,
    lanemiles: 385.8139999999999,
    delay: 16838.0,
    trkdelay: 10626.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 74.0,
    congcost: 631783.0,
    allspeed: 62.26,
    trkspeed: 62.79,
    ttivmt: 21232.0,
    trkttivmt: 21114.0,
  },
  {
    agglevel: "county",
    regioncode: 17,
    year: 2018,
    count: 146,
    len_sec: 192.965,
    lanemiles: 385.93,
    delay: 14147.0,
    trkdelay: 3704.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 41.0,
    congcost: 378358.0,
    allspeed: 59.66,
    trkspeed: 60.32,
    ttivmt: 24777.0,
    trkttivmt: 7058.0,
  },
  {
    agglevel: "county",
    regioncode: 17,
    year: 2019,
    count: 45,
    len_sec: 193.085,
    lanemiles: 386.17,
    delay: 18806.0,
    trkdelay: 6701.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 90.0,
    congcost: 590002.0,
    allspeed: 61.01,
    trkspeed: 60.42,
    ttivmt: 31426.0,
    trkttivmt: 8071.0,
  },
  {
    agglevel: "county",
    regioncode: 17,
    year: 2020,
    count: 230,
    len_sec: 193.11099999999996,
    lanemiles: 386.2219999999999,
    delay: 15884.0,
    trkdelay: 4040.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 68.0,
    congcost: 463320.0,
    allspeed: 60.72,
    trkspeed: 60.57,
    ttivmt: 27645.0,
    trkttivmt: 6971.0,
  },
  {
    agglevel: "county",
    regioncode: 17,
    year: 2021,
    count: 226,
    len_sec: 193.1110000000001,
    lanemiles: 386.2220000000002,
    delay: 11833.0,
    trkdelay: 2689.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.17,
    trkpti95: 1.17,
    tti: 1.02,
    trktti: 1.03,
    ghg: 40.0,
    congcost: 327387.0,
    allspeed: 61.27,
    trkspeed: 60.89,
    ttivmt: 26307.0,
    trkttivmt: 5550.0,
  },
  {
    agglevel: "county",
    regioncode: 17,
    year: 2022,
    count: 227,
    len_sec: 193.16500000000005,
    lanemiles: 386.3300000000001,
    delay: 24052.0,
    trkdelay: 7546.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.19,
    trkpti95: 1.24,
    tti: 1.03,
    trktti: 1.05,
    ghg: 140.0,
    congcost: 822799.0,
    allspeed: 61.08,
    trkspeed: 60.54,
    ttivmt: 29965.0,
    trkttivmt: 6738.0,
  },
  {
    agglevel: "county",
    regioncode: 18,
    year: 2017,
    count: 463,
    len_sec: 367.38999999999965,
    lanemiles: 738.0309999999995,
    delay: 184059.0,
    trkdelay: 26585.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.04,
    ghg: 712.0,
    congcost: 4205498.0,
    allspeed: 57.0,
    trkspeed: 58.95,
    ttivmt: 193005.0,
    trkttivmt: 34392.0,
  },
  {
    agglevel: "county",
    regioncode: 18,
    year: 2018,
    count: 541,
    len_sec: 366.6769999999999,
    lanemiles: 737.2619999999998,
    delay: 163207.0,
    trkdelay: 23866.0,
    pti80: 1.09,
    trkpti80: 1.13,
    pti95: 1.11,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.05,
    ghg: 769.0,
    congcost: 3845214.0,
    allspeed: 50.23,
    trkspeed: 48.65,
    ttivmt: 200019.0,
    trkttivmt: 21082.0,
  },
  {
    agglevel: "county",
    regioncode: 18,
    year: 2019,
    count: 188,
    len_sec: 365.90000000000003,
    lanemiles: 735.708,
    delay: 179934.0,
    trkdelay: 30707.0,
    pti80: 1.1,
    trkpti80: 1.15,
    pti95: 1.13,
    trkpti95: 1.21,
    tti: 1.03,
    trktti: 1.06,
    ghg: 897.0,
    congcost: 4571450.0,
    allspeed: 57.11,
    trkspeed: 55.92,
    ttivmt: 203838.0,
    trkttivmt: 21090.0,
  },
  {
    agglevel: "county",
    regioncode: 18,
    year: 2020,
    count: 751,
    len_sec: 366.87299999999976,
    lanemiles: 736.3709999999995,
    delay: 231796.0,
    trkdelay: 34669.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.06,
    ghg: 1066.0,
    congcost: 5907593.0,
    allspeed: 56.65,
    trkspeed: 56.53,
    ttivmt: 222113.0,
    trkttivmt: 25599.0,
  },
  {
    agglevel: "county",
    regioncode: 18,
    year: 2021,
    count: 748,
    len_sec: 366.8729999999998,
    lanemiles: 736.3709999999996,
    delay: 183307.0,
    trkdelay: 29508.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.05,
    ghg: 743.0,
    congcost: 4711058.0,
    allspeed: 57.09,
    trkspeed: 57.65,
    ttivmt: 227550.0,
    trkttivmt: 28375.0,
  },
  {
    agglevel: "county",
    regioncode: 18,
    year: 2022,
    count: 760,
    len_sec: 366.88399999999973,
    lanemiles: 738.8849999999994,
    delay: 322049.0,
    trkdelay: 42268.0,
    pti80: 1.13,
    trkpti80: 1.18,
    pti95: 1.22,
    trkpti95: 1.31,
    tti: 1.05,
    trktti: 1.07,
    ghg: 1260.0,
    congcost: 8864691.0,
    allspeed: 55.38,
    trkspeed: 55.44,
    ttivmt: 220702.0,
    trkttivmt: 25361.0,
  },
  {
    agglevel: "county",
    regioncode: 19,
    year: 2017,
    count: 1314,
    len_sec: 637.9729999999987,
    lanemiles: 1522.4909999999961,
    delay: 2404475.0,
    trkdelay: 98546.0,
    pti80: 1.13,
    trkpti80: 1.08,
    pti95: 1.17,
    trkpti95: 1.13,
    tti: 1.05,
    trktti: 1.01,
    ghg: 9990.0,
    congcost: 4.7705605e7,
    allspeed: 51.94,
    trkspeed: 61.81,
    ttivmt: 1595168.0,
    trkttivmt: 332157.0,
  },
  {
    agglevel: "county",
    regioncode: 19,
    year: 2018,
    count: 1219,
    len_sec: 635.6080000000003,
    lanemiles: 1514.3980000000006,
    delay: 1660866.0,
    trkdelay: 134900.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.02,
    ghg: 8402.0,
    congcost: 3.5989432e7,
    allspeed: 54.31,
    trkspeed: 60.83,
    ttivmt: 1278102.0,
    trkttivmt: 242425.0,
  },
  {
    agglevel: "county",
    regioncode: 19,
    year: 2019,
    count: 783,
    len_sec: 631.5499999999996,
    lanemiles: 1517.882,
    delay: 2077693.0,
    trkdelay: 194837.0,
    pti80: 1.13,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.15,
    tti: 1.05,
    trktti: 1.03,
    ghg: 11072.0,
    congcost: 4.7770156e7,
    allspeed: 54.26,
    trkspeed: 59.61,
    ttivmt: 1356386.0,
    trkttivmt: 249627.0,
  },
  {
    agglevel: "county",
    regioncode: 19,
    year: 2020,
    count: 1919,
    len_sec: 635.4539999999986,
    lanemiles: 1522.9299999999985,
    delay: 2171422.0,
    trkdelay: 119095.0,
    pti80: 1.12,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.13,
    tti: 1.05,
    trktti: 1.02,
    ghg: 9922.0,
    congcost: 4.8096562e7,
    allspeed: 54.13,
    trkspeed: 62.21,
    ttivmt: 1358708.0,
    trkttivmt: 259262.0,
  },
  {
    agglevel: "county",
    regioncode: 19,
    year: 2021,
    count: 1894,
    len_sec: 635.7520000000001,
    lanemiles: 1526.4879999999991,
    delay: 1380340.0,
    trkdelay: 88745.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.01,
    ghg: 6331.0,
    congcost: 3.1730542e7,
    allspeed: 54.8,
    trkspeed: 62.68,
    ttivmt: 1310718.0,
    trkttivmt: 268139.0,
  },
  {
    agglevel: "county",
    regioncode: 19,
    year: 2022,
    count: 1935,
    len_sec: 636.0389999999983,
    lanemiles: 1522.3439999999966,
    delay: 1927458.0,
    trkdelay: 122951.0,
    pti80: 1.12,
    trkpti80: 1.08,
    pti95: 1.18,
    trkpti95: 1.11,
    tti: 1.04,
    trktti: 1.01,
    ghg: 8803.0,
    congcost: 4.9052729e7,
    allspeed: 53.72,
    trkspeed: 62.41,
    ttivmt: 1371479.0,
    trkttivmt: 315743.0,
  },
  {
    agglevel: "county",
    regioncode: 20,
    year: 2017,
    count: 1713,
    len_sec: 909.0589999999985,
    lanemiles: 2266.100999999997,
    delay: 8735071.0,
    trkdelay: 285308.0,
    pti80: 1.18,
    trkpti80: 1.15,
    pti95: 1.23,
    trkpti95: 1.2,
    tti: 1.09,
    trktti: 1.06,
    ghg: 33902.0,
    congcost: 1.70727162e8,
    allspeed: 45.32,
    trkspeed: 51.44,
    ttivmt: 3835963.0,
    trkttivmt: 209391.0,
  },
  {
    agglevel: "county",
    regioncode: 20,
    year: 2018,
    count: 1617,
    len_sec: 944.3050000000004,
    lanemiles: 2275.2100000000028,
    delay: 6938825.0,
    trkdelay: 442396.0,
    pti80: 1.18,
    trkpti80: 1.25,
    pti95: 1.23,
    trkpti95: 1.33,
    tti: 1.09,
    trktti: 1.12,
    ghg: 29983.0,
    congcost: 1.45623952e8,
    allspeed: 49.21,
    trkspeed: 50.47,
    ttivmt: 3036695.0,
    trkttivmt: 178507.0,
  },
  {
    agglevel: "county",
    regioncode: 20,
    year: 2019,
    count: 669,
    len_sec: 521.7979999999995,
    lanemiles: 1454.9929999999968,
    delay: 6938033.0,
    trkdelay: 477830.0,
    pti80: 1.17,
    trkpti80: 1.23,
    pti95: 1.2,
    trkpti95: 1.31,
    tti: 1.09,
    trktti: 1.11,
    ghg: 32365.0,
    congcost: 1.52841038e8,
    allspeed: 49.29,
    trkspeed: 49.69,
    ttivmt: 2759825.0,
    trkttivmt: 180109.0,
  },
  {
    agglevel: "county",
    regioncode: 20,
    year: 2020,
    count: 2573,
    len_sec: 910.7339999999957,
    lanemiles: 2301.307,
    delay: 8686321.0,
    trkdelay: 427754.0,
    pti80: 1.18,
    trkpti80: 1.2,
    pti95: 1.22,
    trkpti95: 1.27,
    tti: 1.09,
    trktti: 1.1,
    ghg: 37384.0,
    congcost: 1.90160058e8,
    allspeed: 47.29,
    trkspeed: 51.62,
    ttivmt: 3209136.0,
    trkttivmt: 177203.0,
  },
  {
    agglevel: "county",
    regioncode: 20,
    year: 2021,
    count: 2579,
    len_sec: 909.5919999999952,
    lanemiles: 2304.5429999999947,
    delay: 4569919.0,
    trkdelay: 321302.0,
    pti80: 1.14,
    trkpti80: 1.18,
    pti95: 1.17,
    trkpti95: 1.28,
    tti: 1.06,
    trktti: 1.08,
    ghg: 20983.0,
    congcost: 1.05832658e8,
    allspeed: 48.31,
    trkspeed: 53.02,
    ttivmt: 2881457.0,
    trkttivmt: 165831.0,
  },
  {
    agglevel: "county",
    regioncode: 20,
    year: 2022,
    count: 2621,
    len_sec: 913.3289999999964,
    lanemiles: 2323.172999999991,
    delay: 6681056.0,
    trkdelay: 383119.0,
    pti80: 1.17,
    trkpti80: 1.19,
    pti95: 1.23,
    trkpti95: 1.31,
    tti: 1.08,
    trktti: 1.08,
    ghg: 29410.0,
    congcost: 1.68290813e8,
    allspeed: 47.08,
    trkspeed: 51.91,
    ttivmt: 2902400.0,
    trkttivmt: 187534.0,
  },
  {
    agglevel: "county",
    regioncode: 21,
    year: 2017,
    count: 1213,
    len_sec: 608.0770000000002,
    lanemiles: 1541.2289999999998,
    delay: 8010875.0,
    trkdelay: 239638.0,
    pti80: 1.21,
    trkpti80: 1.16,
    pti95: 1.27,
    trkpti95: 1.22,
    tti: 1.11,
    trktti: 1.06,
    ghg: 34260.0,
    congcost: 1.56541463e8,
    allspeed: 43.38,
    trkspeed: 51.71,
    ttivmt: 2473142.0,
    trkttivmt: 154663.0,
  },
  {
    agglevel: "county",
    regioncode: 21,
    year: 2018,
    count: 1132,
    len_sec: 606.4739999999998,
    lanemiles: 1549.9930000000006,
    delay: 6172718.0,
    trkdelay: 341851.0,
    pti80: 1.23,
    trkpti80: 1.23,
    pti95: 1.29,
    trkpti95: 1.31,
    tti: 1.12,
    trktti: 1.1,
    ghg: 29896.0,
    congcost: 1.28664636e8,
    allspeed: 46.63,
    trkspeed: 51.61,
    ttivmt: 1798018.0,
    trkttivmt: 131668.0,
  },
  {
    agglevel: "county",
    regioncode: 21,
    year: 2019,
    count: 506,
    len_sec: 395.85199999999935,
    lanemiles: 1117.2069999999994,
    delay: 6563516.0,
    trkdelay: 388665.0,
    pti80: 1.23,
    trkpti80: 1.22,
    pti95: 1.28,
    trkpti95: 1.29,
    tti: 1.11,
    trktti: 1.1,
    ghg: 32177.0,
    congcost: 1.42931869e8,
    allspeed: 47.0,
    trkspeed: 50.82,
    ttivmt: 1834530.0,
    trkttivmt: 139241.0,
  },
  {
    agglevel: "county",
    regioncode: 21,
    year: 2020,
    count: 1792,
    len_sec: 606.9010000000002,
    lanemiles: 1545.8079999999975,
    delay: 5887934.0,
    trkdelay: 275426.0,
    pti80: 1.2,
    trkpti80: 1.17,
    pti95: 1.25,
    trkpti95: 1.22,
    tti: 1.1,
    trktti: 1.08,
    ghg: 27342.0,
    congcost: 1.2885005e8,
    allspeed: 46.42,
    trkspeed: 54.1,
    ttivmt: 2010699.0,
    trkttivmt: 139999.0,
  },
  {
    agglevel: "county",
    regioncode: 21,
    year: 2021,
    count: 1793,
    len_sec: 600.9529999999988,
    lanemiles: 1539.5739999999998,
    delay: 3326552.0,
    trkdelay: 201204.0,
    pti80: 1.17,
    trkpti80: 1.16,
    pti95: 1.22,
    trkpti95: 1.25,
    tti: 1.07,
    trktti: 1.07,
    ghg: 16119.0,
    congcost: 7.6262368e7,
    allspeed: 47.46,
    trkspeed: 55.49,
    ttivmt: 1608906.0,
    trkttivmt: 122207.0,
  },
  {
    agglevel: "county",
    regioncode: 21,
    year: 2022,
    count: 1822,
    len_sec: 601.5189999999982,
    lanemiles: 1542.2309999999982,
    delay: 3999019.0,
    trkdelay: 197827.0,
    pti80: 1.17,
    trkpti80: 1.15,
    pti95: 1.24,
    trkpti95: 1.25,
    tti: 1.07,
    trktti: 1.05,
    ghg: 18641.0,
    congcost: 9.9992536e7,
    allspeed: 46.84,
    trkspeed: 54.08,
    ttivmt: 1779109.0,
    trkttivmt: 131101.0,
  },
  {
    agglevel: "county",
    regioncode: 22,
    year: 2017,
    count: 215,
    len_sec: 397.9399999999998,
    lanemiles: 827.133999999999,
    delay: 161235.0,
    trkdelay: 20021.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 716.0,
    congcost: 3605061.0,
    allspeed: 51.5,
    trkspeed: 61.33,
    ttivmt: 122588.0,
    trkttivmt: 42879.0,
  },
  {
    agglevel: "county",
    regioncode: 22,
    year: 2018,
    count: 348,
    len_sec: 397.4199999999998,
    lanemiles: 824.1379999999999,
    delay: 101415.0,
    trkdelay: 16025.0,
    pti80: 1.08,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.04,
    ghg: 561.0,
    congcost: 2442532.0,
    allspeed: 37.77,
    trkspeed: 39.73,
    ttivmt: 100707.0,
    trkttivmt: 12625.0,
  },
  {
    agglevel: "county",
    regioncode: 22,
    year: 2019,
    count: 77,
    len_sec: 306.5350000000001,
    lanemiles: 642.3679999999998,
    delay: 127137.0,
    trkdelay: 12547.0,
    pti80: 1.11,
    trkpti80: 1.16,
    pti95: 1.14,
    trkpti95: 1.23,
    tti: 1.04,
    trktti: 1.06,
    ghg: 484.0,
    congcost: 2897882.0,
    allspeed: 55.13,
    trkspeed: 55.55,
    ttivmt: 105114.0,
    trkttivmt: 8763.0,
  },
  {
    agglevel: "county",
    regioncode: 22,
    year: 2020,
    count: 447,
    len_sec: 397.8039999999996,
    lanemiles: 823.9319999999988,
    delay: 152858.0,
    trkdelay: 8451.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.03,
    ghg: 544.0,
    congcost: 3356477.0,
    allspeed: 54.72,
    trkspeed: 56.84,
    ttivmt: 115801.0,
    trkttivmt: 9068.0,
  },
  {
    agglevel: "county",
    regioncode: 22,
    year: 2021,
    count: 449,
    len_sec: 397.80699999999945,
    lanemiles: 823.9379999999989,
    delay: 78680.0,
    trkdelay: 5681.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 317.0,
    congcost: 1820626.0,
    allspeed: 54.11,
    trkspeed: 56.99,
    ttivmt: 105686.0,
    trkttivmt: 9051.0,
  },
  {
    agglevel: "county",
    regioncode: 22,
    year: 2022,
    count: 449,
    len_sec: 397.797,
    lanemiles: 823.9179999999999,
    delay: 111180.0,
    trkdelay: 11889.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.19,
    trkpti95: 1.23,
    tti: 1.04,
    trktti: 1.05,
    ghg: 416.0,
    congcost: 2967708.0,
    allspeed: 54.56,
    trkspeed: 56.29,
    ttivmt: 107194.0,
    trkttivmt: 9702.0,
  },
  {
    agglevel: "county",
    regioncode: 23,
    year: 2017,
    count: 116,
    len_sec: 170.871,
    lanemiles: 344.182,
    delay: 12864.0,
    trkdelay: 3363.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.02,
    ghg: 42.0,
    congcost: 337415.0,
    allspeed: 60.06,
    trkspeed: 61.73,
    ttivmt: 17820.0,
    trkttivmt: 6180.0,
  },
  {
    agglevel: "county",
    regioncode: 23,
    year: 2018,
    count: 109,
    len_sec: 170.901,
    lanemiles: 344.202,
    delay: 11154.0,
    trkdelay: 2553.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 35.0,
    congcost: 287742.0,
    allspeed: 55.97,
    trkspeed: 58.69,
    ttivmt: 17282.0,
    trkttivmt: 3289.0,
  },
  {
    agglevel: "county",
    regioncode: 23,
    year: 2019,
    count: 70,
    len_sec: 170.92700000000002,
    lanemiles: 344.254,
    delay: 13278.0,
    trkdelay: 4546.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.12,
    trkpti95: 1.21,
    tti: 1.03,
    trktti: 1.05,
    ghg: 59.0,
    congcost: 410413.0,
    allspeed: 60.15,
    trkspeed: 59.72,
    ttivmt: 20503.0,
    trkttivmt: 3992.0,
  },
  {
    agglevel: "county",
    regioncode: 23,
    year: 2020,
    count: 167,
    len_sec: 170.92499999999998,
    lanemiles: 344.25,
    delay: 24110.0,
    trkdelay: 4155.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 79.0,
    congcost: 628275.0,
    allspeed: 58.92,
    trkspeed: 59.24,
    ttivmt: 25653.0,
    trkttivmt: 4395.0,
  },
  {
    agglevel: "county",
    regioncode: 23,
    year: 2021,
    count: 170,
    len_sec: 170.925,
    lanemiles: 343.946,
    delay: 17438.0,
    trkdelay: 5093.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 77.0,
    congcost: 517599.0,
    allspeed: 58.77,
    trkspeed: 59.44,
    ttivmt: 24601.0,
    trkttivmt: 6393.0,
  },
  {
    agglevel: "county",
    regioncode: 23,
    year: 2022,
    count: 173,
    len_sec: 170.9709999999999,
    lanemiles: 344.0379999999998,
    delay: 41754.0,
    trkdelay: 8622.0,
    pti80: 1.15,
    trkpti80: 1.18,
    pti95: 1.28,
    trkpti95: 1.29,
    tti: 1.06,
    trktti: 1.07,
    ghg: 168.0,
    congcost: 1256981.0,
    allspeed: 57.6,
    trkspeed: 58.5,
    ttivmt: 25165.0,
    trkttivmt: 4782.0,
  },
  {
    agglevel: "county",
    regioncode: 24,
    year: 2017,
    count: 207,
    len_sec: 147.4250000000001,
    lanemiles: 373.4370000000002,
    delay: 131446.0,
    trkdelay: 37379.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.01,
    trktti: 1.01,
    ghg: 978.0,
    congcost: 3626741.0,
    allspeed: 60.2,
    trkspeed: 60.87,
    ttivmt: 246119.0,
    trkttivmt: 76696.0,
  },
  {
    agglevel: "county",
    regioncode: 24,
    year: 2018,
    count: 227,
    len_sec: 147.32299999999995,
    lanemiles: 373.095,
    delay: 149740.0,
    trkdelay: 12902.0,
    pti80: 1.09,
    trkpti80: 1.06,
    pti95: 1.13,
    trkpti95: 1.07,
    tti: 1.02,
    trktti: 1.01,
    ghg: 593.0,
    congcost: 3233455.0,
    allspeed: 59.93,
    trkspeed: 62.73,
    ttivmt: 260692.0,
    trkttivmt: 56031.0,
  },
  {
    agglevel: "county",
    regioncode: 24,
    year: 2019,
    count: 81,
    len_sec: 78.218,
    lanemiles: 235.01300000000003,
    delay: 134828.0,
    trkdelay: 18626.0,
    pti80: 1.09,
    trkpti80: 1.06,
    pti95: 1.13,
    trkpti95: 1.06,
    tti: 1.01,
    trktti: 1.01,
    ghg: 510.0,
    congcost: 3238426.0,
    allspeed: 61.25,
    trkspeed: 62.24,
    ttivmt: 245678.0,
    trkttivmt: 55667.0,
  },
  {
    agglevel: "county",
    regioncode: 24,
    year: 2020,
    count: 312,
    len_sec: 147.48600000000013,
    lanemiles: 379.3680000000003,
    delay: 120037.0,
    trkdelay: 13694.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 515.0,
    congcost: 2899256.0,
    allspeed: 61.06,
    trkspeed: 63.44,
    ttivmt: 263255.0,
    trkttivmt: 62258.0,
  },
  {
    agglevel: "county",
    regioncode: 24,
    year: 2021,
    count: 313,
    len_sec: 147.47000000000003,
    lanemiles: 387.40099999999995,
    delay: 104825.0,
    trkdelay: 5012.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.0,
    ghg: 408.0,
    congcost: 2345979.0,
    allspeed: 60.41,
    trkspeed: 63.37,
    ttivmt: 217876.0,
    trkttivmt: 56021.0,
  },
  {
    agglevel: "county",
    regioncode: 24,
    year: 2022,
    count: 313,
    len_sec: 147.45300000000017,
    lanemiles: 387.3670000000001,
    delay: 132900.0,
    trkdelay: 18781.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.17,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.01,
    ghg: 521.0,
    congcost: 3698799.0,
    allspeed: 60.64,
    trkspeed: 63.09,
    ttivmt: 257137.0,
    trkttivmt: 63678.0,
  },
  {
    agglevel: "county",
    regioncode: 25,
    year: 2017,
    count: 653,
    len_sec: 459.36699999999996,
    lanemiles: 1033.4290000000008,
    delay: 947089.0,
    trkdelay: 35710.0,
    pti80: 1.14,
    trkpti80: 1.12,
    pti95: 1.18,
    trkpti95: 1.15,
    tti: 1.06,
    trktti: 1.04,
    ghg: 4082.0,
    congcost: 1.8736219e7,
    allspeed: 46.87,
    trkspeed: 55.55,
    ttivmt: 467447.0,
    trkttivmt: 37181.0,
  },
  {
    agglevel: "county",
    regioncode: 25,
    year: 2018,
    count: 735,
    len_sec: 458.1579999999997,
    lanemiles: 1031.2730000000001,
    delay: 592223.0,
    trkdelay: 46892.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.14,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.06,
    ghg: 2826.0,
    congcost: 1.2766039e7,
    allspeed: 50.12,
    trkspeed: 52.48,
    ttivmt: 359244.0,
    trkttivmt: 29612.0,
  },
  {
    agglevel: "county",
    regioncode: 25,
    year: 2019,
    count: 363,
    len_sec: 416.6009999999997,
    lanemiles: 945.5609999999995,
    delay: 560606.0,
    trkdelay: 55545.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.15,
    trkpti95: 1.2,
    tti: 1.05,
    trktti: 1.07,
    ghg: 2930.0,
    congcost: 1.2981747e7,
    allspeed: 53.12,
    trkspeed: 53.52,
    ttivmt: 357437.0,
    trkttivmt: 32285.0,
  },
  {
    agglevel: "county",
    regioncode: 25,
    year: 2020,
    count: 1034,
    len_sec: 459.750999999999,
    lanemiles: 1040.7249999999979,
    delay: 578895.0,
    trkdelay: 55730.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.06,
    ghg: 2933.0,
    congcost: 1.3733422e7,
    allspeed: 53.34,
    trkspeed: 54.89,
    ttivmt: 386569.0,
    trkttivmt: 34078.0,
  },
  {
    agglevel: "county",
    regioncode: 25,
    year: 2021,
    count: 1025,
    len_sec: 459.8779999999992,
    lanemiles: 1042.0400000000002,
    delay: 263875.0,
    trkdelay: 36039.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1403.0,
    congcost: 6667318.0,
    allspeed: 53.78,
    trkspeed: 55.0,
    ttivmt: 332897.0,
    trkttivmt: 32647.0,
  },
  {
    agglevel: "county",
    regioncode: 25,
    year: 2022,
    count: 1022,
    len_sec: 460.0409999999993,
    lanemiles: 1042.531999999999,
    delay: 483705.0,
    trkdelay: 35108.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.19,
    trkpti95: 1.2,
    tti: 1.05,
    trktti: 1.05,
    ghg: 1942.0,
    congcost: 1.2383506e7,
    allspeed: 52.9,
    trkspeed: 54.84,
    ttivmt: 356574.0,
    trkttivmt: 30099.0,
  },
  {
    agglevel: "county",
    regioncode: 26,
    year: 2017,
    count: 286,
    len_sec: 244.4919999999999,
    lanemiles: 551.43,
    delay: 184567.0,
    trkdelay: 19016.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 623.0,
    congcost: 3972422.0,
    allspeed: 59.08,
    trkspeed: 60.97,
    ttivmt: 294397.0,
    trkttivmt: 38154.0,
  },
  {
    agglevel: "county",
    regioncode: 26,
    year: 2018,
    count: 362,
    len_sec: 245.60499999999968,
    lanemiles: 558.9639999999994,
    delay: 205601.0,
    trkdelay: 27170.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.04,
    ghg: 858.0,
    congcost: 4734353.0,
    allspeed: 57.92,
    trkspeed: 58.6,
    ttivmt: 316841.0,
    trkttivmt: 35228.0,
  },
  {
    agglevel: "county",
    regioncode: 26,
    year: 2019,
    count: 89,
    len_sec: 130.995,
    lanemiles: 329.7439999999999,
    delay: 148565.0,
    trkdelay: 26061.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 720.0,
    congcost: 3789235.0,
    allspeed: 59.91,
    trkspeed: 57.46,
    ttivmt: 299691.0,
    trkttivmt: 35806.0,
  },
  {
    agglevel: "county",
    regioncode: 26,
    year: 2020,
    count: 467,
    len_sec: 245.61999999999995,
    lanemiles: 566.0059999999995,
    delay: 199674.0,
    trkdelay: 28368.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.03,
    ghg: 926.0,
    congcost: 5032706.0,
    allspeed: 58.84,
    trkspeed: 58.97,
    ttivmt: 328260.0,
    trkttivmt: 41392.0,
  },
  {
    agglevel: "county",
    regioncode: 26,
    year: 2021,
    count: 462,
    len_sec: 245.62199999999987,
    lanemiles: 566.0099999999994,
    delay: 125008.0,
    trkdelay: 20522.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 620.0,
    congcost: 3247421.0,
    allspeed: 59.11,
    trkspeed: 59.25,
    ttivmt: 296804.0,
    trkttivmt: 38640.0,
  },
  {
    agglevel: "county",
    regioncode: 26,
    year: 2022,
    count: 460,
    len_sec: 245.6139999999998,
    lanemiles: 565.9939999999995,
    delay: 226863.0,
    trkdelay: 36231.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1024.0,
    congcost: 6490341.0,
    allspeed: 58.98,
    trkspeed: 59.34,
    ttivmt: 316968.0,
    trkttivmt: 39943.0,
  },
  {
    agglevel: "county",
    regioncode: 27,
    year: 2017,
    count: 304,
    len_sec: 354.3190000000001,
    lanemiles: 925.9449999999996,
    delay: 920999.0,
    trkdelay: 96725.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.05,
    trktti: 1.04,
    ghg: 4197.0,
    congcost: 2.0082495e7,
    allspeed: 53.77,
    trkspeed: 58.26,
    ttivmt: 676954.0,
    trkttivmt: 105996.0,
  },
  {
    agglevel: "county",
    regioncode: 27,
    year: 2018,
    count: 339,
    len_sec: 354.0839999999995,
    lanemiles: 925.8340000000001,
    delay: 815768.0,
    trkdelay: 96037.0,
    pti80: 1.12,
    trkpti80: 1.16,
    pti95: 1.15,
    trkpti95: 1.2,
    tti: 1.05,
    trktti: 1.07,
    ghg: 3988.0,
    congcost: 1.8555421e7,
    allspeed: 54.08,
    trkspeed: 54.92,
    ttivmt: 598607.0,
    trkttivmt: 58644.0,
  },
  {
    agglevel: "county",
    regioncode: 27,
    year: 2019,
    count: 138,
    len_sec: 213.53899999999996,
    lanemiles: 644.7210000000001,
    delay: 742270.0,
    trkdelay: 74460.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.13,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.06,
    ghg: 3327.0,
    congcost: 1.7086707e7,
    allspeed: 56.44,
    trkspeed: 56.63,
    ttivmt: 609366.0,
    trkttivmt: 55249.0,
  },
  {
    agglevel: "county",
    regioncode: 27,
    year: 2020,
    count: 644,
    len_sec: 356.43399999999974,
    lanemiles: 919.9969999999995,
    delay: 937005.0,
    trkdelay: 62881.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.05,
    ghg: 3811.0,
    congcost: 2.105765e7,
    allspeed: 55.65,
    trkspeed: 57.21,
    ttivmt: 685271.0,
    trkttivmt: 49499.0,
  },
  {
    agglevel: "county",
    regioncode: 27,
    year: 2021,
    count: 690,
    len_sec: 356.4689999999993,
    lanemiles: 917.0399999999997,
    delay: 737886.0,
    trkdelay: 89584.0,
    pti80: 1.12,
    trkpti80: 1.14,
    pti95: 1.15,
    trkpti95: 1.21,
    tti: 1.04,
    trktti: 1.06,
    ghg: 3501.0,
    congcost: 1.8219117e7,
    allspeed: 55.77,
    trkspeed: 56.6,
    ttivmt: 698147.0,
    trkttivmt: 71193.0,
  },
  {
    agglevel: "county",
    regioncode: 27,
    year: 2022,
    count: 745,
    len_sec: 356.6339999999997,
    lanemiles: 918.0809999999981,
    delay: 1074965.0,
    trkdelay: 87504.0,
    pti80: 1.13,
    trkpti80: 1.15,
    pti95: 1.18,
    trkpti95: 1.21,
    tti: 1.06,
    trktti: 1.06,
    ghg: 4320.0,
    congcost: 2.7833951e7,
    allspeed: 54.5,
    trkspeed: 56.49,
    ttivmt: 671428.0,
    trkttivmt: 59312.0,
  },
  {
    agglevel: "county",
    regioncode: 28,
    year: 2017,
    count: 402,
    len_sec: 332.75699999999995,
    lanemiles: 771.828,
    delay: 769340.0,
    trkdelay: 62407.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.03,
    ghg: 3280.0,
    congcost: 1.6186791e7,
    allspeed: 53.93,
    trkspeed: 59.24,
    ttivmt: 563828.0,
    trkttivmt: 74746.0,
  },
  {
    agglevel: "county",
    regioncode: 28,
    year: 2018,
    count: 487,
    len_sec: 332.55199999999985,
    lanemiles: 771.5759999999997,
    delay: 584590.0,
    trkdelay: 63346.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.04,
    ghg: 2608.0,
    congcost: 1.3077465e7,
    allspeed: 54.92,
    trkspeed: 57.99,
    ttivmt: 548080.0,
    trkttivmt: 71494.0,
  },
  {
    agglevel: "county",
    regioncode: 28,
    year: 2019,
    count: 188,
    len_sec: 212.9910000000001,
    lanemiles: 532.4529999999999,
    delay: 504906.0,
    trkdelay: 65054.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.04,
    ghg: 2444.0,
    congcost: 1.2119913e7,
    allspeed: 57.49,
    trkspeed: 58.67,
    ttivmt: 510778.0,
    trkttivmt: 71605.0,
  },
  {
    agglevel: "county",
    regioncode: 28,
    year: 2020,
    count: 775,
    len_sec: 332.73499999999996,
    lanemiles: 772.0399999999993,
    delay: 613276.0,
    trkdelay: 66215.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.03,
    ghg: 2780.0,
    congcost: 1.4718802e7,
    allspeed: 56.63,
    trkspeed: 59.5,
    ttivmt: 577583.0,
    trkttivmt: 77561.0,
  },
  {
    agglevel: "county",
    regioncode: 28,
    year: 2021,
    count: 764,
    len_sec: 332.735,
    lanemiles: 765.4259999999994,
    delay: 420666.0,
    trkdelay: 52630.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1838.0,
    congcost: 1.0395061e7,
    allspeed: 56.1,
    trkspeed: 58.6,
    ttivmt: 491874.0,
    trkttivmt: 62951.0,
  },
  {
    agglevel: "county",
    regioncode: 28,
    year: 2022,
    count: 810,
    len_sec: 331.14699999999976,
    lanemiles: 765.1669999999992,
    delay: 635779.0,
    trkdelay: 64243.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.18,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.04,
    ghg: 2401.0,
    congcost: 1.6823339e7,
    allspeed: 55.93,
    trkspeed: 58.8,
    ttivmt: 568930.0,
    trkttivmt: 73599.0,
  },
  {
    agglevel: "county",
    regioncode: 29,
    year: 2017,
    count: 282,
    len_sec: 191.85799999999992,
    lanemiles: 441.13000000000005,
    delay: 439646.0,
    trkdelay: 23658.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.06,
    trktti: 1.05,
    ghg: 1986.0,
    congcost: 8919696.0,
    allspeed: 49.54,
    trkspeed: 53.0,
    ttivmt: 241291.0,
    trkttivmt: 18369.0,
  },
  {
    agglevel: "county",
    regioncode: 29,
    year: 2018,
    count: 355,
    len_sec: 191.44200000000004,
    lanemiles: 439.4439999999998,
    delay: 369308.0,
    trkdelay: 30898.0,
    pti80: 1.13,
    trkpti80: 1.19,
    pti95: 1.18,
    trkpti95: 1.26,
    tti: 1.06,
    trktti: 1.08,
    ghg: 1837.0,
    congcost: 8024003.0,
    allspeed: 48.56,
    trkspeed: 48.55,
    ttivmt: 221070.0,
    trkttivmt: 16243.0,
  },
  {
    agglevel: "county",
    regioncode: 29,
    year: 2019,
    count: 110,
    len_sec: 108.44200000000001,
    lanemiles: 269.95,
    delay: 312754.0,
    trkdelay: 33908.0,
    pti80: 1.15,
    trkpti80: 1.2,
    pti95: 1.19,
    trkpti95: 1.27,
    tti: 1.07,
    trktti: 1.09,
    ghg: 1673.0,
    congcost: 7344250.0,
    allspeed: 51.65,
    trkspeed: 50.54,
    ttivmt: 196403.0,
    trkttivmt: 15881.0,
  },
  {
    agglevel: "county",
    regioncode: 29,
    year: 2020,
    count: 451,
    len_sec: 191.89599999999993,
    lanemiles: 441.1499999999998,
    delay: 477710.0,
    trkdelay: 30470.0,
    pti80: 1.17,
    trkpti80: 1.18,
    pti95: 1.23,
    trkpti95: 1.26,
    tti: 1.08,
    trktti: 1.08,
    ghg: 2175.0,
    congcost: 1.0729022e7,
    allspeed: 50.86,
    trkspeed: 52.67,
    ttivmt: 248283.0,
    trkttivmt: 16102.0,
  },
  {
    agglevel: "county",
    regioncode: 29,
    year: 2021,
    count: 457,
    len_sec: 191.92599999999993,
    lanemiles: 439.58099999999985,
    delay: 353856.0,
    trkdelay: 28043.0,
    pti80: 1.14,
    trkpti80: 1.17,
    pti95: 1.19,
    trkpti95: 1.26,
    tti: 1.06,
    trktti: 1.08,
    ghg: 1737.0,
    congcost: 8311599.0,
    allspeed: 52.04,
    trkspeed: 53.18,
    ttivmt: 237246.0,
    trkttivmt: 16140.0,
  },
  {
    agglevel: "county",
    regioncode: 29,
    year: 2022,
    count: 465,
    len_sec: 191.90800000000004,
    lanemiles: 439.54500000000013,
    delay: 432628.0,
    trkdelay: 30527.0,
    pti80: 1.13,
    trkpti80: 1.17,
    pti95: 1.19,
    trkpti95: 1.27,
    tti: 1.06,
    trktti: 1.07,
    ghg: 1759.0,
    congcost: 1.1046327e7,
    allspeed: 52.2,
    trkspeed: 53.41,
    ttivmt: 278382.0,
    trkttivmt: 17688.0,
  },
  {
    agglevel: "county",
    regioncode: 30,
    year: 2017,
    count: 400,
    len_sec: 347.5620000000002,
    lanemiles: 759.2390000000004,
    delay: 112475.0,
    trkdelay: 14821.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 371.0,
    congcost: 2515569.0,
    allspeed: 63.18,
    trkspeed: 64.26,
    ttivmt: 397869.0,
    trkttivmt: 128436.0,
  },
  {
    agglevel: "county",
    regioncode: 30,
    year: 2018,
    count: 421,
    len_sec: 344.443,
    lanemiles: 753.2020000000002,
    delay: 87112.0,
    trkdelay: 15949.0,
    pti80: 1.07,
    trkpti80: 1.06,
    pti95: 1.11,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.01,
    ghg: 328.0,
    congcost: 2134871.0,
    allspeed: 61.79,
    trkspeed: 63.28,
    ttivmt: 394490.0,
    trkttivmt: 111813.0,
  },
  {
    agglevel: "county",
    regioncode: 30,
    year: 2019,
    count: 186,
    len_sec: 343.23399999999975,
    lanemiles: 750.7849999999994,
    delay: 117991.0,
    trkdelay: 25312.0,
    pti80: 1.08,
    trkpti80: 1.06,
    pti95: 1.11,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.01,
    ghg: 571.0,
    congcost: 3159298.0,
    allspeed: 63.19,
    trkspeed: 62.67,
    ttivmt: 417369.0,
    trkttivmt: 107924.0,
  },
  {
    agglevel: "county",
    regioncode: 30,
    year: 2020,
    count: 612,
    len_sec: 345.1069999999997,
    lanemiles: 754.5309999999989,
    delay: 184737.0,
    trkdelay: 18489.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 674.0,
    congcost: 4348977.0,
    allspeed: 62.96,
    trkspeed: 64.04,
    ttivmt: 432506.0,
    trkttivmt: 99627.0,
  },
  {
    agglevel: "county",
    regioncode: 30,
    year: 2021,
    count: 609,
    len_sec: 346.79999999999995,
    lanemiles: 758.5889999999999,
    delay: 155323.0,
    trkdelay: 18709.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.01,
    ghg: 584.0,
    congcost: 3798593.0,
    allspeed: 62.57,
    trkspeed: 63.74,
    ttivmt: 383648.0,
    trkttivmt: 90468.0,
  },
  {
    agglevel: "county",
    regioncode: 30,
    year: 2022,
    count: 623,
    len_sec: 346.7999999999997,
    lanemiles: 758.5889999999995,
    delay: 180703.0,
    trkdelay: 40495.0,
    pti80: 1.11,
    trkpti80: 1.07,
    pti95: 1.17,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.02,
    ghg: 634.0,
    congcost: 5509929.0,
    allspeed: 62.63,
    trkspeed: 63.54,
    ttivmt: 473549.0,
    trkttivmt: 112169.0,
  },
  {
    agglevel: "county",
    regioncode: 31,
    year: 2017,
    count: 2210,
    len_sec: 955.2150000000005,
    lanemiles: 2488.3239999999996,
    delay: 1.1243703e7,
    trkdelay: 250640.0,
    pti80: 1.17,
    trkpti80: 1.13,
    pti95: 1.21,
    trkpti95: 1.17,
    tti: 1.08,
    trktti: 1.04,
    ghg: 44520.0,
    congcost: 2.16511417e8,
    allspeed: 43.67,
    trkspeed: 53.26,
    ttivmt: 4095883.0,
    trkttivmt: 210628.0,
  },
  {
    agglevel: "county",
    regioncode: 31,
    year: 2018,
    count: 1841,
    len_sec: 964.974999999999,
    lanemiles: 2506.8100000000045,
    delay: 8742577.0,
    trkdelay: 386975.0,
    pti80: 1.17,
    trkpti80: 1.2,
    pti95: 1.2,
    trkpti95: 1.27,
    tti: 1.08,
    trktti: 1.09,
    ghg: 39713.0,
    congcost: 1.78798596e8,
    allspeed: 45.39,
    trkspeed: 48.56,
    ttivmt: 2956239.0,
    trkttivmt: 157452.0,
  },
  {
    agglevel: "county",
    regioncode: 31,
    year: 2019,
    count: 952,
    len_sec: 617.478,
    lanemiles: 1791.1139999999998,
    delay: 8091969.0,
    trkdelay: 424151.0,
    pti80: 1.17,
    trkpti80: 1.19,
    pti95: 1.21,
    trkpti95: 1.25,
    tti: 1.08,
    trktti: 1.08,
    ghg: 38523.0,
    congcost: 1.74200918e8,
    allspeed: 46.74,
    trkspeed: 49.61,
    ttivmt: 2676067.0,
    trkttivmt: 160406.0,
  },
  {
    agglevel: "county",
    regioncode: 31,
    year: 2020,
    count: 2890,
    len_sec: 964.1979999999996,
    lanemiles: 2574.3259999999887,
    delay: 9580341.0,
    trkdelay: 387212.0,
    pti80: 1.17,
    trkpti80: 1.18,
    pti95: 1.21,
    trkpti95: 1.24,
    tti: 1.08,
    trktti: 1.08,
    ghg: 44446.0,
    congcost: 2.07540907e8,
    allspeed: 44.88,
    trkspeed: 50.19,
    ttivmt: 3219403.0,
    trkttivmt: 148427.0,
  },
  {
    agglevel: "county",
    regioncode: 31,
    year: 2021,
    count: 2934,
    len_sec: 969.0249999999952,
    lanemiles: 2583.0329999999885,
    delay: 6663328.0,
    trkdelay: 338883.0,
    pti80: 1.15,
    trkpti80: 1.17,
    pti95: 1.2,
    trkpti95: 1.26,
    tti: 1.06,
    trktti: 1.07,
    ghg: 31273.0,
    congcost: 1.5069144e8,
    allspeed: 45.48,
    trkspeed: 51.4,
    ttivmt: 2794938.0,
    trkttivmt: 138796.0,
  },
  {
    agglevel: "county",
    regioncode: 31,
    year: 2022,
    count: 3026,
    len_sec: 973.0279999999987,
    lanemiles: 2613.542999999986,
    delay: 8187419.0,
    trkdelay: 410340.0,
    pti80: 1.15,
    trkpti80: 1.17,
    pti95: 1.22,
    trkpti95: 1.29,
    tti: 1.06,
    trktti: 1.07,
    ghg: 39100.0,
    congcost: 2.05151091e8,
    allspeed: 45.24,
    trkspeed: 51.18,
    ttivmt: 3235070.0,
    trkttivmt: 176688.0,
  },
  {
    agglevel: "county",
    regioncode: 32,
    year: 2017,
    count: 205,
    len_sec: 126.30400000000006,
    lanemiles: 281.74800000000016,
    delay: 194600.0,
    trkdelay: 11558.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.09,
    tti: 1.07,
    trktti: 1.04,
    ghg: 726.0,
    congcost: 3951298.0,
    allspeed: 53.72,
    trkspeed: 57.68,
    ttivmt: 108127.0,
    trkttivmt: 12111.0,
  },
  {
    agglevel: "county",
    regioncode: 32,
    year: 2018,
    count: 233,
    len_sec: 129.8960000000001,
    lanemiles: 288.90800000000013,
    delay: 196895.0,
    trkdelay: 21039.0,
    pti80: 1.13,
    trkpti80: 1.17,
    pti95: 1.15,
    trkpti95: 1.2,
    tti: 1.07,
    trktti: 1.08,
    ghg: 963.0,
    congcost: 4410500.0,
    allspeed: 51.11,
    trkspeed: 51.86,
    ttivmt: 107114.0,
    trkttivmt: 11633.0,
  },
  {
    agglevel: "county",
    regioncode: 32,
    year: 2019,
    count: 110,
    len_sec: 129.89600000000002,
    lanemiles: 288.90799999999996,
    delay: 171565.0,
    trkdelay: 20881.0,
    pti80: 1.11,
    trkpti80: 1.16,
    pti95: 1.14,
    trkpti95: 1.21,
    tti: 1.05,
    trktti: 1.08,
    ghg: 871.0,
    congcost: 4085159.0,
    allspeed: 53.74,
    trkspeed: 53.26,
    ttivmt: 114235.0,
    trkttivmt: 11194.0,
  },
  {
    agglevel: "county",
    regioncode: 32,
    year: 2020,
    count: 327,
    len_sec: 130.97899999999998,
    lanemiles: 290.18399999999997,
    delay: 178176.0,
    trkdelay: 21765.0,
    pti80: 1.12,
    trkpti80: 1.16,
    pti95: 1.15,
    trkpti95: 1.2,
    tti: 1.06,
    trktti: 1.08,
    ghg: 906.0,
    congcost: 4384962.0,
    allspeed: 53.1,
    trkspeed: 54.4,
    ttivmt: 106502.0,
    trkttivmt: 10800.0,
  },
  {
    agglevel: "county",
    regioncode: 32,
    year: 2021,
    count: 324,
    len_sec: 131.09300000000005,
    lanemiles: 288.1329999999998,
    delay: 138997.0,
    trkdelay: 18183.0,
    pti80: 1.13,
    trkpti80: 1.16,
    pti95: 1.17,
    trkpti95: 1.22,
    tti: 1.06,
    trktti: 1.08,
    ghg: 703.0,
    congcost: 3476688.0,
    allspeed: 53.21,
    trkspeed: 54.31,
    ttivmt: 102973.0,
    trkttivmt: 10639.0,
  },
  {
    agglevel: "county",
    regioncode: 32,
    year: 2022,
    count: 323,
    len_sec: 131.0930000000001,
    lanemiles: 288.133,
    delay: 189181.0,
    trkdelay: 22579.0,
    pti80: 1.14,
    trkpti80: 1.16,
    pti95: 1.22,
    trkpti95: 1.24,
    tti: 1.06,
    trktti: 1.07,
    ghg: 882.0,
    congcost: 5163913.0,
    allspeed: 51.93,
    trkspeed: 54.09,
    ttivmt: 114595.0,
    trkttivmt: 13491.0,
  },
  {
    agglevel: "county",
    regioncode: 33,
    year: 2017,
    count: 349,
    len_sec: 324.92900000000003,
    lanemiles: 790.7249999999996,
    delay: 59254.0,
    trkdelay: 9642.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.0,
    ghg: 190.0,
    congcost: 1379507.0,
    allspeed: 63.77,
    trkspeed: 64.63,
    ttivmt: 303128.0,
    trkttivmt: 160577.0,
  },
  {
    agglevel: "county",
    regioncode: 33,
    year: 2018,
    count: 312,
    len_sec: 326.62600000000003,
    lanemiles: 795.1110000000006,
    delay: 63615.0,
    trkdelay: 12868.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 278.0,
    congcost: 1604414.0,
    allspeed: 62.74,
    trkspeed: 63.58,
    ttivmt: 320305.0,
    trkttivmt: 79488.0,
  },
  {
    agglevel: "county",
    regioncode: 33,
    year: 2019,
    count: 169,
    len_sec: 324.87999999999994,
    lanemiles: 792.57,
    delay: 64560.0,
    trkdelay: 20631.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.15,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 385.0,
    congcost: 1965973.0,
    allspeed: 63.65,
    trkspeed: 62.3,
    ttivmt: 310024.0,
    trkttivmt: 90399.0,
  },
  {
    agglevel: "county",
    regioncode: 33,
    year: 2020,
    count: 476,
    len_sec: 327.3809999999998,
    lanemiles: 795.314,
    delay: 96627.0,
    trkdelay: 16054.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 406.0,
    congcost: 2509166.0,
    allspeed: 63.26,
    trkspeed: 63.73,
    ttivmt: 315812.0,
    trkttivmt: 70862.0,
  },
  {
    agglevel: "county",
    regioncode: 33,
    year: 2021,
    count: 483,
    len_sec: 326.1869999999998,
    lanemiles: 794.1250000000007,
    delay: 71162.0,
    trkdelay: 18559.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 387.0,
    congcost: 2056553.0,
    allspeed: 63.42,
    trkspeed: 63.94,
    ttivmt: 307475.0,
    trkttivmt: 103833.0,
  },
  {
    agglevel: "county",
    regioncode: 33,
    year: 2022,
    count: 497,
    len_sec: 326.1910000000001,
    lanemiles: 794.1349999999996,
    delay: 129924.0,
    trkdelay: 36609.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 829.0,
    congcost: 4312713.0,
    allspeed: 63.34,
    trkspeed: 63.58,
    ttivmt: 373146.0,
    trkttivmt: 90835.0,
  },
  {
    agglevel: "county",
    regioncode: 34,
    year: 2017,
    count: 720,
    len_sec: 495.553,
    lanemiles: 1100.2739999999994,
    delay: 427931.0,
    trkdelay: 48768.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.04,
    trktti: 1.03,
    ghg: 1843.0,
    congcost: 9420503.0,
    allspeed: 54.92,
    trkspeed: 58.83,
    ttivmt: 349435.0,
    trkttivmt: 69144.0,
  },
  {
    agglevel: "county",
    regioncode: 34,
    year: 2018,
    count: 741,
    len_sec: 494.11799999999937,
    lanemiles: 1095.5119999999993,
    delay: 372704.0,
    trkdelay: 57529.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.05,
    ghg: 1936.0,
    congcost: 8905024.0,
    allspeed: 53.73,
    trkspeed: 55.83,
    ttivmt: 343020.0,
    trkttivmt: 59811.0,
  },
  {
    agglevel: "county",
    regioncode: 34,
    year: 2019,
    count: 379,
    len_sec: 495.3079999999996,
    lanemiles: 1094.0109999999984,
    delay: 400161.0,
    trkdelay: 62811.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.05,
    ghg: 2136.0,
    congcost: 1.00122e7,
    allspeed: 55.9,
    trkspeed: 56.17,
    ttivmt: 366593.0,
    trkttivmt: 57728.0,
  },
  {
    agglevel: "county",
    regioncode: 34,
    year: 2020,
    count: 1092,
    len_sec: 495.315,
    lanemiles: 1095.5830000000008,
    delay: 371359.0,
    trkdelay: 52724.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.04,
    ghg: 1971.0,
    congcost: 9417463.0,
    allspeed: 56.35,
    trkspeed: 58.15,
    ttivmt: 350814.0,
    trkttivmt: 57270.0,
  },
  {
    agglevel: "county",
    regioncode: 34,
    year: 2021,
    count: 1118,
    len_sec: 495.301999999999,
    lanemiles: 1100.7929999999988,
    delay: 281543.0,
    trkdelay: 40956.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1435.0,
    congcost: 7169062.0,
    allspeed: 56.39,
    trkspeed: 58.31,
    ttivmt: 338578.0,
    trkttivmt: 55920.0,
  },
  {
    agglevel: "county",
    regioncode: 34,
    year: 2022,
    count: 1121,
    len_sec: 495.31799999999953,
    lanemiles: 1100.854999999999,
    delay: 362239.0,
    trkdelay: 48703.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1621.0,
    congcost: 1.0057515e7,
    allspeed: 55.4,
    trkspeed: 58.18,
    ttivmt: 355504.0,
    trkttivmt: 66634.0,
  },
  {
    agglevel: "county",
    regioncode: 35,
    year: 2017,
    count: 251,
    len_sec: 318.82600000000014,
    lanemiles: 649.0500000000003,
    delay: 52051.0,
    trkdelay: 10413.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.01,
    ghg: 215.0,
    congcost: 1277269.0,
    allspeed: 60.43,
    trkspeed: 62.58,
    ttivmt: 103934.0,
    trkttivmt: 34476.0,
  },
  {
    agglevel: "county",
    regioncode: 35,
    year: 2018,
    count: 206,
    len_sec: 318.69399999999996,
    lanemiles: 648.4499999999999,
    delay: 64358.0,
    trkdelay: 15938.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 361.0,
    congcost: 1725929.0,
    allspeed: 58.34,
    trkspeed: 59.87,
    ttivmt: 109486.0,
    trkttivmt: 24746.0,
  },
  {
    agglevel: "county",
    regioncode: 35,
    year: 2019,
    count: 143,
    len_sec: 318.7589999999999,
    lanemiles: 648.5799999999999,
    delay: 56749.0,
    trkdelay: 22130.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.03,
    ghg: 410.0,
    congcost: 1874124.0,
    allspeed: 60.55,
    trkspeed: 60.23,
    ttivmt: 100703.0,
    trkttivmt: 28029.0,
  },
  {
    agglevel: "county",
    regioncode: 35,
    year: 2020,
    count: 298,
    len_sec: 318.7860000000002,
    lanemiles: 648.6340000000002,
    delay: 76946.0,
    trkdelay: 21352.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 434.0,
    congcost: 2318930.0,
    allspeed: 59.68,
    trkspeed: 60.44,
    ttivmt: 103010.0,
    trkttivmt: 22708.0,
  },
  {
    agglevel: "county",
    regioncode: 35,
    year: 2021,
    count: 299,
    len_sec: 318.74100000000027,
    lanemiles: 650.8150000000006,
    delay: 69773.0,
    trkdelay: 23708.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 453.0,
    congcost: 2185682.0,
    allspeed: 59.61,
    trkspeed: 60.3,
    ttivmt: 110089.0,
    trkttivmt: 27492.0,
  },
  {
    agglevel: "county",
    regioncode: 35,
    year: 2022,
    count: 297,
    len_sec: 318.74100000000016,
    lanemiles: 650.8150000000003,
    delay: 103865.0,
    trkdelay: 26154.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.18,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.03,
    ghg: 502.0,
    congcost: 3300029.0,
    allspeed: 59.47,
    trkspeed: 60.3,
    ttivmt: 114634.0,
    trkttivmt: 28453.0,
  },
  {
    agglevel: "county",
    regioncode: 36,
    year: 2017,
    count: 364,
    len_sec: 310.5019999999998,
    lanemiles: 784.3,
    delay: 1125943.0,
    trkdelay: 53004.0,
    pti80: 1.13,
    trkpti80: 1.08,
    pti95: 1.18,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.01,
    ghg: 3997.0,
    congcost: 2.240313e7,
    allspeed: 58.57,
    trkspeed: 63.66,
    ttivmt: 1235221.0,
    trkttivmt: 267387.0,
  },
  {
    agglevel: "county",
    regioncode: 36,
    year: 2018,
    count: 515,
    len_sec: 323.4309999999999,
    lanemiles: 826.9209999999989,
    delay: 1009580.0,
    trkdelay: 93711.0,
    pti80: 1.14,
    trkpti80: 1.08,
    pti95: 1.19,
    trkpti95: 1.1,
    tti: 1.04,
    trktti: 1.02,
    ghg: 4676.0,
    congcost: 2.212845e7,
    allspeed: 58.01,
    trkspeed: 60.91,
    ttivmt: 1074791.0,
    trkttivmt: 182574.0,
  },
  {
    agglevel: "county",
    regioncode: 36,
    year: 2019,
    count: 183,
    len_sec: 250.67600000000016,
    lanemiles: 681.411,
    delay: 928520.0,
    trkdelay: 61644.0,
    pti80: 1.14,
    trkpti80: 1.06,
    pti95: 1.18,
    trkpti95: 1.07,
    tti: 1.04,
    trktti: 1.02,
    ghg: 3640.0,
    congcost: 2.0195881e7,
    allspeed: 59.12,
    trkspeed: 60.85,
    ttivmt: 1038785.0,
    trkttivmt: 181488.0,
  },
  {
    agglevel: "county",
    regioncode: 36,
    year: 2020,
    count: 719,
    len_sec: 323.8199999999999,
    lanemiles: 826.1649999999985,
    delay: 1183980.0,
    trkdelay: 58137.0,
    pti80: 1.13,
    trkpti80: 1.08,
    pti95: 1.19,
    trkpti95: 1.11,
    tti: 1.04,
    trktti: 1.01,
    ghg: 4522.0,
    congcost: 2.5772451e7,
    allspeed: 58.44,
    trkspeed: 63.34,
    ttivmt: 1192815.0,
    trkttivmt: 189076.0,
  },
  {
    agglevel: "county",
    regioncode: 36,
    year: 2021,
    count: 759,
    len_sec: 322.0539999999998,
    lanemiles: 825.5470000000006,
    delay: 795008.0,
    trkdelay: 48125.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.16,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.01,
    ghg: 2749.0,
    congcost: 1.8001367e7,
    allspeed: 58.55,
    trkspeed: 63.13,
    ttivmt: 1145837.0,
    trkttivmt: 165580.0,
  },
  {
    agglevel: "county",
    regioncode: 36,
    year: 2022,
    count: 816,
    len_sec: 329.7189999999999,
    lanemiles: 841.809000000001,
    delay: 1185486.0,
    trkdelay: 161858.0,
    pti80: 1.16,
    trkpti80: 1.09,
    pti95: 1.24,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 4849.0,
    congcost: 3.283844e7,
    allspeed: 58.67,
    trkspeed: 61.47,
    ttivmt: 1253488.0,
    trkttivmt: 233555.0,
  },
  {
    agglevel: "county",
    regioncode: 37,
    year: 2017,
    count: 982,
    len_sec: 606.975,
    lanemiles: 1344.1479999999995,
    delay: 744425.0,
    trkdelay: 44025.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.04,
    trktti: 1.03,
    ghg: 2694.0,
    congcost: 1.5097509e7,
    allspeed: 53.16,
    trkspeed: 58.19,
    ttivmt: 541011.0,
    trkttivmt: 55695.0,
  },
  {
    agglevel: "county",
    regioncode: 37,
    year: 2018,
    count: 927,
    len_sec: 605.1149999999998,
    lanemiles: 1340.901999999999,
    delay: 642032.0,
    trkdelay: 75396.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.12,
    trkpti95: 1.18,
    tti: 1.05,
    trktti: 1.06,
    ghg: 3060.0,
    congcost: 1.4578456e7,
    allspeed: 52.37,
    trkspeed: 54.73,
    ttivmt: 493843.0,
    trkttivmt: 55765.0,
  },
  {
    agglevel: "county",
    regioncode: 37,
    year: 2019,
    count: 471,
    len_sec: 571.1949999999996,
    lanemiles: 1274.352999999999,
    delay: 660978.0,
    trkdelay: 85755.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.12,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.06,
    ghg: 3303.0,
    congcost: 1.5910864e7,
    allspeed: 55.32,
    trkspeed: 55.08,
    ttivmt: 533027.0,
    trkttivmt: 59963.0,
  },
  {
    agglevel: "county",
    regioncode: 37,
    year: 2020,
    count: 1449,
    len_sec: 604.6819999999993,
    lanemiles: 1339.6899999999973,
    delay: 697331.0,
    trkdelay: 82867.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.13,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.06,
    ghg: 3319.0,
    congcost: 1.7036789e7,
    allspeed: 54.82,
    trkspeed: 56.12,
    ttivmt: 546451.0,
    trkttivmt: 57294.0,
  },
  {
    agglevel: "county",
    regioncode: 37,
    year: 2021,
    count: 1484,
    len_sec: 604.9159999999988,
    lanemiles: 1340.8489999999977,
    delay: 561594.0,
    trkdelay: 74628.0,
    pti80: 1.11,
    trkpti80: 1.15,
    pti95: 1.14,
    trkpti95: 1.22,
    tti: 1.04,
    trktti: 1.07,
    ghg: 2702.0,
    congcost: 1.4059266e7,
    allspeed: 54.46,
    trkspeed: 55.81,
    ttivmt: 481794.0,
    trkttivmt: 52093.0,
  },
  {
    agglevel: "county",
    regioncode: 37,
    year: 2022,
    count: 1485,
    len_sec: 605.2979999999998,
    lanemiles: 1341.6170000000002,
    delay: 724191.0,
    trkdelay: 76687.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.2,
    trkpti95: 1.21,
    tti: 1.05,
    trktti: 1.05,
    ghg: 2795.0,
    congcost: 1.9306487e7,
    allspeed: 53.98,
    trkspeed: 56.48,
    ttivmt: 519440.0,
    trkttivmt: 62485.0,
  },
  {
    agglevel: "county",
    regioncode: 38,
    year: 2017,
    count: 280,
    len_sec: 217.27700000000004,
    lanemiles: 494.7339999999999,
    delay: 58022.0,
    trkdelay: 9962.0,
    pti80: 1.07,
    trkpti80: 1.06,
    pti95: 1.09,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.01,
    ghg: 274.0,
    congcost: 1381635.0,
    allspeed: 60.01,
    trkspeed: 62.25,
    ttivmt: 161384.0,
    trkttivmt: 52242.0,
  },
  {
    agglevel: "county",
    regioncode: 38,
    year: 2018,
    count: 247,
    len_sec: 217.07399999999987,
    lanemiles: 494.97599999999983,
    delay: 78506.0,
    trkdelay: 16591.0,
    pti80: 1.08,
    trkpti80: 1.06,
    pti95: 1.11,
    trkpti95: 1.07,
    tti: 1.02,
    trktti: 1.02,
    ghg: 503.0,
    congcost: 2030941.0,
    allspeed: 58.26,
    trkspeed: 59.41,
    ttivmt: 153771.0,
    trkttivmt: 37243.0,
  },
  {
    agglevel: "county",
    regioncode: 38,
    year: 2019,
    count: 136,
    len_sec: 217.272,
    lanemiles: 495.3739999999999,
    delay: 100700.0,
    trkdelay: 21222.0,
    pti80: 1.1,
    trkpti80: 1.06,
    pti95: 1.12,
    trkpti95: 1.08,
    tti: 1.02,
    trktti: 1.02,
    ghg: 734.0,
    congcost: 2743964.0,
    allspeed: 59.39,
    trkspeed: 57.63,
    ttivmt: 163199.0,
    trkttivmt: 41350.0,
  },
  {
    agglevel: "county",
    regioncode: 38,
    year: 2020,
    count: 425,
    len_sec: 217.27499999999995,
    lanemiles: 512.8929999999998,
    delay: 96464.0,
    trkdelay: 19865.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 731.0,
    congcost: 2713404.0,
    allspeed: 59.45,
    trkspeed: 60.59,
    ttivmt: 170269.0,
    trkttivmt: 42132.0,
  },
  {
    agglevel: "county",
    regioncode: 38,
    year: 2021,
    count: 425,
    len_sec: 217.28900000000004,
    lanemiles: 512.9489999999998,
    delay: 72623.0,
    trkdelay: 16725.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.01,
    ghg: 515.0,
    congcost: 2057706.0,
    allspeed: 59.47,
    trkspeed: 60.54,
    ttivmt: 169560.0,
    trkttivmt: 45027.0,
  },
  {
    agglevel: "county",
    regioncode: 38,
    year: 2022,
    count: 426,
    len_sec: 217.29000000000005,
    lanemiles: 512.9529999999999,
    delay: 101386.0,
    trkdelay: 19920.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 625.0,
    congcost: 3070722.0,
    allspeed: 58.7,
    trkspeed: 60.29,
    ttivmt: 189406.0,
    trkttivmt: 47903.0,
  },
  {
    agglevel: "county",
    regioncode: 39,
    year: 2017,
    count: 377,
    len_sec: 362.6429999999998,
    lanemiles: 795.4869999999995,
    delay: 93981.0,
    trkdelay: 25916.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 481.0,
    congcost: 2532299.0,
    allspeed: 62.54,
    trkspeed: 63.07,
    ttivmt: 288339.0,
    trkttivmt: 93386.0,
  },
  {
    agglevel: "county",
    regioncode: 39,
    year: 2018,
    count: 409,
    len_sec: 361.59500000000025,
    lanemiles: 794.6110000000004,
    delay: 65501.0,
    trkdelay: 15873.0,
    pti80: 1.08,
    trkpti80: 1.05,
    pti95: 1.11,
    trkpti95: 1.06,
    tti: 1.01,
    trktti: 1.01,
    ghg: 248.0,
    congcost: 1725432.0,
    allspeed: 61.93,
    trkspeed: 62.43,
    ttivmt: 298358.0,
    trkttivmt: 73640.0,
  },
  {
    agglevel: "county",
    regioncode: 39,
    year: 2019,
    count: 187,
    len_sec: 360.6459999999996,
    lanemiles: 792.7129999999994,
    delay: 73430.0,
    trkdelay: 21222.0,
    pti80: 1.09,
    trkpti80: 1.05,
    pti95: 1.13,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.01,
    ghg: 389.0,
    congcost: 2149558.0,
    allspeed: 62.79,
    trkspeed: 62.11,
    ttivmt: 319210.0,
    trkttivmt: 74739.0,
  },
  {
    agglevel: "county",
    regioncode: 39,
    year: 2020,
    count: 597,
    len_sec: 361.2030000000002,
    lanemiles: 772.2170000000006,
    delay: 94220.0,
    trkdelay: 14956.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 382.0,
    congcost: 2421336.0,
    allspeed: 62.66,
    trkspeed: 63.4,
    ttivmt: 339953.0,
    trkttivmt: 78209.0,
  },
  {
    agglevel: "county",
    regioncode: 39,
    year: 2021,
    count: 589,
    len_sec: 361.8849999999999,
    lanemiles: 774.1729999999993,
    delay: 80869.0,
    trkdelay: 13893.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 310.0,
    congcost: 2102157.0,
    allspeed: 62.32,
    trkspeed: 63.46,
    ttivmt: 308725.0,
    trkttivmt: 90684.0,
  },
  {
    agglevel: "county",
    regioncode: 39,
    year: 2022,
    count: 588,
    len_sec: 361.9319999999997,
    lanemiles: 774.314999999999,
    delay: 135602.0,
    trkdelay: 35155.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 555.0,
    congcost: 4313848.0,
    allspeed: 62.47,
    trkspeed: 62.96,
    ttivmt: 353622.0,
    trkttivmt: 90150.0,
  },
  {
    agglevel: "county",
    regioncode: 40,
    year: 2017,
    count: 230,
    len_sec: 286.514,
    lanemiles: 577.5480000000002,
    delay: 28062.0,
    trkdelay: 2845.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.01,
    ghg: 70.0,
    congcost: 600394.0,
    allspeed: 60.95,
    trkspeed: 62.99,
    ttivmt: 42877.0,
    trkttivmt: 8362.0,
  },
  {
    agglevel: "county",
    regioncode: 40,
    year: 2018,
    count: 146,
    len_sec: 285.956,
    lanemiles: 576.4440000000001,
    delay: 23804.0,
    trkdelay: 3778.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.17,
    tti: 1.02,
    trktti: 1.04,
    ghg: 81.0,
    congcost: 564917.0,
    allspeed: 57.62,
    trkspeed: 57.66,
    ttivmt: 38683.0,
    trkttivmt: 4668.0,
  },
  {
    agglevel: "county",
    regioncode: 40,
    year: 2019,
    count: 118,
    len_sec: 286.09299999999996,
    lanemiles: 576.7179999999998,
    delay: 23042.0,
    trkdelay: 5408.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.15,
    trkpti95: 1.19,
    tti: 1.02,
    trktti: 1.05,
    ghg: 95.0,
    congcost: 630205.0,
    allspeed: 60.88,
    trkspeed: 59.69,
    ttivmt: 38848.0,
    trkttivmt: 5045.0,
  },
  {
    agglevel: "county",
    regioncode: 40,
    year: 2020,
    count: 240,
    len_sec: 286.07600000000014,
    lanemiles: 576.6840000000003,
    delay: 32498.0,
    trkdelay: 4871.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.03,
    ghg: 109.0,
    congcost: 822284.0,
    allspeed: 60.48,
    trkspeed: 61.31,
    ttivmt: 41135.0,
    trkttivmt: 6028.0,
  },
  {
    agglevel: "county",
    regioncode: 40,
    year: 2021,
    count: 246,
    len_sec: 286.09600000000023,
    lanemiles: 578.2000000000005,
    delay: 26233.0,
    trkdelay: 4548.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.03,
    ghg: 117.0,
    congcost: 688509.0,
    allspeed: 59.16,
    trkspeed: 61.17,
    ttivmt: 38769.0,
    trkttivmt: 6681.0,
  },
  {
    agglevel: "county",
    regioncode: 40,
    year: 2022,
    count: 243,
    len_sec: 286.13499999999965,
    lanemiles: 578.2719999999995,
    delay: 54719.0,
    trkdelay: 5871.0,
    pti80: 1.14,
    trkpti80: 1.13,
    pti95: 1.25,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.04,
    ghg: 187.0,
    congcost: 1456341.0,
    allspeed: 58.61,
    trkspeed: 60.54,
    ttivmt: 42277.0,
    trkttivmt: 6548.0,
  },
  {
    agglevel: "county",
    regioncode: 41,
    year: 2017,
    count: 186,
    len_sec: 175.31200000000004,
    lanemiles: 376.28100000000006,
    delay: 23375.0,
    trkdelay: 4389.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 61.0,
    congcost: 559227.0,
    allspeed: 62.95,
    trkspeed: 63.99,
    ttivmt: 76512.0,
    trkttivmt: 26604.0,
  },
  {
    agglevel: "county",
    regioncode: 41,
    year: 2018,
    count: 272,
    len_sec: 174.68700000000007,
    lanemiles: 375.168,
    delay: 23836.0,
    trkdelay: 7328.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.03,
    ghg: 114.0,
    congcost: 679215.0,
    allspeed: 62.07,
    trkspeed: 61.68,
    ttivmt: 74616.0,
    trkttivmt: 14088.0,
  },
  {
    agglevel: "county",
    regioncode: 41,
    year: 2019,
    count: 88,
    len_sec: 174.842,
    lanemiles: 375.47800000000007,
    delay: 32158.0,
    trkdelay: 10526.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 136.0,
    congcost: 974030.0,
    allspeed: 62.67,
    trkspeed: 61.1,
    ttivmt: 78706.0,
    trkttivmt: 15443.0,
  },
  {
    agglevel: "county",
    regioncode: 41,
    year: 2020,
    count: 359,
    len_sec: 174.9309999999999,
    lanemiles: 375.6559999999999,
    delay: 37746.0,
    trkdelay: 9926.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.03,
    ghg: 124.0,
    congcost: 1102455.0,
    allspeed: 62.13,
    trkspeed: 61.76,
    ttivmt: 74305.0,
    trkttivmt: 14948.0,
  },
  {
    agglevel: "county",
    regioncode: 41,
    year: 2021,
    count: 356,
    len_sec: 174.47399999999996,
    lanemiles: 375.22899999999987,
    delay: 24435.0,
    trkdelay: 9176.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.14,
    tti: 1.01,
    trktti: 1.02,
    ghg: 116.0,
    congcost: 787007.0,
    allspeed: 62.57,
    trkspeed: 62.03,
    ttivmt: 67727.0,
    trkttivmt: 15457.0,
  },
  {
    agglevel: "county",
    regioncode: 41,
    year: 2022,
    count: 356,
    len_sec: 174.47500000000008,
    lanemiles: 375.23100000000005,
    delay: 41776.0,
    trkdelay: 12105.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 179.0,
    congcost: 1381627.0,
    allspeed: 62.71,
    trkspeed: 62.4,
    ttivmt: 71944.0,
    trkttivmt: 14951.0,
  },
  {
    agglevel: "county",
    regioncode: 42,
    year: 2017,
    count: 376,
    len_sec: 371.55599999999976,
    lanemiles: 825.3359999999999,
    delay: 87673.0,
    trkdelay: 14829.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.09,
    tti: 1.02,
    trktti: 1.01,
    ghg: 370.0,
    congcost: 2075712.0,
    allspeed: 60.19,
    trkspeed: 62.25,
    ttivmt: 160685.0,
    trkttivmt: 45827.0,
  },
  {
    agglevel: "county",
    regioncode: 42,
    year: 2018,
    count: 492,
    len_sec: 370.661,
    lanemiles: 824.8869999999996,
    delay: 88087.0,
    trkdelay: 18720.0,
    pti80: 1.08,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.04,
    ghg: 452.0,
    congcost: 2262748.0,
    allspeed: 57.45,
    trkspeed: 57.59,
    ttivmt: 163389.0,
    trkttivmt: 23612.0,
  },
  {
    agglevel: "county",
    regioncode: 42,
    year: 2019,
    count: 194,
    len_sec: 326.591,
    lanemiles: 736.7469999999997,
    delay: 92388.0,
    trkdelay: 22410.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.04,
    ghg: 569.0,
    congcost: 2587763.0,
    allspeed: 60.4,
    trkspeed: 59.1,
    ttivmt: 155076.0,
    trkttivmt: 23476.0,
  },
  {
    agglevel: "county",
    regioncode: 42,
    year: 2020,
    count: 685,
    len_sec: 373.0739999999999,
    lanemiles: 829.75,
    delay: 97038.0,
    trkdelay: 20619.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 564.0,
    congcost: 2716373.0,
    allspeed: 60.05,
    trkspeed: 59.95,
    ttivmt: 163564.0,
    trkttivmt: 25866.0,
  },
  {
    agglevel: "county",
    regioncode: 42,
    year: 2021,
    count: 677,
    len_sec: 373.07399999999967,
    lanemiles: 829.7139999999995,
    delay: 73667.0,
    trkdelay: 17177.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 426.0,
    congcost: 2077863.0,
    allspeed: 59.45,
    trkspeed: 59.56,
    ttivmt: 150074.0,
    trkttivmt: 23683.0,
  },
  {
    agglevel: "county",
    regioncode: 42,
    year: 2022,
    count: 681,
    len_sec: 373.1389999999997,
    lanemiles: 829.8799999999998,
    delay: 106240.0,
    trkdelay: 23631.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 510.0,
    congcost: 3280343.0,
    allspeed: 59.48,
    trkspeed: 59.42,
    ttivmt: 155956.0,
    trkttivmt: 24678.0,
  },
  {
    agglevel: "county",
    regioncode: 43,
    year: 2017,
    count: 2211,
    len_sec: 1307.774000000001,
    lanemiles: 4106.085999999999,
    delay: 3.3219295e7,
    trkdelay: 1859098.0,
    pti80: 1.37,
    trkpti80: 1.52,
    pti95: 1.47,
    trkpti95: 1.65,
    tti: 1.17,
    trktti: 1.25,
    ghg: 135027.0,
    congcost: 6.73093737e8,
    allspeed: 43.27,
    trkspeed: 47.95,
    ttivmt: 1.1057449e7,
    trkttivmt: 700918.0,
  },
  {
    agglevel: "county",
    regioncode: 43,
    year: 2018,
    count: 2309,
    len_sec: 1350.197000000001,
    lanemiles: 4281.2959999999985,
    delay: 3.2027357e7,
    trkdelay: 1321251.0,
    pti80: 1.42,
    trkpti80: 1.5,
    pti95: 1.5,
    trkpti95: 1.63,
    tti: 1.22,
    trktti: 1.25,
    ghg: 128038.0,
    congcost: 6.48268068e8,
    allspeed: 43.25,
    trkspeed: 46.33,
    ttivmt: 8458597.0,
    trkttivmt: 371461.0,
  },
  {
    agglevel: "county",
    regioncode: 43,
    year: 2019,
    count: 1876,
    len_sec: 1310.2489999999968,
    lanemiles: 4299.091000000009,
    delay: 2.6589155e7,
    trkdelay: 1139558.0,
    pti80: 1.34,
    trkpti80: 1.4,
    pti95: 1.43,
    trkpti95: 1.52,
    tti: 1.18,
    trktti: 1.2,
    ghg: 106800.0,
    congcost: 5.59053694e8,
    allspeed: 44.12,
    trkspeed: 45.76,
    ttivmt: 8248185.0,
    trkttivmt: 342519.0,
  },
  {
    agglevel: "county",
    regioncode: 43,
    year: 2020,
    count: 3536,
    len_sec: 1313.281999999997,
    lanemiles: 4386.590999999988,
    delay: 2.431184e7,
    trkdelay: 952452.0,
    pti80: 1.32,
    trkpti80: 1.35,
    pti95: 1.41,
    trkpti95: 1.47,
    tti: 1.16,
    trktti: 1.17,
    ghg: 97336.0,
    congcost: 5.21893539e8,
    allspeed: 44.6,
    trkspeed: 48.2,
    ttivmt: 8265954.0,
    trkttivmt: 340618.0,
  },
  {
    agglevel: "county",
    regioncode: 43,
    year: 2021,
    count: 3682,
    len_sec: 1320.660999999993,
    lanemiles: 4408.895999999996,
    delay: 1.4096429e7,
    trkdelay: 806935.0,
    pti80: 1.18,
    trkpti80: 1.24,
    pti95: 1.23,
    trkpti95: 1.4,
    tti: 1.08,
    trktti: 1.11,
    ghg: 61649.0,
    congcost: 3.2047011e8,
    allspeed: 46.46,
    trkspeed: 50.3,
    ttivmt: 7752952.0,
    trkttivmt: 333743.0,
  },
  {
    agglevel: "county",
    regioncode: 43,
    year: 2022,
    count: 3867,
    len_sec: 1324.012999999991,
    lanemiles: 4434.567999999993,
    delay: 1.6603783e7,
    trkdelay: 1000613.0,
    pti80: 1.17,
    trkpti80: 1.23,
    pti95: 1.22,
    trkpti95: 1.35,
    tti: 1.08,
    trktti: 1.1,
    ghg: 74446.0,
    congcost: 4.20128473e8,
    allspeed: 46.78,
    trkspeed: 50.97,
    ttivmt: 8823618.0,
    trkttivmt: 434804.0,
  },
  {
    agglevel: "county",
    regioncode: 44,
    year: 2017,
    count: 204,
    len_sec: 208.33699999999988,
    lanemiles: 435.7049999999998,
    delay: 22769.0,
    trkdelay: 2925.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.02,
    ghg: 80.0,
    congcost: 510804.0,
    allspeed: 59.58,
    trkspeed: 62.31,
    ttivmt: 30818.0,
    trkttivmt: 7683.0,
  },
  {
    agglevel: "county",
    regioncode: 44,
    year: 2018,
    count: 180,
    len_sec: 212.68000000000006,
    lanemiles: 444.7320000000003,
    delay: 9501.0,
    trkdelay: 2032.0,
    pti80: 1.07,
    trkpti80: 1.08,
    pti95: 1.09,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.02,
    ghg: 28.0,
    congcost: 241469.0,
    allspeed: 52.74,
    trkspeed: 58.08,
    ttivmt: 26649.0,
    trkttivmt: 4629.0,
  },
  {
    agglevel: "county",
    regioncode: 44,
    year: 2019,
    count: 89,
    len_sec: 217.337,
    lanemiles: 454.04600000000005,
    delay: 30837.0,
    trkdelay: 8687.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.15,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.06,
    ghg: 183.0,
    congcost: 900936.0,
    allspeed: 58.89,
    trkspeed: 58.73,
    ttivmt: 28647.0,
    trkttivmt: 5999.0,
  },
  {
    agglevel: "county",
    regioncode: 44,
    year: 2020,
    count: 344,
    len_sec: 217.34900000000005,
    lanemiles: 454.07000000000005,
    delay: 38415.0,
    trkdelay: 6932.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.04,
    ghg: 156.0,
    congcost: 1018594.0,
    allspeed: 59.35,
    trkspeed: 60.23,
    ttivmt: 35012.0,
    trkttivmt: 7421.0,
  },
  {
    agglevel: "county",
    regioncode: 44,
    year: 2021,
    count: 342,
    len_sec: 217.34899999999993,
    lanemiles: 454.06999999999977,
    delay: 26682.0,
    trkdelay: 6622.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 109.0,
    congcost: 755644.0,
    allspeed: 59.62,
    trkspeed: 59.59,
    ttivmt: 35618.0,
    trkttivmt: 7992.0,
  },
  {
    agglevel: "county",
    regioncode: 44,
    year: 2022,
    count: 342,
    len_sec: 217.34799999999993,
    lanemiles: 454.06799999999976,
    delay: 39402.0,
    trkdelay: 6890.0,
    pti80: 1.12,
    trkpti80: 1.12,
    pti95: 1.21,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.04,
    ghg: 143.0,
    congcost: 1142571.0,
    allspeed: 58.37,
    trkspeed: 60.18,
    ttivmt: 36907.0,
    trkttivmt: 7979.0,
  },
  {
    agglevel: "county",
    regioncode: 45,
    year: 2017,
    count: 378,
    len_sec: 334.04099999999954,
    lanemiles: 764.54,
    delay: 333943.0,
    trkdelay: 61074.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1377.0,
    congcost: 8024665.0,
    allspeed: 61.95,
    trkspeed: 63.35,
    ttivmt: 797349.0,
    trkttivmt: 269363.0,
  },
  {
    agglevel: "county",
    regioncode: 45,
    year: 2018,
    count: 504,
    len_sec: 338.1359999999999,
    lanemiles: 772.5939999999998,
    delay: 316335.0,
    trkdelay: 73491.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.14,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1719.0,
    congcost: 8322929.0,
    allspeed: 61.68,
    trkspeed: 62.62,
    ttivmt: 795487.0,
    trkttivmt: 204611.0,
  },
  {
    agglevel: "county",
    regioncode: 45,
    year: 2019,
    count: 173,
    len_sec: 258.825,
    lanemiles: 614.2770000000003,
    delay: 275804.0,
    trkdelay: 84214.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.14,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.02,
    ghg: 1570.0,
    congcost: 8242686.0,
    allspeed: 62.59,
    trkspeed: 61.31,
    ttivmt: 774627.0,
    trkttivmt: 205241.0,
  },
  {
    agglevel: "county",
    regioncode: 45,
    year: 2020,
    count: 661,
    len_sec: 338.19900000000007,
    lanemiles: 772.7099999999997,
    delay: 327991.0,
    trkdelay: 62625.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1635.0,
    congcost: 8850007.0,
    allspeed: 62.13,
    trkspeed: 63.26,
    ttivmt: 796995.0,
    trkttivmt: 210061.0,
  },
  {
    agglevel: "county",
    regioncode: 45,
    year: 2021,
    count: 664,
    len_sec: 337.73199999999963,
    lanemiles: 772.2959999999994,
    delay: 229023.0,
    trkdelay: 49860.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1180.0,
    congcost: 6307326.0,
    allspeed: 61.92,
    trkspeed: 63.04,
    ttivmt: 727761.0,
    trkttivmt: 169877.0,
  },
  {
    agglevel: "county",
    regioncode: 45,
    year: 2022,
    count: 674,
    len_sec: 336.55400000000026,
    lanemiles: 768.4340000000005,
    delay: 346168.0,
    trkdelay: 91726.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.18,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.02,
    ghg: 1440.0,
    congcost: 1.1070497e7,
    allspeed: 62.1,
    trkspeed: 62.48,
    ttivmt: 873398.0,
    trkttivmt: 185696.0,
  },
  {
    agglevel: "county",
    regioncode: 46,
    year: 2017,
    count: 732,
    len_sec: 367.33299999999997,
    lanemiles: 915.7709999999994,
    delay: 3465651.0,
    trkdelay: 159223.0,
    pti80: 1.13,
    trkpti80: 1.08,
    pti95: 1.16,
    trkpti95: 1.11,
    tti: 1.06,
    trktti: 1.03,
    ghg: 13178.0,
    congcost: 6.9023152e7,
    allspeed: 53.33,
    trkspeed: 59.7,
    ttivmt: 2231903.0,
    trkttivmt: 254166.0,
  },
  {
    agglevel: "county",
    regioncode: 46,
    year: 2018,
    count: 651,
    len_sec: 364.0049999999996,
    lanemiles: 921.3579999999987,
    delay: 3462441.0,
    trkdelay: 154177.0,
    pti80: 1.17,
    trkpti80: 1.13,
    pti95: 1.23,
    trkpti95: 1.21,
    tti: 1.07,
    trktti: 1.06,
    ghg: 13039.0,
    congcost: 7.0256153e7,
    allspeed: 53.33,
    trkspeed: 58.37,
    ttivmt: 1877008.0,
    trkttivmt: 154185.0,
  },
  {
    agglevel: "county",
    regioncode: 46,
    year: 2019,
    count: 292,
    len_sec: 261.14799999999997,
    lanemiles: 719.3769999999996,
    delay: 3255786.0,
    trkdelay: 187021.0,
    pti80: 1.18,
    trkpti80: 1.14,
    pti95: 1.23,
    trkpti95: 1.21,
    tti: 1.08,
    trktti: 1.06,
    ghg: 12542.0,
    congcost: 6.9840427e7,
    allspeed: 53.77,
    trkspeed: 56.13,
    ttivmt: 1732983.0,
    trkttivmt: 159220.0,
  },
  {
    agglevel: "county",
    regioncode: 46,
    year: 2020,
    count: 1076,
    len_sec: 363.236,
    lanemiles: 939.0929999999993,
    delay: 4510855.0,
    trkdelay: 255865.0,
    pti80: 1.16,
    trkpti80: 1.15,
    pti95: 1.22,
    trkpti95: 1.22,
    tti: 1.08,
    trktti: 1.06,
    ghg: 17129.0,
    congcost: 9.9364931e7,
    allspeed: 53.2,
    trkspeed: 58.07,
    ttivmt: 2193234.0,
    trkttivmt: 184265.0,
  },
  {
    agglevel: "county",
    regioncode: 46,
    year: 2021,
    count: 1058,
    len_sec: 360.78599999999966,
    lanemiles: 936.7190000000005,
    delay: 2517568.0,
    trkdelay: 203793.0,
    pti80: 1.14,
    trkpti80: 1.13,
    pti95: 1.2,
    trkpti95: 1.22,
    tti: 1.05,
    trktti: 1.05,
    ghg: 9987.0,
    congcost: 5.8738524e7,
    allspeed: 53.68,
    trkspeed: 58.89,
    ttivmt: 1937016.0,
    trkttivmt: 202484.0,
  },
  {
    agglevel: "county",
    regioncode: 46,
    year: 2022,
    count: 1101,
    len_sec: 362.55199999999945,
    lanemiles: 944.1259999999983,
    delay: 5371828.0,
    trkdelay: 419609.0,
    pti80: 1.21,
    trkpti80: 1.23,
    pti95: 1.29,
    trkpti95: 1.34,
    tti: 1.1,
    trktti: 1.1,
    ghg: 19634.0,
    congcost: 1.37822911e8,
    allspeed: 51.98,
    trkspeed: 55.96,
    ttivmt: 2206864.0,
    trkttivmt: 215424.0,
  },
  {
    agglevel: "county",
    regioncode: 47,
    year: 2017,
    count: 367,
    len_sec: 395.69499999999977,
    lanemiles: 818.7859999999997,
    delay: 129882.0,
    trkdelay: 14732.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.08,
    tti: 1.03,
    trktti: 1.02,
    ghg: 515.0,
    congcost: 2853030.0,
    allspeed: 58.4,
    trkspeed: 61.75,
    ttivmt: 181325.0,
    trkttivmt: 38197.0,
  },
  {
    agglevel: "county",
    regioncode: 47,
    year: 2018,
    count: 520,
    len_sec: 394.7739999999997,
    lanemiles: 817.7829999999996,
    delay: 121148.0,
    trkdelay: 25520.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 711.0,
    congcost: 3121746.0,
    allspeed: 56.06,
    trkspeed: 57.92,
    ttivmt: 181702.0,
    trkttivmt: 31489.0,
  },
  {
    agglevel: "county",
    regioncode: 47,
    year: 2019,
    count: 202,
    len_sec: 391.8479999999999,
    lanemiles: 811.9309999999996,
    delay: 144214.0,
    trkdelay: 30950.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 864.0,
    congcost: 3904202.0,
    allspeed: 58.7,
    trkspeed: 58.45,
    ttivmt: 186138.0,
    trkttivmt: 31210.0,
  },
  {
    agglevel: "county",
    regioncode: 47,
    year: 2020,
    count: 702,
    len_sec: 396.8349999999993,
    lanemiles: 826.1859999999988,
    delay: 145475.0,
    trkdelay: 25001.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.03,
    ghg: 779.0,
    congcost: 3845712.0,
    allspeed: 58.47,
    trkspeed: 59.64,
    ttivmt: 189180.0,
    trkttivmt: 30524.0,
  },
  {
    agglevel: "county",
    regioncode: 47,
    year: 2021,
    count: 697,
    len_sec: 396.8779999999987,
    lanemiles: 825.8059999999983,
    delay: 113533.0,
    trkdelay: 22386.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 608.0,
    congcost: 3066640.0,
    allspeed: 58.19,
    trkspeed: 59.35,
    ttivmt: 167591.0,
    trkttivmt: 28042.0,
  },
  {
    agglevel: "county",
    regioncode: 47,
    year: 2022,
    count: 694,
    len_sec: 396.92099999999925,
    lanemiles: 825.9529999999991,
    delay: 188980.0,
    trkdelay: 38430.0,
    pti80: 1.12,
    trkpti80: 1.14,
    pti95: 1.18,
    trkpti95: 1.21,
    tti: 1.04,
    trktti: 1.05,
    ghg: 950.0,
    congcost: 5715708.0,
    allspeed: 57.35,
    trkspeed: 58.59,
    ttivmt: 181513.0,
    trkttivmt: 30517.0,
  },
  {
    agglevel: "county",
    regioncode: 48,
    year: 2017,
    count: 173,
    len_sec: 216.91900000000007,
    lanemiles: 498.9529999999999,
    delay: 27458.0,
    trkdelay: 7334.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.01,
    trktti: 1.01,
    ghg: 96.0,
    congcost: 726437.0,
    allspeed: 62.1,
    trkspeed: 63.68,
    ttivmt: 113186.0,
    trkttivmt: 57764.0,
  },
  {
    agglevel: "county",
    regioncode: 48,
    year: 2018,
    count: 252,
    len_sec: 216.64600000000002,
    lanemiles: 495.64100000000036,
    delay: 27706.0,
    trkdelay: 6321.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.02,
    ghg: 130.0,
    congcost: 723403.0,
    allspeed: 59.29,
    trkspeed: 59.8,
    ttivmt: 114547.0,
    trkttivmt: 17874.0,
  },
  {
    agglevel: "county",
    regioncode: 48,
    year: 2019,
    count: 75,
    len_sec: 157.80100000000002,
    lanemiles: 377.95099999999996,
    delay: 32496.0,
    trkdelay: 12972.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.02,
    ghg: 210.0,
    congcost: 1076475.0,
    allspeed: 62.53,
    trkspeed: 61.31,
    ttivmt: 107181.0,
    trkttivmt: 30286.0,
  },
  {
    agglevel: "county",
    regioncode: 48,
    year: 2020,
    count: 309,
    len_sec: 216.72699999999998,
    lanemiles: 495.8029999999999,
    delay: 30749.0,
    trkdelay: 8712.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 176.0,
    congcost: 934996.0,
    allspeed: 62.53,
    trkspeed: 62.51,
    ttivmt: 122979.0,
    trkttivmt: 29230.0,
  },
  {
    agglevel: "county",
    regioncode: 48,
    year: 2021,
    count: 303,
    len_sec: 216.80099999999996,
    lanemiles: 496.03700000000026,
    delay: 38119.0,
    trkdelay: 10664.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.02,
    ghg: 173.0,
    congcost: 1117039.0,
    allspeed: 62.1,
    trkspeed: 62.14,
    ttivmt: 114589.0,
    trkttivmt: 27265.0,
  },
  {
    agglevel: "county",
    regioncode: 48,
    year: 2022,
    count: 305,
    len_sec: 216.9099999999999,
    lanemiles: 496.25500000000005,
    delay: 43651.0,
    trkdelay: 13811.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 228.0,
    congcost: 1487689.0,
    allspeed: 62.2,
    trkspeed: 61.89,
    ttivmt: 116506.0,
    trkttivmt: 22382.0,
  },
  {
    agglevel: "county",
    regioncode: 49,
    year: 2017,
    count: 595,
    len_sec: 420.268,
    lanemiles: 955.4539999999994,
    delay: 806038.0,
    trkdelay: 44198.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.14,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.01,
    ghg: 2914.0,
    congcost: 1.623817e7,
    allspeed: 57.41,
    trkspeed: 62.15,
    ttivmt: 802855.0,
    trkttivmt: 128146.0,
  },
  {
    agglevel: "county",
    regioncode: 49,
    year: 2018,
    count: 610,
    len_sec: 422.23099999999994,
    lanemiles: 959.0989999999985,
    delay: 721528.0,
    trkdelay: 64778.0,
    pti80: 1.11,
    trkpti80: 1.07,
    pti95: 1.15,
    trkpti95: 1.09,
    tti: 1.03,
    trktti: 1.02,
    ghg: 3265.0,
    congcost: 1.5739043e7,
    allspeed: 57.67,
    trkspeed: 59.7,
    ttivmt: 759326.0,
    trkttivmt: 111423.0,
  },
  {
    agglevel: "county",
    regioncode: 49,
    year: 2019,
    count: 330,
    len_sec: 421.58600000000007,
    lanemiles: 958.213,
    delay: 657468.0,
    trkdelay: 76585.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.14,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.03,
    ghg: 3212.0,
    congcost: 1.5524411e7,
    allspeed: 59.29,
    trkspeed: 59.21,
    ttivmt: 793300.0,
    trkttivmt: 106827.0,
  },
  {
    agglevel: "county",
    regioncode: 49,
    year: 2020,
    count: 855,
    len_sec: 421.53999999999934,
    lanemiles: 958.052999999998,
    delay: 777364.0,
    trkdelay: 78720.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.15,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.02,
    ghg: 3626.0,
    congcost: 1.849e7,
    allspeed: 58.85,
    trkspeed: 61.34,
    ttivmt: 804319.0,
    trkttivmt: 120616.0,
  },
  {
    agglevel: "county",
    regioncode: 49,
    year: 2021,
    count: 852,
    len_sec: 422.4369999999995,
    lanemiles: 958.1819999999985,
    delay: 534599.0,
    trkdelay: 63885.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.02,
    ghg: 2863.0,
    congcost: 1.3227798e7,
    allspeed: 58.83,
    trkspeed: 60.82,
    ttivmt: 712291.0,
    trkttivmt: 106181.0,
  },
  {
    agglevel: "county",
    regioncode: 49,
    year: 2022,
    count: 893,
    len_sec: 422.7479999999993,
    lanemiles: 958.6189999999981,
    delay: 637501.0,
    trkdelay: 70852.0,
    pti80: 1.12,
    trkpti80: 1.09,
    pti95: 1.19,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.02,
    ghg: 2508.0,
    congcost: 1.7105059e7,
    allspeed: 59.05,
    trkspeed: 61.1,
    ttivmt: 856703.0,
    trkttivmt: 121960.0,
  },
  {
    agglevel: "county",
    regioncode: 50,
    year: 2017,
    count: 517,
    len_sec: 391.23600000000005,
    lanemiles: 820.4020000000011,
    delay: 849074.0,
    trkdelay: 52079.0,
    pti80: 1.13,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.04,
    ghg: 3502.0,
    congcost: 1.7349849e7,
    allspeed: 50.46,
    trkspeed: 56.51,
    ttivmt: 555023.0,
    trkttivmt: 51361.0,
  },
  {
    agglevel: "county",
    regioncode: 50,
    year: 2018,
    count: 602,
    len_sec: 399.2479999999997,
    lanemiles: 864.3969999999995,
    delay: 710700.0,
    trkdelay: 54737.0,
    pti80: 1.12,
    trkpti80: 1.16,
    pti95: 1.15,
    trkpti95: 1.22,
    tti: 1.05,
    trktti: 1.07,
    ghg: 3030.0,
    congcost: 1.5195712e7,
    allspeed: 52.67,
    trkspeed: 52.44,
    ttivmt: 472766.0,
    trkttivmt: 34297.0,
  },
  {
    agglevel: "county",
    regioncode: 50,
    year: 2019,
    count: 195,
    len_sec: 244.52700000000013,
    lanemiles: 585.7170000000001,
    delay: 597944.0,
    trkdelay: 57598.0,
    pti80: 1.12,
    trkpti80: 1.16,
    pti95: 1.15,
    trkpti95: 1.22,
    tti: 1.05,
    trktti: 1.07,
    ghg: 2651.0,
    congcost: 1.3663189e7,
    allspeed: 55.72,
    trkspeed: 54.26,
    ttivmt: 432227.0,
    trkttivmt: 33464.0,
  },
  {
    agglevel: "county",
    regioncode: 50,
    year: 2020,
    count: 916,
    len_sec: 399.3709999999995,
    lanemiles: 896.360999999999,
    delay: 749153.0,
    trkdelay: 67423.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.15,
    trkpti95: 1.21,
    tti: 1.05,
    trktti: 1.07,
    ghg: 3368.0,
    congcost: 1.7502938e7,
    allspeed: 53.64,
    trkspeed: 54.25,
    ttivmt: 520619.0,
    trkttivmt: 37757.0,
  },
  {
    agglevel: "county",
    regioncode: 50,
    year: 2021,
    count: 915,
    len_sec: 399.3709999999991,
    lanemiles: 896.3609999999995,
    delay: 402420.0,
    trkdelay: 49042.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.19,
    tti: 1.03,
    trktti: 1.05,
    ghg: 2025.0,
    congcost: 9962396.0,
    allspeed: 53.81,
    trkspeed: 56.55,
    ttivmt: 486019.0,
    trkttivmt: 42894.0,
  },
  {
    agglevel: "county",
    regioncode: 50,
    year: 2022,
    count: 935,
    len_sec: 400.2509999999998,
    lanemiles: 913.0579999999992,
    delay: 801596.0,
    trkdelay: 60775.0,
    pti80: 1.12,
    trkpti80: 1.14,
    pti95: 1.19,
    trkpti95: 1.22,
    tti: 1.05,
    trktti: 1.05,
    ghg: 3657.0,
    congcost: 2.0725162e7,
    allspeed: 52.31,
    trkspeed: 55.71,
    ttivmt: 496216.0,
    trkttivmt: 42928.0,
  },
  {
    agglevel: "county",
    regioncode: 51,
    year: 2017,
    count: 207,
    len_sec: 201.484,
    lanemiles: 402.968,
    delay: 11446.0,
    trkdelay: 1761.0,
    pti80: 1.07,
    trkpti80: 1.06,
    pti95: 1.09,
    trkpti95: 1.07,
    tti: 1.02,
    trktti: 1.01,
    ghg: 32.0,
    congcost: 263588.0,
    allspeed: 61.13,
    trkspeed: 62.56,
    ttivmt: 29523.0,
    trkttivmt: 6546.0,
  },
  {
    agglevel: "county",
    regioncode: 51,
    year: 2018,
    count: 225,
    len_sec: 201.6449999999999,
    lanemiles: 403.2899999999998,
    delay: 12997.0,
    trkdelay: 3310.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.09,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.03,
    ghg: 61.0,
    congcost: 350370.0,
    allspeed: 59.76,
    trkspeed: 60.49,
    ttivmt: 28049.0,
    trkttivmt: 4600.0,
  },
  {
    agglevel: "county",
    regioncode: 51,
    year: 2019,
    count: 73,
    len_sec: 202.234,
    lanemiles: 404.468,
    delay: 11749.0,
    trkdelay: 3873.0,
    pti80: 1.08,
    trkpti80: 1.11,
    pti95: 1.1,
    trkpti95: 1.15,
    tti: 1.01,
    trktti: 1.04,
    ghg: 71.0,
    congcost: 362662.0,
    allspeed: 61.27,
    trkspeed: 60.38,
    ttivmt: 30500.0,
    trkttivmt: 4776.0,
  },
  {
    agglevel: "county",
    regioncode: 51,
    year: 2020,
    count: 360,
    len_sec: 202.24199999999993,
    lanemiles: 404.48399999999987,
    delay: 12051.0,
    trkdelay: 3130.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 56.0,
    congcost: 356554.0,
    allspeed: 60.7,
    trkspeed: 60.98,
    ttivmt: 26613.0,
    trkttivmt: 4745.0,
  },
  {
    agglevel: "county",
    regioncode: 51,
    year: 2021,
    count: 360,
    len_sec: 202.21400000000008,
    lanemiles: 404.42800000000017,
    delay: 10267.0,
    trkdelay: 3509.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.1,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 52.0,
    congcost: 322145.0,
    allspeed: 60.42,
    trkspeed: 61.48,
    ttivmt: 24361.0,
    trkttivmt: 6443.0,
  },
  {
    agglevel: "county",
    regioncode: 51,
    year: 2022,
    count: 360,
    len_sec: 202.2140000000001,
    lanemiles: 404.4280000000002,
    delay: 19227.0,
    trkdelay: 5447.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 61.0,
    congcost: 626766.0,
    allspeed: 60.08,
    trkspeed: 60.67,
    ttivmt: 24856.0,
    trkttivmt: 6279.0,
  },
  {
    agglevel: "county",
    regioncode: 52,
    year: 2017,
    count: 115,
    len_sec: 137.88500000000005,
    lanemiles: 318.44799999999987,
    delay: 24427.0,
    trkdelay: 12188.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 174.0,
    congcost: 829914.0,
    allspeed: 62.7,
    trkspeed: 63.66,
    ttivmt: 99736.0,
    trkttivmt: 63455.0,
  },
  {
    agglevel: "county",
    regioncode: 52,
    year: 2018,
    count: 113,
    len_sec: 137.65000000000003,
    lanemiles: 318.89799999999997,
    delay: 26371.0,
    trkdelay: 10062.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.02,
    ghg: 169.0,
    congcost: 819336.0,
    allspeed: 62.58,
    trkspeed: 62.11,
    ttivmt: 112408.0,
    trkttivmt: 19281.0,
  },
  {
    agglevel: "county",
    regioncode: 52,
    year: 2019,
    count: 52,
    len_sec: 137.65299999999993,
    lanemiles: 318.90399999999994,
    delay: 36202.0,
    trkdelay: 13143.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.02,
    ghg: 201.0,
    congcost: 1150604.0,
    allspeed: 62.98,
    trkspeed: 62.14,
    ttivmt: 153856.0,
    trkttivmt: 27426.0,
  },
  {
    agglevel: "county",
    regioncode: 52,
    year: 2020,
    count: 167,
    len_sec: 137.65099999999995,
    lanemiles: 318.9,
    delay: 37745.0,
    trkdelay: 12799.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.01,
    trktti: 1.02,
    ghg: 214.0,
    congcost: 1221519.0,
    allspeed: 62.77,
    trkspeed: 62.75,
    ttivmt: 157502.0,
    trkttivmt: 27154.0,
  },
  {
    agglevel: "county",
    regioncode: 52,
    year: 2021,
    count: 165,
    len_sec: 137.65100000000007,
    lanemiles: 318.89999999999986,
    delay: 34130.0,
    trkdelay: 16625.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.02,
    ghg: 278.0,
    congcost: 1227477.0,
    allspeed: 62.72,
    trkspeed: 62.71,
    ttivmt: 119746.0,
    trkttivmt: 32580.0,
  },
  {
    agglevel: "county",
    regioncode: 52,
    year: 2022,
    count: 162,
    len_sec: 137.65800000000013,
    lanemiles: 318.91400000000016,
    delay: 41299.0,
    trkdelay: 16480.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.02,
    ghg: 261.0,
    congcost: 1532346.0,
    allspeed: 61.68,
    trkspeed: 61.56,
    ttivmt: 111356.0,
    trkttivmt: 32295.0,
  },
  {
    agglevel: "county",
    regioncode: 53,
    year: 2017,
    count: 174,
    len_sec: 342.9119999999999,
    lanemiles: 794.1480000000003,
    delay: 65870.0,
    trkdelay: 29341.0,
    pti80: 1.07,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.0,
    ghg: 414.0,
    congcost: 2117022.0,
    allspeed: 63.4,
    trkspeed: 64.33,
    ttivmt: 252684.0,
    trkttivmt: 194887.0,
  },
  {
    agglevel: "county",
    regioncode: 53,
    year: 2018,
    count: 268,
    len_sec: 342.8009999999996,
    lanemiles: 793.9339999999995,
    delay: 50574.0,
    trkdelay: 13697.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 205.0,
    congcost: 1377356.0,
    allspeed: 63.32,
    trkspeed: 63.82,
    ttivmt: 274221.0,
    trkttivmt: 82870.0,
  },
  {
    agglevel: "county",
    regioncode: 53,
    year: 2019,
    count: 60,
    len_sec: 270.02000000000004,
    lanemiles: 648.3720000000002,
    delay: 67159.0,
    trkdelay: 27849.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.14,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 389.0,
    congcost: 2248689.0,
    allspeed: 63.73,
    trkspeed: 61.98,
    ttivmt: 313913.0,
    trkttivmt: 98093.0,
  },
  {
    agglevel: "county",
    regioncode: 53,
    year: 2020,
    count: 397,
    len_sec: 343.07199999999983,
    lanemiles: 794.4759999999994,
    delay: 63181.0,
    trkdelay: 14688.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 334.0,
    congcost: 1803567.0,
    allspeed: 63.55,
    trkspeed: 64.0,
    ttivmt: 335468.0,
    trkttivmt: 94191.0,
  },
  {
    agglevel: "county",
    regioncode: 53,
    year: 2021,
    count: 398,
    len_sec: 343.072,
    lanemiles: 794.2519999999998,
    delay: 42564.0,
    trkdelay: 10844.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 205.0,
    congcost: 1217825.0,
    allspeed: 63.49,
    trkspeed: 64.01,
    ttivmt: 253080.0,
    trkttivmt: 63831.0,
  },
  {
    agglevel: "county",
    regioncode: 53,
    year: 2022,
    count: 433,
    len_sec: 352.3800000000001,
    lanemiles: 805.1290000000008,
    delay: 125944.0,
    trkdelay: 50843.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 529.0,
    congcost: 4609814.0,
    allspeed: 63.08,
    trkspeed: 62.96,
    ttivmt: 256936.0,
    trkttivmt: 85838.0,
  },
  {
    agglevel: "county",
    regioncode: 54,
    year: 2017,
    count: 221,
    len_sec: 246.76399999999992,
    lanemiles: 556.7819999999999,
    delay: 46541.0,
    trkdelay: 6315.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 149.0,
    congcost: 1046792.0,
    allspeed: 60.76,
    trkspeed: 61.9,
    ttivmt: 73930.0,
    trkttivmt: 12545.0,
  },
  {
    agglevel: "county",
    regioncode: 54,
    year: 2018,
    count: 170,
    len_sec: 246.41199999999998,
    lanemiles: 553.2100000000003,
    delay: 25479.0,
    trkdelay: 5677.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.03,
    ghg: 101.0,
    congcost: 655404.0,
    allspeed: 54.77,
    trkspeed: 53.68,
    ttivmt: 69864.0,
    trkttivmt: 9938.0,
  },
  {
    agglevel: "county",
    regioncode: 54,
    year: 2019,
    count: 131,
    len_sec: 246.063,
    lanemiles: 552.5120000000001,
    delay: 40335.0,
    trkdelay: 10513.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.12,
    trkpti95: 1.17,
    tti: 1.02,
    trktti: 1.05,
    ghg: 203.0,
    congcost: 1141998.0,
    allspeed: 60.65,
    trkspeed: 58.66,
    ttivmt: 74366.0,
    trkttivmt: 11043.0,
  },
  {
    agglevel: "county",
    regioncode: 54,
    year: 2020,
    count: 283,
    len_sec: 246.438,
    lanemiles: 556.3940000000001,
    delay: 47309.0,
    trkdelay: 6739.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 186.0,
    congcost: 1186902.0,
    allspeed: 60.61,
    trkspeed: 60.02,
    ttivmt: 74846.0,
    trkttivmt: 9808.0,
  },
  {
    agglevel: "county",
    regioncode: 54,
    year: 2021,
    count: 280,
    len_sec: 246.43800000000002,
    lanemiles: 556.4860000000003,
    delay: 30671.0,
    trkdelay: 8203.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 150.0,
    congcost: 891195.0,
    allspeed: 60.61,
    trkspeed: 59.62,
    ttivmt: 69602.0,
    trkttivmt: 13702.0,
  },
  {
    agglevel: "county",
    regioncode: 54,
    year: 2022,
    count: 279,
    len_sec: 246.43800000000002,
    lanemiles: 556.4860000000001,
    delay: 52896.0,
    trkdelay: 13465.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 254.0,
    congcost: 1686928.0,
    allspeed: 60.72,
    trkspeed: 59.82,
    ttivmt: 81026.0,
    trkttivmt: 13025.0,
  },
  {
    agglevel: "county",
    regioncode: 55,
    year: 2017,
    count: 202,
    len_sec: 323.697,
    lanemiles: 757.0989999999999,
    delay: 111794.0,
    trkdelay: 23325.0,
    pti80: 1.07,
    trkpti80: 1.06,
    pti95: 1.1,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.0,
    ghg: 370.0,
    congcost: 2752751.0,
    allspeed: 63.12,
    trkspeed: 64.6,
    ttivmt: 347146.0,
    trkttivmt: 370135.0,
  },
  {
    agglevel: "county",
    regioncode: 55,
    year: 2018,
    count: 275,
    len_sec: 322.3710000000002,
    lanemiles: 754.8979999999997,
    delay: 86302.0,
    trkdelay: 9687.0,
    pti80: 1.07,
    trkpti80: 1.06,
    pti95: 1.1,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.0,
    ghg: 279.0,
    congcost: 1921237.0,
    allspeed: 63.06,
    trkspeed: 64.3,
    ttivmt: 339870.0,
    trkttivmt: 123413.0,
  },
  {
    agglevel: "county",
    regioncode: 55,
    year: 2019,
    count: 81,
    len_sec: 246.44,
    lanemiles: 603.036,
    delay: 84439.0,
    trkdelay: 18524.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 393.0,
    congcost: 2270561.0,
    allspeed: 63.56,
    trkspeed: 63.06,
    ttivmt: 370627.0,
    trkttivmt: 128175.0,
  },
  {
    agglevel: "county",
    regioncode: 55,
    year: 2020,
    count: 405,
    len_sec: 322.62499999999994,
    lanemiles: 759.7249999999998,
    delay: 129205.0,
    trkdelay: 26252.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 546.0,
    congcost: 3530842.0,
    allspeed: 63.1,
    trkspeed: 64.12,
    ttivmt: 401161.0,
    trkttivmt: 143846.0,
  },
  {
    agglevel: "county",
    regioncode: 55,
    year: 2021,
    count: 404,
    len_sec: 322.6249999999997,
    lanemiles: 759.7249999999997,
    delay: 147659.0,
    trkdelay: 38837.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.01,
    ghg: 975.0,
    congcost: 4303220.0,
    allspeed: 62.98,
    trkspeed: 64.12,
    ttivmt: 328821.0,
    trkttivmt: 141815.0,
  },
  {
    agglevel: "county",
    regioncode: 55,
    year: 2022,
    count: 416,
    len_sec: 324.3089999999995,
    lanemiles: 763.2969999999989,
    delay: 134309.0,
    trkdelay: 37558.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 917.0,
    congcost: 4463128.0,
    allspeed: 62.88,
    trkspeed: 63.74,
    ttivmt: 368886.0,
    trkttivmt: 160823.0,
  },
  {
    agglevel: "county",
    regioncode: 56,
    year: 2017,
    count: 221,
    len_sec: 304.1400000000001,
    lanemiles: 689.5499999999995,
    delay: 142867.0,
    trkdelay: 34449.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.08,
    tti: 1.03,
    trktti: 1.01,
    ghg: 814.0,
    congcost: 3719223.0,
    allspeed: 57.5,
    trkspeed: 62.35,
    ttivmt: 153692.0,
    trkttivmt: 96972.0,
  },
  {
    agglevel: "county",
    regioncode: 56,
    year: 2018,
    count: 230,
    len_sec: 306.336,
    lanemiles: 695.6820000000002,
    delay: 131958.0,
    trkdelay: 36146.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.03,
    ghg: 957.0,
    congcost: 3685087.0,
    allspeed: 55.06,
    trkspeed: 56.47,
    ttivmt: 144712.0,
    trkttivmt: 44390.0,
  },
  {
    agglevel: "county",
    regioncode: 56,
    year: 2019,
    count: 116,
    len_sec: 306.3739999999999,
    lanemiles: 695.7580000000003,
    delay: 118551.0,
    trkdelay: 30568.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 776.0,
    congcost: 3384339.0,
    allspeed: 59.45,
    trkspeed: 59.38,
    ttivmt: 189914.0,
    trkttivmt: 37958.0,
  },
  {
    agglevel: "county",
    regioncode: 56,
    year: 2020,
    count: 306,
    len_sec: 306.3789999999999,
    lanemiles: 707.768,
    delay: 140236.0,
    trkdelay: 29954.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 878.0,
    congcost: 3932557.0,
    allspeed: 58.54,
    trkspeed: 60.1,
    ttivmt: 170109.0,
    trkttivmt: 38502.0,
  },
  {
    agglevel: "county",
    regioncode: 56,
    year: 2021,
    count: 304,
    len_sec: 306.271,
    lanemiles: 707.4559999999998,
    delay: 120949.0,
    trkdelay: 35481.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 945.0,
    congcost: 3657636.0,
    allspeed: 58.88,
    trkspeed: 60.47,
    ttivmt: 177156.0,
    trkttivmt: 56291.0,
  },
  {
    agglevel: "county",
    regioncode: 56,
    year: 2022,
    count: 311,
    len_sec: 306.274,
    lanemiles: 707.4460000000004,
    delay: 160648.0,
    trkdelay: 38838.0,
    pti80: 1.12,
    trkpti80: 1.09,
    pti95: 1.18,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 933.0,
    congcost: 5089465.0,
    allspeed: 58.51,
    trkspeed: 59.59,
    ttivmt: 184960.0,
    trkttivmt: 53679.0,
  },
  {
    agglevel: "county",
    regioncode: 57,
    year: 2017,
    count: 5819,
    len_sec: 3040.318000000013,
    lanemiles: 11285.872999999983,
    delay: 1.20080172e8,
    trkdelay: 7514859.0,
    pti80: 1.51,
    trkpti80: 1.65,
    pti95: 1.64,
    trkpti95: 1.83,
    tti: 1.25,
    trktti: 1.3,
    ghg: 479018.0,
    congcost: 2.45424075e9,
    allspeed: 43.49,
    trkspeed: 48.23,
    ttivmt: 3.3607042e7,
    trkttivmt: 2601554.0,
  },
  {
    agglevel: "county",
    regioncode: 57,
    year: 2018,
    count: 5819,
    len_sec: 3117.6340000000046,
    lanemiles: 11453.986000000037,
    delay: 1.25896506e8,
    trkdelay: 6083811.0,
    pti80: 1.61,
    trkpti80: 1.71,
    pti95: 1.77,
    trkpti95: 1.91,
    tti: 1.32,
    trktti: 1.35,
    ghg: 487562.0,
    congcost: 2.571670692e9,
    allspeed: 41.29,
    trkspeed: 45.39,
    ttivmt: 2.7346231e7,
    trkttivmt: 1566989.0,
  },
  {
    agglevel: "county",
    regioncode: 57,
    year: 2019,
    count: 4666,
    len_sec: 3044.7569999999982,
    lanemiles: 11267.157000000037,
    delay: 1.18829467e8,
    trkdelay: 5626500.0,
    pti80: 1.56,
    trkpti80: 1.62,
    pti95: 1.69,
    trkpti95: 1.8,
    tti: 1.29,
    trktti: 1.3,
    ghg: 444649.0,
    congcost: 2.50692774e9,
    allspeed: 42.01,
    trkspeed: 45.34,
    ttivmt: 2.7585786e7,
    trkttivmt: 1521796.0,
  },
  {
    agglevel: "county",
    regioncode: 57,
    year: 2020,
    count: 8205,
    len_sec: 3062.25200000002,
    lanemiles: 11290.08200000014,
    delay: 1.1807538e8,
    trkdelay: 5322286.0,
    pti80: 1.58,
    trkpti80: 1.61,
    pti95: 1.72,
    trkpti95: 1.78,
    tti: 1.3,
    trktti: 1.3,
    ghg: 435718.0,
    congcost: 2.55002074e9,
    allspeed: 42.42,
    trkspeed: 46.94,
    ttivmt: 2.7246219e7,
    trkttivmt: 1502096.0,
  },
  {
    agglevel: "county",
    regioncode: 57,
    year: 2021,
    count: 8615,
    len_sec: 3077.2710000000066,
    lanemiles: 11349.265000000221,
    delay: 6.3853067e7,
    trkdelay: 3666155.0,
    pti80: 1.32,
    trkpti80: 1.36,
    pti95: 1.4,
    trkpti95: 1.51,
    tti: 1.16,
    trktti: 1.18,
    ghg: 241389.0,
    congcost: 1.444065122e9,
    allspeed: 45.22,
    trkspeed: 49.83,
    ttivmt: 2.5171243e7,
    trkttivmt: 1461308.0,
  },
  {
    agglevel: "county",
    regioncode: 57,
    year: 2022,
    count: 9167,
    len_sec: 3104.0630000000247,
    lanemiles: 11486.745000000137,
    delay: 9.0036822e7,
    trkdelay: 5174693.0,
    pti80: 1.4,
    trkpti80: 1.46,
    pti95: 1.5,
    trkpti95: 1.61,
    tti: 1.2,
    trktti: 1.22,
    ghg: 342651.0,
    congcost: 2.252293167e9,
    allspeed: 43.9,
    trkspeed: 49.43,
    ttivmt: 2.657013e7,
    trkttivmt: 1699195.0,
  },
  {
    agglevel: "county",
    regioncode: 58,
    year: 2017,
    count: 349,
    len_sec: 361.64400000000006,
    lanemiles: 812.997,
    delay: 225580.0,
    trkdelay: 26331.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.02,
    ghg: 1024.0,
    congcost: 4996649.0,
    allspeed: 54.9,
    trkspeed: 61.56,
    ttivmt: 210511.0,
    trkttivmt: 55200.0,
  },
  {
    agglevel: "county",
    regioncode: 58,
    year: 2018,
    count: 304,
    len_sec: 360.9699999999998,
    lanemiles: 811.5759999999998,
    delay: 147851.0,
    trkdelay: 29126.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 888.0,
    congcost: 3749695.0,
    allspeed: 56.93,
    trkspeed: 58.64,
    ttivmt: 188385.0,
    trkttivmt: 31179.0,
  },
  {
    agglevel: "county",
    regioncode: 58,
    year: 2019,
    count: 240,
    len_sec: 360.10399999999987,
    lanemiles: 809.8439999999996,
    delay: 140324.0,
    trkdelay: 32026.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 898.0,
    congcost: 3871480.0,
    allspeed: 58.98,
    trkspeed: 59.44,
    ttivmt: 222904.0,
    trkttivmt: 39237.0,
  },
  {
    agglevel: "county",
    regioncode: 58,
    year: 2020,
    count: 431,
    len_sec: 361.0149999999997,
    lanemiles: 836.8579999999989,
    delay: 149938.0,
    trkdelay: 33256.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 937.0,
    congcost: 4253910.0,
    allspeed: 58.98,
    trkspeed: 60.19,
    ttivmt: 239055.0,
    trkttivmt: 45347.0,
  },
  {
    agglevel: "county",
    regioncode: 58,
    year: 2021,
    count: 425,
    len_sec: 361.081,
    lanemiles: 836.1439999999996,
    delay: 153466.0,
    trkdelay: 40303.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 982.0,
    congcost: 4466048.0,
    allspeed: 58.45,
    trkspeed: 59.7,
    ttivmt: 214269.0,
    trkttivmt: 44779.0,
  },
  {
    agglevel: "county",
    regioncode: 58,
    year: 2022,
    count: 424,
    len_sec: 361.114,
    lanemiles: 836.2289999999999,
    delay: 181729.0,
    trkdelay: 31706.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.18,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.03,
    ghg: 885.0,
    congcost: 5310364.0,
    allspeed: 58.38,
    trkspeed: 59.63,
    ttivmt: 237530.0,
    trkttivmt: 43002.0,
  },
  {
    agglevel: "county",
    regioncode: 59,
    year: 2017,
    count: 193,
    len_sec: 281.3880000000001,
    lanemiles: 620.3879999999998,
    delay: 149279.0,
    trkdelay: 15374.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.01,
    ghg: 726.0,
    congcost: 3255219.0,
    allspeed: 55.21,
    trkspeed: 62.28,
    ttivmt: 171152.0,
    trkttivmt: 64437.0,
  },
  {
    agglevel: "county",
    regioncode: 59,
    year: 2018,
    count: 211,
    len_sec: 280.272,
    lanemiles: 618.755,
    delay: 145698.0,
    trkdelay: 34306.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1134.0,
    congcost: 3913296.0,
    allspeed: 54.79,
    trkspeed: 56.95,
    ttivmt: 171096.0,
    trkttivmt: 33418.0,
  },
  {
    agglevel: "county",
    regioncode: 59,
    year: 2019,
    count: 124,
    len_sec: 280.46499999999986,
    lanemiles: 619.1459999999998,
    delay: 138553.0,
    trkdelay: 39402.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.12,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1181.0,
    congcost: 4146263.0,
    allspeed: 57.83,
    trkspeed: 57.92,
    ttivmt: 171060.0,
    trkttivmt: 37869.0,
  },
  {
    agglevel: "county",
    regioncode: 59,
    year: 2020,
    count: 276,
    len_sec: 281.4749999999999,
    lanemiles: 621.1659999999999,
    delay: 153640.0,
    trkdelay: 35460.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1127.0,
    congcost: 4442808.0,
    allspeed: 57.05,
    trkspeed: 58.51,
    ttivmt: 168993.0,
    trkttivmt: 32306.0,
  },
  {
    agglevel: "county",
    regioncode: 59,
    year: 2021,
    count: 271,
    len_sec: 281.79999999999984,
    lanemiles: 621.7779999999997,
    delay: 139004.0,
    trkdelay: 41751.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1254.0,
    congcost: 4269733.0,
    allspeed: 56.95,
    trkspeed: 58.18,
    ttivmt: 175902.0,
    trkttivmt: 42095.0,
  },
  {
    agglevel: "county",
    regioncode: 59,
    year: 2022,
    count: 272,
    len_sec: 281.7999999999998,
    lanemiles: 621.7780000000002,
    delay: 144671.0,
    trkdelay: 32936.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.18,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.04,
    ghg: 812.0,
    congcost: 4507256.0,
    allspeed: 58.23,
    trkspeed: 59.09,
    ttivmt: 188464.0,
    trkttivmt: 38257.0,
  },
  {
    agglevel: "county",
    regioncode: 60,
    year: 2017,
    count: 349,
    len_sec: 166.10300000000012,
    lanemiles: 352.33400000000023,
    delay: 44591.0,
    trkdelay: 7191.0,
    pti80: 1.07,
    trkpti80: 1.06,
    pti95: 1.08,
    trkpti95: 1.07,
    tti: 1.02,
    trktti: 1.02,
    ghg: 131.0,
    congcost: 1035672.0,
    allspeed: 59.32,
    trkspeed: 60.82,
    ttivmt: 63708.0,
    trkttivmt: 14202.0,
  },
  {
    agglevel: "county",
    regioncode: 60,
    year: 2018,
    count: 295,
    len_sec: 165.60099999999994,
    lanemiles: 352.0019999999999,
    delay: 39735.0,
    trkdelay: 5020.0,
    pti80: 1.06,
    trkpti80: 1.08,
    pti95: 1.07,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.03,
    ghg: 139.0,
    congcost: 903705.0,
    allspeed: 55.85,
    trkspeed: 56.94,
    ttivmt: 65581.0,
    trkttivmt: 7863.0,
  },
  {
    agglevel: "county",
    regioncode: 60,
    year: 2019,
    count: 115,
    len_sec: 164.956,
    lanemiles: 350.71199999999993,
    delay: 46547.0,
    trkdelay: 8660.0,
    pti80: 1.08,
    trkpti80: 1.1,
    pti95: 1.1,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.04,
    ghg: 232.0,
    congcost: 1204266.0,
    allspeed: 59.47,
    trkspeed: 58.39,
    ttivmt: 67305.0,
    trkttivmt: 8656.0,
  },
  {
    agglevel: "county",
    regioncode: 60,
    year: 2020,
    count: 580,
    len_sec: 165.775,
    lanemiles: 372.2339999999997,
    delay: 56738.0,
    trkdelay: 7385.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 255.0,
    congcost: 1411349.0,
    allspeed: 60.04,
    trkspeed: 60.74,
    ttivmt: 71088.0,
    trkttivmt: 9076.0,
  },
  {
    agglevel: "county",
    regioncode: 60,
    year: 2021,
    count: 575,
    len_sec: 165.769,
    lanemiles: 372.2220000000002,
    delay: 38357.0,
    trkdelay: 5453.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 189.0,
    congcost: 976026.0,
    allspeed: 60.54,
    trkspeed: 61.29,
    ttivmt: 68025.0,
    trkttivmt: 8907.0,
  },
  {
    agglevel: "county",
    regioncode: 60,
    year: 2022,
    count: 580,
    len_sec: 165.7429999999999,
    lanemiles: 372.1700000000001,
    delay: 71019.0,
    trkdelay: 6745.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.18,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.02,
    ghg: 188.0,
    congcost: 1852564.0,
    allspeed: 59.04,
    trkspeed: 60.94,
    ttivmt: 79730.0,
    trkttivmt: 10393.0,
  },
  {
    agglevel: "county",
    regioncode: 61,
    year: 2017,
    count: 2209,
    len_sec: 1129.2159999999983,
    lanemiles: 3199.7759999999953,
    delay: 2.5024285e7,
    trkdelay: 1407857.0,
    pti80: 1.39,
    trkpti80: 1.4,
    pti95: 1.49,
    trkpti95: 1.53,
    tti: 1.2,
    trktti: 1.2,
    ghg: 106728.0,
    congcost: 5.08129811e8,
    allspeed: 43.52,
    trkspeed: 49.15,
    ttivmt: 7391229.0,
    trkttivmt: 580093.0,
  },
  {
    agglevel: "county",
    regioncode: 61,
    year: 2018,
    count: 2012,
    len_sec: 1136.2989999999995,
    lanemiles: 3351.3309999999974,
    delay: 2.1485193e7,
    trkdelay: 1457850.0,
    pti80: 1.32,
    trkpti80: 1.38,
    pti95: 1.41,
    trkpti95: 1.49,
    tti: 1.17,
    trktti: 1.19,
    ghg: 100676.0,
    congcost: 4.55095263e8,
    allspeed: 45.31,
    trkspeed: 47.66,
    ttivmt: 6137326.0,
    trkttivmt: 417860.0,
  },
  {
    agglevel: "county",
    regioncode: 61,
    year: 2019,
    count: 1542,
    len_sec: 1135.8349999999991,
    lanemiles: 3404.4179999999933,
    delay: 1.8888376e7,
    trkdelay: 1374462.0,
    pti80: 1.27,
    trkpti80: 1.32,
    pti95: 1.34,
    trkpti95: 1.41,
    tti: 1.14,
    trktti: 1.16,
    ghg: 88932.0,
    congcost: 4.18689535e8,
    allspeed: 46.98,
    trkspeed: 46.74,
    ttivmt: 6356991.0,
    trkttivmt: 424887.0,
  },
  {
    agglevel: "county",
    regioncode: 61,
    year: 2020,
    count: 3270,
    len_sec: 1134.6159999999945,
    lanemiles: 3403.302999999995,
    delay: 2.0538016e7,
    trkdelay: 908949.0,
    pti80: 1.28,
    trkpti80: 1.28,
    pti95: 1.36,
    trkpti95: 1.38,
    tti: 1.15,
    trktti: 1.13,
    ghg: 85299.0,
    congcost: 4.45225726e8,
    allspeed: 46.32,
    trkspeed: 52.22,
    ttivmt: 6794486.0,
    trkttivmt: 383956.0,
  },
  {
    agglevel: "county",
    regioncode: 61,
    year: 2021,
    count: 3612,
    len_sec: 1142.0919999999917,
    lanemiles: 3477.1569999999933,
    delay: 1.1702976e7,
    trkdelay: 686867.0,
    pti80: 1.17,
    trkpti80: 1.19,
    pti95: 1.22,
    trkpti95: 1.31,
    tti: 1.08,
    trktti: 1.09,
    ghg: 51622.0,
    congcost: 2.66621843e8,
    allspeed: 48.31,
    trkspeed: 53.95,
    ttivmt: 6175037.0,
    trkttivmt: 361756.0,
  },
  {
    agglevel: "county",
    regioncode: 61,
    year: 2022,
    count: 3903,
    len_sec: 1158.1719999999984,
    lanemiles: 3595.985999999987,
    delay: 1.5995563e7,
    trkdelay: 924136.0,
    pti80: 1.19,
    trkpti80: 1.21,
    pti95: 1.24,
    trkpti95: 1.32,
    tti: 1.09,
    trktti: 1.09,
    ghg: 71868.0,
    congcost: 4.03425638e8,
    allspeed: 47.19,
    trkspeed: 53.19,
    ttivmt: 6799869.0,
    trkttivmt: 440548.0,
  },
  {
    agglevel: "county",
    regioncode: 62,
    year: 2017,
    count: 430,
    len_sec: 361.42500000000035,
    lanemiles: 755.7360000000009,
    delay: 338932.0,
    trkdelay: 45614.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.09,
    tti: 1.03,
    trktti: 1.02,
    ghg: 1649.0,
    congcost: 7708124.0,
    allspeed: 54.37,
    trkspeed: 58.87,
    ttivmt: 302383.0,
    trkttivmt: 68697.0,
  },
  {
    agglevel: "county",
    regioncode: 62,
    year: 2018,
    count: 592,
    len_sec: 360.8039999999998,
    lanemiles: 753.6689999999994,
    delay: 304630.0,
    trkdelay: 65008.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.13,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.06,
    ghg: 1945.0,
    congcost: 7906515.0,
    allspeed: 54.98,
    trkspeed: 55.72,
    ttivmt: 265664.0,
    trkttivmt: 45895.0,
  },
  {
    agglevel: "county",
    regioncode: 62,
    year: 2019,
    count: 160,
    len_sec: 208.75000000000006,
    lanemiles: 448.24499999999995,
    delay: 248761.0,
    trkdelay: 66406.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.12,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.06,
    ghg: 1715.0,
    congcost: 7210774.0,
    allspeed: 56.73,
    trkspeed: 55.74,
    ttivmt: 234821.0,
    trkttivmt: 46112.0,
  },
  {
    agglevel: "county",
    regioncode: 62,
    year: 2020,
    count: 801,
    len_sec: 360.8859999999999,
    lanemiles: 766.2829999999997,
    delay: 360905.0,
    trkdelay: 72655.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.06,
    ghg: 2422.0,
    congcost: 1.0020242e7,
    allspeed: 55.81,
    trkspeed: 56.3,
    ttivmt: 308385.0,
    trkttivmt: 50265.0,
  },
  {
    agglevel: "county",
    regioncode: 62,
    year: 2021,
    count: 799,
    len_sec: 360.9669999999998,
    lanemiles: 766.5999999999996,
    delay: 234948.0,
    trkdelay: 52008.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.05,
    ghg: 1531.0,
    congcost: 6566446.0,
    allspeed: 56.18,
    trkspeed: 56.85,
    ttivmt: 284809.0,
    trkttivmt: 45589.0,
  },
  {
    agglevel: "county",
    regioncode: 62,
    year: 2022,
    count: 805,
    len_sec: 361.6319999999996,
    lanemiles: 768.0939999999991,
    delay: 291962.0,
    trkdelay: 47751.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1546.0,
    congcost: 8461094.0,
    allspeed: 56.25,
    trkspeed: 57.04,
    ttivmt: 308145.0,
    trkttivmt: 46410.0,
  },
  {
    agglevel: "county",
    regioncode: 63,
    year: 2017,
    count: 182,
    len_sec: 167.83799999999988,
    lanemiles: 400.67000000000024,
    delay: 22243.0,
    trkdelay: 3425.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.02,
    ghg: 72.0,
    congcost: 513583.0,
    allspeed: 60.85,
    trkspeed: 62.3,
    ttivmt: 36325.0,
    trkttivmt: 8411.0,
  },
  {
    agglevel: "county",
    regioncode: 63,
    year: 2018,
    count: 178,
    len_sec: 167.0370000000001,
    lanemiles: 399.03800000000007,
    delay: 13243.0,
    trkdelay: 2954.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 41.0,
    congcost: 339741.0,
    allspeed: 54.62,
    trkspeed: 55.58,
    ttivmt: 37176.0,
    trkttivmt: 6176.0,
  },
  {
    agglevel: "county",
    regioncode: 63,
    year: 2019,
    count: 85,
    len_sec: 196.1419999999999,
    lanemiles: 457.2479999999999,
    delay: 25133.0,
    trkdelay: 6330.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.04,
    ghg: 124.0,
    congcost: 705316.0,
    allspeed: 61.27,
    trkspeed: 59.87,
    ttivmt: 38626.0,
    trkttivmt: 7353.0,
  },
  {
    agglevel: "county",
    regioncode: 63,
    year: 2020,
    count: 323,
    len_sec: 197.1850000000001,
    lanemiles: 459.33599999999996,
    delay: 38937.0,
    trkdelay: 5858.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 161.0,
    congcost: 990553.0,
    allspeed: 60.05,
    trkspeed: 60.43,
    ttivmt: 38905.0,
    trkttivmt: 6418.0,
  },
  {
    agglevel: "county",
    regioncode: 63,
    year: 2021,
    count: 320,
    len_sec: 197.18400000000014,
    lanemiles: 458.8139999999999,
    delay: 16211.0,
    trkdelay: 4297.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 79.0,
    congcost: 470210.0,
    allspeed: 59.6,
    trkspeed: 58.29,
    ttivmt: 36778.0,
    trkttivmt: 8012.0,
  },
  {
    agglevel: "county",
    regioncode: 63,
    year: 2022,
    count: 322,
    len_sec: 197.226,
    lanemiles: 458.8979999999999,
    delay: 38864.0,
    trkdelay: 10770.0,
    pti80: 1.13,
    trkpti80: 1.16,
    pti95: 1.2,
    trkpti95: 1.24,
    tti: 1.04,
    trktti: 1.06,
    ghg: 193.0,
    congcost: 1273657.0,
    allspeed: 59.41,
    trkspeed: 58.88,
    ttivmt: 42422.0,
    trkttivmt: 7391.0,
  },
  {
    agglevel: "county",
    regioncode: 64,
    year: 2017,
    count: 417,
    len_sec: 264.53600000000006,
    lanemiles: 540.032,
    delay: 294631.0,
    trkdelay: 33781.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.09,
    tti: 1.03,
    trktti: 1.01,
    ghg: 1311.0,
    congcost: 6498176.0,
    allspeed: 54.85,
    trkspeed: 60.35,
    ttivmt: 299004.0,
    trkttivmt: 79265.0,
  },
  {
    agglevel: "county",
    regioncode: 64,
    year: 2018,
    count: 410,
    len_sec: 263.5459999999999,
    lanemiles: 537.9749999999997,
    delay: 188481.0,
    trkdelay: 32262.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1003.0,
    congcost: 4606827.0,
    allspeed: 56.04,
    trkspeed: 58.05,
    ttivmt: 223599.0,
    trkttivmt: 37141.0,
  },
  {
    agglevel: "county",
    regioncode: 64,
    year: 2019,
    count: 94,
    len_sec: 161.79700000000003,
    lanemiles: 331.379,
    delay: 154337.0,
    trkdelay: 35112.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.04,
    ghg: 950.0,
    congcost: 4240923.0,
    allspeed: 57.27,
    trkspeed: 56.9,
    ttivmt: 213615.0,
    trkttivmt: 41039.0,
  },
  {
    agglevel: "county",
    regioncode: 64,
    year: 2020,
    count: 622,
    len_sec: 263.712,
    lanemiles: 539.335,
    delay: 241018.0,
    trkdelay: 44820.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1520.0,
    congcost: 6535533.0,
    allspeed: 56.35,
    trkspeed: 57.84,
    ttivmt: 269959.0,
    trkttivmt: 45274.0,
  },
  {
    agglevel: "county",
    regioncode: 64,
    year: 2021,
    count: 617,
    len_sec: 263.7119999999999,
    lanemiles: 539.3349999999997,
    delay: 159613.0,
    trkdelay: 26771.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 878.0,
    congcost: 4179896.0,
    allspeed: 56.02,
    trkspeed: 58.49,
    ttivmt: 212263.0,
    trkttivmt: 38628.0,
  },
  {
    agglevel: "county",
    regioncode: 64,
    year: 2022,
    count: 619,
    len_sec: 263.7659999999999,
    lanemiles: 539.449,
    delay: 160397.0,
    trkdelay: 29484.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 850.0,
    congcost: 4756005.0,
    allspeed: 56.98,
    trkspeed: 59.4,
    ttivmt: 190272.0,
    trkttivmt: 42247.0,
  },
  {
    agglevel: "county",
    regioncode: 65,
    year: 2017,
    count: 179,
    len_sec: 196.97599999999997,
    lanemiles: 479.785,
    delay: 41437.0,
    trkdelay: 9513.0,
    pti80: 1.06,
    trkpti80: 1.06,
    pti95: 1.08,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.0,
    ghg: 144.0,
    congcost: 1046948.0,
    allspeed: 62.48,
    trkspeed: 63.81,
    ttivmt: 204720.0,
    trkttivmt: 99413.0,
  },
  {
    agglevel: "county",
    regioncode: 65,
    year: 2018,
    count: 201,
    len_sec: 200.55899999999997,
    lanemiles: 487.5830000000001,
    delay: 32258.0,
    trkdelay: 10467.0,
    pti80: 1.06,
    trkpti80: 1.05,
    pti95: 1.08,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.01,
    ghg: 160.0,
    congcost: 935065.0,
    allspeed: 61.59,
    trkspeed: 61.88,
    ttivmt: 181525.0,
    trkttivmt: 63649.0,
  },
  {
    agglevel: "county",
    regioncode: 65,
    year: 2019,
    count: 86,
    len_sec: 192.71499999999995,
    lanemiles: 471.8949999999999,
    delay: 39721.0,
    trkdelay: 14265.0,
    pti80: 1.08,
    trkpti80: 1.06,
    pti95: 1.1,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.01,
    ghg: 255.0,
    congcost: 1262243.0,
    allspeed: 62.47,
    trkspeed: 61.75,
    ttivmt: 205617.0,
    trkttivmt: 67591.0,
  },
  {
    agglevel: "county",
    regioncode: 65,
    year: 2020,
    count: 314,
    len_sec: 201.4199999999999,
    lanemiles: 489.32099999999986,
    delay: 45649.0,
    trkdelay: 12847.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 275.0,
    congcost: 1386871.0,
    allspeed: 62.15,
    trkspeed: 62.53,
    ttivmt: 213099.0,
    trkttivmt: 66087.0,
  },
  {
    agglevel: "county",
    regioncode: 65,
    year: 2021,
    count: 315,
    len_sec: 201.47700000000003,
    lanemiles: 489.44100000000014,
    delay: 27628.0,
    trkdelay: 9124.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.1,
    tti: 1.0,
    trktti: 1.0,
    ghg: 164.0,
    congcost: 855646.0,
    allspeed: 62.29,
    trkspeed: 62.74,
    ttivmt: 211115.0,
    trkttivmt: 72503.0,
  },
  {
    agglevel: "county",
    regioncode: 65,
    year: 2022,
    count: 315,
    len_sec: 201.47700000000003,
    lanemiles: 489.4390000000002,
    delay: 81392.0,
    trkdelay: 41371.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.02,
    ghg: 400.0,
    congcost: 3273144.0,
    allspeed: 62.43,
    trkspeed: 61.93,
    ttivmt: 256468.0,
    trkttivmt: 93243.0,
  },
  {
    agglevel: "county",
    regioncode: 66,
    year: 2017,
    count: 24,
    len_sec: 48.836,
    lanemiles: 191.096,
    delay: 9825.0,
    trkdelay: 3148.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.0,
    trktti: 1.0,
    ghg: 45.0,
    congcost: 276968.0,
    allspeed: 64.71,
    trkspeed: 64.66,
    ttivmt: 160092.0,
    trkttivmt: 52057.0,
  },
  {
    agglevel: "county",
    regioncode: 66,
    year: 2018,
    count: 13,
    len_sec: 49.69200000000001,
    lanemiles: 192.79200000000003,
    delay: 29132.0,
    trkdelay: 6027.0,
    pti80: 1.08,
    trkpti80: 1.05,
    pti95: 1.13,
    trkpti95: 1.05,
    tti: 1.0,
    trktti: 1.01,
    ghg: 166.0,
    congcost: 745144.0,
    allspeed: 64.36,
    trkspeed: 63.68,
    ttivmt: 173128.0,
    trkttivmt: 37117.0,
  },
  {
    agglevel: "county",
    regioncode: 66,
    year: 2019,
    count: 10,
    len_sec: 49.692,
    lanemiles: 192.792,
    delay: 15514.0,
    trkdelay: 6320.0,
    pti80: 1.1,
    trkpti80: 1.06,
    pti95: 1.16,
    trkpti95: 1.07,
    tti: 1.0,
    trktti: 1.01,
    ghg: 116.0,
    congcost: 520140.0,
    allspeed: 64.4,
    trkspeed: 62.42,
    ttivmt: 194842.0,
    trkttivmt: 39398.0,
  },
  {
    agglevel: "county",
    regioncode: 66,
    year: 2020,
    count: 29,
    len_sec: 48.77100000000001,
    lanemiles: 190.98200000000003,
    delay: 14430.0,
    trkdelay: 4713.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.0,
    trktti: 1.0,
    ghg: 101.0,
    congcost: 463108.0,
    allspeed: 64.63,
    trkspeed: 64.56,
    ttivmt: 179043.0,
    trkttivmt: 46488.0,
  },
  {
    agglevel: "county",
    regioncode: 66,
    year: 2021,
    count: 29,
    len_sec: 48.77100000000001,
    lanemiles: 190.982,
    delay: 8939.0,
    trkdelay: 3250.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.0,
    trktti: 1.0,
    ghg: 56.0,
    congcost: 284954.0,
    allspeed: 64.64,
    trkspeed: 64.6,
    ttivmt: 149412.0,
    trkttivmt: 42551.0,
  },
  {
    agglevel: "county",
    regioncode: 66,
    year: 2022,
    count: 29,
    len_sec: 48.77099999999999,
    lanemiles: 190.98199999999994,
    delay: 9441.0,
    trkdelay: 3072.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.0,
    trktti: 1.0,
    ghg: 45.0,
    congcost: 322470.0,
    allspeed: 64.68,
    trkspeed: 64.64,
    ttivmt: 191977.0,
    trkttivmt: 51100.0,
  },
  {
    agglevel: "county",
    regioncode: 67,
    year: 2017,
    count: 269,
    len_sec: 369.768,
    lanemiles: 752.734,
    delay: 116703.0,
    trkdelay: 26564.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.02,
    ghg: 472.0,
    congcost: 2962469.0,
    allspeed: 59.5,
    trkspeed: 62.59,
    ttivmt: 161678.0,
    trkttivmt: 56698.0,
  },
  {
    agglevel: "county",
    regioncode: 67,
    year: 2018,
    count: 426,
    len_sec: 370.8559999999997,
    lanemiles: 754.7059999999993,
    delay: 102188.0,
    trkdelay: 18179.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.03,
    ghg: 497.0,
    congcost: 2512214.0,
    allspeed: 59.03,
    trkspeed: 59.07,
    ttivmt: 160560.0,
    trkttivmt: 23468.0,
  },
  {
    agglevel: "county",
    regioncode: 67,
    year: 2019,
    count: 101,
    len_sec: 292.834,
    lanemiles: 598.6620000000001,
    delay: 78964.0,
    trkdelay: 19789.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 437.0,
    congcost: 2221148.0,
    allspeed: 59.97,
    trkspeed: 59.63,
    ttivmt: 157656.0,
    trkttivmt: 24986.0,
  },
  {
    agglevel: "county",
    regioncode: 67,
    year: 2020,
    count: 545,
    len_sec: 370.926,
    lanemiles: 760.2609999999997,
    delay: 110682.0,
    trkdelay: 21585.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 540.0,
    congcost: 3003934.0,
    allspeed: 59.55,
    trkspeed: 60.21,
    ttivmt: 167822.0,
    trkttivmt: 26113.0,
  },
  {
    agglevel: "county",
    regioncode: 67,
    year: 2021,
    count: 544,
    len_sec: 370.954,
    lanemiles: 760.325,
    delay: 72814.0,
    trkdelay: 15246.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 331.0,
    congcost: 1984118.0,
    allspeed: 59.66,
    trkspeed: 60.34,
    ttivmt: 141772.0,
    trkttivmt: 23403.0,
  },
  {
    agglevel: "county",
    regioncode: 67,
    year: 2022,
    count: 545,
    len_sec: 370.955,
    lanemiles: 760.3269999999997,
    delay: 97299.0,
    trkdelay: 20460.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.03,
    ghg: 425.0,
    congcost: 2950813.0,
    allspeed: 59.74,
    trkspeed: 60.07,
    ttivmt: 150768.0,
    trkttivmt: 26160.0,
  },
  {
    agglevel: "county",
    regioncode: 68,
    year: 2017,
    count: 609,
    len_sec: 480.8019999999997,
    lanemiles: 1076.7079999999987,
    delay: 262275.0,
    trkdelay: 31255.0,
    pti80: 1.07,
    trkpti80: 1.06,
    pti95: 1.11,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.0,
    ghg: 1094.0,
    congcost: 5809711.0,
    allspeed: 61.18,
    trkspeed: 63.88,
    ttivmt: 521190.0,
    trkttivmt: 228746.0,
  },
  {
    agglevel: "county",
    regioncode: 68,
    year: 2018,
    count: 713,
    len_sec: 479.25499999999994,
    lanemiles: 1072.391,
    delay: 205924.0,
    trkdelay: 31995.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 941.0,
    congcost: 4908553.0,
    allspeed: 60.6,
    trkspeed: 62.79,
    ttivmt: 525645.0,
    trkttivmt: 157378.0,
  },
  {
    agglevel: "county",
    regioncode: 68,
    year: 2019,
    count: 300,
    len_sec: 460.3549999999999,
    lanemiles: 1034.589,
    delay: 248760.0,
    trkdelay: 53223.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1311.0,
    congcost: 6677299.0,
    allspeed: 61.57,
    trkspeed: 61.48,
    ttivmt: 567392.0,
    trkttivmt: 159645.0,
  },
  {
    agglevel: "county",
    regioncode: 68,
    year: 2020,
    count: 976,
    len_sec: 482.74,
    lanemiles: 1079.5809999999985,
    delay: 314469.0,
    trkdelay: 51442.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.01,
    ghg: 1569.0,
    congcost: 8188886.0,
    allspeed: 61.27,
    trkspeed: 62.87,
    ttivmt: 604916.0,
    trkttivmt: 158488.0,
  },
  {
    agglevel: "county",
    regioncode: 68,
    year: 2021,
    count: 971,
    len_sec: 482.2179999999991,
    lanemiles: 1079.4249999999988,
    delay: 185421.0,
    trkdelay: 31006.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 895.0,
    congcost: 4830807.0,
    allspeed: 61.17,
    trkspeed: 62.93,
    ttivmt: 515030.0,
    trkttivmt: 141588.0,
  },
  {
    agglevel: "county",
    regioncode: 68,
    year: 2022,
    count: 977,
    len_sec: 482.21799999999905,
    lanemiles: 1078.4429999999993,
    delay: 261963.0,
    trkdelay: 61419.0,
    pti80: 1.12,
    trkpti80: 1.07,
    pti95: 1.19,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1260.0,
    congcost: 8169553.0,
    allspeed: 61.31,
    trkspeed: 62.56,
    ttivmt: 526914.0,
    trkttivmt: 125458.0,
  },
  {
    agglevel: "county",
    regioncode: 69,
    year: 2017,
    count: 952,
    len_sec: 521.0499999999993,
    lanemiles: 1470.804999999999,
    delay: 4772811.0,
    trkdelay: 204284.0,
    pti80: 1.15,
    trkpti80: 1.1,
    pti95: 1.19,
    trkpti95: 1.14,
    tti: 1.07,
    trktti: 1.03,
    ghg: 20927.0,
    congcost: 9.5173006e7,
    allspeed: 45.68,
    trkspeed: 56.94,
    ttivmt: 2036557.0,
    trkttivmt: 252783.0,
  },
  {
    agglevel: "county",
    regioncode: 69,
    year: 2018,
    count: 795,
    len_sec: 523.6289999999999,
    lanemiles: 1479.729999999999,
    delay: 4147324.0,
    trkdelay: 278829.0,
    pti80: 1.17,
    trkpti80: 1.16,
    pti95: 1.2,
    trkpti95: 1.21,
    tti: 1.08,
    trktti: 1.06,
    ghg: 19864.0,
    congcost: 8.7906346e7,
    allspeed: 49.48,
    trkspeed: 56.57,
    ttivmt: 1553284.0,
    trkttivmt: 182808.0,
  },
  {
    agglevel: "county",
    regioncode: 69,
    year: 2019,
    count: 602,
    len_sec: 520.1639999999999,
    lanemiles: 1470.9239999999986,
    delay: 5081254.0,
    trkdelay: 525810.0,
    pti80: 1.21,
    trkpti80: 1.2,
    pti95: 1.25,
    trkpti95: 1.24,
    tti: 1.11,
    trktti: 1.09,
    ghg: 27038.0,
    congcost: 1.18414874e8,
    allspeed: 47.63,
    trkspeed: 52.96,
    ttivmt: 1682785.0,
    trkttivmt: 267586.0,
  },
  {
    agglevel: "county",
    regioncode: 69,
    year: 2020,
    count: 1279,
    len_sec: 521.7019999999978,
    lanemiles: 1475.5649999999957,
    delay: 4859106.0,
    trkdelay: 545522.0,
    pti80: 1.18,
    trkpti80: 1.18,
    pti95: 1.21,
    trkpti95: 1.23,
    tti: 1.09,
    trktti: 1.08,
    ghg: 27200.0,
    congcost: 1.18497307e8,
    allspeed: 48.42,
    trkspeed: 55.38,
    ttivmt: 1756458.0,
    trkttivmt: 279547.0,
  },
  {
    agglevel: "county",
    regioncode: 69,
    year: 2021,
    count: 1247,
    len_sec: 521.4159999999986,
    lanemiles: 1475.4329999999954,
    delay: 3225465.0,
    trkdelay: 443741.0,
    pti80: 1.15,
    trkpti80: 1.17,
    pti95: 1.2,
    trkpti95: 1.29,
    tti: 1.07,
    trktti: 1.07,
    ghg: 18649.0,
    congcost: 8.1724958e7,
    allspeed: 48.73,
    trkspeed: 56.22,
    ttivmt: 1540606.0,
    trkttivmt: 238708.0,
  },
  {
    agglevel: "county",
    regioncode: 69,
    year: 2022,
    count: 1275,
    len_sec: 521.1299999999983,
    lanemiles: 1474.8609999999942,
    delay: 3499109.0,
    trkdelay: 292567.0,
    pti80: 1.15,
    trkpti80: 1.12,
    pti95: 1.2,
    trkpti95: 1.18,
    tti: 1.07,
    trktti: 1.04,
    ghg: 18007.0,
    congcost: 9.1905313e7,
    allspeed: 47.12,
    trkspeed: 56.91,
    ttivmt: 1595274.0,
    trkttivmt: 282557.0,
  },
  {
    agglevel: "county",
    regioncode: 70,
    year: 2017,
    count: 99,
    len_sec: 233.9530000000001,
    lanemiles: 488.5740000000003,
    delay: 23011.0,
    trkdelay: 7573.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.09,
    tti: 1.02,
    trktti: 1.02,
    ghg: 110.0,
    congcost: 655694.0,
    allspeed: 60.49,
    trkspeed: 62.82,
    ttivmt: 32179.0,
    trkttivmt: 19217.0,
  },
  {
    agglevel: "county",
    regioncode: 70,
    year: 2018,
    count: 140,
    len_sec: 234.1329999999999,
    lanemiles: 488.77999999999986,
    delay: 22689.0,
    trkdelay: 5725.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 110.0,
    congcost: 609692.0,
    allspeed: 59.17,
    trkspeed: 59.93,
    ttivmt: 30181.0,
    trkttivmt: 6903.0,
  },
  {
    agglevel: "county",
    regioncode: 70,
    year: 2019,
    count: 39,
    len_sec: 167.73799999999994,
    lanemiles: 355.9899999999999,
    delay: 21287.0,
    trkdelay: 8683.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.1,
    trkpti95: 1.17,
    tti: 1.02,
    trktti: 1.04,
    ghg: 140.0,
    congcost: 712335.0,
    allspeed: 61.04,
    trkspeed: 59.18,
    ttivmt: 34963.0,
    trkttivmt: 9484.0,
  },
  {
    agglevel: "county",
    regioncode: 70,
    year: 2020,
    count: 174,
    len_sec: 242.15399999999997,
    lanemiles: 504.8219999999999,
    delay: 29698.0,
    trkdelay: 7882.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 147.0,
    congcost: 880196.0,
    allspeed: 60.97,
    trkspeed: 60.7,
    ttivmt: 54117.0,
    trkttivmt: 13316.0,
  },
  {
    agglevel: "county",
    regioncode: 70,
    year: 2021,
    count: 175,
    len_sec: 242.15400000000008,
    lanemiles: 504.8220000000002,
    delay: 18707.0,
    trkdelay: 3767.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 79.0,
    congcost: 504417.0,
    allspeed: 60.09,
    trkspeed: 60.95,
    ttivmt: 39219.0,
    trkttivmt: 6368.0,
  },
  {
    agglevel: "county",
    regioncode: 70,
    year: 2022,
    count: 183,
    len_sec: 242.16000000000003,
    lanemiles: 504.834,
    delay: 37459.0,
    trkdelay: 6984.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.19,
    tti: 1.03,
    trktti: 1.04,
    ghg: 137.0,
    congcost: 1100805.0,
    allspeed: 59.56,
    trkspeed: 60.67,
    ttivmt: 41626.0,
    trkttivmt: 6733.0,
  },
  {
    agglevel: "county",
    regioncode: 71,
    year: 2017,
    count: 1476,
    len_sec: 843.157999999998,
    lanemiles: 1989.4989999999964,
    delay: 3867202.0,
    trkdelay: 187979.0,
    pti80: 1.11,
    trkpti80: 1.07,
    pti95: 1.15,
    trkpti95: 1.09,
    tti: 1.04,
    trktti: 1.02,
    ghg: 14232.0,
    congcost: 7.7237275e7,
    allspeed: 54.52,
    trkspeed: 62.34,
    ttivmt: 3150446.0,
    trkttivmt: 567346.0,
  },
  {
    agglevel: "county",
    regioncode: 71,
    year: 2018,
    count: 1694,
    len_sec: 845.4049999999996,
    lanemiles: 1987.8149999999982,
    delay: 3038915.0,
    trkdelay: 209515.0,
    pti80: 1.12,
    trkpti80: 1.08,
    pti95: 1.16,
    trkpti95: 1.1,
    tti: 1.04,
    trktti: 1.03,
    ghg: 12156.0,
    congcost: 6.4044885e7,
    allspeed: 56.07,
    trkspeed: 60.59,
    ttivmt: 2450899.0,
    trkttivmt: 373734.0,
  },
  {
    agglevel: "county",
    regioncode: 71,
    year: 2019,
    count: 906,
    len_sec: 839.2649999999998,
    lanemiles: 1975.695999999999,
    delay: 3197673.0,
    trkdelay: 259281.0,
    pti80: 1.12,
    trkpti80: 1.08,
    pti95: 1.16,
    trkpti95: 1.11,
    tti: 1.04,
    trktti: 1.03,
    ghg: 13556.0,
    congcost: 7.132186e7,
    allspeed: 56.29,
    trkspeed: 59.84,
    ttivmt: 2463977.0,
    trkttivmt: 378628.0,
  },
  {
    agglevel: "county",
    regioncode: 71,
    year: 2020,
    count: 2362,
    len_sec: 842.9279999999968,
    lanemiles: 1997.6819999999918,
    delay: 3135162.0,
    trkdelay: 200003.0,
    pti80: 1.12,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.02,
    ghg: 13129.0,
    congcost: 7.0127481e7,
    allspeed: 56.42,
    trkspeed: 61.56,
    ttivmt: 2475341.0,
    trkttivmt: 380698.0,
  },
  {
    agglevel: "county",
    regioncode: 71,
    year: 2021,
    count: 2584,
    len_sec: 844.7979999999963,
    lanemiles: 2002.5319999999936,
    delay: 2255005.0,
    trkdelay: 177621.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.03,
    ghg: 10064.0,
    congcost: 5.2711204e7,
    allspeed: 56.23,
    trkspeed: 61.34,
    ttivmt: 2384997.0,
    trkttivmt: 339821.0,
  },
  {
    agglevel: "county",
    regioncode: 71,
    year: 2022,
    count: 2686,
    len_sec: 857.6589999999951,
    lanemiles: 2088.8299999999917,
    delay: 4694320.0,
    trkdelay: 324182.0,
    pti80: 1.15,
    trkpti80: 1.1,
    pti95: 1.21,
    trkpti95: 1.13,
    tti: 1.06,
    trktti: 1.04,
    ghg: 19939.0,
    congcost: 1.19823526e8,
    allspeed: 55.33,
    trkspeed: 61.25,
    ttivmt: 2671245.0,
    trkttivmt: 379664.0,
  },
  {
    agglevel: "county",
    regioncode: 72,
    year: 2017,
    count: 2403,
    len_sec: 1012.9510000000006,
    lanemiles: 3234.8249999999975,
    delay: 2.6729998e7,
    trkdelay: 1024056.0,
    pti80: 1.26,
    trkpti80: 1.26,
    pti95: 1.33,
    trkpti95: 1.37,
    tti: 1.12,
    trktti: 1.11,
    ghg: 110635.0,
    congcost: 5.28150145e8,
    allspeed: 42.27,
    trkspeed: 46.58,
    ttivmt: 8906359.0,
    trkttivmt: 518621.0,
  },
  {
    agglevel: "county",
    regioncode: 72,
    year: 2018,
    count: 2165,
    len_sec: 1025.741999999999,
    lanemiles: 3283.9820000000004,
    delay: 2.2580702e7,
    trkdelay: 1111138.0,
    pti80: 1.27,
    trkpti80: 1.32,
    pti95: 1.35,
    trkpti95: 1.43,
    tti: 1.14,
    trktti: 1.14,
    ghg: 96391.0,
    congcost: 4.63770271e8,
    allspeed: 44.16,
    trkspeed: 48.5,
    ttivmt: 6428771.0,
    trkttivmt: 375938.0,
  },
  {
    agglevel: "county",
    regioncode: 72,
    year: 2019,
    count: 1671,
    len_sec: 1007.4249999999989,
    lanemiles: 3233.551,
    delay: 2.4558964e7,
    trkdelay: 1575826.0,
    pti80: 1.25,
    trkpti80: 1.29,
    pti95: 1.31,
    trkpti95: 1.39,
    tti: 1.12,
    trktti: 1.14,
    ghg: 112900.0,
    congcost: 5.36783386e8,
    allspeed: 43.97,
    trkspeed: 46.33,
    ttivmt: 6914973.0,
    trkttivmt: 468103.0,
  },
  {
    agglevel: "county",
    regioncode: 72,
    year: 2020,
    count: 3383,
    len_sec: 1042.7499999999955,
    lanemiles: 3313.5029999999824,
    delay: 2.5021941e7,
    trkdelay: 1232669.0,
    pti80: 1.23,
    trkpti80: 1.24,
    pti95: 1.29,
    trkpti95: 1.33,
    tti: 1.12,
    trktti: 1.11,
    ghg: 109496.0,
    congcost: 5.48124794e8,
    allspeed: 43.98,
    trkspeed: 49.23,
    ttivmt: 7234563.0,
    trkttivmt: 430547.0,
  },
  {
    agglevel: "county",
    regioncode: 72,
    year: 2021,
    count: 3340,
    len_sec: 1049.0289999999945,
    lanemiles: 3331.526999999995,
    delay: 1.327063e7,
    trkdelay: 931868.0,
    pti80: 1.16,
    trkpti80: 1.2,
    pti95: 1.2,
    trkpti95: 1.31,
    tti: 1.07,
    trktti: 1.08,
    ghg: 61406.0,
    congcost: 3.07300772e8,
    allspeed: 45.55,
    trkspeed: 51.16,
    ttivmt: 6400143.0,
    trkttivmt: 420175.0,
  },
  {
    agglevel: "county",
    regioncode: 72,
    year: 2022,
    count: 3561,
    len_sec: 1060.7319999999954,
    lanemiles: 3377.3049999999894,
    delay: 1.7870284e7,
    trkdelay: 1097677.0,
    pti80: 1.2,
    trkpti80: 1.23,
    pti95: 1.26,
    trkpti95: 1.34,
    tti: 1.09,
    trktti: 1.1,
    ghg: 77936.0,
    congcost: 4.52284165e8,
    allspeed: 44.41,
    trkspeed: 50.89,
    ttivmt: 6754347.0,
    trkttivmt: 438934.0,
  },
  {
    agglevel: "county",
    regioncode: 73,
    year: 2017,
    count: 502,
    len_sec: 416.0129999999993,
    lanemiles: 890.2799999999995,
    delay: 688798.0,
    trkdelay: 53027.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.05,
    trktti: 1.03,
    ghg: 2916.0,
    congcost: 1.4403766e7,
    allspeed: 53.14,
    trkspeed: 59.76,
    ttivmt: 472082.0,
    trkttivmt: 77347.0,
  },
  {
    agglevel: "county",
    regioncode: 73,
    year: 2018,
    count: 535,
    len_sec: 419.4819999999998,
    lanemiles: 901.4029999999997,
    delay: 606010.0,
    trkdelay: 72080.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.05,
    ghg: 2898.0,
    congcost: 1.3788401e7,
    allspeed: 53.18,
    trkspeed: 55.68,
    ttivmt: 442968.0,
    trkttivmt: 62258.0,
  },
  {
    agglevel: "county",
    regioncode: 73,
    year: 2019,
    count: 243,
    len_sec: 418.5159999999999,
    lanemiles: 899.4569999999997,
    delay: 659324.0,
    trkdelay: 78876.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.05,
    trktti: 1.05,
    ghg: 3280.0,
    congcost: 1.5648879e7,
    allspeed: 55.51,
    trkspeed: 57.61,
    ttivmt: 444883.0,
    trkttivmt: 59442.0,
  },
  {
    agglevel: "county",
    regioncode: 73,
    year: 2020,
    count: 827,
    len_sec: 418.49999999999994,
    lanemiles: 901.4239999999994,
    delay: 745016.0,
    trkdelay: 54210.0,
    pti80: 1.12,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.04,
    ghg: 3179.0,
    congcost: 1.6919577e7,
    allspeed: 55.55,
    trkspeed: 59.51,
    ttivmt: 502919.0,
    trkttivmt: 57256.0,
  },
  {
    agglevel: "county",
    regioncode: 73,
    year: 2021,
    count: 857,
    len_sec: 418.5189999999996,
    lanemiles: 903.0730000000001,
    delay: 551786.0,
    trkdelay: 51272.0,
    pti80: 1.12,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.04,
    ghg: 2440.0,
    congcost: 1.3125944e7,
    allspeed: 55.25,
    trkspeed: 58.64,
    ttivmt: 466349.0,
    trkttivmt: 50855.0,
  },
  {
    agglevel: "county",
    regioncode: 73,
    year: 2022,
    count: 893,
    len_sec: 418.58799999999985,
    lanemiles: 908.359,
    delay: 717286.0,
    trkdelay: 51875.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.17,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.03,
    ghg: 2737.0,
    congcost: 1.8316679e7,
    allspeed: 54.94,
    trkspeed: 58.62,
    ttivmt: 519089.0,
    trkttivmt: 58559.0,
  },
  {
    agglevel: "county",
    regioncode: 74,
    year: 2017,
    count: 419,
    len_sec: 389.5079999999999,
    lanemiles: 834.6620000000001,
    delay: 156371.0,
    trkdelay: 20286.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 543.0,
    congcost: 3493567.0,
    allspeed: 60.31,
    trkspeed: 61.9,
    ttivmt: 289872.0,
    trkttivmt: 51872.0,
  },
  {
    agglevel: "county",
    regioncode: 74,
    year: 2018,
    count: 568,
    len_sec: 388.8530000000001,
    lanemiles: 833.6530000000002,
    delay: 139900.0,
    trkdelay: 24066.0,
    pti80: 1.07,
    trkpti80: 1.06,
    pti95: 1.1,
    trkpti95: 1.08,
    tti: 1.02,
    trktti: 1.02,
    ghg: 476.0,
    congcost: 3371212.0,
    allspeed: 58.66,
    trkspeed: 60.44,
    ttivmt: 302237.0,
    trkttivmt: 55179.0,
  },
  {
    agglevel: "county",
    regioncode: 74,
    year: 2019,
    count: 153,
    len_sec: 221.68800000000007,
    lanemiles: 499.9610000000002,
    delay: 141862.0,
    trkdelay: 31926.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 583.0,
    congcost: 3819834.0,
    allspeed: 61.38,
    trkspeed: 59.01,
    ttivmt: 290606.0,
    trkttivmt: 55601.0,
  },
  {
    agglevel: "county",
    regioncode: 74,
    year: 2020,
    count: 783,
    len_sec: 389.0589999999999,
    lanemiles: 833.876,
    delay: 186443.0,
    trkdelay: 24136.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.01,
    ghg: 711.0,
    congcost: 4587224.0,
    allspeed: 60.8,
    trkspeed: 62.75,
    ttivmt: 325128.0,
    trkttivmt: 67505.0,
  },
  {
    agglevel: "county",
    regioncode: 74,
    year: 2021,
    count: 765,
    len_sec: 387.7609999999995,
    lanemiles: 832.655999999999,
    delay: 93586.0,
    trkdelay: 14796.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 399.0,
    congcost: 2405545.0,
    allspeed: 60.71,
    trkspeed: 62.23,
    ttivmt: 285509.0,
    trkttivmt: 56181.0,
  },
  {
    agglevel: "county",
    regioncode: 74,
    year: 2022,
    count: 768,
    len_sec: 387.7949999999993,
    lanemiles: 839.0119999999985,
    delay: 189255.0,
    trkdelay: 31234.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.17,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 715.0,
    congcost: 5417819.0,
    allspeed: 60.58,
    trkspeed: 61.9,
    ttivmt: 312213.0,
    trkttivmt: 57608.0,
  },
  {
    agglevel: "county",
    regioncode: 75,
    year: 2017,
    count: 921,
    len_sec: 498.7859999999997,
    lanemiles: 1010.9899999999994,
    delay: 407894.0,
    trkdelay: 43092.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 1744.0,
    congcost: 8879294.0,
    allspeed: 55.61,
    trkspeed: 58.48,
    ttivmt: 322493.0,
    trkttivmt: 45467.0,
  },
  {
    agglevel: "county",
    regioncode: 75,
    year: 2018,
    count: 888,
    len_sec: 496.5909999999995,
    lanemiles: 1006.380999999999,
    delay: 318250.0,
    trkdelay: 34400.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.04,
    ghg: 1330.0,
    congcost: 7097961.0,
    allspeed: 54.1,
    trkspeed: 56.29,
    ttivmt: 306320.0,
    trkttivmt: 38187.0,
  },
  {
    agglevel: "county",
    regioncode: 75,
    year: 2019,
    count: 374,
    len_sec: 496.17400000000015,
    lanemiles: 1010.5100000000003,
    delay: 327704.0,
    trkdelay: 55886.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.05,
    ghg: 1921.0,
    congcost: 8383720.0,
    allspeed: 56.75,
    trkspeed: 55.83,
    ttivmt: 314869.0,
    trkttivmt: 41419.0,
  },
  {
    agglevel: "county",
    regioncode: 75,
    year: 2020,
    count: 1389,
    len_sec: 496.68499999999875,
    lanemiles: 1011.9319999999979,
    delay: 412680.0,
    trkdelay: 60565.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.16,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.06,
    ghg: 2163.0,
    congcost: 1.0537952e7,
    allspeed: 56.14,
    trkspeed: 57.38,
    ttivmt: 322049.0,
    trkttivmt: 44341.0,
  },
  {
    agglevel: "county",
    regioncode: 75,
    year: 2021,
    count: 1362,
    len_sec: 494.28999999999917,
    lanemiles: 1008.0699999999988,
    delay: 318956.0,
    trkdelay: 47285.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.05,
    ghg: 1563.0,
    congcost: 8136225.0,
    allspeed: 56.65,
    trkspeed: 58.11,
    ttivmt: 350171.0,
    trkttivmt: 44383.0,
  },
  {
    agglevel: "county",
    regioncode: 75,
    year: 2022,
    count: 1371,
    len_sec: 494.3629999999985,
    lanemiles: 1008.2329999999974,
    delay: 416698.0,
    trkdelay: 51661.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.19,
    trkpti95: 1.21,
    tti: 1.04,
    trktti: 1.05,
    ghg: 1735.0,
    congcost: 1.1396316e7,
    allspeed: 55.53,
    trkspeed: 57.18,
    ttivmt: 355275.0,
    trkttivmt: 43941.0,
  },
  {
    agglevel: "county",
    regioncode: 76,
    year: 2017,
    count: 671,
    len_sec: 449.35299999999955,
    lanemiles: 1048.9909999999993,
    delay: 334783.0,
    trkdelay: 37505.0,
    pti80: 1.07,
    trkpti80: 1.06,
    pti95: 1.1,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1444.0,
    congcost: 7353324.0,
    allspeed: 60.92,
    trkspeed: 63.45,
    ttivmt: 743999.0,
    trkttivmt: 217899.0,
  },
  {
    agglevel: "county",
    regioncode: 76,
    year: 2018,
    count: 787,
    len_sec: 449.5920000000004,
    lanemiles: 1049.627000000002,
    delay: 330954.0,
    trkdelay: 58270.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1800.0,
    congcost: 8146706.0,
    allspeed: 60.68,
    trkspeed: 61.93,
    ttivmt: 732572.0,
    trkttivmt: 147674.0,
  },
  {
    agglevel: "county",
    regioncode: 76,
    year: 2019,
    count: 260,
    len_sec: 304.56300000000016,
    lanemiles: 759.5630000000002,
    delay: 263648.0,
    trkdelay: 63622.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.02,
    ghg: 1673.0,
    congcost: 7378795.0,
    allspeed: 61.7,
    trkspeed: 61.93,
    ttivmt: 715223.0,
    trkttivmt: 149503.0,
  },
  {
    agglevel: "county",
    regioncode: 76,
    year: 2020,
    count: 1056,
    len_sec: 451.15299999999945,
    lanemiles: 1052.7489999999987,
    delay: 378502.0,
    trkdelay: 61411.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1518.0,
    congcost: 9755308.0,
    allspeed: 60.84,
    trkspeed: 62.79,
    ttivmt: 772232.0,
    trkttivmt: 159554.0,
  },
  {
    agglevel: "county",
    regioncode: 76,
    year: 2021,
    count: 1075,
    len_sec: 451.25599999999963,
    lanemiles: 1052.7849999999983,
    delay: 274774.0,
    trkdelay: 39194.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1258.0,
    congcost: 6946031.0,
    allspeed: 60.77,
    trkspeed: 62.8,
    ttivmt: 675301.0,
    trkttivmt: 130458.0,
  },
  {
    agglevel: "county",
    regioncode: 76,
    year: 2022,
    count: 1072,
    len_sec: 449.43399999999946,
    lanemiles: 1051.371999999999,
    delay: 416754.0,
    trkdelay: 65787.0,
    pti80: 1.11,
    trkpti80: 1.07,
    pti95: 1.18,
    trkpti95: 1.09,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1497.0,
    congcost: 1.1792961e7,
    allspeed: 61.03,
    trkspeed: 62.61,
    ttivmt: 802123.0,
    trkttivmt: 141821.0,
  },
  {
    agglevel: "county",
    regioncode: 77,
    year: 2017,
    count: 195,
    len_sec: 274.55299999999994,
    lanemiles: 556.0400000000001,
    delay: 34971.0,
    trkdelay: 6157.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 110.0,
    congcost: 828089.0,
    allspeed: 60.75,
    trkspeed: 62.36,
    ttivmt: 61340.0,
    trkttivmt: 16257.0,
  },
  {
    agglevel: "county",
    regioncode: 77,
    year: 2018,
    count: 283,
    len_sec: 275.13200000000006,
    lanemiles: 558.2680000000001,
    delay: 30990.0,
    trkdelay: 5387.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.1,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 117.0,
    congcost: 750839.0,
    allspeed: 58.13,
    trkspeed: 57.15,
    ttivmt: 62670.0,
    trkttivmt: 7500.0,
  },
  {
    agglevel: "county",
    regioncode: 77,
    year: 2019,
    count: 123,
    len_sec: 275.258,
    lanemiles: 558.52,
    delay: 36700.0,
    trkdelay: 11001.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.12,
    trkpti95: 1.17,
    tti: 1.02,
    trktti: 1.05,
    ghg: 207.0,
    congcost: 1093162.0,
    allspeed: 61.57,
    trkspeed: 59.5,
    ttivmt: 72419.0,
    trkttivmt: 10503.0,
  },
  {
    agglevel: "county",
    regioncode: 77,
    year: 2020,
    count: 395,
    len_sec: 275.2779999999997,
    lanemiles: 558.5599999999995,
    delay: 61105.0,
    trkdelay: 13723.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 296.0,
    congcost: 1721606.0,
    allspeed: 60.25,
    trkspeed: 60.36,
    ttivmt: 71720.0,
    trkttivmt: 14798.0,
  },
  {
    agglevel: "county",
    regioncode: 77,
    year: 2021,
    count: 384,
    len_sec: 274.69099999999986,
    lanemiles: 557.6399999999999,
    delay: 59511.0,
    trkdelay: 15289.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.16,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.06,
    ghg: 279.0,
    congcost: 1704547.0,
    allspeed: 59.73,
    trkspeed: 59.51,
    ttivmt: 63508.0,
    trkttivmt: 11848.0,
  },
  {
    agglevel: "county",
    regioncode: 77,
    year: 2022,
    count: 383,
    len_sec: 274.74399999999974,
    lanemiles: 557.7579999999997,
    delay: 62910.0,
    trkdelay: 17496.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.18,
    trkpti95: 1.24,
    tti: 1.03,
    trktti: 1.05,
    ghg: 350.0,
    congcost: 2068790.0,
    allspeed: 59.91,
    trkspeed: 59.73,
    ttivmt: 63542.0,
    trkttivmt: 12082.0,
  },
  {
    agglevel: "county",
    regioncode: 78,
    year: 2017,
    count: 254,
    len_sec: 336.3720000000001,
    lanemiles: 727.6260000000002,
    delay: 64869.0,
    trkdelay: 10296.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 247.0,
    congcost: 1509521.0,
    allspeed: 59.13,
    trkspeed: 60.14,
    ttivmt: 73811.0,
    trkttivmt: 12702.0,
  },
  {
    agglevel: "county",
    regioncode: 78,
    year: 2018,
    count: 198,
    len_sec: 335.5609999999998,
    lanemiles: 725.6519999999998,
    delay: 27881.0,
    trkdelay: 4059.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 101.0,
    congcost: 650949.0,
    allspeed: 50.44,
    trkspeed: 48.74,
    ttivmt: 68909.0,
    trkttivmt: 7341.0,
  },
  {
    agglevel: "county",
    regioncode: 78,
    year: 2019,
    count: 158,
    len_sec: 335.70300000000003,
    lanemiles: 725.4860000000002,
    delay: 57376.0,
    trkdelay: 9562.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.14,
    trkpti95: 1.19,
    tti: 1.03,
    trktti: 1.05,
    ghg: 229.0,
    congcost: 1437497.0,
    allspeed: 59.58,
    trkspeed: 58.75,
    ttivmt: 67895.0,
    trkttivmt: 7942.0,
  },
  {
    agglevel: "county",
    regioncode: 78,
    year: 2020,
    count: 337,
    len_sec: 335.7169999999995,
    lanemiles: 725.513999999999,
    delay: 75924.0,
    trkdelay: 7565.0,
    pti80: 1.13,
    trkpti80: 1.11,
    pti95: 1.19,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.04,
    ghg: 277.0,
    congcost: 1787422.0,
    allspeed: 58.23,
    trkspeed: 59.35,
    ttivmt: 64796.0,
    trkttivmt: 7616.0,
  },
  {
    agglevel: "county",
    regioncode: 78,
    year: 2021,
    count: 332,
    len_sec: 335.71899999999965,
    lanemiles: 725.5179999999997,
    delay: 47022.0,
    trkdelay: 10858.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 272.0,
    congcost: 1321666.0,
    allspeed: 58.67,
    trkspeed: 58.4,
    ttivmt: 59214.0,
    trkttivmt: 12083.0,
  },
  {
    agglevel: "county",
    regioncode: 78,
    year: 2022,
    count: 326,
    len_sec: 335.7189999999998,
    lanemiles: 725.5179999999991,
    delay: 73325.0,
    trkdelay: 11596.0,
    pti80: 1.13,
    trkpti80: 1.15,
    pti95: 1.21,
    trkpti95: 1.25,
    tti: 1.04,
    trktti: 1.06,
    ghg: 277.0,
    congcost: 2083696.0,
    allspeed: 57.53,
    trkspeed: 57.88,
    ttivmt: 65487.0,
    trkttivmt: 8874.0,
  },
  {
    agglevel: "county",
    regioncode: 79,
    year: 2017,
    count: 136,
    len_sec: 148.70199999999997,
    lanemiles: 297.702,
    delay: 8930.0,
    trkdelay: 2621.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.08,
    trkpti95: 1.09,
    tti: 1.02,
    trktti: 1.02,
    ghg: 39.0,
    congcost: 244806.0,
    allspeed: 60.16,
    trkspeed: 61.87,
    ttivmt: 19076.0,
    trkttivmt: 6999.0,
  },
  {
    agglevel: "county",
    regioncode: 79,
    year: 2018,
    count: 155,
    len_sec: 146.88600000000002,
    lanemiles: 294.05800000000005,
    delay: 7205.0,
    trkdelay: 2227.0,
    pti80: 1.07,
    trkpti80: 1.09,
    pti95: 1.08,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 30.0,
    congcost: 205617.0,
    allspeed: 57.62,
    trkspeed: 59.09,
    ttivmt: 18314.0,
    trkttivmt: 3626.0,
  },
  {
    agglevel: "county",
    regioncode: 79,
    year: 2019,
    count: 54,
    len_sec: 148.89,
    lanemiles: 298.066,
    delay: 11314.0,
    trkdelay: 3970.0,
    pti80: 1.08,
    trkpti80: 1.13,
    pti95: 1.1,
    trkpti95: 1.17,
    tti: 1.02,
    trktti: 1.05,
    ghg: 74.0,
    congcost: 357977.0,
    allspeed: 60.48,
    trkspeed: 59.8,
    ttivmt: 18698.0,
    trkttivmt: 3798.0,
  },
  {
    agglevel: "county",
    regioncode: 79,
    year: 2020,
    count: 245,
    len_sec: 148.89199999999997,
    lanemiles: 298.0699999999999,
    delay: 19645.0,
    trkdelay: 5364.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.05,
    ghg: 83.0,
    congcost: 586967.0,
    allspeed: 59.37,
    trkspeed: 60.14,
    ttivmt: 23893.0,
    trkttivmt: 4711.0,
  },
  {
    agglevel: "county",
    regioncode: 79,
    year: 2021,
    count: 243,
    len_sec: 148.92400000000004,
    lanemiles: 298.1340000000001,
    delay: 7613.0,
    trkdelay: 2826.0,
    pti80: 1.07,
    trkpti80: 1.09,
    pti95: 1.1,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 42.0,
    congcost: 246369.0,
    allspeed: 60.01,
    trkspeed: 61.26,
    ttivmt: 16142.0,
    trkttivmt: 4458.0,
  },
  {
    agglevel: "county",
    regioncode: 79,
    year: 2022,
    count: 243,
    len_sec: 148.949,
    lanemiles: 298.184,
    delay: 22908.0,
    trkdelay: 7310.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.17,
    trkpti95: 1.23,
    tti: 1.03,
    trktti: 1.05,
    ghg: 85.0,
    congcost: 776715.0,
    allspeed: 60.07,
    trkspeed: 59.95,
    ttivmt: 23267.0,
    trkttivmt: 5294.0,
  },
  {
    agglevel: "county",
    regioncode: 80,
    year: 2017,
    count: 1499,
    len_sec: 840.5870000000007,
    lanemiles: 2327.9309999999973,
    delay: 1.9047555e7,
    trkdelay: 784998.0,
    pti80: 1.28,
    trkpti80: 1.27,
    pti95: 1.35,
    trkpti95: 1.35,
    tti: 1.15,
    trktti: 1.12,
    ghg: 74409.0,
    congcost: 3.77113674e8,
    allspeed: 43.21,
    trkspeed: 49.35,
    ttivmt: 5847002.0,
    trkttivmt: 370167.0,
  },
  {
    agglevel: "county",
    regioncode: 80,
    year: 2018,
    count: 1542,
    len_sec: 878.977999999998,
    lanemiles: 2394.001999999995,
    delay: 1.9544104e7,
    trkdelay: 921661.0,
    pti80: 1.35,
    trkpti80: 1.39,
    pti95: 1.44,
    trkpti95: 1.53,
    tti: 1.2,
    trktti: 1.19,
    ghg: 81509.0,
    congcost: 3.99798294e8,
    allspeed: 42.52,
    trkspeed: 47.6,
    ttivmt: 4487745.0,
    trkttivmt: 268216.0,
  },
  {
    agglevel: "county",
    regioncode: 80,
    year: 2019,
    count: 890,
    len_sec: 656.9719999999986,
    lanemiles: 1962.990999999997,
    delay: 1.7001337e7,
    trkdelay: 926946.0,
    pti80: 1.31,
    trkpti80: 1.33,
    pti95: 1.38,
    trkpti95: 1.44,
    tti: 1.17,
    trktti: 1.16,
    ghg: 73265.0,
    congcost: 3.65100442e8,
    allspeed: 43.63,
    trkspeed: 47.51,
    ttivmt: 4504413.0,
    trkttivmt: 280847.0,
  },
  {
    agglevel: "county",
    regioncode: 80,
    year: 2020,
    count: 2234,
    len_sec: 867.8439999999957,
    lanemiles: 2480.7999999999956,
    delay: 1.7208663e7,
    trkdelay: 699486.0,
    pti80: 1.26,
    trkpti80: 1.24,
    pti95: 1.31,
    trkpti95: 1.31,
    tti: 1.14,
    trktti: 1.13,
    ghg: 70904.0,
    congcost: 3.70773028e8,
    allspeed: 43.88,
    trkspeed: 50.43,
    ttivmt: 4927949.0,
    trkttivmt: 253922.0,
  },
  {
    agglevel: "county",
    regioncode: 80,
    year: 2021,
    count: 2395,
    len_sec: 874.8879999999953,
    lanemiles: 2527.5819999999894,
    delay: 8818015.0,
    trkdelay: 485264.0,
    pti80: 1.18,
    trkpti80: 1.19,
    pti95: 1.23,
    trkpti95: 1.32,
    tti: 1.08,
    trktti: 1.09,
    ghg: 37425.0,
    congcost: 1.99662408e8,
    allspeed: 45.86,
    trkspeed: 52.2,
    ttivmt: 4475998.0,
    trkttivmt: 237382.0,
  },
  {
    agglevel: "county",
    regioncode: 80,
    year: 2022,
    count: 2468,
    len_sec: 893.7049999999957,
    lanemiles: 2636.0349999999917,
    delay: 1.3763692e7,
    trkdelay: 746215.0,
    pti80: 1.22,
    trkpti80: 1.24,
    pti95: 1.27,
    trkpti95: 1.37,
    tti: 1.11,
    trktti: 1.1,
    ghg: 58372.0,
    congcost: 3.44546815e8,
    allspeed: 44.59,
    trkspeed: 50.7,
    ttivmt: 4973722.0,
    trkttivmt: 273439.0,
  },
  {
    agglevel: "county",
    regioncode: 81,
    year: 2017,
    count: 239,
    len_sec: 156.77799999999996,
    lanemiles: 338.6419999999999,
    delay: 127327.0,
    trkdelay: 27099.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.08,
    tti: 1.02,
    trktti: 1.01,
    ghg: 488.0,
    congcost: 3159346.0,
    allspeed: 60.29,
    trkspeed: 63.47,
    ttivmt: 236461.0,
    trkttivmt: 105413.0,
  },
  {
    agglevel: "county",
    regioncode: 81,
    year: 2018,
    count: 226,
    len_sec: 158.068,
    lanemiles: 341.3199999999999,
    delay: 118574.0,
    trkdelay: 11987.0,
    pti80: 1.1,
    trkpti80: 1.05,
    pti95: 1.14,
    trkpti95: 1.06,
    tti: 1.02,
    trktti: 1.01,
    ghg: 536.0,
    congcost: 2626045.0,
    allspeed: 59.13,
    trkspeed: 62.72,
    ttivmt: 222723.0,
    trkttivmt: 51247.0,
  },
  {
    agglevel: "county",
    regioncode: 81,
    year: 2019,
    count: 104,
    len_sec: 158.084,
    lanemiles: 341.35200000000003,
    delay: 143418.0,
    trkdelay: 37469.0,
    pti80: 1.1,
    trkpti80: 1.06,
    pti95: 1.14,
    trkpti95: 1.08,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1050.0,
    congcost: 4133900.0,
    allspeed: 60.04,
    trkspeed: 59.11,
    ttivmt: 226848.0,
    trkttivmt: 63211.0,
  },
  {
    agglevel: "county",
    regioncode: 81,
    year: 2020,
    count: 366,
    len_sec: 158.08999999999992,
    lanemiles: 343.68999999999977,
    delay: 250135.0,
    trkdelay: 45295.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.01,
    ghg: 1402.0,
    congcost: 6691428.0,
    allspeed: 59.98,
    trkspeed: 62.28,
    ttivmt: 254512.0,
    trkttivmt: 68159.0,
  },
  {
    agglevel: "county",
    regioncode: 81,
    year: 2021,
    count: 361,
    len_sec: 158.09099999999992,
    lanemiles: 345.71600000000007,
    delay: 83670.0,
    trkdelay: 8600.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 379.0,
    congcost: 2017367.0,
    allspeed: 60.75,
    trkspeed: 63.57,
    ttivmt: 247489.0,
    trkttivmt: 64951.0,
  },
  {
    agglevel: "county",
    regioncode: 81,
    year: 2022,
    count: 360,
    len_sec: 158.10699999999997,
    lanemiles: 345.78200000000004,
    delay: 157344.0,
    trkdelay: 22906.0,
    pti80: 1.1,
    trkpti80: 1.05,
    pti95: 1.15,
    trkpti95: 1.07,
    tti: 1.02,
    trktti: 1.01,
    ghg: 608.0,
    congcost: 4399077.0,
    allspeed: 59.64,
    trkspeed: 63.21,
    ttivmt: 213512.0,
    trkttivmt: 73186.0,
  },
  {
    agglevel: "county",
    regioncode: 82,
    year: 2017,
    count: 460,
    len_sec: 387.9909999999999,
    lanemiles: 846.1139999999994,
    delay: 265246.0,
    trkdelay: 35739.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1028.0,
    congcost: 5984799.0,
    allspeed: 61.79,
    trkspeed: 63.51,
    ttivmt: 636230.0,
    trkttivmt: 174230.0,
  },
  {
    agglevel: "county",
    regioncode: 82,
    year: 2018,
    count: 502,
    len_sec: 391.5219999999999,
    lanemiles: 850.7819999999992,
    delay: 232533.0,
    trkdelay: 37328.0,
    pti80: 1.08,
    trkpti80: 1.05,
    pti95: 1.12,
    trkpti95: 1.06,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1086.0,
    congcost: 5579247.0,
    allspeed: 61.31,
    trkspeed: 62.83,
    ttivmt: 638863.0,
    trkttivmt: 145671.0,
  },
  {
    agglevel: "county",
    regioncode: 82,
    year: 2019,
    count: 183,
    len_sec: 354.4720000000001,
    lanemiles: 777.7860000000002,
    delay: 210303.0,
    trkdelay: 45759.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1107.0,
    congcost: 5672427.0,
    allspeed: 62.39,
    trkspeed: 62.3,
    ttivmt: 623198.0,
    trkttivmt: 144679.0,
  },
  {
    agglevel: "county",
    regioncode: 82,
    year: 2020,
    count: 699,
    len_sec: 390.90999999999997,
    lanemiles: 850.6539999999999,
    delay: 301818.0,
    trkdelay: 42842.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.01,
    ghg: 1457.0,
    congcost: 7621285.0,
    allspeed: 61.97,
    trkspeed: 63.63,
    ttivmt: 645442.0,
    trkttivmt: 175118.0,
  },
  {
    agglevel: "county",
    regioncode: 82,
    year: 2021,
    count: 711,
    len_sec: 390.82699999999977,
    lanemiles: 850.4859999999996,
    delay: 197673.0,
    trkdelay: 30227.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 999.0,
    congcost: 5073981.0,
    allspeed: 62.23,
    trkspeed: 63.32,
    ttivmt: 611114.0,
    trkttivmt: 116369.0,
  },
  {
    agglevel: "county",
    regioncode: 82,
    year: 2022,
    count: 751,
    len_sec: 391.0659999999992,
    lanemiles: 851.1039999999975,
    delay: 383831.0,
    trkdelay: 74929.0,
    pti80: 1.14,
    trkpti80: 1.08,
    pti95: 1.22,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1653.0,
    congcost: 1.1402996e7,
    allspeed: 61.65,
    trkspeed: 62.6,
    ttivmt: 667364.0,
    trkttivmt: 140931.0,
  },
  {
    agglevel: "county",
    regioncode: 83,
    year: 2017,
    count: 361,
    len_sec: 354.0389999999999,
    lanemiles: 788.6370000000004,
    delay: 260769.0,
    trkdelay: 33751.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1199.0,
    congcost: 5874553.0,
    allspeed: 60.61,
    trkspeed: 63.52,
    ttivmt: 528936.0,
    trkttivmt: 215117.0,
  },
  {
    agglevel: "county",
    regioncode: 83,
    year: 2018,
    count: 478,
    len_sec: 355.7949999999999,
    lanemiles: 791.7319999999996,
    delay: 207311.0,
    trkdelay: 31943.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 956.0,
    congcost: 4935223.0,
    allspeed: 61.52,
    trkspeed: 63.66,
    ttivmt: 524478.0,
    trkttivmt: 147487.0,
  },
  {
    agglevel: "county",
    regioncode: 83,
    year: 2019,
    count: 155,
    len_sec: 286.5979999999999,
    lanemiles: 653.5040000000001,
    delay: 207553.0,
    trkdelay: 48836.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1188.0,
    congcost: 5741064.0,
    allspeed: 62.23,
    trkspeed: 61.79,
    ttivmt: 538139.0,
    trkttivmt: 154629.0,
  },
  {
    agglevel: "county",
    regioncode: 83,
    year: 2020,
    count: 625,
    len_sec: 355.9899999999998,
    lanemiles: 792.1099999999997,
    delay: 230404.0,
    trkdelay: 44088.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1225.0,
    congcost: 6241620.0,
    allspeed: 62.32,
    trkspeed: 63.78,
    ttivmt: 588542.0,
    trkttivmt: 165628.0,
  },
  {
    agglevel: "county",
    regioncode: 83,
    year: 2021,
    count: 610,
    len_sec: 354.5059999999998,
    lanemiles: 790.6259999999999,
    delay: 266898.0,
    trkdelay: 45528.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.01,
    ghg: 1232.0,
    congcost: 6955043.0,
    allspeed: 61.77,
    trkspeed: 63.85,
    ttivmt: 506427.0,
    trkttivmt: 169230.0,
  },
  {
    agglevel: "county",
    regioncode: 83,
    year: 2022,
    count: 635,
    len_sec: 355.49699999999945,
    lanemiles: 792.5459999999989,
    delay: 258460.0,
    trkdelay: 59198.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.17,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1194.0,
    congcost: 7997539.0,
    allspeed: 61.75,
    trkspeed: 63.26,
    ttivmt: 537940.0,
    trkttivmt: 166648.0,
  },
  {
    agglevel: "county",
    regioncode: 84,
    year: 2017,
    count: 260,
    len_sec: 424.98300000000006,
    lanemiles: 967.8800000000001,
    delay: 224642.0,
    trkdelay: 24905.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.01,
    ghg: 973.0,
    congcost: 4926201.0,
    allspeed: 58.56,
    trkspeed: 62.96,
    ttivmt: 284678.0,
    trkttivmt: 75283.0,
  },
  {
    agglevel: "county",
    regioncode: 84,
    year: 2018,
    count: 216,
    len_sec: 424.9390000000002,
    lanemiles: 967.5940000000005,
    delay: 275000.0,
    trkdelay: 38788.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.05,
    ghg: 1509.0,
    congcost: 6480512.0,
    allspeed: 57.17,
    trkspeed: 59.03,
    ttivmt: 292382.0,
    trkttivmt: 38592.0,
  },
  {
    agglevel: "county",
    regioncode: 84,
    year: 2019,
    count: 176,
    len_sec: 424.9349999999999,
    lanemiles: 967.5859999999998,
    delay: 250965.0,
    trkdelay: 45421.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1492.0,
    congcost: 6519361.0,
    allspeed: 59.53,
    trkspeed: 59.25,
    ttivmt: 325375.0,
    trkttivmt: 47956.0,
  },
  {
    agglevel: "county",
    regioncode: 84,
    year: 2020,
    count: 313,
    len_sec: 424.942,
    lanemiles: 968.8579999999995,
    delay: 284087.0,
    trkdelay: 49283.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1692.0,
    congcost: 7560385.0,
    allspeed: 59.3,
    trkspeed: 60.33,
    ttivmt: 320555.0,
    trkttivmt: 44425.0,
  },
  {
    agglevel: "county",
    regioncode: 84,
    year: 2021,
    count: 317,
    len_sec: 424.94199999999944,
    lanemiles: 968.7039999999997,
    delay: 215520.0,
    trkdelay: 48552.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 1526.0,
    congcost: 6066642.0,
    allspeed: 59.23,
    trkspeed: 60.25,
    ttivmt: 296466.0,
    trkttivmt: 44257.0,
  },
  {
    agglevel: "county",
    regioncode: 84,
    year: 2022,
    count: 317,
    len_sec: 424.97399999999965,
    lanemiles: 968.7680000000001,
    delay: 312324.0,
    trkdelay: 34731.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.18,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1469.0,
    congcost: 8451748.0,
    allspeed: 59.03,
    trkspeed: 60.42,
    ttivmt: 330202.0,
    trkttivmt: 46367.0,
  },
  {
    agglevel: "county",
    regioncode: 85,
    year: 2017,
    count: 1268,
    len_sec: 579.8169999999989,
    lanemiles: 1663.5369999999964,
    delay: 7895972.0,
    trkdelay: 260222.0,
    pti80: 1.18,
    trkpti80: 1.18,
    pti95: 1.22,
    trkpti95: 1.24,
    tti: 1.09,
    trktti: 1.08,
    ghg: 31561.0,
    congcost: 1.54565129e8,
    allspeed: 44.78,
    trkspeed: 46.74,
    ttivmt: 3094587.0,
    trkttivmt: 124705.0,
  },
  {
    agglevel: "county",
    regioncode: 85,
    year: 2018,
    count: 1163,
    len_sec: 593.674999999999,
    lanemiles: 1663.1099999999972,
    delay: 7824123.0,
    trkdelay: 327043.0,
    pti80: 1.23,
    trkpti80: 1.32,
    pti95: 1.29,
    trkpti95: 1.43,
    tti: 1.12,
    trktti: 1.15,
    ghg: 32591.0,
    congcost: 1.58793755e8,
    allspeed: 45.85,
    trkspeed: 44.68,
    ttivmt: 2464902.0,
    trkttivmt: 97194.0,
  },
  {
    agglevel: "county",
    regioncode: 85,
    year: 2019,
    count: 503,
    len_sec: 371.7029999999999,
    lanemiles: 1208.5529999999999,
    delay: 6593347.0,
    trkdelay: 358366.0,
    pti80: 1.22,
    trkpti80: 1.3,
    pti95: 1.27,
    trkpti95: 1.4,
    tti: 1.11,
    trktti: 1.14,
    ghg: 29010.0,
    congcost: 1.41725045e8,
    allspeed: 46.56,
    trkspeed: 43.29,
    ttivmt: 2281850.0,
    trkttivmt: 97175.0,
  },
  {
    agglevel: "county",
    regioncode: 85,
    year: 2020,
    count: 1863,
    len_sec: 579.2879999999988,
    lanemiles: 1665.2109999999955,
    delay: 8102311.0,
    trkdelay: 325326.0,
    pti80: 1.23,
    trkpti80: 1.3,
    pti95: 1.29,
    trkpti95: 1.41,
    tti: 1.12,
    trktti: 1.15,
    ghg: 33468.0,
    congcost: 1.74477598e8,
    allspeed: 45.51,
    trkspeed: 45.57,
    ttivmt: 2515438.0,
    trkttivmt: 88719.0,
  },
  {
    agglevel: "county",
    regioncode: 85,
    year: 2021,
    count: 1882,
    len_sec: 577.9679999999985,
    lanemiles: 1668.9829999999931,
    delay: 4201001.0,
    trkdelay: 277549.0,
    pti80: 1.15,
    trkpti80: 1.24,
    pti95: 1.19,
    trkpti95: 1.38,
    tti: 1.06,
    trktti: 1.11,
    ghg: 18944.0,
    congcost: 9.67188e7,
    allspeed: 47.03,
    trkspeed: 47.3,
    ttivmt: 2289441.0,
    trkttivmt: 101918.0,
  },
  {
    agglevel: "county",
    regioncode: 85,
    year: 2022,
    count: 1926,
    len_sec: 581.086999999998,
    lanemiles: 1675.5859999999964,
    delay: 6270610.0,
    trkdelay: 329832.0,
    pti80: 1.18,
    trkpti80: 1.22,
    pti95: 1.24,
    trkpti95: 1.36,
    tti: 1.09,
    trktti: 1.1,
    ghg: 27170.0,
    congcost: 1.56887031e8,
    allspeed: 45.23,
    trkspeed: 47.49,
    ttivmt: 2356674.0,
    trkttivmt: 119253.0,
  },
  {
    agglevel: "county",
    regioncode: 86,
    year: 2017,
    count: 202,
    len_sec: 217.6649999999999,
    lanemiles: 526.2040000000002,
    delay: 82511.0,
    trkdelay: 11629.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 383.0,
    congcost: 1888361.0,
    allspeed: 61.75,
    trkspeed: 63.5,
    ttivmt: 198891.0,
    trkttivmt: 62947.0,
  },
  {
    agglevel: "county",
    regioncode: 86,
    year: 2018,
    count: 209,
    len_sec: 217.63399999999993,
    lanemiles: 526.3290000000001,
    delay: 87816.0,
    trkdelay: 15998.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.02,
    ghg: 564.0,
    congcost: 2195158.0,
    allspeed: 61.52,
    trkspeed: 61.24,
    ttivmt: 187754.0,
    trkttivmt: 35891.0,
  },
  {
    agglevel: "county",
    regioncode: 86,
    year: 2019,
    count: 118,
    len_sec: 217.6859999999999,
    lanemiles: 526.4329999999998,
    delay: 73054.0,
    trkdelay: 15838.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.15,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.02,
    ghg: 466.0,
    congcost: 1990352.0,
    allspeed: 62.28,
    trkspeed: 61.03,
    ttivmt: 200075.0,
    trkttivmt: 33102.0,
  },
  {
    agglevel: "county",
    regioncode: 86,
    year: 2020,
    count: 291,
    len_sec: 217.70200000000006,
    lanemiles: 525.9860000000001,
    delay: 77059.0,
    trkdelay: 17063.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.02,
    ghg: 506.0,
    congcost: 2192809.0,
    allspeed: 62.02,
    trkspeed: 62.09,
    ttivmt: 194557.0,
    trkttivmt: 37039.0,
  },
  {
    agglevel: "county",
    regioncode: 86,
    year: 2021,
    count: 291,
    len_sec: 217.71899999999997,
    lanemiles: 525.6779999999999,
    delay: 63931.0,
    trkdelay: 19462.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.02,
    ghg: 493.0,
    congcost: 1957733.0,
    allspeed: 61.97,
    trkspeed: 62.19,
    ttivmt: 186110.0,
    trkttivmt: 49853.0,
  },
  {
    agglevel: "county",
    regioncode: 86,
    year: 2022,
    count: 293,
    len_sec: 217.7750000000002,
    lanemiles: 525.9000000000003,
    delay: 81163.0,
    trkdelay: 18803.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.02,
    ghg: 363.0,
    congcost: 2517972.0,
    allspeed: 61.88,
    trkspeed: 61.78,
    ttivmt: 205123.0,
    trkttivmt: 36674.0,
  },
  {
    agglevel: "county",
    regioncode: 87,
    year: 2017,
    count: 282,
    len_sec: 297.0800000000001,
    lanemiles: 744.5039999999999,
    delay: 732579.0,
    trkdelay: 47781.0,
    pti80: 1.13,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.06,
    trktti: 1.03,
    ghg: 3159.0,
    congcost: 1.5081812e7,
    allspeed: 51.98,
    trkspeed: 60.08,
    ttivmt: 387038.0,
    trkttivmt: 60650.0,
  },
  {
    agglevel: "county",
    regioncode: 87,
    year: 2018,
    count: 412,
    len_sec: 296.7599999999997,
    lanemiles: 744.5429999999997,
    delay: 664424.0,
    trkdelay: 57211.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.14,
    trkpti95: 1.19,
    tti: 1.06,
    trktti: 1.06,
    ghg: 3023.0,
    congcost: 1.4432342e7,
    allspeed: 53.21,
    trkspeed: 55.47,
    ttivmt: 346535.0,
    trkttivmt: 35105.0,
  },
  {
    agglevel: "county",
    regioncode: 87,
    year: 2019,
    count: 96,
    len_sec: 213.11000000000007,
    lanemiles: 572.6850000000001,
    delay: 510927.0,
    trkdelay: 52584.0,
    pti80: 1.12,
    trkpti80: 1.14,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.05,
    trktti: 1.05,
    ghg: 2558.0,
    congcost: 1.1866282e7,
    allspeed: 57.03,
    trkspeed: 57.22,
    ttivmt: 303482.0,
    trkttivmt: 35553.0,
  },
  {
    agglevel: "county",
    regioncode: 87,
    year: 2020,
    count: 622,
    len_sec: 296.77800000000013,
    lanemiles: 739.094,
    delay: 598178.0,
    trkdelay: 51424.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.05,
    ghg: 2869.0,
    congcost: 1.3936811e7,
    allspeed: 56.44,
    trkspeed: 58.46,
    ttivmt: 357541.0,
    trkttivmt: 35861.0,
  },
  {
    agglevel: "county",
    regioncode: 87,
    year: 2021,
    count: 635,
    len_sec: 296.83699999999976,
    lanemiles: 738.8769999999988,
    delay: 476035.0,
    trkdelay: 44921.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.05,
    ghg: 2294.0,
    congcost: 1.1387536e7,
    allspeed: 56.55,
    trkspeed: 57.43,
    ttivmt: 374222.0,
    trkttivmt: 35966.0,
  },
  {
    agglevel: "county",
    regioncode: 87,
    year: 2022,
    count: 652,
    len_sec: 296.8500000000002,
    lanemiles: 738.9289999999996,
    delay: 633695.0,
    trkdelay: 47550.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.17,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.05,
    ghg: 2618.0,
    congcost: 1.6295042e7,
    allspeed: 55.38,
    trkspeed: 58.21,
    ttivmt: 374034.0,
    trkttivmt: 38836.0,
  },
  {
    agglevel: "county",
    regioncode: 88,
    year: 2017,
    count: 69,
    len_sec: 147.95700000000002,
    lanemiles: 316.67,
    delay: 26961.0,
    trkdelay: 15289.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.0,
    trktti: 1.0,
    ghg: 204.0,
    congcost: 969539.0,
    allspeed: 63.65,
    trkspeed: 64.11,
    ttivmt: 130789.0,
    trkttivmt: 130731.0,
  },
  {
    agglevel: "county",
    regioncode: 88,
    year: 2018,
    count: 91,
    len_sec: 147.75500000000002,
    lanemiles: 378.67199999999985,
    delay: 42856.0,
    trkdelay: 18382.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.02,
    ghg: 262.0,
    congcost: 1387579.0,
    allspeed: 62.94,
    trkspeed: 62.71,
    ttivmt: 163341.0,
    trkttivmt: 47915.0,
  },
  {
    agglevel: "county",
    regioncode: 88,
    year: 2019,
    count: 43,
    len_sec: 145.502,
    lanemiles: 374.16599999999994,
    delay: 45772.0,
    trkdelay: 24850.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.02,
    ghg: 277.0,
    congcost: 1723819.0,
    allspeed: 63.24,
    trkspeed: 62.24,
    ttivmt: 162643.0,
    trkttivmt: 57466.0,
  },
  {
    agglevel: "county",
    regioncode: 88,
    year: 2020,
    count: 120,
    len_sec: 147.755,
    lanemiles: 378.66999999999996,
    delay: 40112.0,
    trkdelay: 22129.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.02,
    ghg: 181.0,
    congcost: 1585075.0,
    allspeed: 63.54,
    trkspeed: 63.14,
    ttivmt: 152531.0,
    trkttivmt: 53677.0,
  },
  {
    agglevel: "county",
    regioncode: 88,
    year: 2021,
    count: 117,
    len_sec: 147.83800000000014,
    lanemiles: 378.87800000000016,
    delay: 34949.0,
    trkdelay: 21103.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.02,
    trktti: 1.02,
    ghg: 236.0,
    congcost: 1363074.0,
    allspeed: 63.23,
    trkspeed: 62.98,
    ttivmt: 94692.0,
    trkttivmt: 38154.0,
  },
  {
    agglevel: "county",
    regioncode: 88,
    year: 2022,
    count: 118,
    len_sec: 147.83900000000008,
    lanemiles: 378.8800000000002,
    delay: 44348.0,
    trkdelay: 24218.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.02,
    ghg: 263.0,
    congcost: 1859794.0,
    allspeed: 63.44,
    trkspeed: 62.89,
    ttivmt: 97318.0,
    trkttivmt: 39391.0,
  },
  {
    agglevel: "county",
    regioncode: 89,
    year: 2017,
    count: 230,
    len_sec: 263.68600000000004,
    lanemiles: 561.6670000000001,
    delay: 48350.0,
    trkdelay: 13839.0,
    pti80: 1.07,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 264.0,
    congcost: 1321368.0,
    allspeed: 60.69,
    trkspeed: 63.21,
    ttivmt: 125438.0,
    trkttivmt: 64777.0,
  },
  {
    agglevel: "county",
    regioncode: 89,
    year: 2018,
    count: 302,
    len_sec: 263.4509999999998,
    lanemiles: 560.9019999999996,
    delay: 61865.0,
    trkdelay: 13920.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 306.0,
    congcost: 1612474.0,
    allspeed: 59.17,
    trkspeed: 59.41,
    ttivmt: 126546.0,
    trkttivmt: 21901.0,
  },
  {
    agglevel: "county",
    regioncode: 89,
    year: 2019,
    count: 77,
    len_sec: 147.729,
    lanemiles: 329.7289999999999,
    delay: 42560.0,
    trkdelay: 18323.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.03,
    ghg: 351.0,
    congcost: 1473336.0,
    allspeed: 61.51,
    trkspeed: 60.09,
    ttivmt: 120985.0,
    trkttivmt: 26769.0,
  },
  {
    agglevel: "county",
    regioncode: 89,
    year: 2020,
    count: 430,
    len_sec: 263.519,
    lanemiles: 561.4820000000002,
    delay: 64150.0,
    trkdelay: 15245.0,
    pti80: 1.08,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 316.0,
    congcost: 1840993.0,
    allspeed: 60.42,
    trkspeed: 61.02,
    ttivmt: 137682.0,
    trkttivmt: 22958.0,
  },
  {
    agglevel: "county",
    regioncode: 89,
    year: 2021,
    count: 424,
    len_sec: 263.5539999999999,
    lanemiles: 561.5909999999997,
    delay: 155479.0,
    trkdelay: 31966.0,
    pti80: 1.14,
    trkpti80: 1.18,
    pti95: 1.19,
    trkpti95: 1.28,
    tti: 1.06,
    trktti: 1.07,
    ghg: 634.0,
    congcost: 4199287.0,
    allspeed: 58.55,
    trkspeed: 59.51,
    ttivmt: 122791.0,
    trkttivmt: 21835.0,
  },
  {
    agglevel: "county",
    regioncode: 89,
    year: 2022,
    count: 432,
    len_sec: 263.56399999999996,
    lanemiles: 561.6249999999997,
    delay: 145235.0,
    trkdelay: 35972.0,
    pti80: 1.12,
    trkpti80: 1.17,
    pti95: 1.2,
    trkpti95: 1.29,
    tti: 1.04,
    trktti: 1.06,
    ghg: 834.0,
    congcost: 4633952.0,
    allspeed: 57.61,
    trkspeed: 57.78,
    ttivmt: 142156.0,
    trkttivmt: 23308.0,
  },
  {
    agglevel: "county",
    regioncode: 90,
    year: 2017,
    count: 558,
    len_sec: 430.52299999999974,
    lanemiles: 924.1319999999994,
    delay: 491965.0,
    trkdelay: 118591.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.18,
    tti: 1.02,
    trktti: 1.02,
    ghg: 3238.0,
    congcost: 1.2873729e7,
    allspeed: 60.17,
    trkspeed: 63.51,
    ttivmt: 658315.0,
    trkttivmt: 337743.0,
  },
  {
    agglevel: "county",
    regioncode: 90,
    year: 2018,
    count: 724,
    len_sec: 433.6319999999995,
    lanemiles: 929.7519999999989,
    delay: 426106.0,
    trkdelay: 73156.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2199.0,
    congcost: 1.0410454e7,
    allspeed: 59.86,
    trkspeed: 61.65,
    ttivmt: 611934.0,
    trkttivmt: 135724.0,
  },
  {
    agglevel: "county",
    regioncode: 90,
    year: 2019,
    count: 203,
    len_sec: 291.067,
    lanemiles: 644.6220000000003,
    delay: 323453.0,
    trkdelay: 72125.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.1,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1716.0,
    congcost: 8783246.0,
    allspeed: 61.27,
    trkspeed: 61.74,
    ttivmt: 599818.0,
    trkttivmt: 137839.0,
  },
  {
    agglevel: "county",
    regioncode: 90,
    year: 2020,
    count: 1083,
    len_sec: 433.68399999999986,
    lanemiles: 938.2809999999989,
    delay: 470582.0,
    trkdelay: 84433.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2492.0,
    congcost: 1.2546828e7,
    allspeed: 60.16,
    trkspeed: 62.16,
    ttivmt: 671693.0,
    trkttivmt: 155840.0,
  },
  {
    agglevel: "county",
    regioncode: 90,
    year: 2021,
    count: 1083,
    len_sec: 432.8069999999989,
    lanemiles: 936.9239999999985,
    delay: 260148.0,
    trkdelay: 47184.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1457.0,
    congcost: 6915560.0,
    allspeed: 60.23,
    trkspeed: 62.29,
    ttivmt: 564888.0,
    trkttivmt: 120951.0,
  },
  {
    agglevel: "county",
    regioncode: 90,
    year: 2022,
    count: 1099,
    len_sec: 432.5859999999987,
    lanemiles: 937.1639999999977,
    delay: 415080.0,
    trkdelay: 74348.0,
    pti80: 1.12,
    trkpti80: 1.07,
    pti95: 1.18,
    trkpti95: 1.09,
    tti: 1.02,
    trktti: 1.03,
    ghg: 1920.0,
    congcost: 1.2152395e7,
    allspeed: 60.08,
    trkspeed: 61.79,
    ttivmt: 687672.0,
    trkttivmt: 130553.0,
  },
  {
    agglevel: "county",
    regioncode: 91,
    year: 2017,
    count: 466,
    len_sec: 342.1969999999999,
    lanemiles: 790.1029999999997,
    delay: 274897.0,
    trkdelay: 50387.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.02,
    ghg: 1540.0,
    congcost: 6678553.0,
    allspeed: 57.07,
    trkspeed: 62.51,
    ttivmt: 280893.0,
    trkttivmt: 130680.0,
  },
  {
    agglevel: "county",
    regioncode: 91,
    year: 2018,
    count: 410,
    len_sec: 332.68199999999996,
    lanemiles: 769.808,
    delay: 174623.0,
    trkdelay: 37240.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1262.0,
    congcost: 4561369.0,
    allspeed: 58.09,
    trkspeed: 60.38,
    ttivmt: 259145.0,
    trkttivmt: 66665.0,
  },
  {
    agglevel: "county",
    regioncode: 91,
    year: 2019,
    count: 225,
    len_sec: 338.6149999999998,
    lanemiles: 781.4079999999997,
    delay: 178678.0,
    trkdelay: 45378.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1414.0,
    congcost: 5148650.0,
    allspeed: 60.03,
    trkspeed: 61.07,
    ttivmt: 257469.0,
    trkttivmt: 77459.0,
  },
  {
    agglevel: "county",
    regioncode: 91,
    year: 2020,
    count: 624,
    len_sec: 339.1,
    lanemiles: 781.7369999999994,
    delay: 189112.0,
    trkdelay: 35497.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1255.0,
    congcost: 5162349.0,
    allspeed: 59.71,
    trkspeed: 62.12,
    ttivmt: 260000.0,
    trkttivmt: 65673.0,
  },
  {
    agglevel: "county",
    regioncode: 91,
    year: 2021,
    count: 652,
    len_sec: 341.897,
    lanemiles: 785.3250000000004,
    delay: 179952.0,
    trkdelay: 39107.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1256.0,
    congcost: 5026720.0,
    allspeed: 59.6,
    trkspeed: 62.59,
    ttivmt: 257027.0,
    trkttivmt: 90212.0,
  },
  {
    agglevel: "county",
    regioncode: 91,
    year: 2022,
    count: 654,
    len_sec: 341.89799999999934,
    lanemiles: 785.3289999999996,
    delay: 167564.0,
    trkdelay: 29609.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.01,
    ghg: 798.0,
    congcost: 4909131.0,
    allspeed: 60.79,
    trkspeed: 62.93,
    ttivmt: 286808.0,
    trkttivmt: 81450.0,
  },
  {
    agglevel: "county",
    regioncode: 92,
    year: 2017,
    count: 1561,
    len_sec: 608.8619999999991,
    lanemiles: 1406.9160000000004,
    delay: 2284081.0,
    trkdelay: 113691.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.03,
    ghg: 8157.0,
    congcost: 4.5659694e7,
    allspeed: 54.64,
    trkspeed: 58.84,
    ttivmt: 1865913.0,
    trkttivmt: 172359.0,
  },
  {
    agglevel: "county",
    regioncode: 92,
    year: 2018,
    count: 1267,
    len_sec: 610.2739999999997,
    lanemiles: 1410.942,
    delay: 1641214.0,
    trkdelay: 120413.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.03,
    ghg: 6831.0,
    congcost: 3.4864409e7,
    allspeed: 56.43,
    trkspeed: 59.55,
    ttivmt: 1444882.0,
    trkttivmt: 157252.0,
  },
  {
    agglevel: "county",
    regioncode: 92,
    year: 2019,
    count: 720,
    len_sec: 603.2719999999998,
    lanemiles: 1398.037000000001,
    delay: 1788970.0,
    trkdelay: 154422.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.04,
    ghg: 8182.0,
    congcost: 4.0357749e7,
    allspeed: 56.31,
    trkspeed: 57.27,
    ttivmt: 1430244.0,
    trkttivmt: 133537.0,
  },
  {
    agglevel: "county",
    regioncode: 92,
    year: 2020,
    count: 2176,
    len_sec: 612.5029999999983,
    lanemiles: 1415.5099999999925,
    delay: 2042082.0,
    trkdelay: 139902.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.03,
    ghg: 8684.0,
    congcost: 4.6058444e7,
    allspeed: 56.84,
    trkspeed: 60.1,
    ttivmt: 1599080.0,
    trkttivmt: 155228.0,
  },
  {
    agglevel: "county",
    regioncode: 92,
    year: 2021,
    count: 2148,
    len_sec: 612.0489999999967,
    lanemiles: 1416.1779999999917,
    delay: 1451365.0,
    trkdelay: 126870.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.03,
    ghg: 5920.0,
    congcost: 3.4181444e7,
    allspeed: 56.85,
    trkspeed: 60.19,
    ttivmt: 1531057.0,
    trkttivmt: 163374.0,
  },
  {
    agglevel: "county",
    regioncode: 92,
    year: 2022,
    count: 2195,
    len_sec: 611.8089999999964,
    lanemiles: 1417.275999999995,
    delay: 1903491.0,
    trkdelay: 150302.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.18,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.03,
    ghg: 7753.0,
    congcost: 4.9128786e7,
    allspeed: 56.13,
    trkspeed: 59.51,
    ttivmt: 1620898.0,
    trkttivmt: 178540.0,
  },
  {
    agglevel: "county",
    regioncode: 93,
    year: 2017,
    count: 1046,
    len_sec: 427.39800000000014,
    lanemiles: 1214.3630000000003,
    delay: 5079074.0,
    trkdelay: 133973.0,
    pti80: 1.17,
    trkpti80: 1.12,
    pti95: 1.21,
    trkpti95: 1.16,
    tti: 1.08,
    trktti: 1.04,
    ghg: 19663.0,
    congcost: 9.8307937e7,
    allspeed: 41.84,
    trkspeed: 54.47,
    ttivmt: 2098359.0,
    trkttivmt: 128490.0,
  },
  {
    agglevel: "county",
    regioncode: 93,
    year: 2018,
    count: 921,
    len_sec: 429.71099999999944,
    lanemiles: 1222.2139999999981,
    delay: 3451008.0,
    trkdelay: 232987.0,
    pti80: 1.17,
    trkpti80: 1.18,
    pti95: 1.2,
    trkpti95: 1.23,
    tti: 1.09,
    trktti: 1.08,
    ghg: 16245.0,
    congcost: 7.3074096e7,
    allspeed: 46.87,
    trkspeed: 54.26,
    ttivmt: 1321973.0,
    trkttivmt: 126318.0,
  },
  {
    agglevel: "county",
    regioncode: 93,
    year: 2019,
    count: 673,
    len_sec: 427.5540000000001,
    lanemiles: 1220.3779999999983,
    delay: 4133466.0,
    trkdelay: 282851.0,
    pti80: 1.18,
    trkpti80: 1.19,
    pti95: 1.22,
    trkpti95: 1.24,
    tti: 1.1,
    trktti: 1.08,
    ghg: 20202.0,
    congcost: 9.121675e7,
    allspeed: 45.35,
    trkspeed: 51.86,
    ttivmt: 1419636.0,
    trkttivmt: 131896.0,
  },
  {
    agglevel: "county",
    regioncode: 93,
    year: 2020,
    count: 1760,
    len_sec: 432.1149999999993,
    lanemiles: 1224.7579999999978,
    delay: 4489229.0,
    trkdelay: 252735.0,
    pti80: 1.18,
    trkpti80: 1.17,
    pti95: 1.22,
    trkpti95: 1.23,
    tti: 1.09,
    trktti: 1.07,
    ghg: 20598.0,
    congcost: 9.9662636e7,
    allspeed: 45.28,
    trkspeed: 53.52,
    ttivmt: 1496408.0,
    trkttivmt: 130548.0,
  },
  {
    agglevel: "county",
    regioncode: 93,
    year: 2021,
    count: 1772,
    len_sec: 430.1469999999988,
    lanemiles: 1222.6739999999966,
    delay: 1921070.0,
    trkdelay: 150726.0,
    pti80: 1.13,
    trkpti80: 1.14,
    pti95: 1.17,
    trkpti95: 1.22,
    tti: 1.05,
    trktti: 1.05,
    ghg: 9315.0,
    congcost: 4.5045462e7,
    allspeed: 46.63,
    trkspeed: 55.61,
    ttivmt: 1307609.0,
    trkttivmt: 124524.0,
  },
  {
    agglevel: "county",
    regioncode: 93,
    year: 2022,
    count: 1780,
    len_sec: 430.04199999999867,
    lanemiles: 1222.8979999999933,
    delay: 3849630.0,
    trkdelay: 188700.0,
    pti80: 1.17,
    trkpti80: 1.11,
    pti95: 1.24,
    trkpti95: 1.17,
    tti: 1.08,
    trktti: 1.04,
    ghg: 17900.0,
    congcost: 9.6173833e7,
    allspeed: 44.83,
    trkspeed: 55.68,
    ttivmt: 1473671.0,
    trkttivmt: 165889.0,
  },
  {
    agglevel: "county",
    regioncode: 94,
    year: 2017,
    count: 406,
    len_sec: 301.66400000000016,
    lanemiles: 633.4150000000003,
    delay: 386042.0,
    trkdelay: 39706.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.02,
    ghg: 1446.0,
    congcost: 8341971.0,
    allspeed: 58.4,
    trkspeed: 60.74,
    ttivmt: 445315.0,
    trkttivmt: 67158.0,
  },
  {
    agglevel: "county",
    regioncode: 94,
    year: 2018,
    count: 517,
    len_sec: 301.7319999999994,
    lanemiles: 633.3669999999989,
    delay: 359998.0,
    trkdelay: 42195.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1430.0,
    congcost: 8116930.0,
    allspeed: 58.8,
    trkspeed: 59.75,
    ttivmt: 434836.0,
    trkttivmt: 50440.0,
  },
  {
    agglevel: "county",
    regioncode: 94,
    year: 2019,
    count: 148,
    len_sec: 216.56199999999998,
    lanemiles: 462.7970000000001,
    delay: 325090.0,
    trkdelay: 45012.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1343.0,
    congcost: 7849093.0,
    allspeed: 60.04,
    trkspeed: 59.2,
    ttivmt: 429433.0,
    trkttivmt: 50085.0,
  },
  {
    agglevel: "county",
    regioncode: 94,
    year: 2020,
    count: 686,
    len_sec: 301.76299999999947,
    lanemiles: 633.428999999999,
    delay: 368174.0,
    trkdelay: 39797.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1441.0,
    congcost: 8786133.0,
    allspeed: 59.18,
    trkspeed: 60.78,
    ttivmt: 462494.0,
    trkttivmt: 52266.0,
  },
  {
    agglevel: "county",
    regioncode: 94,
    year: 2021,
    count: 685,
    len_sec: 301.24299999999954,
    lanemiles: 633.3019999999993,
    delay: 226370.0,
    trkdelay: 30004.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.03,
    ghg: 788.0,
    congcost: 5606365.0,
    allspeed: 59.42,
    trkspeed: 61.36,
    ttivmt: 425390.0,
    trkttivmt: 49342.0,
  },
  {
    agglevel: "county",
    regioncode: 94,
    year: 2022,
    count: 716,
    len_sec: 303.45899999999966,
    lanemiles: 644.9549999999989,
    delay: 462259.0,
    trkdelay: 57737.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.16,
    trkpti95: 1.22,
    tti: 1.04,
    trktti: 1.05,
    ghg: 1761.0,
    congcost: 1.2611248e7,
    allspeed: 58.69,
    trkspeed: 60.37,
    ttivmt: 502152.0,
    trkttivmt: 54293.0,
  },
  {
    agglevel: "county",
    regioncode: 95,
    year: 2017,
    count: 879,
    len_sec: 533.9589999999998,
    lanemiles: 1277.0209999999981,
    delay: 3345245.0,
    trkdelay: 160964.0,
    pti80: 1.16,
    trkpti80: 1.11,
    pti95: 1.22,
    trkpti95: 1.15,
    tti: 1.06,
    trktti: 1.02,
    ghg: 13660.0,
    congcost: 6.7025655e7,
    allspeed: 50.71,
    trkspeed: 61.29,
    ttivmt: 2017567.0,
    trkttivmt: 371839.0,
  },
  {
    agglevel: "county",
    regioncode: 95,
    year: 2018,
    count: 964,
    len_sec: 543.8559999999997,
    lanemiles: 1307.4499999999985,
    delay: 2482341.0,
    trkdelay: 178977.0,
    pti80: 1.17,
    trkpti80: 1.13,
    pti95: 1.24,
    trkpti95: 1.18,
    tti: 1.07,
    trktti: 1.05,
    ghg: 10770.0,
    congcost: 5.2720342e7,
    allspeed: 53.46,
    trkspeed: 59.42,
    ttivmt: 1589303.0,
    trkttivmt: 214904.0,
  },
  {
    agglevel: "county",
    regioncode: 95,
    year: 2019,
    count: 297,
    len_sec: 285.0820000000002,
    lanemiles: 787.567,
    delay: 2413417.0,
    trkdelay: 201227.0,
    pti80: 1.18,
    trkpti80: 1.13,
    pti95: 1.24,
    trkpti95: 1.18,
    tti: 1.08,
    trktti: 1.05,
    ghg: 10690.0,
    congcost: 5.4121961e7,
    allspeed: 54.28,
    trkspeed: 57.73,
    ttivmt: 1442286.0,
    trkttivmt: 202588.0,
  },
  {
    agglevel: "county",
    regioncode: 95,
    year: 2020,
    count: 1352,
    len_sec: 542.8410000000002,
    lanemiles: 1296.8339999999982,
    delay: 3114044.0,
    trkdelay: 229905.0,
    pti80: 1.17,
    trkpti80: 1.14,
    pti95: 1.23,
    trkpti95: 1.2,
    tti: 1.08,
    trktti: 1.05,
    ghg: 13003.0,
    congcost: 7.0724724e7,
    allspeed: 52.26,
    trkspeed: 59.46,
    ttivmt: 1665326.0,
    trkttivmt: 219687.0,
  },
  {
    agglevel: "county",
    regioncode: 95,
    year: 2021,
    count: 1343,
    len_sec: 541.323999999998,
    lanemiles: 1292.6259999999947,
    delay: 1554803.0,
    trkdelay: 142670.0,
    pti80: 1.13,
    trkpti80: 1.11,
    pti95: 1.19,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.03,
    ghg: 6860.0,
    congcost: 3.6908909e7,
    allspeed: 53.73,
    trkspeed: 60.57,
    ttivmt: 1408969.0,
    trkttivmt: 216391.0,
  },
  {
    agglevel: "county",
    regioncode: 95,
    year: 2022,
    count: 1394,
    len_sec: 543.6039999999997,
    lanemiles: 1298.2889999999966,
    delay: 3137955.0,
    trkdelay: 288409.0,
    pti80: 1.19,
    trkpti80: 1.13,
    pti95: 1.28,
    trkpti95: 1.2,
    tti: 1.08,
    trktti: 1.05,
    ghg: 12779.0,
    congcost: 8.2306093e7,
    allspeed: 52.47,
    trkspeed: 59.34,
    ttivmt: 1739752.0,
    trkttivmt: 286818.0,
  },
  {
    agglevel: "county",
    regioncode: 96,
    year: 2017,
    count: 659,
    len_sec: 751.9639999999997,
    lanemiles: 1643.5779999999993,
    delay: 561050.0,
    trkdelay: 33968.0,
    pti80: 1.11,
    trkpti80: 1.07,
    pti95: 1.15,
    trkpti95: 1.11,
    tti: 1.04,
    trktti: 1.01,
    ghg: 2496.0,
    congcost: 1.1491532e7,
    allspeed: 53.39,
    trkspeed: 62.14,
    ttivmt: 458366.0,
    trkttivmt: 99058.0,
  },
  {
    agglevel: "county",
    regioncode: 96,
    year: 2018,
    count: 600,
    len_sec: 752.8729999999997,
    lanemiles: 1650.4469999999994,
    delay: 371557.0,
    trkdelay: 39637.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1940.0,
    congcost: 8352661.0,
    allspeed: 56.23,
    trkspeed: 58.38,
    ttivmt: 386012.0,
    trkttivmt: 48726.0,
  },
  {
    agglevel: "county",
    regioncode: 96,
    year: 2019,
    count: 428,
    len_sec: 752.3579999999993,
    lanemiles: 1648.3279999999977,
    delay: 384996.0,
    trkdelay: 55937.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.04,
    ghg: 2194.0,
    congcost: 9530024.0,
    allspeed: 58.49,
    trkspeed: 59.09,
    ttivmt: 392922.0,
    trkttivmt: 55125.0,
  },
  {
    agglevel: "county",
    regioncode: 96,
    year: 2020,
    count: 810,
    len_sec: 752.9909999999987,
    lanemiles: 1652.7919999999972,
    delay: 418558.0,
    trkdelay: 56146.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.03,
    ghg: 2374.0,
    congcost: 1.0537956e7,
    allspeed: 58.16,
    trkspeed: 59.93,
    ttivmt: 407179.0,
    trkttivmt: 52550.0,
  },
  {
    agglevel: "county",
    regioncode: 96,
    year: 2021,
    count: 788,
    len_sec: 753.1239999999996,
    lanemiles: 1650.989999999999,
    delay: 367778.0,
    trkdelay: 60247.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.04,
    ghg: 2197.0,
    congcost: 9618106.0,
    allspeed: 57.27,
    trkspeed: 59.27,
    ttivmt: 424186.0,
    trkttivmt: 62447.0,
  },
  {
    agglevel: "county",
    regioncode: 96,
    year: 2022,
    count: 809,
    len_sec: 753.1859999999984,
    lanemiles: 1651.1559999999981,
    delay: 439622.0,
    trkdelay: 47767.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.2,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1651.0,
    congcost: 1.1747599e7,
    allspeed: 57.32,
    trkspeed: 59.84,
    ttivmt: 453790.0,
    trkttivmt: 57057.0,
  },
  {
    agglevel: "county",
    regioncode: 97,
    year: 2017,
    count: 222,
    len_sec: 210.157,
    lanemiles: 459.966,
    delay: 27650.0,
    trkdelay: 6227.0,
    pti80: 1.05,
    trkpti80: 1.05,
    pti95: 1.07,
    trkpti95: 1.06,
    tti: 1.01,
    trktti: 1.01,
    ghg: 104.0,
    congcost: 698467.0,
    allspeed: 60.18,
    trkspeed: 62.66,
    ttivmt: 90668.0,
    trkttivmt: 36798.0,
  },
  {
    agglevel: "county",
    regioncode: 97,
    year: 2018,
    count: 263,
    len_sec: 209.47400000000016,
    lanemiles: 456.60000000000025,
    delay: 23907.0,
    trkdelay: 7201.0,
    pti80: 1.07,
    trkpti80: 1.06,
    pti95: 1.09,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.01,
    ghg: 148.0,
    congcost: 684561.0,
    allspeed: 59.27,
    trkspeed: 59.74,
    ttivmt: 77024.0,
    trkttivmt: 24497.0,
  },
  {
    agglevel: "county",
    regioncode: 97,
    year: 2019,
    count: 90,
    len_sec: 200.73399999999992,
    lanemiles: 439.11999999999983,
    delay: 28727.0,
    trkdelay: 11504.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.09,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.02,
    ghg: 222.0,
    congcost: 963114.0,
    allspeed: 60.14,
    trkspeed: 59.09,
    ttivmt: 93302.0,
    trkttivmt: 28298.0,
  },
  {
    agglevel: "county",
    regioncode: 97,
    year: 2020,
    count: 410,
    len_sec: 209.89599999999982,
    lanemiles: 457.4419999999998,
    delay: 31317.0,
    trkdelay: 8685.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 210.0,
    congcost: 954000.0,
    allspeed: 59.9,
    trkspeed: 60.19,
    ttivmt: 97058.0,
    trkttivmt: 28921.0,
  },
  {
    agglevel: "county",
    regioncode: 97,
    year: 2021,
    count: 420,
    len_sec: 209.90099999999998,
    lanemiles: 459.96700000000004,
    delay: 20476.0,
    trkdelay: 6903.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 129.0,
    congcost: 641292.0,
    allspeed: 60.03,
    trkspeed: 60.16,
    ttivmt: 96499.0,
    trkttivmt: 30393.0,
  },
  {
    agglevel: "county",
    regioncode: 97,
    year: 2022,
    count: 422,
    len_sec: 209.90099999999995,
    lanemiles: 459.967,
    delay: 35927.0,
    trkdelay: 10348.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 188.0,
    congcost: 1194551.0,
    allspeed: 60.09,
    trkspeed: 60.57,
    ttivmt: 108844.0,
    trkttivmt: 38620.0,
  },
  {
    agglevel: "county",
    regioncode: 98,
    year: 2017,
    count: 348,
    len_sec: 305.373,
    lanemiles: 618.139,
    delay: 87358.0,
    trkdelay: 11176.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.03,
    ghg: 350.0,
    congcost: 1956235.0,
    allspeed: 58.75,
    trkspeed: 60.0,
    ttivmt: 138955.0,
    trkttivmt: 19570.0,
  },
  {
    agglevel: "county",
    regioncode: 98,
    year: 2018,
    count: 410,
    len_sec: 304.6469999999998,
    lanemiles: 615.7699999999995,
    delay: 93827.0,
    trkdelay: 18481.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.04,
    ghg: 528.0,
    congcost: 2374045.0,
    allspeed: 54.0,
    trkspeed: 55.49,
    ttivmt: 138229.0,
    trkttivmt: 20377.0,
  },
  {
    agglevel: "county",
    regioncode: 98,
    year: 2019,
    count: 150,
    len_sec: 269.96899999999994,
    lanemiles: 546.414,
    delay: 116949.0,
    trkdelay: 23685.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.05,
    ghg: 699.0,
    congcost: 3119662.0,
    allspeed: 58.48,
    trkspeed: 57.67,
    ttivmt: 135999.0,
    trkttivmt: 19248.0,
  },
  {
    agglevel: "county",
    regioncode: 98,
    year: 2020,
    count: 558,
    len_sec: 304.7619999999996,
    lanemiles: 614.5449999999994,
    delay: 128670.0,
    trkdelay: 22886.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.05,
    ghg: 733.0,
    congcost: 3441205.0,
    allspeed: 58.24,
    trkspeed: 58.26,
    ttivmt: 148523.0,
    trkttivmt: 21384.0,
  },
  {
    agglevel: "county",
    regioncode: 98,
    year: 2021,
    count: 547,
    len_sec: 303.96799999999917,
    lanemiles: 613.7509999999987,
    delay: 118405.0,
    trkdelay: 28842.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.05,
    ghg: 752.0,
    congcost: 3382558.0,
    allspeed: 57.59,
    trkspeed: 58.19,
    ttivmt: 131421.0,
    trkttivmt: 25129.0,
  },
  {
    agglevel: "county",
    regioncode: 98,
    year: 2022,
    count: 551,
    len_sec: 303.9770000000001,
    lanemiles: 613.7690000000002,
    delay: 118907.0,
    trkdelay: 20794.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.19,
    tti: 1.03,
    trktti: 1.05,
    ghg: 584.0,
    congcost: 3483039.0,
    allspeed: 57.48,
    trkspeed: 57.49,
    ttivmt: 137146.0,
    trkttivmt: 19752.0,
  },
  {
    agglevel: "county",
    regioncode: 99,
    year: 2017,
    count: 186,
    len_sec: 260.9169999999999,
    lanemiles: 525.439,
    delay: 38220.0,
    trkdelay: 13329.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 216.0,
    congcost: 1115750.0,
    allspeed: 59.38,
    trkspeed: 61.44,
    ttivmt: 45141.0,
    trkttivmt: 22551.0,
  },
  {
    agglevel: "county",
    regioncode: 99,
    year: 2018,
    count: 216,
    len_sec: 260.9139999999997,
    lanemiles: 525.5119999999997,
    delay: 44737.0,
    trkdelay: 10569.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.04,
    ghg: 192.0,
    congcost: 1172564.0,
    allspeed: 56.6,
    trkspeed: 58.37,
    ttivmt: 48805.0,
    trkttivmt: 11609.0,
  },
  {
    agglevel: "county",
    regioncode: 99,
    year: 2019,
    count: 98,
    len_sec: 260.9379999999999,
    lanemiles: 525.5599999999998,
    delay: 33203.0,
    trkdelay: 12050.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.04,
    ghg: 202.0,
    congcost: 1058294.0,
    allspeed: 59.33,
    trkspeed: 59.39,
    ttivmt: 42909.0,
    trkttivmt: 10943.0,
  },
  {
    agglevel: "county",
    regioncode: 99,
    year: 2020,
    count: 262,
    len_sec: 260.778,
    lanemiles: 525.24,
    delay: 52348.0,
    trkdelay: 12168.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.15,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.05,
    ghg: 208.0,
    congcost: 1480696.0,
    allspeed: 58.4,
    trkspeed: 59.61,
    ttivmt: 50432.0,
    trkttivmt: 11072.0,
  },
  {
    agglevel: "county",
    regioncode: 99,
    year: 2021,
    count: 259,
    len_sec: 260.906,
    lanemiles: 525.584,
    delay: 46199.0,
    trkdelay: 16761.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.15,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.05,
    ghg: 288.0,
    congcost: 1477479.0,
    allspeed: 59.11,
    trkspeed: 59.62,
    ttivmt: 56562.0,
    trkttivmt: 16201.0,
  },
  {
    agglevel: "county",
    regioncode: 99,
    year: 2022,
    count: 260,
    len_sec: 260.9060000000002,
    lanemiles: 525.5840000000004,
    delay: 99865.0,
    trkdelay: 31953.0,
    pti80: 1.16,
    trkpti80: 1.21,
    pti95: 1.27,
    trkpti95: 1.33,
    tti: 1.06,
    trktti: 1.09,
    ghg: 632.0,
    congcost: 3440359.0,
    allspeed: 57.25,
    trkspeed: 56.77,
    ttivmt: 61828.0,
    trkttivmt: 15165.0,
  },
  {
    agglevel: "county",
    regioncode: 100,
    year: 2017,
    count: 225,
    len_sec: 206.29599999999976,
    lanemiles: 475.252,
    delay: 49352.0,
    trkdelay: 9696.0,
    pti80: 1.07,
    trkpti80: 1.05,
    pti95: 1.08,
    trkpti95: 1.06,
    tti: 1.01,
    trktti: 1.01,
    ghg: 279.0,
    congcost: 1219932.0,
    allspeed: 60.15,
    trkspeed: 62.04,
    ttivmt: 144387.0,
    trkttivmt: 54314.0,
  },
  {
    agglevel: "county",
    regioncode: 100,
    year: 2018,
    count: 233,
    len_sec: 206.44199999999995,
    lanemiles: 475.56199999999984,
    delay: 39986.0,
    trkdelay: 10169.0,
    pti80: 1.07,
    trkpti80: 1.05,
    pti95: 1.09,
    trkpti95: 1.05,
    tti: 1.01,
    trktti: 1.01,
    ghg: 236.0,
    congcost: 1084446.0,
    allspeed: 59.42,
    trkspeed: 60.25,
    ttivmt: 139976.0,
    trkttivmt: 41701.0,
  },
  {
    agglevel: "county",
    regioncode: 100,
    year: 2019,
    count: 123,
    len_sec: 206.64300000000003,
    lanemiles: 475.9639999999999,
    delay: 55732.0,
    trkdelay: 17916.0,
    pti80: 1.08,
    trkpti80: 1.06,
    pti95: 1.12,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.02,
    ghg: 459.0,
    congcost: 1729405.0,
    allspeed: 60.18,
    trkspeed: 59.48,
    ttivmt: 152896.0,
    trkttivmt: 43077.0,
  },
  {
    agglevel: "county",
    regioncode: 100,
    year: 2020,
    count: 359,
    len_sec: 206.63400000000004,
    lanemiles: 475.5760000000001,
    delay: 61281.0,
    trkdelay: 12843.0,
    pti80: 1.06,
    trkpti80: 1.06,
    pti95: 1.09,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.01,
    ghg: 394.0,
    congcost: 1716196.0,
    allspeed: 59.6,
    trkspeed: 60.75,
    ttivmt: 162167.0,
    trkttivmt: 43558.0,
  },
  {
    agglevel: "county",
    regioncode: 100,
    year: 2021,
    count: 365,
    len_sec: 206.6279999999999,
    lanemiles: 475.6319999999997,
    delay: 43209.0,
    trkdelay: 11828.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 320.0,
    congcost: 1281123.0,
    allspeed: 59.92,
    trkspeed: 61.17,
    ttivmt: 156423.0,
    trkttivmt: 53893.0,
  },
  {
    agglevel: "county",
    regioncode: 100,
    year: 2022,
    count: 363,
    len_sec: 206.654,
    lanemiles: 475.6820000000001,
    delay: 51227.0,
    trkdelay: 21259.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 433.0,
    congcost: 1956639.0,
    allspeed: 59.66,
    trkspeed: 60.74,
    ttivmt: 166779.0,
    trkttivmt: 59395.0,
  },
  {
    agglevel: "county",
    regioncode: 101,
    year: 2017,
    count: 512,
    len_sec: 290.8269999999999,
    lanemiles: 662.4359999999998,
    delay: 983596.0,
    trkdelay: 64621.0,
    pti80: 1.14,
    trkpti80: 1.11,
    pti95: 1.17,
    trkpti95: 1.14,
    tti: 1.07,
    trktti: 1.04,
    ghg: 3598.0,
    congcost: 2.0141642e7,
    allspeed: 52.32,
    trkspeed: 57.73,
    ttivmt: 599683.0,
    trkttivmt: 72709.0,
  },
  {
    agglevel: "county",
    regioncode: 101,
    year: 2018,
    count: 556,
    len_sec: 290.4849999999996,
    lanemiles: 660.4079999999998,
    delay: 944016.0,
    trkdelay: 84140.0,
    pti80: 1.16,
    trkpti80: 1.19,
    pti95: 1.19,
    trkpti95: 1.25,
    tti: 1.08,
    trktti: 1.09,
    ghg: 3941.0,
    congcost: 2.0507421e7,
    allspeed: 53.31,
    trkspeed: 54.4,
    ttivmt: 553491.0,
    trkttivmt: 49518.0,
  },
  {
    agglevel: "county",
    regioncode: 101,
    year: 2019,
    count: 182,
    len_sec: 214.00300000000007,
    lanemiles: 509.5559999999999,
    delay: 943296.0,
    trkdelay: 76654.0,
    pti80: 1.17,
    trkpti80: 1.18,
    pti95: 1.21,
    trkpti95: 1.25,
    tti: 1.08,
    trktti: 1.09,
    ghg: 3689.0,
    congcost: 2.0975187e7,
    allspeed: 54.45,
    trkspeed: 54.77,
    ttivmt: 532767.0,
    trkttivmt: 45090.0,
  },
  {
    agglevel: "county",
    regioncode: 101,
    year: 2020,
    count: 855,
    len_sec: 289.6129999999998,
    lanemiles: 662.0079999999997,
    delay: 898301.0,
    trkdelay: 70296.0,
    pti80: 1.15,
    trkpti80: 1.16,
    pti95: 1.18,
    trkpti95: 1.22,
    tti: 1.07,
    trktti: 1.08,
    ghg: 3506.0,
    congcost: 2.0490235e7,
    allspeed: 54.56,
    trkspeed: 56.64,
    ttivmt: 562326.0,
    trkttivmt: 42824.0,
  },
  {
    agglevel: "county",
    regioncode: 101,
    year: 2021,
    count: 849,
    len_sec: 288.7859999999995,
    lanemiles: 662.9879999999996,
    delay: 645348.0,
    trkdelay: 57581.0,
    pti80: 1.14,
    trkpti80: 1.16,
    pti95: 1.17,
    trkpti95: 1.21,
    tti: 1.06,
    trktti: 1.08,
    ghg: 2609.0,
    congcost: 1.5232707e7,
    allspeed: 54.68,
    trkspeed: 56.71,
    ttivmt: 476125.0,
    trkttivmt: 37896.0,
  },
  {
    agglevel: "county",
    regioncode: 101,
    year: 2022,
    count: 861,
    len_sec: 288.98099999999954,
    lanemiles: 665.5819999999992,
    delay: 714250.0,
    trkdelay: 50187.0,
    pti80: 1.13,
    trkpti80: 1.13,
    pti95: 1.18,
    trkpti95: 1.18,
    tti: 1.06,
    trktti: 1.05,
    ghg: 2394.0,
    congcost: 1.8089031e7,
    allspeed: 54.5,
    trkspeed: 56.95,
    ttivmt: 545065.0,
    trkttivmt: 49043.0,
  },
  {
    agglevel: "county",
    regioncode: 102,
    year: 2017,
    count: 8059,
    len_sec: 4373.290999999998,
    lanemiles: 14464.001999999977,
    delay: 2.32658635e8,
    trkdelay: 1.7225501e7,
    pti80: 1.54,
    trkpti80: 1.75,
    pti95: 1.68,
    trkpti95: 1.98,
    tti: 1.27,
    trktti: 1.37,
    ghg: 1013381.0,
    congcost: 4.850104389e9,
    allspeed: 41.59,
    trkspeed: 44.58,
    ttivmt: 5.2871917e7,
    trkttivmt: 4244527.0,
  },
  {
    agglevel: "county",
    regioncode: 102,
    year: 2018,
    count: 8337,
    len_sec: 4545.711000000007,
    lanemiles: 15102.586000000054,
    delay: 2.59996482e8,
    trkdelay: 1.1534567e7,
    pti80: 1.67,
    trkpti80: 1.78,
    pti95: 1.81,
    trkpti95: 1.99,
    tti: 1.37,
    trktti: 1.4,
    ghg: 1032571.0,
    congcost: 5.285335468e9,
    allspeed: 39.92,
    trkspeed: 42.74,
    ttivmt: 4.3666455e7,
    trkttivmt: 2213887.0,
  },
  {
    agglevel: "county",
    regioncode: 102,
    year: 2019,
    count: 5403,
    len_sec: 3551.3860000000054,
    lanemiles: 12920.443000000045,
    delay: 2.22865093e8,
    trkdelay: 1.1166949e7,
    pti80: 1.57,
    trkpti80: 1.64,
    pti95: 1.69,
    trkpti95: 1.81,
    tti: 1.31,
    trktti: 1.33,
    ghg: 899424.0,
    congcost: 4.738554261e9,
    allspeed: 41.58,
    trkspeed: 43.39,
    ttivmt: 4.307143e7,
    trkttivmt: 2380640.0,
  },
  {
    agglevel: "county",
    regioncode: 102,
    year: 2020,
    count: 12211,
    len_sec: 4577.39800000006,
    lanemiles: 15329.345000000307,
    delay: 2.33593155e8,
    trkdelay: 1.0848545e7,
    pti80: 1.58,
    trkpti80: 1.62,
    pti95: 1.7,
    trkpti95: 1.78,
    tti: 1.31,
    trktti: 1.32,
    ghg: 932680.0,
    congcost: 5.072575592e9,
    allspeed: 41.28,
    trkspeed: 44.79,
    ttivmt: 4.5536813e7,
    trkttivmt: 2407735.0,
  },
  {
    agglevel: "county",
    regioncode: 102,
    year: 2021,
    count: 12763,
    len_sec: 4606.056000000068,
    lanemiles: 15506.547000000286,
    delay: 1.10338581e8,
    trkdelay: 6532543.0,
    pti80: 1.29,
    trkpti80: 1.35,
    pti95: 1.36,
    trkpti95: 1.51,
    tti: 1.15,
    trktti: 1.17,
    ghg: 455368.0,
    congcost: 2.508841098e9,
    allspeed: 44.13,
    trkspeed: 47.94,
    ttivmt: 4.0649474e7,
    trkttivmt: 2125128.0,
  },
  {
    agglevel: "county",
    regioncode: 102,
    year: 2022,
    count: 13845,
    len_sec: 4669.0010000000675,
    lanemiles: 15808.866000000242,
    delay: 1.50479354e8,
    trkdelay: 8298192.0,
    pti80: 1.37,
    trkpti80: 1.41,
    pti95: 1.46,
    trkpti95: 1.57,
    tti: 1.2,
    trktti: 1.2,
    ghg: 592260.0,
    congcost: 3.758697336e9,
    allspeed: 44.4,
    trkspeed: 48.75,
    ttivmt: 4.1824483e7,
    trkttivmt: 2563426.0,
  },
  {
    agglevel: "county",
    regioncode: 103,
    year: 2017,
    count: 812,
    len_sec: 548.5459999999991,
    lanemiles: 1332.404999999999,
    delay: 1269086.0,
    trkdelay: 92420.0,
    pti80: 1.12,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.01,
    ghg: 5539.0,
    congcost: 2.640967e7,
    allspeed: 55.47,
    trkspeed: 62.65,
    ttivmt: 1101058.0,
    trkttivmt: 300149.0,
  },
  {
    agglevel: "county",
    regioncode: 103,
    year: 2018,
    count: 871,
    len_sec: 546.0249999999997,
    lanemiles: 1327.7109999999996,
    delay: 1162132.0,
    trkdelay: 129145.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.03,
    ghg: 5523.0,
    congcost: 2.6146534e7,
    allspeed: 57.01,
    trkspeed: 60.86,
    ttivmt: 1080708.0,
    trkttivmt: 207829.0,
  },
  {
    agglevel: "county",
    regioncode: 103,
    year: 2019,
    count: 434,
    len_sec: 523.0909999999997,
    lanemiles: 1282.1989999999992,
    delay: 1053832.0,
    trkdelay: 142598.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.03,
    ghg: 5440.0,
    congcost: 2.5592696e7,
    allspeed: 58.54,
    trkspeed: 60.25,
    ttivmt: 1124734.0,
    trkttivmt: 206811.0,
  },
  {
    agglevel: "county",
    regioncode: 103,
    year: 2020,
    count: 1299,
    len_sec: 546.3059999999987,
    lanemiles: 1323.822999999995,
    delay: 1024266.0,
    trkdelay: 139120.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.03,
    ghg: 5213.0,
    congcost: 2.5694174e7,
    allspeed: 58.39,
    trkspeed: 61.74,
    ttivmt: 1118617.0,
    trkttivmt: 208840.0,
  },
  {
    agglevel: "county",
    regioncode: 103,
    year: 2021,
    count: 1293,
    len_sec: 546.4019999999994,
    lanemiles: 1321.1819999999975,
    delay: 686879.0,
    trkdelay: 93708.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.02,
    ghg: 3712.0,
    congcost: 1.7340716e7,
    allspeed: 58.56,
    trkspeed: 62.11,
    ttivmt: 1042349.0,
    trkttivmt: 205946.0,
  },
  {
    agglevel: "county",
    regioncode: 103,
    year: 2022,
    count: 1297,
    len_sec: 546.3989999999991,
    lanemiles: 1321.1759999999995,
    delay: 960814.0,
    trkdelay: 127016.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.18,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.02,
    ghg: 3703.0,
    congcost: 2.6427772e7,
    allspeed: 59.01,
    trkspeed: 62.22,
    ttivmt: 1142660.0,
    trkttivmt: 263965.0,
  },
  {
    agglevel: "county",
    regioncode: 104,
    year: 2017,
    count: 197,
    len_sec: 257.48699999999997,
    lanemiles: 547.343,
    delay: 92353.0,
    trkdelay: 21833.0,
    pti80: 1.07,
    trkpti80: 1.05,
    pti95: 1.1,
    trkpti95: 1.06,
    tti: 1.02,
    trktti: 1.01,
    ghg: 446.0,
    congcost: 2374841.0,
    allspeed: 59.66,
    trkspeed: 63.4,
    ttivmt: 155478.0,
    trkttivmt: 106495.0,
  },
  {
    agglevel: "county",
    regioncode: 104,
    year: 2018,
    count: 168,
    len_sec: 257.405,
    lanemiles: 547.999,
    delay: 71435.0,
    trkdelay: 19320.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.08,
    tti: 1.02,
    trktti: 1.02,
    ghg: 408.0,
    congcost: 1963952.0,
    allspeed: 59.39,
    trkspeed: 61.09,
    ttivmt: 158150.0,
    trkttivmt: 44409.0,
  },
  {
    agglevel: "county",
    regioncode: 104,
    year: 2019,
    count: 88,
    len_sec: 257.436,
    lanemiles: 548.0609999999999,
    delay: 55284.0,
    trkdelay: 14738.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.02,
    ghg: 244.0,
    congcost: 1564645.0,
    allspeed: 61.88,
    trkspeed: 61.7,
    ttivmt: 196000.0,
    trkttivmt: 37816.0,
  },
  {
    agglevel: "county",
    regioncode: 104,
    year: 2020,
    count: 263,
    len_sec: 257.43799999999993,
    lanemiles: 558.7249999999997,
    delay: 70438.0,
    trkdelay: 13077.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.01,
    ghg: 305.0,
    congcost: 1876384.0,
    allspeed: 61.51,
    trkspeed: 62.88,
    ttivmt: 181259.0,
    trkttivmt: 35457.0,
  },
  {
    agglevel: "county",
    regioncode: 104,
    year: 2021,
    count: 249,
    len_sec: 255.33100000000016,
    lanemiles: 555.1469999999999,
    delay: 60331.0,
    trkdelay: 17536.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 297.0,
    congcost: 1783044.0,
    allspeed: 61.87,
    trkspeed: 62.98,
    ttivmt: 178896.0,
    trkttivmt: 55147.0,
  },
  {
    agglevel: "county",
    regioncode: 104,
    year: 2022,
    count: 255,
    len_sec: 255.338,
    lanemiles: 555.159,
    delay: 105591.0,
    trkdelay: 27881.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.15,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 530.0,
    congcost: 3396477.0,
    allspeed: 61.66,
    trkspeed: 62.18,
    ttivmt: 197768.0,
    trkttivmt: 51746.0,
  },
  {
    agglevel: "county",
    regioncode: 105,
    year: 2017,
    count: 293,
    len_sec: 301.87099999999975,
    lanemiles: 677.6020000000002,
    delay: 76521.0,
    trkdelay: 13168.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 416.0,
    congcost: 1832289.0,
    allspeed: 58.66,
    trkspeed: 60.29,
    ttivmt: 84687.0,
    trkttivmt: 19594.0,
  },
  {
    agglevel: "county",
    regioncode: 105,
    year: 2018,
    count: 312,
    len_sec: 299.1600000000002,
    lanemiles: 671.8180000000002,
    delay: 47206.0,
    trkdelay: 6493.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 224.0,
    congcost: 1102092.0,
    allspeed: 57.19,
    trkspeed: 57.05,
    ttivmt: 88660.0,
    trkttivmt: 13811.0,
  },
  {
    agglevel: "county",
    regioncode: 105,
    year: 2019,
    count: 163,
    len_sec: 300.45100000000014,
    lanemiles: 674.4000000000005,
    delay: 58088.0,
    trkdelay: 14571.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 379.0,
    congcost: 1649306.0,
    allspeed: 60.61,
    trkspeed: 60.24,
    ttivmt: 93318.0,
    trkttivmt: 17487.0,
  },
  {
    agglevel: "county",
    regioncode: 105,
    year: 2020,
    count: 422,
    len_sec: 301.67799999999977,
    lanemiles: 678.8099999999995,
    delay: 66783.0,
    trkdelay: 12575.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.02,
    ghg: 372.0,
    congcost: 1809607.0,
    allspeed: 60.23,
    trkspeed: 60.91,
    ttivmt: 91456.0,
    trkttivmt: 17509.0,
  },
  {
    agglevel: "county",
    regioncode: 105,
    year: 2021,
    count: 415,
    len_sec: 301.6779999999997,
    lanemiles: 677.6959999999997,
    delay: 45690.0,
    trkdelay: 8389.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.02,
    ghg: 235.0,
    congcost: 1216093.0,
    allspeed: 59.83,
    trkspeed: 60.55,
    ttivmt: 88054.0,
    trkttivmt: 15920.0,
  },
  {
    agglevel: "county",
    regioncode: 105,
    year: 2022,
    count: 413,
    len_sec: 301.7190000000003,
    lanemiles: 677.7800000000003,
    delay: 65633.0,
    trkdelay: 12392.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.02,
    trktti: 1.03,
    ghg: 243.0,
    congcost: 1933038.0,
    allspeed: 59.51,
    trkspeed: 60.18,
    ttivmt: 96603.0,
    trkttivmt: 18489.0,
  },
  {
    agglevel: "county",
    regioncode: 106,
    year: 2017,
    count: 579,
    len_sec: 384.909,
    lanemiles: 975.55,
    delay: 5373774.0,
    trkdelay: 251694.0,
    pti80: 1.18,
    trkpti80: 1.13,
    pti95: 1.24,
    trkpti95: 1.19,
    tti: 1.06,
    trktti: 1.04,
    ghg: 20499.0,
    congcost: 1.07182401e8,
    allspeed: 50.66,
    trkspeed: 57.84,
    ttivmt: 3138003.0,
    trkttivmt: 301864.0,
  },
  {
    agglevel: "county",
    regioncode: 106,
    year: 2018,
    count: 561,
    len_sec: 386.6589999999994,
    lanemiles: 984.7799999999987,
    delay: 3971357.0,
    trkdelay: 249616.0,
    pti80: 1.17,
    trkpti80: 1.15,
    pti95: 1.23,
    trkpti95: 1.22,
    tti: 1.07,
    trktti: 1.05,
    ghg: 15959.0,
    congcost: 8.2966404e7,
    allspeed: 53.3,
    trkspeed: 57.43,
    ttivmt: 2226580.0,
    trkttivmt: 194669.0,
  },
  {
    agglevel: "county",
    regioncode: 106,
    year: 2019,
    count: 294,
    len_sec: 281.07700000000006,
    lanemiles: 745.6829999999998,
    delay: 3939433.0,
    trkdelay: 271784.0,
    pti80: 1.17,
    trkpti80: 1.14,
    pti95: 1.22,
    trkpti95: 1.22,
    tti: 1.07,
    trktti: 1.06,
    ghg: 16549.0,
    congcost: 8.6310577e7,
    allspeed: 54.3,
    trkspeed: 54.85,
    ttivmt: 2254207.0,
    trkttivmt: 198135.0,
  },
  {
    agglevel: "county",
    regioncode: 106,
    year: 2020,
    count: 1148,
    len_sec: 386.63899999999944,
    lanemiles: 986.0529999999983,
    delay: 5127600.0,
    trkdelay: 268452.0,
    pti80: 1.18,
    trkpti80: 1.17,
    pti95: 1.25,
    trkpti95: 1.28,
    tti: 1.08,
    trktti: 1.07,
    ghg: 19878.0,
    congcost: 1.12250851e8,
    allspeed: 53.08,
    trkspeed: 58.21,
    ttivmt: 2681320.0,
    trkttivmt: 207080.0,
  },
  {
    agglevel: "county",
    regioncode: 106,
    year: 2021,
    count: 1215,
    len_sec: 386.82299999999896,
    lanemiles: 988.0019999999969,
    delay: 2903902.0,
    trkdelay: 245465.0,
    pti80: 1.15,
    trkpti80: 1.15,
    pti95: 1.2,
    trkpti95: 1.23,
    tti: 1.06,
    trktti: 1.05,
    ghg: 13124.0,
    congcost: 6.8384154e7,
    allspeed: 54.59,
    trkspeed: 59.3,
    ttivmt: 2102289.0,
    trkttivmt: 231213.0,
  },
  {
    agglevel: "county",
    regioncode: 106,
    year: 2022,
    count: 1413,
    len_sec: 387.4139999999996,
    lanemiles: 1000.7159999999978,
    delay: 4370779.0,
    trkdelay: 303701.0,
    pti80: 1.17,
    trkpti80: 1.15,
    pti95: 1.23,
    trkpti95: 1.23,
    tti: 1.07,
    trktti: 1.06,
    ghg: 17922.0,
    congcost: 1.11435136e8,
    allspeed: 52.85,
    trkspeed: 58.11,
    ttivmt: 2462695.0,
    trkttivmt: 245304.0,
  },
  {
    agglevel: "county",
    regioncode: 107,
    year: 2017,
    count: 182,
    len_sec: 184.41200000000003,
    lanemiles: 393.0910000000002,
    delay: 38913.0,
    trkdelay: 11941.0,
    pti80: 1.07,
    trkpti80: 1.08,
    pti95: 1.09,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.01,
    ghg: 224.0,
    congcost: 1087307.0,
    allspeed: 59.66,
    trkspeed: 61.64,
    ttivmt: 67509.0,
    trkttivmt: 23064.0,
  },
  {
    agglevel: "county",
    regioncode: 107,
    year: 2018,
    count: 172,
    len_sec: 184.21800000000005,
    lanemiles: 394.248,
    delay: 36903.0,
    trkdelay: 11154.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.04,
    ghg: 187.0,
    congcost: 1046699.0,
    allspeed: 59.3,
    trkspeed: 59.68,
    ttivmt: 69089.0,
    trkttivmt: 13500.0,
  },
  {
    agglevel: "county",
    regioncode: 107,
    year: 2019,
    count: 73,
    len_sec: 184.23600000000002,
    lanemiles: 394.76300000000003,
    delay: 26937.0,
    trkdelay: 10780.0,
    pti80: 1.08,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 151.0,
    congcost: 889225.0,
    allspeed: 60.68,
    trkspeed: 59.57,
    ttivmt: 65553.0,
    trkttivmt: 14435.0,
  },
  {
    agglevel: "county",
    regioncode: 107,
    year: 2020,
    count: 284,
    len_sec: 184.29999999999978,
    lanemiles: 385.35299999999967,
    delay: 34252.0,
    trkdelay: 10461.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 184.0,
    congcost: 1067394.0,
    allspeed: 59.68,
    trkspeed: 59.94,
    ttivmt: 58269.0,
    trkttivmt: 12934.0,
  },
  {
    agglevel: "county",
    regioncode: 107,
    year: 2021,
    count: 284,
    len_sec: 184.271,
    lanemiles: 386.1390000000002,
    delay: 27063.0,
    trkdelay: 12113.0,
    pti80: 1.08,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.04,
    ghg: 176.0,
    congcost: 934646.0,
    allspeed: 60.11,
    trkspeed: 60.14,
    ttivmt: 52206.0,
    trkttivmt: 14515.0,
  },
  {
    agglevel: "county",
    regioncode: 107,
    year: 2022,
    count: 287,
    len_sec: 184.27400000000014,
    lanemiles: 386.14500000000015,
    delay: 45528.0,
    trkdelay: 13431.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 175.0,
    congcost: 1504877.0,
    allspeed: 59.59,
    trkspeed: 60.0,
    ttivmt: 52083.0,
    trkttivmt: 12905.0,
  },
  {
    agglevel: "county",
    regioncode: 108,
    year: 2017,
    count: 1171,
    len_sec: 542.5960000000002,
    lanemiles: 1274.7730000000008,
    delay: 1226718.0,
    trkdelay: 65130.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.05,
    trktti: 1.04,
    ghg: 4562.0,
    congcost: 2.4677378e7,
    allspeed: 50.9,
    trkspeed: 55.06,
    ttivmt: 823302.0,
    trkttivmt: 66486.0,
  },
  {
    agglevel: "county",
    regioncode: 108,
    year: 2018,
    count: 1149,
    len_sec: 542.774,
    lanemiles: 1274.9189999999999,
    delay: 1130405.0,
    trkdelay: 96490.0,
    pti80: 1.11,
    trkpti80: 1.16,
    pti95: 1.14,
    trkpti95: 1.2,
    tti: 1.05,
    trktti: 1.07,
    ghg: 4790.0,
    congcost: 2.4443446e7,
    allspeed: 51.7,
    trkspeed: 52.95,
    ttivmt: 748995.0,
    trkttivmt: 64597.0,
  },
  {
    agglevel: "county",
    regioncode: 108,
    year: 2019,
    count: 637,
    len_sec: 542.8689999999996,
    lanemiles: 1275.0699999999995,
    delay: 1091806.0,
    trkdelay: 79065.0,
    pti80: 1.11,
    trkpti80: 1.16,
    pti95: 1.13,
    trkpti95: 1.21,
    tti: 1.05,
    trktti: 1.07,
    ghg: 4473.0,
    congcost: 2.4015741e7,
    allspeed: 53.72,
    trkspeed: 53.4,
    ttivmt: 786378.0,
    trkttivmt: 47912.0,
  },
  {
    agglevel: "county",
    regioncode: 108,
    year: 2020,
    count: 1668,
    len_sec: 544.3919999999998,
    lanemiles: 1303.2439999999947,
    delay: 1005638.0,
    trkdelay: 90919.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.12,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.06,
    ghg: 4608.0,
    congcost: 2.3527708e7,
    allspeed: 53.78,
    trkspeed: 54.34,
    ttivmt: 798244.0,
    trkttivmt: 61036.0,
  },
  {
    agglevel: "county",
    regioncode: 108,
    year: 2021,
    count: 1696,
    len_sec: 545.0739999999986,
    lanemiles: 1304.2299999999975,
    delay: 694846.0,
    trkdelay: 71420.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.19,
    tti: 1.03,
    trktti: 1.05,
    ghg: 3157.0,
    congcost: 1.6751095e7,
    allspeed: 53.72,
    trkspeed: 54.8,
    ttivmt: 736020.0,
    trkttivmt: 55049.0,
  },
  {
    agglevel: "county",
    regioncode: 108,
    year: 2022,
    count: 1709,
    len_sec: 545.0319999999987,
    lanemiles: 1308.3099999999945,
    delay: 1179377.0,
    trkdelay: 97032.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.19,
    trkpti95: 1.24,
    tti: 1.05,
    trktti: 1.06,
    ghg: 4679.0,
    congcost: 3.05288e7,
    allspeed: 53.16,
    trkspeed: 55.01,
    ttivmt: 793525.0,
    trkttivmt: 65162.0,
  },
  {
    agglevel: "county",
    regioncode: 109,
    year: 2017,
    count: 3165,
    len_sec: 1522.0669999999957,
    lanemiles: 3984.4289999999983,
    delay: 2.5508237e7,
    trkdelay: 645107.0,
    pti80: 1.21,
    trkpti80: 1.2,
    pti95: 1.26,
    trkpti95: 1.25,
    tti: 1.11,
    trktti: 1.09,
    ghg: 102887.0,
    congcost: 4.93819469e8,
    allspeed: 39.78,
    trkspeed: 50.54,
    ttivmt: 7827687.0,
    trkttivmt: 381192.0,
  },
  {
    agglevel: "county",
    regioncode: 109,
    year: 2018,
    count: 2304,
    len_sec: 1551.586000000001,
    lanemiles: 4060.527,
    delay: 2.2189198e7,
    trkdelay: 853700.0,
    pti80: 1.26,
    trkpti80: 1.27,
    pti95: 1.31,
    trkpti95: 1.35,
    tti: 1.14,
    trktti: 1.13,
    ghg: 96017.0,
    congcost: 4.48864855e8,
    allspeed: 40.96,
    trkspeed: 47.93,
    ttivmt: 5648514.0,
    trkttivmt: 300044.0,
  },
  {
    agglevel: "county",
    regioncode: 109,
    year: 2019,
    count: 1301,
    len_sec: 942.0750000000005,
    lanemiles: 2807.7559999999967,
    delay: 1.979064e7,
    trkdelay: 760154.0,
    pti80: 1.24,
    trkpti80: 1.23,
    pti95: 1.28,
    trkpti95: 1.3,
    tti: 1.13,
    trktti: 1.11,
    ghg: 88053.0,
    congcost: 4.15529431e8,
    allspeed: 41.69,
    trkspeed: 49.3,
    ttivmt: 5194974.0,
    trkttivmt: 282227.0,
  },
  {
    agglevel: "county",
    regioncode: 109,
    year: 2020,
    count: 3807,
    len_sec: 1554.0359999999966,
    lanemiles: 4083.095999999989,
    delay: 2.2735848e7,
    trkdelay: 925860.0,
    pti80: 1.24,
    trkpti80: 1.24,
    pti95: 1.3,
    trkpti95: 1.31,
    tti: 1.13,
    trktti: 1.12,
    ghg: 103000.0,
    congcost: 4.92136443e8,
    allspeed: 40.87,
    trkspeed: 48.36,
    ttivmt: 6197026.0,
    trkttivmt: 308194.0,
  },
  {
    agglevel: "county",
    regioncode: 109,
    year: 2021,
    count: 4201,
    len_sec: 1558.3839999999905,
    lanemiles: 4098.793999999981,
    delay: 1.3308365e7,
    trkdelay: 657897.0,
    pti80: 1.18,
    trkpti80: 1.2,
    pti95: 1.23,
    trkpti95: 1.3,
    tti: 1.08,
    trktti: 1.08,
    ghg: 62235.0,
    congcost: 3.00371821e8,
    allspeed: 41.77,
    trkspeed: 49.7,
    ttivmt: 5430936.0,
    trkttivmt: 301214.0,
  },
  {
    agglevel: "county",
    regioncode: 109,
    year: 2022,
    count: 4295,
    len_sec: 1554.6939999999888,
    lanemiles: 4115.059999999969,
    delay: 1.9338344e7,
    trkdelay: 1081153.0,
    pti80: 1.22,
    trkpti80: 1.25,
    pti95: 1.29,
    trkpti95: 1.39,
    tti: 1.11,
    trktti: 1.11,
    ghg: 91445.0,
    congcost: 4.87947076e8,
    allspeed: 40.35,
    trkspeed: 47.64,
    ttivmt: 5993365.0,
    trkttivmt: 362187.0,
  },
  {
    agglevel: "county",
    regioncode: 110,
    year: 2017,
    count: 759,
    len_sec: 562.5089999999999,
    lanemiles: 1229.168999999998,
    delay: 498611.0,
    trkdelay: 59547.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1941.0,
    congcost: 1.102243e7,
    allspeed: 61.44,
    trkspeed: 63.42,
    ttivmt: 1040851.0,
    trkttivmt: 246006.0,
  },
  {
    agglevel: "county",
    regioncode: 110,
    year: 2018,
    count: 947,
    len_sec: 565.8009999999996,
    lanemiles: 1238.0789999999997,
    delay: 440883.0,
    trkdelay: 60129.0,
    pti80: 1.09,
    trkpti80: 1.05,
    pti95: 1.13,
    trkpti95: 1.06,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1813.0,
    congcost: 1.0205628e7,
    allspeed: 61.27,
    trkspeed: 62.69,
    ttivmt: 1051755.0,
    trkttivmt: 237532.0,
  },
  {
    agglevel: "county",
    regioncode: 110,
    year: 2019,
    count: 449,
    len_sec: 562.3019999999997,
    lanemiles: 1231.0679999999995,
    delay: 474186.0,
    trkdelay: 81222.0,
    pti80: 1.1,
    trkpti80: 1.06,
    pti95: 1.14,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.01,
    ghg: 2360.0,
    congcost: 1.2048117e7,
    allspeed: 61.76,
    trkspeed: 61.38,
    ttivmt: 1078306.0,
    trkttivmt: 244079.0,
  },
  {
    agglevel: "county",
    regioncode: 110,
    year: 2020,
    count: 1294,
    len_sec: 566.1249999999984,
    lanemiles: 1238.5989999999963,
    delay: 514274.0,
    trkdelay: 66585.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 2277.0,
    congcost: 1.2716552e7,
    allspeed: 61.65,
    trkspeed: 63.32,
    ttivmt: 1093466.0,
    trkttivmt: 251620.0,
  },
  {
    agglevel: "county",
    regioncode: 110,
    year: 2021,
    count: 1322,
    len_sec: 566.7239999999987,
    lanemiles: 1239.3749999999964,
    delay: 481778.0,
    trkdelay: 65495.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.01,
    ghg: 2003.0,
    congcost: 1.2036532e7,
    allspeed: 61.33,
    trkspeed: 63.25,
    ttivmt: 976502.0,
    trkttivmt: 223256.0,
  },
  {
    agglevel: "county",
    regioncode: 110,
    year: 2022,
    count: 1409,
    len_sec: 576.2099999999986,
    lanemiles: 1294.153999999997,
    delay: 589425.0,
    trkdelay: 109063.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.16,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2079.0,
    congcost: 1.7197368e7,
    allspeed: 61.45,
    trkspeed: 62.84,
    ttivmt: 1068667.0,
    trkttivmt: 224347.0,
  },
  {
    agglevel: "county",
    regioncode: 111,
    year: 2017,
    count: 384,
    len_sec: 462.9199999999997,
    lanemiles: 1005.616,
    delay: 446696.0,
    trkdelay: 18043.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.02,
    ghg: 1613.0,
    congcost: 8812623.0,
    allspeed: 53.89,
    trkspeed: 60.97,
    ttivmt: 331613.0,
    trkttivmt: 34257.0,
  },
  {
    agglevel: "county",
    regioncode: 111,
    year: 2018,
    count: 322,
    len_sec: 462.7100000000001,
    lanemiles: 1004.1980000000003,
    delay: 269911.0,
    trkdelay: 25072.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1007.0,
    congcost: 5870650.0,
    allspeed: 57.6,
    trkspeed: 58.37,
    ttivmt: 290860.0,
    trkttivmt: 24554.0,
  },
  {
    agglevel: "county",
    regioncode: 111,
    year: 2019,
    count: 268,
    len_sec: 458.00500000000017,
    lanemiles: 994.788000000001,
    delay: 216341.0,
    trkdelay: 29537.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 944.0,
    congcost: 5224640.0,
    allspeed: 59.73,
    trkspeed: 58.56,
    ttivmt: 271469.0,
    trkttivmt: 26907.0,
  },
  {
    agglevel: "county",
    regioncode: 111,
    year: 2020,
    count: 474,
    len_sec: 462.74899999999974,
    lanemiles: 1004.2759999999993,
    delay: 211053.0,
    trkdelay: 28620.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.03,
    ghg: 940.0,
    congcost: 5268567.0,
    allspeed: 59.42,
    trkspeed: 59.49,
    ttivmt: 268027.0,
    trkttivmt: 28704.0,
  },
  {
    agglevel: "county",
    regioncode: 111,
    year: 2021,
    count: 469,
    len_sec: 462.75999999999937,
    lanemiles: 1004.2279999999994,
    delay: 110210.0,
    trkdelay: 19961.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.03,
    ghg: 468.0,
    congcost: 2902486.0,
    allspeed: 59.42,
    trkspeed: 59.92,
    ttivmt: 261552.0,
    trkttivmt: 28892.0,
  },
  {
    agglevel: "county",
    regioncode: 111,
    year: 2022,
    count: 468,
    len_sec: 462.82499999999936,
    lanemiles: 1005.2929999999986,
    delay: 266979.0,
    trkdelay: 23141.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1012.0,
    congcost: 6942056.0,
    allspeed: 57.68,
    trkspeed: 59.28,
    ttivmt: 291547.0,
    trkttivmt: 28641.0,
  },
  {
    agglevel: "county",
    regioncode: 112,
    year: 2017,
    count: 413,
    len_sec: 214.92100000000008,
    lanemiles: 477.4550000000001,
    delay: 1447145.0,
    trkdelay: 66626.0,
    pti80: 1.18,
    trkpti80: 1.16,
    pti95: 1.21,
    trkpti95: 1.2,
    tti: 1.1,
    trktti: 1.08,
    ghg: 5526.0,
    congcost: 2.8831637e7,
    allspeed: 45.22,
    trkspeed: 51.18,
    ttivmt: 509637.0,
    trkttivmt: 34117.0,
  },
  {
    agglevel: "county",
    regioncode: 112,
    year: 2018,
    count: 363,
    len_sec: 215.33200000000002,
    lanemiles: 478.2919999999998,
    delay: 1317298.0,
    trkdelay: 79361.0,
    pti80: 1.22,
    trkpti80: 1.28,
    pti95: 1.25,
    trkpti95: 1.36,
    tti: 1.12,
    trktti: 1.13,
    ghg: 5158.0,
    congcost: 2.7385064e7,
    allspeed: 46.8,
    trkspeed: 47.53,
    ttivmt: 419165.0,
    trkttivmt: 27092.0,
  },
  {
    agglevel: "county",
    regioncode: 112,
    year: 2019,
    count: 210,
    len_sec: 215.30299999999997,
    lanemiles: 478.234,
    delay: 1205687.0,
    trkdelay: 72038.0,
    pti80: 1.19,
    trkpti80: 1.25,
    pti95: 1.21,
    trkpti95: 1.33,
    tti: 1.11,
    trktti: 1.12,
    ghg: 4799.0,
    congcost: 2.5986002e7,
    allspeed: 49.36,
    trkspeed: 48.23,
    ttivmt: 422772.0,
    trkttivmt: 23105.0,
  },
  {
    agglevel: "county",
    regioncode: 112,
    year: 2020,
    count: 594,
    len_sec: 215.33700000000002,
    lanemiles: 480.89499999999975,
    delay: 1372883.0,
    trkdelay: 53714.0,
    pti80: 1.2,
    trkpti80: 1.21,
    pti95: 1.23,
    trkpti95: 1.27,
    tti: 1.11,
    trktti: 1.11,
    ghg: 4991.0,
    congcost: 2.9351588e7,
    allspeed: 48.41,
    trkspeed: 51.01,
    ttivmt: 452741.0,
    trkttivmt: 20819.0,
  },
  {
    agglevel: "county",
    regioncode: 112,
    year: 2021,
    count: 614,
    len_sec: 215.367,
    lanemiles: 480.95500000000027,
    delay: 738721.0,
    trkdelay: 36214.0,
    pti80: 1.16,
    trkpti80: 1.19,
    pti95: 1.21,
    trkpti95: 1.3,
    tti: 1.07,
    trktti: 1.08,
    ghg: 2752.0,
    congcost: 1.6524615e7,
    allspeed: 49.78,
    trkspeed: 52.57,
    ttivmt: 431214.0,
    trkttivmt: 20256.0,
  },
  {
    agglevel: "county",
    regioncode: 112,
    year: 2022,
    count: 624,
    len_sec: 215.38600000000002,
    lanemiles: 482.9910000000001,
    delay: 1361140.0,
    trkdelay: 46975.0,
    pti80: 1.2,
    trkpti80: 1.19,
    pti95: 1.27,
    trkpti95: 1.29,
    tti: 1.1,
    trktti: 1.08,
    ghg: 4891.0,
    congcost: 3.2946413e7,
    allspeed: 47.45,
    trkspeed: 49.99,
    ttivmt: 475932.0,
    trkttivmt: 23586.0,
  },
  {
    agglevel: "county",
    regioncode: 113,
    year: 2017,
    count: 601,
    len_sec: 469.7860000000002,
    lanemiles: 1023.9840000000011,
    delay: 603765.0,
    trkdelay: 75968.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.01,
    ghg: 2656.0,
    congcost: 1.3512378e7,
    allspeed: 59.32,
    trkspeed: 63.71,
    ttivmt: 723620.0,
    trkttivmt: 349493.0,
  },
  {
    agglevel: "county",
    regioncode: 113,
    year: 2018,
    count: 699,
    len_sec: 469.52199999999965,
    lanemiles: 1024.0419999999995,
    delay: 581208.0,
    trkdelay: 50328.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.01,
    ghg: 2562.0,
    congcost: 1.2607264e7,
    allspeed: 58.43,
    trkspeed: 62.75,
    ttivmt: 707041.0,
    trkttivmt: 160958.0,
  },
  {
    agglevel: "county",
    regioncode: 113,
    year: 2019,
    count: 296,
    len_sec: 464.55499999999995,
    lanemiles: 1014.1079999999998,
    delay: 571025.0,
    trkdelay: 73684.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.15,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.02,
    ghg: 2874.0,
    congcost: 1.3734198e7,
    allspeed: 59.72,
    trkspeed: 60.85,
    ttivmt: 724162.0,
    trkttivmt: 176650.0,
  },
  {
    agglevel: "county",
    regioncode: 113,
    year: 2020,
    count: 1025,
    len_sec: 470.6289999999993,
    lanemiles: 1070.0729999999985,
    delay: 683687.0,
    trkdelay: 64013.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.01,
    ghg: 3217.0,
    congcost: 1.6083699e7,
    allspeed: 59.68,
    trkspeed: 62.99,
    ttivmt: 779181.0,
    trkttivmt: 179115.0,
  },
  {
    agglevel: "county",
    regioncode: 113,
    year: 2021,
    count: 1019,
    len_sec: 470.69099999999855,
    lanemiles: 1070.1779999999992,
    delay: 474489.0,
    trkdelay: 41392.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.01,
    ghg: 2147.0,
    congcost: 1.1221885e7,
    allspeed: 59.72,
    trkspeed: 63.32,
    ttivmt: 744359.0,
    trkttivmt: 174001.0,
  },
  {
    agglevel: "county",
    regioncode: 113,
    year: 2022,
    count: 1036,
    len_sec: 470.76199999999847,
    lanemiles: 1070.4239999999977,
    delay: 648923.0,
    trkdelay: 50113.0,
    pti80: 1.11,
    trkpti80: 1.05,
    pti95: 1.17,
    trkpti95: 1.08,
    tti: 1.03,
    trktti: 1.01,
    ghg: 2393.0,
    congcost: 1.6641732e7,
    allspeed: 58.93,
    trkspeed: 63.25,
    ttivmt: 747475.0,
    trkttivmt: 190809.0,
  },
  {
    agglevel: "county",
    regioncode: 114,
    year: 2017,
    count: 710,
    len_sec: 432.246,
    lanemiles: 927.8229999999995,
    delay: 309704.0,
    trkdelay: 49474.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.04,
    ghg: 1442.0,
    congcost: 7261265.0,
    allspeed: 54.93,
    trkspeed: 58.84,
    ttivmt: 271404.0,
    trkttivmt: 52089.0,
  },
  {
    agglevel: "county",
    regioncode: 114,
    year: 2018,
    count: 669,
    len_sec: 435.7819999999995,
    lanemiles: 936.9099999999992,
    delay: 266038.0,
    trkdelay: 49405.0,
    pti80: 1.09,
    trkpti80: 1.14,
    pti95: 1.12,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.06,
    ghg: 1451.0,
    congcost: 6630191.0,
    allspeed: 54.07,
    trkspeed: 55.57,
    ttivmt: 256597.0,
    trkttivmt: 37040.0,
  },
  {
    agglevel: "county",
    regioncode: 114,
    year: 2019,
    count: 182,
    len_sec: 236.04300000000006,
    lanemiles: 537.431,
    delay: 201683.0,
    trkdelay: 50396.0,
    pti80: 1.09,
    trkpti80: 1.13,
    pti95: 1.11,
    trkpti95: 1.19,
    tti: 1.03,
    trktti: 1.05,
    ghg: 1245.0,
    congcost: 5693954.0,
    allspeed: 57.65,
    trkspeed: 56.85,
    ttivmt: 238524.0,
    trkttivmt: 39355.0,
  },
  {
    agglevel: "county",
    regioncode: 114,
    year: 2020,
    count: 971,
    len_sec: 435.81599999999963,
    lanemiles: 936.9769999999991,
    delay: 279117.0,
    trkdelay: 48725.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.05,
    ghg: 1471.0,
    congcost: 7397591.0,
    allspeed: 56.06,
    trkspeed: 56.97,
    ttivmt: 262818.0,
    trkttivmt: 39398.0,
  },
  {
    agglevel: "county",
    regioncode: 114,
    year: 2021,
    count: 994,
    len_sec: 435.73999999999836,
    lanemiles: 940.538999999997,
    delay: 196777.0,
    trkdelay: 39568.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.05,
    ghg: 987.0,
    congcost: 5326119.0,
    allspeed: 56.43,
    trkspeed: 57.34,
    ttivmt: 237043.0,
    trkttivmt: 36670.0,
  },
  {
    agglevel: "county",
    regioncode: 114,
    year: 2022,
    count: 994,
    len_sec: 435.7389999999996,
    lanemiles: 940.5369999999989,
    delay: 314169.0,
    trkdelay: 52990.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.19,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.05,
    ghg: 1483.0,
    congcost: 9104219.0,
    allspeed: 55.68,
    trkspeed: 57.35,
    ttivmt: 273351.0,
    trkttivmt: 47673.0,
  },
  {
    agglevel: "county",
    regioncode: 115,
    year: 2017,
    count: 429,
    len_sec: 390.98999999999955,
    lanemiles: 958.824,
    delay: 599120.0,
    trkdelay: 66472.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.01,
    ghg: 3070.0,
    congcost: 1.3223957e7,
    allspeed: 54.74,
    trkspeed: 62.64,
    ttivmt: 569424.0,
    trkttivmt: 224801.0,
  },
  {
    agglevel: "county",
    regioncode: 115,
    year: 2018,
    count: 462,
    len_sec: 398.86199999999997,
    lanemiles: 987.3730000000008,
    delay: 402499.0,
    trkdelay: 61426.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2179.0,
    congcost: 9616332.0,
    allspeed: 58.26,
    trkspeed: 61.48,
    ttivmt: 561282.0,
    trkttivmt: 112367.0,
  },
  {
    agglevel: "county",
    regioncode: 115,
    year: 2019,
    count: 263,
    len_sec: 384.46700000000004,
    lanemiles: 958.2370000000001,
    delay: 390324.0,
    trkdelay: 84910.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2592.0,
    congcost: 1.0660805e7,
    allspeed: 59.66,
    trkspeed: 59.39,
    ttivmt: 602197.0,
    trkttivmt: 150505.0,
  },
  {
    agglevel: "county",
    regioncode: 115,
    year: 2020,
    count: 621,
    len_sec: 398.7759999999998,
    lanemiles: 986.1230000000003,
    delay: 441378.0,
    trkdelay: 60764.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2284.0,
    congcost: 1.111297e7,
    allspeed: 59.13,
    trkspeed: 62.55,
    ttivmt: 643780.0,
    trkttivmt: 145765.0,
  },
  {
    agglevel: "county",
    regioncode: 115,
    year: 2021,
    count: 655,
    len_sec: 415.33399999999943,
    lanemiles: 1022.6559999999995,
    delay: 265542.0,
    trkdelay: 44075.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.01,
    ghg: 1467.0,
    congcost: 6936184.0,
    allspeed: 59.65,
    trkspeed: 63.2,
    ttivmt: 522038.0,
    trkttivmt: 133181.0,
  },
  {
    agglevel: "county",
    regioncode: 115,
    year: 2022,
    count: 669,
    len_sec: 415.3339999999999,
    lanemiles: 1022.6559999999997,
    delay: 408031.0,
    trkdelay: 60869.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.01,
    ghg: 2195.0,
    congcost: 1.1633278e7,
    allspeed: 58.55,
    trkspeed: 62.64,
    ttivmt: 584680.0,
    trkttivmt: 159481.0,
  },
  {
    agglevel: "county",
    regioncode: 116,
    year: 2017,
    count: 261,
    len_sec: 333.4279999999999,
    lanemiles: 813.0620000000005,
    delay: 112798.0,
    trkdelay: 68384.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.0,
    trktti: 1.0,
    ghg: 1388.0,
    congcost: 4280371.0,
    allspeed: 63.83,
    trkspeed: 63.8,
    ttivmt: 546859.0,
    trkttivmt: 465299.0,
  },
  {
    agglevel: "county",
    regioncode: 116,
    year: 2018,
    count: 243,
    len_sec: 339.40599999999984,
    lanemiles: 825.0669999999996,
    delay: 78205.0,
    trkdelay: 20490.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.0,
    trktti: 1.0,
    ghg: 391.0,
    congcost: 2120852.0,
    allspeed: 63.78,
    trkspeed: 64.1,
    ttivmt: 601784.0,
    trkttivmt: 212452.0,
  },
  {
    agglevel: "county",
    regioncode: 116,
    year: 2019,
    count: 57,
    len_sec: 308.40600000000006,
    lanemiles: 763.0650000000003,
    delay: 75632.0,
    trkdelay: 24689.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.14,
    trkpti95: 1.1,
    tti: 1.0,
    trktti: 1.0,
    ghg: 434.0,
    congcost: 2309346.0,
    allspeed: 64.21,
    trkspeed: 62.54,
    ttivmt: 651481.0,
    trkttivmt: 225731.0,
  },
  {
    agglevel: "county",
    regioncode: 116,
    year: 2020,
    count: 406,
    len_sec: 339.6359999999994,
    lanemiles: 825.1899999999985,
    delay: 112315.0,
    trkdelay: 31232.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.0,
    trktti: 1.0,
    ghg: 707.0,
    congcost: 3403745.0,
    allspeed: 64.05,
    trkspeed: 64.26,
    ttivmt: 696553.0,
    trkttivmt: 246930.0,
  },
  {
    agglevel: "county",
    regioncode: 116,
    year: 2021,
    count: 408,
    len_sec: 340.6550000000001,
    lanemiles: 827.2250000000005,
    delay: 100756.0,
    trkdelay: 32799.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.0,
    trktti: 1.0,
    ghg: 708.0,
    congcost: 3122288.0,
    allspeed: 64.04,
    trkspeed: 64.24,
    ttivmt: 616889.0,
    trkttivmt: 239960.0,
  },
  {
    agglevel: "county",
    regioncode: 116,
    year: 2022,
    count: 434,
    len_sec: 346.1309999999999,
    lanemiles: 833.5930000000001,
    delay: 130769.0,
    trkdelay: 47607.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 913.0,
    congcost: 4711308.0,
    allspeed: 63.89,
    trkspeed: 64.05,
    ttivmt: 663950.0,
    trkttivmt: 244232.0,
  },
  {
    agglevel: "county",
    regioncode: 117,
    year: 2017,
    count: 1179,
    len_sec: 698.166999999999,
    lanemiles: 1563.6819999999987,
    delay: 1628834.0,
    trkdelay: 201225.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.03,
    ghg: 8385.0,
    congcost: 3.6563549e7,
    allspeed: 56.06,
    trkspeed: 60.85,
    ttivmt: 1284609.0,
    trkttivmt: 296995.0,
  },
  {
    agglevel: "county",
    regioncode: 117,
    year: 2018,
    count: 1296,
    len_sec: 697.4799999999987,
    lanemiles: 1558.2869999999966,
    delay: 1520472.0,
    trkdelay: 141796.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.13,
    tti: 1.05,
    trktti: 1.03,
    ghg: 6921.0,
    congcost: 3.3331677e7,
    allspeed: 55.26,
    trkspeed: 59.49,
    ttivmt: 1163184.0,
    trkttivmt: 175037.0,
  },
  {
    agglevel: "county",
    regioncode: 117,
    year: 2019,
    count: 616,
    len_sec: 684.8669999999996,
    lanemiles: 1538.7629999999986,
    delay: 1304236.0,
    trkdelay: 169747.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.03,
    ghg: 6670.0,
    congcost: 3.1429558e7,
    allspeed: 56.88,
    trkspeed: 58.68,
    ttivmt: 1162794.0,
    trkttivmt: 181592.0,
  },
  {
    agglevel: "county",
    regioncode: 117,
    year: 2020,
    count: 1915,
    len_sec: 699.5439999999971,
    lanemiles: 1588.3039999999958,
    delay: 1730833.0,
    trkdelay: 108720.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.02,
    ghg: 7135.0,
    congcost: 3.8640065e7,
    allspeed: 56.52,
    trkspeed: 61.63,
    ttivmt: 1307442.0,
    trkttivmt: 181695.0,
  },
  {
    agglevel: "county",
    regioncode: 117,
    year: 2021,
    count: 1960,
    len_sec: 698.2049999999961,
    lanemiles: 1584.0159999999933,
    delay: 1132751.0,
    trkdelay: 91579.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.02,
    ghg: 4916.0,
    congcost: 2.6529547e7,
    allspeed: 56.91,
    trkspeed: 61.81,
    ttivmt: 1227174.0,
    trkttivmt: 183193.0,
  },
  {
    agglevel: "county",
    regioncode: 117,
    year: 2022,
    count: 2031,
    len_sec: 697.5839999999985,
    lanemiles: 1579.8759999999952,
    delay: 1502269.0,
    trkdelay: 104009.0,
    pti80: 1.13,
    trkpti80: 1.07,
    pti95: 1.19,
    trkpti95: 1.1,
    tti: 1.04,
    trktti: 1.02,
    ghg: 5461.0,
    congcost: 3.8106506e7,
    allspeed: 55.75,
    trkspeed: 61.34,
    ttivmt: 1359784.0,
    trkttivmt: 213972.0,
  },
  {
    agglevel: "county",
    regioncode: 118,
    year: 2017,
    count: 363,
    len_sec: 229.2730000000002,
    lanemiles: 534.297,
    delay: 268892.0,
    trkdelay: 10813.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.04,
    trktti: 1.02,
    ghg: 1064.0,
    congcost: 5321545.0,
    allspeed: 48.13,
    trkspeed: 59.63,
    ttivmt: 179205.0,
    trkttivmt: 21075.0,
  },
  {
    agglevel: "county",
    regioncode: 118,
    year: 2018,
    count: 327,
    len_sec: 227.463,
    lanemiles: 530.0390000000006,
    delay: 179550.0,
    trkdelay: 22176.0,
    pti80: 1.12,
    trkpti80: 1.17,
    pti95: 1.15,
    trkpti95: 1.23,
    tti: 1.04,
    trktti: 1.07,
    ghg: 953.0,
    congcost: 4127219.0,
    allspeed: 50.82,
    trkspeed: 54.23,
    ttivmt: 139671.0,
    trkttivmt: 13638.0,
  },
  {
    agglevel: "county",
    regioncode: 118,
    year: 2019,
    count: 208,
    len_sec: 228.59700000000004,
    lanemiles: 531.4530000000003,
    delay: 138414.0,
    trkdelay: 24683.0,
    pti80: 1.1,
    trkpti80: 1.15,
    pti95: 1.13,
    trkpti95: 1.21,
    tti: 1.03,
    trktti: 1.06,
    ghg: 858.0,
    congcost: 3591572.0,
    allspeed: 53.93,
    trkspeed: 54.74,
    ttivmt: 137996.0,
    trkttivmt: 16025.0,
  },
  {
    agglevel: "county",
    regioncode: 118,
    year: 2020,
    count: 499,
    len_sec: 229.1429999999999,
    lanemiles: 532.4269999999993,
    delay: 160042.0,
    trkdelay: 22116.0,
    pti80: 1.09,
    trkpti80: 1.15,
    pti95: 1.12,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.06,
    ghg: 859.0,
    congcost: 4045501.0,
    allspeed: 53.63,
    trkspeed: 55.19,
    ttivmt: 140921.0,
    trkttivmt: 13891.0,
  },
  {
    agglevel: "county",
    regioncode: 118,
    year: 2021,
    count: 487,
    len_sec: 227.83900000000008,
    lanemiles: 533.9670000000001,
    delay: 139149.0,
    trkdelay: 27663.0,
    pti80: 1.1,
    trkpti80: 1.15,
    pti95: 1.13,
    trkpti95: 1.23,
    tti: 1.03,
    trktti: 1.06,
    ghg: 853.0,
    congcost: 3788640.0,
    allspeed: 53.81,
    trkspeed: 55.82,
    ttivmt: 150480.0,
    trkttivmt: 19467.0,
  },
  {
    agglevel: "county",
    regioncode: 118,
    year: 2022,
    count: 487,
    len_sec: 228.27399999999992,
    lanemiles: 534.8349999999997,
    delay: 237283.0,
    trkdelay: 22057.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.19,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.04,
    ghg: 1003.0,
    congcost: 6248981.0,
    allspeed: 52.53,
    trkspeed: 55.11,
    ttivmt: 158560.0,
    trkttivmt: 16865.0,
  },
  {
    agglevel: "county",
    regioncode: 119,
    year: 2017,
    count: 86,
    len_sec: 130.57199999999997,
    lanemiles: 261.35599999999994,
    delay: 28746.0,
    trkdelay: 6031.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 82.0,
    congcost: 708343.0,
    allspeed: 62.45,
    trkspeed: 63.6,
    ttivmt: 87411.0,
    trkttivmt: 25805.0,
  },
  {
    agglevel: "county",
    regioncode: 119,
    year: 2018,
    count: 170,
    len_sec: 130.41900000000007,
    lanemiles: 261.21800000000013,
    delay: 41110.0,
    trkdelay: 10329.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.17,
    tti: 1.01,
    trktti: 1.04,
    ghg: 141.0,
    congcost: 1092905.0,
    allspeed: 62.07,
    trkspeed: 61.42,
    ttivmt: 122871.0,
    trkttivmt: 14887.0,
  },
  {
    agglevel: "county",
    regioncode: 119,
    year: 2019,
    count: 31,
    len_sec: 106.59199999999998,
    lanemiles: 213.56399999999996,
    delay: 23258.0,
    trkdelay: 8411.0,
    pti80: 1.08,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.15,
    tti: 1.01,
    trktti: 1.03,
    ghg: 92.0,
    congcost: 731258.0,
    allspeed: 62.93,
    trkspeed: 61.81,
    ttivmt: 99534.0,
    trkttivmt: 13857.0,
  },
  {
    agglevel: "county",
    regioncode: 119,
    year: 2020,
    count: 197,
    len_sec: 130.41700000000006,
    lanemiles: 261.23200000000014,
    delay: 26524.0,
    trkdelay: 9492.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.01,
    trktti: 1.03,
    ghg: 129.0,
    congcost: 874031.0,
    allspeed: 62.57,
    trkspeed: 62.2,
    ttivmt: 96167.0,
    trkttivmt: 14831.0,
  },
  {
    agglevel: "county",
    regioncode: 119,
    year: 2021,
    count: 194,
    len_sec: 130.417,
    lanemiles: 261.232,
    delay: 18687.0,
    trkdelay: 7075.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.03,
    ghg: 115.0,
    congcost: 607902.0,
    allspeed: 62.59,
    trkspeed: 62.53,
    ttivmt: 76543.0,
    trkttivmt: 11631.0,
  },
  {
    agglevel: "county",
    regioncode: 119,
    year: 2022,
    count: 193,
    len_sec: 130.50500000000002,
    lanemiles: 261.01000000000005,
    delay: 44285.0,
    trkdelay: 11545.0,
    pti80: 1.08,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 197.0,
    congcost: 1418028.0,
    allspeed: 62.51,
    trkspeed: 61.87,
    ttivmt: 78884.0,
    trkttivmt: 12039.0,
  },
  {
    agglevel: "county",
    regioncode: 120,
    year: 2017,
    count: 249,
    len_sec: 307.7079999999997,
    lanemiles: 647.9379999999995,
    delay: 77353.0,
    trkdelay: 15615.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 318.0,
    congcost: 1900956.0,
    allspeed: 59.13,
    trkspeed: 61.75,
    ttivmt: 131040.0,
    trkttivmt: 31988.0,
  },
  {
    agglevel: "county",
    regioncode: 120,
    year: 2018,
    count: 305,
    len_sec: 306.8169999999999,
    lanemiles: 646.6179999999999,
    delay: 87813.0,
    trkdelay: 22939.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.11,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 481.0,
    congcost: 2389217.0,
    allspeed: 58.43,
    trkspeed: 58.76,
    ttivmt: 129147.0,
    trkttivmt: 24195.0,
  },
  {
    agglevel: "county",
    regioncode: 120,
    year: 2019,
    count: 128,
    len_sec: 306.8539999999999,
    lanemiles: 646.692,
    delay: 77414.0,
    trkdelay: 19905.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.11,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 396.0,
    congcost: 2183101.0,
    allspeed: 59.13,
    trkspeed: 58.52,
    ttivmt: 123314.0,
    trkttivmt: 19909.0,
  },
  {
    agglevel: "county",
    regioncode: 120,
    year: 2020,
    count: 474,
    len_sec: 306.9030000000001,
    lanemiles: 654.2080000000001,
    delay: 78203.0,
    trkdelay: 18770.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.04,
    ghg: 382.0,
    congcost: 2247819.0,
    allspeed: 59.26,
    trkspeed: 59.38,
    ttivmt: 127898.0,
    trkttivmt: 20782.0,
  },
  {
    agglevel: "county",
    regioncode: 120,
    year: 2021,
    count: 467,
    len_sec: 306.9029999999999,
    lanemiles: 654.2879999999999,
    delay: 49856.0,
    trkdelay: 14652.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.04,
    ghg: 249.0,
    congcost: 1485123.0,
    allspeed: 59.44,
    trkspeed: 59.61,
    ttivmt: 113897.0,
    trkttivmt: 19846.0,
  },
  {
    agglevel: "county",
    regioncode: 120,
    year: 2022,
    count: 484,
    len_sec: 306.9209999999998,
    lanemiles: 653.9270000000002,
    delay: 104893.0,
    trkdelay: 24839.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.15,
    trkpti95: 1.19,
    tti: 1.03,
    trktti: 1.05,
    ghg: 464.0,
    congcost: 3273740.0,
    allspeed: 58.99,
    trkspeed: 58.87,
    ttivmt: 128003.0,
    trkttivmt: 23240.0,
  },
  {
    agglevel: "county",
    regioncode: 121,
    year: 2017,
    count: 475,
    len_sec: 309.56599999999986,
    lanemiles: 668.9250000000002,
    delay: 260677.0,
    trkdelay: 33698.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1261.0,
    congcost: 5883284.0,
    allspeed: 59.15,
    trkspeed: 62.2,
    ttivmt: 391999.0,
    trkttivmt: 70761.0,
  },
  {
    agglevel: "county",
    regioncode: 121,
    year: 2018,
    count: 456,
    len_sec: 309.99999999999994,
    lanemiles: 669.8399999999995,
    delay: 217371.0,
    trkdelay: 30405.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1074.0,
    congcost: 5091493.0,
    allspeed: 58.74,
    trkspeed: 61.26,
    ttivmt: 367616.0,
    trkttivmt: 67012.0,
  },
  {
    agglevel: "county",
    regioncode: 121,
    year: 2019,
    count: 155,
    len_sec: 199.08099999999993,
    lanemiles: 447.9799999999999,
    delay: 200167.0,
    trkdelay: 47755.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.15,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1210.0,
    congcost: 5567561.0,
    allspeed: 61.24,
    trkspeed: 61.19,
    ttivmt: 317901.0,
    trkttivmt: 66142.0,
  },
  {
    agglevel: "county",
    regioncode: 121,
    year: 2020,
    count: 703,
    len_sec: 310.2120000000003,
    lanemiles: 670.2420000000003,
    delay: 233105.0,
    trkdelay: 28800.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1018.0,
    congcost: 5716546.0,
    allspeed: 60.2,
    trkspeed: 62.16,
    ttivmt: 381851.0,
    trkttivmt: 63177.0,
  },
  {
    agglevel: "county",
    regioncode: 121,
    year: 2021,
    count: 708,
    len_sec: 310.2790000000001,
    lanemiles: 670.2960000000005,
    delay: 146017.0,
    trkdelay: 24826.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 727.0,
    congcost: 3820230.0,
    allspeed: 60.58,
    trkspeed: 62.16,
    ttivmt: 373697.0,
    trkttivmt: 61354.0,
  },
  {
    agglevel: "county",
    regioncode: 121,
    year: 2022,
    count: 721,
    len_sec: 310.3180000000003,
    lanemiles: 670.3740000000003,
    delay: 263525.0,
    trkdelay: 34030.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1160.0,
    congcost: 7268126.0,
    allspeed: 60.93,
    trkspeed: 62.6,
    ttivmt: 466277.0,
    trkttivmt: 84102.0,
  },
  {
    agglevel: "county",
    regioncode: 122,
    year: 2017,
    count: 475,
    len_sec: 346.6749999999998,
    lanemiles: 825.0179999999995,
    delay: 604703.0,
    trkdelay: 55317.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 2690.0,
    congcost: 1.2928933e7,
    allspeed: 55.3,
    trkspeed: 59.54,
    ttivmt: 451562.0,
    trkttivmt: 74251.0,
  },
  {
    agglevel: "county",
    regioncode: 122,
    year: 2018,
    count: 625,
    len_sec: 342.56099999999975,
    lanemiles: 816.7649999999994,
    delay: 572079.0,
    trkdelay: 65466.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.15,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.05,
    ghg: 2822.0,
    congcost: 1.2953444e7,
    allspeed: 54.49,
    trkspeed: 55.7,
    ttivmt: 417305.0,
    trkttivmt: 51285.0,
  },
  {
    agglevel: "county",
    regioncode: 122,
    year: 2019,
    count: 159,
    len_sec: 218.64199999999997,
    lanemiles: 564.683,
    delay: 479523.0,
    trkdelay: 69151.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.05,
    ghg: 2688.0,
    congcost: 1.1840071e7,
    allspeed: 57.47,
    trkspeed: 56.47,
    ttivmt: 401418.0,
    trkttivmt: 52593.0,
  },
  {
    agglevel: "county",
    regioncode: 122,
    year: 2020,
    count: 872,
    len_sec: 342.8059999999997,
    lanemiles: 816.2120000000003,
    delay: 489878.0,
    trkdelay: 72634.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.06,
    ghg: 2800.0,
    congcost: 1.2574659e7,
    allspeed: 56.85,
    trkspeed: 57.51,
    ttivmt: 416080.0,
    trkttivmt: 51725.0,
  },
  {
    agglevel: "county",
    regioncode: 122,
    year: 2021,
    count: 862,
    len_sec: 342.80900000000014,
    lanemiles: 816.2499999999995,
    delay: 397442.0,
    trkdelay: 62652.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.05,
    ghg: 2436.0,
    congcost: 1.0333715e7,
    allspeed: 57.05,
    trkspeed: 57.65,
    ttivmt: 397954.0,
    trkttivmt: 49646.0,
  },
  {
    agglevel: "county",
    regioncode: 122,
    year: 2022,
    count: 877,
    len_sec: 343.1349999999997,
    lanemiles: 820.0589999999991,
    delay: 483589.0,
    trkdelay: 53630.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.17,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.03,
    ghg: 2276.0,
    congcost: 1.3076915e7,
    allspeed: 56.97,
    trkspeed: 58.36,
    ttivmt: 476648.0,
    trkttivmt: 67743.0,
  },
  {
    agglevel: "county",
    regioncode: 123,
    year: 2017,
    count: 104,
    len_sec: 207.15899999999993,
    lanemiles: 429.22199999999987,
    delay: 47323.0,
    trkdelay: 11903.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 252.0,
    congcost: 1245218.0,
    allspeed: 60.88,
    trkspeed: 62.29,
    ttivmt: 85413.0,
    trkttivmt: 36955.0,
  },
  {
    agglevel: "county",
    regioncode: 123,
    year: 2018,
    count: 308,
    len_sec: 206.32600000000008,
    lanemiles: 427.56600000000014,
    delay: 58089.0,
    trkdelay: 6277.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.09,
    tti: 1.03,
    trktti: 1.01,
    ghg: 195.0,
    congcost: 1285893.0,
    allspeed: 55.68,
    trkspeed: 61.23,
    ttivmt: 78584.0,
    trkttivmt: 23502.0,
  },
  {
    agglevel: "county",
    regioncode: 123,
    year: 2019,
    count: 39,
    len_sec: 201.15199999999993,
    lanemiles: 417.21599999999995,
    delay: 38405.0,
    trkdelay: 4944.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.09,
    tti: 1.02,
    trktti: 1.01,
    ghg: 145.0,
    congcost: 911877.0,
    allspeed: 61.73,
    trkspeed: 60.9,
    ttivmt: 83760.0,
    trkttivmt: 21617.0,
  },
  {
    agglevel: "county",
    regioncode: 123,
    year: 2020,
    count: 397,
    len_sec: 226.76099999999994,
    lanemiles: 468.434,
    delay: 40463.0,
    trkdelay: 2115.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.0,
    ghg: 100.0,
    congcost: 875279.0,
    allspeed: 61.44,
    trkspeed: 64.14,
    ttivmt: 89833.0,
    trkttivmt: 23024.0,
  },
  {
    agglevel: "county",
    regioncode: 123,
    year: 2021,
    count: 398,
    len_sec: 226.76099999999997,
    lanemiles: 468.4339999999999,
    delay: 25918.0,
    trkdelay: 1666.0,
    pti80: 1.08,
    trkpti80: 1.05,
    pti95: 1.12,
    trkpti95: 1.09,
    tti: 1.02,
    trktti: 1.0,
    ghg: 70.0,
    congcost: 588686.0,
    allspeed: 61.56,
    trkspeed: 64.25,
    ttivmt: 70930.0,
    trkttivmt: 22719.0,
  },
  {
    agglevel: "county",
    regioncode: 123,
    year: 2022,
    count: 405,
    len_sec: 227.0389999999999,
    lanemiles: 472.44699999999995,
    delay: 36689.0,
    trkdelay: 5455.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.01,
    ghg: 113.0,
    congcost: 1029793.0,
    allspeed: 61.17,
    trkspeed: 63.71,
    ttivmt: 84019.0,
    trkttivmt: 25747.0,
  },
  {
    agglevel: "county",
    regioncode: 124,
    year: 2017,
    count: 1920,
    len_sec: 696.3349999999995,
    lanemiles: 1944.7479999999985,
    delay: 8169680.0,
    trkdelay: 306204.0,
    pti80: 1.18,
    trkpti80: 1.13,
    pti95: 1.23,
    trkpti95: 1.18,
    tti: 1.08,
    trktti: 1.04,
    ghg: 33384.0,
    congcost: 1.61166551e8,
    allspeed: 45.91,
    trkspeed: 57.03,
    ttivmt: 3482965.0,
    trkttivmt: 371778.0,
  },
  {
    agglevel: "county",
    regioncode: 124,
    year: 2018,
    count: 1779,
    len_sec: 693.4649999999995,
    lanemiles: 1937.3599999999938,
    delay: 6427250.0,
    trkdelay: 362292.0,
    pti80: 1.18,
    trkpti80: 1.16,
    pti95: 1.22,
    trkpti95: 1.23,
    tti: 1.08,
    trktti: 1.07,
    ghg: 27220.0,
    congcost: 1.33378046e8,
    allspeed: 49.55,
    trkspeed: 55.12,
    ttivmt: 2629223.0,
    trkttivmt: 226621.0,
  },
  {
    agglevel: "county",
    regioncode: 124,
    year: 2019,
    count: 779,
    len_sec: 471.851,
    lanemiles: 1452.7389999999998,
    delay: 5642701.0,
    trkdelay: 324009.0,
    pti80: 1.16,
    trkpti80: 1.16,
    pti95: 1.2,
    trkpti95: 1.22,
    tti: 1.08,
    trktti: 1.07,
    ghg: 24211.0,
    congcost: 1.21698917e8,
    allspeed: 50.52,
    trkspeed: 54.57,
    ttivmt: 2509026.0,
    trkttivmt: 204390.0,
  },
  {
    agglevel: "county",
    regioncode: 124,
    year: 2020,
    count: 2885,
    len_sec: 692.0569999999949,
    lanemiles: 1943.9119999999866,
    delay: 6346638.0,
    trkdelay: 316861.0,
    pti80: 1.17,
    trkpti80: 1.14,
    pti95: 1.21,
    trkpti95: 1.18,
    tti: 1.08,
    trktti: 1.06,
    ghg: 27121.0,
    congcost: 1.39061889e8,
    allspeed: 49.26,
    trkspeed: 56.67,
    ttivmt: 2707577.0,
    trkttivmt: 217463.0,
  },
  {
    agglevel: "county",
    regioncode: 124,
    year: 2021,
    count: 2866,
    len_sec: 689.8369999999945,
    lanemiles: 1941.8339999999876,
    delay: 3851334.0,
    trkdelay: 265691.0,
    pti80: 1.13,
    trkpti80: 1.13,
    pti95: 1.17,
    trkpti95: 1.19,
    tti: 1.06,
    trktti: 1.05,
    ghg: 16873.0,
    congcost: 8.8878184e7,
    allspeed: 49.77,
    trkspeed: 57.49,
    ttivmt: 2384441.0,
    trkttivmt: 213515.0,
  },
  {
    agglevel: "county",
    regioncode: 124,
    year: 2022,
    count: 2985,
    len_sec: 695.4789999999948,
    lanemiles: 1935.690999999987,
    delay: 4743724.0,
    trkdelay: 415453.0,
    pti80: 1.13,
    trkpti80: 1.14,
    pti95: 1.19,
    trkpti95: 1.21,
    tti: 1.05,
    trktti: 1.06,
    ghg: 20916.0,
    congcost: 1.24258937e8,
    allspeed: 50.58,
    trkspeed: 56.27,
    ttivmt: 2752205.0,
    trkttivmt: 277133.0,
  },
  {
    agglevel: "county",
    regioncode: 125,
    year: 2017,
    count: 92,
    len_sec: 144.28100000000006,
    lanemiles: 291.27600000000024,
    delay: 67286.0,
    trkdelay: 4060.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.09,
    tti: 1.03,
    trktti: 1.01,
    ghg: 230.0,
    congcost: 1363219.0,
    allspeed: 55.21,
    trkspeed: 60.83,
    ttivmt: 65734.0,
    trkttivmt: 12220.0,
  },
  {
    agglevel: "county",
    regioncode: 125,
    year: 2018,
    count: 138,
    len_sec: 144.52300000000005,
    lanemiles: 291.71500000000015,
    delay: 89301.0,
    trkdelay: 9135.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.15,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.06,
    ghg: 420.0,
    congcost: 1984297.0,
    allspeed: 50.02,
    trkspeed: 52.94,
    ttivmt: 67711.0,
    trkttivmt: 6661.0,
  },
  {
    agglevel: "county",
    regioncode: 125,
    year: 2019,
    count: 34,
    len_sec: 59.507,
    lanemiles: 121.683,
    delay: 33531.0,
    trkdelay: 8266.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.05,
    ghg: 240.0,
    congcost: 950135.0,
    allspeed: 54.78,
    trkspeed: 54.24,
    ttivmt: 37226.0,
    trkttivmt: 5970.0,
  },
  {
    agglevel: "county",
    regioncode: 125,
    year: 2020,
    count: 179,
    len_sec: 144.27900000000005,
    lanemiles: 291.2290000000001,
    delay: 69578.0,
    trkdelay: 9687.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.05,
    ghg: 368.0,
    congcost: 1756722.0,
    allspeed: 53.59,
    trkspeed: 55.69,
    ttivmt: 65470.0,
    trkttivmt: 8479.0,
  },
  {
    agglevel: "county",
    regioncode: 125,
    year: 2021,
    count: 178,
    len_sec: 144.27900000000005,
    lanemiles: 291.2290000000001,
    delay: 50039.0,
    trkdelay: 7723.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.2,
    tti: 1.03,
    trktti: 1.04,
    ghg: 288.0,
    congcost: 1292740.0,
    allspeed: 52.71,
    trkspeed: 53.1,
    ttivmt: 49039.0,
    trkttivmt: 6259.0,
  },
  {
    agglevel: "county",
    regioncode: 125,
    year: 2022,
    count: 178,
    len_sec: 144.26099999999988,
    lanemiles: 291.1929999999998,
    delay: 58375.0,
    trkdelay: 7275.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.19,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.03,
    ghg: 224.0,
    congcost: 1593396.0,
    allspeed: 55.45,
    trkspeed: 59.62,
    ttivmt: 57067.0,
    trkttivmt: 10633.0,
  },
  {
    agglevel: "county",
    regioncode: 126,
    year: 2017,
    count: 503,
    len_sec: 344.5730000000002,
    lanemiles: 867.7899999999994,
    delay: 864029.0,
    trkdelay: 55749.0,
    pti80: 1.12,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.02,
    ghg: 3939.0,
    congcost: 1.7809967e7,
    allspeed: 52.8,
    trkspeed: 60.64,
    ttivmt: 556117.0,
    trkttivmt: 91256.0,
  },
  {
    agglevel: "county",
    regioncode: 126,
    year: 2018,
    count: 545,
    len_sec: 343.2650000000005,
    lanemiles: 863.2750000000008,
    delay: 670050.0,
    trkdelay: 51509.0,
    pti80: 1.12,
    trkpti80: 1.07,
    pti95: 1.15,
    trkpti95: 1.09,
    tti: 1.04,
    trktti: 1.02,
    ghg: 3272.0,
    congcost: 1.4409707e7,
    allspeed: 55.22,
    trkspeed: 60.39,
    ttivmt: 500372.0,
    trkttivmt: 85336.0,
  },
  {
    agglevel: "county",
    regioncode: 126,
    year: 2019,
    count: 166,
    len_sec: 172.96300000000002,
    lanemiles: 515.6120000000003,
    delay: 566700.0,
    trkdelay: 55758.0,
    pti80: 1.12,
    trkpti80: 1.08,
    pti95: 1.15,
    trkpti95: 1.1,
    tti: 1.04,
    trktti: 1.03,
    ghg: 2971.0,
    congcost: 1.3108132e7,
    allspeed: 57.8,
    trkspeed: 60.17,
    ttivmt: 450333.0,
    trkttivmt: 88098.0,
  },
  {
    agglevel: "county",
    regioncode: 126,
    year: 2020,
    count: 708,
    len_sec: 343.4339999999997,
    lanemiles: 863.584999999998,
    delay: 640265.0,
    trkdelay: 55313.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.02,
    ghg: 3140.0,
    congcost: 1.4938237e7,
    allspeed: 57.29,
    trkspeed: 61.58,
    ttivmt: 524098.0,
    trkttivmt: 90512.0,
  },
  {
    agglevel: "county",
    regioncode: 126,
    year: 2021,
    count: 702,
    len_sec: 343.5129999999997,
    lanemiles: 864.1069999999986,
    delay: 460812.0,
    trkdelay: 42412.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.02,
    ghg: 2276.0,
    congcost: 1.0997843e7,
    allspeed: 57.12,
    trkspeed: 61.73,
    ttivmt: 460479.0,
    trkttivmt: 81152.0,
  },
  {
    agglevel: "county",
    regioncode: 126,
    year: 2022,
    count: 704,
    len_sec: 343.5119999999999,
    lanemiles: 864.1049999999988,
    delay: 549857.0,
    trkdelay: 40588.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.17,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.02,
    ghg: 2313.0,
    congcost: 1.4124919e7,
    allspeed: 57.19,
    trkspeed: 61.87,
    ttivmt: 521009.0,
    trkttivmt: 98498.0,
  },
  {
    agglevel: "county",
    regioncode: 127,
    year: 2017,
    count: 1148,
    len_sec: 581.9469999999995,
    lanemiles: 1349.4519999999984,
    delay: 3239766.0,
    trkdelay: 170161.0,
    pti80: 1.15,
    trkpti80: 1.1,
    pti95: 1.18,
    trkpti95: 1.13,
    tti: 1.07,
    trktti: 1.04,
    ghg: 14556.0,
    congcost: 6.5570779e7,
    allspeed: 48.97,
    trkspeed: 57.21,
    ttivmt: 1587262.0,
    trkttivmt: 188243.0,
  },
  {
    agglevel: "county",
    regioncode: 127,
    year: 2018,
    count: 1051,
    len_sec: 606.447999999999,
    lanemiles: 1418.9059999999986,
    delay: 3291218.0,
    trkdelay: 334743.0,
    pti80: 1.17,
    trkpti80: 1.19,
    pti95: 1.21,
    trkpti95: 1.24,
    tti: 1.08,
    trktti: 1.09,
    ghg: 18120.0,
    congcost: 7.3613608e7,
    allspeed: 50.84,
    trkspeed: 53.25,
    ttivmt: 1495419.0,
    trkttivmt: 174511.0,
  },
  {
    agglevel: "county",
    regioncode: 127,
    year: 2019,
    count: 594,
    len_sec: 598.9959999999998,
    lanemiles: 1390.9420000000005,
    delay: 3056757.0,
    trkdelay: 337436.0,
    pti80: 1.16,
    trkpti80: 1.17,
    pti95: 1.19,
    trkpti95: 1.22,
    tti: 1.08,
    trktti: 1.08,
    ghg: 16815.0,
    congcost: 7.2019996e7,
    allspeed: 51.9,
    trkspeed: 53.23,
    ttivmt: 1512166.0,
    trkttivmt: 178116.0,
  },
  {
    agglevel: "county",
    regioncode: 127,
    year: 2020,
    count: 1746,
    len_sec: 608.1739999999993,
    lanemiles: 1404.1109999999996,
    delay: 3523396.0,
    trkdelay: 217581.0,
    pti80: 1.15,
    trkpti80: 1.15,
    pti95: 1.19,
    trkpti95: 1.19,
    tti: 1.07,
    trktti: 1.06,
    ghg: 15938.0,
    congcost: 7.8836788e7,
    allspeed: 51.47,
    trkspeed: 56.91,
    ttivmt: 1671207.0,
    trkttivmt: 143599.0,
  },
  {
    agglevel: "county",
    regioncode: 127,
    year: 2021,
    count: 1792,
    len_sec: 608.8269999999976,
    lanemiles: 1406.5929999999955,
    delay: 2328704.0,
    trkdelay: 183775.0,
    pti80: 1.14,
    trkpti80: 1.12,
    pti95: 1.18,
    trkpti95: 1.17,
    tti: 1.06,
    trktti: 1.05,
    ghg: 11035.0,
    congcost: 5.4578197e7,
    allspeed: 52.02,
    trkspeed: 58.26,
    ttivmt: 1555945.0,
    trkttivmt: 159609.0,
  },
  {
    agglevel: "county",
    regioncode: 127,
    year: 2022,
    count: 1880,
    len_sec: 610.2489999999982,
    lanemiles: 1408.1469999999943,
    delay: 3255167.0,
    trkdelay: 203688.0,
    pti80: 1.16,
    trkpti80: 1.12,
    pti95: 1.21,
    trkpti95: 1.18,
    tti: 1.07,
    trktti: 1.05,
    ghg: 14342.0,
    congcost: 8.2551249e7,
    allspeed: 51.31,
    trkspeed: 57.54,
    ttivmt: 1734085.0,
    trkttivmt: 180659.0,
  },
  {
    agglevel: "county",
    regioncode: 128,
    year: 2017,
    count: 371,
    len_sec: 480.53899999999896,
    lanemiles: 1068.9679999999994,
    delay: 166030.0,
    trkdelay: 25878.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.02,
    ghg: 688.0,
    congcost: 3857792.0,
    allspeed: 58.48,
    trkspeed: 61.55,
    ttivmt: 204832.0,
    trkttivmt: 53509.0,
  },
  {
    agglevel: "county",
    regioncode: 128,
    year: 2018,
    count: 513,
    len_sec: 480.2940000000003,
    lanemiles: 1066.2300000000007,
    delay: 104536.0,
    trkdelay: 16238.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.03,
    ghg: 538.0,
    congcost: 2503059.0,
    allspeed: 54.13,
    trkspeed: 54.27,
    ttivmt: 182623.0,
    trkttivmt: 22632.0,
  },
  {
    agglevel: "county",
    regioncode: 128,
    year: 2019,
    count: 267,
    len_sec: 453.2580000000001,
    lanemiles: 1012.1600000000005,
    delay: 133196.0,
    trkdelay: 33267.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.05,
    ghg: 812.0,
    congcost: 3762733.0,
    allspeed: 59.87,
    trkspeed: 57.94,
    ttivmt: 186095.0,
    trkttivmt: 28256.0,
  },
  {
    agglevel: "county",
    regioncode: 128,
    year: 2020,
    count: 669,
    len_sec: 479.9499999999997,
    lanemiles: 1065.5439999999983,
    delay: 153766.0,
    trkdelay: 24320.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.03,
    ghg: 702.0,
    congcost: 3963316.0,
    allspeed: 59.28,
    trkspeed: 59.56,
    ttivmt: 196007.0,
    trkttivmt: 30082.0,
  },
  {
    agglevel: "county",
    regioncode: 128,
    year: 2021,
    count: 660,
    len_sec: 480.1599999999997,
    lanemiles: 1065.9639999999984,
    delay: 95842.0,
    trkdelay: 18259.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 420.0,
    congcost: 2555516.0,
    allspeed: 58.86,
    trkspeed: 59.86,
    ttivmt: 183457.0,
    trkttivmt: 34002.0,
  },
  {
    agglevel: "county",
    regioncode: 128,
    year: 2022,
    count: 672,
    len_sec: 480.03,
    lanemiles: 1073.0960000000002,
    delay: 163138.0,
    trkdelay: 38735.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.04,
    ghg: 813.0,
    congcost: 5120052.0,
    allspeed: 58.37,
    trkspeed: 58.99,
    ttivmt: 203270.0,
    trkttivmt: 37145.0,
  },
  {
    agglevel: "county",
    regioncode: 129,
    year: 2017,
    count: 412,
    len_sec: 361.98499999999973,
    lanemiles: 745.4419999999993,
    delay: 354115.0,
    trkdelay: 65340.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.09,
    tti: 1.03,
    trktti: 1.02,
    ghg: 1929.0,
    congcost: 8607798.0,
    allspeed: 54.52,
    trkspeed: 57.37,
    ttivmt: 343142.0,
    trkttivmt: 86522.0,
  },
  {
    agglevel: "county",
    regioncode: 129,
    year: 2018,
    count: 516,
    len_sec: 361.16200000000003,
    lanemiles: 742.1139999999998,
    delay: 350374.0,
    trkdelay: 78891.0,
    pti80: 1.1,
    trkpti80: 1.16,
    pti95: 1.12,
    trkpti95: 1.21,
    tti: 1.04,
    trktti: 1.07,
    ghg: 2181.0,
    congcost: 9207283.0,
    allspeed: 53.65,
    trkspeed: 54.1,
    ttivmt: 320199.0,
    trkttivmt: 52122.0,
  },
  {
    agglevel: "county",
    regioncode: 129,
    year: 2019,
    count: 166,
    len_sec: 220.342,
    lanemiles: 460.4739999999998,
    delay: 234179.0,
    trkdelay: 72728.0,
    pti80: 1.08,
    trkpti80: 1.14,
    pti95: 1.09,
    trkpti95: 1.19,
    tti: 1.03,
    trktti: 1.06,
    ghg: 1689.0,
    congcost: 7138989.0,
    allspeed: 56.25,
    trkspeed: 54.85,
    ttivmt: 287531.0,
    trkttivmt: 54620.0,
  },
  {
    agglevel: "county",
    regioncode: 129,
    year: 2020,
    count: 697,
    len_sec: 361.22899999999976,
    lanemiles: 758.4219999999997,
    delay: 368905.0,
    trkdelay: 72503.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.11,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.05,
    ghg: 2157.0,
    congcost: 1.0107748e7,
    allspeed: 54.97,
    trkspeed: 55.4,
    ttivmt: 368231.0,
    trkttivmt: 57353.0,
  },
  {
    agglevel: "county",
    regioncode: 129,
    year: 2021,
    count: 692,
    len_sec: 361.26299999999947,
    lanemiles: 758.4919999999995,
    delay: 358100.0,
    trkdelay: 74648.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.05,
    ghg: 2174.0,
    congcost: 9836487.0,
    allspeed: 54.39,
    trkspeed: 55.92,
    ttivmt: 353553.0,
    trkttivmt: 69081.0,
  },
  {
    agglevel: "county",
    regioncode: 129,
    year: 2022,
    count: 692,
    len_sec: 361.26399999999967,
    lanemiles: 758.493999999999,
    delay: 271088.0,
    trkdelay: 62345.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1655.0,
    congcost: 8510050.0,
    allspeed: 55.09,
    trkspeed: 56.96,
    ttivmt: 280785.0,
    trkttivmt: 58900.0,
  },
  {
    agglevel: "county",
    regioncode: 130,
    year: 2017,
    count: 1002,
    len_sec: 581.3949999999996,
    lanemiles: 1381.8649999999996,
    delay: 2583263.0,
    trkdelay: 176412.0,
    pti80: 1.15,
    trkpti80: 1.12,
    pti95: 1.2,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.03,
    ghg: 10256.0,
    congcost: 5.3216624e7,
    allspeed: 55.01,
    trkspeed: 59.66,
    ttivmt: 2052407.0,
    trkttivmt: 241098.0,
  },
  {
    agglevel: "county",
    regioncode: 130,
    year: 2018,
    count: 1135,
    len_sec: 585.2979999999993,
    lanemiles: 1393.9749999999997,
    delay: 2101965.0,
    trkdelay: 158929.0,
    pti80: 1.15,
    trkpti80: 1.11,
    pti95: 1.21,
    trkpti95: 1.14,
    tti: 1.05,
    trktti: 1.03,
    ghg: 8023.0,
    congcost: 4.463999e7,
    allspeed: 56.1,
    trkspeed: 60.0,
    ttivmt: 1722665.0,
    trkttivmt: 200761.0,
  },
  {
    agglevel: "county",
    regioncode: 130,
    year: 2019,
    count: 602,
    len_sec: 578.7290000000005,
    lanemiles: 1381.2089999999992,
    delay: 2308225.0,
    trkdelay: 180283.0,
    pti80: 1.15,
    trkpti80: 1.11,
    pti95: 1.21,
    trkpti95: 1.15,
    tti: 1.06,
    trktti: 1.04,
    ghg: 9524.0,
    congcost: 5.1200169e7,
    allspeed: 56.79,
    trkspeed: 58.28,
    ttivmt: 1748000.0,
    trkttivmt: 185138.0,
  },
  {
    agglevel: "county",
    regioncode: 130,
    year: 2020,
    count: 1658,
    len_sec: 584.0599999999989,
    lanemiles: 1390.8649999999943,
    delay: 2471047.0,
    trkdelay: 163602.0,
    pti80: 1.14,
    trkpti80: 1.11,
    pti95: 1.2,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.04,
    ghg: 9848.0,
    congcost: 5.536559e7,
    allspeed: 56.64,
    trkspeed: 60.92,
    ttivmt: 1816287.0,
    trkttivmt: 205180.0,
  },
  {
    agglevel: "county",
    regioncode: 130,
    year: 2021,
    count: 1752,
    len_sec: 584.0889999999982,
    lanemiles: 1396.3379999999913,
    delay: 1817769.0,
    trkdelay: 129827.0,
    pti80: 1.13,
    trkpti80: 1.11,
    pti95: 1.19,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.04,
    ghg: 7591.0,
    congcost: 4.2003655e7,
    allspeed: 56.88,
    trkspeed: 60.92,
    ttivmt: 1750916.0,
    trkttivmt: 185191.0,
  },
  {
    agglevel: "county",
    regioncode: 130,
    year: 2022,
    count: 1867,
    len_sec: 590.908999999998,
    lanemiles: 1414.055999999995,
    delay: 2547614.0,
    trkdelay: 161913.0,
    pti80: 1.16,
    trkpti80: 1.12,
    pti95: 1.23,
    trkpti95: 1.17,
    tti: 1.06,
    trktti: 1.04,
    ghg: 9206.0,
    congcost: 6.4108771e7,
    allspeed: 55.97,
    trkspeed: 60.63,
    ttivmt: 2024877.0,
    trkttivmt: 218013.0,
  },
  {
    agglevel: "county",
    regioncode: 131,
    year: 2017,
    count: 382,
    len_sec: 205.47599999999983,
    lanemiles: 474.827,
    delay: 671724.0,
    trkdelay: 35470.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 2720.0,
    congcost: 1.3548569e7,
    allspeed: 55.53,
    trkspeed: 59.09,
    ttivmt: 530292.0,
    trkttivmt: 50108.0,
  },
  {
    agglevel: "county",
    regioncode: 131,
    year: 2018,
    count: 349,
    len_sec: 203.0919999999998,
    lanemiles: 469.1029999999996,
    delay: 736651.0,
    trkdelay: 36579.0,
    pti80: 1.14,
    trkpti80: 1.1,
    pti95: 1.18,
    trkpti95: 1.13,
    tti: 1.05,
    trktti: 1.03,
    ghg: 3164.0,
    congcost: 1.5144404e7,
    allspeed: 55.87,
    trkspeed: 59.95,
    ttivmt: 497911.0,
    trkttivmt: 51763.0,
  },
  {
    agglevel: "county",
    regioncode: 131,
    year: 2019,
    count: 178,
    len_sec: 174.21199999999993,
    lanemiles: 411.23899999999975,
    delay: 670503.0,
    trkdelay: 42514.0,
    pti80: 1.13,
    trkpti80: 1.09,
    pti95: 1.17,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 2948.0,
    congcost: 1.4606346e7,
    allspeed: 56.79,
    trkspeed: 59.48,
    ttivmt: 498009.0,
    trkttivmt: 52931.0,
  },
  {
    agglevel: "county",
    regioncode: 131,
    year: 2020,
    count: 591,
    len_sec: 204.5240000000001,
    lanemiles: 472.66600000000005,
    delay: 931080.0,
    trkdelay: 48117.0,
    pti80: 1.12,
    trkpti80: 1.08,
    pti95: 1.15,
    trkpti95: 1.12,
    tti: 1.05,
    trktti: 1.03,
    ghg: 4097.0,
    congcost: 2.0486804e7,
    allspeed: 55.81,
    trkspeed: 60.01,
    ttivmt: 547552.0,
    trkttivmt: 57639.0,
  },
  {
    agglevel: "county",
    regioncode: 131,
    year: 2021,
    count: 554,
    len_sec: 203.16899999999998,
    lanemiles: 471.87999999999954,
    delay: 672993.0,
    trkdelay: 46564.0,
    pti80: 1.12,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.03,
    ghg: 2983.0,
    congcost: 1.5543099e7,
    allspeed: 55.76,
    trkspeed: 60.04,
    ttivmt: 470644.0,
    trkttivmt: 51154.0,
  },
  {
    agglevel: "county",
    regioncode: 131,
    year: 2022,
    count: 604,
    len_sec: 204.93300000000008,
    lanemiles: 475.6580000000002,
    delay: 781480.0,
    trkdelay: 92575.0,
    pti80: 1.14,
    trkpti80: 1.13,
    pti95: 1.19,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.05,
    ghg: 3373.0,
    congcost: 2.1255245e7,
    allspeed: 56.69,
    trkspeed: 59.12,
    ttivmt: 572349.0,
    trkttivmt: 67525.0,
  },
  {
    agglevel: "county",
    regioncode: 132,
    year: 2017,
    count: 105,
    len_sec: 165.816,
    lanemiles: 331.632,
    delay: 8492.0,
    trkdelay: 2935.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 24.0,
    congcost: 243931.0,
    allspeed: 62.57,
    trkspeed: 63.31,
    ttivmt: 16207.0,
    trkttivmt: 7782.0,
  },
  {
    agglevel: "county",
    regioncode: 132,
    year: 2018,
    count: 102,
    len_sec: 164.52800000000002,
    lanemiles: 329.05600000000004,
    delay: 7482.0,
    trkdelay: 1857.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.1,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 15.0,
    congcost: 196653.0,
    allspeed: 59.29,
    trkspeed: 57.5,
    ttivmt: 17295.0,
    trkttivmt: 3417.0,
  },
  {
    agglevel: "county",
    regioncode: 132,
    year: 2019,
    count: 54,
    len_sec: 164.64800000000002,
    lanemiles: 329.29600000000005,
    delay: 9937.0,
    trkdelay: 3040.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.17,
    tti: 1.02,
    trktti: 1.04,
    ghg: 23.0,
    congcost: 291878.0,
    allspeed: 62.6,
    trkspeed: 61.61,
    ttivmt: 19135.0,
    trkttivmt: 3521.0,
  },
  {
    agglevel: "county",
    regioncode: 132,
    year: 2020,
    count: 177,
    len_sec: 164.51400000000007,
    lanemiles: 329.02800000000013,
    delay: 13908.0,
    trkdelay: 3506.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.03,
    ghg: 22.0,
    congcost: 398178.0,
    allspeed: 62.17,
    trkspeed: 62.09,
    ttivmt: 19554.0,
    trkttivmt: 4169.0,
  },
  {
    agglevel: "county",
    regioncode: 132,
    year: 2021,
    count: 177,
    len_sec: 164.51400000000004,
    lanemiles: 329.0280000000001,
    delay: 8083.0,
    trkdelay: 2390.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 22.0,
    congcost: 239218.0,
    allspeed: 61.9,
    trkspeed: 62.41,
    ttivmt: 17145.0,
    trkttivmt: 4157.0,
  },
  {
    agglevel: "county",
    regioncode: 132,
    year: 2022,
    count: 179,
    len_sec: 164.4989999999999,
    lanemiles: 328.9979999999998,
    delay: 27792.0,
    trkdelay: 7347.0,
    pti80: 1.13,
    trkpti80: 1.16,
    pti95: 1.23,
    trkpti95: 1.29,
    tti: 1.05,
    trktti: 1.06,
    ghg: 106.0,
    congcost: 891021.0,
    allspeed: 60.83,
    trkspeed: 61.06,
    ttivmt: 18508.0,
    trkttivmt: 4063.0,
  },
  {
    agglevel: "county",
    regioncode: 133,
    year: 2017,
    count: 509,
    len_sec: 320.3329999999998,
    lanemiles: 765.8490000000003,
    delay: 998019.0,
    trkdelay: 60268.0,
    pti80: 1.14,
    trkpti80: 1.09,
    pti95: 1.17,
    trkpti95: 1.13,
    tti: 1.05,
    trktti: 1.01,
    ghg: 4463.0,
    congcost: 2.0434451e7,
    allspeed: 50.62,
    trkspeed: 61.42,
    ttivmt: 528234.0,
    trkttivmt: 140355.0,
  },
  {
    agglevel: "county",
    regioncode: 133,
    year: 2018,
    count: 576,
    len_sec: 325.66399999999965,
    lanemiles: 780.5289999999992,
    delay: 1001637.0,
    trkdelay: 58771.0,
    pti80: 1.13,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.13,
    tti: 1.06,
    trktti: 1.03,
    ghg: 4496.0,
    congcost: 2.0906406e7,
    allspeed: 51.04,
    trkspeed: 58.37,
    ttivmt: 519254.0,
    trkttivmt: 76522.0,
  },
  {
    agglevel: "county",
    regioncode: 133,
    year: 2019,
    count: 186,
    len_sec: 270.10900000000015,
    lanemiles: 667.3920000000002,
    delay: 961697.0,
    trkdelay: 61361.0,
    pti80: 1.14,
    trkpti80: 1.11,
    pti95: 1.17,
    trkpti95: 1.14,
    tti: 1.05,
    trktti: 1.03,
    ghg: 4412.0,
    congcost: 2.1008521e7,
    allspeed: 53.93,
    trkspeed: 59.47,
    ttivmt: 504242.0,
    trkttivmt: 76733.0,
  },
  {
    agglevel: "county",
    regioncode: 133,
    year: 2020,
    count: 780,
    len_sec: 325.60099999999943,
    lanemiles: 780.9479999999987,
    delay: 1173325.0,
    trkdelay: 49967.0,
    pti80: 1.13,
    trkpti80: 1.09,
    pti95: 1.17,
    trkpti95: 1.12,
    tti: 1.06,
    trktti: 1.02,
    ghg: 5077.0,
    congcost: 2.5425313e7,
    allspeed: 52.67,
    trkspeed: 61.0,
    ttivmt: 572993.0,
    trkttivmt: 85599.0,
  },
  {
    agglevel: "county",
    regioncode: 133,
    year: 2021,
    count: 782,
    len_sec: 325.7189999999997,
    lanemiles: 781.5299999999994,
    delay: 599403.0,
    trkdelay: 31968.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.02,
    ghg: 2460.0,
    congcost: 1.3531166e7,
    allspeed: 52.99,
    trkspeed: 60.24,
    ttivmt: 546774.0,
    trkttivmt: 63955.0,
  },
  {
    agglevel: "county",
    regioncode: 133,
    year: 2022,
    count: 819,
    len_sec: 327.4309999999998,
    lanemiles: 783.0229999999997,
    delay: 1025324.0,
    trkdelay: 94612.0,
    pti80: 1.13,
    trkpti80: 1.1,
    pti95: 1.18,
    trkpti95: 1.13,
    tti: 1.05,
    trktti: 1.04,
    ghg: 4079.0,
    congcost: 2.6898598e7,
    allspeed: 52.61,
    trkspeed: 59.8,
    ttivmt: 633759.0,
    trkttivmt: 101825.0,
  },
  {
    agglevel: "county",
    regioncode: 134,
    year: 2017,
    count: 211,
    len_sec: 321.4419999999999,
    lanemiles: 737.1409999999998,
    delay: 71030.0,
    trkdelay: 15445.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 346.0,
    congcost: 1790114.0,
    allspeed: 63.1,
    trkspeed: 64.22,
    ttivmt: 261463.0,
    trkttivmt: 128551.0,
  },
  {
    agglevel: "county",
    regioncode: 134,
    year: 2018,
    count: 317,
    len_sec: 323.65100000000007,
    lanemiles: 741.1450000000001,
    delay: 60820.0,
    trkdelay: 11700.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 249.0,
    congcost: 1512455.0,
    allspeed: 61.66,
    trkspeed: 62.93,
    ttivmt: 271188.0,
    trkttivmt: 74224.0,
  },
  {
    agglevel: "county",
    regioncode: 134,
    year: 2019,
    count: 85,
    len_sec: 257.43899999999996,
    lanemiles: 608.7209999999999,
    delay: 76521.0,
    trkdelay: 21429.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.01,
    ghg: 411.0,
    congcost: 2219400.0,
    allspeed: 63.42,
    trkspeed: 62.48,
    ttivmt: 296812.0,
    trkttivmt: 88911.0,
  },
  {
    agglevel: "county",
    regioncode: 134,
    year: 2020,
    count: 442,
    len_sec: 325.069,
    lanemiles: 743.9810000000001,
    delay: 85808.0,
    trkdelay: 17518.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 433.0,
    congcost: 2359469.0,
    allspeed: 63.23,
    trkspeed: 63.53,
    ttivmt: 313640.0,
    trkttivmt: 86690.0,
  },
  {
    agglevel: "county",
    regioncode: 134,
    year: 2021,
    count: 446,
    len_sec: 325.00599999999963,
    lanemiles: 742.9439999999994,
    delay: 44410.0,
    trkdelay: 11802.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 229.0,
    congcost: 1288927.0,
    allspeed: 63.09,
    trkspeed: 63.27,
    ttivmt: 264546.0,
    trkttivmt: 68762.0,
  },
  {
    agglevel: "county",
    regioncode: 134,
    year: 2022,
    count: 473,
    len_sec: 327.97299999999984,
    lanemiles: 746.249999999999,
    delay: 113471.0,
    trkdelay: 46073.0,
    pti80: 1.12,
    trkpti80: 1.09,
    pti95: 1.17,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 454.0,
    congcost: 4158878.0,
    allspeed: 62.92,
    trkspeed: 62.36,
    ttivmt: 284605.0,
    trkttivmt: 75326.0,
  },
  {
    agglevel: "county",
    regioncode: 135,
    year: 2017,
    count: 90,
    len_sec: 92.74200000000002,
    lanemiles: 202.90000000000006,
    delay: 5896.0,
    trkdelay: 3526.0,
    pti80: 1.06,
    trkpti80: 1.07,
    pti95: 1.08,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.01,
    ghg: 17.0,
    congcost: 213016.0,
    allspeed: 64.19,
    trkspeed: 63.86,
    ttivmt: 27451.0,
    trkttivmt: 16693.0,
  },
  {
    agglevel: "county",
    regioncode: 135,
    year: 2018,
    count: 119,
    len_sec: 94.52499999999996,
    lanemiles: 206.5559999999999,
    delay: 5156.0,
    trkdelay: 2354.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.02,
    ghg: 10.0,
    congcost: 167141.0,
    allspeed: 63.64,
    trkspeed: 63.01,
    ttivmt: 29563.0,
    trkttivmt: 5746.0,
  },
  {
    agglevel: "county",
    regioncode: 135,
    year: 2019,
    count: 27,
    len_sec: 92.46000000000001,
    lanemiles: 202.42600000000004,
    delay: 6601.0,
    trkdelay: 3255.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.02,
    ghg: 16.0,
    congcost: 232212.0,
    allspeed: 64.16,
    trkspeed: 63.26,
    ttivmt: 34274.0,
    trkttivmt: 8340.0,
  },
  {
    agglevel: "county",
    regioncode: 135,
    year: 2020,
    count: 180,
    len_sec: 94.49799999999996,
    lanemiles: 229.305,
    delay: 5505.0,
    trkdelay: 2199.0,
    pti80: 1.09,
    trkpti80: 1.06,
    pti95: 1.12,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.01,
    ghg: 11.0,
    congcost: 185312.0,
    allspeed: 64.35,
    trkspeed: 63.95,
    ttivmt: 33058.0,
    trkttivmt: 7667.0,
  },
  {
    agglevel: "county",
    regioncode: 135,
    year: 2021,
    count: 176,
    len_sec: 93.37400000000007,
    lanemiles: 228.18100000000018,
    delay: 4444.0,
    trkdelay: 2200.0,
    pti80: 1.08,
    trkpti80: 1.06,
    pti95: 1.11,
    trkpti95: 1.08,
    tti: 1.0,
    trktti: 1.01,
    ghg: 9.0,
    congcost: 155514.0,
    allspeed: 64.47,
    trkspeed: 63.95,
    ttivmt: 34684.0,
    trkttivmt: 7926.0,
  },
  {
    agglevel: "county",
    regioncode: 135,
    year: 2022,
    count: 177,
    len_sec: 93.38100000000003,
    lanemiles: 228.19499999999996,
    delay: 6303.0,
    trkdelay: 2811.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.02,
    ghg: 17.0,
    congcost: 238383.0,
    allspeed: 64.46,
    trkspeed: 63.89,
    ttivmt: 33871.0,
    trkttivmt: 7846.0,
  },
  {
    agglevel: "county",
    regioncode: 136,
    year: 2017,
    count: 152,
    len_sec: 184.98199999999997,
    lanemiles: 369.96399999999994,
    delay: 36205.0,
    trkdelay: 5010.0,
    pti80: 1.07,
    trkpti80: 1.05,
    pti95: 1.09,
    trkpti95: 1.06,
    tti: 1.01,
    trktti: 1.01,
    ghg: 135.0,
    congcost: 820879.0,
    allspeed: 61.9,
    trkspeed: 63.18,
    ttivmt: 84661.0,
    trkttivmt: 19073.0,
  },
  {
    agglevel: "county",
    regioncode: 136,
    year: 2018,
    count: 160,
    len_sec: 203.17400000000015,
    lanemiles: 407.1120000000003,
    delay: 18293.0,
    trkdelay: 3448.0,
    pti80: 1.07,
    trkpti80: 1.05,
    pti95: 1.08,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.01,
    ghg: 67.0,
    congcost: 450416.0,
    allspeed: 59.53,
    trkspeed: 59.41,
    ttivmt: 85738.0,
    trkttivmt: 11194.0,
  },
  {
    agglevel: "county",
    regioncode: 136,
    year: 2019,
    count: 47,
    len_sec: 155.619,
    lanemiles: 312.002,
    delay: 36816.0,
    trkdelay: 6877.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.03,
    ghg: 178.0,
    congcost: 952174.0,
    allspeed: 62.21,
    trkspeed: 61.5,
    ttivmt: 89368.0,
    trkttivmt: 11491.0,
  },
  {
    agglevel: "county",
    regioncode: 136,
    year: 2020,
    count: 244,
    len_sec: 203.41899999999998,
    lanemiles: 406.83799999999997,
    delay: 38057.0,
    trkdelay: 5682.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 143.0,
    congcost: 961499.0,
    allspeed: 61.92,
    trkspeed: 62.8,
    ttivmt: 89458.0,
    trkttivmt: 14967.0,
  },
  {
    agglevel: "county",
    regioncode: 136,
    year: 2021,
    count: 243,
    len_sec: 203.41899999999987,
    lanemiles: 406.83799999999974,
    delay: 30937.0,
    trkdelay: 5046.0,
    pti80: 1.08,
    trkpti80: 1.06,
    pti95: 1.12,
    trkpti95: 1.08,
    tti: 1.02,
    trktti: 1.01,
    ghg: 142.0,
    congcost: 801341.0,
    allspeed: 61.63,
    trkspeed: 62.82,
    ttivmt: 70637.0,
    trkttivmt: 15119.0,
  },
  {
    agglevel: "county",
    regioncode: 136,
    year: 2022,
    count: 252,
    len_sec: 203.42200000000003,
    lanemiles: 406.84400000000005,
    delay: 34429.0,
    trkdelay: 9511.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.02,
    ghg: 168.0,
    congcost: 1124114.0,
    allspeed: 61.83,
    trkspeed: 62.36,
    ttivmt: 93718.0,
    trkttivmt: 17864.0,
  },
  {
    agglevel: "county",
    regioncode: 137,
    year: 2017,
    count: 310,
    len_sec: 181.39700000000002,
    lanemiles: 439.99,
    delay: 691667.0,
    trkdelay: 54144.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.03,
    ghg: 3235.0,
    congcost: 1.4554083e7,
    allspeed: 51.72,
    trkspeed: 58.13,
    ttivmt: 364861.0,
    trkttivmt: 62129.0,
  },
  {
    agglevel: "county",
    regioncode: 137,
    year: 2018,
    count: 318,
    len_sec: 181.86100000000016,
    lanemiles: 441.47900000000016,
    delay: 564873.0,
    trkdelay: 51244.0,
    pti80: 1.12,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.05,
    ghg: 2895.0,
    congcost: 1.2411967e7,
    allspeed: 52.07,
    trkspeed: 56.24,
    ttivmt: 335982.0,
    trkttivmt: 39528.0,
  },
  {
    agglevel: "county",
    regioncode: 137,
    year: 2019,
    count: 127,
    len_sec: 108.67299999999997,
    lanemiles: 295.10299999999995,
    delay: 629762.0,
    trkdelay: 68305.0,
    pti80: 1.13,
    trkpti80: 1.13,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.05,
    ghg: 3476.0,
    congcost: 1.4807934e7,
    allspeed: 53.66,
    trkspeed: 55.26,
    ttivmt: 332777.0,
    trkttivmt: 41272.0,
  },
  {
    agglevel: "county",
    regioncode: 137,
    year: 2020,
    count: 430,
    len_sec: 184.43900000000008,
    lanemiles: 445.71900000000034,
    delay: 582402.0,
    trkdelay: 60947.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.03,
    ghg: 3306.0,
    congcost: 1.4053497e7,
    allspeed: 53.41,
    trkspeed: 56.9,
    ttivmt: 354276.0,
    trkttivmt: 53191.0,
  },
  {
    agglevel: "county",
    regioncode: 137,
    year: 2021,
    count: 422,
    len_sec: 184.54999999999998,
    lanemiles: 445.94100000000003,
    delay: 304539.0,
    trkdelay: 33244.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1680.0,
    congcost: 7450997.0,
    allspeed: 54.04,
    trkspeed: 57.73,
    ttivmt: 321301.0,
    trkttivmt: 49724.0,
  },
  {
    agglevel: "county",
    regioncode: 137,
    year: 2022,
    count: 425,
    len_sec: 184.57199999999995,
    lanemiles: 445.985,
    delay: 420819.0,
    trkdelay: 49305.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.04,
    ghg: 2090.0,
    congcost: 1.1496725e7,
    allspeed: 53.62,
    trkspeed: 58.15,
    ttivmt: 346224.0,
    trkttivmt: 52360.0,
  },
  {
    agglevel: "county",
    regioncode: 138,
    year: 2017,
    count: 280,
    len_sec: 219.6190000000001,
    lanemiles: 465.98600000000016,
    delay: 26582.0,
    trkdelay: 8010.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.01,
    ghg: 149.0,
    congcost: 740036.0,
    allspeed: 61.13,
    trkspeed: 62.73,
    ttivmt: 47695.0,
    trkttivmt: 23408.0,
  },
  {
    agglevel: "county",
    regioncode: 138,
    year: 2018,
    count: 247,
    len_sec: 219.306,
    lanemiles: 465.59799999999996,
    delay: 21742.0,
    trkdelay: 7990.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 159.0,
    congcost: 670719.0,
    allspeed: 59.78,
    trkspeed: 58.59,
    ttivmt: 47257.0,
    trkttivmt: 11293.0,
  },
  {
    agglevel: "county",
    regioncode: 138,
    year: 2019,
    count: 116,
    len_sec: 219.44600000000003,
    lanemiles: 465.8779999999999,
    delay: 29042.0,
    trkdelay: 11277.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 213.0,
    congcost: 958031.0,
    allspeed: 61.64,
    trkspeed: 60.91,
    ttivmt: 53098.0,
    trkttivmt: 13320.0,
  },
  {
    agglevel: "county",
    regioncode: 138,
    year: 2020,
    count: 392,
    len_sec: 219.44699999999975,
    lanemiles: 466.87799999999964,
    delay: 27448.0,
    trkdelay: 7368.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 145.0,
    congcost: 821415.0,
    allspeed: 61.54,
    trkspeed: 61.48,
    ttivmt: 53097.0,
    trkttivmt: 13219.0,
  },
  {
    agglevel: "county",
    regioncode: 138,
    year: 2021,
    count: 392,
    len_sec: 219.44699999999986,
    lanemiles: 467.8359999999998,
    delay: 32340.0,
    trkdelay: 10384.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.02,
    trktti: 1.03,
    ghg: 160.0,
    congcost: 990212.0,
    allspeed: 61.27,
    trkspeed: 60.72,
    ttivmt: 60951.0,
    trkttivmt: 14296.0,
  },
  {
    agglevel: "county",
    regioncode: 138,
    year: 2022,
    count: 394,
    len_sec: 219.50999999999965,
    lanemiles: 469.55799999999937,
    delay: 47048.0,
    trkdelay: 18315.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.19,
    trkpti95: 1.25,
    tti: 1.03,
    trktti: 1.05,
    ghg: 328.0,
    congcost: 1742258.0,
    allspeed: 60.47,
    trkspeed: 59.78,
    ttivmt: 58373.0,
    trkttivmt: 14216.0,
  },
  {
    agglevel: "county",
    regioncode: 139,
    year: 2017,
    count: 753,
    len_sec: 467.85099999999983,
    lanemiles: 1040.6580000000001,
    delay: 992480.0,
    trkdelay: 53368.0,
    pti80: 1.13,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.06,
    trktti: 1.04,
    ghg: 4230.0,
    congcost: 2.0081742e7,
    allspeed: 50.36,
    trkspeed: 53.81,
    ttivmt: 547353.0,
    trkttivmt: 44890.0,
  },
  {
    agglevel: "county",
    regioncode: 139,
    year: 2018,
    count: 736,
    len_sec: 466.9069999999993,
    lanemiles: 1043.284999999998,
    delay: 768425.0,
    trkdelay: 85396.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.15,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.06,
    ghg: 4131.0,
    congcost: 1.7387855e7,
    allspeed: 50.07,
    trkspeed: 51.52,
    ttivmt: 495657.0,
    trkttivmt: 51260.0,
  },
  {
    agglevel: "county",
    regioncode: 139,
    year: 2019,
    count: 352,
    len_sec: 466.4579999999999,
    lanemiles: 1042.9470000000006,
    delay: 854051.0,
    trkdelay: 106204.0,
    pti80: 1.13,
    trkpti80: 1.17,
    pti95: 1.16,
    trkpti95: 1.23,
    tti: 1.06,
    trktti: 1.08,
    ghg: 4725.0,
    congcost: 2.0512984e7,
    allspeed: 53.96,
    trkspeed: 52.84,
    ttivmt: 506906.0,
    trkttivmt: 52935.0,
  },
  {
    agglevel: "county",
    regioncode: 139,
    year: 2020,
    count: 1225,
    len_sec: 466.24699999999916,
    lanemiles: 1049.5759999999977,
    delay: 1047163.0,
    trkdelay: 73134.0,
    pti80: 1.13,
    trkpti80: 1.13,
    pti95: 1.17,
    trkpti95: 1.17,
    tti: 1.06,
    trktti: 1.05,
    ghg: 4882.0,
    congcost: 2.376886e7,
    allspeed: 53.24,
    trkspeed: 56.12,
    ttivmt: 532268.0,
    trkttivmt: 54271.0,
  },
  {
    agglevel: "county",
    regioncode: 139,
    year: 2021,
    count: 1214,
    len_sec: 465.4279999999984,
    lanemiles: 1048.9089999999987,
    delay: 477060.0,
    trkdelay: 45605.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.04,
    ghg: 2190.0,
    congcost: 1.1407415e7,
    allspeed: 54.54,
    trkspeed: 57.27,
    ttivmt: 534169.0,
    trkttivmt: 51751.0,
  },
  {
    agglevel: "county",
    regioncode: 139,
    year: 2022,
    count: 1236,
    len_sec: 465.42799999999903,
    lanemiles: 1048.9089999999983,
    delay: 845450.0,
    trkdelay: 59985.0,
    pti80: 1.13,
    trkpti80: 1.13,
    pti95: 1.19,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.04,
    ghg: 3524.0,
    congcost: 2.1624381e7,
    allspeed: 53.29,
    trkspeed: 56.84,
    ttivmt: 567034.0,
    trkttivmt: 57133.0,
  },
  {
    agglevel: "county",
    regioncode: 140,
    year: 2017,
    count: 405,
    len_sec: 406.29299999999995,
    lanemiles: 894.0379999999991,
    delay: 119636.0,
    trkdelay: 12767.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 497.0,
    congcost: 2609666.0,
    allspeed: 59.71,
    trkspeed: 61.87,
    ttivmt: 204613.0,
    trkttivmt: 31625.0,
  },
  {
    agglevel: "county",
    regioncode: 140,
    year: 2018,
    count: 325,
    len_sec: 405.8989999999994,
    lanemiles: 892.955999999999,
    delay: 104322.0,
    trkdelay: 18067.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 555.0,
    congcost: 2556233.0,
    allspeed: 59.33,
    trkspeed: 60.49,
    ttivmt: 183563.0,
    trkttivmt: 31590.0,
  },
  {
    agglevel: "county",
    regioncode: 140,
    year: 2019,
    count: 245,
    len_sec: 405.977,
    lanemiles: 893.1119999999997,
    delay: 85370.0,
    trkdelay: 26127.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 612.0,
    congcost: 2589591.0,
    allspeed: 60.72,
    trkspeed: 60.54,
    ttivmt: 172928.0,
    trkttivmt: 35407.0,
  },
  {
    agglevel: "county",
    regioncode: 140,
    year: 2020,
    count: 465,
    len_sec: 405.99199999999973,
    lanemiles: 893.1419999999993,
    delay: 105844.0,
    trkdelay: 23145.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 626.0,
    congcost: 2985155.0,
    allspeed: 60.52,
    trkspeed: 60.97,
    ttivmt: 174317.0,
    trkttivmt: 30491.0,
  },
  {
    agglevel: "county",
    regioncode: 140,
    year: 2021,
    count: 467,
    len_sec: 406.00099999999946,
    lanemiles: 895.1519999999989,
    delay: 90870.0,
    trkdelay: 23684.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 600.0,
    congcost: 2644758.0,
    allspeed: 60.37,
    trkspeed: 61.21,
    ttivmt: 175847.0,
    trkttivmt: 37507.0,
  },
  {
    agglevel: "county",
    regioncode: 140,
    year: 2022,
    count: 470,
    len_sec: 406.0509999999994,
    lanemiles: 896.341999999999,
    delay: 133678.0,
    trkdelay: 27510.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.18,
    tti: 1.02,
    trktti: 1.03,
    ghg: 749.0,
    congcost: 4070150.0,
    allspeed: 60.41,
    trkspeed: 60.88,
    ttivmt: 196099.0,
    trkttivmt: 34448.0,
  },
  {
    agglevel: "county",
    regioncode: 141,
    year: 2017,
    count: 423,
    len_sec: 286.7889999999998,
    lanemiles: 647.838,
    delay: 365354.0,
    trkdelay: 27666.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 1550.0,
    congcost: 7630889.0,
    allspeed: 54.3,
    trkspeed: 57.86,
    ttivmt: 293825.0,
    trkttivmt: 34647.0,
  },
  {
    agglevel: "county",
    regioncode: 141,
    year: 2018,
    count: 427,
    len_sec: 286.8009999999999,
    lanemiles: 653.7419999999998,
    delay: 305592.0,
    trkdelay: 36277.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.06,
    ghg: 1508.0,
    congcost: 6964374.0,
    allspeed: 56.0,
    trkspeed: 55.43,
    ttivmt: 279702.0,
    trkttivmt: 27703.0,
  },
  {
    agglevel: "county",
    regioncode: 141,
    year: 2019,
    count: 135,
    len_sec: 182.9590000000001,
    lanemiles: 446.05800000000005,
    delay: 268515.0,
    trkdelay: 38820.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.12,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.06,
    ghg: 1406.0,
    congcost: 6608469.0,
    allspeed: 57.62,
    trkspeed: 56.6,
    ttivmt: 256134.0,
    trkttivmt: 26427.0,
  },
  {
    agglevel: "county",
    regioncode: 141,
    year: 2020,
    count: 713,
    len_sec: 289.38599999999997,
    lanemiles: 669.0329999999996,
    delay: 348328.0,
    trkdelay: 35166.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.05,
    ghg: 1645.0,
    congcost: 8294716.0,
    allspeed: 56.17,
    trkspeed: 56.84,
    ttivmt: 302923.0,
    trkttivmt: 24398.0,
  },
  {
    agglevel: "county",
    regioncode: 141,
    year: 2021,
    count: 706,
    len_sec: 289.3859999999997,
    lanemiles: 669.0329999999998,
    delay: 251258.0,
    trkdelay: 35348.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.19,
    tti: 1.03,
    trktti: 1.05,
    ghg: 1295.0,
    congcost: 6367489.0,
    allspeed: 56.1,
    trkspeed: 57.31,
    ttivmt: 262572.0,
    trkttivmt: 28916.0,
  },
  {
    agglevel: "county",
    regioncode: 141,
    year: 2022,
    count: 707,
    len_sec: 289.4469999999996,
    lanemiles: 669.2200000000001,
    delay: 375660.0,
    trkdelay: 37777.0,
    pti80: 1.12,
    trkpti80: 1.16,
    pti95: 1.18,
    trkpti95: 1.25,
    tti: 1.05,
    trktti: 1.07,
    ghg: 1613.0,
    congcost: 9996386.0,
    allspeed: 54.99,
    trkspeed: 55.95,
    ttivmt: 287514.0,
    trkttivmt: 23400.0,
  },
  {
    agglevel: "county",
    regioncode: 142,
    year: 2017,
    count: 197,
    len_sec: 293.5180000000002,
    lanemiles: 679.4970000000003,
    delay: 291729.0,
    trkdelay: 92418.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1965.0,
    congcost: 8288981.0,
    allspeed: 60.99,
    trkspeed: 63.33,
    ttivmt: 516754.0,
    trkttivmt: 347906.0,
  },
  {
    agglevel: "county",
    regioncode: 142,
    year: 2018,
    count: 325,
    len_sec: 294.52699999999993,
    lanemiles: 681.4420000000002,
    delay: 171685.0,
    trkdelay: 23665.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 785.0,
    congcost: 3998763.0,
    allspeed: 61.32,
    trkspeed: 63.88,
    ttivmt: 497342.0,
    trkttivmt: 166279.0,
  },
  {
    agglevel: "county",
    regioncode: 142,
    year: 2019,
    count: 87,
    len_sec: 248.97699999999983,
    lanemiles: 590.3409999999998,
    delay: 133487.0,
    trkdelay: 38947.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 862.0,
    congcost: 3957512.0,
    allspeed: 62.71,
    trkspeed: 61.37,
    ttivmt: 507017.0,
    trkttivmt: 204008.0,
  },
  {
    agglevel: "county",
    regioncode: 142,
    year: 2020,
    count: 411,
    len_sec: 292.7039999999998,
    lanemiles: 677.8289999999995,
    delay: 186740.0,
    trkdelay: 32512.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1067.0,
    congcost: 4961167.0,
    allspeed: 62.48,
    trkspeed: 64.28,
    ttivmt: 561182.0,
    trkttivmt: 221647.0,
  },
  {
    agglevel: "county",
    regioncode: 142,
    year: 2021,
    count: 414,
    len_sec: 294.2399999999998,
    lanemiles: 680.8999999999996,
    delay: 226170.0,
    trkdelay: 38900.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.01,
    ghg: 1130.0,
    congcost: 5932381.0,
    allspeed: 61.74,
    trkspeed: 64.09,
    ttivmt: 469751.0,
    trkttivmt: 198771.0,
  },
  {
    agglevel: "county",
    regioncode: 142,
    year: 2022,
    count: 422,
    len_sec: 294.9199999999999,
    lanemiles: 682.1660000000002,
    delay: 181666.0,
    trkdelay: 42232.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1099.0,
    congcost: 5709712.0,
    allspeed: 62.46,
    trkspeed: 64.02,
    ttivmt: 488313.0,
    trkttivmt: 219011.0,
  },
  {
    agglevel: "county",
    regioncode: 143,
    year: 2017,
    count: 423,
    len_sec: 327.09800000000035,
    lanemiles: 687.7710000000002,
    delay: 249197.0,
    trkdelay: 23275.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1000.0,
    congcost: 5322934.0,
    allspeed: 56.27,
    trkspeed: 59.68,
    ttivmt: 301309.0,
    trkttivmt: 44600.0,
  },
  {
    agglevel: "county",
    regioncode: 143,
    year: 2018,
    count: 520,
    len_sec: 326.14600000000013,
    lanemiles: 686.6240000000001,
    delay: 219908.0,
    trkdelay: 36307.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.12,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.05,
    ghg: 1145.0,
    congcost: 5334779.0,
    allspeed: 55.74,
    trkspeed: 57.4,
    ttivmt: 236460.0,
    trkttivmt: 31934.0,
  },
  {
    agglevel: "county",
    regioncode: 143,
    year: 2019,
    count: 145,
    len_sec: 195.26800000000003,
    lanemiles: 423.812,
    delay: 154346.0,
    trkdelay: 35116.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.11,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 993.0,
    congcost: 4261386.0,
    allspeed: 57.95,
    trkspeed: 58.14,
    ttivmt: 206106.0,
    trkttivmt: 31423.0,
  },
  {
    agglevel: "county",
    regioncode: 143,
    year: 2020,
    count: 730,
    len_sec: 327.0249999999998,
    lanemiles: 688.3809999999999,
    delay: 227445.0,
    trkdelay: 39051.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.04,
    ghg: 1286.0,
    congcost: 6025483.0,
    allspeed: 57.17,
    trkspeed: 58.13,
    ttivmt: 280825.0,
    trkttivmt: 35400.0,
  },
  {
    agglevel: "county",
    regioncode: 143,
    year: 2021,
    count: 728,
    len_sec: 327.1269999999999,
    lanemiles: 687.2189999999998,
    delay: 154835.0,
    trkdelay: 28719.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.17,
    tti: 1.02,
    trktti: 1.04,
    ghg: 836.0,
    congcost: 4132317.0,
    allspeed: 57.14,
    trkspeed: 58.1,
    ttivmt: 240371.0,
    trkttivmt: 28671.0,
  },
  {
    agglevel: "county",
    regioncode: 143,
    year: 2022,
    count: 732,
    len_sec: 327.1279999999993,
    lanemiles: 687.2229999999986,
    delay: 265392.0,
    trkdelay: 31451.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1059.0,
    congcost: 7198057.0,
    allspeed: 56.78,
    trkspeed: 57.92,
    ttivmt: 263937.0,
    trkttivmt: 29242.0,
  },
  {
    agglevel: "county",
    regioncode: 144,
    year: 2017,
    count: 209,
    len_sec: 218.61899999999972,
    lanemiles: 579.2939999999996,
    delay: 223214.0,
    trkdelay: 27689.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.08,
    tti: 1.03,
    trktti: 1.02,
    ghg: 915.0,
    congcost: 4972320.0,
    allspeed: 58.18,
    trkspeed: 61.04,
    ttivmt: 272464.0,
    trkttivmt: 55759.0,
  },
  {
    agglevel: "county",
    regioncode: 144,
    year: 2018,
    count: 311,
    len_sec: 218.18300000000008,
    lanemiles: 577.358,
    delay: 210285.0,
    trkdelay: 36595.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1134.0,
    congcost: 5159720.0,
    allspeed: 57.82,
    trkspeed: 58.18,
    ttivmt: 281614.0,
    trkttivmt: 40960.0,
  },
  {
    agglevel: "county",
    regioncode: 144,
    year: 2019,
    count: 91,
    len_sec: 151.46599999999998,
    lanemiles: 443.9200000000001,
    delay: 189028.0,
    trkdelay: 37555.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.04,
    ghg: 1093.0,
    congcost: 5009454.0,
    allspeed: 59.34,
    trkspeed: 58.09,
    ttivmt: 283905.0,
    trkttivmt: 41764.0,
  },
  {
    agglevel: "county",
    regioncode: 144,
    year: 2020,
    count: 427,
    len_sec: 218.19500000000002,
    lanemiles: 571.176,
    delay: 238652.0,
    trkdelay: 36698.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.04,
    ghg: 1278.0,
    congcost: 6154055.0,
    allspeed: 58.78,
    trkspeed: 59.14,
    ttivmt: 297235.0,
    trkttivmt: 39420.0,
  },
  {
    agglevel: "county",
    regioncode: 144,
    year: 2021,
    count: 440,
    len_sec: 218.30299999999997,
    lanemiles: 571.5820000000002,
    delay: 182507.0,
    trkdelay: 27284.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 901.0,
    congcost: 4660635.0,
    allspeed: 58.66,
    trkspeed: 59.19,
    ttivmt: 274071.0,
    trkttivmt: 35866.0,
  },
  {
    agglevel: "county",
    regioncode: 144,
    year: 2022,
    count: 489,
    len_sec: 218.30299999999983,
    lanemiles: 568.2469999999988,
    delay: 232486.0,
    trkdelay: 26051.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 927.0,
    congcost: 6249782.0,
    allspeed: 58.4,
    trkspeed: 59.57,
    ttivmt: 304361.0,
    trkttivmt: 39169.0,
  },
  {
    agglevel: "county",
    regioncode: 145,
    year: 2017,
    count: 483,
    len_sec: 392.9849999999998,
    lanemiles: 839.7369999999997,
    delay: 210900.0,
    trkdelay: 37209.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.0,
    ghg: 729.0,
    congcost: 5000813.0,
    allspeed: 61.79,
    trkspeed: 63.95,
    ttivmt: 646426.0,
    trkttivmt: 320994.0,
  },
  {
    agglevel: "county",
    regioncode: 145,
    year: 2018,
    count: 559,
    len_sec: 399.64999999999975,
    lanemiles: 854.9039999999994,
    delay: 236925.0,
    trkdelay: 44026.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1078.0,
    congcost: 5855835.0,
    allspeed: 61.37,
    trkspeed: 62.31,
    ttivmt: 644009.0,
    trkttivmt: 146120.0,
  },
  {
    agglevel: "county",
    regioncode: 145,
    year: 2019,
    count: 160,
    len_sec: 316.775,
    lanemiles: 689.1419999999997,
    delay: 263532.0,
    trkdelay: 57317.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1211.0,
    congcost: 7057514.0,
    allspeed: 61.86,
    trkspeed: 62.23,
    ttivmt: 649069.0,
    trkttivmt: 148933.0,
  },
  {
    agglevel: "county",
    regioncode: 145,
    year: 2020,
    count: 768,
    len_sec: 399.7039999999998,
    lanemiles: 854.9299999999998,
    delay: 327836.0,
    trkdelay: 52582.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.01,
    ghg: 1552.0,
    congcost: 8481122.0,
    allspeed: 61.51,
    trkspeed: 63.09,
    ttivmt: 677053.0,
    trkttivmt: 176342.0,
  },
  {
    agglevel: "county",
    regioncode: 145,
    year: 2021,
    count: 758,
    len_sec: 398.93799999999953,
    lanemiles: 854.2269999999994,
    delay: 225220.0,
    trkdelay: 40079.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1120.0,
    congcost: 5939072.0,
    allspeed: 61.75,
    trkspeed: 62.9,
    ttivmt: 611821.0,
    trkttivmt: 134851.0,
  },
  {
    agglevel: "county",
    regioncode: 145,
    year: 2022,
    count: 766,
    len_sec: 399.07699999999966,
    lanemiles: 854.6249999999997,
    delay: 276914.0,
    trkdelay: 69918.0,
    pti80: 1.12,
    trkpti80: 1.07,
    pti95: 1.19,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.02,
    ghg: 1188.0,
    congcost: 8751365.0,
    allspeed: 61.68,
    trkspeed: 62.36,
    ttivmt: 701627.0,
    trkttivmt: 157442.0,
  },
  {
    agglevel: "county",
    regioncode: 146,
    year: 2017,
    count: 720,
    len_sec: 409.5789999999995,
    lanemiles: 921.8569999999984,
    delay: 1327965.0,
    trkdelay: 102582.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.05,
    trktti: 1.03,
    ghg: 5291.0,
    congcost: 2.7720542e7,
    allspeed: 53.07,
    trkspeed: 58.09,
    ttivmt: 903279.0,
    trkttivmt: 118866.0,
  },
  {
    agglevel: "county",
    regioncode: 146,
    year: 2018,
    count: 777,
    len_sec: 408.5979999999997,
    lanemiles: 918.3609999999993,
    delay: 1316571.0,
    trkdelay: 130770.0,
    pti80: 1.12,
    trkpti80: 1.16,
    pti95: 1.14,
    trkpti95: 1.21,
    tti: 1.06,
    trktti: 1.07,
    ghg: 5900.0,
    congcost: 2.9089115e7,
    allspeed: 54.39,
    trkspeed: 55.55,
    ttivmt: 837251.0,
    trkttivmt: 81681.0,
  },
  {
    agglevel: "county",
    regioncode: 146,
    year: 2019,
    count: 225,
    len_sec: 250.96599999999995,
    lanemiles: 632.0130000000003,
    delay: 959463.0,
    trkdelay: 112560.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.13,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.06,
    ghg: 4586.0,
    congcost: 2.2654723e7,
    allspeed: 57.28,
    trkspeed: 56.11,
    ttivmt: 736350.0,
    trkttivmt: 77515.0,
  },
  {
    agglevel: "county",
    regioncode: 146,
    year: 2020,
    count: 1193,
    len_sec: 408.59999999999974,
    lanemiles: 946.1749999999988,
    delay: 1240392.0,
    trkdelay: 130993.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.13,
    trkpti95: 1.18,
    tti: 1.05,
    trktti: 1.06,
    ghg: 5830.0,
    congcost: 2.9699395e7,
    allspeed: 56.33,
    trkspeed: 57.49,
    ttivmt: 918312.0,
    trkttivmt: 88962.0,
  },
  {
    agglevel: "county",
    regioncode: 146,
    year: 2021,
    count: 1243,
    len_sec: 408.6379999999981,
    lanemiles: 946.2869999999973,
    delay: 880859.0,
    trkdelay: 107724.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.14,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.06,
    ghg: 3634.0,
    congcost: 2.1646496e7,
    allspeed: 56.39,
    trkspeed: 57.63,
    ttivmt: 835585.0,
    trkttivmt: 83319.0,
  },
  {
    agglevel: "county",
    regioncode: 146,
    year: 2022,
    count: 1256,
    len_sec: 408.68799999999925,
    lanemiles: 946.5309999999988,
    delay: 1524113.0,
    trkdelay: 138131.0,
    pti80: 1.13,
    trkpti80: 1.13,
    pti95: 1.21,
    trkpti95: 1.2,
    tti: 1.06,
    trktti: 1.06,
    ghg: 6014.0,
    congcost: 3.9869387e7,
    allspeed: 55.01,
    trkspeed: 57.44,
    ttivmt: 913758.0,
    trkttivmt: 103879.0,
  },
  {
    agglevel: "county",
    regioncode: 147,
    year: 2017,
    count: 629,
    len_sec: 433.0110000000001,
    lanemiles: 878.7440000000003,
    delay: 367140.0,
    trkdelay: 27897.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.1,
    tti: 1.05,
    trktti: 1.03,
    ghg: 1427.0,
    congcost: 7650789.0,
    allspeed: 53.8,
    trkspeed: 60.0,
    ttivmt: 266840.0,
    trkttivmt: 42617.0,
  },
  {
    agglevel: "county",
    regioncode: 147,
    year: 2018,
    count: 721,
    len_sec: 432.23999999999984,
    lanemiles: 877.2439999999997,
    delay: 339577.0,
    trkdelay: 40087.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.05,
    trktti: 1.06,
    ghg: 1526.0,
    congcost: 7702341.0,
    allspeed: 53.85,
    trkspeed: 55.26,
    ttivmt: 268052.0,
    trkttivmt: 29332.0,
  },
  {
    agglevel: "county",
    regioncode: 147,
    year: 2019,
    count: 254,
    len_sec: 315.7379999999999,
    lanemiles: 644.2399999999998,
    delay: 260164.0,
    trkdelay: 37694.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.12,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.06,
    ghg: 1307.0,
    congcost: 6394395.0,
    allspeed: 56.89,
    trkspeed: 56.81,
    ttivmt: 236057.0,
    trkttivmt: 27608.0,
  },
  {
    agglevel: "county",
    regioncode: 147,
    year: 2020,
    count: 991,
    len_sec: 431.9919999999991,
    lanemiles: 876.7479999999985,
    delay: 347527.0,
    trkdelay: 45586.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.13,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.06,
    ghg: 1768.0,
    congcost: 8672379.0,
    allspeed: 56.05,
    trkspeed: 57.62,
    ttivmt: 267179.0,
    trkttivmt: 31245.0,
  },
  {
    agglevel: "county",
    regioncode: 147,
    year: 2021,
    count: 978,
    len_sec: 432.36099999999965,
    lanemiles: 877.4859999999991,
    delay: 211367.0,
    trkdelay: 36493.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.11,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.05,
    ghg: 1171.0,
    congcost: 5570125.0,
    allspeed: 56.55,
    trkspeed: 58.1,
    ttivmt: 260392.0,
    trkttivmt: 31798.0,
  },
  {
    agglevel: "county",
    regioncode: 147,
    year: 2022,
    count: 986,
    len_sec: 432.5119999999992,
    lanemiles: 877.8039999999988,
    delay: 372422.0,
    trkdelay: 41429.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.18,
    trkpti95: 1.22,
    tti: 1.05,
    trktti: 1.06,
    ghg: 1467.0,
    congcost: 1.001039e7,
    allspeed: 55.14,
    trkspeed: 56.75,
    ttivmt: 265088.0,
    trkttivmt: 30920.0,
  },
  {
    agglevel: "county",
    regioncode: 148,
    year: 2017,
    count: 284,
    len_sec: 197.7119999999999,
    lanemiles: 411.9229999999997,
    delay: 21768.0,
    trkdelay: 2801.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.01,
    ghg: 54.0,
    congcost: 484195.0,
    allspeed: 60.89,
    trkspeed: 62.37,
    ttivmt: 36015.0,
    trkttivmt: 7467.0,
  },
  {
    agglevel: "county",
    regioncode: 148,
    year: 2018,
    count: 260,
    len_sec: 197.7610000000002,
    lanemiles: 411.71300000000025,
    delay: 31827.0,
    trkdelay: 6923.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.04,
    ghg: 118.0,
    congcost: 813335.0,
    allspeed: 59.84,
    trkspeed: 59.98,
    ttivmt: 46908.0,
    trkttivmt: 8016.0,
  },
  {
    agglevel: "county",
    regioncode: 148,
    year: 2019,
    count: 101,
    len_sec: 197.78500000000003,
    lanemiles: 411.76099999999997,
    delay: 19680.0,
    trkdelay: 7631.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 114.0,
    congcost: 644366.0,
    allspeed: 60.93,
    trkspeed: 59.94,
    ttivmt: 35298.0,
    trkttivmt: 8644.0,
  },
  {
    agglevel: "county",
    regioncode: 148,
    year: 2020,
    count: 394,
    len_sec: 197.79399999999995,
    lanemiles: 413.3090000000001,
    delay: 24762.0,
    trkdelay: 6883.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 127.0,
    congcost: 748613.0,
    allspeed: 60.34,
    trkspeed: 60.44,
    ttivmt: 38006.0,
    trkttivmt: 8572.0,
  },
  {
    agglevel: "county",
    regioncode: 148,
    year: 2021,
    count: 396,
    len_sec: 197.78400000000016,
    lanemiles: 414.12400000000025,
    delay: 28011.0,
    trkdelay: 7797.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 135.0,
    congcost: 822737.0,
    allspeed: 59.91,
    trkspeed: 60.38,
    ttivmt: 38451.0,
    trkttivmt: 9379.0,
  },
  {
    agglevel: "county",
    regioncode: 148,
    year: 2022,
    count: 400,
    len_sec: 197.78400000000025,
    lanemiles: 414.1240000000003,
    delay: 53530.0,
    trkdelay: 12986.0,
    pti80: 1.13,
    trkpti80: 1.15,
    pti95: 1.22,
    trkpti95: 1.24,
    tti: 1.05,
    trktti: 1.06,
    ghg: 246.0,
    congcost: 1686382.0,
    allspeed: 58.34,
    trkspeed: 59.13,
    ttivmt: 37036.0,
    trkttivmt: 7562.0,
  },
  {
    agglevel: "county",
    regioncode: 149,
    year: 2017,
    count: 448,
    len_sec: 447.375,
    lanemiles: 1069.0629999999994,
    delay: 178468.0,
    trkdelay: 28478.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 988.0,
    congcost: 4208218.0,
    allspeed: 62.01,
    trkspeed: 63.32,
    ttivmt: 580222.0,
    trkttivmt: 174473.0,
  },
  {
    agglevel: "county",
    regioncode: 149,
    year: 2018,
    count: 561,
    len_sec: 447.16399999999976,
    lanemiles: 1069.2299999999984,
    delay: 185184.0,
    trkdelay: 45137.0,
    pti80: 1.08,
    trkpti80: 1.06,
    pti95: 1.12,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1217.0,
    congcost: 4980488.0,
    allspeed: 61.61,
    trkspeed: 61.48,
    ttivmt: 601739.0,
    trkttivmt: 130338.0,
  },
  {
    agglevel: "county",
    regioncode: 149,
    year: 2019,
    count: 136,
    len_sec: 283.94199999999995,
    lanemiles: 743.0269999999999,
    delay: 255615.0,
    trkdelay: 78065.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.14,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.02,
    ghg: 1783.0,
    congcost: 7709709.0,
    allspeed: 62.78,
    trkspeed: 61.36,
    ttivmt: 655545.0,
    trkttivmt: 137228.0,
  },
  {
    agglevel: "county",
    regioncode: 149,
    year: 2020,
    count: 790,
    len_sec: 447.54799999999926,
    lanemiles: 1073.9719999999998,
    delay: 298536.0,
    trkdelay: 71088.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.15,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1886.0,
    congcost: 8636854.0,
    allspeed: 62.1,
    trkspeed: 62.22,
    ttivmt: 683475.0,
    trkttivmt: 148109.0,
  },
  {
    agglevel: "county",
    regioncode: 149,
    year: 2021,
    count: 792,
    len_sec: 447.6589999999994,
    lanemiles: 1074.1720000000003,
    delay: 134786.0,
    trkdelay: 36249.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 947.0,
    congcost: 3964909.0,
    allspeed: 62.32,
    trkspeed: 62.59,
    ttivmt: 606348.0,
    trkttivmt: 155534.0,
  },
  {
    agglevel: "county",
    regioncode: 149,
    year: 2022,
    count: 792,
    len_sec: 447.64299999999963,
    lanemiles: 1074.1399999999999,
    delay: 240472.0,
    trkdelay: 88869.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.18,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.02,
    ghg: 1311.0,
    congcost: 8608480.0,
    allspeed: 62.24,
    trkspeed: 62.05,
    ttivmt: 664768.0,
    trkttivmt: 169047.0,
  },
  {
    agglevel: "county",
    regioncode: 150,
    year: 2017,
    count: 176,
    len_sec: 268.59000000000003,
    lanemiles: 580.3839999999998,
    delay: 220195.0,
    trkdelay: 29638.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.02,
    ghg: 858.0,
    congcost: 4969080.0,
    allspeed: 55.48,
    trkspeed: 60.96,
    ttivmt: 221634.0,
    trkttivmt: 52107.0,
  },
  {
    agglevel: "county",
    regioncode: 150,
    year: 2018,
    count: 325,
    len_sec: 268.3530000000002,
    lanemiles: 579.9680000000006,
    delay: 199353.0,
    trkdelay: 24315.0,
    pti80: 1.09,
    trkpti80: 1.15,
    pti95: 1.12,
    trkpti95: 1.21,
    tti: 1.03,
    trktti: 1.06,
    ghg: 900.0,
    congcost: 4547117.0,
    allspeed: 53.58,
    trkspeed: 55.66,
    ttivmt: 199837.0,
    trkttivmt: 18895.0,
  },
  {
    agglevel: "county",
    regioncode: 150,
    year: 2019,
    count: 86,
    len_sec: 196.03799999999998,
    lanemiles: 434.94000000000005,
    delay: 160046.0,
    trkdelay: 23577.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.11,
    trkpti95: 1.2,
    tti: 1.03,
    trktti: 1.06,
    ghg: 749.0,
    congcost: 3937112.0,
    allspeed: 57.65,
    trkspeed: 57.4,
    ttivmt: 188351.0,
    trkttivmt: 18466.0,
  },
  {
    agglevel: "county",
    regioncode: 150,
    year: 2020,
    count: 423,
    len_sec: 268.445,
    lanemiles: 590.0780000000003,
    delay: 195620.0,
    trkdelay: 19519.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 771.0,
    congcost: 4617359.0,
    allspeed: 56.44,
    trkspeed: 57.82,
    ttivmt: 221651.0,
    trkttivmt: 18576.0,
  },
  {
    agglevel: "county",
    regioncode: 150,
    year: 2021,
    count: 432,
    len_sec: 268.44499999999994,
    lanemiles: 589.8439999999998,
    delay: 142802.0,
    trkdelay: 23206.0,
    pti80: 1.09,
    trkpti80: 1.13,
    pti95: 1.12,
    trkpti95: 1.19,
    tti: 1.02,
    trktti: 1.05,
    ghg: 624.0,
    congcost: 3687098.0,
    allspeed: 56.78,
    trkspeed: 56.9,
    ttivmt: 256470.0,
    trkttivmt: 22837.0,
  },
  {
    agglevel: "county",
    regioncode: 150,
    year: 2022,
    count: 471,
    len_sec: 268.46799999999985,
    lanemiles: 595.9319999999997,
    delay: 234602.0,
    trkdelay: 31295.0,
    pti80: 1.11,
    trkpti80: 1.15,
    pti95: 1.16,
    trkpti95: 1.24,
    tti: 1.04,
    trktti: 1.06,
    ghg: 914.0,
    congcost: 6473921.0,
    allspeed: 56.0,
    trkspeed: 57.58,
    ttivmt: 229343.0,
    trkttivmt: 23238.0,
  },
  {
    agglevel: "county",
    regioncode: 151,
    year: 2017,
    count: 25,
    len_sec: 33.747,
    lanemiles: 67.494,
    delay: 11645.0,
    trkdelay: 3124.0,
    pti80: 1.06,
    trkpti80: 1.05,
    pti95: 1.09,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.0,
    ghg: 12.0,
    congcost: 302467.0,
    allspeed: 62.11,
    trkspeed: 64.43,
    ttivmt: 35084.0,
    trkttivmt: 41874.0,
  },
  {
    agglevel: "county",
    regioncode: 151,
    year: 2018,
    count: 24,
    len_sec: 33.70499999999999,
    lanemiles: 67.40999999999998,
    delay: 38260.0,
    trkdelay: 15321.0,
    pti80: 1.08,
    trkpti80: 1.13,
    pti95: 1.09,
    trkpti95: 1.21,
    tti: 1.02,
    trktti: 1.06,
    ghg: 183.0,
    congcost: 1194736.0,
    allspeed: 60.5,
    trkspeed: 60.18,
    ttivmt: 76831.0,
    trkttivmt: 18184.0,
  },
  {
    agglevel: "county",
    regioncode: 151,
    year: 2019,
    count: 11,
    len_sec: 33.70499999999999,
    lanemiles: 67.40999999999998,
    delay: 61905.0,
    trkdelay: 24198.0,
    pti80: 1.09,
    trkpti80: 1.14,
    pti95: 1.09,
    trkpti95: 1.21,
    tti: 1.03,
    trktti: 1.06,
    ghg: 209.0,
    congcost: 1985714.0,
    allspeed: 59.64,
    trkspeed: 57.3,
    ttivmt: 93379.0,
    trkttivmt: 23308.0,
  },
  {
    agglevel: "county",
    regioncode: 151,
    year: 2020,
    count: 32,
    len_sec: 33.705,
    lanemiles: 67.41,
    delay: 66737.0,
    trkdelay: 30440.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.1,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.06,
    ghg: 499.0,
    congcost: 2456969.0,
    allspeed: 57.78,
    trkspeed: 57.17,
    ttivmt: 92204.0,
    trkttivmt: 29044.0,
  },
  {
    agglevel: "county",
    regioncode: 151,
    year: 2021,
    count: 31,
    len_sec: 33.747,
    lanemiles: 67.494,
    delay: 27701.0,
    trkdelay: 16482.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.02,
    trktti: 1.03,
    ghg: 397.0,
    congcost: 1116386.0,
    allspeed: 59.16,
    trkspeed: 59.71,
    ttivmt: 70735.0,
    trkttivmt: 26507.0,
  },
  {
    agglevel: "county",
    regioncode: 151,
    year: 2022,
    count: 31,
    len_sec: 33.747000000000014,
    lanemiles: 67.49400000000003,
    delay: 36552.0,
    trkdelay: 12552.0,
    pti80: 1.06,
    trkpti80: 1.07,
    pti95: 1.06,
    trkpti95: 1.08,
    tti: 1.02,
    trktti: 1.02,
    ghg: 200.0,
    congcost: 1274382.0,
    allspeed: 61.1,
    trkspeed: 60.47,
    ttivmt: 84554.0,
    trkttivmt: 24425.0,
  },
  {
    agglevel: "county",
    regioncode: 152,
    year: 2017,
    count: 2065,
    len_sec: 1042.3319999999987,
    lanemiles: 2804.861000000001,
    delay: 7771846.0,
    trkdelay: 194833.0,
    pti80: 1.15,
    trkpti80: 1.12,
    pti95: 1.2,
    trkpti95: 1.17,
    tti: 1.06,
    trktti: 1.03,
    ghg: 25251.0,
    congcost: 1.49192413e8,
    allspeed: 44.33,
    trkspeed: 52.8,
    ttivmt: 4153518.0,
    trkttivmt: 215125.0,
  },
  {
    agglevel: "county",
    regioncode: 152,
    year: 2018,
    count: 1753,
    len_sec: 1042.682999999999,
    lanemiles: 2838.502000000006,
    delay: 5483470.0,
    trkdelay: 318952.0,
    pti80: 1.15,
    trkpti80: 1.19,
    pti95: 1.19,
    trkpti95: 1.25,
    tti: 1.08,
    trktti: 1.07,
    ghg: 24514.0,
    congcost: 1.14362868e8,
    allspeed: 47.83,
    trkspeed: 52.01,
    ttivmt: 2392919.0,
    trkttivmt: 167591.0,
  },
  {
    agglevel: "county",
    regioncode: 152,
    year: 2019,
    count: 1369,
    len_sec: 1042.2390000000005,
    lanemiles: 2838.062999999998,
    delay: 5081644.0,
    trkdelay: 374773.0,
    pti80: 1.15,
    trkpti80: 1.18,
    pti95: 1.18,
    trkpti95: 1.24,
    tti: 1.07,
    trktti: 1.08,
    ghg: 23941.0,
    congcost: 1.12815188e8,
    allspeed: 47.86,
    trkspeed: 50.25,
    ttivmt: 2388599.0,
    trkttivmt: 170274.0,
  },
  {
    agglevel: "county",
    regioncode: 152,
    year: 2020,
    count: 2704,
    len_sec: 1047.6959999999958,
    lanemiles: 2850.411999999997,
    delay: 5265070.0,
    trkdelay: 276987.0,
    pti80: 1.15,
    trkpti80: 1.15,
    pti95: 1.19,
    trkpti95: 1.2,
    tti: 1.07,
    trktti: 1.07,
    ghg: 22887.0,
    congcost: 1.15943821e8,
    allspeed: 47.8,
    trkspeed: 52.66,
    ttivmt: 2474129.0,
    trkttivmt: 150743.0,
  },
  {
    agglevel: "county",
    regioncode: 152,
    year: 2021,
    count: 2678,
    len_sec: 1048.1149999999952,
    lanemiles: 2851.2459999999905,
    delay: 4086038.0,
    trkdelay: 325108.0,
    pti80: 1.15,
    trkpti80: 1.17,
    pti95: 1.19,
    trkpti95: 1.26,
    tti: 1.06,
    trktti: 1.08,
    ghg: 17506.0,
    congcost: 9.5483053e7,
    allspeed: 47.05,
    trkspeed: 52.83,
    ttivmt: 2414339.0,
    trkttivmt: 176323.0,
  },
  {
    agglevel: "county",
    regioncode: 152,
    year: 2022,
    count: 2814,
    len_sec: 1048.383999999995,
    lanemiles: 2856.3409999999935,
    delay: 4925450.0,
    trkdelay: 345037.0,
    pti80: 1.14,
    trkpti80: 1.17,
    pti95: 1.19,
    trkpti95: 1.28,
    tti: 1.06,
    trktti: 1.07,
    ghg: 22350.0,
    congcost: 1.26341897e8,
    allspeed: 46.54,
    trkspeed: 51.44,
    ttivmt: 2652679.0,
    trkttivmt: 180215.0,
  },
  {
    agglevel: "county",
    regioncode: 153,
    year: 2017,
    count: 242,
    len_sec: 353.06200000000024,
    lanemiles: 783.4020000000003,
    delay: 50201.0,
    trkdelay: 5153.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.01,
    trktti: 1.0,
    ghg: 147.0,
    congcost: 1078072.0,
    allspeed: 62.99,
    trkspeed: 64.28,
    ttivmt: 159430.0,
    trkttivmt: 43893.0,
  },
  {
    agglevel: "county",
    regioncode: 153,
    year: 2018,
    count: 204,
    len_sec: 353.12700000000007,
    lanemiles: 783.3300000000002,
    delay: 34863.0,
    trkdelay: 5073.0,
    pti80: 1.08,
    trkpti80: 1.05,
    pti95: 1.12,
    trkpti95: 1.06,
    tti: 1.01,
    trktti: 1.01,
    ghg: 128.0,
    congcost: 815009.0,
    allspeed: 60.17,
    trkspeed: 60.37,
    ttivmt: 158951.0,
    trkttivmt: 24591.0,
  },
  {
    agglevel: "county",
    regioncode: 153,
    year: 2019,
    count: 162,
    len_sec: 351.95600000000013,
    lanemiles: 781.1010000000003,
    delay: 64155.0,
    trkdelay: 11460.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.15,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.02,
    ghg: 302.0,
    congcost: 1644115.0,
    allspeed: 63.31,
    trkspeed: 62.22,
    ttivmt: 179748.0,
    trkttivmt: 23823.0,
  },
  {
    agglevel: "county",
    regioncode: 153,
    year: 2020,
    count: 278,
    len_sec: 345.2850000000001,
    lanemiles: 763.5980000000001,
    delay: 84991.0,
    trkdelay: 11473.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 314.0,
    congcost: 2106313.0,
    allspeed: 63.09,
    trkspeed: 63.17,
    ttivmt: 181689.0,
    trkttivmt: 28221.0,
  },
  {
    agglevel: "county",
    regioncode: 153,
    year: 2021,
    count: 277,
    len_sec: 345.37800000000004,
    lanemiles: 762.9220000000007,
    delay: 43103.0,
    trkdelay: 9309.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 193.0,
    congcost: 1182755.0,
    allspeed: 63.4,
    trkspeed: 63.66,
    ttivmt: 162724.0,
    trkttivmt: 34408.0,
  },
  {
    agglevel: "county",
    regioncode: 153,
    year: 2022,
    count: 274,
    len_sec: 345.3950000000002,
    lanemiles: 762.9659999999999,
    delay: 58791.0,
    trkdelay: 11298.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.01,
    trktti: 1.02,
    ghg: 229.0,
    congcost: 1739028.0,
    allspeed: 63.04,
    trkspeed: 62.99,
    ttivmt: 182978.0,
    trkttivmt: 25119.0,
  },
  {
    agglevel: "county",
    regioncode: 154,
    year: 2017,
    count: 292,
    len_sec: 271.2829999999996,
    lanemiles: 589.9999999999995,
    delay: 244934.0,
    trkdelay: 63439.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.01,
    ghg: 1822.0,
    congcost: 6578080.0,
    allspeed: 61.33,
    trkspeed: 63.02,
    ttivmt: 436498.0,
    trkttivmt: 130851.0,
  },
  {
    agglevel: "county",
    regioncode: 154,
    year: 2018,
    count: 441,
    len_sec: 267.1069999999999,
    lanemiles: 621.4630000000006,
    delay: 221660.0,
    trkdelay: 24992.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.09,
    tti: 1.02,
    trktti: 1.01,
    ghg: 964.0,
    congcost: 4983976.0,
    allspeed: 61.11,
    trkspeed: 63.2,
    ttivmt: 434046.0,
    trkttivmt: 86364.0,
  },
  {
    agglevel: "county",
    regioncode: 154,
    year: 2019,
    count: 102,
    len_sec: 185.02199999999996,
    lanemiles: 457.5910000000001,
    delay: 175174.0,
    trkdelay: 26787.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 781.0,
    congcost: 4324032.0,
    allspeed: 62.11,
    trkspeed: 62.52,
    ttivmt: 442965.0,
    trkttivmt: 88718.0,
  },
  {
    agglevel: "county",
    regioncode: 154,
    year: 2020,
    count: 555,
    len_sec: 271.7729999999999,
    lanemiles: 598.6239999999999,
    delay: 239431.0,
    trkdelay: 27875.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.01,
    ghg: 1033.0,
    congcost: 5805191.0,
    allspeed: 61.25,
    trkspeed: 63.59,
    ttivmt: 470228.0,
    trkttivmt: 108520.0,
  },
  {
    agglevel: "county",
    regioncode: 154,
    year: 2021,
    count: 553,
    len_sec: 271.6049999999998,
    lanemiles: 598.2859999999997,
    delay: 191215.0,
    trkdelay: 29188.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.02,
    ghg: 948.0,
    congcost: 4901534.0,
    allspeed: 61.3,
    trkspeed: 62.85,
    ttivmt: 411582.0,
    trkttivmt: 81548.0,
  },
  {
    agglevel: "county",
    regioncode: 154,
    year: 2022,
    count: 568,
    len_sec: 271.77799999999985,
    lanemiles: 598.7539999999989,
    delay: 385323.0,
    trkdelay: 71043.0,
    pti80: 1.14,
    trkpti80: 1.08,
    pti95: 1.22,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1846.0,
    congcost: 1.1363071e7,
    allspeed: 60.66,
    trkspeed: 61.92,
    ttivmt: 477433.0,
    trkttivmt: 98739.0,
  },
  {
    agglevel: "county",
    regioncode: 155,
    year: 2017,
    count: 198,
    len_sec: 170.898,
    lanemiles: 370.0590000000001,
    delay: 121992.0,
    trkdelay: 16828.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.04,
    trktti: 1.03,
    ghg: 513.0,
    congcost: 2769425.0,
    allspeed: 57.52,
    trkspeed: 60.62,
    ttivmt: 108962.0,
    trkttivmt: 28111.0,
  },
  {
    agglevel: "county",
    regioncode: 155,
    year: 2018,
    count: 231,
    len_sec: 171.191,
    lanemiles: 370.7109999999999,
    delay: 129647.0,
    trkdelay: 20745.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.05,
    trktti: 1.05,
    ghg: 656.0,
    congcost: 3116926.0,
    allspeed: 55.05,
    trkspeed: 57.14,
    ttivmt: 109190.0,
    trkttivmt: 19170.0,
  },
  {
    agglevel: "county",
    regioncode: 155,
    year: 2019,
    count: 114,
    len_sec: 171.19699999999997,
    lanemiles: 370.7249999999999,
    delay: 104994.0,
    trkdelay: 17610.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 500.0,
    congcost: 2648596.0,
    allspeed: 58.15,
    trkspeed: 58.77,
    ttivmt: 115402.0,
    trkttivmt: 18500.0,
  },
  {
    agglevel: "county",
    regioncode: 155,
    year: 2020,
    count: 341,
    len_sec: 171.19599999999988,
    lanemiles: 370.7229999999998,
    delay: 111534.0,
    trkdelay: 16266.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.04,
    ghg: 521.0,
    congcost: 2827213.0,
    allspeed: 57.52,
    trkspeed: 59.07,
    ttivmt: 108980.0,
    trkttivmt: 17961.0,
  },
  {
    agglevel: "county",
    regioncode: 155,
    year: 2021,
    count: 337,
    len_sec: 171.21700000000004,
    lanemiles: 370.533,
    delay: 105950.0,
    trkdelay: 16631.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.05,
    ghg: 520.0,
    congcost: 2725972.0,
    allspeed: 57.24,
    trkspeed: 58.72,
    ttivmt: 109198.0,
    trkttivmt: 17942.0,
  },
  {
    agglevel: "county",
    regioncode: 155,
    year: 2022,
    count: 332,
    len_sec: 171.16399999999993,
    lanemiles: 370.42699999999985,
    delay: 151527.0,
    trkdelay: 17133.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.19,
    trkpti95: 1.15,
    tti: 1.05,
    trktti: 1.03,
    ghg: 584.0,
    congcost: 4072510.0,
    allspeed: 56.68,
    trkspeed: 59.04,
    ttivmt: 116206.0,
    trkttivmt: 22125.0,
  },
  {
    agglevel: "county",
    regioncode: 156,
    year: 2017,
    count: 225,
    len_sec: 273.9969999999999,
    lanemiles: 587.4269999999999,
    delay: 88716.0,
    trkdelay: 12344.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.0,
    ghg: 329.0,
    congcost: 2010060.0,
    allspeed: 62.48,
    trkspeed: 64.27,
    ttivmt: 291319.0,
    trkttivmt: 131153.0,
  },
  {
    agglevel: "county",
    regioncode: 156,
    year: 2018,
    count: 198,
    len_sec: 273.342,
    lanemiles: 586.165,
    delay: 122709.0,
    trkdelay: 29975.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 469.0,
    congcost: 3233298.0,
    allspeed: 61.26,
    trkspeed: 62.3,
    ttivmt: 337223.0,
    trkttivmt: 71250.0,
  },
  {
    agglevel: "county",
    regioncode: 156,
    year: 2019,
    count: 115,
    len_sec: 262.4530000000001,
    lanemiles: 564.4419999999999,
    delay: 106627.0,
    trkdelay: 41810.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.02,
    ghg: 557.0,
    congcost: 3481320.0,
    allspeed: 63.04,
    trkspeed: 62.06,
    ttivmt: 396178.0,
    trkttivmt: 99490.0,
  },
  {
    agglevel: "county",
    regioncode: 156,
    year: 2020,
    count: 277,
    len_sec: 273.7369999999999,
    lanemiles: 651.5890000000002,
    delay: 135468.0,
    trkdelay: 46231.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.02,
    ghg: 668.0,
    congcost: 4369030.0,
    allspeed: 62.79,
    trkspeed: 62.93,
    ttivmt: 446546.0,
    trkttivmt: 117057.0,
  },
  {
    agglevel: "county",
    regioncode: 156,
    year: 2021,
    count: 269,
    len_sec: 273.74099999999976,
    lanemiles: 651.6049999999996,
    delay: 99546.0,
    trkdelay: 44732.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.14,
    tti: 1.01,
    trktti: 1.02,
    ghg: 664.0,
    congcost: 3434314.0,
    allspeed: 62.82,
    trkspeed: 62.94,
    ttivmt: 371007.0,
    trkttivmt: 102883.0,
  },
  {
    agglevel: "county",
    regioncode: 156,
    year: 2022,
    count: 264,
    len_sec: 273.73999999999995,
    lanemiles: 651.6029999999997,
    delay: 201761.0,
    trkdelay: 74691.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.03,
    ghg: 997.0,
    congcost: 7202189.0,
    allspeed: 62.37,
    trkspeed: 62.18,
    ttivmt: 401945.0,
    trkttivmt: 123161.0,
  },
  {
    agglevel: "county",
    regioncode: 157,
    year: 2017,
    count: 125,
    len_sec: 199.42999999999984,
    lanemiles: 436.1349999999998,
    delay: 51680.0,
    trkdelay: 7523.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.15,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.02,
    ghg: 171.0,
    congcost: 1178455.0,
    allspeed: 59.37,
    trkspeed: 62.36,
    ttivmt: 73138.0,
    trkttivmt: 18452.0,
  },
  {
    agglevel: "county",
    regioncode: 157,
    year: 2018,
    count: 240,
    len_sec: 193.83,
    lanemiles: 424.689,
    delay: 36645.0,
    trkdelay: 6344.0,
    pti80: 1.08,
    trkpti80: 1.1,
    pti95: 1.1,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 144.0,
    congcost: 888700.0,
    allspeed: 57.86,
    trkspeed: 58.54,
    ttivmt: 74121.0,
    trkttivmt: 9067.0,
  },
  {
    agglevel: "county",
    regioncode: 157,
    year: 2019,
    count: 70,
    len_sec: 165.84700000000007,
    lanemiles: 368.72300000000007,
    delay: 36722.0,
    trkdelay: 8289.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.04,
    ghg: 175.0,
    congcost: 996778.0,
    allspeed: 60.84,
    trkspeed: 60.06,
    ttivmt: 75627.0,
    trkttivmt: 9826.0,
  },
  {
    agglevel: "county",
    regioncode: 157,
    year: 2020,
    count: 307,
    len_sec: 202.59499999999997,
    lanemiles: 442.2150000000001,
    delay: 45596.0,
    trkdelay: 7032.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 169.0,
    congcost: 1162404.0,
    allspeed: 60.08,
    trkspeed: 60.46,
    ttivmt: 81216.0,
    trkttivmt: 11026.0,
  },
  {
    agglevel: "county",
    regioncode: 157,
    year: 2021,
    count: 308,
    len_sec: 202.67399999999986,
    lanemiles: 440.3179999999997,
    delay: 28694.0,
    trkdelay: 5334.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.02,
    ghg: 112.0,
    congcost: 759509.0,
    allspeed: 60.45,
    trkspeed: 60.67,
    ttivmt: 85102.0,
    trkttivmt: 10718.0,
  },
  {
    agglevel: "county",
    regioncode: 157,
    year: 2022,
    count: 328,
    len_sec: 202.67199999999988,
    lanemiles: 441.5379999999996,
    delay: 47932.0,
    trkdelay: 10056.0,
    pti80: 1.08,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.03,
    ghg: 148.0,
    congcost: 1438224.0,
    allspeed: 60.08,
    trkspeed: 60.4,
    ttivmt: 83034.0,
    trkttivmt: 12439.0,
  },
  {
    agglevel: "county",
    regioncode: 158,
    year: 2017,
    count: 400,
    len_sec: 359.191,
    lanemiles: 769.5800000000003,
    delay: 734486.0,
    trkdelay: 49084.0,
    pti80: 1.12,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.12,
    tti: 1.05,
    trktti: 1.03,
    ghg: 3132.0,
    congcost: 1.5151835e7,
    allspeed: 50.38,
    trkspeed: 57.83,
    ttivmt: 451114.0,
    trkttivmt: 64380.0,
  },
  {
    agglevel: "county",
    regioncode: 158,
    year: 2018,
    count: 474,
    len_sec: 359.14599999999996,
    lanemiles: 770.392,
    delay: 592682.0,
    trkdelay: 43093.0,
    pti80: 1.11,
    trkpti80: 1.16,
    pti95: 1.13,
    trkpti95: 1.22,
    tti: 1.05,
    trktti: 1.07,
    ghg: 2446.0,
    congcost: 1.2578572e7,
    allspeed: 52.4,
    trkspeed: 53.94,
    ttivmt: 420019.0,
    trkttivmt: 27602.0,
  },
  {
    agglevel: "county",
    regioncode: 158,
    year: 2019,
    count: 127,
    len_sec: 191.01100000000008,
    lanemiles: 428.73799999999994,
    delay: 429104.0,
    trkdelay: 48588.0,
    pti80: 1.1,
    trkpti80: 1.15,
    pti95: 1.12,
    trkpti95: 1.21,
    tti: 1.04,
    trktti: 1.07,
    ghg: 2071.0,
    congcost: 1.0086351e7,
    allspeed: 55.35,
    trkspeed: 55.03,
    ttivmt: 305550.0,
    trkttivmt: 29578.0,
  },
  {
    agglevel: "county",
    regioncode: 158,
    year: 2020,
    count: 617,
    len_sec: 359.15899999999976,
    lanemiles: 789.0819999999995,
    delay: 645589.0,
    trkdelay: 67072.0,
    pti80: 1.11,
    trkpti80: 1.15,
    pti95: 1.14,
    trkpti95: 1.2,
    tti: 1.05,
    trktti: 1.07,
    ghg: 3079.0,
    congcost: 1.5434171e7,
    allspeed: 54.16,
    trkspeed: 55.02,
    ttivmt: 401789.0,
    trkttivmt: 36851.0,
  },
  {
    agglevel: "county",
    regioncode: 158,
    year: 2021,
    count: 615,
    len_sec: 359.1589999999992,
    lanemiles: 789.0429999999991,
    delay: 247444.0,
    trkdelay: 30908.0,
    pti80: 1.08,
    trkpti80: 1.12,
    pti95: 1.11,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1117.0,
    congcost: 6124590.0,
    allspeed: 55.06,
    trkspeed: 56.01,
    ttivmt: 369234.0,
    trkttivmt: 32764.0,
  },
  {
    agglevel: "county",
    regioncode: 158,
    year: 2022,
    count: 624,
    len_sec: 359.21399999999966,
    lanemiles: 789.1529999999997,
    delay: 677483.0,
    trkdelay: 58444.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.19,
    trkpti95: 1.23,
    tti: 1.06,
    trktti: 1.06,
    ghg: 2730.0,
    congcost: 1.7647246e7,
    allspeed: 53.02,
    trkspeed: 54.1,
    ttivmt: 409985.0,
    trkttivmt: 37902.0,
  },
  {
    agglevel: "county",
    regioncode: 159,
    year: 2017,
    count: 343,
    len_sec: 287.51599999999974,
    lanemiles: 644.568999999999,
    delay: 1649507.0,
    trkdelay: 65846.0,
    pti80: 1.19,
    trkpti80: 1.1,
    pti95: 1.24,
    trkpti95: 1.12,
    tti: 1.08,
    trktti: 1.02,
    ghg: 6620.0,
    congcost: 3.2626853e7,
    allspeed: 42.48,
    trkspeed: 58.07,
    ttivmt: 639093.0,
    trkttivmt: 100596.0,
  },
  {
    agglevel: "county",
    regioncode: 159,
    year: 2018,
    count: 446,
    len_sec: 287.9870000000002,
    lanemiles: 642.5590000000004,
    delay: 1421814.0,
    trkdelay: 93234.0,
    pti80: 1.18,
    trkpti80: 1.18,
    pti95: 1.22,
    trkpti95: 1.24,
    tti: 1.1,
    trktti: 1.08,
    ghg: 6677.0,
    congcost: 3.0028287e7,
    allspeed: 47.86,
    trkspeed: 52.15,
    ttivmt: 416821.0,
    trkttivmt: 41469.0,
  },
  {
    agglevel: "county",
    regioncode: 159,
    year: 2019,
    count: 110,
    len_sec: 197.652,
    lanemiles: 456.82500000000005,
    delay: 1250187.0,
    trkdelay: 118671.0,
    pti80: 1.17,
    trkpti80: 1.19,
    pti95: 1.19,
    trkpti95: 1.24,
    tti: 1.08,
    trktti: 1.08,
    ghg: 6294.0,
    congcost: 2.8689815e7,
    allspeed: 48.28,
    trkspeed: 50.59,
    ttivmt: 389889.0,
    trkttivmt: 44699.0,
  },
  {
    agglevel: "county",
    regioncode: 159,
    year: 2020,
    count: 586,
    len_sec: 287.18399999999986,
    lanemiles: 645.8439999999998,
    delay: 1538439.0,
    trkdelay: 107347.0,
    pti80: 1.17,
    trkpti80: 1.16,
    pti95: 1.2,
    trkpti95: 1.2,
    tti: 1.09,
    trktti: 1.07,
    ghg: 7198.0,
    congcost: 3.4900236e7,
    allspeed: 47.84,
    trkspeed: 54.32,
    ttivmt: 454949.0,
    trkttivmt: 46122.0,
  },
  {
    agglevel: "county",
    regioncode: 159,
    year: 2021,
    count: 590,
    len_sec: 287.71599999999995,
    lanemiles: 646.9079999999994,
    delay: 849859.0,
    trkdelay: 66534.0,
    pti80: 1.14,
    trkpti80: 1.13,
    pti95: 1.17,
    trkpti95: 1.18,
    tti: 1.06,
    trktti: 1.05,
    ghg: 3969.0,
    congcost: 1.9885345e7,
    allspeed: 47.27,
    trkspeed: 55.0,
    ttivmt: 377241.0,
    trkttivmt: 42270.0,
  },
  {
    agglevel: "county",
    regioncode: 159,
    year: 2022,
    count: 595,
    len_sec: 287.75499999999994,
    lanemiles: 655.2,
    delay: 1081693.0,
    trkdelay: 55716.0,
    pti80: 1.16,
    trkpti80: 1.12,
    pti95: 1.23,
    trkpti95: 1.18,
    tti: 1.07,
    trktti: 1.04,
    ghg: 4296.0,
    congcost: 2.6895336e7,
    allspeed: 46.4,
    trkspeed: 55.43,
    ttivmt: 403018.0,
    trkttivmt: 42160.0,
  },
  {
    agglevel: "county",
    regioncode: 160,
    year: 2017,
    count: 316,
    len_sec: 313.0770000000001,
    lanemiles: 640.7049999999999,
    delay: 141093.0,
    trkdelay: 19143.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.02,
    ghg: 692.0,
    congcost: 3213723.0,
    allspeed: 55.58,
    trkspeed: 60.05,
    ttivmt: 149822.0,
    trkttivmt: 33074.0,
  },
  {
    agglevel: "county",
    regioncode: 160,
    year: 2018,
    count: 377,
    len_sec: 312.3229999999998,
    lanemiles: 638.7289999999995,
    delay: 105021.0,
    trkdelay: 15463.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.03,
    ghg: 524.0,
    congcost: 2486933.0,
    allspeed: 57.27,
    trkspeed: 58.45,
    ttivmt: 154391.0,
    trkttivmt: 19301.0,
  },
  {
    agglevel: "county",
    regioncode: 160,
    year: 2019,
    count: 86,
    len_sec: 203.35399999999998,
    lanemiles: 420.791,
    delay: 78499.0,
    trkdelay: 23611.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.03,
    ghg: 573.0,
    congcost: 2367087.0,
    allspeed: 59.22,
    trkspeed: 59.24,
    ttivmt: 119014.0,
    trkttivmt: 28281.0,
  },
  {
    agglevel: "county",
    regioncode: 160,
    year: 2020,
    count: 544,
    len_sec: 312.42999999999955,
    lanemiles: 646.368999999999,
    delay: 103339.0,
    trkdelay: 21781.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 706.0,
    congcost: 2909213.0,
    allspeed: 58.66,
    trkspeed: 58.97,
    ttivmt: 136198.0,
    trkttivmt: 23970.0,
  },
  {
    agglevel: "county",
    regioncode: 160,
    year: 2021,
    count: 540,
    len_sec: 312.4969999999998,
    lanemiles: 646.5489999999994,
    delay: 75010.0,
    trkdelay: 15626.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.04,
    ghg: 451.0,
    congcost: 2063226.0,
    allspeed: 58.5,
    trkspeed: 59.23,
    ttivmt: 117809.0,
    trkttivmt: 18792.0,
  },
  {
    agglevel: "county",
    regioncode: 160,
    year: 2022,
    count: 541,
    len_sec: 312.55500000000023,
    lanemiles: 646.6650000000004,
    delay: 103502.0,
    trkdelay: 18817.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.16,
    trkpti95: 1.21,
    tti: 1.03,
    trktti: 1.05,
    ghg: 407.0,
    congcost: 3030305.0,
    allspeed: 58.75,
    trkspeed: 59.0,
    ttivmt: 138544.0,
    trkttivmt: 16908.0,
  },
  {
    agglevel: "county",
    regioncode: 161,
    year: 2017,
    count: 1893,
    len_sec: 892.8629999999985,
    lanemiles: 2266.2239999999942,
    delay: 6201804.0,
    trkdelay: 367701.0,
    pti80: 1.14,
    trkpti80: 1.11,
    pti95: 1.18,
    trkpti95: 1.15,
    tti: 1.05,
    trktti: 1.03,
    ghg: 25168.0,
    congcost: 1.26254464e8,
    allspeed: 50.33,
    trkspeed: 58.4,
    ttivmt: 4011675.0,
    trkttivmt: 478356.0,
  },
  {
    agglevel: "county",
    regioncode: 161,
    year: 2018,
    count: 1844,
    len_sec: 887.636000000001,
    lanemiles: 2255.083999999999,
    delay: 4627597.0,
    trkdelay: 438633.0,
    pti80: 1.14,
    trkpti80: 1.11,
    pti95: 1.19,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.04,
    ghg: 21824.0,
    congcost: 1.01809372e8,
    allspeed: 53.23,
    trkspeed: 58.96,
    ttivmt: 3087687.0,
    trkttivmt: 461935.0,
  },
  {
    agglevel: "county",
    regioncode: 161,
    year: 2019,
    count: 853,
    len_sec: 640.4009999999993,
    lanemiles: 1727.726999999999,
    delay: 4385819.0,
    trkdelay: 397947.0,
    pti80: 1.13,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.14,
    tti: 1.05,
    trktti: 1.04,
    ghg: 20066.0,
    congcost: 9.9562457e7,
    allspeed: 54.36,
    trkspeed: 58.7,
    ttivmt: 2929009.0,
    trkttivmt: 455182.0,
  },
  {
    agglevel: "county",
    regioncode: 161,
    year: 2020,
    count: 2739,
    len_sec: 891.6929999999982,
    lanemiles: 2268.8299999999927,
    delay: 6415640.0,
    trkdelay: 545977.0,
    pti80: 1.15,
    trkpti80: 1.12,
    pti95: 1.24,
    trkpti95: 1.24,
    tti: 1.06,
    trktti: 1.04,
    ghg: 31752.0,
    congcost: 1.49356233e8,
    allspeed: 52.34,
    trkspeed: 59.36,
    ttivmt: 3271539.0,
    trkttivmt: 487848.0,
  },
  {
    agglevel: "county",
    regioncode: 161,
    year: 2021,
    count: 2739,
    len_sec: 888.2599999999952,
    lanemiles: 2269.165999999993,
    delay: 3854752.0,
    trkdelay: 389035.0,
    pti80: 1.13,
    trkpti80: 1.13,
    pti95: 1.25,
    trkpti95: 1.29,
    tti: 1.05,
    trktti: 1.04,
    ghg: 18728.0,
    congcost: 9.2857302e7,
    allspeed: 52.53,
    trkspeed: 59.79,
    ttivmt: 2867506.0,
    trkttivmt: 408694.0,
  },
  {
    agglevel: "county",
    regioncode: 161,
    year: 2022,
    count: 2850,
    len_sec: 889.7069999999945,
    lanemiles: 2330.569999999991,
    delay: 6399284.0,
    trkdelay: 528146.0,
    pti80: 1.19,
    trkpti80: 1.16,
    pti95: 1.34,
    trkpti95: 1.34,
    tti: 1.08,
    trktti: 1.06,
    ghg: 30491.0,
    congcost: 1.6710055e8,
    allspeed: 51.46,
    trkspeed: 58.46,
    ttivmt: 2986156.0,
    trkttivmt: 387041.0,
  },
  {
    agglevel: "county",
    regioncode: 162,
    year: 2017,
    count: 74,
    len_sec: 168.707,
    lanemiles: 337.414,
    delay: 65240.0,
    trkdelay: 40775.0,
    pti80: 1.06,
    trkpti80: 1.07,
    pti95: 1.08,
    trkpti95: 1.08,
    tti: 1.02,
    trktti: 1.02,
    ghg: 557.0,
    congcost: 2476300.0,
    allspeed: 60.65,
    trkspeed: 61.56,
    ttivmt: 105921.0,
    trkttivmt: 89888.0,
  },
  {
    agglevel: "county",
    regioncode: 162,
    year: 2018,
    count: 171,
    len_sec: 169.9979999999999,
    lanemiles: 339.9959999999998,
    delay: 63201.0,
    trkdelay: 24198.0,
    pti80: 1.08,
    trkpti80: 1.11,
    pti95: 1.1,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.04,
    ghg: 309.0,
    congcost: 1953208.0,
    allspeed: 59.98,
    trkspeed: 59.8,
    ttivmt: 106217.0,
    trkttivmt: 27621.0,
  },
  {
    agglevel: "county",
    regioncode: 162,
    year: 2019,
    count: 33,
    len_sec: 127.85,
    lanemiles: 255.7,
    delay: 42618.0,
    trkdelay: 21413.0,
    pti80: 1.07,
    trkpti80: 1.11,
    pti95: 1.09,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.04,
    ghg: 169.0,
    congcost: 1533564.0,
    allspeed: 61.75,
    trkspeed: 60.49,
    ttivmt: 108413.0,
    trkttivmt: 28192.0,
  },
  {
    agglevel: "county",
    regioncode: 162,
    year: 2020,
    count: 227,
    len_sec: 169.98400000000004,
    lanemiles: 339.9680000000001,
    delay: 96551.0,
    trkdelay: 34014.0,
    pti80: 1.09,
    trkpti80: 1.14,
    pti95: 1.11,
    trkpti95: 1.2,
    tti: 1.03,
    trktti: 1.06,
    ghg: 412.0,
    congcost: 3140399.0,
    allspeed: 60.54,
    trkspeed: 60.22,
    ttivmt: 122702.0,
    trkttivmt: 28415.0,
  },
  {
    agglevel: "county",
    regioncode: 162,
    year: 2021,
    count: 222,
    len_sec: 169.98599999999996,
    lanemiles: 339.9719999999999,
    delay: 49755.0,
    trkdelay: 21925.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.04,
    ghg: 244.0,
    congcost: 1693533.0,
    allspeed: 60.99,
    trkspeed: 60.9,
    ttivmt: 94307.0,
    trkttivmt: 26739.0,
  },
  {
    agglevel: "county",
    regioncode: 162,
    year: 2022,
    count: 228,
    len_sec: 169.98399999999998,
    lanemiles: 344.883,
    delay: 56447.0,
    trkdelay: 24598.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.15,
    trkpti95: 1.2,
    tti: 1.03,
    trktti: 1.05,
    ghg: 285.0,
    congcost: 2144326.0,
    allspeed: 61.42,
    trkspeed: 61.0,
    ttivmt: 72201.0,
    trkttivmt: 23246.0,
  },
  {
    agglevel: "county",
    regioncode: 163,
    year: 2017,
    count: 670,
    len_sec: 374.9219999999999,
    lanemiles: 864.8800000000001,
    delay: 603078.0,
    trkdelay: 71032.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.03,
    ghg: 2789.0,
    congcost: 1.3376195e7,
    allspeed: 56.14,
    trkspeed: 59.04,
    ttivmt: 555143.0,
    trkttivmt: 103107.0,
  },
  {
    agglevel: "county",
    regioncode: 163,
    year: 2018,
    count: 691,
    len_sec: 374.9280000000005,
    lanemiles: 864.8450000000003,
    delay: 616033.0,
    trkdelay: 59051.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.03,
    ghg: 2560.0,
    congcost: 1.3506843e7,
    allspeed: 55.66,
    trkspeed: 59.34,
    ttivmt: 566435.0,
    trkttivmt: 82440.0,
  },
  {
    agglevel: "county",
    regioncode: 163,
    year: 2019,
    count: 187,
    len_sec: 223.85200000000003,
    lanemiles: 561.0210000000001,
    delay: 532182.0,
    trkdelay: 63205.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 2660.0,
    congcost: 1.2624265e7,
    allspeed: 57.48,
    trkspeed: 58.12,
    ttivmt: 535635.0,
    trkttivmt: 83133.0,
  },
  {
    agglevel: "county",
    regioncode: 163,
    year: 2020,
    count: 972,
    len_sec: 375.008,
    lanemiles: 864.5159999999993,
    delay: 723679.0,
    trkdelay: 75146.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.04,
    ghg: 3400.0,
    congcost: 1.7290523e7,
    allspeed: 56.71,
    trkspeed: 60.3,
    ttivmt: 582476.0,
    trkttivmt: 88727.0,
  },
  {
    agglevel: "county",
    regioncode: 163,
    year: 2021,
    count: 958,
    len_sec: 373.5919999999995,
    lanemiles: 863.9059999999996,
    delay: 678290.0,
    trkdelay: 80128.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.05,
    trktti: 1.04,
    ghg: 3130.0,
    congcost: 1.6654285e7,
    allspeed: 56.09,
    trkspeed: 60.22,
    ttivmt: 555648.0,
    trkttivmt: 90429.0,
  },
  {
    agglevel: "county",
    regioncode: 163,
    year: 2022,
    count: 945,
    len_sec: 373.9449999999997,
    lanemiles: 863.849,
    delay: 878573.0,
    trkdelay: 90380.0,
    pti80: 1.14,
    trkpti80: 1.15,
    pti95: 1.23,
    trkpti95: 1.25,
    tti: 1.06,
    trktti: 1.06,
    ghg: 3108.0,
    congcost: 2.3235341e7,
    allspeed: 56.1,
    trkspeed: 59.54,
    ttivmt: 660483.0,
    trkttivmt: 95120.0,
  },
  {
    agglevel: "county",
    regioncode: 164,
    year: 2017,
    count: 117,
    len_sec: 182.44899999999996,
    lanemiles: 373.28,
    delay: 20505.0,
    trkdelay: 8025.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 83.0,
    congcost: 620025.0,
    allspeed: 60.92,
    trkspeed: 63.18,
    ttivmt: 60957.0,
    trkttivmt: 34311.0,
  },
  {
    agglevel: "county",
    regioncode: 164,
    year: 2018,
    count: 200,
    len_sec: 179.97699999999983,
    lanemiles: 368.47099999999983,
    delay: 19401.0,
    trkdelay: 5254.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.09,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.02,
    ghg: 88.0,
    congcost: 531062.0,
    allspeed: 60.62,
    trkspeed: 60.66,
    ttivmt: 57697.0,
    trkttivmt: 12474.0,
  },
  {
    agglevel: "county",
    regioncode: 164,
    year: 2019,
    count: 44,
    len_sec: 123.61899999999997,
    lanemiles: 255.89499999999992,
    delay: 22008.0,
    trkdelay: 9071.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 123.0,
    congcost: 734624.0,
    allspeed: 61.1,
    trkspeed: 60.04,
    ttivmt: 60596.0,
    trkttivmt: 17074.0,
  },
  {
    agglevel: "county",
    regioncode: 164,
    year: 2020,
    count: 255,
    len_sec: 182.08999999999992,
    lanemiles: 372.69699999999983,
    delay: 39096.0,
    trkdelay: 10069.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.03,
    ghg: 123.0,
    congcost: 1128746.0,
    allspeed: 60.21,
    trkspeed: 60.54,
    ttivmt: 68768.0,
    trkttivmt: 16752.0,
  },
  {
    agglevel: "county",
    regioncode: 164,
    year: 2021,
    count: 256,
    len_sec: 181.94999999999987,
    lanemiles: 372.55699999999985,
    delay: 17822.0,
    trkdelay: 4717.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 91.0,
    congcost: 517098.0,
    allspeed: 60.59,
    trkspeed: 61.29,
    ttivmt: 62098.0,
    trkttivmt: 16211.0,
  },
  {
    agglevel: "county",
    regioncode: 164,
    year: 2022,
    count: 256,
    len_sec: 181.94900000000004,
    lanemiles: 372.55499999999995,
    delay: 33073.0,
    trkdelay: 8809.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 117.0,
    congcost: 1057496.0,
    allspeed: 60.4,
    trkspeed: 60.78,
    ttivmt: 63430.0,
    trkttivmt: 14062.0,
  },
  {
    agglevel: "county",
    regioncode: 165,
    year: 2017,
    count: 976,
    len_sec: 573.1469999999997,
    lanemiles: 1537.9560000000008,
    delay: 4654355.0,
    trkdelay: 267409.0,
    pti80: 1.13,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.15,
    tti: 1.06,
    trktti: 1.04,
    ghg: 19434.0,
    congcost: 9.4591871e7,
    allspeed: 48.82,
    trkspeed: 55.05,
    ttivmt: 3037302.0,
    trkttivmt: 302581.0,
  },
  {
    agglevel: "county",
    regioncode: 165,
    year: 2018,
    count: 781,
    len_sec: 581.4089999999995,
    lanemiles: 1549.7269999999994,
    delay: 3800372.0,
    trkdelay: 333476.0,
    pti80: 1.15,
    trkpti80: 1.18,
    pti95: 1.18,
    trkpti95: 1.23,
    tti: 1.07,
    trktti: 1.08,
    ghg: 18254.0,
    congcost: 8.2903832e7,
    allspeed: 51.65,
    trkspeed: 55.52,
    ttivmt: 1997711.0,
    trkttivmt: 193273.0,
  },
  {
    agglevel: "county",
    regioncode: 165,
    year: 2019,
    count: 606,
    len_sec: 574.6599999999996,
    lanemiles: 1539.6339999999989,
    delay: 5113922.0,
    trkdelay: 615983.0,
    pti80: 1.18,
    trkpti80: 1.19,
    pti95: 1.21,
    trkpti95: 1.25,
    tti: 1.09,
    trktti: 1.09,
    ghg: 27451.0,
    congcost: 1.22044476e8,
    allspeed: 50.63,
    trkspeed: 52.76,
    ttivmt: 2216690.0,
    trkttivmt: 309046.0,
  },
  {
    agglevel: "county",
    regioncode: 165,
    year: 2020,
    count: 1182,
    len_sec: 579.7339999999988,
    lanemiles: 1549.6119999999958,
    delay: 5405882.0,
    trkdelay: 777121.0,
    pti80: 1.19,
    trkpti80: 1.21,
    pti95: 1.22,
    trkpti95: 1.27,
    tti: 1.1,
    trktti: 1.1,
    ghg: 31767.0,
    congcost: 1.38073191e8,
    allspeed: 50.01,
    trkspeed: 54.47,
    ttivmt: 2222697.0,
    trkttivmt: 353078.0,
  },
  {
    agglevel: "county",
    regioncode: 165,
    year: 2021,
    count: 1155,
    len_sec: 578.5969999999988,
    lanemiles: 1547.4699999999964,
    delay: 3190785.0,
    trkdelay: 543049.0,
    pti80: 1.15,
    trkpti80: 1.18,
    pti95: 1.2,
    trkpti95: 1.3,
    tti: 1.06,
    trktti: 1.08,
    ghg: 19502.0,
    congcost: 8.4054858e7,
    allspeed: 50.52,
    trkspeed: 56.12,
    ttivmt: 1898616.0,
    trkttivmt: 283855.0,
  },
  {
    agglevel: "county",
    regioncode: 165,
    year: 2022,
    count: 1204,
    len_sec: 578.9869999999992,
    lanemiles: 1548.4679999999964,
    delay: 3334765.0,
    trkdelay: 345781.0,
    pti80: 1.15,
    trkpti80: 1.12,
    pti95: 1.19,
    trkpti95: 1.16,
    tti: 1.06,
    trktti: 1.04,
    ghg: 17618.0,
    congcost: 8.9930929e7,
    allspeed: 50.88,
    trkspeed: 57.01,
    ttivmt: 1995760.0,
    trkttivmt: 321774.0,
  },
  {
    agglevel: "county",
    regioncode: 166,
    year: 2017,
    count: 532,
    len_sec: 369.06400000000036,
    lanemiles: 760.5490000000004,
    delay: 323827.0,
    trkdelay: 38166.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.08,
    tti: 1.03,
    trktti: 1.02,
    ghg: 1324.0,
    congcost: 7156878.0,
    allspeed: 55.84,
    trkspeed: 59.88,
    ttivmt: 355755.0,
    trkttivmt: 70368.0,
  },
  {
    agglevel: "county",
    regioncode: 166,
    year: 2018,
    count: 646,
    len_sec: 368.23399999999964,
    lanemiles: 775.6959999999995,
    delay: 277866.0,
    trkdelay: 50244.0,
    pti80: 1.08,
    trkpti80: 1.1,
    pti95: 1.09,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1465.0,
    congcost: 6866366.0,
    allspeed: 56.09,
    trkspeed: 57.25,
    ttivmt: 350109.0,
    trkttivmt: 60091.0,
  },
  {
    agglevel: "county",
    regioncode: 166,
    year: 2019,
    count: 172,
    len_sec: 272.57099999999997,
    lanemiles: 584.374,
    delay: 227018.0,
    trkdelay: 52339.0,
    pti80: 1.08,
    trkpti80: 1.1,
    pti95: 1.09,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1360.0,
    congcost: 6249787.0,
    allspeed: 58.4,
    trkspeed: 57.29,
    ttivmt: 311540.0,
    trkttivmt: 58137.0,
  },
  {
    agglevel: "county",
    regioncode: 166,
    year: 2020,
    count: 918,
    len_sec: 368.32199999999955,
    lanemiles: 780.1179999999987,
    delay: 272356.0,
    trkdelay: 53388.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.1,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1568.0,
    congcost: 7452137.0,
    allspeed: 57.74,
    trkspeed: 58.28,
    ttivmt: 325108.0,
    trkttivmt: 62349.0,
  },
  {
    agglevel: "county",
    regioncode: 166,
    year: 2021,
    count: 906,
    len_sec: 368.1249999999998,
    lanemiles: 780.0859999999996,
    delay: 219455.0,
    trkdelay: 31171.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.03,
    ghg: 919.0,
    congcost: 5528612.0,
    allspeed: 57.6,
    trkspeed: 58.93,
    ttivmt: 332079.0,
    trkttivmt: 50905.0,
  },
  {
    agglevel: "county",
    regioncode: 166,
    year: 2022,
    count: 905,
    len_sec: 368.1300000000002,
    lanemiles: 780.0919999999999,
    delay: 347318.0,
    trkdelay: 48317.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1371.0,
    congcost: 9648080.0,
    allspeed: 57.09,
    trkspeed: 58.65,
    ttivmt: 356084.0,
    trkttivmt: 51851.0,
  },
  {
    agglevel: "county",
    regioncode: 167,
    year: 2017,
    count: 244,
    len_sec: 230.61399999999986,
    lanemiles: 492.9809999999998,
    delay: 59156.0,
    trkdelay: 8488.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 231.0,
    congcost: 1352245.0,
    allspeed: 60.25,
    trkspeed: 60.68,
    ttivmt: 111510.0,
    trkttivmt: 17487.0,
  },
  {
    agglevel: "county",
    regioncode: 167,
    year: 2018,
    count: 271,
    len_sec: 230.168,
    lanemiles: 491.88000000000005,
    delay: 58309.0,
    trkdelay: 12376.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.04,
    ghg: 287.0,
    congcost: 1495584.0,
    allspeed: 56.84,
    trkspeed: 56.51,
    ttivmt: 119514.0,
    trkttivmt: 15527.0,
  },
  {
    agglevel: "county",
    regioncode: 167,
    year: 2019,
    count: 98,
    len_sec: 183.65800000000002,
    lanemiles: 398.86,
    delay: 54374.0,
    trkdelay: 13479.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 317.0,
    congcost: 1529269.0,
    allspeed: 60.38,
    trkspeed: 58.99,
    ttivmt: 94500.0,
    trkttivmt: 13977.0,
  },
  {
    agglevel: "county",
    regioncode: 167,
    year: 2020,
    count: 393,
    len_sec: 230.1809999999999,
    lanemiles: 504.42799999999977,
    delay: 65485.0,
    trkdelay: 12246.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.04,
    ghg: 334.0,
    congcost: 1763076.0,
    allspeed: 59.8,
    trkspeed: 59.41,
    ttivmt: 101985.0,
    trkttivmt: 13908.0,
  },
  {
    agglevel: "county",
    regioncode: 167,
    year: 2021,
    count: 389,
    len_sec: 230.18099999999987,
    lanemiles: 504.42800000000005,
    delay: 55132.0,
    trkdelay: 13897.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 343.0,
    congcost: 1587788.0,
    allspeed: 59.65,
    trkspeed: 59.36,
    ttivmt: 89442.0,
    trkttivmt: 16030.0,
  },
  {
    agglevel: "county",
    regioncode: 167,
    year: 2022,
    count: 390,
    len_sec: 230.2819999999999,
    lanemiles: 504.7179999999998,
    delay: 73203.0,
    trkdelay: 15817.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.15,
    trkpti95: 1.21,
    tti: 1.03,
    trktti: 1.05,
    ghg: 326.0,
    congcost: 2236029.0,
    allspeed: 59.23,
    trkspeed: 58.77,
    ttivmt: 100835.0,
    trkttivmt: 13527.0,
  },
  {
    agglevel: "county",
    regioncode: 168,
    year: 2017,
    count: 286,
    len_sec: 307.5840000000001,
    lanemiles: 682.9639999999998,
    delay: 169453.0,
    trkdelay: 49567.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 923.0,
    congcost: 4658453.0,
    allspeed: 61.59,
    trkspeed: 63.81,
    ttivmt: 274656.0,
    trkttivmt: 159274.0,
  },
  {
    agglevel: "county",
    regioncode: 168,
    year: 2018,
    count: 357,
    len_sec: 306.97999999999985,
    lanemiles: 681.5219999999996,
    delay: 59002.0,
    trkdelay: 6819.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.0,
    ghg: 269.0,
    congcost: 1335308.0,
    allspeed: 62.53,
    trkspeed: 63.88,
    ttivmt: 283092.0,
    trkttivmt: 67928.0,
  },
  {
    agglevel: "county",
    regioncode: 168,
    year: 2019,
    count: 188,
    len_sec: 307.64499999999987,
    lanemiles: 682.8519999999999,
    delay: 104454.0,
    trkdelay: 27340.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.16,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 727.0,
    congcost: 3008618.0,
    allspeed: 63.08,
    trkspeed: 61.48,
    ttivmt: 315754.0,
    trkttivmt: 96064.0,
  },
  {
    agglevel: "county",
    regioncode: 168,
    year: 2020,
    count: 485,
    len_sec: 308.24999999999966,
    lanemiles: 684.0599999999991,
    delay: 94313.0,
    trkdelay: 9331.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.0,
    ghg: 398.0,
    congcost: 2231435.0,
    allspeed: 63.17,
    trkspeed: 64.31,
    ttivmt: 332852.0,
    trkttivmt: 83931.0,
  },
  {
    agglevel: "county",
    regioncode: 168,
    year: 2021,
    count: 480,
    len_sec: 308.66399999999976,
    lanemiles: 684.8879999999999,
    delay: 66527.0,
    trkdelay: 8150.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.0,
    ghg: 295.0,
    congcost: 1642615.0,
    allspeed: 63.13,
    trkspeed: 64.38,
    ttivmt: 282668.0,
    trkttivmt: 94919.0,
  },
  {
    agglevel: "county",
    regioncode: 168,
    year: 2022,
    count: 481,
    len_sec: 308.65699999999987,
    lanemiles: 684.8759999999995,
    delay: 78125.0,
    trkdelay: 10214.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.01,
    trktti: 1.0,
    ghg: 346.0,
    congcost: 2162142.0,
    allspeed: 62.93,
    trkspeed: 64.35,
    ttivmt: 299432.0,
    trkttivmt: 97938.0,
  },
  {
    agglevel: "county",
    regioncode: 169,
    year: 2017,
    count: 471,
    len_sec: 389.7339999999998,
    lanemiles: 860.937999999999,
    delay: 213170.0,
    trkdelay: 26834.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.01,
    ghg: 981.0,
    congcost: 4780454.0,
    allspeed: 58.25,
    trkspeed: 62.81,
    ttivmt: 278086.0,
    trkttivmt: 109252.0,
  },
  {
    agglevel: "county",
    regioncode: 169,
    year: 2018,
    count: 511,
    len_sec: 388.58799999999985,
    lanemiles: 854.9849999999986,
    delay: 212488.0,
    trkdelay: 31097.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1029.0,
    congcost: 5013452.0,
    allspeed: 57.79,
    trkspeed: 58.7,
    ttivmt: 278335.0,
    trkttivmt: 45069.0,
  },
  {
    agglevel: "county",
    regioncode: 169,
    year: 2019,
    count: 254,
    len_sec: 388.3170000000001,
    lanemiles: 854.4359999999999,
    delay: 211478.0,
    trkdelay: 38618.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 1219.0,
    congcost: 5487880.0,
    allspeed: 59.65,
    trkspeed: 59.68,
    ttivmt: 300458.0,
    trkttivmt: 49986.0,
  },
  {
    agglevel: "county",
    regioncode: 169,
    year: 2020,
    count: 718,
    len_sec: 389.4309999999995,
    lanemiles: 862.9129999999988,
    delay: 224174.0,
    trkdelay: 31405.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1163.0,
    congcost: 5664930.0,
    allspeed: 59.65,
    trkspeed: 61.42,
    ttivmt: 306540.0,
    trkttivmt: 49497.0,
  },
  {
    agglevel: "county",
    regioncode: 169,
    year: 2021,
    count: 707,
    len_sec: 389.4109999999999,
    lanemiles: 865.0389999999999,
    delay: 154418.0,
    trkdelay: 26774.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.02,
    ghg: 771.0,
    congcost: 4054185.0,
    allspeed: 59.58,
    trkspeed: 61.67,
    ttivmt: 290103.0,
    trkttivmt: 56815.0,
  },
  {
    agglevel: "county",
    regioncode: 169,
    year: 2022,
    count: 724,
    len_sec: 390.44099999999975,
    lanemiles: 867.0289999999994,
    delay: 248013.0,
    trkdelay: 37892.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.17,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1041.0,
    congcost: 7020611.0,
    allspeed: 58.93,
    trkspeed: 60.66,
    ttivmt: 322189.0,
    trkttivmt: 56814.0,
  },
  {
    agglevel: "county",
    regioncode: 170,
    year: 2017,
    count: 1354,
    len_sec: 736.5869999999999,
    lanemiles: 1813.125,
    delay: 1.594547e7,
    trkdelay: 732134.0,
    pti80: 1.3,
    trkpti80: 1.27,
    pti95: 1.4,
    trkpti95: 1.37,
    tti: 1.15,
    trktti: 1.12,
    ghg: 62839.0,
    congcost: 3.17947809e8,
    allspeed: 43.45,
    trkspeed: 51.19,
    ttivmt: 5410955.0,
    trkttivmt: 403041.0,
  },
  {
    agglevel: "county",
    regioncode: 170,
    year: 2018,
    count: 1382,
    len_sec: 816.5539999999996,
    lanemiles: 2038.9869999999985,
    delay: 1.5970673e7,
    trkdelay: 749158.0,
    pti80: 1.29,
    trkpti80: 1.28,
    pti95: 1.37,
    trkpti95: 1.38,
    tti: 1.15,
    trktti: 1.14,
    ghg: 64637.0,
    congcost: 3.26063926e8,
    allspeed: 48.32,
    trkspeed: 52.51,
    ttivmt: 5184694.0,
    trkttivmt: 319652.0,
  },
  {
    agglevel: "county",
    regioncode: 170,
    year: 2019,
    count: 668,
    len_sec: 581.6750000000005,
    lanemiles: 1556.1200000000003,
    delay: 1.4666612e7,
    trkdelay: 744962.0,
    pti80: 1.28,
    trkpti80: 1.27,
    pti95: 1.35,
    trkpti95: 1.36,
    tti: 1.15,
    trktti: 1.13,
    ghg: 60009.0,
    congcost: 3.1231693e8,
    allspeed: 49.2,
    trkspeed: 52.41,
    ttivmt: 5035412.0,
    trkttivmt: 326973.0,
  },
  {
    agglevel: "county",
    regioncode: 170,
    year: 2020,
    count: 2284,
    len_sec: 807.6779999999965,
    lanemiles: 2044.7399999999889,
    delay: 1.5762028e7,
    trkdelay: 821749.0,
    pti80: 1.29,
    trkpti80: 1.28,
    pti95: 1.36,
    trkpti95: 1.36,
    tti: 1.15,
    trktti: 1.14,
    ghg: 64704.0,
    congcost: 3.457969e8,
    allspeed: 48.23,
    trkspeed: 53.14,
    ttivmt: 5529905.0,
    trkttivmt: 348870.0,
  },
  {
    agglevel: "county",
    regioncode: 170,
    year: 2021,
    count: 2378,
    len_sec: 818.0029999999962,
    lanemiles: 2124.104999999989,
    delay: 8454223.0,
    trkdelay: 585680.0,
    pti80: 1.18,
    trkpti80: 1.21,
    pti95: 1.23,
    trkpti95: 1.33,
    tti: 1.08,
    trktti: 1.1,
    ghg: 36781.0,
    congcost: 1.95068923e8,
    allspeed: 50.05,
    trkspeed: 54.39,
    ttivmt: 5037763.0,
    trkttivmt: 311027.0,
  },
  {
    agglevel: "county",
    regioncode: 170,
    year: 2022,
    count: 2431,
    len_sec: 838.0509999999947,
    lanemiles: 2279.933999999993,
    delay: 1.5044504e7,
    trkdelay: 858699.0,
    pti80: 1.3,
    trkpti80: 1.3,
    pti95: 1.4,
    trkpti95: 1.43,
    tti: 1.15,
    trktti: 1.13,
    ghg: 61720.0,
    congcost: 3.77403137e8,
    allspeed: 47.36,
    trkspeed: 52.31,
    ttivmt: 5419635.0,
    trkttivmt: 388615.0,
  },
  {
    agglevel: "county",
    regioncode: 171,
    year: 2017,
    count: 244,
    len_sec: 220.17799999999994,
    lanemiles: 510.623,
    delay: 263238.0,
    trkdelay: 28935.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.04,
    trktti: 1.02,
    ghg: 1407.0,
    congcost: 5815539.0,
    allspeed: 53.62,
    trkspeed: 60.4,
    ttivmt: 205925.0,
    trkttivmt: 54004.0,
  },
  {
    agglevel: "county",
    regioncode: 171,
    year: 2018,
    count: 232,
    len_sec: 220.7020000000001,
    lanemiles: 518.012,
    delay: 236276.0,
    trkdelay: 50520.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.04,
    ghg: 1663.0,
    congcost: 6157999.0,
    allspeed: 55.86,
    trkspeed: 57.48,
    ttivmt: 210984.0,
    trkttivmt: 52959.0,
  },
  {
    agglevel: "county",
    regioncode: 171,
    year: 2019,
    count: 140,
    len_sec: 220.69799999999995,
    lanemiles: 519.3679999999999,
    delay: 219819.0,
    trkdelay: 49049.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1579.0,
    congcost: 6068849.0,
    allspeed: 58.25,
    trkspeed: 57.6,
    ttivmt: 216749.0,
    trkttivmt: 49542.0,
  },
  {
    agglevel: "county",
    regioncode: 171,
    year: 2020,
    count: 306,
    len_sec: 220.70499999999998,
    lanemiles: 526.1329999999997,
    delay: 216619.0,
    trkdelay: 41103.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1477.0,
    congcost: 5928018.0,
    allspeed: 58.39,
    trkspeed: 58.99,
    ttivmt: 220259.0,
    trkttivmt: 44473.0,
  },
  {
    agglevel: "county",
    regioncode: 171,
    year: 2021,
    count: 286,
    len_sec: 219.40900000000005,
    lanemiles: 524.7389999999998,
    delay: 200794.0,
    trkdelay: 53620.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1685.0,
    congcost: 5945754.0,
    allspeed: 58.29,
    trkspeed: 58.91,
    ttivmt: 226503.0,
    trkttivmt: 60005.0,
  },
  {
    agglevel: "county",
    regioncode: 171,
    year: 2022,
    count: 295,
    len_sec: 219.4040000000002,
    lanemiles: 524.729,
    delay: 277915.0,
    trkdelay: 74345.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.17,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.04,
    ghg: 1723.0,
    congcost: 9072243.0,
    allspeed: 57.91,
    trkspeed: 58.44,
    ttivmt: 248563.0,
    trkttivmt: 65943.0,
  },
  {
    agglevel: "county",
    regioncode: 172,
    year: 2017,
    count: 259,
    len_sec: 135.2970000000001,
    lanemiles: 359.91200000000003,
    delay: 98408.0,
    trkdelay: 14017.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 369.0,
    congcost: 2239244.0,
    allspeed: 59.43,
    trkspeed: 61.84,
    ttivmt: 203967.0,
    trkttivmt: 34032.0,
  },
  {
    agglevel: "county",
    regioncode: 172,
    year: 2018,
    count: 265,
    len_sec: 136.27599999999993,
    lanemiles: 361.6679999999999,
    delay: 106371.0,
    trkdelay: 19320.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 583.0,
    congcost: 2636878.0,
    allspeed: 58.62,
    trkspeed: 61.15,
    ttivmt: 198612.0,
    trkttivmt: 42157.0,
  },
  {
    agglevel: "county",
    regioncode: 172,
    year: 2019,
    count: 143,
    len_sec: 136.22399999999996,
    lanemiles: 361.6249999999998,
    delay: 101275.0,
    trkdelay: 17649.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 470.0,
    congcost: 2572595.0,
    allspeed: 59.33,
    trkspeed: 62.13,
    ttivmt: 204279.0,
    trkttivmt: 45114.0,
  },
  {
    agglevel: "county",
    regioncode: 172,
    year: 2020,
    count: 383,
    len_sec: 136.2230000000001,
    lanemiles: 361.428,
    delay: 105859.0,
    trkdelay: 14802.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.01,
    ghg: 454.0,
    congcost: 2654132.0,
    allspeed: 59.51,
    trkspeed: 62.94,
    ttivmt: 215615.0,
    trkttivmt: 50862.0,
  },
  {
    agglevel: "county",
    regioncode: 172,
    year: 2021,
    count: 379,
    len_sec: 135.6230000000001,
    lanemiles: 362.7509999999999,
    delay: 84561.0,
    trkdelay: 14063.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.01,
    ghg: 388.0,
    congcost: 2195025.0,
    allspeed: 59.67,
    trkspeed: 62.66,
    ttivmt: 203051.0,
    trkttivmt: 43818.0,
  },
  {
    agglevel: "county",
    regioncode: 172,
    year: 2022,
    count: 374,
    len_sec: 135.6330000000001,
    lanemiles: 361.21299999999997,
    delay: 136722.0,
    trkdelay: 20117.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.01,
    ghg: 546.0,
    congcost: 3837850.0,
    allspeed: 59.06,
    trkspeed: 62.52,
    ttivmt: 194600.0,
    trkttivmt: 53990.0,
  },
  {
    agglevel: "county",
    regioncode: 173,
    year: 2017,
    count: 150,
    len_sec: 167.35700000000008,
    lanemiles: 335.27000000000015,
    delay: 14985.0,
    trkdelay: 2367.0,
    pti80: 1.07,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.03,
    ghg: 55.0,
    congcost: 349056.0,
    allspeed: 60.09,
    trkspeed: 61.26,
    ttivmt: 22308.0,
    trkttivmt: 4485.0,
  },
  {
    agglevel: "county",
    regioncode: 173,
    year: 2018,
    count: 166,
    len_sec: 169.32999999999996,
    lanemiles: 339.2419999999999,
    delay: 11238.0,
    trkdelay: 1796.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.09,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 33.0,
    congcost: 266218.0,
    allspeed: 55.51,
    trkspeed: 56.42,
    ttivmt: 21781.0,
    trkttivmt: 3269.0,
  },
  {
    agglevel: "county",
    regioncode: 173,
    year: 2019,
    count: 79,
    len_sec: 171.10699999999997,
    lanemiles: 342.79599999999994,
    delay: 14521.0,
    trkdelay: 3509.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 57.0,
    congcost: 400469.0,
    allspeed: 60.76,
    trkspeed: 59.76,
    ttivmt: 21093.0,
    trkttivmt: 3788.0,
  },
  {
    agglevel: "county",
    regioncode: 173,
    year: 2020,
    count: 273,
    len_sec: 171.18299999999996,
    lanemiles: 342.9479999999999,
    delay: 18224.0,
    trkdelay: 2591.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 50.0,
    congcost: 455292.0,
    allspeed: 60.01,
    trkspeed: 60.61,
    ttivmt: 19820.0,
    trkttivmt: 3727.0,
  },
  {
    agglevel: "county",
    regioncode: 173,
    year: 2021,
    count: 277,
    len_sec: 171.1610000000002,
    lanemiles: 342.5420000000004,
    delay: 10861.0,
    trkdelay: 2544.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 37.0,
    congcost: 303753.0,
    allspeed: 59.73,
    trkspeed: 59.87,
    ttivmt: 19098.0,
    trkttivmt: 4912.0,
  },
  {
    agglevel: "county",
    regioncode: 173,
    year: 2022,
    count: 277,
    len_sec: 171.16,
    lanemiles: 342.53999999999996,
    delay: 19060.0,
    trkdelay: 3939.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.03,
    ghg: 67.0,
    congcost: 573938.0,
    allspeed: 59.42,
    trkspeed: 60.76,
    ttivmt: 20362.0,
    trkttivmt: 4541.0,
  },
  {
    agglevel: "county",
    regioncode: 174,
    year: 2017,
    count: 768,
    len_sec: 431.7180000000002,
    lanemiles: 1031.9589999999992,
    delay: 1252936.0,
    trkdelay: 110263.0,
    pti80: 1.13,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.12,
    tti: 1.06,
    trktti: 1.03,
    ghg: 6033.0,
    congcost: 2.6741749e7,
    allspeed: 52.02,
    trkspeed: 57.6,
    ttivmt: 700040.0,
    trkttivmt: 143425.0,
  },
  {
    agglevel: "county",
    regioncode: 174,
    year: 2018,
    count: 776,
    len_sec: 430.3289999999995,
    lanemiles: 1029.2289999999987,
    delay: 1221475.0,
    trkdelay: 180676.0,
    pti80: 1.13,
    trkpti80: 1.14,
    pti95: 1.15,
    trkpti95: 1.18,
    tti: 1.06,
    trktti: 1.06,
    ghg: 7471.0,
    congcost: 2.9182739e7,
    allspeed: 52.62,
    trkspeed: 54.58,
    ttivmt: 697552.0,
    trkttivmt: 108172.0,
  },
  {
    agglevel: "county",
    regioncode: 174,
    year: 2019,
    count: 385,
    len_sec: 401.85499999999996,
    lanemiles: 972.7720000000003,
    delay: 1057842.0,
    trkdelay: 177633.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.05,
    ghg: 6816.0,
    congcost: 2.7131278e7,
    allspeed: 54.55,
    trkspeed: 55.03,
    ttivmt: 679949.0,
    trkttivmt: 115778.0,
  },
  {
    agglevel: "county",
    regioncode: 174,
    year: 2020,
    count: 1076,
    len_sec: 430.37000000000006,
    lanemiles: 1029.4529999999993,
    delay: 1222558.0,
    trkdelay: 120757.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.05,
    trktti: 1.05,
    ghg: 6456.0,
    congcost: 2.9149746e7,
    allspeed: 54.18,
    trkspeed: 57.26,
    ttivmt: 750851.0,
    trkttivmt: 101013.0,
  },
  {
    agglevel: "county",
    regioncode: 174,
    year: 2021,
    count: 1080,
    len_sec: 430.3289999999996,
    lanemiles: 1029.837999999998,
    delay: 829290.0,
    trkdelay: 96075.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.04,
    ghg: 4420.0,
    congcost: 2.042426e7,
    allspeed: 54.83,
    trkspeed: 57.92,
    ttivmt: 672199.0,
    trkttivmt: 94178.0,
  },
  {
    agglevel: "county",
    regioncode: 174,
    year: 2022,
    count: 1089,
    len_sec: 430.362,
    lanemiles: 1029.662999999999,
    delay: 812904.0,
    trkdelay: 98567.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.17,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.03,
    ghg: 3606.0,
    congcost: 2.2198721e7,
    allspeed: 55.44,
    trkspeed: 58.84,
    ttivmt: 705872.0,
    trkttivmt: 135692.0,
  },
  {
    agglevel: "county",
    regioncode: 175,
    year: 2017,
    count: 788,
    len_sec: 580.848,
    lanemiles: 1328.2239999999997,
    delay: 1339453.0,
    trkdelay: 167397.0,
    pti80: 1.14,
    trkpti80: 1.12,
    pti95: 1.18,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.04,
    ghg: 6025.0,
    congcost: 2.9961039e7,
    allspeed: 56.55,
    trkspeed: 61.91,
    ttivmt: 983627.0,
    trkttivmt: 231352.0,
  },
  {
    agglevel: "county",
    regioncode: 175,
    year: 2018,
    count: 862,
    len_sec: 580.3889999999993,
    lanemiles: 1327.0810000000004,
    delay: 967219.0,
    trkdelay: 124345.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.09,
    tti: 1.03,
    trktti: 1.03,
    ghg: 4069.0,
    congcost: 2.2172159e7,
    allspeed: 57.16,
    trkspeed: 59.73,
    ttivmt: 931799.0,
    trkttivmt: 162690.0,
  },
  {
    agglevel: "county",
    regioncode: 175,
    year: 2019,
    count: 460,
    len_sec: 573.5949999999993,
    lanemiles: 1313.218999999999,
    delay: 859358.0,
    trkdelay: 129385.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 4194.0,
    congcost: 2.1224638e7,
    allspeed: 59.58,
    trkspeed: 60.26,
    ttivmt: 905874.0,
    trkttivmt: 154834.0,
  },
  {
    agglevel: "county",
    regioncode: 175,
    year: 2020,
    count: 1181,
    len_sec: 580.1669999999999,
    lanemiles: 1387.6229999999991,
    delay: 926456.0,
    trkdelay: 70779.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.01,
    ghg: 4271.0,
    congcost: 2.1228244e7,
    allspeed: 58.92,
    trkspeed: 62.54,
    ttivmt: 911904.0,
    trkttivmt: 163606.0,
  },
  {
    agglevel: "county",
    regioncode: 175,
    year: 2021,
    count: 1236,
    len_sec: 580.8439999999989,
    lanemiles: 1388.8339999999994,
    delay: 530514.0,
    trkdelay: 43706.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.01,
    ghg: 2437.0,
    congcost: 1.2478939e7,
    allspeed: 59.15,
    trkspeed: 62.48,
    ttivmt: 905799.0,
    trkttivmt: 154017.0,
  },
  {
    agglevel: "county",
    regioncode: 175,
    year: 2022,
    count: 1319,
    len_sec: 580.6689999999993,
    lanemiles: 1388.7839999999983,
    delay: 868431.0,
    trkdelay: 73768.0,
    pti80: 1.13,
    trkpti80: 1.06,
    pti95: 1.2,
    trkpti95: 1.08,
    tti: 1.03,
    trktti: 1.02,
    ghg: 3493.0,
    congcost: 2.2578288e7,
    allspeed: 57.83,
    trkspeed: 62.06,
    ttivmt: 897510.0,
    trkttivmt: 168327.0,
  },
  {
    agglevel: "county",
    regioncode: 176,
    year: 2017,
    count: 324,
    len_sec: 274.582,
    lanemiles: 554.3159999999998,
    delay: 114913.0,
    trkdelay: 16227.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.03,
    ghg: 440.0,
    congcost: 2611721.0,
    allspeed: 58.67,
    trkspeed: 59.33,
    ttivmt: 139394.0,
    trkttivmt: 23669.0,
  },
  {
    agglevel: "county",
    regioncode: 176,
    year: 2018,
    count: 450,
    len_sec: 273.09699999999964,
    lanemiles: 554.4549999999994,
    delay: 120605.0,
    trkdelay: 19226.0,
    pti80: 1.09,
    trkpti80: 1.13,
    pti95: 1.12,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.05,
    ghg: 523.0,
    congcost: 2882843.0,
    allspeed: 56.19,
    trkspeed: 57.02,
    ttivmt: 138884.0,
    trkttivmt: 18170.0,
  },
  {
    agglevel: "county",
    regioncode: 176,
    year: 2019,
    count: 90,
    len_sec: 151.23199999999997,
    lanemiles: 310.725,
    delay: 83142.0,
    trkdelay: 17043.0,
    pti80: 1.09,
    trkpti80: 1.13,
    pti95: 1.11,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.05,
    ghg: 392.0,
    congcost: 2198057.0,
    allspeed: 59.78,
    trkspeed: 57.82,
    ttivmt: 115969.0,
    trkttivmt: 14404.0,
  },
  {
    agglevel: "county",
    regioncode: 176,
    year: 2020,
    count: 621,
    len_sec: 273.41199999999986,
    lanemiles: 555.0829999999996,
    delay: 124008.0,
    trkdelay: 15464.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.05,
    ghg: 473.0,
    congcost: 3029988.0,
    allspeed: 58.1,
    trkspeed: 58.6,
    ttivmt: 147574.0,
    trkttivmt: 14963.0,
  },
  {
    agglevel: "county",
    regioncode: 176,
    year: 2021,
    count: 616,
    len_sec: 273.4239999999998,
    lanemiles: 555.1049999999996,
    delay: 92838.0,
    trkdelay: 12938.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 344.0,
    congcost: 2325517.0,
    allspeed: 58.4,
    trkspeed: 58.24,
    ttivmt: 144237.0,
    trkttivmt: 15779.0,
  },
  {
    agglevel: "county",
    regioncode: 176,
    year: 2022,
    count: 615,
    len_sec: 273.4330000000001,
    lanemiles: 555.123,
    delay: 180298.0,
    trkdelay: 20866.0,
    pti80: 1.12,
    trkpti80: 1.14,
    pti95: 1.21,
    trkpti95: 1.22,
    tti: 1.05,
    trktti: 1.05,
    ghg: 674.0,
    congcost: 4862005.0,
    allspeed: 57.51,
    trkspeed: 58.0,
    ttivmt: 155534.0,
    trkttivmt: 18727.0,
  },
  {
    agglevel: "county",
    regioncode: 177,
    year: 2017,
    count: 422,
    len_sec: 312.10899999999987,
    lanemiles: 730.8679999999998,
    delay: 252879.0,
    trkdelay: 66064.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.01,
    ghg: 1947.0,
    congcost: 6819155.0,
    allspeed: 61.01,
    trkspeed: 63.0,
    ttivmt: 451817.0,
    trkttivmt: 178335.0,
  },
  {
    agglevel: "county",
    regioncode: 177,
    year: 2018,
    count: 452,
    len_sec: 312.3929999999999,
    lanemiles: 731.8480000000003,
    delay: 209894.0,
    trkdelay: 51763.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1289.0,
    congcost: 5641357.0,
    allspeed: 61.16,
    trkspeed: 61.85,
    ttivmt: 470212.0,
    trkttivmt: 109365.0,
  },
  {
    agglevel: "county",
    regioncode: 177,
    year: 2019,
    count: 233,
    len_sec: 305.619,
    lanemiles: 718.4689999999997,
    delay: 232460.0,
    trkdelay: 76470.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1853.0,
    congcost: 7255428.0,
    allspeed: 62.11,
    trkspeed: 58.93,
    ttivmt: 503344.0,
    trkttivmt: 136257.0,
  },
  {
    agglevel: "county",
    regioncode: 177,
    year: 2020,
    count: 603,
    len_sec: 312.75799999999987,
    lanemiles: 731.5409999999988,
    delay: 219519.0,
    trkdelay: 28453.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1038.0,
    congcost: 5444178.0,
    allspeed: 62.36,
    trkspeed: 63.52,
    ttivmt: 519555.0,
    trkttivmt: 124175.0,
  },
  {
    agglevel: "county",
    regioncode: 177,
    year: 2021,
    count: 602,
    len_sec: 312.7130000000001,
    lanemiles: 731.3500000000001,
    delay: 198522.0,
    trkdelay: 25303.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 876.0,
    congcost: 4918858.0,
    allspeed: 62.16,
    trkspeed: 63.44,
    ttivmt: 444821.0,
    trkttivmt: 116122.0,
  },
  {
    agglevel: "county",
    regioncode: 177,
    year: 2022,
    count: 604,
    len_sec: 312.7329999999998,
    lanemiles: 731.4199999999998,
    delay: 309806.0,
    trkdelay: 61853.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1434.0,
    congcost: 9282718.0,
    allspeed: 61.29,
    trkspeed: 62.86,
    ttivmt: 499496.0,
    trkttivmt: 140120.0,
  },
  {
    agglevel: "county",
    regioncode: 178,
    year: 2017,
    count: 1736,
    len_sec: 825.8119999999985,
    lanemiles: 2277.919,
    delay: 1.1131719e7,
    trkdelay: 649142.0,
    pti80: 1.19,
    trkpti80: 1.16,
    pti95: 1.24,
    trkpti95: 1.22,
    tti: 1.09,
    trktti: 1.06,
    ghg: 49037.0,
    congcost: 2.27095164e8,
    allspeed: 46.82,
    trkspeed: 52.2,
    ttivmt: 4437259.0,
    trkttivmt: 424202.0,
  },
  {
    agglevel: "county",
    regioncode: 178,
    year: 2018,
    count: 1759,
    len_sec: 854.8899999999992,
    lanemiles: 2370.6159999999986,
    delay: 1.0999386e7,
    trkdelay: 487927.0,
    pti80: 1.22,
    trkpti80: 1.21,
    pti95: 1.27,
    trkpti95: 1.28,
    tti: 1.11,
    trktti: 1.09,
    ghg: 48392.0,
    congcost: 2.24720639e8,
    allspeed: 49.25,
    trkspeed: 52.5,
    ttivmt: 3605258.0,
    trkttivmt: 223518.0,
  },
  {
    agglevel: "county",
    regioncode: 178,
    year: 2019,
    count: 853,
    len_sec: 667.5100000000002,
    lanemiles: 1967.3449999999991,
    delay: 1.0755128e7,
    trkdelay: 676974.0,
    pti80: 1.21,
    trkpti80: 1.21,
    pti95: 1.24,
    trkpti95: 1.28,
    tti: 1.1,
    trktti: 1.1,
    ghg: 51768.0,
    congcost: 2.35393029e8,
    allspeed: 49.56,
    trkspeed: 49.91,
    ttivmt: 3509558.0,
    trkttivmt: 247857.0,
  },
  {
    agglevel: "county",
    regioncode: 178,
    year: 2020,
    count: 2518,
    len_sec: 876.4029999999967,
    lanemiles: 2417.2979999999907,
    delay: 1.0244706e7,
    trkdelay: 670483.0,
    pti80: 1.19,
    trkpti80: 1.2,
    pti95: 1.23,
    trkpti95: 1.27,
    tti: 1.09,
    trktti: 1.1,
    ghg: 50580.0,
    congcost: 2.31621707e8,
    allspeed: 49.51,
    trkspeed: 51.07,
    ttivmt: 3683548.0,
    trkttivmt: 245347.0,
  },
  {
    agglevel: "county",
    regioncode: 178,
    year: 2021,
    count: 2506,
    len_sec: 877.0509999999972,
    lanemiles: 2467.510999999991,
    delay: 5624143.0,
    trkdelay: 589649.0,
    pti80: 1.13,
    trkpti80: 1.19,
    pti95: 1.18,
    trkpti95: 1.32,
    tti: 1.05,
    trktti: 1.09,
    ghg: 31067.0,
    congcost: 1.36925471e8,
    allspeed: 50.2,
    trkspeed: 51.31,
    ttivmt: 3354758.0,
    trkttivmt: 232721.0,
  },
  {
    agglevel: "county",
    regioncode: 178,
    year: 2022,
    count: 2618,
    len_sec: 877.0569999999965,
    lanemiles: 2465.9519999999898,
    delay: 1.0379413e7,
    trkdelay: 638422.0,
    pti80: 1.18,
    trkpti80: 1.2,
    pti95: 1.23,
    trkpti95: 1.32,
    tti: 1.08,
    trktti: 1.08,
    ghg: 50561.0,
    congcost: 2.64268557e8,
    allspeed: 49.07,
    trkspeed: 51.12,
    ttivmt: 3634951.0,
    trkttivmt: 250379.0,
  },
  {
    agglevel: "county",
    regioncode: 179,
    year: 2017,
    count: 238,
    len_sec: 216.75799999999995,
    lanemiles: 438.32199999999983,
    delay: 96232.0,
    trkdelay: 17701.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.02,
    ghg: 544.0,
    congcost: 2341785.0,
    allspeed: 54.39,
    trkspeed: 60.8,
    ttivmt: 94836.0,
    trkttivmt: 36882.0,
  },
  {
    agglevel: "county",
    regioncode: 179,
    year: 2018,
    count: 218,
    len_sec: 217.047,
    lanemiles: 439.3100000000001,
    delay: 98704.0,
    trkdelay: 21243.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 591.0,
    congcost: 2557284.0,
    allspeed: 55.88,
    trkspeed: 57.61,
    ttivmt: 104529.0,
    trkttivmt: 19859.0,
  },
  {
    agglevel: "county",
    regioncode: 179,
    year: 2019,
    count: 111,
    len_sec: 217.061,
    lanemiles: 441.66100000000006,
    delay: 79773.0,
    trkdelay: 26520.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.05,
    ghg: 689.0,
    congcost: 2513107.0,
    allspeed: 57.36,
    trkspeed: 56.37,
    ttivmt: 93778.0,
    trkttivmt: 21354.0,
  },
  {
    agglevel: "county",
    regioncode: 179,
    year: 2020,
    count: 312,
    len_sec: 217.0639999999999,
    lanemiles: 441.66699999999975,
    delay: 104370.0,
    trkdelay: 29460.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.12,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.05,
    ghg: 737.0,
    congcost: 3199069.0,
    allspeed: 56.73,
    trkspeed: 57.58,
    ttivmt: 103106.0,
    trkttivmt: 21056.0,
  },
  {
    agglevel: "county",
    regioncode: 179,
    year: 2021,
    count: 304,
    len_sec: 217.06399999999988,
    lanemiles: 441.6669999999999,
    delay: 117917.0,
    trkdelay: 36248.0,
    pti80: 1.11,
    trkpti80: 1.15,
    pti95: 1.15,
    trkpti95: 1.22,
    tti: 1.04,
    trktti: 1.06,
    ghg: 901.0,
    congcost: 3610231.0,
    allspeed: 56.76,
    trkspeed: 57.98,
    ttivmt: 101064.0,
    trkttivmt: 24360.0,
  },
  {
    agglevel: "county",
    regioncode: 179,
    year: 2022,
    count: 318,
    len_sec: 219.169,
    lanemiles: 445.87700000000007,
    delay: 104959.0,
    trkdelay: 28800.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.17,
    trkpti95: 1.22,
    tti: 1.04,
    trktti: 1.05,
    ghg: 564.0,
    congcost: 3430258.0,
    allspeed: 55.85,
    trkspeed: 56.91,
    ttivmt: 95644.0,
    trkttivmt: 22507.0,
  },
  {
    agglevel: "county",
    regioncode: 180,
    year: 2017,
    count: 193,
    len_sec: 172.96700000000004,
    lanemiles: 459.438,
    delay: 41606.0,
    trkdelay: 17898.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.0,
    trktti: 1.0,
    ghg: 352.0,
    congcost: 1333322.0,
    allspeed: 64.31,
    trkspeed: 64.6,
    ttivmt: 326316.0,
    trkttivmt: 297087.0,
  },
  {
    agglevel: "county",
    regioncode: 180,
    year: 2018,
    count: 189,
    len_sec: 176.05500000000004,
    lanemiles: 465.091,
    delay: 29543.0,
    trkdelay: 10571.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.0,
    trktti: 1.0,
    ghg: 150.0,
    congcost: 886349.0,
    allspeed: 64.19,
    trkspeed: 64.22,
    ttivmt: 342002.0,
    trkttivmt: 98911.0,
  },
  {
    agglevel: "county",
    regioncode: 180,
    year: 2019,
    count: 97,
    len_sec: 177.892,
    lanemiles: 468.76000000000005,
    delay: 28496.0,
    trkdelay: 12248.0,
    pti80: 1.08,
    trkpti80: 1.06,
    pti95: 1.13,
    trkpti95: 1.08,
    tti: 1.0,
    trktti: 1.0,
    ghg: 188.0,
    congcost: 972572.0,
    allspeed: 64.45,
    trkspeed: 61.84,
    ttivmt: 327993.0,
    trkttivmt: 106177.0,
  },
  {
    agglevel: "county",
    regioncode: 180,
    year: 2020,
    count: 269,
    len_sec: 177.88800000000015,
    lanemiles: 468.73100000000005,
    delay: 33099.0,
    trkdelay: 6020.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.0,
    trktti: 1.0,
    ghg: 144.0,
    congcost: 877573.0,
    allspeed: 64.39,
    trkspeed: 64.56,
    ttivmt: 332425.0,
    trkttivmt: 106826.0,
  },
  {
    agglevel: "county",
    regioncode: 180,
    year: 2021,
    count: 291,
    len_sec: 179.5139999999999,
    lanemiles: 473.6599999999997,
    delay: 39621.0,
    trkdelay: 11109.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.0,
    trktti: 1.0,
    ghg: 196.0,
    congcost: 1160082.0,
    allspeed: 64.27,
    trkspeed: 64.48,
    ttivmt: 332868.0,
    trkttivmt: 160246.0,
  },
  {
    agglevel: "county",
    regioncode: 180,
    year: 2022,
    count: 301,
    len_sec: 179.2990000000001,
    lanemiles: 473.4749999999999,
    delay: 56628.0,
    trkdelay: 20107.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.01,
    trktti: 1.01,
    ghg: 232.0,
    congcost: 1978117.0,
    allspeed: 64.09,
    trkspeed: 64.25,
    ttivmt: 389291.0,
    trkttivmt: 153221.0,
  },
  {
    agglevel: "county",
    regioncode: 181,
    year: 2017,
    count: 788,
    len_sec: 338.3510000000004,
    lanemiles: 832.7930000000008,
    delay: 2309255.0,
    trkdelay: 84392.0,
    pti80: 1.16,
    trkpti80: 1.12,
    pti95: 1.22,
    trkpti95: 1.18,
    tti: 1.06,
    trktti: 1.03,
    ghg: 8393.0,
    congcost: 4.5281741e7,
    allspeed: 50.54,
    trkspeed: 60.0,
    ttivmt: 1472272.0,
    trkttivmt: 174642.0,
  },
  {
    agglevel: "county",
    regioncode: 181,
    year: 2018,
    count: 890,
    len_sec: 339.4979999999995,
    lanemiles: 836.0749999999991,
    delay: 2038680.0,
    trkdelay: 145925.0,
    pti80: 1.16,
    trkpti80: 1.13,
    pti95: 1.21,
    trkpti95: 1.18,
    tti: 1.07,
    trktti: 1.05,
    ghg: 8236.0,
    congcost: 4.3140336e7,
    allspeed: 53.92,
    trkspeed: 58.75,
    ttivmt: 1185760.0,
    trkttivmt: 147668.0,
  },
  {
    agglevel: "county",
    regioncode: 181,
    year: 2019,
    count: 274,
    len_sec: 178.20100000000002,
    lanemiles: 509.0869999999998,
    delay: 1702365.0,
    trkdelay: 131249.0,
    pti80: 1.15,
    trkpti80: 1.13,
    pti95: 1.19,
    trkpti95: 1.17,
    tti: 1.06,
    trktti: 1.05,
    ghg: 6801.0,
    congcost: 3.7654583e7,
    allspeed: 56.09,
    trkspeed: 59.37,
    ttivmt: 1114285.0,
    trkttivmt: 134611.0,
  },
  {
    agglevel: "county",
    regioncode: 181,
    year: 2020,
    count: 1180,
    len_sec: 339.50599999999935,
    lanemiles: 850.4229999999994,
    delay: 1713238.0,
    trkdelay: 89347.0,
    pti80: 1.14,
    trkpti80: 1.1,
    pti95: 1.18,
    trkpti95: 1.15,
    tti: 1.05,
    trktti: 1.03,
    ghg: 6771.0,
    congcost: 3.7538747e7,
    allspeed: 55.05,
    trkspeed: 60.86,
    ttivmt: 1253900.0,
    trkttivmt: 135551.0,
  },
  {
    agglevel: "county",
    regioncode: 181,
    year: 2021,
    count: 1210,
    len_sec: 339.7819999999997,
    lanemiles: 863.2289999999988,
    delay: 890399.0,
    trkdelay: 60041.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.02,
    ghg: 3492.0,
    congcost: 2.0427987e7,
    allspeed: 56.04,
    trkspeed: 61.65,
    ttivmt: 1112777.0,
    trkttivmt: 139517.0,
  },
  {
    agglevel: "county",
    regioncode: 181,
    year: 2022,
    count: 1225,
    len_sec: 340.1239999999997,
    lanemiles: 866.6289999999983,
    delay: 1602455.0,
    trkdelay: 98706.0,
    pti80: 1.13,
    trkpti80: 1.09,
    pti95: 1.19,
    trkpti95: 1.14,
    tti: 1.05,
    trktti: 1.02,
    ghg: 6527.0,
    congcost: 4.0436231e7,
    allspeed: 55.11,
    trkspeed: 61.5,
    ttivmt: 1236993.0,
    trkttivmt: 163594.0,
  },
  {
    agglevel: "county",
    regioncode: 182,
    year: 2017,
    count: 497,
    len_sec: 403.3909999999998,
    lanemiles: 872.2739999999993,
    delay: 420542.0,
    trkdelay: 34847.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.01,
    ghg: 1807.0,
    congcost: 8876125.0,
    allspeed: 56.87,
    trkspeed: 62.97,
    ttivmt: 452787.0,
    trkttivmt: 118060.0,
  },
  {
    agglevel: "county",
    regioncode: 182,
    year: 2018,
    count: 643,
    len_sec: 403.49400000000037,
    lanemiles: 871.7570000000006,
    delay: 343538.0,
    trkdelay: 43547.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.02,
    ghg: 1745.0,
    congcost: 7923156.0,
    allspeed: 57.15,
    trkspeed: 61.8,
    ttivmt: 434369.0,
    trkttivmt: 99512.0,
  },
  {
    agglevel: "county",
    regioncode: 182,
    year: 2019,
    count: 286,
    len_sec: 403.80899999999997,
    lanemiles: 872.387,
    delay: 349928.0,
    trkdelay: 48020.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.02,
    ghg: 1717.0,
    congcost: 8500512.0,
    allspeed: 58.77,
    trkspeed: 61.92,
    ttivmt: 452183.0,
    trkttivmt: 101565.0,
  },
  {
    agglevel: "county",
    regioncode: 182,
    year: 2020,
    count: 910,
    len_sec: 401.3009999999996,
    lanemiles: 867.3669999999992,
    delay: 361475.0,
    trkdelay: 35940.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.01,
    ghg: 1580.0,
    congcost: 8559247.0,
    allspeed: 58.56,
    trkspeed: 62.61,
    ttivmt: 469276.0,
    trkttivmt: 95000.0,
  },
  {
    agglevel: "county",
    regioncode: 182,
    year: 2021,
    count: 933,
    len_sec: 401.30199999999934,
    lanemiles: 875.855999999999,
    delay: 220587.0,
    trkdelay: 27472.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.01,
    ghg: 1012.0,
    congcost: 5463494.0,
    allspeed: 59.47,
    trkspeed: 62.54,
    ttivmt: 421283.0,
    trkttivmt: 79122.0,
  },
  {
    agglevel: "county",
    regioncode: 182,
    year: 2022,
    count: 960,
    len_sec: 401.40499999999923,
    lanemiles: 876.0899999999978,
    delay: 380060.0,
    trkdelay: 45398.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.17,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.02,
    ghg: 1483.0,
    congcost: 1.0316014e7,
    allspeed: 57.87,
    trkspeed: 61.76,
    ttivmt: 477603.0,
    trkttivmt: 83223.0,
  },
  {
    agglevel: "county",
    regioncode: 183,
    year: 2017,
    count: 547,
    len_sec: 353.2339999999996,
    lanemiles: 841.4559999999996,
    delay: 311581.0,
    trkdelay: 39146.0,
    pti80: 1.08,
    trkpti80: 1.06,
    pti95: 1.1,
    trkpti95: 1.08,
    tti: 1.03,
    trktti: 1.02,
    ghg: 1569.0,
    congcost: 7007571.0,
    allspeed: 57.4,
    trkspeed: 60.84,
    ttivmt: 351630.0,
    trkttivmt: 84323.0,
  },
  {
    agglevel: "county",
    regioncode: 183,
    year: 2018,
    count: 533,
    len_sec: 348.4699999999999,
    lanemiles: 833.8769999999997,
    delay: 311937.0,
    trkdelay: 50843.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1730.0,
    congcost: 7556584.0,
    allspeed: 57.55,
    trkspeed: 59.2,
    ttivmt: 382899.0,
    trkttivmt: 71395.0,
  },
  {
    agglevel: "county",
    regioncode: 183,
    year: 2019,
    count: 247,
    len_sec: 348.463,
    lanemiles: 835.1199999999995,
    delay: 266203.0,
    trkdelay: 67468.0,
    pti80: 1.08,
    trkpti80: 1.1,
    pti95: 1.1,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1923.0,
    congcost: 7607756.0,
    allspeed: 58.86,
    trkspeed: 59.02,
    ttivmt: 401416.0,
    trkttivmt: 90603.0,
  },
  {
    agglevel: "county",
    regioncode: 183,
    year: 2020,
    count: 842,
    len_sec: 349.08399999999966,
    lanemiles: 835.7389999999996,
    delay: 324378.0,
    trkdelay: 74469.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 2340.0,
    congcost: 9353766.0,
    allspeed: 58.46,
    trkspeed: 59.68,
    ttivmt: 400474.0,
    trkttivmt: 89842.0,
  },
  {
    agglevel: "county",
    regioncode: 183,
    year: 2021,
    count: 832,
    len_sec: 349.120999999999,
    lanemiles: 836.3859999999983,
    delay: 191910.0,
    trkdelay: 49420.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 1311.0,
    congcost: 5573414.0,
    allspeed: 58.64,
    trkspeed: 60.32,
    ttivmt: 376707.0,
    trkttivmt: 83796.0,
  },
  {
    agglevel: "county",
    regioncode: 183,
    year: 2022,
    count: 832,
    len_sec: 349.1209999999998,
    lanemiles: 835.9539999999992,
    delay: 315726.0,
    trkdelay: 56697.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.02,
    ghg: 1550.0,
    congcost: 9271633.0,
    allspeed: 58.19,
    trkspeed: 60.74,
    ttivmt: 395767.0,
    trkttivmt: 104744.0,
  },
  {
    agglevel: "county",
    regioncode: 184,
    year: 2017,
    count: 930,
    len_sec: 578.3110000000001,
    lanemiles: 1342.0230000000013,
    delay: 2317804.0,
    trkdelay: 120649.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.05,
    trktti: 1.02,
    ghg: 9052.0,
    congcost: 4.6629531e7,
    allspeed: 54.62,
    trkspeed: 60.72,
    ttivmt: 1669804.0,
    trkttivmt: 196886.0,
  },
  {
    agglevel: "county",
    regioncode: 184,
    year: 2018,
    count: 884,
    len_sec: 582.4809999999997,
    lanemiles: 1350.6159999999986,
    delay: 1979791.0,
    trkdelay: 191487.0,
    pti80: 1.13,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.04,
    ghg: 9437.0,
    congcost: 4.3701747e7,
    allspeed: 55.58,
    trkspeed: 58.94,
    ttivmt: 1533350.0,
    trkttivmt: 205898.0,
  },
  {
    agglevel: "county",
    regioncode: 184,
    year: 2019,
    count: 557,
    len_sec: 579.765,
    lanemiles: 1345.1840000000004,
    delay: 2015571.0,
    trkdelay: 181733.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.16,
    trkpti95: 1.2,
    tti: 1.05,
    trktti: 1.05,
    ghg: 9108.0,
    congcost: 4.5693207e7,
    allspeed: 55.89,
    trkspeed: 58.78,
    ttivmt: 1585762.0,
    trkttivmt: 194381.0,
  },
  {
    agglevel: "county",
    regioncode: 184,
    year: 2020,
    count: 1360,
    len_sec: 581.7569999999988,
    lanemiles: 1351.684999999999,
    delay: 2544489.0,
    trkdelay: 175309.0,
    pti80: 1.13,
    trkpti80: 1.13,
    pti95: 1.18,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.04,
    ghg: 10845.0,
    congcost: 5.7409201e7,
    allspeed: 54.87,
    trkspeed: 59.57,
    ttivmt: 1748501.0,
    trkttivmt: 196771.0,
  },
  {
    agglevel: "county",
    regioncode: 184,
    year: 2021,
    count: 1406,
    len_sec: 581.8339999999981,
    lanemiles: 1352.0719999999963,
    delay: 1333890.0,
    trkdelay: 106507.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.02,
    ghg: 5629.0,
    congcost: 3.1160068e7,
    allspeed: 55.35,
    trkspeed: 61.26,
    ttivmt: 1569418.0,
    trkttivmt: 204023.0,
  },
  {
    agglevel: "county",
    regioncode: 184,
    year: 2022,
    count: 1491,
    len_sec: 586.5499999999977,
    lanemiles: 1365.975999999996,
    delay: 2480163.0,
    trkdelay: 167357.0,
    pti80: 1.16,
    trkpti80: 1.13,
    pti95: 1.23,
    trkpti95: 1.21,
    tti: 1.06,
    trktti: 1.05,
    ghg: 9316.0,
    congcost: 6.2839762e7,
    allspeed: 54.62,
    trkspeed: 60.38,
    ttivmt: 1845354.0,
    trkttivmt: 224215.0,
  },
  {
    agglevel: "county",
    regioncode: 185,
    year: 2017,
    count: 273,
    len_sec: 281.28599999999994,
    lanemiles: 667.2079999999996,
    delay: 77014.0,
    trkdelay: 12738.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.01,
    ghg: 281.0,
    congcost: 1804587.0,
    allspeed: 60.56,
    trkspeed: 62.9,
    ttivmt: 160599.0,
    trkttivmt: 55492.0,
  },
  {
    agglevel: "county",
    regioncode: 185,
    year: 2018,
    count: 221,
    len_sec: 281.0339999999998,
    lanemiles: 666.4099999999997,
    delay: 84030.0,
    trkdelay: 18002.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 402.0,
    congcost: 2151525.0,
    allspeed: 59.63,
    trkspeed: 60.67,
    ttivmt: 166533.0,
    trkttivmt: 36028.0,
  },
  {
    agglevel: "county",
    regioncode: 185,
    year: 2019,
    count: 149,
    len_sec: 279.6379999999999,
    lanemiles: 664.8359999999997,
    delay: 66308.0,
    trkdelay: 21724.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 424.0,
    congcost: 2041722.0,
    allspeed: 60.84,
    trkspeed: 60.26,
    ttivmt: 159310.0,
    trkttivmt: 34066.0,
  },
  {
    agglevel: "county",
    regioncode: 185,
    year: 2020,
    count: 324,
    len_sec: 281.13099999999986,
    lanemiles: 665.1179999999996,
    delay: 77274.0,
    trkdelay: 23731.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 506.0,
    congcost: 2428022.0,
    allspeed: 60.35,
    trkspeed: 60.83,
    ttivmt: 155325.0,
    trkttivmt: 35487.0,
  },
  {
    agglevel: "county",
    regioncode: 185,
    year: 2021,
    count: 325,
    len_sec: 281.131,
    lanemiles: 665.7059999999999,
    delay: 81608.0,
    trkdelay: 29168.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 593.0,
    congcost: 2609654.0,
    allspeed: 60.0,
    trkspeed: 60.51,
    ttivmt: 174117.0,
    trkttivmt: 42913.0,
  },
  {
    agglevel: "county",
    regioncode: 185,
    year: 2022,
    count: 322,
    len_sec: 281.14,
    lanemiles: 665.7259999999995,
    delay: 108629.0,
    trkdelay: 24451.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 555.0,
    congcost: 3361564.0,
    allspeed: 59.98,
    trkspeed: 60.83,
    ttivmt: 198627.0,
    trkttivmt: 44846.0,
  },
  {
    agglevel: "county",
    regioncode: 186,
    year: 2017,
    count: 620,
    len_sec: 733.307,
    lanemiles: 1715.9370000000004,
    delay: 233587.0,
    trkdelay: 35846.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.0,
    ghg: 1200.0,
    congcost: 5456498.0,
    allspeed: 61.42,
    trkspeed: 64.19,
    ttivmt: 481370.0,
    trkttivmt: 278971.0,
  },
  {
    agglevel: "county",
    regioncode: 186,
    year: 2018,
    count: 991,
    len_sec: 733.1549999999987,
    lanemiles: 1715.5669999999975,
    delay: 242458.0,
    trkdelay: 49923.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1366.0,
    congcost: 6205054.0,
    allspeed: 61.99,
    trkspeed: 62.86,
    ttivmt: 548300.0,
    trkttivmt: 150120.0,
  },
  {
    agglevel: "county",
    regioncode: 186,
    year: 2019,
    count: 226,
    len_sec: 665.329,
    lanemiles: 1579.0410000000006,
    delay: 248981.0,
    trkdelay: 67916.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.15,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.02,
    ghg: 1615.0,
    congcost: 7234612.0,
    allspeed: 62.61,
    trkspeed: 61.84,
    ttivmt: 558080.0,
    trkttivmt: 163288.0,
  },
  {
    agglevel: "county",
    regioncode: 186,
    year: 2020,
    count: 1329,
    len_sec: 743.7589999999979,
    lanemiles: 1732.9349999999963,
    delay: 251547.0,
    trkdelay: 51827.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1364.0,
    congcost: 6957852.0,
    allspeed: 62.2,
    trkspeed: 63.38,
    ttivmt: 546110.0,
    trkttivmt: 159379.0,
  },
  {
    agglevel: "county",
    regioncode: 186,
    year: 2021,
    count: 1333,
    len_sec: 743.8199999999973,
    lanemiles: 1732.7379999999969,
    delay: 176157.0,
    trkdelay: 42258.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1026.0,
    congcost: 4999168.0,
    allspeed: 62.4,
    trkspeed: 63.55,
    ttivmt: 499149.0,
    trkttivmt: 140938.0,
  },
  {
    agglevel: "county",
    regioncode: 186,
    year: 2022,
    count: 1351,
    len_sec: 744.7039999999974,
    lanemiles: 1734.562999999996,
    delay: 225418.0,
    trkdelay: 52451.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.14,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1029.0,
    congcost: 7010272.0,
    allspeed: 62.35,
    trkspeed: 63.45,
    ttivmt: 554447.0,
    trkttivmt: 162464.0,
  },
  {
    agglevel: "county",
    regioncode: 187,
    year: 2017,
    count: 657,
    len_sec: 372.05899999999974,
    lanemiles: 878.279,
    delay: 741148.0,
    trkdelay: 83173.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.09,
    tti: 1.04,
    trktti: 1.02,
    ghg: 3421.0,
    congcost: 1.6318092e7,
    allspeed: 56.42,
    trkspeed: 60.53,
    ttivmt: 628664.0,
    trkttivmt: 170061.0,
  },
  {
    agglevel: "county",
    regioncode: 187,
    year: 2018,
    count: 583,
    len_sec: 372.679,
    lanemiles: 879.8649999999996,
    delay: 756369.0,
    trkdelay: 77110.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 3627.0,
    congcost: 1.6813858e7,
    allspeed: 55.68,
    trkspeed: 58.29,
    ttivmt: 635734.0,
    trkttivmt: 95396.0,
  },
  {
    agglevel: "county",
    regioncode: 187,
    year: 2019,
    count: 210,
    len_sec: 280.78000000000003,
    lanemiles: 696.0520000000007,
    delay: 679099.0,
    trkdelay: 94868.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 3738.0,
    congcost: 1.6638307e7,
    allspeed: 58.61,
    trkspeed: 59.58,
    ttivmt: 638795.0,
    trkttivmt: 120262.0,
  },
  {
    agglevel: "county",
    regioncode: 187,
    year: 2020,
    count: 935,
    len_sec: 372.9510000000001,
    lanemiles: 880.3960000000009,
    delay: 748352.0,
    trkdelay: 124324.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.04,
    ghg: 4703.0,
    congcost: 1.9768811e7,
    allspeed: 58.01,
    trkspeed: 59.68,
    ttivmt: 715080.0,
    trkttivmt: 121902.0,
  },
  {
    agglevel: "county",
    regioncode: 187,
    year: 2021,
    count: 935,
    len_sec: 373.03900000000016,
    lanemiles: 880.7479999999995,
    delay: 667639.0,
    trkdelay: 123735.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 4424.0,
    congcost: 1.7956638e7,
    allspeed: 58.34,
    trkspeed: 59.86,
    ttivmt: 680742.0,
    trkttivmt: 120236.0,
  },
  {
    agglevel: "county",
    regioncode: 187,
    year: 2022,
    count: 931,
    len_sec: 373.025,
    lanemiles: 883.2930000000005,
    delay: 840346.0,
    trkdelay: 109871.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.03,
    ghg: 4087.0,
    congcost: 2.3301013e7,
    allspeed: 57.61,
    trkspeed: 60.4,
    ttivmt: 725594.0,
    trkttivmt: 165927.0,
  },
  {
    agglevel: "county",
    regioncode: 188,
    year: 2017,
    count: 1143,
    len_sec: 445.8250000000001,
    lanemiles: 1280.284000000001,
    delay: 3329505.0,
    trkdelay: 126355.0,
    pti80: 1.14,
    trkpti80: 1.08,
    pti95: 1.18,
    trkpti95: 1.1,
    tti: 1.06,
    trktti: 1.02,
    ghg: 13997.0,
    congcost: 6.5804459e7,
    allspeed: 46.75,
    trkspeed: 59.24,
    ttivmt: 1618923.0,
    trkttivmt: 261456.0,
  },
  {
    agglevel: "county",
    regioncode: 188,
    year: 2018,
    count: 932,
    len_sec: 447.01499999999965,
    lanemiles: 1284.8519999999978,
    delay: 2662105.0,
    trkdelay: 275500.0,
    pti80: 1.15,
    trkpti80: 1.14,
    pti95: 1.18,
    trkpti95: 1.18,
    tti: 1.06,
    trktti: 1.05,
    ghg: 14393.0,
    congcost: 5.9648673e7,
    allspeed: 50.45,
    trkspeed: 55.59,
    ttivmt: 1274701.0,
    trkttivmt: 191930.0,
  },
  {
    agglevel: "county",
    regioncode: 188,
    year: 2019,
    count: 732,
    len_sec: 445.06100000000004,
    lanemiles: 1281.9440000000002,
    delay: 2659184.0,
    trkdelay: 266534.0,
    pti80: 1.14,
    trkpti80: 1.13,
    pti95: 1.17,
    trkpti95: 1.16,
    tti: 1.06,
    trktti: 1.05,
    ghg: 14601.0,
    congcost: 6.1794435e7,
    allspeed: 49.82,
    trkspeed: 54.46,
    ttivmt: 1256159.0,
    trkttivmt: 178858.0,
  },
  {
    agglevel: "county",
    regioncode: 188,
    year: 2020,
    count: 1452,
    len_sec: 445.1929999999993,
    lanemiles: 1296.0429999999994,
    delay: 2698171.0,
    trkdelay: 154845.0,
    pti80: 1.14,
    trkpti80: 1.11,
    pti95: 1.18,
    trkpti95: 1.16,
    tti: 1.06,
    trktti: 1.04,
    ghg: 12664.0,
    congcost: 6.0074321e7,
    allspeed: 49.55,
    trkspeed: 57.02,
    ttivmt: 1239565.0,
    trkttivmt: 141589.0,
  },
  {
    agglevel: "county",
    regioncode: 188,
    year: 2021,
    count: 1622,
    len_sec: 435.94599999999934,
    lanemiles: 1295.0479999999989,
    delay: 1607710.0,
    trkdelay: 162141.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 7838.0,
    congcost: 3.872978e7,
    allspeed: 49.75,
    trkspeed: 58.3,
    ttivmt: 1296864.0,
    trkttivmt: 222450.0,
  },
  {
    agglevel: "county",
    regioncode: 188,
    year: 2022,
    count: 1678,
    len_sec: 436.300999999999,
    lanemiles: 1295.8279999999975,
    delay: 2392496.0,
    trkdelay: 196809.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.03,
    ghg: 11241.0,
    congcost: 6.243252e7,
    allspeed: 49.4,
    trkspeed: 57.84,
    ttivmt: 1405725.0,
    trkttivmt: 202235.0,
  },
  {
    agglevel: "county",
    regioncode: 189,
    year: 2017,
    count: 201,
    len_sec: 271.38500000000005,
    lanemiles: 546.3689999999999,
    delay: 89253.0,
    trkdelay: 8116.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 307.0,
    congcost: 1892519.0,
    allspeed: 58.97,
    trkspeed: 61.4,
    ttivmt: 82702.0,
    trkttivmt: 14275.0,
  },
  {
    agglevel: "county",
    regioncode: 189,
    year: 2018,
    count: 223,
    len_sec: 228.41999999999987,
    lanemiles: 461.9809999999997,
    delay: 115190.0,
    trkdelay: 7060.0,
    pti80: 1.13,
    trkpti80: 1.14,
    pti95: 1.15,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.05,
    ghg: 456.0,
    congcost: 2401956.0,
    allspeed: 53.74,
    trkspeed: 56.07,
    ttivmt: 63088.0,
    trkttivmt: 4659.0,
  },
  {
    agglevel: "county",
    regioncode: 189,
    year: 2019,
    count: 44,
    len_sec: 162.41400000000002,
    lanemiles: 329.9690000000001,
    delay: 123413.0,
    trkdelay: 5592.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.14,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.05,
    ghg: 459.0,
    congcost: 2596901.0,
    allspeed: 56.8,
    trkspeed: 57.12,
    ttivmt: 83721.0,
    trkttivmt: 3614.0,
  },
  {
    agglevel: "county",
    regioncode: 189,
    year: 2020,
    count: 399,
    len_sec: 270.71000000000004,
    lanemiles: 554.133,
    delay: 153578.0,
    trkdelay: 6909.0,
    pti80: 1.12,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.04,
    ghg: 584.0,
    congcost: 3323712.0,
    allspeed: 55.85,
    trkspeed: 58.27,
    ttivmt: 89958.0,
    trkttivmt: 5402.0,
  },
  {
    agglevel: "county",
    regioncode: 189,
    year: 2021,
    count: 401,
    len_sec: 270.6099999999999,
    lanemiles: 553.9330000000004,
    delay: 79411.0,
    trkdelay: 7165.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.03,
    ghg: 308.0,
    congcost: 1876715.0,
    allspeed: 55.56,
    trkspeed: 60.51,
    ttivmt: 76027.0,
    trkttivmt: 10408.0,
  },
  {
    agglevel: "county",
    regioncode: 189,
    year: 2022,
    count: 400,
    len_sec: 270.6069999999999,
    lanemiles: 553.9269999999998,
    delay: 102097.0,
    trkdelay: 17718.0,
    pti80: 1.12,
    trkpti80: 1.16,
    pti95: 1.18,
    trkpti95: 1.29,
    tti: 1.04,
    trktti: 1.06,
    ghg: 387.0,
    congcost: 2959192.0,
    allspeed: 56.58,
    trkspeed: 58.99,
    ttivmt: 97278.0,
    trkttivmt: 12561.0,
  },
  {
    agglevel: "county",
    regioncode: 190,
    year: 2017,
    count: 225,
    len_sec: 138.20200000000006,
    lanemiles: 278.0040000000002,
    delay: 171299.0,
    trkdelay: 11782.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.05,
    trktti: 1.03,
    ghg: 518.0,
    congcost: 3501494.0,
    allspeed: 56.24,
    trkspeed: 58.3,
    ttivmt: 135564.0,
    trkttivmt: 14265.0,
  },
  {
    agglevel: "county",
    regioncode: 190,
    year: 2018,
    count: 214,
    len_sec: 137.66499999999996,
    lanemiles: 276.8379999999999,
    delay: 171853.0,
    trkdelay: 12587.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.12,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.06,
    ghg: 577.0,
    congcost: 3620697.0,
    allspeed: 54.33,
    trkspeed: 55.27,
    ttivmt: 136488.0,
    trkttivmt: 9731.0,
  },
  {
    agglevel: "county",
    regioncode: 190,
    year: 2019,
    count: 102,
    len_sec: 137.63800000000003,
    lanemiles: 276.7840000000001,
    delay: 160148.0,
    trkdelay: 18254.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.11,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.07,
    ghg: 675.0,
    congcost: 3738180.0,
    allspeed: 55.69,
    trkspeed: 55.51,
    ttivmt: 131054.0,
    trkttivmt: 12168.0,
  },
  {
    agglevel: "county",
    regioncode: 190,
    year: 2020,
    count: 340,
    len_sec: 137.64100000000005,
    lanemiles: 276.7900000000001,
    delay: 180164.0,
    trkdelay: 19114.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.05,
    trktti: 1.06,
    ghg: 768.0,
    congcost: 4297160.0,
    allspeed: 55.72,
    trkspeed: 56.78,
    ttivmt: 138546.0,
    trkttivmt: 12807.0,
  },
  {
    agglevel: "county",
    regioncode: 190,
    year: 2021,
    count: 333,
    len_sec: 137.68,
    lanemiles: 276.86800000000017,
    delay: 121799.0,
    trkdelay: 11076.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.05,
    ghg: 478.0,
    congcost: 2878431.0,
    allspeed: 55.86,
    trkspeed: 57.38,
    ttivmt: 129139.0,
    trkttivmt: 10669.0,
  },
  {
    agglevel: "county",
    regioncode: 190,
    year: 2022,
    count: 334,
    len_sec: 137.68,
    lanemiles: 276.8680000000001,
    delay: 191011.0,
    trkdelay: 20098.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.19,
    trkpti95: 1.25,
    tti: 1.05,
    trktti: 1.06,
    ghg: 792.0,
    congcost: 5099608.0,
    allspeed: 55.14,
    trkspeed: 56.49,
    ttivmt: 139707.0,
    trkttivmt: 13614.0,
  },
  {
    agglevel: "county",
    regioncode: 191,
    year: 2017,
    count: 718,
    len_sec: 498.90699999999975,
    lanemiles: 1258.1879999999994,
    delay: 3014684.0,
    trkdelay: 71301.0,
    pti80: 1.17,
    trkpti80: 1.1,
    pti95: 1.21,
    trkpti95: 1.14,
    tti: 1.08,
    trktti: 1.02,
    ghg: 12646.0,
    congcost: 5.8317916e7,
    allspeed: 44.87,
    trkspeed: 59.18,
    ttivmt: 1105321.0,
    trkttivmt: 130938.0,
  },
  {
    agglevel: "county",
    regioncode: 191,
    year: 2018,
    count: 675,
    len_sec: 505.6679999999995,
    lanemiles: 1271.7759999999985,
    delay: 2572974.0,
    trkdelay: 106324.0,
    pti80: 1.18,
    trkpti80: 1.14,
    pti95: 1.22,
    trkpti95: 1.18,
    tti: 1.09,
    trktti: 1.05,
    ghg: 11941.0,
    congcost: 5.2448816e7,
    allspeed: 48.09,
    trkspeed: 54.97,
    ttivmt: 865784.0,
    trkttivmt: 69313.0,
  },
  {
    agglevel: "county",
    regioncode: 191,
    year: 2019,
    count: 513,
    len_sec: 499.48299999999966,
    lanemiles: 1259.5479999999989,
    delay: 2483742.0,
    trkdelay: 127965.0,
    pti80: 1.18,
    trkpti80: 1.14,
    pti95: 1.22,
    trkpti95: 1.19,
    tti: 1.09,
    trktti: 1.06,
    ghg: 11739.0,
    congcost: 5.3382752e7,
    allspeed: 48.0,
    trkspeed: 53.47,
    ttivmt: 877100.0,
    trkttivmt: 77228.0,
  },
  {
    agglevel: "county",
    regioncode: 191,
    year: 2020,
    count: 944,
    len_sec: 505.8319999999994,
    lanemiles: 1279.1729999999982,
    delay: 2290840.0,
    trkdelay: 128160.0,
    pti80: 1.17,
    trkpti80: 1.14,
    pti95: 1.21,
    trkpti95: 1.19,
    tti: 1.08,
    trktti: 1.06,
    ghg: 10865.0,
    congcost: 5.0921301e7,
    allspeed: 48.64,
    trkspeed: 55.68,
    ttivmt: 903835.0,
    trkttivmt: 79646.0,
  },
  {
    agglevel: "county",
    regioncode: 191,
    year: 2021,
    count: 991,
    len_sec: 511.9029999999989,
    lanemiles: 1295.2589999999982,
    delay: 1782423.0,
    trkdelay: 139505.0,
    pti80: 1.15,
    trkpti80: 1.14,
    pti95: 1.2,
    trkpti95: 1.22,
    tti: 1.07,
    trktti: 1.06,
    ghg: 9021.0,
    congcost: 4.185922e7,
    allspeed: 47.66,
    trkspeed: 56.34,
    ttivmt: 931003.0,
    trkttivmt: 99762.0,
  },
  {
    agglevel: "county",
    regioncode: 191,
    year: 2022,
    count: 1005,
    len_sec: 513.9509999999995,
    lanemiles: 1299.8210000000001,
    delay: 2212248.0,
    trkdelay: 156304.0,
    pti80: 1.15,
    trkpti80: 1.15,
    pti95: 1.22,
    trkpti95: 1.24,
    tti: 1.06,
    trktti: 1.06,
    ghg: 10686.0,
    congcost: 5.6966693e7,
    allspeed: 47.82,
    trkspeed: 55.3,
    ttivmt: 1064132.0,
    trkttivmt: 96875.0,
  },
  {
    agglevel: "county",
    regioncode: 192,
    year: 2017,
    count: 76,
    len_sec: 168.83200000000002,
    lanemiles: 339.836,
    delay: 50149.0,
    trkdelay: 9336.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 253.0,
    congcost: 1217114.0,
    allspeed: 61.01,
    trkspeed: 63.38,
    ttivmt: 131944.0,
    trkttivmt: 49984.0,
  },
  {
    agglevel: "county",
    regioncode: 192,
    year: 2018,
    count: 112,
    len_sec: 168.66399999999993,
    lanemiles: 339.4379999999998,
    delay: 80192.0,
    trkdelay: 15082.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 352.0,
    congcost: 1987576.0,
    allspeed: 59.87,
    trkspeed: 61.04,
    ttivmt: 185404.0,
    trkttivmt: 25292.0,
  },
  {
    agglevel: "county",
    regioncode: 192,
    year: 2019,
    count: 28,
    len_sec: 96.155,
    lanemiles: 194.42,
    delay: 74710.0,
    trkdelay: 15101.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 258.0,
    congcost: 1945755.0,
    allspeed: 60.97,
    trkspeed: 61.36,
    ttivmt: 165568.0,
    trkttivmt: 25701.0,
  },
  {
    agglevel: "county",
    regioncode: 192,
    year: 2020,
    count: 142,
    len_sec: 168.665,
    lanemiles: 339.42999999999995,
    delay: 61978.0,
    trkdelay: 25630.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.02,
    ghg: 380.0,
    congcost: 2172759.0,
    allspeed: 61.49,
    trkspeed: 61.99,
    ttivmt: 157558.0,
    trkttivmt: 43047.0,
  },
  {
    agglevel: "county",
    regioncode: 192,
    year: 2021,
    count: 141,
    len_sec: 168.7080000000001,
    lanemiles: 339.51600000000013,
    delay: 33742.0,
    trkdelay: 15846.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.19,
    tti: 1.02,
    trktti: 1.04,
    ghg: 257.0,
    congcost: 1191228.0,
    allspeed: 60.53,
    trkspeed: 61.24,
    ttivmt: 78392.0,
    trkttivmt: 20167.0,
  },
  {
    agglevel: "county",
    regioncode: 192,
    year: 2022,
    count: 146,
    len_sec: 168.77199999999996,
    lanemiles: 339.65000000000003,
    delay: 56211.0,
    trkdelay: 20240.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 320.0,
    congcost: 2004686.0,
    allspeed: 60.4,
    trkspeed: 61.02,
    ttivmt: 82114.0,
    trkttivmt: 22831.0,
  },
  {
    agglevel: "county",
    regioncode: 193,
    year: 2017,
    count: 110,
    len_sec: 150.99999999999994,
    lanemiles: 301.9999999999999,
    delay: 50495.0,
    trkdelay: 9012.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 224.0,
    congcost: 1209952.0,
    allspeed: 56.33,
    trkspeed: 60.41,
    ttivmt: 44988.0,
    trkttivmt: 15710.0,
  },
  {
    agglevel: "county",
    regioncode: 193,
    year: 2018,
    count: 133,
    len_sec: 138.176,
    lanemiles: 276.352,
    delay: 18200.0,
    trkdelay: 3499.0,
    pti80: 1.06,
    trkpti80: 1.1,
    pti95: 1.08,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 74.0,
    congcost: 451793.0,
    allspeed: 42.34,
    trkspeed: 47.95,
    ttivmt: 40891.0,
    trkttivmt: 4941.0,
  },
  {
    agglevel: "county",
    regioncode: 193,
    year: 2019,
    count: 38,
    len_sec: 119.335,
    lanemiles: 238.67,
    delay: 39780.0,
    trkdelay: 7866.0,
    pti80: 1.1,
    trkpti80: 1.16,
    pti95: 1.12,
    trkpti95: 1.21,
    tti: 1.03,
    trktti: 1.06,
    ghg: 206.0,
    congcost: 1045602.0,
    allspeed: 57.24,
    trkspeed: 56.62,
    ttivmt: 43947.0,
    trkttivmt: 5640.0,
  },
  {
    agglevel: "county",
    regioncode: 193,
    year: 2020,
    count: 184,
    len_sec: 150.51200000000006,
    lanemiles: 301.0240000000001,
    delay: 46485.0,
    trkdelay: 7796.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.05,
    ghg: 225.0,
    congcost: 1216863.0,
    allspeed: 56.4,
    trkspeed: 57.23,
    ttivmt: 48294.0,
    trkttivmt: 7382.0,
  },
  {
    agglevel: "county",
    regioncode: 193,
    year: 2021,
    count: 183,
    len_sec: 150.51199999999994,
    lanemiles: 301.0239999999999,
    delay: 34477.0,
    trkdelay: 5387.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.04,
    ghg: 140.0,
    congcost: 881474.0,
    allspeed: 55.27,
    trkspeed: 56.82,
    ttivmt: 41820.0,
    trkttivmt: 6099.0,
  },
  {
    agglevel: "county",
    regioncode: 193,
    year: 2022,
    count: 187,
    len_sec: 150.56500000000008,
    lanemiles: 301.13000000000017,
    delay: 46417.0,
    trkdelay: 7609.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.17,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.06,
    ghg: 164.0,
    congcost: 1323582.0,
    allspeed: 54.27,
    trkspeed: 56.46,
    ttivmt: 43956.0,
    trkttivmt: 6308.0,
  },
  {
    agglevel: "county",
    regioncode: 194,
    year: 2017,
    count: 522,
    len_sec: 361.98600000000016,
    lanemiles: 725.7120000000003,
    delay: 164726.0,
    trkdelay: 18710.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.08,
    tti: 1.04,
    trktti: 1.03,
    ghg: 639.0,
    congcost: 3613246.0,
    allspeed: 57.19,
    trkspeed: 60.6,
    ttivmt: 149440.0,
    trkttivmt: 33812.0,
  },
  {
    agglevel: "county",
    regioncode: 194,
    year: 2018,
    count: 439,
    len_sec: 362.87899999999996,
    lanemiles: 729.7919999999999,
    delay: 102657.0,
    trkdelay: 19459.0,
    pti80: 1.07,
    trkpti80: 1.1,
    pti95: 1.08,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.04,
    ghg: 503.0,
    congcost: 2557158.0,
    allspeed: 53.29,
    trkspeed: 56.01,
    ttivmt: 157307.0,
    trkttivmt: 24229.0,
  },
  {
    agglevel: "county",
    regioncode: 194,
    year: 2019,
    count: 177,
    len_sec: 372.667,
    lanemiles: 749.3679999999999,
    delay: 164107.0,
    trkdelay: 28809.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.12,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.05,
    ghg: 855.0,
    congcost: 4200118.0,
    allspeed: 58.1,
    trkspeed: 57.89,
    ttivmt: 156975.0,
    trkttivmt: 26784.0,
  },
  {
    agglevel: "county",
    regioncode: 194,
    year: 2020,
    count: 845,
    len_sec: 372.10999999999973,
    lanemiles: 749.6439999999994,
    delay: 171795.0,
    trkdelay: 15496.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.04,
    trktti: 1.03,
    ghg: 694.0,
    congcost: 4002449.0,
    allspeed: 57.93,
    trkspeed: 60.17,
    ttivmt: 153696.0,
    trkttivmt: 21985.0,
  },
  {
    agglevel: "county",
    regioncode: 194,
    year: 2021,
    count: 836,
    len_sec: 372.82299999999924,
    lanemiles: 751.0719999999986,
    delay: 129130.0,
    trkdelay: 14236.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 537.0,
    congcost: 3135222.0,
    allspeed: 57.47,
    trkspeed: 58.75,
    ttivmt: 163025.0,
    trkttivmt: 21289.0,
  },
  {
    agglevel: "county",
    regioncode: 194,
    year: 2022,
    count: 841,
    len_sec: 372.76799999999946,
    lanemiles: 756.6469999999991,
    delay: 164579.0,
    trkdelay: 27673.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.05,
    ghg: 669.0,
    congcost: 4745042.0,
    allspeed: 56.87,
    trkspeed: 58.12,
    ttivmt: 178300.0,
    trkttivmt: 26919.0,
  },
  {
    agglevel: "county",
    regioncode: 195,
    year: 2017,
    count: 431,
    len_sec: 534.4389999999999,
    lanemiles: 1257.6530000000002,
    delay: 238126.0,
    trkdelay: 37589.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.0,
    ghg: 998.0,
    congcost: 5548758.0,
    allspeed: 60.27,
    trkspeed: 64.24,
    ttivmt: 530462.0,
    trkttivmt: 478986.0,
  },
  {
    agglevel: "county",
    regioncode: 195,
    year: 2018,
    count: 493,
    len_sec: 535.3219999999999,
    lanemiles: 1260.6569999999992,
    delay: 437774.0,
    trkdelay: 106398.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.03,
    ghg: 2186.0,
    congcost: 1.1619389e7,
    allspeed: 59.79,
    trkspeed: 61.43,
    ttivmt: 711345.0,
    trkttivmt: 178000.0,
  },
  {
    agglevel: "county",
    regioncode: 195,
    year: 2019,
    count: 163,
    len_sec: 430.51900000000006,
    lanemiles: 1050.983,
    delay: 785970.0,
    trkdelay: 193418.0,
    pti80: 1.13,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.04,
    ghg: 4461.0,
    congcost: 2.1977793e7,
    allspeed: 58.78,
    trkspeed: 59.09,
    ttivmt: 748985.0,
    trkttivmt: 225857.0,
  },
  {
    agglevel: "county",
    regioncode: 195,
    year: 2020,
    count: 642,
    len_sec: 539.4209999999991,
    lanemiles: 1272.4369999999983,
    delay: 890308.0,
    trkdelay: 281549.0,
    pti80: 1.13,
    trkpti80: 1.15,
    pti95: 1.17,
    trkpti95: 1.21,
    tti: 1.05,
    trktti: 1.05,
    ghg: 7913.0,
    congcost: 2.8694558e7,
    allspeed: 57.66,
    trkspeed: 59.24,
    ttivmt: 768152.0,
    trkttivmt: 256076.0,
  },
  {
    agglevel: "county",
    regioncode: 195,
    year: 2021,
    count: 637,
    len_sec: 539.3649999999993,
    lanemiles: 1272.9259999999983,
    delay: 340576.0,
    trkdelay: 109658.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2979.0,
    congcost: 1.0650497e7,
    allspeed: 58.54,
    trkspeed: 61.06,
    ttivmt: 669638.0,
    trkttivmt: 238453.0,
  },
  {
    agglevel: "county",
    regioncode: 195,
    year: 2022,
    count: 657,
    len_sec: 541.3689999999992,
    lanemiles: 1278.7509999999993,
    delay: 407594.0,
    trkdelay: 105444.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2361.0,
    congcost: 1.3148887e7,
    allspeed: 60.04,
    trkspeed: 61.95,
    ttivmt: 698447.0,
    trkttivmt: 264796.0,
  },
  {
    agglevel: "county",
    regioncode: 196,
    year: 2017,
    count: 318,
    len_sec: 214.46099999999993,
    lanemiles: 501.7260000000006,
    delay: 106404.0,
    trkdelay: 23417.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 522.0,
    congcost: 2692175.0,
    allspeed: 61.09,
    trkspeed: 63.44,
    ttivmt: 285337.0,
    trkttivmt: 123302.0,
  },
  {
    agglevel: "county",
    regioncode: 196,
    year: 2018,
    count: 379,
    len_sec: 214.2970000000001,
    lanemiles: 504.02199999999993,
    delay: 110522.0,
    trkdelay: 26530.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.02,
    ghg: 700.0,
    congcost: 2956163.0,
    allspeed: 60.39,
    trkspeed: 60.88,
    ttivmt: 274240.0,
    trkttivmt: 64701.0,
  },
  {
    agglevel: "county",
    regioncode: 196,
    year: 2019,
    count: 85,
    len_sec: 155.40300000000005,
    lanemiles: 386.12999999999994,
    delay: 97806.0,
    trkdelay: 30663.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.02,
    ghg: 734.0,
    congcost: 2995588.0,
    allspeed: 61.39,
    trkspeed: 60.55,
    ttivmt: 261259.0,
    trkttivmt: 66177.0,
  },
  {
    agglevel: "county",
    regioncode: 196,
    year: 2020,
    count: 479,
    len_sec: 214.46399999999994,
    lanemiles: 504.3539999999999,
    delay: 113033.0,
    trkdelay: 25915.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 802.0,
    congcost: 3261023.0,
    allspeed: 61.08,
    trkspeed: 61.72,
    ttivmt: 276574.0,
    trkttivmt: 65282.0,
  },
  {
    agglevel: "county",
    regioncode: 196,
    year: 2021,
    count: 480,
    len_sec: 214.48699999999982,
    lanemiles: 504.4759999999999,
    delay: 84531.0,
    trkdelay: 19738.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 517.0,
    congcost: 2389075.0,
    allspeed: 61.28,
    trkspeed: 61.77,
    ttivmt: 285337.0,
    trkttivmt: 58796.0,
  },
  {
    agglevel: "county",
    regioncode: 196,
    year: 2022,
    count: 484,
    len_sec: 214.52199999999982,
    lanemiles: 504.5459999999999,
    delay: 162420.0,
    trkdelay: 34354.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.02,
    ghg: 780.0,
    congcost: 4942758.0,
    allspeed: 61.19,
    trkspeed: 61.46,
    ttivmt: 317651.0,
    trkttivmt: 65898.0,
  },
  {
    agglevel: "county",
    regioncode: 197,
    year: 2017,
    count: 89,
    len_sec: 120.41400000000003,
    lanemiles: 243.01400000000007,
    delay: 15657.0,
    trkdelay: 7644.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 108.0,
    congcost: 524376.0,
    allspeed: 62.02,
    trkspeed: 62.76,
    ttivmt: 36413.0,
    trkttivmt: 21643.0,
  },
  {
    agglevel: "county",
    regioncode: 197,
    year: 2018,
    count: 106,
    len_sec: 120.21799999999996,
    lanemiles: 241.57799999999992,
    delay: 14530.0,
    trkdelay: 7441.0,
    pti80: 1.08,
    trkpti80: 1.1,
    pti95: 1.1,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 96.0,
    congcost: 510029.0,
    allspeed: 60.97,
    trkspeed: 60.46,
    ttivmt: 32253.0,
    trkttivmt: 10781.0,
  },
  {
    agglevel: "county",
    regioncode: 197,
    year: 2019,
    count: 37,
    len_sec: 120.268,
    lanemiles: 241.678,
    delay: 14206.0,
    trkdelay: 8568.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 119.0,
    congcost: 570644.0,
    allspeed: 62.43,
    trkspeed: 60.92,
    ttivmt: 33035.0,
    trkttivmt: 10353.0,
  },
  {
    agglevel: "county",
    regioncode: 197,
    year: 2020,
    count: 152,
    len_sec: 120.32900000000001,
    lanemiles: 243.52800000000002,
    delay: 31381.0,
    trkdelay: 9395.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.16,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.05,
    ghg: 139.0,
    congcost: 964089.0,
    allspeed: 60.16,
    trkspeed: 60.11,
    ttivmt: 34604.0,
    trkttivmt: 8698.0,
  },
  {
    agglevel: "county",
    regioncode: 197,
    year: 2021,
    count: 149,
    len_sec: 120.316,
    lanemiles: 243.49800000000005,
    delay: 13659.0,
    trkdelay: 7991.0,
    pti80: 1.08,
    trkpti80: 1.11,
    pti95: 1.1,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 83.0,
    congcost: 524512.0,
    allspeed: 61.81,
    trkspeed: 61.81,
    ttivmt: 30864.0,
    trkttivmt: 10446.0,
  },
  {
    agglevel: "county",
    regioncode: 197,
    year: 2022,
    count: 148,
    len_sec: 120.317,
    lanemiles: 243.49999999999997,
    delay: 28136.0,
    trkdelay: 12090.0,
    pti80: 1.11,
    trkpti80: 1.17,
    pti95: 1.17,
    trkpti95: 1.3,
    tti: 1.03,
    trktti: 1.07,
    ghg: 177.0,
    congcost: 1073775.0,
    allspeed: 60.21,
    trkspeed: 58.21,
    ttivmt: 30613.0,
    trkttivmt: 7293.0,
  },
  {
    agglevel: "county",
    regioncode: 198,
    year: 2017,
    count: 439,
    len_sec: 309.6179999999997,
    lanemiles: 689.1719999999993,
    delay: 225315.0,
    trkdelay: 33084.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1069.0,
    congcost: 5199153.0,
    allspeed: 59.17,
    trkspeed: 60.75,
    ttivmt: 363326.0,
    trkttivmt: 70570.0,
  },
  {
    agglevel: "county",
    regioncode: 198,
    year: 2018,
    count: 463,
    len_sec: 309.93699999999995,
    lanemiles: 691.9949999999997,
    delay: 260145.0,
    trkdelay: 44853.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1391.0,
    congcost: 6364579.0,
    allspeed: 58.61,
    trkspeed: 59.27,
    ttivmt: 384758.0,
    trkttivmt: 65293.0,
  },
  {
    agglevel: "county",
    regioncode: 198,
    year: 2019,
    count: 133,
    len_sec: 228.69299999999993,
    lanemiles: 529.509,
    delay: 229055.0,
    trkdelay: 45762.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.03,
    ghg: 1411.0,
    congcost: 6091182.0,
    allspeed: 59.68,
    trkspeed: 59.33,
    ttivmt: 381759.0,
    trkttivmt: 65122.0,
  },
  {
    agglevel: "county",
    regioncode: 198,
    year: 2020,
    count: 682,
    len_sec: 309.9999999999997,
    lanemiles: 691.2739999999993,
    delay: 229458.0,
    trkdelay: 48696.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1488.0,
    congcost: 6443528.0,
    allspeed: 59.17,
    trkspeed: 59.52,
    ttivmt: 391988.0,
    trkttivmt: 75731.0,
  },
  {
    agglevel: "county",
    regioncode: 198,
    year: 2021,
    count: 671,
    len_sec: 309.84499999999923,
    lanemiles: 691.2119999999989,
    delay: 157709.0,
    trkdelay: 36177.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.09,
    tti: 1.02,
    trktti: 1.02,
    ghg: 958.0,
    congcost: 4428501.0,
    allspeed: 59.2,
    trkspeed: 59.85,
    ttivmt: 336200.0,
    trkttivmt: 75053.0,
  },
  {
    agglevel: "county",
    regioncode: 198,
    year: 2022,
    count: 685,
    len_sec: 309.78199999999924,
    lanemiles: 691.3779999999997,
    delay: 221577.0,
    trkdelay: 44653.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 1115.0,
    congcost: 6681846.0,
    allspeed: 58.76,
    trkspeed: 59.75,
    ttivmt: 344168.0,
    trkttivmt: 67158.0,
  },
  {
    agglevel: "county",
    regioncode: 199,
    year: 2017,
    count: 373,
    len_sec: 202.0979999999998,
    lanemiles: 490.06399999999957,
    delay: 2363124.0,
    trkdelay: 158464.0,
    pti80: 1.2,
    trkpti80: 1.19,
    pti95: 1.27,
    trkpti95: 1.27,
    tti: 1.09,
    trktti: 1.08,
    ghg: 9211.0,
    congcost: 4.8566293e7,
    allspeed: 50.27,
    trkspeed: 54.34,
    ttivmt: 1200543.0,
    trkttivmt: 118614.0,
  },
  {
    agglevel: "county",
    regioncode: 199,
    year: 2018,
    count: 432,
    len_sec: 203.49699999999987,
    lanemiles: 497.5309999999996,
    delay: 2379017.0,
    trkdelay: 112055.0,
    pti80: 1.25,
    trkpti80: 1.23,
    pti95: 1.33,
    trkpti95: 1.34,
    tti: 1.13,
    trktti: 1.1,
    ghg: 8623.0,
    congcost: 4.8384134e7,
    allspeed: 48.04,
    trkspeed: 55.96,
    ttivmt: 874829.0,
    trkttivmt: 81752.0,
  },
  {
    agglevel: "county",
    regioncode: 199,
    year: 2019,
    count: 291,
    len_sec: 203.584,
    lanemiles: 497.7019999999998,
    delay: 2283861.0,
    trkdelay: 116974.0,
    pti80: 1.22,
    trkpti80: 1.22,
    pti95: 1.29,
    trkpti95: 1.31,
    tti: 1.11,
    trktti: 1.1,
    ghg: 8436.0,
    congcost: 4.8445932e7,
    allspeed: 48.7,
    trkspeed: 54.49,
    ttivmt: 900408.0,
    trkttivmt: 75573.0,
  },
  {
    agglevel: "county",
    regioncode: 199,
    year: 2020,
    count: 604,
    len_sec: 203.59099999999998,
    lanemiles: 499.3529999999996,
    delay: 2587087.0,
    trkdelay: 131906.0,
    pti80: 1.25,
    trkpti80: 1.24,
    pti95: 1.34,
    trkpti95: 1.37,
    tti: 1.13,
    trktti: 1.11,
    ghg: 9987.0,
    congcost: 5.6499995e7,
    allspeed: 47.74,
    trkspeed: 55.87,
    ttivmt: 986547.0,
    trkttivmt: 91062.0,
  },
  {
    agglevel: "county",
    regioncode: 199,
    year: 2021,
    count: 686,
    len_sec: 203.54199999999997,
    lanemiles: 499.2159999999995,
    delay: 1781231.0,
    trkdelay: 106103.0,
    pti80: 1.17,
    trkpti80: 1.16,
    pti95: 1.22,
    trkpti95: 1.25,
    tti: 1.08,
    trktti: 1.07,
    ghg: 7368.0,
    congcost: 4.0520707e7,
    allspeed: 49.22,
    trkspeed: 57.33,
    ttivmt: 949501.0,
    trkttivmt: 99307.0,
  },
  {
    agglevel: "county",
    regioncode: 199,
    year: 2022,
    count: 740,
    len_sec: 204.74400000000023,
    lanemiles: 518.6950000000006,
    delay: 2717728.0,
    trkdelay: 204898.0,
    pti80: 1.22,
    trkpti80: 1.24,
    pti95: 1.28,
    trkpti95: 1.32,
    tti: 1.11,
    trktti: 1.11,
    ghg: 10558.0,
    congcost: 6.9661391e7,
    allspeed: 47.97,
    trkspeed: 55.55,
    ttivmt: 1156122.0,
    trkttivmt: 118522.0,
  },
  {
    agglevel: "county",
    regioncode: 200,
    year: 2017,
    count: 360,
    len_sec: 357.237,
    lanemiles: 763.4759999999998,
    delay: 84501.0,
    trkdelay: 14870.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.01,
    ghg: 336.0,
    congcost: 2012502.0,
    allspeed: 59.62,
    trkspeed: 62.5,
    ttivmt: 150472.0,
    trkttivmt: 44329.0,
  },
  {
    agglevel: "county",
    regioncode: 200,
    year: 2018,
    count: 502,
    len_sec: 356.96999999999974,
    lanemiles: 762.3149999999995,
    delay: 68832.0,
    trkdelay: 15374.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.03,
    ghg: 332.0,
    congcost: 1787132.0,
    allspeed: 56.78,
    trkspeed: 57.4,
    ttivmt: 144979.0,
    trkttivmt: 23707.0,
  },
  {
    agglevel: "county",
    regioncode: 200,
    year: 2019,
    count: 163,
    len_sec: 311.8449999999999,
    lanemiles: 672.1519999999998,
    delay: 83697.0,
    trkdelay: 22255.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.04,
    ghg: 490.0,
    congcost: 2401883.0,
    allspeed: 59.95,
    trkspeed: 58.86,
    ttivmt: 151799.0,
    trkttivmt: 26288.0,
  },
  {
    agglevel: "county",
    regioncode: 200,
    year: 2020,
    count: 633,
    len_sec: 357.1729999999996,
    lanemiles: 762.5859999999992,
    delay: 92046.0,
    trkdelay: 19002.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 460.0,
    congcost: 2538940.0,
    allspeed: 59.66,
    trkspeed: 60.38,
    ttivmt: 165166.0,
    trkttivmt: 28649.0,
  },
  {
    agglevel: "county",
    regioncode: 200,
    year: 2021,
    count: 618,
    len_sec: 356.2899999999997,
    lanemiles: 761.448999999999,
    delay: 78747.0,
    trkdelay: 14988.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.03,
    ghg: 361.0,
    congcost: 2102302.0,
    allspeed: 59.38,
    trkspeed: 59.98,
    ttivmt: 163168.0,
    trkttivmt: 25873.0,
  },
  {
    agglevel: "county",
    regioncode: 200,
    year: 2022,
    count: 614,
    len_sec: 356.39199999999965,
    lanemiles: 761.7409999999993,
    delay: 102445.0,
    trkdelay: 19904.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.03,
    ghg: 409.0,
    congcost: 3042580.0,
    allspeed: 59.53,
    trkspeed: 60.25,
    ttivmt: 164546.0,
    trkttivmt: 25747.0,
  },
  {
    agglevel: "county",
    regioncode: 201,
    year: 2017,
    count: 1028,
    len_sec: 621.8259999999996,
    lanemiles: 1384.327,
    delay: 695666.0,
    trkdelay: 72069.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.04,
    trktti: 1.03,
    ghg: 3150.0,
    congcost: 1.5126909e7,
    allspeed: 53.31,
    trkspeed: 57.85,
    ttivmt: 556422.0,
    trkttivmt: 90843.0,
  },
  {
    agglevel: "county",
    regioncode: 201,
    year: 2018,
    count: 1073,
    len_sec: 620.492999999999,
    lanemiles: 1380.468999999998,
    delay: 748847.0,
    trkdelay: 102607.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.15,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.06,
    ghg: 4010.0,
    congcost: 1.7527158e7,
    allspeed: 53.35,
    trkspeed: 55.53,
    ttivmt: 524139.0,
    trkttivmt: 77949.0,
  },
  {
    agglevel: "county",
    regioncode: 201,
    year: 2019,
    count: 534,
    len_sec: 620.1879999999995,
    lanemiles: 1381.1589999999985,
    delay: 687438.0,
    trkdelay: 126733.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.06,
    ghg: 4271.0,
    congcost: 1.7955291e7,
    allspeed: 55.75,
    trkspeed: 55.52,
    ttivmt: 551033.0,
    trkttivmt: 95365.0,
  },
  {
    agglevel: "county",
    regioncode: 201,
    year: 2020,
    count: 1649,
    len_sec: 620.3799999999991,
    lanemiles: 1375.5339999999992,
    delay: 745380.0,
    trkdelay: 121602.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.05,
    ghg: 4459.0,
    congcost: 1.9568153e7,
    allspeed: 55.51,
    trkspeed: 56.91,
    ttivmt: 568886.0,
    trkttivmt: 89269.0,
  },
  {
    agglevel: "county",
    regioncode: 201,
    year: 2021,
    count: 1624,
    len_sec: 620.6519999999981,
    lanemiles: 1375.6489999999965,
    delay: 496187.0,
    trkdelay: 88173.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.05,
    ghg: 3076.0,
    congcost: 1.3201386e7,
    allspeed: 55.85,
    trkspeed: 57.26,
    ttivmt: 507384.0,
    trkttivmt: 82534.0,
  },
  {
    agglevel: "county",
    regioncode: 201,
    year: 2022,
    count: 1639,
    len_sec: 620.697999999998,
    lanemiles: 1375.7409999999968,
    delay: 710171.0,
    trkdelay: 91404.0,
    pti80: 1.12,
    trkpti80: 1.12,
    pti95: 1.2,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.04,
    ghg: 3438.0,
    congcost: 1.9646814e7,
    allspeed: 55.02,
    trkspeed: 57.53,
    ttivmt: 519281.0,
    trkttivmt: 92301.0,
  },
  {
    agglevel: "county",
    regioncode: 202,
    year: 2017,
    count: 389,
    len_sec: 237.98699999999997,
    lanemiles: 483.297,
    delay: 137270.0,
    trkdelay: 22148.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.16,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.06,
    ghg: 640.0,
    congcost: 3223502.0,
    allspeed: 55.7,
    trkspeed: 56.84,
    ttivmt: 103024.0,
    trkttivmt: 17965.0,
  },
  {
    agglevel: "county",
    regioncode: 202,
    year: 2018,
    count: 354,
    len_sec: 236.87199999999993,
    lanemiles: 482.3689999999999,
    delay: 140053.0,
    trkdelay: 17484.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.15,
    trkpti95: 1.2,
    tti: 1.05,
    trktti: 1.05,
    ghg: 600.0,
    congcost: 3199384.0,
    allspeed: 51.69,
    trkspeed: 54.86,
    ttivmt: 111746.0,
    trkttivmt: 14084.0,
  },
  {
    agglevel: "county",
    regioncode: 202,
    year: 2019,
    count: 91,
    len_sec: 126.13799999999995,
    lanemiles: 260.9,
    delay: 84003.0,
    trkdelay: 27483.0,
    pti80: 1.11,
    trkpti80: 1.17,
    pti95: 1.13,
    trkpti95: 1.24,
    tti: 1.04,
    trktti: 1.07,
    ghg: 700.0,
    congcost: 2626089.0,
    allspeed: 57.2,
    trkspeed: 54.73,
    ttivmt: 86008.0,
    trkttivmt: 15779.0,
  },
  {
    agglevel: "county",
    regioncode: 202,
    year: 2020,
    count: 654,
    len_sec: 236.91100000000014,
    lanemiles: 481.96400000000006,
    delay: 132118.0,
    trkdelay: 25528.0,
    pti80: 1.1,
    trkpti80: 1.15,
    pti95: 1.14,
    trkpti95: 1.21,
    tti: 1.04,
    trktti: 1.06,
    ghg: 780.0,
    congcost: 3610795.0,
    allspeed: 55.96,
    trkspeed: 55.59,
    ttivmt: 118063.0,
    trkttivmt: 16990.0,
  },
  {
    agglevel: "county",
    regioncode: 202,
    year: 2021,
    count: 662,
    len_sec: 236.89899999999994,
    lanemiles: 481.9589999999997,
    delay: 103955.0,
    trkdelay: 20672.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.15,
    trkpti95: 1.21,
    tti: 1.04,
    trktti: 1.06,
    ghg: 582.0,
    congcost: 2820306.0,
    allspeed: 56.09,
    trkspeed: 55.97,
    ttivmt: 114444.0,
    trkttivmt: 16321.0,
  },
  {
    agglevel: "county",
    regioncode: 202,
    year: 2022,
    count: 663,
    len_sec: 236.9310000000001,
    lanemiles: 482.0229999999999,
    delay: 184087.0,
    trkdelay: 29534.0,
    pti80: 1.15,
    trkpti80: 1.16,
    pti95: 1.27,
    trkpti95: 1.26,
    tti: 1.07,
    trktti: 1.06,
    ghg: 884.0,
    congcost: 5291378.0,
    allspeed: 54.44,
    trkspeed: 56.48,
    ttivmt: 116360.0,
    trkttivmt: 21531.0,
  },
  {
    agglevel: "county",
    regioncode: 203,
    year: 2017,
    count: 395,
    len_sec: 268.6509999999999,
    lanemiles: 550.073,
    delay: 128340.0,
    trkdelay: 13300.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.04,
    ghg: 474.0,
    congcost: 2775393.0,
    allspeed: 57.14,
    trkspeed: 59.71,
    ttivmt: 107946.0,
    trkttivmt: 15718.0,
  },
  {
    agglevel: "county",
    regioncode: 203,
    year: 2018,
    count: 383,
    len_sec: 269.02999999999975,
    lanemiles: 550.3519999999995,
    delay: 130295.0,
    trkdelay: 18373.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.13,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.06,
    ghg: 501.0,
    congcost: 3028612.0,
    allspeed: 53.28,
    trkspeed: 55.77,
    ttivmt: 116916.0,
    trkttivmt: 14651.0,
  },
  {
    agglevel: "county",
    regioncode: 203,
    year: 2019,
    count: 132,
    len_sec: 177.21499999999992,
    lanemiles: 366.72399999999976,
    delay: 105476.0,
    trkdelay: 18306.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.12,
    trkpti95: 1.21,
    tti: 1.04,
    trktti: 1.06,
    ghg: 481.0,
    congcost: 2677950.0,
    allspeed: 57.73,
    trkspeed: 58.28,
    ttivmt: 104538.0,
    trkttivmt: 13689.0,
  },
  {
    agglevel: "county",
    regioncode: 203,
    year: 2020,
    count: 639,
    len_sec: 269.1310000000001,
    lanemiles: 549.4699999999998,
    delay: 149441.0,
    trkdelay: 18522.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.15,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.05,
    ghg: 655.0,
    congcost: 3671121.0,
    allspeed: 57.11,
    trkspeed: 58.93,
    ttivmt: 118223.0,
    trkttivmt: 14659.0,
  },
  {
    agglevel: "county",
    regioncode: 203,
    year: 2021,
    count: 645,
    len_sec: 269.2119999999998,
    lanemiles: 547.6819999999994,
    delay: 116387.0,
    trkdelay: 16521.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.05,
    ghg: 511.0,
    congcost: 2938925.0,
    allspeed: 57.43,
    trkspeed: 59.23,
    ttivmt: 121518.0,
    trkttivmt: 14829.0,
  },
  {
    agglevel: "county",
    regioncode: 203,
    year: 2022,
    count: 650,
    len_sec: 269.15599999999984,
    lanemiles: 546.4239999999992,
    delay: 163406.0,
    trkdelay: 25879.0,
    pti80: 1.13,
    trkpti80: 1.13,
    pti95: 1.21,
    trkpti95: 1.2,
    tti: 1.05,
    trktti: 1.05,
    ghg: 713.0,
    congcost: 4666370.0,
    allspeed: 55.8,
    trkspeed: 58.7,
    ttivmt: 119253.0,
    trkttivmt: 22196.0,
  },
  {
    agglevel: "county",
    regioncode: 204,
    year: 2017,
    count: 312,
    len_sec: 246.48299999999995,
    lanemiles: 539.1199999999999,
    delay: 215930.0,
    trkdelay: 27292.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.02,
    ghg: 873.0,
    congcost: 4822503.0,
    allspeed: 58.35,
    trkspeed: 61.4,
    ttivmt: 274613.0,
    trkttivmt: 65943.0,
  },
  {
    agglevel: "county",
    regioncode: 204,
    year: 2018,
    count: 290,
    len_sec: 247.12599999999992,
    lanemiles: 540.8629999999996,
    delay: 238204.0,
    trkdelay: 21896.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.03,
    ghg: 907.0,
    congcost: 5174237.0,
    allspeed: 57.67,
    trkspeed: 59.97,
    ttivmt: 292752.0,
    trkttivmt: 37982.0,
  },
  {
    agglevel: "county",
    regioncode: 204,
    year: 2019,
    count: 92,
    len_sec: 130.49599999999998,
    lanemiles: 307.60200000000003,
    delay: 158668.0,
    trkdelay: 27429.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.03,
    ghg: 678.0,
    congcost: 4008088.0,
    allspeed: 60.36,
    trkspeed: 59.96,
    ttivmt: 276557.0,
    trkttivmt: 46387.0,
  },
  {
    agglevel: "county",
    regioncode: 204,
    year: 2020,
    count: 468,
    len_sec: 249.6660000000001,
    lanemiles: 545.1639999999994,
    delay: 282881.0,
    trkdelay: 29006.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 1042.0,
    congcost: 6680233.0,
    allspeed: 59.01,
    trkspeed: 61.48,
    ttivmt: 333770.0,
    trkttivmt: 44444.0,
  },
  {
    agglevel: "county",
    regioncode: 204,
    year: 2021,
    count: 463,
    len_sec: 249.444,
    lanemiles: 545.5590000000002,
    delay: 210440.0,
    trkdelay: 23320.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.02,
    ghg: 820.0,
    congcost: 5095049.0,
    allspeed: 59.49,
    trkspeed: 61.96,
    ttivmt: 315245.0,
    trkttivmt: 43498.0,
  },
  {
    agglevel: "county",
    regioncode: 204,
    year: 2022,
    count: 461,
    len_sec: 249.45999999999992,
    lanemiles: 545.5909999999996,
    delay: 346919.0,
    trkdelay: 35285.0,
    pti80: 1.12,
    trkpti80: 1.09,
    pti95: 1.2,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.03,
    ghg: 1111.0,
    congcost: 9131974.0,
    allspeed: 58.74,
    trkspeed: 61.87,
    ttivmt: 361030.0,
    trkttivmt: 60867.0,
  },
  {
    agglevel: "county",
    regioncode: 205,
    year: 2017,
    count: 1078,
    len_sec: 459.938,
    lanemiles: 1156.3309999999988,
    delay: 1381034.0,
    trkdelay: 96393.0,
    pti80: 1.12,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.03,
    ghg: 6263.0,
    congcost: 2.8673445e7,
    allspeed: 53.69,
    trkspeed: 59.02,
    ttivmt: 1061094.0,
    trkttivmt: 147496.0,
  },
  {
    agglevel: "county",
    regioncode: 205,
    year: 2018,
    count: 983,
    len_sec: 462.77299999999923,
    lanemiles: 1161.7409999999986,
    delay: 1100217.0,
    trkdelay: 98579.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.04,
    ghg: 5194.0,
    congcost: 2.4045406e7,
    allspeed: 56.32,
    trkspeed: 58.13,
    ttivmt: 952427.0,
    trkttivmt: 99647.0,
  },
  {
    agglevel: "county",
    regioncode: 205,
    year: 2019,
    count: 384,
    len_sec: 319.95900000000006,
    lanemiles: 869.6459999999995,
    delay: 846410.0,
    trkdelay: 112981.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 4581.0,
    congcost: 2.0565734e7,
    allspeed: 58.08,
    trkspeed: 57.88,
    ttivmt: 956229.0,
    trkttivmt: 107600.0,
  },
  {
    agglevel: "county",
    regioncode: 205,
    year: 2020,
    count: 1361,
    len_sec: 463.22899999999936,
    lanemiles: 1164.491999999998,
    delay: 1119973.0,
    trkdelay: 121434.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.04,
    ghg: 5624.0,
    congcost: 2.7021338e7,
    allspeed: 56.99,
    trkspeed: 58.8,
    ttivmt: 1010375.0,
    trkttivmt: 107366.0,
  },
  {
    agglevel: "county",
    regioncode: 205,
    year: 2021,
    count: 1356,
    len_sec: 462.6699999999985,
    lanemiles: 1164.2559999999974,
    delay: 819944.0,
    trkdelay: 116310.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.19,
    tti: 1.03,
    trktti: 1.04,
    ghg: 4472.0,
    congcost: 2.083653e7,
    allspeed: 56.86,
    trkspeed: 59.16,
    ttivmt: 917920.0,
    trkttivmt: 107965.0,
  },
  {
    agglevel: "county",
    regioncode: 205,
    year: 2022,
    count: 1374,
    len_sec: 462.0709999999995,
    lanemiles: 1164.0759999999987,
    delay: 954366.0,
    trkdelay: 108774.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.18,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 4444.0,
    congcost: 2.590972e7,
    allspeed: 56.68,
    trkspeed: 59.33,
    ttivmt: 1005426.0,
    trkttivmt: 118313.0,
  },
  {
    agglevel: "county",
    regioncode: 206,
    year: 2017,
    count: 274,
    len_sec: 294.01300000000003,
    lanemiles: 591.472,
    delay: 59487.0,
    trkdelay: 7616.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 245.0,
    congcost: 1334308.0,
    allspeed: 57.21,
    trkspeed: 59.74,
    ttivmt: 67017.0,
    trkttivmt: 12212.0,
  },
  {
    agglevel: "county",
    regioncode: 206,
    year: 2018,
    count: 300,
    len_sec: 293.5900000000002,
    lanemiles: 590.6660000000004,
    delay: 52397.0,
    trkdelay: 11406.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.11,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.05,
    ghg: 289.0,
    congcost: 1359245.0,
    allspeed: 54.75,
    trkspeed: 55.77,
    ttivmt: 71046.0,
    trkttivmt: 11122.0,
  },
  {
    agglevel: "county",
    regioncode: 206,
    year: 2019,
    count: 71,
    len_sec: 161.61999999999998,
    lanemiles: 326.72599999999994,
    delay: 58398.0,
    trkdelay: 15083.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.12,
    trkpti95: 1.2,
    tti: 1.03,
    trktti: 1.05,
    ghg: 368.0,
    congcost: 1668659.0,
    allspeed: 58.46,
    trkspeed: 57.0,
    ttivmt: 62189.0,
    trkttivmt: 11923.0,
  },
  {
    agglevel: "county",
    regioncode: 206,
    year: 2020,
    count: 449,
    len_sec: 293.8189999999998,
    lanemiles: 592.5369999999996,
    delay: 74690.0,
    trkdelay: 8873.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 320.0,
    congcost: 1820870.0,
    allspeed: 57.19,
    trkspeed: 58.37,
    ttivmt: 78263.0,
    trkttivmt: 8392.0,
  },
  {
    agglevel: "county",
    regioncode: 206,
    year: 2021,
    count: 451,
    len_sec: 293.81899999999945,
    lanemiles: 592.536999999999,
    delay: 64918.0,
    trkdelay: 8945.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 296.0,
    congcost: 1634990.0,
    allspeed: 56.61,
    trkspeed: 58.12,
    ttivmt: 81222.0,
    trkttivmt: 9904.0,
  },
  {
    agglevel: "county",
    regioncode: 206,
    year: 2022,
    count: 451,
    len_sec: 293.81999999999977,
    lanemiles: 592.5389999999996,
    delay: 94268.0,
    trkdelay: 12701.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.2,
    trkpti95: 1.27,
    tti: 1.04,
    trktti: 1.06,
    ghg: 366.0,
    congcost: 2609575.0,
    allspeed: 56.46,
    trkspeed: 57.42,
    ttivmt: 81130.0,
    trkttivmt: 8878.0,
  },
  {
    agglevel: "county",
    regioncode: 207,
    year: 2017,
    count: 101,
    len_sec: 187.91500000000005,
    lanemiles: 380.176,
    delay: 17875.0,
    trkdelay: 3384.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 74.0,
    congcost: 433566.0,
    allspeed: 62.06,
    trkspeed: 63.83,
    ttivmt: 52958.0,
    trkttivmt: 21889.0,
  },
  {
    agglevel: "county",
    regioncode: 207,
    year: 2018,
    count: 122,
    len_sec: 188.32000000000008,
    lanemiles: 380.9640000000001,
    delay: 15506.0,
    trkdelay: 3458.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.02,
    ghg: 79.0,
    congcost: 403225.0,
    allspeed: 60.18,
    trkspeed: 60.69,
    ttivmt: 52901.0,
    trkttivmt: 7421.0,
  },
  {
    agglevel: "county",
    regioncode: 207,
    year: 2019,
    count: 37,
    len_sec: 143.83000000000004,
    lanemiles: 291.98400000000004,
    delay: 16217.0,
    trkdelay: 5402.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.02,
    ghg: 88.0,
    congcost: 500103.0,
    allspeed: 62.58,
    trkspeed: 61.82,
    ttivmt: 53314.0,
    trkttivmt: 10343.0,
  },
  {
    agglevel: "county",
    regioncode: 207,
    year: 2020,
    count: 168,
    len_sec: 188.15400000000002,
    lanemiles: 380.634,
    delay: 21355.0,
    trkdelay: 5615.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 116.0,
    congcost: 633147.0,
    allspeed: 61.77,
    trkspeed: 62.34,
    ttivmt: 50769.0,
    trkttivmt: 9934.0,
  },
  {
    agglevel: "county",
    regioncode: 207,
    year: 2021,
    count: 166,
    len_sec: 188.1540000000001,
    lanemiles: 380.5240000000001,
    delay: 17684.0,
    trkdelay: 3865.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.02,
    ghg: 84.0,
    congcost: 486806.0,
    allspeed: 62.02,
    trkspeed: 62.6,
    ttivmt: 53844.0,
    trkttivmt: 8171.0,
  },
  {
    agglevel: "county",
    regioncode: 207,
    year: 2022,
    count: 166,
    len_sec: 188.16000000000005,
    lanemiles: 380.53600000000006,
    delay: 29972.0,
    trkdelay: 6133.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.02,
    trktti: 1.03,
    ghg: 105.0,
    congcost: 899130.0,
    allspeed: 61.79,
    trkspeed: 61.94,
    ttivmt: 57751.0,
    trkttivmt: 8246.0,
  },
  {
    agglevel: "county",
    regioncode: 208,
    year: 2017,
    count: 394,
    len_sec: 360.0759999999996,
    lanemiles: 829.6159999999998,
    delay: 245110.0,
    trkdelay: 18595.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.01,
    ghg: 1108.0,
    congcost: 5133975.0,
    allspeed: 56.96,
    trkspeed: 63.01,
    ttivmt: 289852.0,
    trkttivmt: 96898.0,
  },
  {
    agglevel: "county",
    regioncode: 208,
    year: 2018,
    count: 394,
    len_sec: 359.8839999999999,
    lanemiles: 830.2999999999996,
    delay: 161761.0,
    trkdelay: 31086.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1061.0,
    congcost: 4096760.0,
    allspeed: 58.13,
    trkspeed: 59.29,
    ttivmt: 264429.0,
    trkttivmt: 51126.0,
  },
  {
    agglevel: "county",
    regioncode: 208,
    year: 2019,
    count: 237,
    len_sec: 359.2960000000001,
    lanemiles: 829.1299999999999,
    delay: 176193.0,
    trkdelay: 33088.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 1103.0,
    congcost: 4628318.0,
    allspeed: 59.7,
    trkspeed: 60.68,
    ttivmt: 272717.0,
    trkttivmt: 54300.0,
  },
  {
    agglevel: "county",
    regioncode: 208,
    year: 2020,
    count: 559,
    len_sec: 359.97700000000026,
    lanemiles: 831.1570000000004,
    delay: 172470.0,
    trkdelay: 28448.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 951.0,
    congcost: 4526083.0,
    allspeed: 59.63,
    trkspeed: 61.79,
    ttivmt: 271175.0,
    trkttivmt: 55039.0,
  },
  {
    agglevel: "county",
    regioncode: 208,
    year: 2021,
    count: 562,
    len_sec: 359.97699999999935,
    lanemiles: 830.8769999999995,
    delay: 107824.0,
    trkdelay: 20856.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 589.0,
    congcost: 2903890.0,
    allspeed: 58.76,
    trkspeed: 61.53,
    ttivmt: 216641.0,
    trkttivmt: 48013.0,
  },
  {
    agglevel: "county",
    regioncode: 208,
    year: 2022,
    count: 573,
    len_sec: 360.09199999999953,
    lanemiles: 831.1370000000007,
    delay: 190379.0,
    trkdelay: 34103.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.18,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 927.0,
    congcost: 5590209.0,
    allspeed: 58.71,
    trkspeed: 60.96,
    ttivmt: 265683.0,
    trkttivmt: 55810.0,
  },
  {
    agglevel: "county",
    regioncode: 209,
    year: 2017,
    count: 141,
    len_sec: 176.20699999999997,
    lanemiles: 359.465,
    delay: 26404.0,
    trkdelay: 5858.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 125.0,
    congcost: 668361.0,
    allspeed: 60.96,
    trkspeed: 63.0,
    ttivmt: 61661.0,
    trkttivmt: 21592.0,
  },
  {
    agglevel: "county",
    regioncode: 209,
    year: 2018,
    count: 228,
    len_sec: 175.74999999999997,
    lanemiles: 357.32899999999995,
    delay: 28001.0,
    trkdelay: 8876.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.18,
    tti: 1.02,
    trktti: 1.04,
    ghg: 150.0,
    congcost: 810865.0,
    allspeed: 58.72,
    trkspeed: 59.3,
    ttivmt: 57865.0,
    trkttivmt: 9836.0,
  },
  {
    agglevel: "county",
    regioncode: 209,
    year: 2019,
    count: 91,
    len_sec: 175.836,
    lanemiles: 357.5010000000001,
    delay: 27824.0,
    trkdelay: 8913.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.17,
    tti: 1.02,
    trktti: 1.04,
    ghg: 143.0,
    congcost: 842656.0,
    allspeed: 61.3,
    trkspeed: 60.17,
    ttivmt: 55028.0,
    trkttivmt: 9329.0,
  },
  {
    agglevel: "county",
    regioncode: 209,
    year: 2020,
    count: 305,
    len_sec: 175.85999999999999,
    lanemiles: 357.54900000000004,
    delay: 37423.0,
    trkdelay: 7493.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 157.0,
    congcost: 1018344.0,
    allspeed: 60.76,
    trkspeed: 60.91,
    ttivmt: 62377.0,
    trkttivmt: 9317.0,
  },
  {
    agglevel: "county",
    regioncode: 209,
    year: 2021,
    count: 300,
    len_sec: 175.61500000000004,
    lanemiles: 357.1780000000001,
    delay: 30581.0,
    trkdelay: 7809.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.02,
    trktti: 1.04,
    ghg: 150.0,
    congcost: 876613.0,
    allspeed: 60.42,
    trkspeed: 60.62,
    ttivmt: 55362.0,
    trkttivmt: 8336.0,
  },
  {
    agglevel: "county",
    regioncode: 209,
    year: 2022,
    count: 301,
    len_sec: 175.676,
    lanemiles: 357.3140000000001,
    delay: 64202.0,
    trkdelay: 12541.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.16,
    trkpti95: 1.24,
    tti: 1.03,
    trktti: 1.05,
    ghg: 233.0,
    congcost: 1901341.0,
    allspeed: 60.12,
    trkspeed: 60.22,
    ttivmt: 65141.0,
    trkttivmt: 9810.0,
  },
  {
    agglevel: "county",
    regioncode: 210,
    year: 2017,
    count: 668,
    len_sec: 386.06099999999986,
    lanemiles: 886.5700000000003,
    delay: 333759.0,
    trkdelay: 29423.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.08,
    tti: 1.03,
    trktti: 1.02,
    ghg: 1231.0,
    congcost: 7059182.0,
    allspeed: 55.13,
    trkspeed: 59.51,
    ttivmt: 303138.0,
    trkttivmt: 52501.0,
  },
  {
    agglevel: "county",
    regioncode: 210,
    year: 2018,
    count: 603,
    len_sec: 388.2899999999992,
    lanemiles: 888.2709999999992,
    delay: 380026.0,
    trkdelay: 57500.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.12,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.06,
    ghg: 1885.0,
    congcost: 9036935.0,
    allspeed: 53.64,
    trkspeed: 55.31,
    ttivmt: 297080.0,
    trkttivmt: 44024.0,
  },
  {
    agglevel: "county",
    regioncode: 210,
    year: 2019,
    count: 305,
    len_sec: 388.412,
    lanemiles: 888.5119999999998,
    delay: 318137.0,
    trkdelay: 52879.0,
    pti80: 1.08,
    trkpti80: 1.12,
    pti95: 1.1,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.05,
    ghg: 1673.0,
    congcost: 8050209.0,
    allspeed: 55.56,
    trkspeed: 55.87,
    ttivmt: 306883.0,
    trkttivmt: 45094.0,
  },
  {
    agglevel: "county",
    regioncode: 210,
    year: 2020,
    count: 956,
    len_sec: 388.24799999999954,
    lanemiles: 885.3689999999986,
    delay: 350911.0,
    trkdelay: 57453.0,
    pti80: 1.09,
    trkpti80: 1.14,
    pti95: 1.11,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.06,
    ghg: 1905.0,
    congcost: 9180445.0,
    allspeed: 55.7,
    trkspeed: 56.52,
    ttivmt: 324609.0,
    trkttivmt: 40350.0,
  },
  {
    agglevel: "county",
    regioncode: 210,
    year: 2021,
    count: 953,
    len_sec: 387.2339999999992,
    lanemiles: 884.3159999999993,
    delay: 292392.0,
    trkdelay: 53076.0,
    pti80: 1.09,
    trkpti80: 1.13,
    pti95: 1.12,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.06,
    ghg: 1672.0,
    congcost: 7784646.0,
    allspeed: 55.67,
    trkspeed: 56.72,
    ttivmt: 296682.0,
    trkttivmt: 38179.0,
  },
  {
    agglevel: "county",
    regioncode: 210,
    year: 2022,
    count: 971,
    len_sec: 387.23999999999984,
    lanemiles: 884.2759999999988,
    delay: 342608.0,
    trkdelay: 52135.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.18,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.03,
    ghg: 1491.0,
    congcost: 9703391.0,
    allspeed: 55.42,
    trkspeed: 57.93,
    ttivmt: 298333.0,
    trkttivmt: 64454.0,
  },
  {
    agglevel: "county",
    regioncode: 211,
    year: 2017,
    count: 128,
    len_sec: 199.87999999999985,
    lanemiles: 439.48799999999983,
    delay: 54430.0,
    trkdelay: 36404.0,
    pti80: 1.06,
    trkpti80: 1.05,
    pti95: 1.07,
    trkpti95: 1.06,
    tti: 1.02,
    trktti: 1.02,
    ghg: 254.0,
    congcost: 2088539.0,
    allspeed: 61.38,
    trkspeed: 63.08,
    ttivmt: 89210.0,
    trkttivmt: 115619.0,
  },
  {
    agglevel: "county",
    regioncode: 211,
    year: 2018,
    count: 142,
    len_sec: 199.89100000000002,
    lanemiles: 441.19,
    delay: 27451.0,
    trkdelay: 11275.0,
    pti80: 1.07,
    trkpti80: 1.05,
    pti95: 1.08,
    trkpti95: 1.06,
    tti: 1.01,
    trktti: 1.01,
    ghg: 189.0,
    congcost: 877732.0,
    allspeed: 60.97,
    trkspeed: 60.75,
    ttivmt: 99472.0,
    trkttivmt: 40681.0,
  },
  {
    agglevel: "county",
    regioncode: 211,
    year: 2019,
    count: 67,
    len_sec: 199.8979999999999,
    lanemiles: 441.20399999999984,
    delay: 28014.0,
    trkdelay: 13578.0,
    pti80: 1.08,
    trkpti80: 1.06,
    pti95: 1.08,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.02,
    ghg: 202.0,
    congcost: 1007904.0,
    allspeed: 61.71,
    trkspeed: 60.87,
    ttivmt: 94940.0,
    trkttivmt: 35637.0,
  },
  {
    agglevel: "county",
    regioncode: 211,
    year: 2020,
    count: 213,
    len_sec: 199.91899999999993,
    lanemiles: 454.88499999999976,
    delay: 27341.0,
    trkdelay: 8146.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 146.0,
    congcost: 843086.0,
    allspeed: 61.47,
    trkspeed: 61.56,
    ttivmt: 100016.0,
    trkttivmt: 37213.0,
  },
  {
    agglevel: "county",
    regioncode: 211,
    year: 2021,
    count: 207,
    len_sec: 199.91899999999993,
    lanemiles: 454.88499999999993,
    delay: 31001.0,
    trkdelay: 13417.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 285.0,
    congcost: 1072230.0,
    allspeed: 61.31,
    trkspeed: 61.38,
    ttivmt: 108862.0,
    trkttivmt: 53156.0,
  },
  {
    agglevel: "county",
    regioncode: 211,
    year: 2022,
    count: 207,
    len_sec: 199.91999999999993,
    lanemiles: 454.8870000000001,
    delay: 42736.0,
    trkdelay: 20758.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.02,
    ghg: 329.0,
    congcost: 1722519.0,
    allspeed: 61.45,
    trkspeed: 61.41,
    ttivmt: 122416.0,
    trkttivmt: 55763.0,
  },
  {
    agglevel: "county",
    regioncode: 212,
    year: 2017,
    count: 1798,
    len_sec: 918.3819999999981,
    lanemiles: 2288.100999999995,
    delay: 9375983.0,
    trkdelay: 464688.0,
    pti80: 1.18,
    trkpti80: 1.15,
    pti95: 1.23,
    trkpti95: 1.2,
    tti: 1.09,
    trktti: 1.05,
    ghg: 41969.0,
    congcost: 1.88919338e8,
    allspeed: 44.03,
    trkspeed: 55.18,
    ttivmt: 3572391.0,
    trkttivmt: 344986.0,
  },
  {
    agglevel: "county",
    regioncode: 212,
    year: 2018,
    count: 1514,
    len_sec: 926.7549999999993,
    lanemiles: 2309.2869999999975,
    delay: 6897853.0,
    trkdelay: 607219.0,
    pti80: 1.19,
    trkpti80: 1.21,
    pti95: 1.23,
    trkpti95: 1.26,
    tti: 1.1,
    trktti: 1.09,
    ghg: 35314.0,
    congcost: 1.50900614e8,
    allspeed: 48.48,
    trkspeed: 53.16,
    ttivmt: 2508682.0,
    trkttivmt: 286251.0,
  },
  {
    agglevel: "county",
    regioncode: 212,
    year: 2019,
    count: 1081,
    len_sec: 917.7439999999996,
    lanemiles: 2293.907999999996,
    delay: 6827566.0,
    trkdelay: 624168.0,
    pti80: 1.18,
    trkpti80: 1.2,
    pti95: 1.22,
    trkpti95: 1.25,
    tti: 1.09,
    trktti: 1.09,
    ghg: 35850.0,
    congcost: 1.56303531e8,
    allspeed: 48.23,
    trkspeed: 52.08,
    ttivmt: 2627896.0,
    trkttivmt: 284286.0,
  },
  {
    agglevel: "county",
    regioncode: 212,
    year: 2020,
    count: 3061,
    len_sec: 933.5739999999959,
    lanemiles: 2312.2899999999877,
    delay: 6584340.0,
    trkdelay: 556889.0,
    pti80: 1.17,
    trkpti80: 1.18,
    pti95: 1.2,
    trkpti95: 1.23,
    tti: 1.08,
    trktti: 1.08,
    ghg: 34717.0,
    congcost: 1.5368424e8,
    allspeed: 48.44,
    trkspeed: 54.64,
    ttivmt: 2678867.0,
    trkttivmt: 280473.0,
  },
  {
    agglevel: "county",
    regioncode: 212,
    year: 2021,
    count: 3070,
    len_sec: 932.892999999993,
    lanemiles: 2315.5739999999937,
    delay: 4038153.0,
    trkdelay: 400712.0,
    pti80: 1.14,
    trkpti80: 1.15,
    pti95: 1.18,
    trkpti95: 1.22,
    tti: 1.06,
    trktti: 1.06,
    ghg: 21705.0,
    congcost: 9.7528931e7,
    allspeed: 49.25,
    trkspeed: 55.81,
    ttivmt: 2360640.0,
    trkttivmt: 272178.0,
  },
  {
    agglevel: "county",
    regioncode: 212,
    year: 2022,
    count: 3162,
    len_sec: 935.110999999994,
    lanemiles: 2320.260999999993,
    delay: 6332154.0,
    trkdelay: 502684.0,
    pti80: 1.18,
    trkpti80: 1.14,
    pti95: 1.25,
    trkpti95: 1.21,
    tti: 1.08,
    trktti: 1.06,
    ghg: 30155.0,
    congcost: 1.64700718e8,
    allspeed: 48.19,
    trkspeed: 56.01,
    ttivmt: 2677638.0,
    trkttivmt: 357617.0,
  },
  {
    agglevel: "county",
    regioncode: 213,
    year: 2017,
    count: 121,
    len_sec: 100.98799999999999,
    lanemiles: 216.39599999999993,
    delay: 125019.0,
    trkdelay: 12965.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.1,
    trkpti95: 1.1,
    tti: 1.04,
    trktti: 1.03,
    ghg: 404.0,
    congcost: 2690022.0,
    allspeed: 55.37,
    trkspeed: 58.34,
    ttivmt: 109475.0,
    trkttivmt: 15810.0,
  },
  {
    agglevel: "county",
    regioncode: 213,
    year: 2018,
    count: 134,
    len_sec: 100.47400000000003,
    lanemiles: 215.351,
    delay: 122829.0,
    trkdelay: 16924.0,
    pti80: 1.1,
    trkpti80: 1.16,
    pti95: 1.13,
    trkpti95: 1.23,
    tti: 1.05,
    trktti: 1.07,
    ghg: 487.0,
    congcost: 2844069.0,
    allspeed: 52.36,
    trkspeed: 51.19,
    ttivmt: 101320.0,
    trkttivmt: 11034.0,
  },
  {
    agglevel: "county",
    regioncode: 213,
    year: 2019,
    count: 68,
    len_sec: 100.49199999999998,
    lanemiles: 215.38699999999994,
    delay: 118725.0,
    trkdelay: 15946.0,
    pti80: 1.1,
    trkpti80: 1.18,
    pti95: 1.11,
    trkpti95: 1.25,
    tti: 1.04,
    trktti: 1.08,
    ghg: 489.0,
    congcost: 2847480.0,
    allspeed: 54.87,
    trkspeed: 53.62,
    ttivmt: 103428.0,
    trkttivmt: 8923.0,
  },
  {
    agglevel: "county",
    regioncode: 213,
    year: 2020,
    count: 232,
    len_sec: 100.49400000000004,
    lanemiles: 217.64100000000005,
    delay: 140544.0,
    trkdelay: 16051.0,
    pti80: 1.1,
    trkpti80: 1.16,
    pti95: 1.12,
    trkpti95: 1.23,
    tti: 1.04,
    trktti: 1.07,
    ghg: 580.0,
    congcost: 3391489.0,
    allspeed: 55.0,
    trkspeed: 55.75,
    ttivmt: 114333.0,
    trkttivmt: 9534.0,
  },
  {
    agglevel: "county",
    regioncode: 213,
    year: 2021,
    count: 233,
    len_sec: 100.494,
    lanemiles: 217.64100000000008,
    delay: 117216.0,
    trkdelay: 17222.0,
    pti80: 1.11,
    trkpti80: 1.18,
    pti95: 1.14,
    trkpti95: 1.27,
    tti: 1.05,
    trktti: 1.09,
    ghg: 515.0,
    congcost: 2973164.0,
    allspeed: 54.61,
    trkspeed: 55.26,
    ttivmt: 97907.0,
    trkttivmt: 9516.0,
  },
  {
    agglevel: "county",
    regioncode: 213,
    year: 2022,
    count: 235,
    len_sec: 100.53400000000005,
    lanemiles: 217.75699999999998,
    delay: 124432.0,
    trkdelay: 16537.0,
    pti80: 1.1,
    trkpti80: 1.16,
    pti95: 1.15,
    trkpti95: 1.25,
    tti: 1.04,
    trktti: 1.07,
    ghg: 399.0,
    congcost: 3408371.0,
    allspeed: 52.54,
    trkspeed: 53.84,
    ttivmt: 101960.0,
    trkttivmt: 10155.0,
  },
  {
    agglevel: "county",
    regioncode: 214,
    year: 2017,
    count: 342,
    len_sec: 285.793,
    lanemiles: 637.496,
    delay: 1107996.0,
    trkdelay: 32728.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.06,
    trktti: 1.05,
    ghg: 4397.0,
    congcost: 2.1571318e7,
    allspeed: 44.65,
    trkspeed: 49.66,
    ttivmt: 595265.0,
    trkttivmt: 28588.0,
  },
  {
    agglevel: "county",
    regioncode: 214,
    year: 2018,
    count: 353,
    len_sec: 286.654,
    lanemiles: 638.8700000000001,
    delay: 947280.0,
    trkdelay: 52799.0,
    pti80: 1.13,
    trkpti80: 1.19,
    pti95: 1.16,
    trkpti95: 1.25,
    tti: 1.07,
    trktti: 1.09,
    ghg: 4000.0,
    congcost: 1.9629974e7,
    allspeed: 44.75,
    trkspeed: 47.06,
    ttivmt: 454580.0,
    trkttivmt: 24126.0,
  },
  {
    agglevel: "county",
    regioncode: 214,
    year: 2019,
    count: 58,
    len_sec: 72.07000000000005,
    lanemiles: 214.07000000000002,
    delay: 530368.0,
    trkdelay: 34252.0,
    pti80: 1.12,
    trkpti80: 1.14,
    pti95: 1.12,
    trkpti95: 1.17,
    tti: 1.06,
    trktti: 1.06,
    ghg: 2414.0,
    congcost: 1.1595372e7,
    allspeed: 49.03,
    trkspeed: 49.27,
    ttivmt: 298460.0,
    trkttivmt: 21032.0,
  },
  {
    agglevel: "county",
    regioncode: 214,
    year: 2020,
    count: 497,
    len_sec: 289.24700000000024,
    lanemiles: 655.8220000000002,
    delay: 733263.0,
    trkdelay: 50240.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.11,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.06,
    ghg: 3325.0,
    congcost: 1.6583383e7,
    allspeed: 50.79,
    trkspeed: 53.12,
    ttivmt: 489677.0,
    trkttivmt: 32421.0,
  },
  {
    agglevel: "county",
    regioncode: 214,
    year: 2021,
    count: 519,
    len_sec: 289.2330000000002,
    lanemiles: 660.3700000000001,
    delay: 422639.0,
    trkdelay: 34978.0,
    pti80: 1.09,
    trkpti80: 1.13,
    pti95: 1.12,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.05,
    ghg: 1933.0,
    congcost: 9942000.0,
    allspeed: 51.9,
    trkspeed: 53.22,
    ttivmt: 397235.0,
    trkttivmt: 26458.0,
  },
  {
    agglevel: "county",
    regioncode: 214,
    year: 2022,
    count: 520,
    len_sec: 289.0219999999997,
    lanemiles: 659.9379999999995,
    delay: 691324.0,
    trkdelay: 51911.0,
    pti80: 1.11,
    trkpti80: 1.15,
    pti95: 1.18,
    trkpti95: 1.24,
    tti: 1.05,
    trktti: 1.07,
    ghg: 2737.0,
    congcost: 1.7741352e7,
    allspeed: 50.26,
    trkspeed: 52.45,
    ttivmt: 458421.0,
    trkttivmt: 32600.0,
  },
  {
    agglevel: "county",
    regioncode: 215,
    year: 2017,
    count: 245,
    len_sec: 289.80599999999976,
    lanemiles: 605.8829999999992,
    delay: 130258.0,
    trkdelay: 10100.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.02,
    ghg: 565.0,
    congcost: 2730305.0,
    allspeed: 51.72,
    trkspeed: 58.49,
    ttivmt: 111823.0,
    trkttivmt: 19797.0,
  },
  {
    agglevel: "county",
    regioncode: 215,
    year: 2018,
    count: 333,
    len_sec: 288.82999999999987,
    lanemiles: 604.0299999999996,
    delay: 89971.0,
    trkdelay: 14633.0,
    pti80: 1.1,
    trkpti80: 1.15,
    pti95: 1.12,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.06,
    ghg: 517.0,
    congcost: 2185479.0,
    allspeed: 50.47,
    trkspeed: 51.47,
    ttivmt: 90119.0,
    trkttivmt: 10693.0,
  },
  {
    agglevel: "county",
    regioncode: 215,
    year: 2019,
    count: 152,
    len_sec: 288.996,
    lanemiles: 604.362,
    delay: 86138.0,
    trkdelay: 15319.0,
    pti80: 1.11,
    trkpti80: 1.15,
    pti95: 1.14,
    trkpti95: 1.22,
    tti: 1.03,
    trktti: 1.06,
    ghg: 544.0,
    congcost: 2237005.0,
    allspeed: 54.72,
    trkspeed: 54.93,
    ttivmt: 90261.0,
    trkttivmt: 10367.0,
  },
  {
    agglevel: "county",
    regioncode: 215,
    year: 2020,
    count: 457,
    len_sec: 289.00500000000005,
    lanemiles: 604.3800000000001,
    delay: 95080.0,
    trkdelay: 12809.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.05,
    ghg: 462.0,
    congcost: 2380897.0,
    allspeed: 53.6,
    trkspeed: 56.55,
    ttivmt: 94568.0,
    trkttivmt: 10047.0,
  },
  {
    agglevel: "county",
    regioncode: 215,
    year: 2021,
    count: 456,
    len_sec: 289.0049999999998,
    lanemiles: 604.27,
    delay: 52542.0,
    trkdelay: 9051.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.13,
    trkpti95: 1.19,
    tti: 1.02,
    trktti: 1.04,
    ghg: 241.0,
    congcost: 1376214.0,
    allspeed: 54.07,
    trkspeed: 56.55,
    ttivmt: 92468.0,
    trkttivmt: 9092.0,
  },
  {
    agglevel: "county",
    regioncode: 215,
    year: 2022,
    count: 459,
    len_sec: 289.059,
    lanemiles: 604.627,
    delay: 115065.0,
    trkdelay: 12511.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.19,
    trkpti95: 1.21,
    tti: 1.04,
    trktti: 1.04,
    ghg: 414.0,
    congcost: 3072762.0,
    allspeed: 53.86,
    trkspeed: 57.39,
    ttivmt: 98639.0,
    trkttivmt: 11028.0,
  },
  {
    agglevel: "county",
    regioncode: 216,
    year: 2017,
    count: 59,
    len_sec: 108.64099999999999,
    lanemiles: 282.537,
    delay: 29894.0,
    trkdelay: 15940.0,
    pti80: 1.06,
    trkpti80: 1.05,
    pti95: 1.08,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.01,
    ghg: 83.0,
    congcost: 1017097.0,
    allspeed: 63.05,
    trkspeed: 64.3,
    ttivmt: 92541.0,
    trkttivmt: 87411.0,
  },
  {
    agglevel: "county",
    regioncode: 216,
    year: 2018,
    count: 94,
    len_sec: 108.69100000000002,
    lanemiles: 308.9670000000001,
    delay: 17184.0,
    trkdelay: 5504.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 108.0,
    congcost: 500890.0,
    allspeed: 63.05,
    trkspeed: 62.97,
    ttivmt: 99704.0,
    trkttivmt: 22617.0,
  },
  {
    agglevel: "county",
    regioncode: 216,
    year: 2019,
    count: 30,
    len_sec: 108.69900000000003,
    lanemiles: 308.983,
    delay: 16409.0,
    trkdelay: 7098.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 94.0,
    congcost: 558337.0,
    allspeed: 63.51,
    trkspeed: 62.07,
    ttivmt: 109539.0,
    trkttivmt: 28824.0,
  },
  {
    agglevel: "county",
    regioncode: 216,
    year: 2020,
    count: 112,
    len_sec: 108.70000000000002,
    lanemiles: 308.9869999999999,
    delay: 17237.0,
    trkdelay: 5904.0,
    pti80: 1.07,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 81.0,
    congcost: 557037.0,
    allspeed: 63.4,
    trkspeed: 63.67,
    ttivmt: 103976.0,
    trkttivmt: 24520.0,
  },
  {
    agglevel: "county",
    regioncode: 216,
    year: 2021,
    count: 112,
    len_sec: 108.76500000000001,
    lanemiles: 309.24100000000016,
    delay: 11302.0,
    trkdelay: 4645.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.0,
    trktti: 1.01,
    ghg: 65.0,
    congcost: 377375.0,
    allspeed: 63.33,
    trkspeed: 63.7,
    ttivmt: 80503.0,
    trkttivmt: 20143.0,
  },
  {
    agglevel: "county",
    regioncode: 216,
    year: 2022,
    count: 113,
    len_sec: 108.76600000000002,
    lanemiles: 309.243,
    delay: 24409.0,
    trkdelay: 10173.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.01,
    trktti: 1.02,
    ghg: 183.0,
    congcost: 928988.0,
    allspeed: 63.14,
    trkspeed: 63.3,
    ttivmt: 84499.0,
    trkttivmt: 19972.0,
  },
  {
    agglevel: "county",
    regioncode: 217,
    year: 2017,
    count: 138,
    len_sec: 163.47600000000008,
    lanemiles: 329.38800000000003,
    delay: 13565.0,
    trkdelay: 2866.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.09,
    trkpti95: 1.09,
    tti: 1.02,
    trktti: 1.02,
    ghg: 49.0,
    congcost: 336073.0,
    allspeed: 61.21,
    trkspeed: 62.43,
    ttivmt: 30280.0,
    trkttivmt: 7824.0,
  },
  {
    agglevel: "county",
    regioncode: 217,
    year: 2018,
    count: 131,
    len_sec: 163.00400000000002,
    lanemiles: 326.37600000000003,
    delay: 15610.0,
    trkdelay: 3503.0,
    pti80: 1.08,
    trkpti80: 1.1,
    pti95: 1.1,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.04,
    ghg: 64.0,
    congcost: 404247.0,
    allspeed: 59.4,
    trkspeed: 59.75,
    ttivmt: 31670.0,
    trkttivmt: 4402.0,
  },
  {
    agglevel: "county",
    regioncode: 217,
    year: 2019,
    count: 58,
    len_sec: 162.65299999999993,
    lanemiles: 325.67399999999986,
    delay: 13505.0,
    trkdelay: 4905.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 69.0,
    congcost: 428434.0,
    allspeed: 61.66,
    trkspeed: 60.87,
    ttivmt: 31527.0,
    trkttivmt: 5731.0,
  },
  {
    agglevel: "county",
    regioncode: 217,
    year: 2020,
    count: 224,
    len_sec: 163.2120000000001,
    lanemiles: 326.7920000000002,
    delay: 14699.0,
    trkdelay: 3618.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.1,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 62.0,
    congcost: 424969.0,
    allspeed: 61.21,
    trkspeed: 61.34,
    ttivmt: 29283.0,
    trkttivmt: 5391.0,
  },
  {
    agglevel: "county",
    regioncode: 217,
    year: 2021,
    count: 222,
    len_sec: 163.21200000000005,
    lanemiles: 326.7920000000001,
    delay: 12606.0,
    trkdelay: 4174.0,
    pti80: 1.08,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.03,
    ghg: 50.0,
    congcost: 388097.0,
    allspeed: 61.52,
    trkspeed: 61.88,
    ttivmt: 26896.0,
    trkttivmt: 6028.0,
  },
  {
    agglevel: "county",
    regioncode: 217,
    year: 2022,
    count: 222,
    len_sec: 163.20500000000004,
    lanemiles: 326.7780000000001,
    delay: 32112.0,
    trkdelay: 7496.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.16,
    trkpti95: 1.22,
    tti: 1.03,
    trktti: 1.05,
    ghg: 118.0,
    congcost: 995570.0,
    allspeed: 60.39,
    trkspeed: 60.56,
    ttivmt: 28148.0,
    trkttivmt: 4952.0,
  },
  {
    agglevel: "county",
    regioncode: 218,
    year: 2017,
    count: 145,
    len_sec: 269.271,
    lanemiles: 647.729,
    delay: 45932.0,
    trkdelay: 14206.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 303.0,
    congcost: 1293491.0,
    allspeed: 63.56,
    trkspeed: 64.45,
    ttivmt: 241300.0,
    trkttivmt: 144696.0,
  },
  {
    agglevel: "county",
    regioncode: 218,
    year: 2018,
    count: 183,
    len_sec: 269.37699999999995,
    lanemiles: 647.8999999999994,
    delay: 32634.0,
    trkdelay: 8828.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.09,
    tti: 1.0,
    trktti: 1.0,
    ghg: 186.0,
    congcost: 899306.0,
    allspeed: 63.0,
    trkspeed: 63.7,
    ttivmt: 256868.0,
    trkttivmt: 76253.0,
  },
  {
    agglevel: "county",
    regioncode: 218,
    year: 2019,
    count: 49,
    len_sec: 183.82200000000003,
    lanemiles: 475.81399999999996,
    delay: 38465.0,
    trkdelay: 17157.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 301.0,
    congcost: 1343165.0,
    allspeed: 64.09,
    trkspeed: 63.5,
    ttivmt: 285916.0,
    trkttivmt: 95001.0,
  },
  {
    agglevel: "county",
    regioncode: 218,
    year: 2020,
    count: 261,
    len_sec: 268.56499999999994,
    lanemiles: 646.2759999999995,
    delay: 50965.0,
    trkdelay: 10135.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.0,
    trktti: 1.0,
    ghg: 286.0,
    congcost: 1397188.0,
    allspeed: 63.6,
    trkspeed: 64.27,
    ttivmt: 318387.0,
    trkttivmt: 92223.0,
  },
  {
    agglevel: "county",
    regioncode: 218,
    year: 2021,
    count: 270,
    len_sec: 269.88200000000006,
    lanemiles: 649.6939999999998,
    delay: 29730.0,
    trkdelay: 6626.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.0,
    trktti: 1.0,
    ghg: 170.0,
    congcost: 827395.0,
    allspeed: 63.85,
    trkspeed: 64.33,
    ttivmt: 258606.0,
    trkttivmt: 67299.0,
  },
  {
    agglevel: "county",
    regioncode: 218,
    year: 2022,
    count: 291,
    len_sec: 273.84500000000014,
    lanemiles: 653.973,
    delay: 72478.0,
    trkdelay: 31702.0,
    pti80: 1.11,
    trkpti80: 1.06,
    pti95: 1.15,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.02,
    ghg: 245.0,
    congcost: 2714385.0,
    allspeed: 63.72,
    trkspeed: 63.59,
    ttivmt: 265839.0,
    trkttivmt: 89349.0,
  },
  {
    agglevel: "county",
    regioncode: 219,
    year: 2017,
    count: 286,
    len_sec: 369.9419999999998,
    lanemiles: 841.7170000000003,
    delay: 54161.0,
    trkdelay: 10778.0,
    pti80: 1.07,
    trkpti80: 1.06,
    pti95: 1.1,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 283.0,
    congcost: 1339575.0,
    allspeed: 62.48,
    trkspeed: 63.65,
    ttivmt: 167770.0,
    trkttivmt: 59994.0,
  },
  {
    agglevel: "county",
    regioncode: 219,
    year: 2018,
    count: 288,
    len_sec: 369.8840000000003,
    lanemiles: 841.2450000000006,
    delay: 52513.0,
    trkdelay: 8402.0,
    pti80: 1.08,
    trkpti80: 1.06,
    pti95: 1.13,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.01,
    ghg: 260.0,
    congcost: 1263820.0,
    allspeed: 60.48,
    trkspeed: 61.07,
    ttivmt: 175891.0,
    trkttivmt: 31575.0,
  },
  {
    agglevel: "county",
    regioncode: 219,
    year: 2019,
    count: 159,
    len_sec: 376.30599999999987,
    lanemiles: 854.3919999999998,
    delay: 66354.0,
    trkdelay: 17517.0,
    pti80: 1.11,
    trkpti80: 1.07,
    pti95: 1.16,
    trkpti95: 1.09,
    tti: 1.02,
    trktti: 1.02,
    ghg: 400.0,
    congcost: 1901330.0,
    allspeed: 62.63,
    trkspeed: 61.35,
    ttivmt: 171219.0,
    trkttivmt: 33875.0,
  },
  {
    agglevel: "county",
    regioncode: 219,
    year: 2020,
    count: 431,
    len_sec: 376.79099999999994,
    lanemiles: 855.054,
    delay: 94511.0,
    trkdelay: 14499.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.01,
    ghg: 388.0,
    congcost: 2412045.0,
    allspeed: 62.18,
    trkspeed: 62.77,
    ttivmt: 178961.0,
    trkttivmt: 34569.0,
  },
  {
    agglevel: "county",
    regioncode: 219,
    year: 2021,
    count: 418,
    len_sec: 376.78599999999994,
    lanemiles: 853.4500000000002,
    delay: 57147.0,
    trkdelay: 13506.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 299.0,
    congcost: 1609093.0,
    allspeed: 62.0,
    trkspeed: 62.38,
    ttivmt: 165710.0,
    trkttivmt: 38290.0,
  },
  {
    agglevel: "county",
    regioncode: 219,
    year: 2022,
    count: 423,
    len_sec: 377.38799999999986,
    lanemiles: 855.0669999999994,
    delay: 93170.0,
    trkdelay: 25554.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.19,
    trkpti95: 1.18,
    tti: 1.02,
    trktti: 1.03,
    ghg: 421.0,
    congcost: 3026163.0,
    allspeed: 61.73,
    trkspeed: 61.55,
    ttivmt: 186159.0,
    trkttivmt: 37727.0,
  },
  {
    agglevel: "county",
    regioncode: 220,
    year: 2017,
    count: 5356,
    len_sec: 2468.889000000006,
    lanemiles: 7865.877999999994,
    delay: 7.1804683e7,
    trkdelay: 3338356.0,
    pti80: 1.37,
    trkpti80: 1.44,
    pti95: 1.47,
    trkpti95: 1.57,
    tti: 1.18,
    trktti: 1.19,
    ghg: 281734.0,
    congcost: 1.432984651e9,
    allspeed: 44.34,
    trkspeed: 49.0,
    ttivmt: 2.2948373e7,
    trkttivmt: 1377451.0,
  },
  {
    agglevel: "county",
    regioncode: 220,
    year: 2018,
    count: 5418,
    len_sec: 2517.9579999999914,
    lanemiles: 8024.474999999996,
    delay: 7.9008728e7,
    trkdelay: 3842425.0,
    pti80: 1.46,
    trkpti80: 1.58,
    pti95: 1.57,
    trkpti95: 1.74,
    tti: 1.25,
    trktti: 1.29,
    ghg: 324425.0,
    congcost: 1.618537199e9,
    allspeed: 42.95,
    trkspeed: 44.46,
    ttivmt: 1.8803258e7,
    trkttivmt: 938345.0,
  },
  {
    agglevel: "county",
    regioncode: 220,
    year: 2019,
    count: 3930,
    len_sec: 2491.3239999999955,
    lanemiles: 7964.994000000007,
    delay: 7.2547898e7,
    trkdelay: 3520529.0,
    pti80: 1.43,
    trkpti80: 1.5,
    pti95: 1.54,
    trkpti95: 1.67,
    tti: 1.23,
    trktti: 1.25,
    ghg: 286713.0,
    congcost: 1.537269899e9,
    allspeed: 43.33,
    trkspeed: 45.28,
    ttivmt: 1.8948877e7,
    trkttivmt: 981282.0,
  },
  {
    agglevel: "county",
    regioncode: 220,
    year: 2020,
    count: 7722,
    len_sec: 2507.081,
    lanemiles: 8043.318999999997,
    delay: 6.9325649e7,
    trkdelay: 3240654.0,
    pti80: 1.43,
    trkpti80: 1.49,
    pti95: 1.52,
    trkpti95: 1.62,
    tti: 1.22,
    trktti: 1.24,
    ghg: 267529.0,
    congcost: 1.504119534e9,
    allspeed: 44.1,
    trkspeed: 47.75,
    ttivmt: 1.9384221e7,
    trkttivmt: 1016244.0,
  },
  {
    agglevel: "county",
    regioncode: 220,
    year: 2021,
    count: 8071,
    len_sec: 2513.4460000000036,
    lanemiles: 8067.472000000023,
    delay: 3.6165104e7,
    trkdelay: 2217064.0,
    pti80: 1.25,
    trkpti80: 1.3,
    pti95: 1.31,
    trkpti95: 1.42,
    tti: 1.12,
    trktti: 1.15,
    ghg: 146423.0,
    congcost: 8.23998081e8,
    allspeed: 46.66,
    trkspeed: 51.1,
    ttivmt: 1.7290172e7,
    trkttivmt: 996422.0,
  },
  {
    agglevel: "county",
    regioncode: 220,
    year: 2022,
    count: 8580,
    len_sec: 2554.491000000001,
    lanemiles: 8262.17000000002,
    delay: 6.0120677e7,
    trkdelay: 3393178.0,
    pti80: 1.34,
    trkpti80: 1.42,
    pti95: 1.42,
    trkpti95: 1.57,
    tti: 1.18,
    trktti: 1.2,
    ghg: 234355.0,
    congcost: 1.503395653e9,
    allspeed: 45.57,
    trkspeed: 49.85,
    ttivmt: 1.9492558e7,
    trkttivmt: 1136653.0,
  },
  {
    agglevel: "county",
    regioncode: 221,
    year: 2017,
    count: 1156,
    len_sec: 689.0029999999996,
    lanemiles: 1721.3809999999992,
    delay: 3200769.0,
    trkdelay: 187743.0,
    pti80: 1.13,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.14,
    tti: 1.06,
    trktti: 1.03,
    ghg: 14491.0,
    congcost: 6.5408672e7,
    allspeed: 47.62,
    trkspeed: 58.51,
    ttivmt: 1608383.0,
    trkttivmt: 247741.0,
  },
  {
    agglevel: "county",
    regioncode: 221,
    year: 2018,
    count: 1214,
    len_sec: 689.7609999999983,
    lanemiles: 1727.2229999999968,
    delay: 2412207.0,
    trkdelay: 154822.0,
    pti80: 1.13,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.15,
    tti: 1.05,
    trktti: 1.03,
    ghg: 11378.0,
    congcost: 5.0876469e7,
    allspeed: 51.04,
    trkspeed: 58.56,
    ttivmt: 1305693.0,
    trkttivmt: 170291.0,
  },
  {
    agglevel: "county",
    regioncode: 221,
    year: 2019,
    count: 779,
    len_sec: 681.9959999999991,
    lanemiles: 1711.687999999997,
    delay: 2776061.0,
    trkdelay: 224493.0,
    pti80: 1.15,
    trkpti80: 1.13,
    pti95: 1.19,
    trkpti95: 1.17,
    tti: 1.06,
    trktti: 1.04,
    ghg: 13748.0,
    congcost: 6.2434646e7,
    allspeed: 50.56,
    trkspeed: 56.74,
    ttivmt: 1346399.0,
    trkttivmt: 178864.0,
  },
  {
    agglevel: "county",
    regioncode: 221,
    year: 2020,
    count: 1827,
    len_sec: 690.2119999999993,
    lanemiles: 1728.0289999999968,
    delay: 2735987.0,
    trkdelay: 195173.0,
    pti80: 1.14,
    trkpti80: 1.13,
    pti95: 1.18,
    trkpti95: 1.18,
    tti: 1.05,
    trktti: 1.04,
    ghg: 13563.0,
    congcost: 6.242227e7,
    allspeed: 50.88,
    trkspeed: 57.99,
    ttivmt: 1374317.0,
    trkttivmt: 173135.0,
  },
  {
    agglevel: "county",
    regioncode: 221,
    year: 2021,
    count: 1806,
    len_sec: 689.4949999999988,
    lanemiles: 1725.2799999999972,
    delay: 1811693.0,
    trkdelay: 185576.0,
    pti80: 1.13,
    trkpti80: 1.14,
    pti95: 1.18,
    trkpti95: 1.22,
    tti: 1.05,
    trktti: 1.04,
    ghg: 9339.0,
    congcost: 4.3858367e7,
    allspeed: 50.7,
    trkspeed: 57.48,
    ttivmt: 1272440.0,
    trkttivmt: 169359.0,
  },
  {
    agglevel: "county",
    regioncode: 221,
    year: 2022,
    count: 1853,
    len_sec: 690.0039999999984,
    lanemiles: 1717.7379999999953,
    delay: 2212393.0,
    trkdelay: 185341.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.19,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.03,
    ghg: 10929.0,
    congcost: 5.8015944e7,
    allspeed: 49.81,
    trkspeed: 58.13,
    ttivmt: 1410107.0,
    trkttivmt: 197383.0,
  },
  {
    agglevel: "county",
    regioncode: 222,
    year: 2017,
    count: 89,
    len_sec: 176.06600000000003,
    lanemiles: 373.8740000000001,
    delay: 10916.0,
    trkdelay: 5209.0,
    pti80: 1.05,
    trkpti80: 1.05,
    pti95: 1.06,
    trkpti95: 1.05,
    tti: 1.02,
    trktti: 1.01,
    ghg: 31.0,
    congcost: 354735.0,
    allspeed: 61.43,
    trkspeed: 62.98,
    ttivmt: 31513.0,
    trkttivmt: 24195.0,
  },
  {
    agglevel: "county",
    regioncode: 222,
    year: 2018,
    count: 169,
    len_sec: 175.64300000000003,
    lanemiles: 372.4570000000001,
    delay: 10112.0,
    trkdelay: 5119.0,
    pti80: 1.06,
    trkpti80: 1.07,
    pti95: 1.06,
    trkpti95: 1.08,
    tti: 1.01,
    trktti: 1.02,
    ghg: 62.0,
    congcost: 351231.0,
    allspeed: 58.92,
    trkspeed: 60.41,
    ttivmt: 31811.0,
    trkttivmt: 11694.0,
  },
  {
    agglevel: "county",
    regioncode: 222,
    year: 2019,
    count: 64,
    len_sec: 172.082,
    lanemiles: 365.3349999999999,
    delay: 12304.0,
    trkdelay: 7409.0,
    pti80: 1.07,
    trkpti80: 1.09,
    pti95: 1.08,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 81.0,
    congcost: 488618.0,
    allspeed: 62.03,
    trkspeed: 61.18,
    ttivmt: 35597.0,
    trkttivmt: 13385.0,
  },
  {
    agglevel: "county",
    regioncode: 222,
    year: 2020,
    count: 223,
    len_sec: 175.729,
    lanemiles: 374.38,
    delay: 8611.0,
    trkdelay: 3887.0,
    pti80: 1.07,
    trkpti80: 1.06,
    pti95: 1.08,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.01,
    ghg: 43.0,
    congcost: 312044.0,
    allspeed: 61.98,
    trkspeed: 61.67,
    ttivmt: 32638.0,
    trkttivmt: 14336.0,
  },
  {
    agglevel: "county",
    regioncode: 222,
    year: 2021,
    count: 223,
    len_sec: 175.72899999999987,
    lanemiles: 374.38,
    delay: 5885.0,
    trkdelay: 2722.0,
    pti80: 1.07,
    trkpti80: 1.06,
    pti95: 1.08,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.01,
    ghg: 28.0,
    congcost: 204544.0,
    allspeed: 62.02,
    trkspeed: 61.75,
    ttivmt: 25976.0,
    trkttivmt: 12680.0,
  },
  {
    agglevel: "county",
    regioncode: 222,
    year: 2022,
    count: 223,
    len_sec: 175.7289999999999,
    lanemiles: 374.37999999999977,
    delay: 14442.0,
    trkdelay: 8240.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.1,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 67.0,
    congcost: 609729.0,
    allspeed: 61.72,
    trkspeed: 61.27,
    ttivmt: 32556.0,
    trkttivmt: 14724.0,
  },
  {
    agglevel: "county",
    regioncode: 223,
    year: 2017,
    count: 258,
    len_sec: 338.92299999999994,
    lanemiles: 756.8499999999996,
    delay: 174967.0,
    trkdelay: 13777.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.01,
    ghg: 765.0,
    congcost: 3674522.0,
    allspeed: 57.76,
    trkspeed: 62.45,
    ttivmt: 228001.0,
    trkttivmt: 38239.0,
  },
  {
    agglevel: "county",
    regioncode: 223,
    year: 2018,
    count: 235,
    len_sec: 334.519,
    lanemiles: 748.6019999999996,
    delay: 126933.0,
    trkdelay: 21971.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 728.0,
    congcost: 3120774.0,
    allspeed: 59.14,
    trkspeed: 59.29,
    ttivmt: 204709.0,
    trkttivmt: 26803.0,
  },
  {
    agglevel: "county",
    regioncode: 223,
    year: 2019,
    count: 187,
    len_sec: 334.53000000000003,
    lanemiles: 748.6240000000001,
    delay: 118375.0,
    trkdelay: 25675.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.03,
    ghg: 735.0,
    congcost: 3223781.0,
    allspeed: 60.82,
    trkspeed: 59.71,
    ttivmt: 220198.0,
    trkttivmt: 32437.0,
  },
  {
    agglevel: "county",
    regioncode: 223,
    year: 2020,
    count: 310,
    len_sec: 334.5669999999997,
    lanemiles: 748.6979999999996,
    delay: 125742.0,
    trkdelay: 21141.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.03,
    ghg: 673.0,
    congcost: 3307427.0,
    allspeed: 60.72,
    trkspeed: 60.88,
    ttivmt: 228298.0,
    trkttivmt: 29916.0,
  },
  {
    agglevel: "county",
    regioncode: 223,
    year: 2021,
    count: 304,
    len_sec: 334.5709999999996,
    lanemiles: 748.7059999999994,
    delay: 63520.0,
    trkdelay: 18251.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.01,
    trktti: 1.03,
    ghg: 380.0,
    congcost: 1889550.0,
    allspeed: 60.63,
    trkspeed: 60.94,
    ttivmt: 205039.0,
    trkttivmt: 29771.0,
  },
  {
    agglevel: "county",
    regioncode: 223,
    year: 2022,
    count: 302,
    len_sec: 334.5709999999995,
    lanemiles: 748.705999999999,
    delay: 133407.0,
    trkdelay: 18351.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.02,
    ghg: 611.0,
    congcost: 3726278.0,
    allspeed: 60.44,
    trkspeed: 61.03,
    ttivmt: 227793.0,
    trkttivmt: 26293.0,
  },
  {
    agglevel: "county",
    regioncode: 224,
    year: 2017,
    count: 134,
    len_sec: 169.62600000000003,
    lanemiles: 343.254,
    delay: 18659.0,
    trkdelay: 5112.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.02,
    ghg: 71.0,
    congcost: 498975.0,
    allspeed: 59.94,
    trkspeed: 62.28,
    ttivmt: 27056.0,
    trkttivmt: 12081.0,
  },
  {
    agglevel: "county",
    regioncode: 224,
    year: 2018,
    count: 175,
    len_sec: 169.2389999999999,
    lanemiles: 342.5219999999998,
    delay: 18637.0,
    trkdelay: 4583.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 99.0,
    congcost: 499400.0,
    allspeed: 59.12,
    trkspeed: 57.47,
    ttivmt: 28757.0,
    trkttivmt: 5033.0,
  },
  {
    agglevel: "county",
    regioncode: 224,
    year: 2019,
    count: 67,
    len_sec: 169.40900000000002,
    lanemiles: 342.862,
    delay: 19914.0,
    trkdelay: 6073.0,
    pti80: 1.09,
    trkpti80: 1.14,
    pti95: 1.11,
    trkpti95: 1.2,
    tti: 1.03,
    trktti: 1.05,
    ghg: 113.0,
    congcost: 597584.0,
    allspeed: 59.43,
    trkspeed: 57.68,
    ttivmt: 27041.0,
    trkttivmt: 4812.0,
  },
  {
    agglevel: "county",
    regioncode: 224,
    year: 2020,
    count: 241,
    len_sec: 169.41200000000003,
    lanemiles: 342.868,
    delay: 29684.0,
    trkdelay: 6108.0,
    pti80: 1.12,
    trkpti80: 1.15,
    pti95: 1.18,
    trkpti95: 1.23,
    tti: 1.04,
    trktti: 1.06,
    ghg: 124.0,
    congcost: 813245.0,
    allspeed: 58.77,
    trkspeed: 59.02,
    ttivmt: 29412.0,
    trkttivmt: 4845.0,
  },
  {
    agglevel: "county",
    regioncode: 224,
    year: 2021,
    count: 234,
    len_sec: 169.412,
    lanemiles: 342.8679999999999,
    delay: 16333.0,
    trkdelay: 4925.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 89.0,
    congcost: 493817.0,
    allspeed: 59.25,
    trkspeed: 59.85,
    ttivmt: 25723.0,
    trkttivmt: 5177.0,
  },
  {
    agglevel: "county",
    regioncode: 224,
    year: 2022,
    count: 234,
    len_sec: 169.4119999999999,
    lanemiles: 342.8680000000001,
    delay: 38039.0,
    trkdelay: 9677.0,
    pti80: 1.11,
    trkpti80: 1.16,
    pti95: 1.18,
    trkpti95: 1.28,
    tti: 1.04,
    trktti: 1.06,
    ghg: 178.0,
    congcost: 1213341.0,
    allspeed: 59.69,
    trkspeed: 58.6,
    ttivmt: 36800.0,
    trkttivmt: 6312.0,
  },
  {
    agglevel: "county",
    regioncode: 225,
    year: 2017,
    count: 508,
    len_sec: 267.95199999999977,
    lanemiles: 655.7859999999995,
    delay: 681126.0,
    trkdelay: 43827.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.1,
    tti: 1.04,
    trktti: 1.01,
    ghg: 2897.0,
    congcost: 1.3993153e7,
    allspeed: 55.71,
    trkspeed: 62.73,
    ttivmt: 575869.0,
    trkttivmt: 129755.0,
  },
  {
    agglevel: "county",
    regioncode: 225,
    year: 2018,
    count: 582,
    len_sec: 276.3259999999998,
    lanemiles: 668.148,
    delay: 607085.0,
    trkdelay: 68941.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 2991.0,
    congcost: 1.3732276e7,
    allspeed: 56.46,
    trkspeed: 61.21,
    ttivmt: 511547.0,
    trkttivmt: 107813.0,
  },
  {
    agglevel: "county",
    regioncode: 225,
    year: 2019,
    count: 280,
    len_sec: 263.2910000000001,
    lanemiles: 642.563,
    delay: 643140.0,
    trkdelay: 69205.0,
    pti80: 1.12,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.11,
    tti: 1.04,
    trktti: 1.02,
    ghg: 3316.0,
    congcost: 1.5041576e7,
    allspeed: 57.4,
    trkspeed: 60.51,
    ttivmt: 526990.0,
    trkttivmt: 124896.0,
  },
  {
    agglevel: "county",
    regioncode: 225,
    year: 2020,
    count: 821,
    len_sec: 276.3589999999997,
    lanemiles: 668.2109999999993,
    delay: 660566.0,
    trkdelay: 66536.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.02,
    ghg: 3427.0,
    congcost: 1.5781427e7,
    allspeed: 58.07,
    trkspeed: 62.67,
    ttivmt: 570855.0,
    trkttivmt: 143709.0,
  },
  {
    agglevel: "county",
    regioncode: 225,
    year: 2021,
    count: 833,
    len_sec: 276.6429999999996,
    lanemiles: 668.5289999999997,
    delay: 479362.0,
    trkdelay: 53575.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.01,
    ghg: 2491.0,
    congcost: 1.1735744e7,
    allspeed: 58.61,
    trkspeed: 62.94,
    ttivmt: 537665.0,
    trkttivmt: 139149.0,
  },
  {
    agglevel: "county",
    regioncode: 225,
    year: 2022,
    count: 832,
    len_sec: 276.67499999999995,
    lanemiles: 667.2149999999989,
    delay: 600927.0,
    trkdelay: 57583.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.18,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.01,
    ghg: 2738.0,
    congcost: 1.5927224e7,
    allspeed: 56.39,
    trkspeed: 62.75,
    ttivmt: 503270.0,
    trkttivmt: 148420.0,
  },
  {
    agglevel: "county",
    regioncode: 226,
    year: 2017,
    count: 831,
    len_sec: 565.4050000000001,
    lanemiles: 1442.7999999999997,
    delay: 2721835.0,
    trkdelay: 150835.0,
    pti80: 1.14,
    trkpti80: 1.13,
    pti95: 1.18,
    trkpti95: 1.19,
    tti: 1.07,
    trktti: 1.04,
    ghg: 12424.0,
    congcost: 5.5374886e7,
    allspeed: 46.74,
    trkspeed: 53.77,
    ttivmt: 1069808.0,
    trkttivmt: 101394.0,
  },
  {
    agglevel: "county",
    regioncode: 226,
    year: 2018,
    count: 864,
    len_sec: 564.7839999999994,
    lanemiles: 1443.8209999999976,
    delay: 2432892.0,
    trkdelay: 123000.0,
    pti80: 1.14,
    trkpti80: 1.16,
    pti95: 1.18,
    trkpti95: 1.21,
    tti: 1.07,
    trktti: 1.06,
    ghg: 11144.0,
    congcost: 5.0249578e7,
    allspeed: 49.19,
    trkspeed: 54.09,
    ttivmt: 890801.0,
    trkttivmt: 68467.0,
  },
  {
    agglevel: "county",
    regioncode: 226,
    year: 2019,
    count: 414,
    len_sec: 387.9379999999995,
    lanemiles: 1075.9679999999992,
    delay: 2364819.0,
    trkdelay: 164103.0,
    pti80: 1.15,
    trkpti80: 1.15,
    pti95: 1.18,
    trkpti95: 1.2,
    tti: 1.07,
    trktti: 1.06,
    ghg: 11589.0,
    congcost: 5.2290949e7,
    allspeed: 50.05,
    trkspeed: 52.87,
    ttivmt: 859522.0,
    trkttivmt: 80322.0,
  },
  {
    agglevel: "county",
    regioncode: 226,
    year: 2020,
    count: 1311,
    len_sec: 565.3769999999987,
    lanemiles: 1444.946999999998,
    delay: 2533918.0,
    trkdelay: 161948.0,
    pti80: 1.15,
    trkpti80: 1.15,
    pti95: 1.19,
    trkpti95: 1.2,
    tti: 1.07,
    trktti: 1.06,
    ghg: 12163.0,
    congcost: 5.7067767e7,
    allspeed: 49.33,
    trkspeed: 54.73,
    ttivmt: 940988.0,
    trkttivmt: 79437.0,
  },
  {
    agglevel: "county",
    regioncode: 226,
    year: 2021,
    count: 1280,
    len_sec: 562.0929999999984,
    lanemiles: 1440.573999999997,
    delay: 1667479.0,
    trkdelay: 143605.0,
    pti80: 1.13,
    trkpti80: 1.15,
    pti95: 1.17,
    trkpti95: 1.23,
    tti: 1.05,
    trktti: 1.06,
    ghg: 8305.0,
    congcost: 3.9512379e7,
    allspeed: 49.32,
    trkspeed: 54.39,
    ttivmt: 867290.0,
    trkttivmt: 77864.0,
  },
  {
    agglevel: "county",
    regioncode: 226,
    year: 2022,
    count: 1308,
    len_sec: 562.2669999999983,
    lanemiles: 1444.694999999996,
    delay: 2108453.0,
    trkdelay: 144622.0,
    pti80: 1.14,
    trkpti80: 1.16,
    pti95: 1.21,
    trkpti95: 1.27,
    tti: 1.05,
    trktti: 1.06,
    ghg: 9948.0,
    congcost: 5.4090782e7,
    allspeed: 48.36,
    trkspeed: 54.07,
    ttivmt: 927953.0,
    trkttivmt: 75236.0,
  },
  {
    agglevel: "county",
    regioncode: 227,
    year: 2017,
    count: 3121,
    len_sec: 1468.775000000002,
    lanemiles: 4449.143999999999,
    delay: 7.2430296e7,
    trkdelay: 4100107.0,
    pti80: 1.67,
    trkpti80: 2.13,
    pti95: 1.86,
    trkpti95: 2.43,
    tti: 1.33,
    trktti: 1.5,
    ghg: 294597.0,
    congcost: 1.468666551e9,
    allspeed: 40.21,
    trkspeed: 44.45,
    ttivmt: 1.4831945e7,
    trkttivmt: 783956.0,
  },
  {
    agglevel: "county",
    regioncode: 227,
    year: 2018,
    count: 2966,
    len_sec: 1478.9499999999925,
    lanemiles: 4478.981999999989,
    delay: 7.0486348e7,
    trkdelay: 2910260.0,
    pti80: 1.84,
    trkpti80: 1.98,
    pti95: 2.05,
    trkpti95: 2.25,
    tti: 1.43,
    trktti: 1.46,
    ghg: 270743.0,
    congcost: 1.424222343e9,
    allspeed: 40.93,
    trkspeed: 44.95,
    ttivmt: 1.1236981e7,
    trkttivmt: 538412.0,
  },
  {
    agglevel: "county",
    regioncode: 227,
    year: 2019,
    count: 2116,
    len_sec: 1146.7789999999957,
    lanemiles: 3780.0519999999947,
    delay: 6.4833006e7,
    trkdelay: 2727781.0,
    pti80: 1.71,
    trkpti80: 1.84,
    pti95: 1.89,
    trkpti95: 2.08,
    tti: 1.36,
    trktti: 1.39,
    ghg: 249997.0,
    congcost: 1.35852779e9,
    allspeed: 42.01,
    trkspeed: 45.16,
    ttivmt: 1.1382614e7,
    trkttivmt: 535376.0,
  },
  {
    agglevel: "county",
    regioncode: 227,
    year: 2020,
    count: 5212,
    len_sec: 1479.2949999999905,
    lanemiles: 4480.382999999981,
    delay: 7.1361276e7,
    trkdelay: 2859914.0,
    pti80: 1.78,
    trkpti80: 1.81,
    pti95: 1.98,
    trkpti95: 2.06,
    tti: 1.39,
    trktti: 1.38,
    ghg: 272253.0,
    congcost: 1.530717251e9,
    allspeed: 41.51,
    trkspeed: 46.42,
    ttivmt: 1.2074276e7,
    trkttivmt: 570160.0,
  },
  {
    agglevel: "county",
    regioncode: 227,
    year: 2021,
    count: 5458,
    len_sec: 1484.1089999999851,
    lanemiles: 4498.542999999976,
    delay: 2.6708411e7,
    trkdelay: 1924868.0,
    pti80: 1.34,
    trkpti80: 1.45,
    pti95: 1.43,
    trkpti95: 1.63,
    tti: 1.17,
    trktti: 1.21,
    ghg: 112342.0,
    congcost: 6.17598335e8,
    allspeed: 46.08,
    trkspeed: 50.47,
    ttivmt: 9422235.0,
    trkttivmt: 591091.0,
  },
  {
    agglevel: "county",
    regioncode: 227,
    year: 2022,
    count: 6189,
    len_sec: 1512.9199999999857,
    lanemiles: 4619.208999999977,
    delay: 3.9617842e7,
    trkdelay: 2224324.0,
    pti80: 1.38,
    trkpti80: 1.48,
    pti95: 1.47,
    trkpti95: 1.64,
    tti: 1.19,
    trktti: 1.22,
    ghg: 154483.0,
    congcost: 9.90308454e8,
    allspeed: 45.43,
    trkspeed: 49.39,
    ttivmt: 1.1188393e7,
    trkttivmt: 611489.0,
  },
  {
    agglevel: "county",
    regioncode: 228,
    year: 2017,
    count: 286,
    len_sec: 235.30800000000002,
    lanemiles: 484.6410000000002,
    delay: 121962.0,
    trkdelay: 12467.0,
    pti80: 1.08,
    trkpti80: 1.06,
    pti95: 1.1,
    trkpti95: 1.07,
    tti: 1.03,
    trktti: 1.02,
    ghg: 409.0,
    congcost: 2622963.0,
    allspeed: 57.19,
    trkspeed: 60.95,
    ttivmt: 136278.0,
    trkttivmt: 24829.0,
  },
  {
    agglevel: "county",
    regioncode: 228,
    year: 2018,
    count: 289,
    len_sec: 235.20899999999997,
    lanemiles: 484.9279999999999,
    delay: 119744.0,
    trkdelay: 20816.0,
    pti80: 1.08,
    trkpti80: 1.12,
    pti95: 1.1,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 527.0,
    congcost: 2917242.0,
    allspeed: 54.9,
    trkspeed: 56.93,
    ttivmt: 147361.0,
    trkttivmt: 20522.0,
  },
  {
    agglevel: "county",
    regioncode: 228,
    year: 2019,
    count: 89,
    len_sec: 158.03900000000002,
    lanemiles: 330.58799999999997,
    delay: 111164.0,
    trkdelay: 18406.0,
    pti80: 1.09,
    trkpti80: 1.13,
    pti95: 1.1,
    trkpti95: 1.18,
    tti: 1.02,
    trktti: 1.05,
    ghg: 518.0,
    congcost: 2793940.0,
    allspeed: 58.16,
    trkspeed: 57.8,
    ttivmt: 145531.0,
    trkttivmt: 14901.0,
  },
  {
    agglevel: "county",
    regioncode: 228,
    year: 2020,
    count: 489,
    len_sec: 235.30299999999986,
    lanemiles: 485.11599999999953,
    delay: 131028.0,
    trkdelay: 17018.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 514.0,
    congcost: 3230195.0,
    allspeed: 57.44,
    trkspeed: 58.43,
    ttivmt: 147004.0,
    trkttivmt: 15720.0,
  },
  {
    agglevel: "county",
    regioncode: 228,
    year: 2021,
    count: 495,
    len_sec: 235.30900000000008,
    lanemiles: 485.12800000000027,
    delay: 96100.0,
    trkdelay: 17332.0,
    pti80: 1.08,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.16,
    tti: 1.02,
    trktti: 1.04,
    ghg: 439.0,
    congcost: 2537735.0,
    allspeed: 57.28,
    trkspeed: 58.02,
    ttivmt: 145111.0,
    trkttivmt: 18233.0,
  },
  {
    agglevel: "county",
    regioncode: 228,
    year: 2022,
    count: 502,
    len_sec: 235.31400000000005,
    lanemiles: 485.1380000000001,
    delay: 167763.0,
    trkdelay: 24147.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.16,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.05,
    ghg: 657.0,
    congcost: 4690621.0,
    allspeed: 56.88,
    trkspeed: 58.07,
    ttivmt: 158661.0,
    trkttivmt: 21457.0,
  },
  {
    agglevel: "county",
    regioncode: 229,
    year: 2017,
    count: 329,
    len_sec: 259.0950000000001,
    lanemiles: 537.5840000000001,
    delay: 176053.0,
    trkdelay: 26548.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.1,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.03,
    ghg: 772.0,
    congcost: 4072494.0,
    allspeed: 58.29,
    trkspeed: 59.42,
    ttivmt: 193181.0,
    trkttivmt: 34956.0,
  },
  {
    agglevel: "county",
    regioncode: 229,
    year: 2018,
    count: 400,
    len_sec: 257.88800000000015,
    lanemiles: 535.023,
    delay: 176408.0,
    trkdelay: 25025.0,
    pti80: 1.08,
    trkpti80: 1.11,
    pti95: 1.1,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 776.0,
    congcost: 4124505.0,
    allspeed: 55.36,
    trkspeed: 54.75,
    ttivmt: 201011.0,
    trkttivmt: 26019.0,
  },
  {
    agglevel: "county",
    regioncode: 229,
    year: 2019,
    count: 109,
    len_sec: 194.96300000000008,
    lanemiles: 409.1730000000001,
    delay: 193821.0,
    trkdelay: 32607.0,
    pti80: 1.09,
    trkpti80: 1.14,
    pti95: 1.11,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.06,
    ghg: 910.0,
    congcost: 4893828.0,
    allspeed: 58.67,
    trkspeed: 57.02,
    ttivmt: 212957.0,
    trkttivmt: 26176.0,
  },
  {
    agglevel: "county",
    regioncode: 229,
    year: 2020,
    count: 564,
    len_sec: 258.72699999999986,
    lanemiles: 534.3999999999996,
    delay: 253512.0,
    trkdelay: 43385.0,
    pti80: 1.11,
    trkpti80: 1.15,
    pti95: 1.14,
    trkpti95: 1.22,
    tti: 1.05,
    trktti: 1.07,
    ghg: 1134.0,
    congcost: 6646920.0,
    allspeed: 57.46,
    trkspeed: 57.34,
    ttivmt: 206404.0,
    trkttivmt: 28316.0,
  },
  {
    agglevel: "county",
    regioncode: 229,
    year: 2021,
    count: 570,
    len_sec: 258.8040000000002,
    lanemiles: 534.3640000000001,
    delay: 151216.0,
    trkdelay: 29798.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.06,
    ghg: 781.0,
    congcost: 4078397.0,
    allspeed: 58.23,
    trkspeed: 58.14,
    ttivmt: 178703.0,
    trkttivmt: 26225.0,
  },
  {
    agglevel: "county",
    regioncode: 229,
    year: 2022,
    count: 569,
    len_sec: 258.80400000000026,
    lanemiles: 534.3640000000009,
    delay: 221685.0,
    trkdelay: 34157.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.16,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.05,
    ghg: 1035.0,
    congcost: 6315014.0,
    allspeed: 57.26,
    trkspeed: 58.03,
    ttivmt: 204039.0,
    trkttivmt: 30535.0,
  },
  {
    agglevel: "county",
    regioncode: 230,
    year: 2017,
    count: 496,
    len_sec: 352.2290000000002,
    lanemiles: 832.0980000000004,
    delay: 525074.0,
    trkdelay: 33723.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.05,
    trktti: 1.04,
    ghg: 1942.0,
    congcost: 1.0730792e7,
    allspeed: 54.67,
    trkspeed: 56.84,
    ttivmt: 420732.0,
    trkttivmt: 37054.0,
  },
  {
    agglevel: "county",
    regioncode: 230,
    year: 2018,
    count: 541,
    len_sec: 351.10799999999995,
    lanemiles: 829.3549999999997,
    delay: 485359.0,
    trkdelay: 52053.0,
    pti80: 1.11,
    trkpti80: 1.15,
    pti95: 1.12,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.07,
    ghg: 2010.0,
    congcost: 1.080746e7,
    allspeed: 55.15,
    trkspeed: 55.63,
    ttivmt: 403259.0,
    trkttivmt: 39268.0,
  },
  {
    agglevel: "county",
    regioncode: 230,
    year: 2019,
    count: 255,
    len_sec: 347.3470000000002,
    lanemiles: 821.9590000000002,
    delay: 455689.0,
    trkdelay: 45848.0,
    pti80: 1.1,
    trkpti80: 1.14,
    pti95: 1.12,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.06,
    ghg: 1900.0,
    congcost: 1.044611e7,
    allspeed: 56.62,
    trkspeed: 56.08,
    ttivmt: 423771.0,
    trkttivmt: 33612.0,
  },
  {
    agglevel: "county",
    regioncode: 230,
    year: 2020,
    count: 804,
    len_sec: 351.0750000000003,
    lanemiles: 827.7649999999994,
    delay: 456126.0,
    trkdelay: 45823.0,
    pti80: 1.09,
    trkpti80: 1.13,
    pti95: 1.11,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.06,
    ghg: 1909.0,
    congcost: 1.0793053e7,
    allspeed: 56.41,
    trkspeed: 57.18,
    ttivmt: 424157.0,
    trkttivmt: 35119.0,
  },
  {
    agglevel: "county",
    regioncode: 230,
    year: 2021,
    count: 820,
    len_sec: 351.1769999999997,
    lanemiles: 827.9909999999991,
    delay: 323292.0,
    trkdelay: 35730.0,
    pti80: 1.09,
    trkpti80: 1.12,
    pti95: 1.12,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.05,
    ghg: 1317.0,
    congcost: 7834086.0,
    allspeed: 56.64,
    trkspeed: 57.61,
    ttivmt: 402909.0,
    trkttivmt: 34289.0,
  },
  {
    agglevel: "county",
    regioncode: 230,
    year: 2022,
    count: 814,
    len_sec: 351.2319999999999,
    lanemiles: 828.1009999999997,
    delay: 551135.0,
    trkdelay: 45612.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.05,
    ghg: 2166.0,
    congcost: 1.4274607e7,
    allspeed: 55.8,
    trkspeed: 57.54,
    ttivmt: 428444.0,
    trkttivmt: 40990.0,
  },
  {
    agglevel: "county",
    regioncode: 231,
    year: 2017,
    count: 146,
    len_sec: 190.86500000000004,
    lanemiles: 393.4180000000001,
    delay: 60518.0,
    trkdelay: 37515.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.02,
    ghg: 233.0,
    congcost: 2233499.0,
    allspeed: 61.03,
    trkspeed: 62.91,
    ttivmt: 91457.0,
    trkttivmt: 77123.0,
  },
  {
    agglevel: "county",
    regioncode: 231,
    year: 2018,
    count: 200,
    len_sec: 190.83999999999995,
    lanemiles: 393.384,
    delay: 72386.0,
    trkdelay: 25831.0,
    pti80: 1.11,
    trkpti80: 1.15,
    pti95: 1.14,
    trkpti95: 1.2,
    tti: 1.03,
    trktti: 1.05,
    ghg: 306.0,
    congcost: 2160790.0,
    allspeed: 60.11,
    trkspeed: 60.28,
    ttivmt: 116306.0,
    trkttivmt: 25558.0,
  },
  {
    agglevel: "county",
    regioncode: 231,
    year: 2019,
    count: 71,
    len_sec: 162.011,
    lanemiles: 335.72599999999994,
    delay: 47031.0,
    trkdelay: 27460.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.01,
    trktti: 1.03,
    ghg: 241.0,
    congcost: 1826366.0,
    allspeed: 62.85,
    trkspeed: 61.75,
    ttivmt: 140429.0,
    trkttivmt: 37817.0,
  },
  {
    agglevel: "county",
    regioncode: 231,
    year: 2020,
    count: 252,
    len_sec: 190.80100000000002,
    lanemiles: 394.044,
    delay: 49137.0,
    trkdelay: 18038.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.02,
    ghg: 236.0,
    congcost: 1629075.0,
    allspeed: 62.45,
    trkspeed: 62.82,
    ttivmt: 147977.0,
    trkttivmt: 38266.0,
  },
  {
    agglevel: "county",
    regioncode: 231,
    year: 2021,
    count: 246,
    len_sec: 190.80099999999993,
    lanemiles: 394.0440000000001,
    delay: 48011.0,
    trkdelay: 21740.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 364.0,
    congcost: 1673474.0,
    allspeed: 61.74,
    trkspeed: 62.17,
    ttivmt: 115684.0,
    trkttivmt: 31995.0,
  },
  {
    agglevel: "county",
    regioncode: 231,
    year: 2022,
    count: 246,
    len_sec: 190.91600000000003,
    lanemiles: 394.29600000000005,
    delay: 41021.0,
    trkdelay: 15676.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 224.0,
    congcost: 1489591.0,
    allspeed: 62.77,
    trkspeed: 62.8,
    ttivmt: 120970.0,
    trkttivmt: 38511.0,
  },
  {
    agglevel: "county",
    regioncode: 232,
    year: 2017,
    count: 551,
    len_sec: 390.60800000000006,
    lanemiles: 854.6639999999994,
    delay: 651530.0,
    trkdelay: 50009.0,
    pti80: 1.13,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.12,
    tti: 1.06,
    trktti: 1.03,
    ghg: 3056.0,
    congcost: 1.3684362e7,
    allspeed: 50.24,
    trkspeed: 58.79,
    ttivmt: 340863.0,
    trkttivmt: 62332.0,
  },
  {
    agglevel: "county",
    regioncode: 232,
    year: 2018,
    count: 540,
    len_sec: 390.2880000000003,
    lanemiles: 853.8900000000003,
    delay: 512575.0,
    trkdelay: 48871.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.06,
    ghg: 2602.0,
    congcost: 1.1330215e7,
    allspeed: 52.59,
    trkspeed: 54.06,
    ttivmt: 297854.0,
    trkttivmt: 32818.0,
  },
  {
    agglevel: "county",
    regioncode: 232,
    year: 2019,
    count: 127,
    len_sec: 246.20200000000008,
    lanemiles: 565.6370000000001,
    delay: 342270.0,
    trkdelay: 46175.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.12,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.05,
    ghg: 1829.0,
    congcost: 8322608.0,
    allspeed: 55.52,
    trkspeed: 56.48,
    ttivmt: 262132.0,
    trkttivmt: 33179.0,
  },
  {
    agglevel: "county",
    regioncode: 232,
    year: 2020,
    count: 774,
    len_sec: 391.4629999999998,
    lanemiles: 861.5889999999995,
    delay: 419562.0,
    trkdelay: 59396.0,
    pti80: 1.1,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.05,
    ghg: 2274.0,
    congcost: 1.0647357e7,
    allspeed: 54.6,
    trkspeed: 56.57,
    ttivmt: 312987.0,
    trkttivmt: 41773.0,
  },
  {
    agglevel: "county",
    regioncode: 232,
    year: 2021,
    count: 775,
    len_sec: 391.4629999999998,
    lanemiles: 861.3479999999993,
    delay: 416213.0,
    trkdelay: 54348.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.14,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.06,
    ghg: 2111.0,
    congcost: 1.0409085e7,
    allspeed: 54.41,
    trkspeed: 56.07,
    ttivmt: 299128.0,
    trkttivmt: 34513.0,
  },
  {
    agglevel: "county",
    regioncode: 232,
    year: 2022,
    count: 761,
    len_sec: 391.52200000000005,
    lanemiles: 861.4769999999992,
    delay: 444661.0,
    trkdelay: 48340.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.18,
    trkpti95: 1.2,
    tti: 1.05,
    trktti: 1.05,
    ghg: 2085.0,
    congcost: 1.1996766e7,
    allspeed: 54.65,
    trkspeed: 55.91,
    ttivmt: 315847.0,
    trkttivmt: 36595.0,
  },
  {
    agglevel: "county",
    regioncode: 233,
    year: 2017,
    count: 474,
    len_sec: 398.2280000000001,
    lanemiles: 890.3460000000006,
    delay: 831483.0,
    trkdelay: 34925.0,
    pti80: 1.15,
    trkpti80: 1.08,
    pti95: 1.18,
    trkpti95: 1.1,
    tti: 1.08,
    trktti: 1.03,
    ghg: 3709.0,
    congcost: 1.6574724e7,
    allspeed: 44.16,
    trkspeed: 58.09,
    ttivmt: 310079.0,
    trkttivmt: 41590.0,
  },
  {
    agglevel: "county",
    regioncode: 233,
    year: 2018,
    count: 463,
    len_sec: 398.84700000000004,
    lanemiles: 888.31,
    delay: 672883.0,
    trkdelay: 60085.0,
    pti80: 1.15,
    trkpti80: 1.15,
    pti95: 1.18,
    trkpti95: 1.2,
    tti: 1.08,
    trktti: 1.07,
    ghg: 3623.0,
    congcost: 1.4791807e7,
    allspeed: 47.01,
    trkspeed: 53.52,
    ttivmt: 258130.0,
    trkttivmt: 33314.0,
  },
  {
    agglevel: "county",
    regioncode: 233,
    year: 2019,
    count: 173,
    len_sec: 262.99999999999994,
    lanemiles: 616.958,
    delay: 756351.0,
    trkdelay: 66347.0,
    pti80: 1.17,
    trkpti80: 1.16,
    pti95: 1.19,
    trkpti95: 1.21,
    tti: 1.08,
    trktti: 1.07,
    ghg: 4126.0,
    congcost: 1.7270768e7,
    allspeed: 47.61,
    trkspeed: 53.45,
    ttivmt: 245267.0,
    trkttivmt: 32376.0,
  },
  {
    agglevel: "county",
    regioncode: 233,
    year: 2020,
    count: 759,
    len_sec: 398.436,
    lanemiles: 900.6400000000007,
    delay: 927753.0,
    trkdelay: 42451.0,
    pti80: 1.16,
    trkpti80: 1.11,
    pti95: 1.19,
    trkpti95: 1.14,
    tti: 1.08,
    trktti: 1.04,
    ghg: 4149.0,
    congcost: 2.0231123e7,
    allspeed: 47.95,
    trkspeed: 58.24,
    ttivmt: 314219.0,
    trkttivmt: 37212.0,
  },
  {
    agglevel: "county",
    regioncode: 233,
    year: 2021,
    count: 750,
    len_sec: 397.4670000000005,
    lanemiles: 900.141,
    delay: 690525.0,
    trkdelay: 34074.0,
    pti80: 1.14,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.13,
    tti: 1.07,
    trktti: 1.03,
    ghg: 3223.0,
    congcost: 1.5587427e7,
    allspeed: 47.39,
    trkspeed: 58.68,
    ttivmt: 260019.0,
    trkttivmt: 34289.0,
  },
  {
    agglevel: "county",
    regioncode: 233,
    year: 2022,
    count: 753,
    len_sec: 397.51999999999987,
    lanemiles: 900.3009999999996,
    delay: 642964.0,
    trkdelay: 43219.0,
    pti80: 1.14,
    trkpti80: 1.11,
    pti95: 1.19,
    trkpti95: 1.16,
    tti: 1.06,
    trktti: 1.04,
    ghg: 2813.0,
    congcost: 1.6404763e7,
    allspeed: 47.08,
    trkspeed: 58.5,
    ttivmt: 303059.0,
    trkttivmt: 42866.0,
  },
  {
    agglevel: "county",
    regioncode: 234,
    year: 2017,
    count: 991,
    len_sec: 647.1189999999995,
    lanemiles: 1415.8249999999966,
    delay: 1200886.0,
    trkdelay: 215429.0,
    pti80: 1.13,
    trkpti80: 1.15,
    pti95: 1.18,
    trkpti95: 1.2,
    tti: 1.05,
    trktti: 1.05,
    ghg: 6547.0,
    congcost: 2.8985625e7,
    allspeed: 58.44,
    trkspeed: 60.28,
    ttivmt: 972988.0,
    trkttivmt: 180304.0,
  },
  {
    agglevel: "county",
    regioncode: 234,
    year: 2018,
    count: 1007,
    len_sec: 644.2039999999992,
    lanemiles: 1409.8529999999976,
    delay: 779306.0,
    trkdelay: 91710.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.02,
    ghg: 3361.0,
    congcost: 1.7628125e7,
    allspeed: 58.48,
    trkspeed: 61.21,
    ttivmt: 974634.0,
    trkttivmt: 174579.0,
  },
  {
    agglevel: "county",
    regioncode: 234,
    year: 2019,
    count: 468,
    len_sec: 645.0459999999997,
    lanemiles: 1411.5369999999996,
    delay: 714386.0,
    trkdelay: 73331.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.14,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2974.0,
    congcost: 1.6413671e7,
    allspeed: 59.53,
    trkspeed: 60.65,
    ttivmt: 1016273.0,
    trkttivmt: 151009.0,
  },
  {
    agglevel: "county",
    regioncode: 234,
    year: 2020,
    count: 1405,
    len_sec: 646.8149999999981,
    lanemiles: 1426.6789999999976,
    delay: 768582.0,
    trkdelay: 77934.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 3328.0,
    congcost: 1.8230025e7,
    allspeed: 59.36,
    trkspeed: 62.46,
    ttivmt: 1015891.0,
    trkttivmt: 171595.0,
  },
  {
    agglevel: "county",
    regioncode: 234,
    year: 2021,
    count: 1500,
    len_sec: 646.5939999999991,
    lanemiles: 1426.2069999999974,
    delay: 521049.0,
    trkdelay: 59388.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2213.0,
    congcost: 1.2696729e7,
    allspeed: 59.64,
    trkspeed: 62.58,
    ttivmt: 961923.0,
    trkttivmt: 157976.0,
  },
  {
    agglevel: "county",
    regioncode: 234,
    year: 2022,
    count: 1511,
    len_sec: 647.2969999999991,
    lanemiles: 1427.612999999996,
    delay: 903242.0,
    trkdelay: 122921.0,
    pti80: 1.13,
    trkpti80: 1.08,
    pti95: 1.2,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.03,
    ghg: 3554.0,
    congcost: 2.4978567e7,
    allspeed: 59.1,
    trkspeed: 61.71,
    ttivmt: 1095753.0,
    trkttivmt: 201877.0,
  },
  {
    agglevel: "county",
    regioncode: 235,
    year: 2017,
    count: 537,
    len_sec: 362.1589999999999,
    lanemiles: 1083.008999999999,
    delay: 2574015.0,
    trkdelay: 106052.0,
    pti80: 1.14,
    trkpti80: 1.1,
    pti95: 1.18,
    trkpti95: 1.14,
    tti: 1.06,
    trktti: 1.02,
    ghg: 10921.0,
    congcost: 5.1143028e7,
    allspeed: 48.94,
    trkspeed: 59.24,
    ttivmt: 1299110.0,
    trkttivmt: 181289.0,
  },
  {
    agglevel: "county",
    regioncode: 235,
    year: 2018,
    count: 547,
    len_sec: 368.84299999999973,
    lanemiles: 1089.1690000000003,
    delay: 2449330.0,
    trkdelay: 227490.0,
    pti80: 1.15,
    trkpti80: 1.16,
    pti95: 1.19,
    trkpti95: 1.2,
    tti: 1.07,
    trktti: 1.07,
    ghg: 13499.0,
    congcost: 5.4173672e7,
    allspeed: 50.94,
    trkspeed: 55.61,
    ttivmt: 1013151.0,
    trkttivmt: 134265.0,
  },
  {
    agglevel: "county",
    regioncode: 235,
    year: 2019,
    count: 246,
    len_sec: 224.726,
    lanemiles: 764.3590000000003,
    delay: 1643947.0,
    trkdelay: 198162.0,
    pti80: 1.14,
    trkpti80: 1.13,
    pti95: 1.17,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.05,
    ghg: 9663.0,
    congcost: 3.9453021e7,
    allspeed: 52.94,
    trkspeed: 55.82,
    ttivmt: 914053.0,
    trkttivmt: 139620.0,
  },
  {
    agglevel: "county",
    regioncode: 235,
    year: 2020,
    count: 928,
    len_sec: 368.9449999999995,
    lanemiles: 1096.5449999999992,
    delay: 2076869.0,
    trkdelay: 132196.0,
    pti80: 1.13,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.16,
    tti: 1.06,
    trktti: 1.04,
    ghg: 10285.0,
    congcost: 4.6830642e7,
    allspeed: 51.19,
    trkspeed: 59.25,
    ttivmt: 1068892.0,
    trkttivmt: 131406.0,
  },
  {
    agglevel: "county",
    regioncode: 235,
    year: 2021,
    count: 915,
    len_sec: 370.26600000000025,
    lanemiles: 1098.8359999999993,
    delay: 1277420.0,
    trkdelay: 110671.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.03,
    ghg: 6716.0,
    congcost: 3.0361546e7,
    allspeed: 52.36,
    trkspeed: 59.34,
    ttivmt: 1015743.0,
    trkttivmt: 126093.0,
  },
  {
    agglevel: "county",
    regioncode: 235,
    year: 2022,
    count: 956,
    len_sec: 376.28299999999956,
    lanemiles: 1113.046,
    delay: 2083603.0,
    trkdelay: 137108.0,
    pti80: 1.13,
    trkpti80: 1.11,
    pti95: 1.19,
    trkpti95: 1.18,
    tti: 1.05,
    trktti: 1.03,
    ghg: 10201.0,
    congcost: 5.3365824e7,
    allspeed: 51.69,
    trkspeed: 58.44,
    ttivmt: 1208974.0,
    trkttivmt: 150451.0,
  },
  {
    agglevel: "county",
    regioncode: 236,
    year: 2017,
    count: 629,
    len_sec: 429.2199999999997,
    lanemiles: 985.4799999999992,
    delay: 1362977.0,
    trkdelay: 70228.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.04,
    trktti: 1.01,
    ghg: 5214.0,
    congcost: 2.7386249e7,
    allspeed: 56.39,
    trkspeed: 62.74,
    ttivmt: 1235028.0,
    trkttivmt: 241068.0,
  },
  {
    agglevel: "county",
    regioncode: 236,
    year: 2018,
    count: 684,
    len_sec: 428.78099999999966,
    lanemiles: 984.2799999999996,
    delay: 1213388.0,
    trkdelay: 93802.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 4951.0,
    congcost: 2.5905223e7,
    allspeed: 57.5,
    trkspeed: 61.28,
    ttivmt: 1049219.0,
    trkttivmt: 149184.0,
  },
  {
    agglevel: "county",
    regioncode: 236,
    year: 2019,
    count: 222,
    len_sec: 277.46899999999977,
    lanemiles: 673.3959999999997,
    delay: 1080680.0,
    trkdelay: 101349.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.03,
    ghg: 4556.0,
    congcost: 2.4543309e7,
    allspeed: 59.25,
    trkspeed: 60.5,
    ttivmt: 994063.0,
    trkttivmt: 147927.0,
  },
  {
    agglevel: "county",
    regioncode: 236,
    year: 2020,
    count: 951,
    len_sec: 428.8589999999996,
    lanemiles: 990.2759999999988,
    delay: 1323287.0,
    trkdelay: 141324.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 5765.0,
    congcost: 3.1636485e7,
    allspeed: 58.26,
    trkspeed: 61.58,
    ttivmt: 1108673.0,
    trkttivmt: 198417.0,
  },
  {
    agglevel: "county",
    regioncode: 236,
    year: 2021,
    count: 951,
    len_sec: 428.23999999999853,
    lanemiles: 991.9499999999978,
    delay: 754228.0,
    trkdelay: 93602.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 3655.0,
    congcost: 1.8686301e7,
    allspeed: 58.8,
    trkspeed: 61.47,
    ttivmt: 984418.0,
    trkttivmt: 157943.0,
  },
  {
    agglevel: "county",
    regioncode: 236,
    year: 2022,
    count: 965,
    len_sec: 426.2099999999997,
    lanemiles: 1005.3839999999992,
    delay: 1228083.0,
    trkdelay: 187569.0,
    pti80: 1.14,
    trkpti80: 1.1,
    pti95: 1.2,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 4800.0,
    congcost: 3.4630516e7,
    allspeed: 59.14,
    trkspeed: 60.92,
    ttivmt: 1164879.0,
    trkttivmt: 184421.0,
  },
  {
    agglevel: "county",
    regioncode: 237,
    year: 2017,
    count: 391,
    len_sec: 277.3810000000001,
    lanemiles: 665.8389999999996,
    delay: 604845.0,
    trkdelay: 67784.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2612.0,
    congcost: 1.3282254e7,
    allspeed: 58.54,
    trkspeed: 61.22,
    ttivmt: 855094.0,
    trkttivmt: 159221.0,
  },
  {
    agglevel: "county",
    regioncode: 237,
    year: 2018,
    count: 408,
    len_sec: 277.5609999999999,
    lanemiles: 668.2779999999998,
    delay: 613190.0,
    trkdelay: 77628.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 2605.0,
    congcost: 1.4019205e7,
    allspeed: 58.63,
    trkspeed: 59.68,
    ttivmt: 876716.0,
    trkttivmt: 101764.0,
  },
  {
    agglevel: "county",
    regioncode: 237,
    year: 2019,
    count: 195,
    len_sec: 189.50799999999992,
    lanemiles: 490.03,
    delay: 543605.0,
    trkdelay: 90769.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 2355.0,
    congcost: 1.3633639e7,
    allspeed: 59.91,
    trkspeed: 59.74,
    ttivmt: 832948.0,
    trkttivmt: 113142.0,
  },
  {
    agglevel: "county",
    regioncode: 237,
    year: 2020,
    count: 546,
    len_sec: 281.99899999999974,
    lanemiles: 672.7279999999989,
    delay: 884497.0,
    trkdelay: 93358.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.03,
    ghg: 3537.0,
    congcost: 2.1028828e7,
    allspeed: 58.35,
    trkspeed: 60.14,
    ttivmt: 921111.0,
    trkttivmt: 112418.0,
  },
  {
    agglevel: "county",
    regioncode: 237,
    year: 2021,
    count: 625,
    len_sec: 282.27099999999996,
    lanemiles: 678.6059999999995,
    delay: 563499.0,
    trkdelay: 82851.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 2696.0,
    congcost: 1.4315795e7,
    allspeed: 58.36,
    trkspeed: 60.29,
    ttivmt: 821027.0,
    trkttivmt: 104712.0,
  },
  {
    agglevel: "county",
    regioncode: 237,
    year: 2022,
    count: 652,
    len_sec: 287.0089999999998,
    lanemiles: 695.1519999999991,
    delay: 910119.0,
    trkdelay: 146254.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.15,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.05,
    ghg: 4057.0,
    congcost: 2.6035079e7,
    allspeed: 57.2,
    trkspeed: 59.69,
    ttivmt: 926574.0,
    trkttivmt: 127178.0,
  },
  {
    agglevel: "county",
    regioncode: 238,
    year: 2017,
    count: 410,
    len_sec: 268.0669999999998,
    lanemiles: 646.9519999999997,
    delay: 141294.0,
    trkdelay: 18741.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 727.0,
    congcost: 3213721.0,
    allspeed: 61.17,
    trkspeed: 62.51,
    ttivmt: 357472.0,
    trkttivmt: 69383.0,
  },
  {
    agglevel: "county",
    regioncode: 238,
    year: 2018,
    count: 376,
    len_sec: 270.32599999999974,
    lanemiles: 652.0959999999997,
    delay: 213075.0,
    trkdelay: 48067.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 1392.0,
    congcost: 5611140.0,
    allspeed: 60.91,
    trkspeed: 61.85,
    ttivmt: 461855.0,
    trkttivmt: 94018.0,
  },
  {
    agglevel: "county",
    regioncode: 238,
    year: 2019,
    count: 177,
    len_sec: 232.918,
    lanemiles: 577.5380000000001,
    delay: 276272.0,
    trkdelay: 114500.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 2594.0,
    congcost: 9487051.0,
    allspeed: 60.55,
    trkspeed: 58.9,
    ttivmt: 485889.0,
    trkttivmt: 164108.0,
  },
  {
    agglevel: "county",
    regioncode: 238,
    year: 2020,
    count: 515,
    len_sec: 270.69199999999984,
    lanemiles: 650.4039999999995,
    delay: 277833.0,
    trkdelay: 107216.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2511.0,
    congcost: 9643506.0,
    allspeed: 60.49,
    trkspeed: 61.48,
    ttivmt: 555106.0,
    trkttivmt: 174597.0,
  },
  {
    agglevel: "county",
    regioncode: 238,
    year: 2021,
    count: 512,
    len_sec: 270.544,
    lanemiles: 650.3830000000003,
    delay: 151960.0,
    trkdelay: 52709.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1269.0,
    congcost: 4852973.0,
    allspeed: 61.69,
    trkspeed: 62.87,
    ttivmt: 487363.0,
    trkttivmt: 148491.0,
  },
  {
    agglevel: "county",
    regioncode: 238,
    year: 2022,
    count: 516,
    len_sec: 270.536,
    lanemiles: 650.3840000000004,
    delay: 181941.0,
    trkdelay: 43187.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1146.0,
    congcost: 5765745.0,
    allspeed: 61.56,
    trkspeed: 62.84,
    ttivmt: 491074.0,
    trkttivmt: 167548.0,
  },
  {
    agglevel: "county",
    regioncode: 239,
    year: 2017,
    count: 549,
    len_sec: 347.8519999999992,
    lanemiles: 801.1499999999985,
    delay: 675813.0,
    trkdelay: 55749.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 2775.0,
    congcost: 1.4226111e7,
    allspeed: 55.1,
    trkspeed: 58.48,
    ttivmt: 511430.0,
    trkttivmt: 58869.0,
  },
  {
    agglevel: "county",
    regioncode: 239,
    year: 2018,
    count: 565,
    len_sec: 345.5180000000001,
    lanemiles: 796.9859999999999,
    delay: 589109.0,
    trkdelay: 74021.0,
    pti80: 1.11,
    trkpti80: 1.15,
    pti95: 1.14,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.06,
    ghg: 2874.0,
    congcost: 1.3532233e7,
    allspeed: 54.89,
    trkspeed: 54.38,
    ttivmt: 487984.0,
    trkttivmt: 47134.0,
  },
  {
    agglevel: "county",
    regioncode: 239,
    year: 2019,
    count: 153,
    len_sec: 170.35700000000006,
    lanemiles: 447.26500000000016,
    delay: 477912.0,
    trkdelay: 77739.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.13,
    trkpti95: 1.19,
    tti: 1.03,
    trktti: 1.06,
    ghg: 2650.0,
    congcost: 1.207287e7,
    allspeed: 57.53,
    trkspeed: 55.2,
    ttivmt: 486832.0,
    trkttivmt: 48714.0,
  },
  {
    agglevel: "county",
    regioncode: 239,
    year: 2020,
    count: 819,
    len_sec: 346.32299999999975,
    lanemiles: 804.3479999999997,
    delay: 631915.0,
    trkdelay: 47884.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.04,
    ghg: 2645.0,
    congcost: 1.4402203e7,
    allspeed: 56.45,
    trkspeed: 58.12,
    ttivmt: 565390.0,
    trkttivmt: 48694.0,
  },
  {
    agglevel: "county",
    regioncode: 239,
    year: 2021,
    count: 807,
    len_sec: 346.39299999999923,
    lanemiles: 804.5159999999993,
    delay: 337033.0,
    trkdelay: 39208.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 1347.0,
    congcost: 8218749.0,
    allspeed: 57.95,
    trkspeed: 58.67,
    ttivmt: 533050.0,
    trkttivmt: 50825.0,
  },
  {
    agglevel: "county",
    regioncode: 239,
    year: 2022,
    count: 816,
    len_sec: 346.40699999999913,
    lanemiles: 796.0669999999991,
    delay: 533978.0,
    trkdelay: 50679.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1990.0,
    congcost: 1.4015513e7,
    allspeed: 57.87,
    trkspeed: 58.82,
    ttivmt: 621481.0,
    trkttivmt: 59113.0,
  },
  {
    agglevel: "county",
    regioncode: 240,
    year: 2017,
    count: 1314,
    len_sec: 925.6019999999988,
    lanemiles: 2212.1910000000007,
    delay: 8425684.0,
    trkdelay: 399845.0,
    pti80: 1.22,
    trkpti80: 1.13,
    pti95: 1.28,
    trkpti95: 1.17,
    tti: 1.1,
    trktti: 1.04,
    ghg: 34992.0,
    congcost: 1.68734998e8,
    allspeed: 42.81,
    trkspeed: 57.07,
    ttivmt: 2864980.0,
    trkttivmt: 411269.0,
  },
  {
    agglevel: "county",
    regioncode: 240,
    year: 2018,
    count: 1330,
    len_sec: 927.2639999999988,
    lanemiles: 2260.7449999999953,
    delay: 7429413.0,
    trkdelay: 608135.0,
    pti80: 1.25,
    trkpti80: 1.21,
    pti95: 1.3,
    trkpti95: 1.26,
    tti: 1.13,
    trktti: 1.09,
    ghg: 35826.0,
    congcost: 1.60750028e8,
    allspeed: 45.72,
    trkspeed: 53.42,
    ttivmt: 1870792.0,
    trkttivmt: 242705.0,
  },
  {
    agglevel: "county",
    regioncode: 240,
    year: 2019,
    count: 420,
    len_sec: 523.5939999999997,
    lanemiles: 1432.6049999999987,
    delay: 7932448.0,
    trkdelay: 687945.0,
    pti80: 1.25,
    trkpti80: 1.18,
    pti95: 1.3,
    trkpti95: 1.22,
    tti: 1.13,
    trktti: 1.08,
    ghg: 39475.0,
    congcost: 1.79848224e8,
    allspeed: 45.07,
    trkspeed: 52.08,
    ttivmt: 1835304.0,
    trkttivmt: 275363.0,
  },
  {
    agglevel: "county",
    regioncode: 240,
    year: 2020,
    count: 1921,
    len_sec: 927.5339999999995,
    lanemiles: 2242.6119999999983,
    delay: 8108363.0,
    trkdelay: 677927.0,
    pti80: 1.23,
    trkpti80: 1.17,
    pti95: 1.28,
    trkpti95: 1.22,
    tti: 1.11,
    trktti: 1.07,
    ghg: 40816.0,
    congcost: 1.88513098e8,
    allspeed: 43.73,
    trkspeed: 54.98,
    ttivmt: 2106759.0,
    trkttivmt: 312918.0,
  },
  {
    agglevel: "county",
    regioncode: 240,
    year: 2021,
    count: 1888,
    len_sec: 926.6579999999983,
    lanemiles: 2242.8890000000015,
    delay: 6250845.0,
    trkdelay: 554767.0,
    pti80: 1.2,
    trkpti80: 1.15,
    pti95: 1.25,
    trkpti95: 1.22,
    tti: 1.09,
    trktti: 1.06,
    ghg: 31742.0,
    congcost: 1.48643159e8,
    allspeed: 43.36,
    trkspeed: 55.45,
    ttivmt: 1904317.0,
    trkttivmt: 289580.0,
  },
  {
    agglevel: "county",
    regioncode: 240,
    year: 2022,
    count: 1983,
    len_sec: 929.4649999999965,
    lanemiles: 2254.0049999999937,
    delay: 7410977.0,
    trkdelay: 769518.0,
    pti80: 1.22,
    trkpti80: 1.17,
    pti95: 1.31,
    trkpti95: 1.25,
    tti: 1.1,
    trktti: 1.06,
    ghg: 37327.0,
    congcost: 1.99294677e8,
    allspeed: 42.81,
    trkspeed: 53.93,
    ttivmt: 2044335.0,
    trkttivmt: 347119.0,
  },
  {
    agglevel: "county",
    regioncode: 241,
    year: 2017,
    count: 626,
    len_sec: 433.56700000000023,
    lanemiles: 959.8980000000003,
    delay: 726217.0,
    trkdelay: 61557.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 3223.0,
    congcost: 1.5386469e7,
    allspeed: 56.1,
    trkspeed: 56.55,
    ttivmt: 761518.0,
    trkttivmt: 67917.0,
  },
  {
    agglevel: "county",
    regioncode: 241,
    year: 2018,
    count: 712,
    len_sec: 429.1289999999997,
    lanemiles: 950.2289999999992,
    delay: 591301.0,
    trkdelay: 72972.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.02,
    ghg: 3090.0,
    congcost: 1.3587881e7,
    allspeed: 56.83,
    trkspeed: 60.56,
    ttivmt: 696741.0,
    trkttivmt: 117869.0,
  },
  {
    agglevel: "county",
    regioncode: 241,
    year: 2019,
    count: 280,
    len_sec: 260.77099999999996,
    lanemiles: 613.6190000000003,
    delay: 441977.0,
    trkdelay: 84491.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.03,
    ghg: 2872.0,
    congcost: 1.1677056e7,
    allspeed: 58.97,
    trkspeed: 60.5,
    ttivmt: 593801.0,
    trkttivmt: 117629.0,
  },
  {
    agglevel: "county",
    regioncode: 241,
    year: 2020,
    count: 1007,
    len_sec: 430.90599999999995,
    lanemiles: 982.7879999999992,
    delay: 592549.0,
    trkdelay: 78541.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.03,
    ghg: 3209.0,
    congcost: 1.4850383e7,
    allspeed: 57.65,
    trkspeed: 60.21,
    ttivmt: 684825.0,
    trkttivmt: 111622.0,
  },
  {
    agglevel: "county",
    regioncode: 241,
    year: 2021,
    count: 1042,
    len_sec: 430.7899999999988,
    lanemiles: 981.9799999999988,
    delay: 473601.0,
    trkdelay: 67480.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.03,
    ghg: 2556.0,
    congcost: 1.2041503e7,
    allspeed: 57.6,
    trkspeed: 60.44,
    ttivmt: 660022.0,
    trkttivmt: 102334.0,
  },
  {
    agglevel: "county",
    regioncode: 241,
    year: 2022,
    count: 1052,
    len_sec: 434.7959999999994,
    lanemiles: 991.8549999999984,
    delay: 628107.0,
    trkdelay: 66061.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.02,
    ghg: 2771.0,
    congcost: 1.6820126e7,
    allspeed: 57.87,
    trkspeed: 60.69,
    ttivmt: 821369.0,
    trkttivmt: 134403.0,
  },
  {
    agglevel: "county",
    regioncode: 242,
    year: 2017,
    count: 272,
    len_sec: 287.03899999999993,
    lanemiles: 650.9820000000001,
    delay: 67810.0,
    trkdelay: 26049.0,
    pti80: 1.07,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.0,
    ghg: 524.0,
    congcost: 2074883.0,
    allspeed: 62.84,
    trkspeed: 64.35,
    ttivmt: 225726.0,
    trkttivmt: 186355.0,
  },
  {
    agglevel: "county",
    regioncode: 242,
    year: 2018,
    count: 352,
    len_sec: 297.961,
    lanemiles: 673.002,
    delay: 44411.0,
    trkdelay: 8462.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.0,
    ghg: 186.0,
    congcost: 1103212.0,
    allspeed: 62.11,
    trkspeed: 63.69,
    ttivmt: 228549.0,
    trkttivmt: 79861.0,
  },
  {
    agglevel: "county",
    regioncode: 242,
    year: 2019,
    count: 127,
    len_sec: 298.22200000000004,
    lanemiles: 673.528,
    delay: 50667.0,
    trkdelay: 18241.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.15,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.01,
    ghg: 420.0,
    congcost: 1638429.0,
    allspeed: 63.01,
    trkspeed: 61.61,
    ttivmt: 232841.0,
    trkttivmt: 78180.0,
  },
  {
    agglevel: "county",
    regioncode: 242,
    year: 2020,
    count: 494,
    len_sec: 298.22799999999995,
    lanemiles: 673.5399999999998,
    delay: 51082.0,
    trkdelay: 14271.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 352.0,
    congcost: 1560594.0,
    allspeed: 63.01,
    trkspeed: 63.89,
    ttivmt: 225509.0,
    trkttivmt: 90372.0,
  },
  {
    agglevel: "county",
    regioncode: 242,
    year: 2021,
    count: 531,
    len_sec: 298.89600000000013,
    lanemiles: 674.2140000000004,
    delay: 46879.0,
    trkdelay: 12779.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.01,
    trktti: 1.01,
    ghg: 283.0,
    congcost: 1377644.0,
    allspeed: 63.06,
    trkspeed: 64.14,
    ttivmt: 218099.0,
    trkttivmt: 101655.0,
  },
  {
    agglevel: "county",
    regioncode: 242,
    year: 2022,
    count: 539,
    len_sec: 298.8930000000001,
    lanemiles: 674.2080000000008,
    delay: 56301.0,
    trkdelay: 13235.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.0,
    ghg: 252.0,
    congcost: 1758532.0,
    allspeed: 63.06,
    trkspeed: 64.22,
    ttivmt: 247557.0,
    trkttivmt: 112935.0,
  },
  {
    agglevel: "county",
    regioncode: 243,
    year: 2017,
    count: 1347,
    len_sec: 605.3469999999994,
    lanemiles: 1556.6799999999987,
    delay: 2713341.0,
    trkdelay: 108709.0,
    pti80: 1.13,
    trkpti80: 1.09,
    pti95: 1.17,
    trkpti95: 1.13,
    tti: 1.06,
    trktti: 1.02,
    ghg: 11073.0,
    congcost: 5.3730891e7,
    allspeed: 47.38,
    trkspeed: 58.34,
    ttivmt: 1483492.0,
    trkttivmt: 180234.0,
  },
  {
    agglevel: "county",
    regioncode: 243,
    year: 2018,
    count: 1274,
    len_sec: 604.8819999999998,
    lanemiles: 1556.770999999997,
    delay: 1709731.0,
    trkdelay: 125414.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.05,
    trktti: 1.04,
    ghg: 8173.0,
    congcost: 3.6552605e7,
    allspeed: 51.41,
    trkspeed: 55.44,
    ttivmt: 1047832.0,
    trkttivmt: 111431.0,
  },
  {
    agglevel: "county",
    regioncode: 243,
    year: 2019,
    count: 846,
    len_sec: 600.0729999999993,
    lanemiles: 1546.3320000000003,
    delay: 2065673.0,
    trkdelay: 184179.0,
    pti80: 1.13,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.05,
    ghg: 10751.0,
    congcost: 4.712631e7,
    allspeed: 50.82,
    trkspeed: 54.24,
    ttivmt: 1086324.0,
    trkttivmt: 128104.0,
  },
  {
    agglevel: "county",
    regioncode: 243,
    year: 2020,
    count: 1815,
    len_sec: 604.7349999999989,
    lanemiles: 1551.1279999999958,
    delay: 2206670.0,
    trkdelay: 106988.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.13,
    tti: 1.05,
    trktti: 1.03,
    ghg: 9840.0,
    congcost: 4.8340948e7,
    allspeed: 50.79,
    trkspeed: 57.81,
    ttivmt: 1139109.0,
    trkttivmt: 107644.0,
  },
  {
    agglevel: "county",
    regioncode: 243,
    year: 2021,
    count: 1819,
    len_sec: 604.9659999999994,
    lanemiles: 1551.748999999994,
    delay: 1471986.0,
    trkdelay: 93419.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.02,
    ghg: 6935.0,
    congcost: 3.3844671e7,
    allspeed: 50.86,
    trkspeed: 58.8,
    ttivmt: 1041413.0,
    trkttivmt: 135410.0,
  },
  {
    agglevel: "county",
    regioncode: 243,
    year: 2022,
    count: 1889,
    len_sec: 605.3239999999989,
    lanemiles: 1552.9009999999928,
    delay: 1952212.0,
    trkdelay: 116295.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.18,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.03,
    ghg: 9009.0,
    congcost: 4.9453376e7,
    allspeed: 50.0,
    trkspeed: 57.83,
    ttivmt: 1155365.0,
    trkttivmt: 130148.0,
  },
  {
    agglevel: "county",
    regioncode: 244,
    year: 2017,
    count: 407,
    len_sec: 354.94299999999953,
    lanemiles: 807.073999999999,
    delay: 237811.0,
    trkdelay: 38892.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.01,
    ghg: 1247.0,
    congcost: 5623594.0,
    allspeed: 57.31,
    trkspeed: 62.92,
    ttivmt: 258342.0,
    trkttivmt: 102251.0,
  },
  {
    agglevel: "county",
    regioncode: 244,
    year: 2018,
    count: 453,
    len_sec: 354.04900000000015,
    lanemiles: 804.3710000000007,
    delay: 108051.0,
    trkdelay: 15988.0,
    pti80: 1.08,
    trkpti80: 1.06,
    pti95: 1.11,
    trkpti95: 1.07,
    tti: 1.01,
    trktti: 1.01,
    ghg: 517.0,
    congcost: 2557078.0,
    allspeed: 59.93,
    trkspeed: 61.88,
    ttivmt: 256141.0,
    trkttivmt: 51050.0,
  },
  {
    agglevel: "county",
    regioncode: 244,
    year: 2019,
    count: 241,
    len_sec: 353.95600000000036,
    lanemiles: 804.1850000000004,
    delay: 143280.0,
    trkdelay: 24148.0,
    pti80: 1.1,
    trkpti80: 1.06,
    pti95: 1.13,
    trkpti95: 1.08,
    tti: 1.02,
    trktti: 1.02,
    ghg: 805.0,
    congcost: 3649438.0,
    allspeed: 61.0,
    trkspeed: 61.35,
    ttivmt: 262398.0,
    trkttivmt: 53240.0,
  },
  {
    agglevel: "county",
    regioncode: 244,
    year: 2020,
    count: 635,
    len_sec: 354.81899999999956,
    lanemiles: 806.5309999999989,
    delay: 182417.0,
    trkdelay: 19274.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.01,
    ghg: 899.0,
    congcost: 4383388.0,
    allspeed: 60.5,
    trkspeed: 62.66,
    ttivmt: 276859.0,
    trkttivmt: 51012.0,
  },
  {
    agglevel: "county",
    regioncode: 244,
    year: 2021,
    count: 635,
    len_sec: 355.240999999999,
    lanemiles: 814.9079999999993,
    delay: 102595.0,
    trkdelay: 16243.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 579.0,
    congcost: 2664102.0,
    allspeed: 60.72,
    trkspeed: 63.13,
    ttivmt: 244922.0,
    trkttivmt: 63833.0,
  },
  {
    agglevel: "county",
    regioncode: 244,
    year: 2022,
    count: 652,
    len_sec: 355.2899999999998,
    lanemiles: 815.0279999999991,
    delay: 147695.0,
    trkdelay: 20905.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.15,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.01,
    ghg: 657.0,
    congcost: 4136151.0,
    allspeed: 60.2,
    trkspeed: 62.92,
    ttivmt: 261557.0,
    trkttivmt: 60776.0,
  },
  {
    agglevel: "county",
    regioncode: 245,
    year: 2017,
    count: 428,
    len_sec: 261.4090000000002,
    lanemiles: 560.5660000000001,
    delay: 285509.0,
    trkdelay: 14781.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.02,
    ghg: 1167.0,
    congcost: 5752398.0,
    allspeed: 54.06,
    trkspeed: 61.32,
    ttivmt: 209260.0,
    trkttivmt: 32906.0,
  },
  {
    agglevel: "county",
    regioncode: 245,
    year: 2018,
    count: 393,
    len_sec: 260.16999999999985,
    lanemiles: 558.1080000000001,
    delay: 200521.0,
    trkdelay: 14045.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.14,
    trkpti95: 1.09,
    tti: 1.03,
    trktti: 1.02,
    ghg: 786.0,
    congcost: 4233572.0,
    allspeed: 54.65,
    trkspeed: 57.81,
    ttivmt: 199113.0,
    trkttivmt: 24516.0,
  },
  {
    agglevel: "county",
    regioncode: 245,
    year: 2019,
    count: 136,
    len_sec: 171.46899999999994,
    lanemiles: 380.706,
    delay: 201884.0,
    trkdelay: 22157.0,
    pti80: 1.12,
    trkpti80: 1.09,
    pti95: 1.17,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 941.0,
    congcost: 4712872.0,
    allspeed: 57.68,
    trkspeed: 59.15,
    ttivmt: 174704.0,
    trkttivmt: 23985.0,
  },
  {
    agglevel: "county",
    regioncode: 245,
    year: 2020,
    count: 565,
    len_sec: 260.7569999999999,
    lanemiles: 559.2880000000001,
    delay: 308003.0,
    trkdelay: 27094.0,
    pti80: 1.13,
    trkpti80: 1.09,
    pti95: 1.18,
    trkpti95: 1.12,
    tti: 1.05,
    trktti: 1.03,
    ghg: 1270.0,
    congcost: 7147126.0,
    allspeed: 56.13,
    trkspeed: 60.47,
    ttivmt: 202367.0,
    trkttivmt: 30590.0,
  },
  {
    agglevel: "county",
    regioncode: 245,
    year: 2021,
    count: 571,
    len_sec: 260.67500000000007,
    lanemiles: 558.5720000000001,
    delay: 254904.0,
    trkdelay: 24825.0,
    pti80: 1.13,
    trkpti80: 1.1,
    pti95: 1.18,
    trkpti95: 1.15,
    tti: 1.05,
    trktti: 1.03,
    ghg: 1070.0,
    congcost: 6085659.0,
    allspeed: 55.81,
    trkspeed: 60.5,
    ttivmt: 178821.0,
    trkttivmt: 31947.0,
  },
  {
    agglevel: "county",
    regioncode: 245,
    year: 2022,
    count: 592,
    len_sec: 260.63100000000014,
    lanemiles: 558.4840000000002,
    delay: 254383.0,
    trkdelay: 50521.0,
    pti80: 1.15,
    trkpti80: 1.18,
    pti95: 1.24,
    trkpti95: 1.28,
    tti: 1.05,
    trktti: 1.07,
    ghg: 1629.0,
    congcost: 7736774.0,
    allspeed: 56.04,
    trkspeed: 58.54,
    ttivmt: 209421.0,
    trkttivmt: 36248.0,
  },
  {
    agglevel: "county",
    regioncode: 246,
    year: 2017,
    count: 1558,
    len_sec: 1022.3469999999983,
    lanemiles: 2837.811999999992,
    delay: 1.7047417e7,
    trkdelay: 798758.0,
    pti80: 1.25,
    trkpti80: 1.21,
    pti95: 1.32,
    trkpti95: 1.28,
    tti: 1.11,
    trktti: 1.09,
    ghg: 67387.0,
    congcost: 3.40457901e8,
    allspeed: 44.51,
    trkspeed: 54.32,
    ttivmt: 6560558.0,
    trkttivmt: 522732.0,
  },
  {
    agglevel: "county",
    regioncode: 246,
    year: 2018,
    count: 1689,
    len_sec: 1012.5339999999989,
    lanemiles: 2812.9759999999974,
    delay: 1.3061421e7,
    trkdelay: 726337.0,
    pti80: 1.29,
    trkpti80: 1.27,
    pti95: 1.36,
    trkpti95: 1.34,
    tti: 1.14,
    trktti: 1.12,
    ghg: 52494.0,
    congcost: 2.70040916e8,
    allspeed: 48.53,
    trkspeed: 53.73,
    ttivmt: 4545930.0,
    trkttivmt: 344250.0,
  },
  {
    agglevel: "county",
    regioncode: 246,
    year: 2019,
    count: 813,
    len_sec: 669.2149999999988,
    lanemiles: 2078.3179999999984,
    delay: 1.3548569e7,
    trkdelay: 773115.0,
    pti80: 1.28,
    trkpti80: 1.25,
    pti95: 1.35,
    trkpti95: 1.32,
    tti: 1.14,
    trktti: 1.12,
    ghg: 56746.0,
    congcost: 2.91610107e8,
    allspeed: 49.25,
    trkspeed: 53.67,
    ttivmt: 4581316.0,
    trkttivmt: 356367.0,
  },
  {
    agglevel: "county",
    regioncode: 246,
    year: 2020,
    count: 2990,
    len_sec: 1013.0009999999987,
    lanemiles: 2836.0999999999867,
    delay: 1.5187276e7,
    trkdelay: 774807.0,
    pti80: 1.27,
    trkpti80: 1.25,
    pti95: 1.33,
    trkpti95: 1.31,
    tti: 1.14,
    trktti: 1.12,
    ghg: 60742.0,
    congcost: 3.32247014e8,
    allspeed: 48.71,
    trkspeed: 55.05,
    ttivmt: 5393488.0,
    trkttivmt: 381500.0,
  },
  {
    agglevel: "county",
    regioncode: 246,
    year: 2021,
    count: 3295,
    len_sec: 1016.8369999999957,
    lanemiles: 2884.8709999999896,
    delay: 7906892.0,
    trkdelay: 616551.0,
    pti80: 1.19,
    trkpti80: 1.21,
    pti95: 1.25,
    trkpti95: 1.32,
    tti: 1.09,
    trktti: 1.09,
    ghg: 33696.0,
    congcost: 1.84298378e8,
    allspeed: 50.87,
    trkspeed: 56.08,
    ttivmt: 4521714.0,
    trkttivmt: 355672.0,
  },
  {
    agglevel: "county",
    regioncode: 246,
    year: 2022,
    count: 3520,
    len_sec: 1018.344999999995,
    lanemiles: 2900.134999999988,
    delay: 1.3904703e7,
    trkdelay: 844444.0,
    pti80: 1.24,
    trkpti80: 1.25,
    pti95: 1.32,
    trkpti95: 1.34,
    tti: 1.12,
    trktti: 1.11,
    ghg: 56576.0,
    congcost: 3.50360665e8,
    allspeed: 49.23,
    trkspeed: 54.11,
    ttivmt: 5456071.0,
    trkttivmt: 386171.0,
  },
  {
    agglevel: "county",
    regioncode: 247,
    year: 2017,
    count: 478,
    len_sec: 337.3929999999999,
    lanemiles: 755.2330000000004,
    delay: 541016.0,
    trkdelay: 55833.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.11,
    trkpti95: 1.08,
    tti: 1.04,
    trktti: 1.03,
    ghg: 2016.0,
    congcost: 1.168509e7,
    allspeed: 56.21,
    trkspeed: 59.64,
    ttivmt: 459529.0,
    trkttivmt: 81420.0,
  },
  {
    agglevel: "county",
    regioncode: 247,
    year: 2018,
    count: 496,
    len_sec: 335.87200000000007,
    lanemiles: 750.8050000000006,
    delay: 601110.0,
    trkdelay: 81042.0,
    pti80: 1.1,
    trkpti80: 1.17,
    pti95: 1.12,
    trkpti95: 1.24,
    tti: 1.05,
    trktti: 1.07,
    ghg: 2449.0,
    congcost: 1.3887678e7,
    allspeed: 54.6,
    trkspeed: 54.99,
    ttivmt: 454260.0,
    trkttivmt: 51000.0,
  },
  {
    agglevel: "county",
    regioncode: 247,
    year: 2019,
    count: 175,
    len_sec: 235.17100000000002,
    lanemiles: 549.3829999999999,
    delay: 536059.0,
    trkdelay: 76617.0,
    pti80: 1.09,
    trkpti80: 1.16,
    pti95: 1.1,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.07,
    ghg: 2221.0,
    congcost: 1.3025978e7,
    allspeed: 56.58,
    trkspeed: 55.46,
    ttivmt: 447847.0,
    trkttivmt: 51976.0,
  },
  {
    agglevel: "county",
    regioncode: 247,
    year: 2020,
    count: 735,
    len_sec: 335.9899999999999,
    lanemiles: 747.1799999999997,
    delay: 614961.0,
    trkdelay: 72657.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.11,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.05,
    ghg: 2836.0,
    congcost: 1.498165e7,
    allspeed: 56.11,
    trkspeed: 57.59,
    ttivmt: 510797.0,
    trkttivmt: 63997.0,
  },
  {
    agglevel: "county",
    regioncode: 247,
    year: 2021,
    count: 717,
    len_sec: 335.32800000000003,
    lanemiles: 746.5919999999999,
    delay: 487810.0,
    trkdelay: 58060.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.04,
    ghg: 2008.0,
    congcost: 1.1941605e7,
    allspeed: 56.23,
    trkspeed: 58.2,
    ttivmt: 452740.0,
    trkttivmt: 60535.0,
  },
  {
    agglevel: "county",
    regioncode: 247,
    year: 2022,
    count: 739,
    len_sec: 335.3129999999997,
    lanemiles: 749.3369999999989,
    delay: 689454.0,
    trkdelay: 76889.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.16,
    trkpti95: 1.18,
    tti: 1.06,
    trktti: 1.05,
    ghg: 2610.0,
    congcost: 1.8487297e7,
    allspeed: 55.34,
    trkspeed: 57.89,
    ttivmt: 480862.0,
    trkttivmt: 65020.0,
  },
  {
    agglevel: "county",
    regioncode: 248,
    year: 2017,
    count: 171,
    len_sec: 139.9819999999999,
    lanemiles: 302.65599999999966,
    delay: 55091.0,
    trkdelay: 13479.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.01,
    ghg: 349.0,
    congcost: 1447446.0,
    allspeed: 60.04,
    trkspeed: 63.54,
    ttivmt: 126745.0,
    trkttivmt: 97843.0,
  },
  {
    agglevel: "county",
    regioncode: 248,
    year: 2018,
    count: 134,
    len_sec: 139.72499999999994,
    lanemiles: 301.7399999999999,
    delay: 146749.0,
    trkdelay: 56089.0,
    pti80: 1.12,
    trkpti80: 1.18,
    pti95: 1.16,
    trkpti95: 1.25,
    tti: 1.03,
    trktti: 1.07,
    ghg: 1297.0,
    congcost: 4628511.0,
    allspeed: 60.06,
    trkspeed: 58.94,
    ttivmt: 228728.0,
    trkttivmt: 46188.0,
  },
  {
    agglevel: "county",
    regioncode: 248,
    year: 2019,
    count: 85,
    len_sec: 139.72700000000003,
    lanemiles: 301.74399999999997,
    delay: 299684.0,
    trkdelay: 99457.0,
    pti80: 1.13,
    trkpti80: 1.18,
    pti95: 1.15,
    trkpti95: 1.25,
    tti: 1.05,
    trktti: 1.07,
    ghg: 2035.0,
    congcost: 9301869.0,
    allspeed: 59.31,
    trkspeed: 58.35,
    ttivmt: 323167.0,
    trkttivmt: 70883.0,
  },
  {
    agglevel: "county",
    regioncode: 248,
    year: 2020,
    count: 195,
    len_sec: 139.72799999999998,
    lanemiles: 301.74600000000004,
    delay: 425050.0,
    trkdelay: 134988.0,
    pti80: 1.16,
    trkpti80: 1.21,
    pti95: 1.19,
    trkpti95: 1.28,
    tti: 1.07,
    trktti: 1.09,
    ghg: 3044.0,
    congcost: 1.3557647e7,
    allspeed: 57.94,
    trkspeed: 57.28,
    ttivmt: 327532.0,
    trkttivmt: 82344.0,
  },
  {
    agglevel: "county",
    regioncode: 248,
    year: 2021,
    count: 196,
    len_sec: 139.79500000000007,
    lanemiles: 301.88800000000003,
    delay: 173744.0,
    trkdelay: 66804.0,
    pti80: 1.09,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.21,
    tti: 1.03,
    trktti: 1.05,
    ghg: 1392.0,
    congcost: 5720351.0,
    allspeed: 58.66,
    trkspeed: 59.02,
    ttivmt: 256794.0,
    trkttivmt: 59676.0,
  },
  {
    agglevel: "county",
    regioncode: 248,
    year: 2022,
    count: 199,
    len_sec: 139.812,
    lanemiles: 301.922,
    delay: 165393.0,
    trkdelay: 61547.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.12,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.04,
    ghg: 1253.0,
    congcost: 6026812.0,
    allspeed: 58.28,
    trkspeed: 57.92,
    ttivmt: 241958.0,
    trkttivmt: 73887.0,
  },
  {
    agglevel: "county",
    regioncode: 249,
    year: 2017,
    count: 576,
    len_sec: 449.21000000000004,
    lanemiles: 1050.193,
    delay: 879574.0,
    trkdelay: 107054.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.02,
    ghg: 3950.0,
    congcost: 1.9595798e7,
    allspeed: 56.77,
    trkspeed: 60.62,
    ttivmt: 954036.0,
    trkttivmt: 218125.0,
  },
  {
    agglevel: "county",
    regioncode: 249,
    year: 2018,
    count: 646,
    len_sec: 448.4059999999997,
    lanemiles: 1049.4689999999991,
    delay: 799798.0,
    trkdelay: 148392.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.04,
    ghg: 4599.0,
    congcost: 1.9957653e7,
    allspeed: 57.39,
    trkspeed: 59.08,
    ttivmt: 920576.0,
    trkttivmt: 175822.0,
  },
  {
    agglevel: "county",
    regioncode: 249,
    year: 2019,
    count: 373,
    len_sec: 448.3740000000001,
    lanemiles: 1049.4049999999997,
    delay: 691925.0,
    trkdelay: 127181.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 3884.0,
    congcost: 1.7953396e7,
    allspeed: 58.49,
    trkspeed: 58.91,
    ttivmt: 934260.0,
    trkttivmt: 169183.0,
  },
  {
    agglevel: "county",
    regioncode: 249,
    year: 2020,
    count: 918,
    len_sec: 448.63999999999965,
    lanemiles: 1053.0499999999997,
    delay: 766976.0,
    trkdelay: 95999.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.03,
    ghg: 3574.0,
    congcost: 1.8887973e7,
    allspeed: 58.01,
    trkspeed: 60.33,
    ttivmt: 963208.0,
    trkttivmt: 141429.0,
  },
  {
    agglevel: "county",
    regioncode: 249,
    year: 2021,
    count: 934,
    len_sec: 447.2519999999994,
    lanemiles: 1052.5779999999995,
    delay: 554472.0,
    trkdelay: 83508.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2859.0,
    congcost: 1.4195685e7,
    allspeed: 58.38,
    trkspeed: 60.81,
    ttivmt: 920819.0,
    trkttivmt: 147461.0,
  },
  {
    agglevel: "county",
    regioncode: 249,
    year: 2022,
    count: 992,
    len_sec: 447.4039999999993,
    lanemiles: 1048.9859999999994,
    delay: 753452.0,
    trkdelay: 96969.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.02,
    ghg: 3113.0,
    congcost: 2.0698626e7,
    allspeed: 58.83,
    trkspeed: 60.97,
    ttivmt: 991302.0,
    trkttivmt: 172600.0,
  },
  {
    agglevel: "county",
    regioncode: 250,
    year: 2017,
    count: 824,
    len_sec: 589.4489999999995,
    lanemiles: 1248.9069999999992,
    delay: 492627.0,
    trkdelay: 30716.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.11,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.04,
    ghg: 1670.0,
    congcost: 1.0017766e7,
    allspeed: 53.22,
    trkspeed: 55.81,
    ttivmt: 390546.0,
    trkttivmt: 32821.0,
  },
  {
    agglevel: "county",
    regioncode: 250,
    year: 2018,
    count: 849,
    len_sec: 586.6109999999996,
    lanemiles: 1246.6859999999997,
    delay: 499049.0,
    trkdelay: 37559.0,
    pti80: 1.09,
    trkpti80: 1.15,
    pti95: 1.12,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.06,
    ghg: 1933.0,
    congcost: 1.0606471e7,
    allspeed: 50.56,
    trkspeed: 50.37,
    ttivmt: 390282.0,
    trkttivmt: 24659.0,
  },
  {
    agglevel: "county",
    regioncode: 250,
    year: 2019,
    count: 485,
    len_sec: 585.3890000000001,
    lanemiles: 1244.2420000000009,
    delay: 521658.0,
    trkdelay: 48360.0,
    pti80: 1.09,
    trkpti80: 1.15,
    pti95: 1.12,
    trkpti95: 1.21,
    tti: 1.04,
    trktti: 1.07,
    ghg: 2324.0,
    congcost: 1.1867986e7,
    allspeed: 53.23,
    trkspeed: 53.21,
    ttivmt: 395220.0,
    trkttivmt: 28138.0,
  },
  {
    agglevel: "county",
    regioncode: 250,
    year: 2020,
    count: 1188,
    len_sec: 586.6979999999985,
    lanemiles: 1248.8329999999971,
    delay: 568281.0,
    trkdelay: 47601.0,
    pti80: 1.1,
    trkpti80: 1.15,
    pti95: 1.14,
    trkpti95: 1.22,
    tti: 1.05,
    trktti: 1.07,
    ghg: 2280.0,
    congcost: 1.3095024e7,
    allspeed: 52.92,
    trkspeed: 53.57,
    ttivmt: 406167.0,
    trkttivmt: 26767.0,
  },
  {
    agglevel: "county",
    regioncode: 250,
    year: 2021,
    count: 1206,
    len_sec: 586.6099999999986,
    lanemiles: 1247.8299999999967,
    delay: 411531.0,
    trkdelay: 37599.0,
    pti80: 1.1,
    trkpti80: 1.15,
    pti95: 1.15,
    trkpti95: 1.24,
    tti: 1.04,
    trktti: 1.06,
    ghg: 1678.0,
    congcost: 9749831.0,
    allspeed: 53.05,
    trkspeed: 53.87,
    ttivmt: 403861.0,
    trkttivmt: 25498.0,
  },
  {
    agglevel: "county",
    regioncode: 250,
    year: 2022,
    count: 1204,
    len_sec: 586.6119999999992,
    lanemiles: 1247.8339999999992,
    delay: 650026.0,
    trkdelay: 54585.0,
    pti80: 1.12,
    trkpti80: 1.16,
    pti95: 1.22,
    trkpti95: 1.27,
    tti: 1.06,
    trktti: 1.07,
    ghg: 2482.0,
    congcost: 1.6839228e7,
    allspeed: 52.18,
    trkspeed: 53.74,
    ttivmt: 418352.0,
    trkttivmt: 33040.0,
  },
  {
    agglevel: "county",
    regioncode: 251,
    year: 2017,
    count: 142,
    len_sec: 255.9580000000001,
    lanemiles: 526.4020000000003,
    delay: 76734.0,
    trkdelay: 6949.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.01,
    ghg: 281.0,
    congcost: 1628809.0,
    allspeed: 59.73,
    trkspeed: 63.01,
    ttivmt: 130251.0,
    trkttivmt: 28689.0,
  },
  {
    agglevel: "county",
    regioncode: 251,
    year: 2018,
    count: 125,
    len_sec: 255.873,
    lanemiles: 526.3540000000002,
    delay: 88809.0,
    trkdelay: 11273.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.03,
    trktti: 1.04,
    ghg: 347.0,
    congcost: 2027319.0,
    allspeed: 57.36,
    trkspeed: 59.76,
    ttivmt: 126333.0,
    trkttivmt: 14559.0,
  },
  {
    agglevel: "county",
    regioncode: 251,
    year: 2019,
    count: 100,
    len_sec: 255.87400000000002,
    lanemiles: 526.3559999999998,
    delay: 76067.0,
    trkdelay: 13695.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.03,
    ghg: 369.0,
    congcost: 1954703.0,
    allspeed: 59.56,
    trkspeed: 59.89,
    ttivmt: 142212.0,
    trkttivmt: 18022.0,
  },
  {
    agglevel: "county",
    regioncode: 251,
    year: 2020,
    count: 166,
    len_sec: 255.87800000000013,
    lanemiles: 526.3660000000003,
    delay: 93455.0,
    trkdelay: 15546.0,
    pti80: 1.09,
    trkpti80: 1.11,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 480.0,
    congcost: 2447967.0,
    allspeed: 58.71,
    trkspeed: 60.87,
    ttivmt: 133393.0,
    trkttivmt: 19454.0,
  },
  {
    agglevel: "county",
    regioncode: 251,
    year: 2021,
    count: 164,
    len_sec: 255.8580000000001,
    lanemiles: 526.774,
    delay: 68514.0,
    trkdelay: 14355.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.02,
    trktti: 1.04,
    ghg: 364.0,
    congcost: 1874115.0,
    allspeed: 58.54,
    trkspeed: 61.04,
    ttivmt: 107763.0,
    trkttivmt: 16782.0,
  },
  {
    agglevel: "county",
    regioncode: 251,
    year: 2022,
    count: 159,
    len_sec: 255.89400000000006,
    lanemiles: 526.852,
    delay: 113246.0,
    trkdelay: 14163.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.04,
    ghg: 497.0,
    congcost: 3108815.0,
    allspeed: 57.78,
    trkspeed: 60.26,
    ttivmt: 119087.0,
    trkttivmt: 15779.0,
  },
  {
    agglevel: "county",
    regioncode: 252,
    year: 2017,
    count: 397,
    len_sec: 394.01200000000006,
    lanemiles: 809.4979999999998,
    delay: 247521.0,
    trkdelay: 21876.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.05,
    trktti: 1.03,
    ghg: 1165.0,
    congcost: 5284715.0,
    allspeed: 52.99,
    trkspeed: 58.62,
    ttivmt: 172216.0,
    trkttivmt: 29159.0,
  },
  {
    agglevel: "county",
    regioncode: 252,
    year: 2018,
    count: 477,
    len_sec: 393.65,
    lanemiles: 806.0369999999998,
    delay: 178853.0,
    trkdelay: 25496.0,
    pti80: 1.1,
    trkpti80: 1.15,
    pti95: 1.13,
    trkpti95: 1.21,
    tti: 1.04,
    trktti: 1.06,
    ghg: 910.0,
    congcost: 4213074.0,
    allspeed: 53.05,
    trkspeed: 52.21,
    ttivmt: 155301.0,
    trkttivmt: 17833.0,
  },
  {
    agglevel: "county",
    regioncode: 252,
    year: 2019,
    count: 267,
    len_sec: 393.6200000000002,
    lanemiles: 806.503,
    delay: 181547.0,
    trkdelay: 28423.0,
    pti80: 1.12,
    trkpti80: 1.16,
    pti95: 1.14,
    trkpti95: 1.23,
    tti: 1.04,
    trktti: 1.07,
    ghg: 1045.0,
    congcost: 4563352.0,
    allspeed: 56.18,
    trkspeed: 55.4,
    ttivmt: 153161.0,
    trkttivmt: 16489.0,
  },
  {
    agglevel: "county",
    regioncode: 252,
    year: 2020,
    count: 642,
    len_sec: 393.6380000000001,
    lanemiles: 804.6430000000001,
    delay: 193329.0,
    trkdelay: 20552.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.15,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.05,
    ghg: 896.0,
    congcost: 4636285.0,
    allspeed: 55.39,
    trkspeed: 57.17,
    ttivmt: 154373.0,
    trkttivmt: 15654.0,
  },
  {
    agglevel: "county",
    regioncode: 252,
    year: 2021,
    count: 623,
    len_sec: 393.7689999999996,
    lanemiles: 804.9049999999993,
    delay: 105457.0,
    trkdelay: 18633.0,
    pti80: 1.1,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.18,
    tti: 1.03,
    trktti: 1.04,
    ghg: 505.0,
    congcost: 2778211.0,
    allspeed: 55.87,
    trkspeed: 57.77,
    ttivmt: 152768.0,
    trkttivmt: 18669.0,
  },
  {
    agglevel: "county",
    regioncode: 252,
    year: 2022,
    count: 624,
    len_sec: 393.8339999999998,
    lanemiles: 805.0349999999999,
    delay: 206511.0,
    trkdelay: 33781.0,
    pti80: 1.12,
    trkpti80: 1.17,
    pti95: 1.2,
    trkpti95: 1.29,
    tti: 1.05,
    trktti: 1.07,
    ghg: 955.0,
    congcost: 5949818.0,
    allspeed: 55.3,
    trkspeed: 56.0,
    ttivmt: 161150.0,
    trkttivmt: 20116.0,
  },
  {
    agglevel: "county",
    regioncode: 253,
    year: 2017,
    count: 171,
    len_sec: 135.18799999999993,
    lanemiles: 306.4819999999998,
    delay: 139019.0,
    trkdelay: 11925.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.09,
    tti: 1.03,
    trktti: 1.02,
    ghg: 607.0,
    congcost: 2948115.0,
    allspeed: 54.35,
    trkspeed: 59.17,
    ttivmt: 133772.0,
    trkttivmt: 19994.0,
  },
  {
    agglevel: "county",
    regioncode: 253,
    year: 2018,
    count: 174,
    len_sec: 135.867,
    lanemiles: 307.464,
    delay: 97903.0,
    trkdelay: 12971.0,
    pti80: 1.08,
    trkpti80: 1.11,
    pti95: 1.09,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.04,
    ghg: 493.0,
    congcost: 2272278.0,
    allspeed: 55.65,
    trkspeed: 57.28,
    ttivmt: 119614.0,
    trkttivmt: 13915.0,
  },
  {
    agglevel: "county",
    regioncode: 253,
    year: 2019,
    count: 36,
    len_sec: 79.711,
    lanemiles: 194.90599999999998,
    delay: 62325.0,
    trkdelay: 14551.0,
    pti80: 1.08,
    trkpti80: 1.1,
    pti95: 1.09,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 434.0,
    congcost: 1739640.0,
    allspeed: 57.1,
    trkspeed: 57.67,
    ttivmt: 113193.0,
    trkttivmt: 16289.0,
  },
  {
    agglevel: "county",
    regioncode: 253,
    year: 2020,
    count: 298,
    len_sec: 135.147,
    lanemiles: 320.75400000000013,
    delay: 83016.0,
    trkdelay: 15185.0,
    pti80: 1.07,
    trkpti80: 1.09,
    pti95: 1.09,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 548.0,
    congcost: 2248352.0,
    allspeed: 56.98,
    trkspeed: 58.93,
    ttivmt: 127964.0,
    trkttivmt: 18458.0,
  },
  {
    agglevel: "county",
    regioncode: 253,
    year: 2021,
    count: 296,
    len_sec: 135.14400000000003,
    lanemiles: 320.8980000000002,
    delay: 63661.0,
    trkdelay: 13440.0,
    pti80: 1.08,
    trkpti80: 1.09,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 419.0,
    congcost: 1760852.0,
    allspeed: 56.95,
    trkspeed: 59.03,
    ttivmt: 111218.0,
    trkttivmt: 16861.0,
  },
  {
    agglevel: "county",
    regioncode: 253,
    year: 2022,
    count: 299,
    len_sec: 135.99300000000002,
    lanemiles: 322.5960000000001,
    delay: 77447.0,
    trkdelay: 9698.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.02,
    trktti: 1.02,
    ghg: 346.0,
    congcost: 2128793.0,
    allspeed: 57.24,
    trkspeed: 58.56,
    ttivmt: 130406.0,
    trkttivmt: 15529.0,
  },
  {
    agglevel: "county",
    regioncode: 254,
    year: 2017,
    count: 299,
    len_sec: 277.4019999999999,
    lanemiles: 568.7540000000001,
    delay: 153269.0,
    trkdelay: 13214.0,
    pti80: 1.08,
    trkpti80: 1.06,
    pti95: 1.11,
    trkpti95: 1.09,
    tti: 1.02,
    trktti: 1.01,
    ghg: 587.0,
    congcost: 3238335.0,
    allspeed: 56.82,
    trkspeed: 62.89,
    ttivmt: 185391.0,
    trkttivmt: 61063.0,
  },
  {
    agglevel: "county",
    regioncode: 254,
    year: 2018,
    count: 340,
    len_sec: 276.9709999999999,
    lanemiles: 567.872,
    delay: 80685.0,
    trkdelay: 14757.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.02,
    trktti: 1.03,
    ghg: 421.0,
    congcost: 2000172.0,
    allspeed: 58.96,
    trkspeed: 59.97,
    ttivmt: 151107.0,
    trkttivmt: 25382.0,
  },
  {
    agglevel: "county",
    regioncode: 254,
    year: 2019,
    count: 72,
    len_sec: 164.44899999999998,
    lanemiles: 339.2900000000001,
    delay: 56810.0,
    trkdelay: 15905.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.11,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.02,
    ghg: 347.0,
    congcost: 1657481.0,
    allspeed: 61.11,
    trkspeed: 60.4,
    ttivmt: 147704.0,
    trkttivmt: 26554.0,
  },
  {
    agglevel: "county",
    regioncode: 254,
    year: 2020,
    count: 504,
    len_sec: 276.61899999999997,
    lanemiles: 567.9010000000002,
    delay: 94773.0,
    trkdelay: 19136.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 512.0,
    congcost: 2605714.0,
    allspeed: 60.05,
    trkspeed: 61.14,
    ttivmt: 177935.0,
    trkttivmt: 33066.0,
  },
  {
    agglevel: "county",
    regioncode: 254,
    year: 2021,
    count: 500,
    len_sec: 276.6189999999997,
    lanemiles: 567.9009999999993,
    delay: 77925.0,
    trkdelay: 17869.0,
    pti80: 1.09,
    trkpti80: 1.07,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.02,
    trktti: 1.02,
    ghg: 448.0,
    congcost: 2183117.0,
    allspeed: 59.74,
    trkspeed: 61.03,
    ttivmt: 149652.0,
    trkttivmt: 30652.0,
  },
  {
    agglevel: "county",
    regioncode: 254,
    year: 2022,
    count: 503,
    len_sec: 276.72299999999973,
    lanemiles: 568.1149999999996,
    delay: 99871.0,
    trkdelay: 21559.0,
    pti80: 1.08,
    trkpti80: 1.1,
    pti95: 1.12,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 534.0,
    congcost: 3071388.0,
    allspeed: 59.82,
    trkspeed: 59.97,
    ttivmt: 173718.0,
    trkttivmt: 26929.0,
  },
  {
    agglevel: "district",
    regioncode: 1,
    year: 2017,
    count: 6350,
    len_sec: 3566.521000000012,
    lanemiles: 7740.922000000016,
    delay: 6424997.0,
    trkdelay: 552126.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.02,
    ghg: 26948.0,
    congcost: 1.36006415e8,
    allspeed: 55.65,
    trkspeed: 61.28,
    ttivmt: 5329161.0,
    trkttivmt: 1076896.0,
  },
  {
    agglevel: "district",
    regioncode: 1,
    year: 2018,
    count: 6060,
    len_sec: 3564.987000000001,
    lanemiles: 7742.889000000005,
    delay: 5262388.0,
    trkdelay: 481386.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.03,
    ghg: 23530.0,
    congcost: 1.14996771e8,
    allspeed: 55.57,
    trkspeed: 59.53,
    ttivmt: 4699183.0,
    trkttivmt: 675764.0,
  },
  {
    agglevel: "district",
    regioncode: 1,
    year: 2019,
    count: 2856,
    len_sec: 3548.671000000004,
    lanemiles: 7722.581000000002,
    delay: 5360206.0,
    trkdelay: 653135.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.04,
    ghg: 27184.0,
    congcost: 1.27694673e8,
    allspeed: 57.02,
    trkspeed: 58.3,
    ttivmt: 4721157.0,
    trkttivmt: 696952.0,
  },
  {
    agglevel: "district",
    regioncode: 1,
    year: 2020,
    count: 9861,
    len_sec: 3579.2240000000243,
    lanemiles: 7877.753000000041,
    delay: 6575277.0,
    trkdelay: 533624.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.03,
    ghg: 29200.0,
    congcost: 1.51491406e8,
    allspeed: 56.97,
    trkspeed: 60.89,
    ttivmt: 5157862.0,
    trkttivmt: 726677.0,
  },
  {
    agglevel: "district",
    regioncode: 1,
    year: 2021,
    count: 9808,
    len_sec: 3575.0260000000394,
    lanemiles: 7873.229000000043,
    delay: 4227577.0,
    trkdelay: 392096.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.02,
    ghg: 18319.0,
    congcost: 1.00483562e8,
    allspeed: 57.27,
    trkspeed: 61.25,
    ttivmt: 4994608.0,
    trkttivmt: 722518.0,
  },
  {
    agglevel: "district",
    regioncode: 1,
    year: 2022,
    count: 9984,
    len_sec: 3574.2440000000074,
    lanemiles: 7876.185000000016,
    delay: 5900784.0,
    trkdelay: 493492.0,
    pti80: 1.12,
    trkpti80: 1.08,
    pti95: 1.18,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.02,
    ghg: 23123.0,
    congcost: 1.52994012e8,
    allspeed: 56.27,
    trkspeed: 60.82,
    ttivmt: 5261715.0,
    trkttivmt: 808507.0,
  },
  {
    agglevel: "district",
    regioncode: 2,
    year: 2017,
    count: 9792,
    len_sec: 5521.37800000001,
    lanemiles: 14711.889000000041,
    delay: 8.1000684e7,
    trkdelay: 3919300.0,
    pti80: 1.32,
    trkpti80: 1.3,
    pti95: 1.41,
    trkpti95: 1.39,
    tti: 1.15,
    trktti: 1.13,
    ghg: 320263.0,
    congcost: 1.621483265e9,
    allspeed: 46.07,
    trkspeed: 53.21,
    ttivmt: 2.8834496e7,
    trkttivmt: 2258027.0,
  },
  {
    agglevel: "district",
    regioncode: 2,
    year: 2018,
    count: 9976,
    len_sec: 5597.510000000022,
    lanemiles: 14950.121000000036,
    delay: 8.755581e7,
    trkdelay: 4751852.0,
    pti80: 1.39,
    trkpti80: 1.38,
    pti95: 1.48,
    trkpti95: 1.49,
    tti: 1.21,
    trktti: 1.18,
    ghg: 367346.0,
    congcost: 1.810115905e9,
    allspeed: 45.41,
    trkspeed: 50.28,
    ttivmt: 2.427888e7,
    trkttivmt: 1718642.0,
  },
  {
    agglevel: "district",
    regioncode: 2,
    year: 2019,
    count: 6389,
    len_sec: 5563.433000000015,
    lanemiles: 14862.682000000059,
    delay: 8.0723229e7,
    trkdelay: 4401664.0,
    pti80: 1.36,
    trkpti80: 1.34,
    pti95: 1.45,
    trkpti95: 1.45,
    tti: 1.19,
    trktti: 1.16,
    ghg: 327201.0,
    congcost: 1.728102472e9,
    allspeed: 45.98,
    trkspeed: 50.57,
    ttivmt: 2.4527645e7,
    trkttivmt: 1735906.0,
  },
  {
    agglevel: "district",
    regioncode: 2,
    year: 2020,
    count: 14783,
    len_sec: 5588.187000000054,
    lanemiles: 14973.700000000166,
    delay: 7.8858631e7,
    trkdelay: 3908228.0,
    pti80: 1.36,
    trkpti80: 1.34,
    pti95: 1.44,
    trkpti95: 1.44,
    tti: 1.18,
    trktti: 1.16,
    ghg: 308598.0,
    congcost: 1.719723216e9,
    allspeed: 46.55,
    trkspeed: 52.39,
    ttivmt: 2.5434304e7,
    trkttivmt: 1701434.0,
  },
  {
    agglevel: "district",
    regioncode: 2,
    year: 2021,
    count: 15307,
    len_sec: 5593.944000000114,
    lanemiles: 15010.528000000251,
    delay: 4.2060336e7,
    trkdelay: 2737686.0,
    pti80: 1.22,
    trkpti80: 1.22,
    pti95: 1.27,
    trkpti95: 1.32,
    tti: 1.1,
    trktti: 1.1,
    ghg: 172914.0,
    congcost: 9.63504371e8,
    allspeed: 48.66,
    trkspeed: 54.76,
    ttivmt: 2.2867004e7,
    trkttivmt: 1687110.0,
  },
  {
    agglevel: "district",
    regioncode: 2,
    year: 2022,
    count: 16139,
    len_sec: 5641.528000000146,
    lanemiles: 15224.403000000291,
    delay: 6.929727e7,
    trkdelay: 4046816.0,
    pti80: 1.29,
    trkpti80: 1.3,
    pti95: 1.37,
    trkpti95: 1.41,
    tti: 1.15,
    trktti: 1.14,
    ghg: 271100.0,
    congcost: 1.737746507e9,
    allspeed: 47.66,
    trkspeed: 53.73,
    ttivmt: 2.5765886e7,
    trkttivmt: 1912890.0,
  },
  {
    agglevel: "district",
    regioncode: 3,
    year: 2017,
    count: 4227,
    len_sec: 3203.7850000000085,
    lanemiles: 7240.046999999995,
    delay: 4437583.0,
    trkdelay: 295251.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.04,
    trktti: 1.02,
    ghg: 18484.0,
    congcost: 9.1404681e7,
    allspeed: 53.67,
    trkspeed: 61.41,
    ttivmt: 3524208.0,
    trkttivmt: 723813.0,
  },
  {
    agglevel: "district",
    regioncode: 3,
    year: 2018,
    count: 4522,
    len_sec: 3200.007999999999,
    lanemiles: 7250.377999999987,
    delay: 3130037.0,
    trkdelay: 314424.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.03,
    ghg: 14935.0,
    congcost: 6.9484696e7,
    allspeed: 56.03,
    trkspeed: 58.82,
    ttivmt: 3054075.0,
    trkttivmt: 461703.0,
  },
  {
    agglevel: "district",
    regioncode: 3,
    year: 2019,
    count: 2453,
    len_sec: 3197.5550000000067,
    lanemiles: 7245.574000000015,
    delay: 3472331.0,
    trkdelay: 414652.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 18332.0,
    congcost: 8.2667609e7,
    allspeed: 56.93,
    trkspeed: 58.56,
    ttivmt: 3193846.0,
    trkttivmt: 481860.0,
  },
  {
    agglevel: "district",
    regioncode: 3,
    year: 2020,
    count: 6338,
    len_sec: 3205.5970000000107,
    lanemiles: 7240.25700000002,
    delay: 3842372.0,
    trkdelay: 301404.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.02,
    ghg: 17535.0,
    congcost: 8.8289649e7,
    allspeed: 56.67,
    trkspeed: 60.95,
    ttivmt: 3303772.0,
    trkttivmt: 472710.0,
  },
  {
    agglevel: "district",
    regioncode: 3,
    year: 2021,
    count: 6269,
    len_sec: 3207.940000000026,
    lanemiles: 7253.728000000012,
    delay: 2568163.0,
    trkdelay: 259026.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.02,
    ghg: 12505.0,
    congcost: 6.1938238e7,
    allspeed: 56.68,
    trkspeed: 61.13,
    ttivmt: 3022465.0,
    trkttivmt: 522690.0,
  },
  {
    agglevel: "district",
    regioncode: 3,
    year: 2022,
    count: 6416,
    len_sec: 3209.799999999998,
    lanemiles: 7257.698999999989,
    delay: 3557692.0,
    trkdelay: 368461.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.18,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.03,
    ghg: 15817.0,
    congcost: 9.5151728e7,
    allspeed: 56.34,
    trkspeed: 60.59,
    ttivmt: 3409816.0,
    trkttivmt: 535445.0,
  },
  {
    agglevel: "district",
    regioncode: 4,
    year: 2017,
    count: 5332,
    len_sec: 4410.118000000012,
    lanemiles: 10308.611000000026,
    delay: 7919166.0,
    trkdelay: 481395.0,
    pti80: 1.12,
    trkpti80: 1.08,
    pti95: 1.16,
    trkpti95: 1.1,
    tti: 1.05,
    trktti: 1.01,
    ghg: 34647.0,
    congcost: 1.62099679e8,
    allspeed: 51.8,
    trkspeed: 62.32,
    ttivmt: 5009486.0,
    trkttivmt: 1640121.0,
  },
  {
    agglevel: "district",
    regioncode: 4,
    year: 2018,
    count: 4910,
    len_sec: 4414.066000000007,
    lanemiles: 10329.074000000044,
    delay: 6538044.0,
    trkdelay: 678724.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.03,
    ghg: 34601.0,
    congcost: 1.46442032e8,
    allspeed: 54.39,
    trkspeed: 59.02,
    ttivmt: 4411394.0,
    trkttivmt: 842451.0,
  },
  {
    agglevel: "district",
    regioncode: 4,
    year: 2019,
    count: 2968,
    len_sec: 4413.113000000015,
    lanemiles: 10332.307000000035,
    delay: 6302840.0,
    trkdelay: 717857.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.03,
    ghg: 34565.0,
    congcost: 1.49269922e8,
    allspeed: 55.16,
    trkspeed: 58.57,
    ttivmt: 4451823.0,
    trkttivmt: 858962.0,
  },
  {
    agglevel: "district",
    regioncode: 4,
    year: 2020,
    count: 7217,
    len_sec: 4424.635000000036,
    lanemiles: 10409.875000000064,
    delay: 6344501.0,
    trkdelay: 563130.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.03,
    ghg: 31622.0,
    congcost: 1.48655961e8,
    allspeed: 55.01,
    trkspeed: 60.28,
    ttivmt: 4475001.0,
    trkttivmt: 771867.0,
  },
  {
    agglevel: "district",
    regioncode: 4,
    year: 2021,
    count: 7415,
    len_sec: 4420.273000000061,
    lanemiles: 10431.059000000094,
    delay: 4627515.0,
    trkdelay: 647474.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.02,
    ghg: 25733.0,
    congcost: 1.17395522e8,
    allspeed: 54.79,
    trkspeed: 60.72,
    ttivmt: 4578981.0,
    trkttivmt: 1086690.0,
  },
  {
    agglevel: "district",
    regioncode: 4,
    year: 2022,
    count: 7556,
    len_sec: 4425.011000000045,
    lanemiles: 10441.298000000086,
    delay: 6306003.0,
    trkdelay: 779174.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.03,
    ghg: 31229.0,
    congcost: 1.73663272e8,
    allspeed: 54.72,
    trkspeed: 60.27,
    ttivmt: 5071493.0,
    trkttivmt: 1029041.0,
  },
  {
    agglevel: "district",
    regioncode: 5,
    year: 2017,
    count: 6648,
    len_sec: 6703.686000000027,
    lanemiles: 15278.160000000053,
    delay: 1.0110937e7,
    trkdelay: 409265.0,
    pti80: 1.13,
    trkpti80: 1.09,
    pti95: 1.17,
    trkpti95: 1.13,
    tti: 1.05,
    trktti: 1.02,
    ghg: 34905.0,
    congcost: 1.99112985e8,
    allspeed: 49.98,
    trkspeed: 60.19,
    ttivmt: 7075589.0,
    trkttivmt: 882247.0,
  },
  {
    agglevel: "district",
    regioncode: 5,
    year: 2018,
    count: 5651,
    len_sec: 6696.889000000012,
    lanemiles: 15298.285000000027,
    delay: 7312161.0,
    trkdelay: 590558.0,
    pti80: 1.12,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.04,
    ghg: 33779.0,
    congcost: 1.5771907e8,
    allspeed: 53.25,
    trkspeed: 57.17,
    ttivmt: 5127673.0,
    trkttivmt: 577149.0,
  },
  {
    agglevel: "district",
    regioncode: 5,
    year: 2019,
    count: 4241,
    len_sec: 6694.375000000016,
    lanemiles: 15293.801000000007,
    delay: 6847414.0,
    trkdelay: 730967.0,
    pti80: 1.12,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.05,
    ghg: 34053.0,
    congcost: 1.59770518e8,
    allspeed: 54.61,
    trkspeed: 57.26,
    ttivmt: 5214251.0,
    trkttivmt: 619333.0,
  },
  {
    agglevel: "district",
    regioncode: 5,
    year: 2020,
    count: 8358,
    len_sec: 6701.910000000075,
    lanemiles: 15335.436000000178,
    delay: 7267952.0,
    trkdelay: 623297.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.04,
    ghg: 33623.0,
    congcost: 1.68957673e8,
    allspeed: 54.32,
    trkspeed: 58.78,
    ttivmt: 5328510.0,
    trkttivmt: 598829.0,
  },
  {
    agglevel: "district",
    regioncode: 5,
    year: 2021,
    count: 8282,
    len_sec: 6702.614000000103,
    lanemiles: 15337.771000000186,
    delay: 5612259.0,
    trkdelay: 681457.0,
    pti80: 1.12,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.18,
    tti: 1.04,
    trktti: 1.04,
    ghg: 26868.0,
    congcost: 1.38545352e8,
    allspeed: 53.79,
    trkspeed: 58.7,
    ttivmt: 5155932.0,
    trkttivmt: 686200.0,
  },
  {
    agglevel: "district",
    regioncode: 5,
    year: 2022,
    count: 8418,
    len_sec: 6703.93400000009,
    lanemiles: 15347.613000000147,
    delay: 7195701.0,
    trkdelay: 694287.0,
    pti80: 1.12,
    trkpti80: 1.12,
    pti95: 1.18,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.04,
    ghg: 32352.0,
    congcost: 1.90836796e8,
    allspeed: 53.42,
    trkspeed: 58.03,
    ttivmt: 5686649.0,
    trkttivmt: 651095.0,
  },
  {
    agglevel: "district",
    regioncode: 6,
    year: 2017,
    count: 4325,
    len_sec: 3837.099000000016,
    lanemiles: 9261.260000000013,
    delay: 1.0609758e7,
    trkdelay: 677880.0,
    pti80: 1.12,
    trkpti80: 1.08,
    pti95: 1.16,
    trkpti95: 1.12,
    tti: 1.05,
    trktti: 1.01,
    ghg: 45903.0,
    congcost: 2.1801682e8,
    allspeed: 51.65,
    trkspeed: 61.63,
    ttivmt: 7429676.0,
    trkttivmt: 1947404.0,
  },
  {
    agglevel: "district",
    regioncode: 6,
    year: 2018,
    count: 4457,
    len_sec: 3860.4490000000046,
    lanemiles: 9306.953000000038,
    delay: 9536989.0,
    trkdelay: 1003428.0,
    pti80: 1.13,
    trkpti80: 1.13,
    pti95: 1.16,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.05,
    ghg: 47031.0,
    congcost: 2.13358821e8,
    allspeed: 55.18,
    trkspeed: 59.57,
    ttivmt: 6496866.0,
    trkttivmt: 1040418.0,
  },
  {
    agglevel: "district",
    regioncode: 6,
    year: 2019,
    count: 2327,
    len_sec: 3600.358000000006,
    lanemiles: 8787.671000000008,
    delay: 1.2359471e7,
    trkdelay: 1796520.0,
    pti80: 1.15,
    trkpti80: 1.14,
    pti95: 1.19,
    trkpti95: 1.18,
    tti: 1.07,
    trktti: 1.06,
    ghg: 68331.0,
    congcost: 3.05368054e8,
    allspeed: 54.65,
    trkspeed: 57.28,
    ttivmt: 7238657.0,
    trkttivmt: 1475075.0,
  },
  {
    agglevel: "district",
    regioncode: 6,
    year: 2020,
    count: 6294,
    len_sec: 3875.7970000000196,
    lanemiles: 9406.758000000065,
    delay: 1.2730163e7,
    trkdelay: 2093487.0,
    pti80: 1.15,
    trkpti80: 1.15,
    pti95: 1.18,
    trkpti95: 1.2,
    tti: 1.06,
    trktti: 1.06,
    ghg: 77776.0,
    congcost: 3.35069724e8,
    allspeed: 54.46,
    trkspeed: 58.62,
    ttivmt: 7478001.0,
    trkttivmt: 1621185.0,
  },
  {
    agglevel: "district",
    regioncode: 6,
    year: 2021,
    count: 6219,
    len_sec: 3874.3530000000324,
    lanemiles: 9405.015000000029,
    delay: 7774888.0,
    trkdelay: 1450968.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.16,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.04,
    ghg: 48770.0,
    congcost: 2.08799101e8,
    allspeed: 54.84,
    trkspeed: 59.77,
    ttivmt: 6408619.0,
    trkttivmt: 1393341.0,
  },
  {
    agglevel: "district",
    regioncode: 6,
    year: 2022,
    count: 6331,
    len_sec: 3877.4760000000233,
    lanemiles: 9413.39200000007,
    delay: 8397574.0,
    trkdelay: 1071968.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.03,
    ghg: 44560.0,
    congcost: 2.33197804e8,
    allspeed: 54.71,
    trkspeed: 60.1,
    ttivmt: 6726800.0,
    trkttivmt: 1593108.0,
  },
  {
    agglevel: "district",
    regioncode: 7,
    year: 2017,
    count: 2797,
    len_sec: 3559.8170000000064,
    lanemiles: 8004.957000000001,
    delay: 3287637.0,
    trkdelay: 311010.0,
    pti80: 1.1,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.03,
    trktti: 1.01,
    ghg: 15093.0,
    congcost: 7.0705009e7,
    allspeed: 56.38,
    trkspeed: 63.01,
    ttivmt: 2799192.0,
    trkttivmt: 1083283.0,
  },
  {
    agglevel: "district",
    regioncode: 7,
    year: 2018,
    count: 3720,
    len_sec: 3545.0510000000095,
    lanemiles: 8062.786000000011,
    delay: 2954432.0,
    trkdelay: 253481.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.02,
    ghg: 13574.0,
    congcost: 6.4193167e7,
    allspeed: 57.27,
    trkspeed: 61.14,
    ttivmt: 2780210.0,
    trkttivmt: 499933.0,
  },
  {
    agglevel: "district",
    regioncode: 7,
    year: 2019,
    count: 1224,
    len_sec: 2755.1769999999983,
    lanemiles: 6468.128000000001,
    delay: 2954756.0,
    trkdelay: 362773.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.02,
    ghg: 14804.0,
    congcost: 7.0506424e7,
    allspeed: 58.85,
    trkspeed: 60.73,
    ttivmt: 2823953.0,
    trkttivmt: 602733.0,
  },
  {
    agglevel: "district",
    regioncode: 7,
    year: 2020,
    count: 5064,
    len_sec: 3569.361000000014,
    lanemiles: 8111.22100000002,
    delay: 3176898.0,
    trkdelay: 336446.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.02,
    ghg: 15358.0,
    congcost: 7.6252236e7,
    allspeed: 58.36,
    trkspeed: 61.91,
    ttivmt: 3003113.0,
    trkttivmt: 608827.0,
  },
  {
    agglevel: "district",
    regioncode: 7,
    year: 2021,
    count: 5015,
    len_sec: 3566.1160000000164,
    lanemiles: 8107.972999999998,
    delay: 2112854.0,
    trkdelay: 274110.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.02,
    trktti: 1.02,
    ghg: 10626.0,
    congcost: 5.2782248e7,
    allspeed: 57.82,
    trkspeed: 61.47,
    ttivmt: 2516117.0,
    trkttivmt: 473295.0,
  },
  {
    agglevel: "district",
    regioncode: 7,
    year: 2022,
    count: 5142,
    len_sec: 3582.958000000021,
    lanemiles: 8131.112,
    delay: 2924392.0,
    trkdelay: 414771.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.03,
    ghg: 13478.0,
    congcost: 8.2078264e7,
    allspeed: 57.3,
    trkspeed: 61.21,
    ttivmt: 2637907.0,
    trkttivmt: 518411.0,
  },
  {
    agglevel: "district",
    regioncode: 8,
    year: 2017,
    count: 4433,
    len_sec: 4162.693000000003,
    lanemiles: 9391.801000000014,
    delay: 4922627.0,
    trkdelay: 470750.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.16,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.02,
    ghg: 23396.0,
    congcost: 1.06158099e8,
    allspeed: 54.64,
    trkspeed: 62.11,
    ttivmt: 4072240.0,
    trkttivmt: 1183157.0,
  },
  {
    agglevel: "district",
    regioncode: 8,
    year: 2018,
    count: 5015,
    len_sec: 4163.155999999995,
    lanemiles: 9406.475000000006,
    delay: 3580447.0,
    trkdelay: 367923.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.02,
    ghg: 17653.0,
    congcost: 7.984721e7,
    allspeed: 56.59,
    trkspeed: 60.74,
    ttivmt: 3744758.0,
    trkttivmt: 691546.0,
  },
  {
    agglevel: "district",
    regioncode: 8,
    year: 2019,
    count: 2687,
    len_sec: 4107.446000000005,
    lanemiles: 9294.88200000001,
    delay: 4095539.0,
    trkdelay: 554011.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 22317.0,
    congcost: 9.9805284e7,
    allspeed: 57.65,
    trkspeed: 59.51,
    ttivmt: 3946728.0,
    trkttivmt: 806812.0,
  },
  {
    agglevel: "district",
    regioncode: 8,
    year: 2020,
    count: 7129,
    len_sec: 4168.683000000023,
    lanemiles: 9417.946000000024,
    delay: 4211972.0,
    trkdelay: 409933.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.02,
    ghg: 20587.0,
    congcost: 9.9885253e7,
    allspeed: 57.64,
    trkspeed: 61.76,
    ttivmt: 4072227.0,
    trkttivmt: 769909.0,
  },
  {
    agglevel: "district",
    regioncode: 8,
    year: 2021,
    count: 7098,
    len_sec: 4185.964000000011,
    lanemiles: 9454.160000000002,
    delay: 2869577.0,
    trkdelay: 361668.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.02,
    ghg: 14346.0,
    congcost: 7.1365978e7,
    allspeed: 57.33,
    trkspeed: 61.7,
    ttivmt: 3582985.0,
    trkttivmt: 737903.0,
  },
  {
    agglevel: "district",
    regioncode: 8,
    year: 2022,
    count: 7200,
    len_sec: 4186.658000000009,
    lanemiles: 9454.469999999994,
    delay: 3819276.0,
    trkdelay: 496428.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.02,
    ghg: 18468.0,
    congcost: 1.05926831e8,
    allspeed: 56.75,
    trkspeed: 61.52,
    ttivmt: 4038124.0,
    trkttivmt: 856180.0,
  },
  {
    agglevel: "district",
    regioncode: 9,
    year: 2017,
    count: 6663,
    len_sec: 4223.379999999999,
    lanemiles: 9624.229000000003,
    delay: 1.7272862e7,
    trkdelay: 985681.0,
    pti80: 1.14,
    trkpti80: 1.1,
    pti95: 1.19,
    trkpti95: 1.15,
    tti: 1.05,
    trktti: 1.03,
    ghg: 69021.0,
    congcost: 3.50336736e8,
    allspeed: 51.0,
    trkspeed: 59.03,
    ttivmt: 1.1047211e7,
    trkttivmt: 1409313.0,
  },
  {
    agglevel: "district",
    regioncode: 9,
    year: 2018,
    count: 7263,
    len_sec: 4224.478999999991,
    lanemiles: 9659.313000000024,
    delay: 1.3569969e7,
    trkdelay: 1076193.0,
    pti80: 1.14,
    trkpti80: 1.1,
    pti95: 1.19,
    trkpti95: 1.15,
    tti: 1.05,
    trktti: 1.04,
    ghg: 59653.0,
    congcost: 2.91374214e8,
    allspeed: 53.84,
    trkspeed: 58.91,
    ttivmt: 8957242.0,
    trkttivmt: 1215458.0,
  },
  {
    agglevel: "district",
    regioncode: 9,
    year: 2019,
    count: 2922,
    len_sec: 3182.3800000000056,
    lanemiles: 7517.315000000007,
    delay: 1.168341e7,
    trkdelay: 1103832.0,
    pti80: 1.13,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.14,
    tti: 1.05,
    trktti: 1.04,
    ghg: 53823.0,
    congcost: 2.66809787e8,
    allspeed: 55.65,
    trkspeed: 58.05,
    ttivmt: 8483888.0,
    trkttivmt: 1202792.0,
  },
  {
    agglevel: "district",
    regioncode: 9,
    year: 2020,
    count: 10596,
    len_sec: 4233.200000000025,
    lanemiles: 9727.881000000058,
    delay: 1.483838e7,
    trkdelay: 1157520.0,
    pti80: 1.13,
    trkpti80: 1.11,
    pti95: 1.19,
    trkpti95: 1.18,
    tti: 1.05,
    trktti: 1.03,
    ghg: 69562.0,
    congcost: 3.40991623e8,
    allspeed: 54.09,
    trkspeed: 60.1,
    ttivmt: 9555140.0,
    trkttivmt: 1331722.0,
  },
  {
    agglevel: "district",
    regioncode: 9,
    year: 2021,
    count: 10601,
    len_sec: 4225.416000000041,
    lanemiles: 9724.14300000002,
    delay: 8677044.0,
    trkdelay: 857555.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.19,
    trkpti95: 1.21,
    tti: 1.04,
    trktti: 1.03,
    ghg: 41230.0,
    congcost: 2.08397328e8,
    allspeed: 54.28,
    trkspeed: 60.23,
    ttivmt: 8435566.0,
    trkttivmt: 1153686.0,
  },
  {
    agglevel: "district",
    regioncode: 9,
    year: 2022,
    count: 10941,
    len_sec: 4241.14600000006,
    lanemiles: 9946.303000000045,
    delay: 1.5045868e7,
    trkdelay: 1208084.0,
    pti80: 1.15,
    trkpti80: 1.13,
    pti95: 1.25,
    trkpti95: 1.24,
    tti: 1.06,
    trktti: 1.05,
    ghg: 67926.0,
    congcost: 3.90871509e8,
    allspeed: 53.56,
    trkspeed: 59.33,
    ttivmt: 9054241.0,
    trkttivmt: 1125757.0,
  },
  {
    agglevel: "district",
    regioncode: 10,
    year: 2017,
    count: 8701,
    len_sec: 4931.035000000022,
    lanemiles: 11422.721000000001,
    delay: 1.9746823e7,
    trkdelay: 1078641.0,
    pti80: 1.15,
    trkpti80: 1.13,
    pti95: 1.19,
    trkpti95: 1.17,
    tti: 1.07,
    trktti: 1.05,
    ghg: 83762.0,
    congcost: 3.99970791e8,
    allspeed: 47.47,
    trkspeed: 56.49,
    ttivmt: 9534233.0,
    trkttivmt: 955313.0,
  },
  {
    agglevel: "district",
    regioncode: 10,
    year: 2018,
    count: 8217,
    len_sec: 4931.046000000006,
    lanemiles: 11435.438000000013,
    delay: 1.498074e7,
    trkdelay: 1318537.0,
    pti80: 1.15,
    trkpti80: 1.16,
    pti95: 1.18,
    trkpti95: 1.2,
    tti: 1.07,
    trktti: 1.07,
    ghg: 72361.0,
    congcost: 3.26882264e8,
    allspeed: 50.71,
    trkspeed: 55.17,
    ttivmt: 7480273.0,
    trkttivmt: 862747.0,
  },
  {
    agglevel: "district",
    regioncode: 10,
    year: 2019,
    count: 4789,
    len_sec: 4876.202000000005,
    lanemiles: 11345.909000000003,
    delay: 1.5373456e7,
    trkdelay: 1401195.0,
    pti80: 1.14,
    trkpti80: 1.16,
    pti95: 1.18,
    trkpti95: 1.2,
    tti: 1.07,
    trktti: 1.07,
    ghg: 77016.0,
    congcost: 3.5091596e8,
    allspeed: 51.32,
    trkspeed: 54.37,
    ttivmt: 7832534.0,
    trkttivmt: 851426.0,
  },
  {
    agglevel: "district",
    regioncode: 10,
    year: 2020,
    count: 13541,
    len_sec: 4944.38800000002,
    lanemiles: 11504.639000000105,
    delay: 1.5728231e7,
    trkdelay: 1308845.0,
    pti80: 1.14,
    trkpti80: 1.15,
    pti95: 1.17,
    trkpti95: 1.2,
    tti: 1.06,
    trktti: 1.06,
    ghg: 77317.0,
    congcost: 3.65146339e8,
    allspeed: 51.22,
    trkspeed: 56.3,
    ttivmt: 8053994.0,
    trkttivmt: 868506.0,
  },
  {
    agglevel: "district",
    regioncode: 10,
    year: 2021,
    count: 13727,
    len_sec: 4942.667000000081,
    lanemiles: 11506.787000000133,
    delay: 9128087.0,
    trkdelay: 943562.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.16,
    trkpti95: 1.2,
    tti: 1.05,
    trktti: 1.05,
    ghg: 46244.0,
    congcost: 2.21059651e8,
    allspeed: 51.92,
    trkspeed: 57.08,
    ttivmt: 7240121.0,
    trkttivmt: 817505.0,
  },
  {
    agglevel: "district",
    regioncode: 10,
    year: 2022,
    count: 13863,
    len_sec: 4945.874000000058,
    lanemiles: 11518.072000000073,
    delay: 1.5136059e7,
    trkdelay: 1206159.0,
    pti80: 1.15,
    trkpti80: 1.12,
    pti95: 1.23,
    trkpti95: 1.18,
    tti: 1.06,
    trktti: 1.05,
    ghg: 68112.0,
    congcost: 3.92806266e8,
    allspeed: 50.88,
    trkspeed: 57.05,
    ttivmt: 8015739.0,
    trkttivmt: 1033182.0,
  },
  {
    agglevel: "district",
    regioncode: 11,
    year: 2017,
    count: 5178,
    len_sec: 3044.397999999998,
    lanemiles: 6838.002000000003,
    delay: 5310143.0,
    trkdelay: 491222.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.11,
    tti: 1.05,
    trktti: 1.03,
    ghg: 24049.0,
    congcost: 1.13775151e8,
    allspeed: 53.0,
    trkspeed: 58.38,
    ttivmt: 3445442.0,
    trkttivmt: 656546.0,
  },
  {
    agglevel: "district",
    regioncode: 11,
    year: 2018,
    count: 4838,
    len_sec: 3048.3320000000003,
    lanemiles: 6843.596000000006,
    delay: 4919701.0,
    trkdelay: 663701.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.06,
    ghg: 26530.0,
    congcost: 1.14891537e8,
    allspeed: 53.22,
    trkspeed: 55.23,
    ttivmt: 3387572.0,
    trkttivmt: 474550.0,
  },
  {
    agglevel: "district",
    regioncode: 11,
    year: 2019,
    count: 1856,
    len_sec: 2155.6309999999994,
    lanemiles: 5056.163999999995,
    delay: 4113030.0,
    trkdelay: 691963.0,
    pti80: 1.11,
    trkpti80: 1.13,
    pti95: 1.13,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.05,
    ghg: 24878.0,
    congcost: 1.05128818e8,
    allspeed: 55.86,
    trkspeed: 56.06,
    ttivmt: 3206325.0,
    trkttivmt: 525642.0,
  },
  {
    agglevel: "district",
    regioncode: 11,
    year: 2020,
    count: 7487,
    len_sec: 3055.692000000002,
    lanemiles: 6859.362000000003,
    delay: 5057475.0,
    trkdelay: 634991.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.05,
    ghg: 27066.0,
    congcost: 1.25410598e8,
    allspeed: 55.17,
    trkspeed: 57.35,
    ttivmt: 3685306.0,
    trkttivmt: 506318.0,
  },
  {
    agglevel: "district",
    regioncode: 11,
    year: 2021,
    count: 7543,
    len_sec: 3054.192000000038,
    lanemiles: 6861.700000000027,
    delay: 3775932.0,
    trkdelay: 540240.0,
    pti80: 1.11,
    trkpti80: 1.12,
    pti95: 1.14,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.05,
    ghg: 20810.0,
    congcost: 9.613502e7,
    allspeed: 55.65,
    trkspeed: 57.82,
    ttivmt: 3417453.0,
    trkttivmt: 488717.0,
  },
  {
    agglevel: "district",
    regioncode: 11,
    year: 2022,
    count: 7574,
    len_sec: 3054.2270000000112,
    lanemiles: 6862.890000000019,
    delay: 4528001.0,
    trkdelay: 590879.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.18,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.04,
    ghg: 20614.0,
    congcost: 1.25192851e8,
    allspeed: 55.42,
    trkspeed: 58.54,
    ttivmt: 3666973.0,
    trkttivmt: 682303.0,
  },
  {
    agglevel: "district",
    regioncode: 12,
    year: 2017,
    count: 14284,
    len_sec: 7716.721999999981,
    lanemiles: 23200.535000000076,
    delay: 2.84887548e8,
    trkdelay: 1.9355947e7,
    pti80: 1.46,
    trkpti80: 1.63,
    pti95: 1.58,
    trkpti95: 1.82,
    tti: 1.23,
    trktti: 1.31,
    ghg: 1218704.0,
    congcost: 5.883740417e9,
    allspeed: 42.4,
    trkspeed: 46.17,
    ttivmt: 7.1915518e7,
    trkttivmt: 5511052.0,
  },
  {
    agglevel: "district",
    regioncode: 12,
    year: 2018,
    count: 14449,
    len_sec: 8056.784000000053,
    lanemiles: 24142.173000000203,
    delay: 3.10887397e8,
    trkdelay: 1.4052453e7,
    pti80: 1.56,
    trkpti80: 1.63,
    pti95: 1.68,
    trkpti95: 1.81,
    tti: 1.31,
    trktti: 1.32,
    ghg: 1243896.0,
    congcost: 6.3296346e9,
    allspeed: 41.83,
    trkspeed: 45.17,
    ttivmt: 5.9717207e7,
    trkttivmt: 3179220.0,
  },
  {
    agglevel: "district",
    regioncode: 12,
    year: 2019,
    count: 8328,
    len_sec: 5873.042000000036,
    lanemiles: 19593.130000000143,
    delay: 2.68608027e8,
    trkdelay: 1.3765822e7,
    pti80: 1.48,
    trkpti80: 1.53,
    pti95: 1.59,
    trkpti95: 1.68,
    tti: 1.26,
    trktti: 1.27,
    ghg: 1096428.0,
    congcost: 5.724171355e9,
    allspeed: 43.21,
    trkspeed: 45.49,
    ttivmt: 5.8485878e7,
    trkttivmt: 3378886.0,
  },
  {
    agglevel: "district",
    regioncode: 12,
    year: 2020,
    count: 21711,
    len_sec: 8024.941000000251,
    lanemiles: 24494.131000000572,
    delay: 2.84236975e8,
    trkdelay: 1.3216218e7,
    pti80: 1.49,
    trkpti80: 1.51,
    pti95: 1.59,
    trkpti95: 1.64,
    tti: 1.26,
    trktti: 1.26,
    ghg: 1142677.0,
    congcost: 6.174812004e9,
    allspeed: 42.83,
    trkspeed: 46.96,
    ttivmt: 6.2640352e7,
    trkttivmt: 3388867.0,
  },
  {
    agglevel: "district",
    regioncode: 12,
    year: 2021,
    count: 22622,
    len_sec: 8068.778000000252,
    lanemiles: 24810.366000000835,
    delay: 1.36945238e8,
    trkdelay: 8285189.0,
    pti80: 1.26,
    trkpti80: 1.3,
    pti95: 1.32,
    trkpti95: 1.44,
    tti: 1.13,
    trktti: 1.15,
    ghg: 572197.0,
    congcost: 3.120439682e9,
    allspeed: 45.34,
    trkspeed: 49.61,
    ttivmt: 5.615516e7,
    trkttivmt: 3045998.0,
  },
  {
    agglevel: "district",
    regioncode: 12,
    year: 2022,
    count: 23943,
    len_sec: 8182.182000000294,
    lanemiles: 25418.74600000098,
    delay: 1.93149335e8,
    trkdelay: 1.0762311e7,
    pti80: 1.33,
    trkpti80: 1.36,
    pti95: 1.42,
    trkpti95: 1.51,
    tti: 1.17,
    trktti: 1.17,
    ghg: 772989.0,
    congcost: 4.831860211e9,
    allspeed: 45.06,
    trkspeed: 49.77,
    ttivmt: 5.8403488e7,
    trkttivmt: 3659445.0,
  },
  {
    agglevel: "district",
    regioncode: 13,
    year: 2017,
    count: 5229,
    len_sec: 3867.9329999999986,
    lanemiles: 8776.361000000003,
    delay: 6884320.0,
    trkdelay: 607050.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.02,
    ghg: 30809.0,
    congcost: 1.46596719e8,
    allspeed: 56.2,
    trkspeed: 61.74,
    ttivmt: 6616481.0,
    trkttivmt: 1487466.0,
  },
  {
    agglevel: "district",
    regioncode: 13,
    year: 2018,
    count: 6191,
    len_sec: 3876.511000000005,
    lanemiles: 8786.074999999995,
    delay: 6271126.0,
    trkdelay: 771458.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 32833.0,
    congcost: 1.44061089e8,
    allspeed: 56.78,
    trkspeed: 60.04,
    ttivmt: 5953654.0,
    trkttivmt: 1032936.0,
  },
  {
    agglevel: "district",
    regioncode: 13,
    year: 2019,
    count: 2070,
    len_sec: 2484.488999999997,
    lanemiles: 5954.98,
    delay: 4651084.0,
    trkdelay: 802898.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 27096.0,
    congcost: 1.19352047e8,
    allspeed: 58.72,
    trkspeed: 59.71,
    ttivmt: 5457527.0,
    trkttivmt: 1051813.0,
  },
  {
    agglevel: "district",
    regioncode: 13,
    year: 2020,
    count: 8775,
    len_sec: 3881.9600000000105,
    lanemiles: 8890.834000000004,
    delay: 6352996.0,
    trkdelay: 737743.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.02,
    ghg: 31700.0,
    congcost: 1.5492698e8,
    allspeed: 57.52,
    trkspeed: 61.05,
    ttivmt: 6249910.0,
    trkttivmt: 1083570.0,
  },
  {
    agglevel: "district",
    regioncode: 13,
    year: 2021,
    count: 8821,
    len_sec: 3882.125000000045,
    lanemiles: 8888.059000000057,
    delay: 4024495.0,
    trkdelay: 542648.0,
    pti80: 1.1,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.02,
    ghg: 20825.0,
    congcost: 1.01235313e8,
    allspeed: 57.75,
    trkspeed: 60.97,
    ttivmt: 5736897.0,
    trkttivmt: 934184.0,
  },
  {
    agglevel: "district",
    regioncode: 13,
    year: 2022,
    count: 8963,
    len_sec: 3892.001000000034,
    lanemiles: 8918.087000000038,
    delay: 6424576.0,
    trkdelay: 769270.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.17,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 28130.0,
    congcost: 1.7509203e8,
    allspeed: 57.55,
    trkspeed: 60.45,
    ttivmt: 6807453.0,
    trkttivmt: 1075205.0,
  },
  {
    agglevel: "district",
    regioncode: 14,
    year: 2017,
    count: 7451,
    len_sec: 5136.124000000034,
    lanemiles: 13724.366000000056,
    delay: 9.9294627e7,
    trkdelay: 5564735.0,
    pti80: 1.45,
    trkpti80: 1.51,
    pti95: 1.57,
    trkpti95: 1.65,
    tti: 1.21,
    trktti: 1.22,
    ghg: 400245.0,
    congcost: 2.01118083e9,
    allspeed: 44.25,
    trkspeed: 52.5,
    ttivmt: 2.7973637e7,
    trkttivmt: 2136001.0,
  },
  {
    agglevel: "district",
    regioncode: 14,
    year: 2018,
    count: 8102,
    len_sec: 5128.26100000002,
    lanemiles: 13718.190000000091,
    delay: 9.1607192e7,
    trkdelay: 4304428.0,
    pti80: 1.54,
    trkpti80: 1.49,
    pti95: 1.68,
    trkpti95: 1.63,
    tti: 1.27,
    trktti: 1.23,
    ghg: 356337.0,
    congcost: 1.867720469e9,
    allspeed: 46.07,
    trkspeed: 51.6,
    ttivmt: 2.1257975e7,
    trkttivmt: 1411522.0,
  },
  {
    agglevel: "district",
    regioncode: 14,
    year: 2019,
    count: 4200,
    len_sec: 3650.9250000000106,
    lanemiles: 10645.875000000002,
    delay: 8.5763293e7,
    trkdelay: 4163966.0,
    pti80: 1.47,
    trkpti80: 1.43,
    pti95: 1.59,
    trkpti95: 1.55,
    tti: 1.24,
    trktti: 1.2,
    ghg: 338040.0,
    congcost: 1.81697108e9,
    allspeed: 47.23,
    trkspeed: 51.55,
    ttivmt: 2.1427149e7,
    trkttivmt: 1427466.0,
  },
  {
    agglevel: "district",
    regioncode: 14,
    year: 2020,
    count: 13875,
    len_sec: 5141.1510000000035,
    lanemiles: 13751.555000000155,
    delay: 9.5875373e7,
    trkdelay: 4294001.0,
    pti80: 1.5,
    trkpti80: 1.42,
    pti95: 1.62,
    trkpti95: 1.56,
    tti: 1.25,
    trktti: 1.2,
    ghg: 369833.0,
    congcost: 2.073367666e9,
    allspeed: 46.83,
    trkspeed: 53.0,
    ttivmt: 2.3729254e7,
    trkttivmt: 1501716.0,
  },
  {
    agglevel: "district",
    regioncode: 14,
    year: 2021,
    count: 14574,
    len_sec: 5147.750000000128,
    lanemiles: 13806.056000000272,
    delay: 4.0683694e7,
    trkdelay: 3178962.0,
    pti80: 1.24,
    trkpti80: 1.27,
    pti95: 1.31,
    trkpti95: 1.39,
    tti: 1.11,
    trktti: 1.12,
    ghg: 172892.0,
    congcost: 9.48378002e8,
    allspeed: 50.07,
    trkspeed: 54.87,
    ttivmt: 1.9448884e7,
    trkttivmt: 1541823.0,
  },
  {
    agglevel: "district",
    regioncode: 14,
    year: 2022,
    count: 16102,
    len_sec: 5181.5330000000595,
    lanemiles: 13973.191000000164,
    delay: 6.2598139e7,
    trkdelay: 3807633.0,
    pti80: 1.28,
    trkpti80: 1.29,
    pti95: 1.36,
    trkpti95: 1.4,
    tti: 1.14,
    trktti: 1.13,
    ghg: 245765.0,
    congcost: 1.575044389e9,
    allspeed: 48.91,
    trkspeed: 53.91,
    ttivmt: 2.271788e7,
    trkttivmt: 1615888.0,
  },
  {
    agglevel: "district",
    regioncode: 15,
    year: 2017,
    count: 10994,
    len_sec: 5839.382000000013,
    lanemiles: 15067.043000000032,
    delay: 8.2461965e7,
    trkdelay: 4208316.0,
    pti80: 1.34,
    trkpti80: 1.27,
    pti95: 1.45,
    trkpti95: 1.36,
    tti: 1.17,
    trktti: 1.11,
    ghg: 328353.0,
    congcost: 1.657561501e9,
    allspeed: 46.67,
    trkspeed: 55.21,
    ttivmt: 2.9171274e7,
    trkttivmt: 2999316.0,
  },
  {
    agglevel: "district",
    regioncode: 15,
    year: 2018,
    count: 10535,
    len_sec: 5869.287000000014,
    lanemiles: 15189.776000000013,
    delay: 8.3336925e7,
    trkdelay: 4025997.0,
    pti80: 1.41,
    trkpti80: 1.33,
    pti95: 1.54,
    trkpti95: 1.45,
    tti: 1.21,
    trktti: 1.15,
    ghg: 327899.0,
    congcost: 1.703425692e9,
    allspeed: 47.17,
    trkspeed: 53.38,
    ttivmt: 2.4760836e7,
    trkttivmt: 1968307.0,
  },
  {
    agglevel: "district",
    regioncode: 15,
    year: 2019,
    count: 4559,
    len_sec: 4493.800000000002,
    lanemiles: 12396.213000000038,
    delay: 7.3373549e7,
    trkdelay: 4179957.0,
    pti80: 1.36,
    trkpti80: 1.29,
    pti95: 1.47,
    trkpti95: 1.4,
    tti: 1.18,
    trktti: 1.13,
    ghg: 295066.0,
    congcost: 1.576032203e9,
    allspeed: 48.38,
    trkspeed: 52.79,
    ttivmt: 2.4330243e7,
    trkttivmt: 2040558.0,
  },
  {
    agglevel: "district",
    regioncode: 15,
    year: 2020,
    count: 15896,
    len_sec: 5854.217000000052,
    lanemiles: 15161.290000000145,
    delay: 7.8913606e7,
    trkdelay: 4176496.0,
    pti80: 1.36,
    trkpti80: 1.29,
    pti95: 1.47,
    trkpti95: 1.4,
    tti: 1.17,
    trktti: 1.13,
    ghg: 313725.0,
    congcost: 1.731294061e9,
    allspeed: 48.29,
    trkspeed: 54.84,
    ttivmt: 2.6274911e7,
    trkttivmt: 2169304.0,
  },
  {
    agglevel: "district",
    regioncode: 15,
    year: 2021,
    count: 15797,
    len_sec: 5850.880000000067,
    lanemiles: 15163.15200000016,
    delay: 3.8643415e7,
    trkdelay: 2571267.0,
    pti80: 1.2,
    trkpti80: 1.18,
    pti95: 1.26,
    trkpti95: 1.27,
    tti: 1.09,
    trktti: 1.07,
    ghg: 156761.0,
    congcost: 8.86423971e8,
    allspeed: 50.03,
    trkspeed: 56.65,
    ttivmt: 2.3317638e7,
    trkttivmt: 2030582.0,
  },
  {
    agglevel: "district",
    regioncode: 15,
    year: 2022,
    count: 16558,
    len_sec: 5903.850000000111,
    lanemiles: 15314.889000000227,
    delay: 6.0325077e7,
    trkdelay: 3705861.0,
    pti80: 1.24,
    trkpti80: 1.21,
    pti95: 1.32,
    trkpti95: 1.31,
    tti: 1.11,
    trktti: 1.09,
    ghg: 233648.0,
    congcost: 1.518445127e9,
    allspeed: 49.65,
    trkspeed: 55.93,
    ttivmt: 2.6393724e7,
    trkttivmt: 2283296.0,
  },
  {
    agglevel: "district",
    regioncode: 16,
    year: 2017,
    count: 5767,
    len_sec: 3550.8090000000057,
    lanemiles: 8654.395,
    delay: 1.5742505e7,
    trkdelay: 1063409.0,
    pti80: 1.15,
    trkpti80: 1.11,
    pti95: 1.2,
    trkpti95: 1.16,
    tti: 1.06,
    trktti: 1.03,
    ghg: 71073.0,
    congcost: 3.25767949e8,
    allspeed: 50.61,
    trkspeed: 57.76,
    ttivmt: 8308337.0,
    trkttivmt: 1265706.0,
  },
  {
    agglevel: "district",
    regioncode: 16,
    year: 2018,
    count: 6176,
    len_sec: 3579.206000000001,
    lanemiles: 8745.204999999994,
    delay: 1.4837826e7,
    trkdelay: 942287.0,
    pti80: 1.16,
    trkpti80: 1.13,
    pti95: 1.21,
    trkpti95: 1.17,
    tti: 1.07,
    trktti: 1.05,
    ghg: 68497.0,
    congcost: 3.12376496e8,
    allspeed: 52.79,
    trkspeed: 56.96,
    ttivmt: 7193038.0,
    trkttivmt: 775139.0,
  },
  {
    agglevel: "district",
    regioncode: 16,
    year: 2019,
    count: 2277,
    len_sec: 2358.6079999999984,
    lanemiles: 6263.013999999995,
    delay: 1.3969174e7,
    trkdelay: 1211772.0,
    pti80: 1.16,
    trkpti80: 1.13,
    pti95: 1.19,
    trkpti95: 1.17,
    tti: 1.06,
    trktti: 1.05,
    ghg: 70919.0,
    congcost: 3.17328895e8,
    allspeed: 53.94,
    trkspeed: 56.06,
    ttivmt: 6986603.0,
    trkttivmt: 832003.0,
  },
  {
    agglevel: "district",
    regioncode: 16,
    year: 2020,
    count: 8483,
    len_sec: 3604.5680000000166,
    lanemiles: 8826.24700000003,
    delay: 1.4130106e7,
    trkdelay: 1165989.0,
    pti80: 1.14,
    trkpti80: 1.13,
    pti95: 1.18,
    trkpti95: 1.17,
    tti: 1.06,
    trktti: 1.05,
    ghg: 71382.0,
    congcost: 3.28247796e8,
    allspeed: 53.59,
    trkspeed: 57.28,
    ttivmt: 7528208.0,
    trkttivmt: 844768.0,
  },
  {
    agglevel: "district",
    regioncode: 16,
    year: 2021,
    count: 8439,
    len_sec: 3605.093000000033,
    lanemiles: 8877.571999999993,
    delay: 8355384.0,
    trkdelay: 1010371.0,
    pti80: 1.12,
    trkpti80: 1.13,
    pti95: 1.16,
    trkpti95: 1.2,
    tti: 1.04,
    trktti: 1.04,
    ghg: 46187.0,
    congcost: 2.07353393e8,
    allspeed: 53.94,
    trkspeed: 57.58,
    ttivmt: 6884209.0,
    trkttivmt: 835024.0,
  },
  {
    agglevel: "district",
    regioncode: 16,
    year: 2022,
    count: 8614,
    len_sec: 3604.58400000002,
    lanemiles: 8876.015000000043,
    delay: 1.3706669e7,
    trkdelay: 1115496.0,
    pti80: 1.15,
    trkpti80: 1.13,
    pti95: 1.2,
    trkpti95: 1.2,
    tti: 1.06,
    trktti: 1.04,
    ghg: 66586.0,
    congcost: 3.57970098e8,
    allspeed: 53.33,
    trkspeed: 57.51,
    ttivmt: 7391149.0,
    trkttivmt: 884745.0,
  },
  {
    agglevel: "district",
    regioncode: 17,
    year: 2017,
    count: 5289,
    len_sec: 3662.2460000000096,
    lanemiles: 8238.276000000005,
    delay: 1.1890496e7,
    trkdelay: 631974.0,
    pti80: 1.13,
    trkpti80: 1.09,
    pti95: 1.17,
    trkpti95: 1.12,
    tti: 1.05,
    trktti: 1.02,
    ghg: 50290.0,
    congcost: 2.40387939e8,
    allspeed: 53.52,
    trkspeed: 61.28,
    ttivmt: 7397547.0,
    trkttivmt: 1326925.0,
  },
  {
    agglevel: "district",
    regioncode: 17,
    year: 2018,
    count: 5871,
    len_sec: 3664.5600000000013,
    lanemiles: 8318.429999999993,
    delay: 9769943.0,
    trkdelay: 780482.0,
    pti80: 1.13,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.13,
    tti: 1.05,
    trktti: 1.04,
    ghg: 45993.0,
    congcost: 2.10603378e8,
    allspeed: 55.4,
    trkspeed: 59.53,
    ttivmt: 6538683.0,
    trkttivmt: 917193.0,
  },
  {
    agglevel: "district",
    regioncode: 17,
    year: 2019,
    count: 1868,
    len_sec: 2548.767999999995,
    lanemiles: 6068.81099999999,
    delay: 9700845.0,
    trkdelay: 866790.0,
    pti80: 1.13,
    trkpti80: 1.11,
    pti95: 1.17,
    trkpti95: 1.14,
    tti: 1.05,
    trktti: 1.04,
    ghg: 47316.0,
    congcost: 2.20581318e8,
    allspeed: 56.47,
    trkspeed: 59.05,
    ttivmt: 6453080.0,
    trkttivmt: 927362.0,
  },
  {
    agglevel: "district",
    regioncode: 17,
    year: 2020,
    count: 8337,
    len_sec: 3670.1750000000056,
    lanemiles: 8315.467000000006,
    delay: 9781883.0,
    trkdelay: 758182.0,
    pti80: 1.13,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.13,
    tti: 1.05,
    trktti: 1.03,
    ghg: 45217.0,
    congcost: 2.2451084e8,
    allspeed: 55.68,
    trkspeed: 60.76,
    ttivmt: 6985335.0,
    trkttivmt: 1078828.0,
  },
  {
    agglevel: "district",
    regioncode: 17,
    year: 2021,
    count: 8297,
    len_sec: 3661.791000000032,
    lanemiles: 8309.649000000043,
    delay: 5760463.0,
    trkdelay: 551382.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.03,
    trktti: 1.03,
    ghg: 27473.0,
    congcost: 1.37892904e8,
    allspeed: 56.58,
    trkspeed: 60.68,
    ttivmt: 6151364.0,
    trkttivmt: 877683.0,
  },
  {
    agglevel: "district",
    regioncode: 17,
    year: 2022,
    count: 8454,
    len_sec: 3663.042000000028,
    lanemiles: 8330.584000000028,
    delay: 8065165.0,
    trkdelay: 838903.0,
    pti80: 1.13,
    trkpti80: 1.1,
    pti95: 1.2,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.03,
    ghg: 35389.0,
    congcost: 2.15587512e8,
    allspeed: 56.31,
    trkspeed: 60.16,
    ttivmt: 6931265.0,
    trkttivmt: 984992.0,
  },
  {
    agglevel: "district",
    regioncode: 18,
    year: 2017,
    count: 13878,
    len_sec: 7684.807000000052,
    lanemiles: 23781.387000000093,
    delay: 1.88476794e8,
    trkdelay: 1.1472066e7,
    pti80: 1.42,
    trkpti80: 1.48,
    pti95: 1.53,
    trkpti95: 1.61,
    tti: 1.2,
    trktti: 1.22,
    ghg: 760497.0,
    congcost: 3.844445529e9,
    allspeed: 44.79,
    trkspeed: 51.2,
    ttivmt: 5.9442743e7,
    trkttivmt: 5040975.0,
  },
  {
    agglevel: "district",
    regioncode: 18,
    year: 2018,
    count: 14266,
    len_sec: 7822.101000000091,
    lanemiles: 24299.781000000083,
    delay: 1.87897385e8,
    trkdelay: 9467802.0,
    pti80: 1.48,
    trkpti80: 1.48,
    pti95: 1.6,
    trkpti95: 1.62,
    tti: 1.25,
    trktti: 1.24,
    ghg: 749151.0,
    congcost: 3.8542994e9,
    allspeed: 43.87,
    trkspeed: 49.52,
    ttivmt: 4.7923038e7,
    trkttivmt: 3175272.0,
  },
  {
    agglevel: "district",
    regioncode: 18,
    year: 2019,
    count: 10343,
    len_sec: 7686.0140000000665,
    lanemiles: 24138.492000000282,
    delay: 1.72956115e8,
    trkdelay: 8826443.0,
    pti80: 1.43,
    trkpti80: 1.42,
    pti95: 1.53,
    trkpti95: 1.55,
    tti: 1.22,
    trktti: 1.2,
    ghg: 676091.0,
    congcost: 3.676863568e9,
    allspeed: 44.75,
    trkspeed: 49.11,
    ttivmt: 4.8209221e7,
    trkttivmt: 3083375.0,
  },
  {
    agglevel: "district",
    regioncode: 18,
    year: 2020,
    count: 20816,
    len_sec: 7720.896000000219,
    lanemiles: 24355.499000000364,
    delay: 1.72044988e8,
    trkdelay: 7749977.0,
    pti80: 1.44,
    trkpti80: 1.4,
    pti95: 1.55,
    trkpti95: 1.52,
    tti: 1.22,
    trktti: 1.19,
    ghg: 655588.0,
    congcost: 3.720361315e9,
    allspeed: 45.0,
    trkspeed: 51.59,
    ttivmt: 4.8496738e7,
    trkttivmt: 3067216.0,
  },
  {
    agglevel: "district",
    regioncode: 18,
    year: 2021,
    count: 22167,
    len_sec: 7753.297000000266,
    lanemiles: 24522.238000000503,
    delay: 9.6036991e7,
    trkdelay: 5617214.0,
    pti80: 1.25,
    trkpti80: 1.26,
    pti95: 1.32,
    trkpti95: 1.38,
    tti: 1.12,
    trktti: 1.12,
    ghg: 382120.0,
    congcost: 2.17887158e9,
    allspeed: 47.26,
    trkspeed: 53.34,
    ttivmt: 4.5090445e7,
    trkttivmt: 2935143.0,
  },
  {
    agglevel: "district",
    regioncode: 18,
    year: 2022,
    count: 23549,
    len_sec: 7820.2290000002085,
    lanemiles: 24927.664000000626,
    delay: 1.33464261e8,
    trkdelay: 7864203.0,
    pti80: 1.3,
    trkpti80: 1.31,
    pti95: 1.37,
    trkpti95: 1.42,
    tti: 1.15,
    trktti: 1.14,
    ghg: 532161.0,
    congcost: 3.352019254e9,
    allspeed: 46.35,
    trkspeed: 52.93,
    ttivmt: 4.8943371e7,
    trkttivmt: 3459073.0,
  },
  {
    agglevel: "district",
    regioncode: 19,
    year: 2017,
    count: 5059,
    len_sec: 3087.9859999999985,
    lanemiles: 7296.22900000002,
    delay: 6034273.0,
    trkdelay: 398833.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.02,
    ghg: 25388.0,
    congcost: 1.24227261e8,
    allspeed: 54.54,
    trkspeed: 61.63,
    ttivmt: 4814948.0,
    trkttivmt: 1026836.0,
  },
  {
    agglevel: "district",
    regioncode: 19,
    year: 2018,
    count: 5216,
    len_sec: 3089.0179999999978,
    lanemiles: 7290.287999999992,
    delay: 5032996.0,
    trkdelay: 554515.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.14,
    tti: 1.04,
    trktti: 1.03,
    ghg: 24794.0,
    congcost: 1.13301614e8,
    allspeed: 55.67,
    trkspeed: 59.91,
    ttivmt: 4414451.0,
    trkttivmt: 801501.0,
  },
  {
    agglevel: "district",
    regioncode: 19,
    year: 2019,
    count: 2745,
    len_sec: 3046.367000000006,
    lanemiles: 7214.992,
    delay: 5274552.0,
    trkdelay: 638907.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.03,
    ghg: 27628.0,
    congcost: 1.25776844e8,
    allspeed: 56.71,
    trkspeed: 59.49,
    ttivmt: 4633806.0,
    trkttivmt: 838085.0,
  },
  {
    agglevel: "district",
    regioncode: 19,
    year: 2020,
    count: 7828,
    len_sec: 3091.9910000000154,
    lanemiles: 7296.386000000047,
    delay: 5403686.0,
    trkdelay: 550600.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.03,
    ghg: 26663.0,
    congcost: 1.29002752e8,
    allspeed: 56.69,
    trkspeed: 61.33,
    ttivmt: 4654722.0,
    trkttivmt: 873665.0,
  },
  {
    agglevel: "district",
    regioncode: 19,
    year: 2021,
    count: 7830,
    len_sec: 3092.3300000000295,
    lanemiles: 7302.78599999999,
    delay: 3672834.0,
    trkdelay: 411011.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.02,
    ghg: 18208.0,
    congcost: 8.9781249e7,
    allspeed: 57.01,
    trkspeed: 61.68,
    ttivmt: 4424148.0,
    trkttivmt: 859638.0,
  },
  {
    agglevel: "district",
    regioncode: 19,
    year: 2022,
    count: 7860,
    len_sec: 3092.674000000023,
    lanemiles: 7295.417999999994,
    delay: 5195729.0,
    trkdelay: 518391.0,
    pti80: 1.11,
    trkpti80: 1.08,
    pti95: 1.17,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.02,
    ghg: 22593.0,
    congcost: 1.38085753e8,
    allspeed: 56.28,
    trkspeed: 61.6,
    ttivmt: 4622525.0,
    trkttivmt: 1030102.0,
  },
  {
    agglevel: "district",
    regioncode: 20,
    year: 2017,
    count: 5432,
    len_sec: 2925.945999999997,
    lanemiles: 7063.052,
    delay: 1.4812108e7,
    trkdelay: 708895.0,
    pti80: 1.15,
    trkpti80: 1.11,
    pti95: 1.2,
    trkpti95: 1.15,
    tti: 1.06,
    trktti: 1.03,
    ghg: 58565.0,
    congcost: 2.96326754e8,
    allspeed: 50.77,
    trkspeed: 59.48,
    ttivmt: 8477557.0,
    trkttivmt: 1138258.0,
  },
  {
    agglevel: "district",
    regioncode: 20,
    year: 2018,
    count: 5992,
    len_sec: 2929.0229999999992,
    lanemiles: 7085.368000000018,
    delay: 1.2605189e7,
    trkdelay: 926555.0,
    pti80: 1.15,
    trkpti80: 1.13,
    pti95: 1.19,
    trkpti95: 1.18,
    tti: 1.07,
    trktti: 1.05,
    ghg: 54094.0,
    congcost: 2.6820416e8,
    allspeed: 53.04,
    trkspeed: 57.22,
    ttivmt: 7037716.0,
    trkttivmt: 783536.0,
  },
  {
    agglevel: "district",
    regioncode: 20,
    year: 2019,
    count: 2001,
    len_sec: 1930.5339999999997,
    lanemiles: 5069.386999999994,
    delay: 1.0932831e7,
    trkdelay: 824917.0,
    pti80: 1.15,
    trkpti80: 1.12,
    pti95: 1.18,
    trkpti95: 1.17,
    tti: 1.06,
    trktti: 1.05,
    ghg: 46917.0,
    congcost: 2.42111247e8,
    allspeed: 54.7,
    trkspeed: 57.46,
    ttivmt: 6661557.0,
    trkttivmt: 736267.0,
  },
  {
    agglevel: "district",
    regioncode: 20,
    year: 2020,
    count: 8889,
    len_sec: 2928.541000000005,
    lanemiles: 7134.378000000042,
    delay: 1.2249947e7,
    trkdelay: 797117.0,
    pti80: 1.14,
    trkpti80: 1.12,
    pti95: 1.18,
    trkpti95: 1.16,
    tti: 1.06,
    trktti: 1.04,
    ghg: 52157.0,
    congcost: 2.74814284e8,
    allspeed: 53.83,
    trkspeed: 59.26,
    ttivmt: 7404988.0,
    trkttivmt: 768880.0,
  },
  {
    agglevel: "district",
    regioncode: 20,
    year: 2021,
    count: 8975,
    len_sec: 2924.1340000000423,
    lanemiles: 7145.604000000077,
    delay: 7704444.0,
    trkdelay: 644550.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.04,
    ghg: 32918.0,
    congcost: 1.8092437e8,
    allspeed: 54.35,
    trkspeed: 59.59,
    ttivmt: 6675659.0,
    trkttivmt: 731477.0,
  },
  {
    agglevel: "district",
    regioncode: 20,
    year: 2022,
    count: 9204,
    len_sec: 2938.363000000013,
    lanemiles: 7165.788000000023,
    delay: 1.06556e7,
    trkdelay: 972988.0,
    pti80: 1.13,
    trkpti80: 1.12,
    pti95: 1.2,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.04,
    ghg: 44685.0,
    congcost: 2.7974596e8,
    allspeed: 54.22,
    trkspeed: 58.87,
    ttivmt: 7537730.0,
    trkttivmt: 944209.0,
  },
  {
    agglevel: "district",
    regioncode: 21,
    year: 2017,
    count: 6639,
    len_sec: 3500.2140000000054,
    lanemiles: 8833.105999999992,
    delay: 3.8493021e7,
    trkdelay: 999768.0,
    pti80: 1.19,
    trkpti80: 1.15,
    pti95: 1.23,
    trkpti95: 1.2,
    tti: 1.09,
    trktti: 1.06,
    ghg: 154831.0,
    congcost: 7.45869645e8,
    allspeed: 42.32,
    trkspeed: 53.89,
    ttivmt: 1.3333812e7,
    trkttivmt: 814281.0,
  },
  {
    agglevel: "district",
    regioncode: 21,
    year: 2018,
    count: 5443,
    len_sec: 3540.7900000000022,
    lanemiles: 8929.381000000016,
    delay: 3.2445652e7,
    trkdelay: 1348554.0,
    pti80: 1.21,
    trkpti80: 1.2,
    pti95: 1.26,
    trkpti95: 1.27,
    tti: 1.11,
    trktti: 1.09,
    ghg: 142188.0,
    congcost: 6.59762171e8,
    allspeed: 43.89,
    trkspeed: 50.99,
    ttivmt: 9879591.0,
    trkttivmt: 619862.0,
  },
  {
    agglevel: "district",
    regioncode: 21,
    year: 2019,
    count: 2608,
    len_sec: 2070.2199999999957,
    lanemiles: 5938.039999999982,
    delay: 2.8861059e7,
    trkdelay: 1288477.0,
    pti80: 1.2,
    trkpti80: 1.18,
    pti95: 1.24,
    trkpti95: 1.23,
    tti: 1.1,
    trktti: 1.08,
    ghg: 131231.0,
    congcost: 6.12486934e8,
    allspeed: 45.04,
    trkspeed: 52.09,
    ttivmt: 8935144.0,
    trkttivmt: 604974.0,
  },
  {
    agglevel: "district",
    regioncode: 21,
    year: 2020,
    count: 8577,
    len_sec: 3543.921000000014,
    lanemiles: 9054.865000000076,
    delay: 3.3644516e7,
    trkdelay: 1433685.0,
    pti80: 1.2,
    trkpti80: 1.18,
    pti95: 1.25,
    trkpti95: 1.24,
    tti: 1.1,
    trktti: 1.08,
    ghg: 153573.0,
    congcost: 7.30775297e8,
    allspeed: 43.97,
    trkspeed: 52.55,
    ttivmt: 1.0744205e7,
    trkttivmt: 655315.0,
  },
  {
    agglevel: "district",
    regioncode: 21,
    year: 2021,
    count: 9041,
    len_sec: 3552.9810000000452,
    lanemiles: 9091.279000000048,
    delay: 2.08767e7,
    trkdelay: 1086008.0,
    pti80: 1.16,
    trkpti80: 1.16,
    pti95: 1.21,
    trkpti95: 1.24,
    tti: 1.07,
    trktti: 1.06,
    ghg: 97682.0,
    congcost: 4.72775445e8,
    allspeed: 44.62,
    trkspeed: 53.33,
    ttivmt: 9329475.0,
    trkttivmt: 620107.0,
  },
  {
    agglevel: "district",
    regioncode: 21,
    year: 2022,
    count: 9252,
    len_sec: 3553.853000000037,
    lanemiles: 9139.163000000108,
    delay: 2.8749633e7,
    trkdelay: 1632751.0,
    pti80: 1.18,
    trkpti80: 1.19,
    pti95: 1.25,
    trkpti95: 1.3,
    tti: 1.08,
    trktti: 1.08,
    ghg: 136047.0,
    congcost: 7.26319751e8,
    allspeed: 43.83,
    trkspeed: 52.08,
    ttivmt: 1.0532864e7,
    trkttivmt: 748663.0,
  },
  {
    agglevel: "district",
    regioncode: 22,
    year: 2017,
    count: 3465,
    len_sec: 3001.551999999991,
    lanemiles: 6658.0869999999795,
    delay: 1.1799211e7,
    trkdelay: 671603.0,
    pti80: 1.18,
    trkpti80: 1.1,
    pti95: 1.23,
    trkpti95: 1.13,
    tti: 1.08,
    trktti: 1.02,
    ghg: 49791.0,
    congcost: 2.39745415e8,
    allspeed: 46.78,
    trkspeed: 60.08,
    ttivmt: 5061640.0,
    trkttivmt: 1117460.0,
  },
  {
    agglevel: "district",
    regioncode: 22,
    year: 2018,
    count: 3900,
    len_sec: 3023.1720000000037,
    lanemiles: 6740.721000000001,
    delay: 1.0085442e7,
    trkdelay: 853765.0,
    pti80: 1.19,
    trkpti80: 1.15,
    pti95: 1.23,
    trkpti95: 1.19,
    tti: 1.09,
    trktti: 1.06,
    ghg: 48899.0,
    congcost: 2.19138514e8,
    allspeed: 50.25,
    trkspeed: 57.26,
    ttivmt: 3664089.0,
    trkttivmt: 580952.0,
  },
  {
    agglevel: "district",
    regioncode: 22,
    year: 2019,
    count: 1104,
    len_sec: 2007.9220000000005,
    lanemiles: 4678.062000000007,
    delay: 1.03994e7,
    trkdelay: 989593.0,
    pti80: 1.19,
    trkpti80: 1.13,
    pti95: 1.23,
    trkpti95: 1.17,
    tti: 1.09,
    trktti: 1.05,
    ghg: 52669.0,
    congcost: 2.38838045e8,
    allspeed: 50.56,
    trkspeed: 56.0,
    ttivmt: 3585820.0,
    trkttivmt: 660516.0,
  },
  {
    agglevel: "district",
    regioncode: 22,
    year: 2020,
    count: 5592,
    len_sec: 3020.5340000000065,
    lanemiles: 6741.26,
    delay: 1.1245825e7,
    trkdelay: 951460.0,
    pti80: 1.17,
    trkpti80: 1.13,
    pti95: 1.22,
    trkpti95: 1.17,
    tti: 1.08,
    trktti: 1.04,
    ghg: 55945.0,
    congcost: 2.61712304e8,
    allspeed: 49.6,
    trkspeed: 58.69,
    ttivmt: 4142283.0,
    trkttivmt: 737319.0,
  },
  {
    agglevel: "district",
    regioncode: 22,
    year: 2021,
    count: 5546,
    len_sec: 3020.784999999993,
    lanemiles: 6745.236999999982,
    delay: 8358688.0,
    trkdelay: 759207.0,
    pti80: 1.16,
    trkpti80: 1.12,
    pti95: 1.2,
    trkpti95: 1.18,
    tti: 1.06,
    trktti: 1.04,
    ghg: 41863.0,
    congcost: 1.99196784e8,
    allspeed: 48.91,
    trkspeed: 58.9,
    ttivmt: 3585652.0,
    trkttivmt: 672712.0,
  },
  {
    agglevel: "district",
    regioncode: 22,
    year: 2022,
    count: 5672,
    len_sec: 3024.526000000004,
    lanemiles: 6766.407000000003,
    delay: 9709296.0,
    trkdelay: 991699.0,
    pti80: 1.17,
    trkpti80: 1.13,
    pti95: 1.24,
    trkpti95: 1.2,
    tti: 1.07,
    trktti: 1.04,
    ghg: 47512.0,
    congcost: 2.60206808e8,
    allspeed: 48.61,
    trkspeed: 58.08,
    ttivmt: 3847201.0,
    trkttivmt: 764356.0,
  },
  {
    agglevel: "district",
    regioncode: 23,
    year: 2017,
    count: 3507,
    len_sec: 3121.7190000000105,
    lanemiles: 6733.137999999993,
    delay: 2182267.0,
    trkdelay: 169539.0,
    pti80: 1.1,
    trkpti80: 1.07,
    pti95: 1.13,
    trkpti95: 1.1,
    tti: 1.03,
    trktti: 1.01,
    ghg: 9344.0,
    congcost: 4.5736142e7,
    allspeed: 55.54,
    trkspeed: 61.71,
    ttivmt: 2064644.0,
    trkttivmt: 467168.0,
  },
  {
    agglevel: "district",
    regioncode: 23,
    year: 2018,
    count: 4168,
    len_sec: 3114.5599999999886,
    lanemiles: 6725.380999999983,
    delay: 1618672.0,
    trkdelay: 213282.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.03,
    trktti: 1.03,
    ghg: 8055.0,
    congcost: 3.7550701e7,
    allspeed: 56.13,
    trkspeed: 59.22,
    ttivmt: 1944752.0,
    trkttivmt: 326437.0,
  },
  {
    agglevel: "district",
    regioncode: 23,
    year: 2019,
    count: 1601,
    len_sec: 2615.9820000000054,
    lanemiles: 5725.625000000006,
    delay: 1591892.0,
    trkdelay: 268440.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 8882.0,
    congcost: 4.05615e7,
    allspeed: 58.42,
    trkspeed: 59.24,
    ttivmt: 1888141.0,
    trkttivmt: 337591.0,
  },
  {
    agglevel: "district",
    regioncode: 23,
    year: 2020,
    count: 5953,
    len_sec: 3127.220999999986,
    lanemiles: 6792.988999999985,
    delay: 1822799.0,
    trkdelay: 243667.0,
    pti80: 1.1,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.13,
    tti: 1.03,
    trktti: 1.03,
    ghg: 9312.0,
    congcost: 4.5653165e7,
    allspeed: 57.92,
    trkspeed: 60.33,
    ttivmt: 2058166.0,
    trkttivmt: 329671.0,
  },
  {
    agglevel: "district",
    regioncode: 23,
    year: 2021,
    count: 5912,
    len_sec: 3126.936000000026,
    lanemiles: 6793.802000000041,
    delay: 1135356.0,
    trkdelay: 189475.0,
    pti80: 1.09,
    trkpti80: 1.1,
    pti95: 1.13,
    trkpti95: 1.14,
    tti: 1.02,
    trktti: 1.03,
    ghg: 5958.0,
    congcost: 2.9672335e7,
    allspeed: 57.84,
    trkspeed: 60.23,
    ttivmt: 1809105.0,
    trkttivmt: 308694.0,
  },
  {
    agglevel: "district",
    regioncode: 23,
    year: 2022,
    count: 5922,
    len_sec: 3127.48199999999,
    lanemiles: 6794.57699999998,
    delay: 1802586.0,
    trkdelay: 256211.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.18,
    trkpti95: 1.16,
    tti: 1.03,
    trktti: 1.04,
    ghg: 7788.0,
    congcost: 5.0494167e7,
    allspeed: 57.41,
    trkspeed: 59.75,
    ttivmt: 1927619.0,
    trkttivmt: 284493.0,
  },
  {
    agglevel: "district",
    regioncode: 24,
    year: 2017,
    count: 3386,
    len_sec: 2546.5600000000013,
    lanemiles: 6607.710999999988,
    delay: 2.7252401e7,
    trkdelay: 1155805.0,
    pti80: 1.24,
    trkpti80: 1.14,
    pti95: 1.31,
    trkpti95: 1.2,
    tti: 1.11,
    trktti: 1.04,
    ghg: 113668.0,
    congcost: 5.41926065e8,
    allspeed: 44.56,
    trkspeed: 57.7,
    ttivmt: 1.0091067e7,
    trkttivmt: 1448164.0,
  },
  {
    agglevel: "district",
    regioncode: 24,
    year: 2018,
    count: 3562,
    len_sec: 2519.685000000003,
    lanemiles: 6577.632000000009,
    delay: 2.3019903e7,
    trkdelay: 1170677.0,
    pti80: 1.24,
    trkpti80: 1.2,
    pti95: 1.31,
    trkpti95: 1.27,
    tti: 1.12,
    trktti: 1.07,
    ghg: 98273.0,
    congcost: 4.73942741e8,
    allspeed: 46.67,
    trkspeed: 55.79,
    ttivmt: 7612804.0,
    trkttivmt: 752589.0,
  },
  {
    agglevel: "district",
    regioncode: 24,
    year: 2019,
    count: 1969,
    len_sec: 2232.3719999999994,
    lanemiles: 5989.204999999992,
    delay: 2.500799e7,
    trkdelay: 1642122.0,
    pti80: 1.23,
    trkpti80: 1.2,
    pti95: 1.28,
    trkpti95: 1.26,
    tti: 1.11,
    trktti: 1.08,
    ghg: 114815.0,
    congcost: 5.47769953e8,
    allspeed: 46.92,
    trkspeed: 53.62,
    ttivmt: 8209676.0,
    trkttivmt: 856003.0,
  },
  {
    agglevel: "district",
    regioncode: 24,
    year: 2020,
    count: 5437,
    len_sec: 2600.286000000009,
    lanemiles: 6744.917000000015,
    delay: 2.561036e7,
    trkdelay: 1307628.0,
    pti80: 1.21,
    trkpti80: 1.16,
    pti95: 1.27,
    trkpti95: 1.23,
    tti: 1.1,
    trktti: 1.06,
    ghg: 111977.0,
    congcost: 5.62614849e8,
    allspeed: 46.94,
    trkspeed: 56.58,
    ttivmt: 8627869.0,
    trkttivmt: 858817.0,
  },
  {
    agglevel: "district",
    regioncode: 24,
    year: 2021,
    count: 5400,
    len_sec: 2607.487000000018,
    lanemiles: 6764.782000000013,
    delay: 1.3703054e7,
    trkdelay: 1018016.0,
    pti80: 1.15,
    trkpti80: 1.14,
    pti95: 1.19,
    trkpti95: 1.22,
    tti: 1.06,
    trktti: 1.05,
    ghg: 63784.0,
    congcost: 3.19012307e8,
    allspeed: 48.17,
    trkspeed: 57.59,
    ttivmt: 7598496.0,
    trkttivmt: 844128.0,
  },
  {
    agglevel: "district",
    regioncode: 24,
    year: 2022,
    count: 5665,
    len_sec: 2626.6150000000034,
    lanemiles: 6824.486999999991,
    delay: 1.8385328e7,
    trkdelay: 1217904.0,
    pti80: 1.18,
    trkpti80: 1.15,
    pti95: 1.24,
    trkpti95: 1.23,
    tti: 1.08,
    trktti: 1.05,
    ghg: 80682.0,
    congcost: 4.68415294e8,
    allspeed: 47.31,
    trkspeed: 57.36,
    ttivmt: 8075674.0,
    trkttivmt: 891999.0,
  },
  {
    agglevel: "district",
    regioncode: 25,
    year: 2017,
    count: 2543,
    len_sec: 2494.695000000001,
    lanemiles: 5446.1020000000035,
    delay: 369986.0,
    trkdelay: 89445.0,
    pti80: 1.07,
    trkpti80: 1.06,
    pti95: 1.09,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1811.0,
    congcost: 9594169.0,
    allspeed: 61.34,
    trkspeed: 63.39,
    ttivmt: 1057901.0,
    trkttivmt: 509527.0,
  },
  {
    agglevel: "district",
    regioncode: 25,
    year: 2018,
    count: 2675,
    len_sec: 2513.8199999999993,
    lanemiles: 5484.439000000004,
    delay: 311304.0,
    trkdelay: 78106.0,
    pti80: 1.08,
    trkpti80: 1.07,
    pti95: 1.1,
    trkpti95: 1.09,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1630.0,
    congcost: 8367242.0,
    allspeed: 59.85,
    trkspeed: 61.19,
    ttivmt: 1006916.0,
    trkttivmt: 289579.0,
  },
  {
    agglevel: "district",
    regioncode: 25,
    year: 2019,
    count: 1155,
    len_sec: 2534.129000000004,
    lanemiles: 5525.062999999999,
    delay: 418022.0,
    trkdelay: 128595.0,
    pti80: 1.09,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.1,
    tti: 1.01,
    trktti: 1.02,
    ghg: 2887.0,
    congcost: 1.2665171e7,
    allspeed: 61.34,
    trkspeed: 60.41,
    ttivmt: 1093294.0,
    trkttivmt: 309862.0,
  },
  {
    agglevel: "district",
    regioncode: 25,
    year: 2020,
    count: 4286,
    len_sec: 2555.1740000000054,
    lanemiles: 5608.113000000015,
    delay: 470128.0,
    trkdelay: 106108.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.11,
    tti: 1.01,
    trktti: 1.01,
    ghg: 2703.0,
    congcost: 1.3374027e7,
    allspeed: 61.02,
    trkspeed: 61.98,
    ttivmt: 1124153.0,
    trkttivmt: 323373.0,
  },
  {
    agglevel: "district",
    regioncode: 25,
    year: 2021,
    count: 4336,
    len_sec: 2554.7690000000084,
    lanemiles: 5610.154000000022,
    delay: 336671.0,
    trkdelay: 94834.0,
    pti80: 1.08,
    trkpti80: 1.08,
    pti95: 1.12,
    trkpti95: 1.12,
    tti: 1.01,
    trktti: 1.01,
    ghg: 1976.0,
    congcost: 9974857.0,
    allspeed: 61.11,
    trkspeed: 62.05,
    ttivmt: 1103929.0,
    trkttivmt: 363903.0,
  },
  {
    agglevel: "district",
    regioncode: 25,
    year: 2022,
    count: 4353,
    len_sec: 2554.973999999996,
    lanemiles: 5612.158000000015,
    delay: 560799.0,
    trkdelay: 170237.0,
    pti80: 1.09,
    trkpti80: 1.09,
    pti95: 1.14,
    trkpti95: 1.12,
    tti: 1.02,
    trktti: 1.02,
    ghg: 2960.0,
    congcost: 1.8884857e7,
    allspeed: 60.9,
    trkspeed: 61.85,
    ttivmt: 1234277.0,
    trkttivmt: 410424.0,
  },
  {
    agglevel: "mpo",
    regioncode: 1,
    year: 2021,
    count: 2214,
    len_sec: 904.4309999999953,
    lanemiles: 2562.824999999995,
    delay: 6292564.0,
    trkdelay: 931599.0,
    pti80: 1.16,
    trkpti80: 1.19,
    pti95: 1.2,
    trkpti95: 1.32,
    tti: 1.07,
    trktti: 1.08,
    ghg: 37517.0,
    congcost: 1.61555138e8,
    allspeed: 48.15,
    trkspeed: 54.54,
    ttivmt: 3091246.0,
    trkttivmt: 425751.0,
  },
  {
    agglevel: "mpo",
    regioncode: 2,
    year: 2021,
    count: 2345,
    len_sec: 657.9599999999961,
    lanemiles: 1880.739999999997,
    delay: 3351481.0,
    trkdelay: 288458.0,
    pti80: 1.13,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.17,
    tti: 1.06,
    trktti: 1.04,
    ghg: 16781.0,
    congcost: 7.9412092e7,
    allspeed: 46.8,
    trkspeed: 55.72,
    ttivmt: 1962950.0,
    trkttivmt: 246407.0,
  },
  {
    agglevel: "mpo",
    regioncode: 3,
    year: 2021,
    count: 804,
    len_sec: 245.62000000000018,
    lanemiles: 720.0359999999993,
    delay: 1605358.0,
    trkdelay: 133241.0,
    pti80: 1.15,
    trkpti80: 1.22,
    pti95: 1.2,
    trkpti95: 1.34,
    tti: 1.07,
    trktti: 1.1,
    ghg: 8117.0,
    congcost: 3.7914237e7,
    allspeed: 43.74,
    trkspeed: 45.9,
    ttivmt: 616112.0,
    trkttivmt: 40671.0,
  },
  {
    agglevel: "mpo",
    regioncode: 4,
    year: 2021,
    count: 1413,
    len_sec: 459.13699999999875,
    lanemiles: 1209.1339999999957,
    delay: 1739511.0,
    trkdelay: 171356.0,
    pti80: 1.14,
    trkpti80: 1.15,
    pti95: 1.19,
    trkpti95: 1.25,
    tti: 1.05,
    trktti: 1.05,
    ghg: 8943.0,
    congcost: 4.1904242e7,
    allspeed: 48.09,
    trkspeed: 55.02,
    ttivmt: 1039803.0,
    trkttivmt: 117395.0,
  },
  {
    agglevel: "mpo",
    regioncode: 5,
    year: 2021,
    count: 1246,
    len_sec: 349.1959999999992,
    lanemiles: 982.5069999999992,
    delay: 1359746.0,
    trkdelay: 80976.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.15,
    trkpti95: 1.14,
    tti: 1.05,
    trktti: 1.03,
    ghg: 6446.0,
    congcost: 3.1112941e7,
    allspeed: 48.47,
    trkspeed: 55.76,
    ttivmt: 824482.0,
    trkttivmt: 80755.0,
  },
  {
    agglevel: "mpo",
    regioncode: 6,
    year: 2021,
    count: 1989,
    len_sec: 610.4879999999982,
    lanemiles: 1858.4519999999984,
    delay: 3842360.0,
    trkdelay: 296851.0,
    pti80: 1.16,
    trkpti80: 1.21,
    pti95: 1.19,
    trkpti95: 1.32,
    tti: 1.07,
    trktti: 1.1,
    ghg: 16626.0,
    congcost: 8.9559375e7,
    allspeed: 44.69,
    trkspeed: 48.83,
    ttivmt: 2037417.0,
    trkttivmt: 122393.0,
  },
  {
    agglevel: "mpo",
    regioncode: 7,
    year: 2021,
    count: 2984,
    len_sec: 1007.9949999999974,
    lanemiles: 2561.8569999999927,
    delay: 3540604.0,
    trkdelay: 258564.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.17,
    trkpti95: 1.19,
    tti: 1.04,
    trktti: 1.03,
    ghg: 16131.0,
    congcost: 8.2257451e7,
    allspeed: 52.71,
    trkspeed: 59.29,
    ttivmt: 3518881.0,
    trkttivmt: 351538.0,
  },
  {
    agglevel: "mpo",
    regioncode: 8,
    year: 2021,
    count: 35708,
    len_sec: 12237.3840000005,
    lanemiles: 37077.09000000146,
    delay: 1.37760119e8,
    trkdelay: 8292155.0,
    pti80: 1.24,
    trkpti80: 1.25,
    pti95: 1.31,
    trkpti95: 1.36,
    tti: 1.12,
    trktti: 1.12,
    ghg: 553297.0,
    congcost: 3.133378834e9,
    allspeed: 47.59,
    trkspeed: 53.65,
    ttivmt: 6.7179388e7,
    trkttivmt: 4492090.0,
  },
  {
    agglevel: "mpo",
    regioncode: 9,
    year: 2021,
    count: 2054,
    len_sec: 597.6409999999973,
    lanemiles: 1835.7349999999942,
    delay: 5588810.0,
    trkdelay: 581464.0,
    pti80: 1.14,
    trkpti80: 1.23,
    pti95: 1.18,
    trkpti95: 1.38,
    tti: 1.06,
    trktti: 1.11,
    ghg: 31093.0,
    congcost: 1.35973325e8,
    allspeed: 49.32,
    trkspeed: 48.41,
    ttivmt: 3090743.0,
    trkttivmt: 182246.0,
  },
  {
    agglevel: "mpo",
    regioncode: 11,
    year: 2021,
    count: 24624,
    len_sec: 8799.470000000365,
    lanemiles: 26582.200000000987,
    delay: 1.38621105e8,
    trkdelay: 8441038.0,
    pti80: 1.25,
    trkpti80: 1.28,
    pti95: 1.31,
    trkpti95: 1.42,
    tti: 1.12,
    trktti: 1.14,
    ghg: 578580.0,
    congcost: 3.160087545e9,
    allspeed: 45.76,
    trkspeed: 50.49,
    ttivmt: 5.8136582e7,
    trkttivmt: 3294897.0,
  },
  {
    agglevel: "mpo",
    regioncode: 12,
    year: 2021,
    count: 2900,
    len_sec: 836.6269999999955,
    lanemiles: 2102.545999999994,
    delay: 3995727.0,
    trkdelay: 396298.0,
    pti80: 1.15,
    trkpti80: 1.16,
    pti95: 1.19,
    trkpti95: 1.24,
    tti: 1.07,
    trktti: 1.07,
    ghg: 21548.0,
    congcost: 9.6512128e7,
    allspeed: 48.15,
    trkspeed: 54.53,
    ttivmt: 2182638.0,
    trkttivmt: 235946.0,
  },
  {
    agglevel: "mpo",
    regioncode: 13,
    year: 2021,
    count: 1403,
    len_sec: 372.52099999999876,
    lanemiles: 1095.585999999998,
    delay: 1787925.0,
    trkdelay: 141581.0,
    pti80: 1.13,
    trkpti80: 1.16,
    pti95: 1.17,
    trkpti95: 1.25,
    tti: 1.06,
    trktti: 1.06,
    ghg: 8680.0,
    congcost: 4.1961381e7,
    allspeed: 45.79,
    trkspeed: 54.24,
    ttivmt: 1203340.0,
    trkttivmt: 99898.0,
  },
  {
    agglevel: "mpo",
    regioncode: 14,
    year: 2021,
    count: 1045,
    len_sec: 254.0259999999997,
    lanemiles: 669.7659999999992,
    delay: 1149360.0,
    trkdelay: 60172.0,
    pti80: 1.13,
    trkpti80: 1.11,
    pti95: 1.17,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.03,
    ghg: 5340.0,
    congcost: 2.6035809e7,
    allspeed: 47.66,
    trkspeed: 59.47,
    ttivmt: 650673.0,
    trkttivmt: 75568.0,
  },
  {
    agglevel: "mpo",
    regioncode: 15,
    year: 2017,
    count: 15368,
    len_sec: 8436.802999999989,
    lanemiles: 24906.69200000011,
    delay: 2.87341456e8,
    trkdelay: 1.9511533e7,
    pti80: 1.45,
    trkpti80: 1.59,
    pti95: 1.57,
    trkpti95: 1.77,
    tti: 1.22,
    trktti: 1.29,
    ghg: 1227992.0,
    congcost: 5.933864089e9,
    allspeed: 42.8,
    trkspeed: 47.21,
    ttivmt: 7.4054018e7,
    trkttivmt: 5897305.0,
  },
  {
    agglevel: "mpo",
    regioncode: 15,
    year: 2018,
    count: 15723,
    len_sec: 8787.42700000007,
    lanemiles: 25884.38700000023,
    delay: 3.13210438e8,
    trkdelay: 1.4276605e7,
    pti80: 1.54,
    trkpti80: 1.59,
    pti95: 1.66,
    trkpti95: 1.75,
    tti: 1.3,
    trktti: 1.3,
    ghg: 1254462.0,
    congcost: 6.380783509e9,
    allspeed: 42.28,
    trkspeed: 46.25,
    ttivmt: 6.162544e7,
    trkttivmt: 3442974.0,
  },
  {
    agglevel: "mpo",
    regioncode: 15,
    year: 2019,
    count: 8279,
    len_sec: 6196.696000000025,
    lanemiles: 20699.012000000137,
    delay: 2.66244015e8,
    trkdelay: 1.3780862e7,
    pti80: 1.47,
    trkpti80: 1.5,
    pti95: 1.58,
    trkpti95: 1.63,
    tti: 1.26,
    trktti: 1.25,
    ghg: 1089301.0,
    congcost: 5.678840778e9,
    allspeed: 43.66,
    trkspeed: 46.52,
    ttivmt: 5.930039e7,
    trkttivmt: 3603107.0,
  },
  {
    agglevel: "mpo",
    regioncode: 15,
    year: 2020,
    count: 23623,
    len_sec: 8757.36100000032,
    lanemiles: 26266.471000000634,
    delay: 2.86661347e8,
    trkdelay: 1.3405348e7,
    pti80: 1.48,
    trkpti80: 1.48,
    pti95: 1.58,
    trkpti95: 1.61,
    tti: 1.26,
    trktti: 1.24,
    ghg: 1153029.0,
    congcost: 6.23028385e9,
    allspeed: 43.31,
    trkspeed: 48.06,
    ttivmt: 6.4751479e7,
    trkttivmt: 3666905.0,
  },
  {
    agglevel: "mpo",
    regioncode: 15,
    year: 2021,
    count: 3340,
    len_sec: 1049.0289999999945,
    lanemiles: 3331.526999999995,
    delay: 1.327063e7,
    trkdelay: 931868.0,
    pti80: 1.16,
    trkpti80: 1.2,
    pti95: 1.2,
    trkpti95: 1.31,
    tti: 1.07,
    trktti: 1.08,
    ghg: 61406.0,
    congcost: 3.07300772e8,
    allspeed: 45.55,
    trkspeed: 51.16,
    ttivmt: 6400143.0,
    trkttivmt: 420175.0,
  },
  {
    agglevel: "mpo",
    regioncode: 15,
    year: 2022,
    count: 26015,
    len_sec: 8920.589000000322,
    lanemiles: 27207.086000001153,
    delay: 1.95858934e8,
    trkdelay: 1.10623e7,
    pti80: 1.33,
    trkpti80: 1.34,
    pti95: 1.41,
    trkpti95: 1.48,
    tti: 1.17,
    trktti: 1.16,
    ghg: 783852.0,
    congcost: 4.904568038e9,
    allspeed: 45.49,
    trkspeed: 50.66,
    ttivmt: 6.0570734e7,
    trkttivmt: 3996879.0,
  },
  {
    agglevel: "mpo",
    regioncode: 16,
    year: 2021,
    count: 1326,
    len_sec: 433.3859999999989,
    lanemiles: 1183.6589999999971,
    delay: 6041732.0,
    trkdelay: 472842.0,
    pti80: 1.22,
    trkpti80: 1.21,
    pti95: 1.27,
    trkpti95: 1.3,
    tti: 1.1,
    trktti: 1.09,
    ghg: 29971.0,
    congcost: 1.41702097e8,
    allspeed: 39.26,
    trkspeed: 48.91,
    ttivmt: 1558851.0,
    trkttivmt: 152267.0,
  },
  {
    agglevel: "mpo",
    regioncode: 17,
    year: 2021,
    count: 4925,
    len_sec: 1318.404999999985,
    lanemiles: 3468.050999999955,
    delay: 5387081.0,
    trkdelay: 383313.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.04,
    ghg: 22974.0,
    congcost: 1.24538878e8,
    allspeed: 52.12,
    trkspeed: 58.9,
    ttivmt: 3973343.0,
    trkttivmt: 390928.0,
  },
  {
    agglevel: "mpo",
    regioncode: 18,
    year: 2021,
    count: 915,
    len_sec: 370.26600000000025,
    lanemiles: 1098.8359999999993,
    delay: 1277420.0,
    trkdelay: 110671.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.03,
    ghg: 6716.0,
    congcost: 3.0361546e7,
    allspeed: 52.36,
    trkspeed: 59.34,
    ttivmt: 1015743.0,
    trkttivmt: 126093.0,
  },
  {
    agglevel: "mpo",
    regioncode: 19,
    year: 2021,
    count: 1793,
    len_sec: 600.9529999999988,
    lanemiles: 1539.5739999999998,
    delay: 3326552.0,
    trkdelay: 201204.0,
    pti80: 1.17,
    trkpti80: 1.16,
    pti95: 1.22,
    trkpti95: 1.25,
    tti: 1.07,
    trktti: 1.07,
    ghg: 16119.0,
    congcost: 7.6262368e7,
    allspeed: 47.46,
    trkspeed: 55.49,
    ttivmt: 1608906.0,
    trkttivmt: 122207.0,
  },
  {
    agglevel: "mpo",
    regioncode: 20,
    year: 2021,
    count: 12329,
    len_sec: 3948.301000000076,
    lanemiles: 10944.037000000113,
    delay: 3.9686313e7,
    trkdelay: 3053186.0,
    pti80: 1.25,
    trkpti80: 1.29,
    pti95: 1.33,
    trkpti95: 1.41,
    tti: 1.12,
    trktti: 1.13,
    ghg: 168276.0,
    congcost: 9.23631401e8,
    allspeed: 49.49,
    trkspeed: 54.55,
    ttivmt: 1.8142602e7,
    trkttivmt: 1408509.0,
  },
  {
    agglevel: "mpo",
    regioncode: 21,
    year: 2021,
    count: 10280,
    len_sec: 3148.801000000016,
    lanemiles: 9070.691000000032,
    delay: 3.5451149e7,
    trkdelay: 2182089.0,
    pti80: 1.21,
    trkpti80: 1.21,
    pti95: 1.28,
    trkpti95: 1.32,
    tti: 1.1,
    trktti: 1.09,
    ghg: 142526.0,
    congcost: 8.07782878e8,
    allspeed: 48.78,
    trkspeed: 54.75,
    ttivmt: 1.9790853e7,
    trkttivmt: 1412012.0,
  },
  {
    agglevel: "mpo",
    regioncode: 22,
    year: 2021,
    count: 2739,
    len_sec: 888.2599999999952,
    lanemiles: 2269.165999999993,
    delay: 3854752.0,
    trkdelay: 389035.0,
    pti80: 1.13,
    trkpti80: 1.13,
    pti95: 1.25,
    trkpti95: 1.29,
    tti: 1.05,
    trktti: 1.04,
    ghg: 18728.0,
    congcost: 9.2857302e7,
    allspeed: 52.53,
    trkspeed: 59.79,
    ttivmt: 2867506.0,
    trkttivmt: 408694.0,
  },
  {
    agglevel: "mpo",
    regioncode: 24,
    year: 2021,
    count: 2148,
    len_sec: 612.0489999999967,
    lanemiles: 1416.1779999999917,
    delay: 1451365.0,
    trkdelay: 126870.0,
    pti80: 1.1,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.03,
    trktti: 1.03,
    ghg: 5920.0,
    congcost: 3.4181444e7,
    allspeed: 56.85,
    trkspeed: 60.19,
    ttivmt: 1531057.0,
    trkttivmt: 163374.0,
  },
  {
    agglevel: "mpo",
    regioncode: 25,
    year: 2021,
    count: 6929,
    len_sec: 2433.228000000012,
    lanemiles: 6434.230999999999,
    delay: 1.9819077e7,
    trkdelay: 987379.0,
    pti80: 1.17,
    trkpti80: 1.19,
    pti95: 1.22,
    trkpti95: 1.29,
    tti: 1.08,
    trktti: 1.08,
    ghg: 92832.0,
    congcost: 4.47570528e8,
    allspeed: 42.96,
    trkspeed: 50.25,
    ttivmt: 8082433.0,
    trkttivmt: 434234.0,
  },
  {
    agglevel: "mpo",
    regioncode: 28,
    year: 2017,
    count: 7386,
    len_sec: 3146.699999999999,
    lanemiles: 9000.533000000007,
    delay: 7.8284023e7,
    trkdelay: 3751133.0,
    pti80: 1.38,
    trkpti80: 1.35,
    pti95: 1.5,
    trkpti95: 1.47,
    tti: 1.18,
    trktti: 1.15,
    ghg: 308530.0,
    congcost: 1.56577986e9,
    allspeed: 45.37,
    trkspeed: 52.64,
    ttivmt: 2.5548788e7,
    trkttivmt: 2067452.0,
  },
  {
    agglevel: "mpo",
    regioncode: 28,
    year: 2018,
    count: 6557,
    len_sec: 3173.91800000003,
    lanemiles: 9119.180999999997,
    delay: 7.9285754e7,
    trkdelay: 3562060.0,
    pti80: 1.46,
    trkpti80: 1.42,
    pti95: 1.61,
    trkpti95: 1.58,
    tti: 1.23,
    trktti: 1.19,
    ghg: 308580.0,
    congcost: 1.611778388e9,
    allspeed: 45.71,
    trkspeed: 50.85,
    ttivmt: 2.1206274e7,
    trkttivmt: 1380095.0,
  },
  {
    agglevel: "mpo",
    regioncode: 28,
    year: 2019,
    count: 3140,
    len_sec: 2421.404000000002,
    lanemiles: 7640.925999999998,
    delay: 6.8572178e7,
    trkdelay: 3645617.0,
    pti80: 1.4,
    trkpti80: 1.37,
    pti95: 1.52,
    trkpti95: 1.51,
    tti: 1.2,
    trktti: 1.17,
    ghg: 272631.0,
    congcost: 1.463699698e9,
    allspeed: 46.8,
    trkspeed: 50.15,
    ttivmt: 2.0384281e7,
    trkttivmt: 1413798.0,
  },
  {
    agglevel: "mpo",
    regioncode: 28,
    year: 2020,
    count: 10315,
    len_sec: 3148.102999999999,
    lanemiles: 9065.934000000021,
    delay: 7.4564752e7,
    trkdelay: 3686200.0,
    pti80: 1.4,
    trkpti80: 1.37,
    pti95: 1.52,
    trkpti95: 1.51,
    tti: 1.2,
    trktti: 1.17,
    ghg: 292808.0,
    congcost: 1.625930237e9,
    allspeed: 46.7,
    trkspeed: 52.28,
    ttivmt: 2.2359955e7,
    trkttivmt: 1506866.0,
  },
  {
    agglevel: "mpo",
    regioncode: 28,
    year: 2022,
    count: 10870,
    len_sec: 3198.4710000000073,
    lanemiles: 9200.394000000026,
    delay: 5.5858238e7,
    trkdelay: 3139511.0,
    pti80: 1.26,
    trkpti80: 1.25,
    pti95: 1.35,
    trkpti95: 1.37,
    tti: 1.12,
    trktti: 1.11,
    ghg: 215856.0,
    congcost: 1.396154591e9,
    allspeed: 48.39,
    trkspeed: 54.01,
    ttivmt: 2.2445135e7,
    trkttivmt: 1598484.0,
  },
  {
    agglevel: "mpo",
    regioncode: 66,
    year: 2017,
    count: 2403,
    len_sec: 1012.9510000000006,
    lanemiles: 3234.8249999999975,
    delay: 2.6729998e7,
    trkdelay: 1024056.0,
    pti80: 1.26,
    trkpti80: 1.26,
    pti95: 1.33,
    trkpti95: 1.37,
    tti: 1.12,
    trktti: 1.11,
    ghg: 110635.0,
    congcost: 5.28150145e8,
    allspeed: 42.27,
    trkspeed: 46.58,
    ttivmt: 8906359.0,
    trkttivmt: 518621.0,
  },
  {
    agglevel: "mpo",
    regioncode: 66,
    year: 2018,
    count: 2165,
    len_sec: 1025.741999999999,
    lanemiles: 3283.9820000000004,
    delay: 2.2580702e7,
    trkdelay: 1111138.0,
    pti80: 1.27,
    trkpti80: 1.32,
    pti95: 1.35,
    trkpti95: 1.43,
    tti: 1.14,
    trktti: 1.14,
    ghg: 96391.0,
    congcost: 4.63770271e8,
    allspeed: 44.16,
    trkspeed: 48.5,
    ttivmt: 6428771.0,
    trkttivmt: 375938.0,
  },
  {
    agglevel: "mpo",
    regioncode: 66,
    year: 2019,
    count: 1611,
    len_sec: 982.4959999999991,
    lanemiles: 3203.1330000000016,
    delay: 2.4333156e7,
    trkdelay: 1565670.0,
    pti80: 1.25,
    trkpti80: 1.29,
    pti95: 1.31,
    trkpti95: 1.39,
    tti: 1.13,
    trktti: 1.14,
    ghg: 112023.0,
    congcost: 5.32030163e8,
    allspeed: 43.92,
    trkspeed: 46.35,
    ttivmt: 6818301.0,
    trkttivmt: 465023.0,
  },
  {
    agglevel: "mpo",
    regioncode: 66,
    year: 2020,
    count: 3383,
    len_sec: 1042.7499999999955,
    lanemiles: 3313.5029999999824,
    delay: 2.5021941e7,
    trkdelay: 1232669.0,
    pti80: 1.23,
    trkpti80: 1.24,
    pti95: 1.29,
    trkpti95: 1.33,
    tti: 1.12,
    trktti: 1.11,
    ghg: 109496.0,
    congcost: 5.48124794e8,
    allspeed: 43.98,
    trkspeed: 49.23,
    ttivmt: 7234563.0,
    trkttivmt: 430547.0,
  },
  {
    agglevel: "mpo",
    regioncode: 66,
    year: 2022,
    count: 3561,
    len_sec: 1060.7319999999954,
    lanemiles: 3377.3049999999894,
    delay: 1.7870284e7,
    trkdelay: 1097677.0,
    pti80: 1.2,
    trkpti80: 1.23,
    pti95: 1.26,
    trkpti95: 1.34,
    tti: 1.09,
    trktti: 1.1,
    ghg: 77936.0,
    congcost: 4.52284165e8,
    allspeed: 44.41,
    trkspeed: 50.89,
    ttivmt: 6754347.0,
    trkttivmt: 438934.0,
  },
  {
    agglevel: "mpo",
    regioncode: 90,
    year: 2017,
    count: 6481,
    len_sec: 3939.0509999999945,
    lanemiles: 10862.941000000023,
    delay: 9.7919901e7,
    trkdelay: 5431311.0,
    pti80: 1.47,
    trkpti80: 1.56,
    pti95: 1.59,
    trkpti95: 1.72,
    tti: 1.22,
    trktti: 1.24,
    ghg: 394628.0,
    congcost: 1.981644614e9,
    allspeed: 43.7,
    trkspeed: 51.53,
    ttivmt: 2.6762941e7,
    trkttivmt: 1914338.0,
  },
  {
    agglevel: "mpo",
    regioncode: 90,
    year: 2018,
    count: 6560,
    len_sec: 3938.147000000014,
    lanemiles: 10871.588000000074,
    delay: 9.0360098e7,
    trkdelay: 4162116.0,
    pti80: 1.56,
    trkpti80: 1.53,
    pti95: 1.71,
    trkpti95: 1.68,
    tti: 1.28,
    trktti: 1.25,
    ghg: 350697.0,
    congcost: 1.839581893e9,
    allspeed: 45.5,
    trkspeed: 51.09,
    ttivmt: 2.0103089e7,
    trkttivmt: 1287318.0,
  },
  {
    agglevel: "mpo",
    regioncode: 90,
    year: 2019,
    count: 3641,
    len_sec: 2735.2260000000024,
    lanemiles: 8388.690999999992,
    delay: 8.3940065e7,
    trkdelay: 3992209.0,
    pti80: 1.49,
    trkpti80: 1.46,
    pti95: 1.62,
    trkpti95: 1.59,
    tti: 1.25,
    trktti: 1.21,
    ghg: 330119.0,
    congcost: 1.775450724e9,
    allspeed: 46.57,
    trkspeed: 50.94,
    ttivmt: 2.0081195e7,
    trkttivmt: 1291626.0,
  },
  {
    agglevel: "mpo",
    regioncode: 90,
    year: 2020,
    count: 11685,
    len_sec: 3941.961000000014,
    lanemiles: 10887.666000000092,
    delay: 9.4645894e7,
    trkdelay: 4163789.0,
    pti80: 1.52,
    trkpti80: 1.45,
    pti95: 1.65,
    trkpti95: 1.59,
    tti: 1.26,
    trktti: 1.21,
    ghg: 364247.0,
    congcost: 2.043927085e9,
    allspeed: 46.2,
    trkspeed: 52.49,
    ttivmt: 2.2482045e7,
    trkttivmt: 1379262.0,
  },
  {
    agglevel: "mpo",
    regioncode: 90,
    year: 2022,
    count: 13723,
    len_sec: 3981.972000000011,
    lanemiles: 11106.88500000005,
    delay: 6.1229114e7,
    trkdelay: 3668064.0,
    pti80: 1.29,
    trkpti80: 1.31,
    pti95: 1.37,
    trkpti95: 1.42,
    tti: 1.14,
    trktti: 1.14,
    ghg: 240461.0,
    congcost: 1.538710454e9,
    allspeed: 48.39,
    trkspeed: 53.45,
    ttivmt: 2.1424271e7,
    trkttivmt: 1478742.0,
  },
  {
    agglevel: "mpo",
    regioncode: 96,
    year: 2017,
    count: 1549,
    len_sec: 581.6189999999998,
    lanemiles: 1739.6619999999996,
    delay: 1.1088093e7,
    trkdelay: 632533.0,
    pti80: 1.19,
    trkpti80: 1.18,
    pti95: 1.25,
    trkpti95: 1.24,
    tti: 1.09,
    trktti: 1.07,
    ghg: 49048.0,
    congcost: 2.25824048e8,
    allspeed: 46.29,
    trkspeed: 50.69,
    ttivmt: 4281470.0,
    trkttivmt: 371257.0,
  },
  {
    agglevel: "mpo",
    regioncode: 96,
    year: 2018,
    count: 1413,
    len_sec: 593.8579999999998,
    lanemiles: 1787.4659999999988,
    delay: 1.0903322e7,
    trkdelay: 452035.0,
    pti80: 1.23,
    trkpti80: 1.25,
    pti95: 1.28,
    trkpti95: 1.35,
    tti: 1.12,
    trktti: 1.11,
    ghg: 47868.0,
    congcost: 2.21782577e8,
    allspeed: 48.53,
    trkspeed: 50.21,
    ttivmt: 3334136.0,
    trkttivmt: 164758.0,
  },
  {
    agglevel: "mpo",
    regioncode: 96,
    year: 2019,
    count: 731,
    len_sec: 476.159,
    lanemiles: 1531.722,
    delay: 1.05861e7,
    trkdelay: 634050.0,
    pti80: 1.21,
    trkpti80: 1.25,
    pti95: 1.25,
    trkpti95: 1.33,
    tti: 1.11,
    trktti: 1.12,
    ghg: 50839.0,
    congcost: 2.30622896e8,
    allspeed: 48.64,
    trkspeed: 46.86,
    ttivmt: 3211850.0,
    trkttivmt: 188564.0,
  },
  {
    agglevel: "mpo",
    regioncode: 96,
    year: 2020,
    count: 2060,
    len_sec: 596.9979999999994,
    lanemiles: 1799.2109999999952,
    delay: 1.008844e7,
    trkdelay: 640203.0,
    pti80: 1.2,
    trkpti80: 1.24,
    pti95: 1.24,
    trkpti95: 1.31,
    tti: 1.1,
    trktti: 1.12,
    ghg: 49841.0,
    congcost: 2.27396229e8,
    allspeed: 48.6,
    trkspeed: 48.06,
    ttivmt: 3395966.0,
    trkttivmt: 188246.0,
  },
  {
    agglevel: "mpo",
    regioncode: 96,
    year: 2022,
    count: 2151,
    len_sec: 597.5909999999984,
    lanemiles: 1834.7039999999963,
    delay: 1.0163479e7,
    trkdelay: 549946.0,
    pti80: 1.19,
    trkpti80: 1.22,
    pti95: 1.24,
    trkpti95: 1.36,
    tti: 1.09,
    trktti: 1.09,
    ghg: 48693.0,
    congcost: 2.56064444e8,
    allspeed: 47.94,
    trkspeed: 48.68,
    ttivmt: 3319007.0,
    trkttivmt: 191236.0,
  },
  {
    agglevel: "mpo",
    regioncode: 120,
    year: 2017,
    count: 1409,
    len_sec: 523.9259999999996,
    lanemiles: 1501.322999999999,
    delay: 5960058.0,
    trkdelay: 170101.0,
    pti80: 1.17,
    trkpti80: 1.09,
    pti95: 1.2,
    trkpti95: 1.11,
    tti: 1.08,
    trktti: 1.03,
    ghg: 25189.0,
    congcost: 1.16176535e8,
    allspeed: 43.04,
    trkspeed: 55.8,
    ttivmt: 2200204.0,
    trkttivmt: 223558.0,
  },
  {
    agglevel: "mpo",
    regioncode: 120,
    year: 2018,
    count: 1171,
    len_sec: 522.3089999999991,
    lanemiles: 1504.041999999997,
    delay: 4991407.0,
    trkdelay: 348492.0,
    pti80: 1.18,
    trkpti80: 1.18,
    pti95: 1.22,
    trkpti95: 1.23,
    tti: 1.09,
    trktti: 1.07,
    ghg: 25337.0,
    congcost: 1.06458254e8,
    allspeed: 46.57,
    trkspeed: 52.11,
    ttivmt: 1675684.0,
    trkttivmt: 172802.0,
  },
  {
    agglevel: "mpo",
    regioncode: 120,
    year: 2019,
    count: 920,
    len_sec: 497.72599999999966,
    lanemiles: 1465.1180000000004,
    delay: 4854607.0,
    trkdelay: 352051.0,
    pti80: 1.17,
    trkpti80: 1.17,
    pti95: 1.2,
    trkpti95: 1.22,
    tti: 1.09,
    trktti: 1.07,
    ghg: 25039.0,
    congcost: 1.08107935e8,
    allspeed: 45.39,
    trkspeed: 50.68,
    ttivmt: 1630774.0,
    trkttivmt: 164158.0,
  },
  {
    agglevel: "mpo",
    regioncode: 120,
    year: 2020,
    count: 2133,
    len_sec: 661.0829999999978,
    lanemiles: 1869.4759999999924,
    delay: 4937954.0,
    trkdelay: 273642.0,
    pti80: 1.16,
    trkpti80: 1.14,
    pti95: 1.2,
    trkpti95: 1.19,
    tti: 1.08,
    trktti: 1.06,
    ghg: 23415.0,
    congcost: 1.09659397e8,
    allspeed: 47.03,
    trkspeed: 54.18,
    ttivmt: 1874055.0,
    trkttivmt: 168777.0,
  },
  {
    agglevel: "mpo",
    regioncode: 120,
    year: 2022,
    count: 2409,
    len_sec: 658.7299999999972,
    lanemiles: 1883.072999999996,
    delay: 4533852.0,
    trkdelay: 325277.0,
    pti80: 1.14,
    trkpti80: 1.13,
    pti95: 1.19,
    trkpti95: 1.2,
    tti: 1.06,
    trktti: 1.05,
    ghg: 21725.0,
    congcost: 1.16855382e8,
    allspeed: 46.66,
    trkspeed: 54.78,
    ttivmt: 2177477.0,
    trkttivmt: 224643.0,
  },
  {
    agglevel: "mpo",
    regioncode: 122,
    year: 2017,
    count: 1518,
    len_sec: 633.4919999999993,
    lanemiles: 1884.0319999999972,
    delay: 7493033.0,
    trkdelay: 173374.0,
    pti80: 1.15,
    trkpti80: 1.12,
    pti95: 1.2,
    trkpti95: 1.17,
    tti: 1.07,
    trktti: 1.04,
    ghg: 24318.0,
    congcost: 1.4340604e8,
    allspeed: 42.71,
    trkspeed: 50.37,
    ttivmt: 3764757.0,
    trkttivmt: 170388.0,
  },
  {
    agglevel: "mpo",
    regioncode: 122,
    year: 2018,
    count: 1294,
    len_sec: 602.6299999999989,
    lanemiles: 1833.6249999999982,
    delay: 5237297.0,
    trkdelay: 295096.0,
    pti80: 1.16,
    trkpti80: 1.22,
    pti95: 1.2,
    trkpti95: 1.3,
    tti: 1.09,
    trktti: 1.09,
    ghg: 23741.0,
    congcost: 1.09003576e8,
    allspeed: 45.47,
    trkspeed: 48.83,
    ttivmt: 1994677.0,
    trkttivmt: 123430.0,
  },
  {
    agglevel: "mpo",
    regioncode: 122,
    year: 2019,
    count: 960,
    len_sec: 586.7789999999994,
    lanemiles: 1802.825999999998,
    delay: 4790428.0,
    trkdelay: 336550.0,
    pti80: 1.16,
    trkpti80: 1.21,
    pti95: 1.19,
    trkpti95: 1.29,
    tti: 1.08,
    trktti: 1.1,
    ghg: 22761.0,
    congcost: 1.0585548e8,
    allspeed: 44.83,
    trkspeed: 46.28,
    ttivmt: 1927964.0,
    trkttivmt: 121015.0,
  },
  {
    agglevel: "mpo",
    regioncode: 122,
    year: 2020,
    count: 2003,
    len_sec: 608.5819999999974,
    lanemiles: 1851.9439999999947,
    delay: 4941384.0,
    trkdelay: 246758.0,
    pti80: 1.16,
    trkpti80: 1.18,
    pti95: 1.19,
    trkpti95: 1.24,
    tti: 1.08,
    trktti: 1.09,
    ghg: 21715.0,
    congcost: 1.08404579e8,
    allspeed: 45.4,
    trkspeed: 48.81,
    ttivmt: 2072356.0,
    trkttivmt: 105565.0,
  },
  {
    agglevel: "mpo",
    regioncode: 122,
    year: 2022,
    count: 2083,
    len_sec: 612.4639999999984,
    lanemiles: 1866.2349999999954,
    delay: 4593625.0,
    trkdelay: 304037.0,
    pti80: 1.15,
    trkpti80: 1.2,
    pti95: 1.19,
    trkpti95: 1.32,
    tti: 1.07,
    trktti: 1.08,
    ghg: 21079.0,
    congcost: 1.17302773e8,
    allspeed: 44.07,
    trkspeed: 47.69,
    ttivmt: 2237126.0,
    trkttivmt: 129609.0,
  },
  {
    agglevel: "mpo",
    regioncode: 135,
    year: 2017,
    count: 3220,
    len_sec: 1325.512999999998,
    lanemiles: 3439.9769999999985,
    delay: 1.1462531e7,
    trkdelay: 455217.0,
    pti80: 1.17,
    trkpti80: 1.12,
    pti95: 1.22,
    trkpti95: 1.17,
    tti: 1.07,
    trktti: 1.04,
    ghg: 45375.0,
    congcost: 2.26589934e8,
    allspeed: 47.83,
    trkspeed: 57.95,
    ttivmt: 5554920.0,
    trkttivmt: 619129.0,
  },
  {
    agglevel: "mpo",
    regioncode: 135,
    year: 2018,
    count: 3223,
    len_sec: 1322.8979999999954,
    lanemiles: 3431.8109999999847,
    delay: 9406311.0,
    trkdelay: 591127.0,
    pti80: 1.17,
    trkpti80: 1.16,
    pti95: 1.22,
    trkpti95: 1.22,
    tti: 1.08,
    trktti: 1.06,
    ghg: 39394.0,
    congcost: 1.96922094e8,
    allspeed: 51.18,
    trkspeed: 56.27,
    ttivmt: 4358623.0,
    trkttivmt: 421513.0,
  },
  {
    agglevel: "mpo",
    regioncode: 135,
    year: 2019,
    count: 1198,
    len_sec: 852.0350000000001,
    lanemiles: 2454.854999999999,
    delay: 8235519.0,
    trkdelay: 527645.0,
    pti80: 1.16,
    trkpti80: 1.15,
    pti95: 1.2,
    trkpti95: 1.21,
    tti: 1.07,
    trktti: 1.06,
    ghg: 34519.0,
    congcost: 1.79160858e8,
    allspeed: 52.51,
    trkspeed: 56.29,
    ttivmt: 4116310.0,
    trkttivmt: 381814.0,
  },
  {
    agglevel: "mpo",
    regioncode: 135,
    year: 2020,
    count: 4920,
    len_sec: 1321.175999999986,
    lanemiles: 3456.3429999999585,
    delay: 8958177.0,
    trkdelay: 476504.0,
    pti80: 1.16,
    trkpti80: 1.13,
    pti95: 1.2,
    trkpti95: 1.17,
    tti: 1.07,
    trktti: 1.05,
    ghg: 37398.0,
    congcost: 1.97090871e8,
    allspeed: 51.53,
    trkspeed: 58.1,
    ttivmt: 4523803.0,
    trkttivmt: 395838.0,
  },
  {
    agglevel: "mpo",
    regioncode: 135,
    year: 2022,
    count: 5071,
    len_sec: 1324.583999999981,
    lanemiles: 3467.901999999965,
    delay: 7060429.0,
    trkdelay: 564346.0,
    pti80: 1.13,
    trkpti80: 1.12,
    pti95: 1.19,
    trkpti95: 1.19,
    tti: 1.05,
    trktti: 1.05,
    ghg: 29837.0,
    congcost: 1.82784199e8,
    allspeed: 52.29,
    trkspeed: 58.09,
    ttivmt: 4534263.0,
    trkttivmt: 489770.0,
  },
  {
    agglevel: "mpo",
    regioncode: 140,
    year: 2017,
    count: 1893,
    len_sec: 892.8629999999985,
    lanemiles: 2266.2239999999942,
    delay: 6201804.0,
    trkdelay: 367701.0,
    pti80: 1.14,
    trkpti80: 1.11,
    pti95: 1.18,
    trkpti95: 1.15,
    tti: 1.05,
    trktti: 1.03,
    ghg: 25168.0,
    congcost: 1.26254464e8,
    allspeed: 50.33,
    trkspeed: 58.4,
    ttivmt: 4011675.0,
    trkttivmt: 478356.0,
  },
  {
    agglevel: "mpo",
    regioncode: 140,
    year: 2018,
    count: 1842,
    len_sec: 885.738000000001,
    lanemiles: 2251.287999999999,
    delay: 4626851.0,
    trkdelay: 438509.0,
    pti80: 1.14,
    trkpti80: 1.11,
    pti95: 1.19,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.04,
    ghg: 21824.0,
    congcost: 1.01792072e8,
    allspeed: 53.23,
    trkspeed: 58.96,
    ttivmt: 3086664.0,
    trkttivmt: 461778.0,
  },
  {
    agglevel: "mpo",
    regioncode: 140,
    year: 2019,
    count: 824,
    len_sec: 632.7939999999993,
    lanemiles: 1719.9869999999987,
    delay: 4339930.0,
    trkdelay: 396045.0,
    pti80: 1.13,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.14,
    tti: 1.05,
    trktti: 1.04,
    ghg: 19893.0,
    congcost: 9.8603358e7,
    allspeed: 54.42,
    trkspeed: 58.72,
    ttivmt: 2910322.0,
    trkttivmt: 454558.0,
  },
  {
    agglevel: "mpo",
    regioncode: 140,
    year: 2020,
    count: 2739,
    len_sec: 891.6929999999982,
    lanemiles: 2268.8299999999927,
    delay: 6415640.0,
    trkdelay: 545977.0,
    pti80: 1.15,
    trkpti80: 1.12,
    pti95: 1.24,
    trkpti95: 1.24,
    tti: 1.06,
    trktti: 1.04,
    ghg: 31752.0,
    congcost: 1.49356233e8,
    allspeed: 52.34,
    trkspeed: 59.36,
    ttivmt: 3271539.0,
    trkttivmt: 487848.0,
  },
  {
    agglevel: "mpo",
    regioncode: 140,
    year: 2022,
    count: 2850,
    len_sec: 889.7069999999945,
    lanemiles: 2330.569999999991,
    delay: 6399284.0,
    trkdelay: 528146.0,
    pti80: 1.19,
    trkpti80: 1.16,
    pti95: 1.34,
    trkpti95: 1.34,
    tti: 1.08,
    trktti: 1.06,
    ghg: 30491.0,
    congcost: 1.6710055e8,
    allspeed: 51.46,
    trkspeed: 58.46,
    ttivmt: 2986156.0,
    trkttivmt: 387041.0,
  },
  {
    agglevel: "mpo",
    regioncode: 151,
    year: 2017,
    count: 923,
    len_sec: 346.5529999999998,
    lanemiles: 979.8119999999994,
    delay: 2471598.0,
    trkdelay: 85814.0,
    pti80: 1.13,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.14,
    tti: 1.06,
    trktti: 1.03,
    ghg: 9934.0,
    congcost: 4.8526366e7,
    allspeed: 45.81,
    trkspeed: 54.89,
    ttivmt: 1255630.0,
    trkttivmt: 104293.0,
  },
  {
    agglevel: "mpo",
    regioncode: 151,
    year: 2018,
    count: 843,
    len_sec: 345.8129999999998,
    lanemiles: 980.9249999999993,
    delay: 1549329.0,
    trkdelay: 105380.0,
    pti80: 1.11,
    trkpti80: 1.14,
    pti95: 1.15,
    trkpti95: 1.18,
    tti: 1.05,
    trktti: 1.05,
    ghg: 7361.0,
    congcost: 3.2862152e7,
    allspeed: 49.82,
    trkspeed: 53.03,
    ttivmt: 841107.0,
    trkttivmt: 73619.0,
  },
  {
    agglevel: "mpo",
    regioncode: 151,
    year: 2019,
    count: 611,
    len_sec: 340.90599999999955,
    lanemiles: 973.2629999999994,
    delay: 1878713.0,
    trkdelay: 144449.0,
    pti80: 1.13,
    trkpti80: 1.14,
    pti95: 1.16,
    trkpti95: 1.18,
    tti: 1.06,
    trktti: 1.06,
    ghg: 9540.0,
    congcost: 4.2063197e7,
    allspeed: 48.49,
    trkspeed: 51.24,
    ttivmt: 857657.0,
    trkttivmt: 80768.0,
  },
  {
    agglevel: "mpo",
    regioncode: 151,
    year: 2020,
    count: 1235,
    len_sec: 349.22499999999957,
    lanemiles: 982.4059999999985,
    delay: 2000920.0,
    trkdelay: 85026.0,
    pti80: 1.13,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.14,
    tti: 1.06,
    trktti: 1.04,
    ghg: 8886.0,
    congcost: 4.3404282e7,
    allspeed: 48.39,
    trkspeed: 55.01,
    ttivmt: 906433.0,
    trkttivmt: 65906.0,
  },
  {
    agglevel: "mpo",
    regioncode: 151,
    year: 2022,
    count: 1309,
    len_sec: 349.44499999999897,
    lanemiles: 983.4029999999991,
    delay: 1793295.0,
    trkdelay: 94281.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.17,
    trkpti95: 1.18,
    tti: 1.05,
    trktti: 1.04,
    ghg: 8350.0,
    congcost: 4.5029023e7,
    allspeed: 47.54,
    trkspeed: 54.81,
    ttivmt: 914982.0,
    trkttivmt: 78457.0,
  },
  {
    agglevel: "mpo",
    regioncode: 166,
    year: 2017,
    count: 944,
    len_sec: 459.13399999999996,
    lanemiles: 1206.0149999999994,
    delay: 3077983.0,
    trkdelay: 138628.0,
    pti80: 1.14,
    trkpti80: 1.12,
    pti95: 1.18,
    trkpti95: 1.16,
    tti: 1.06,
    trktti: 1.04,
    ghg: 13650.0,
    congcost: 6.1612854e7,
    allspeed: 45.13,
    trkspeed: 54.95,
    ttivmt: 1374421.0,
    trkttivmt: 136632.0,
  },
  {
    agglevel: "mpo",
    regioncode: 166,
    year: 2018,
    count: 934,
    len_sec: 456.97200000000043,
    lanemiles: 1202.0669999999986,
    delay: 2361351.0,
    trkdelay: 145658.0,
    pti80: 1.14,
    trkpti80: 1.13,
    pti95: 1.17,
    trkpti95: 1.17,
    tti: 1.06,
    trktti: 1.05,
    ghg: 11189.0,
    congcost: 4.9634538e7,
    allspeed: 48.72,
    trkspeed: 56.88,
    ttivmt: 1053701.0,
    trkttivmt: 114378.0,
  },
  {
    agglevel: "mpo",
    regioncode: 166,
    year: 2019,
    count: 616,
    len_sec: 441.7670000000002,
    lanemiles: 1184.5499999999997,
    delay: 2660376.0,
    trkdelay: 203362.0,
    pti80: 1.15,
    trkpti80: 1.15,
    pti95: 1.19,
    trkpti95: 1.2,
    tti: 1.07,
    trktti: 1.06,
    ghg: 13160.0,
    congcost: 5.9449589e7,
    allspeed: 47.62,
    trkspeed: 54.44,
    ttivmt: 1076448.0,
    trkttivmt: 117211.0,
  },
  {
    agglevel: "mpo",
    regioncode: 166,
    year: 2020,
    count: 1439,
    len_sec: 459.8539999999991,
    lanemiles: 1211.8709999999987,
    delay: 2634441.0,
    trkdelay: 180961.0,
    pti80: 1.15,
    trkpti80: 1.15,
    pti95: 1.19,
    trkpti95: 1.21,
    tti: 1.07,
    trktti: 1.05,
    ghg: 13131.0,
    congcost: 5.9874929e7,
    allspeed: 48.1,
    trkspeed: 55.09,
    ttivmt: 1110611.0,
    trkttivmt: 113631.0,
  },
  {
    agglevel: "mpo",
    regioncode: 166,
    year: 2022,
    count: 1461,
    len_sec: 459.64599999999956,
    lanemiles: 1201.5919999999965,
    delay: 2137749.0,
    trkdelay: 172140.0,
    pti80: 1.13,
    trkpti80: 1.13,
    pti95: 1.2,
    trkpti95: 1.21,
    tti: 1.05,
    trktti: 1.04,
    ghg: 10642.0,
    congcost: 5.5845266e7,
    allspeed: 47.07,
    trkspeed: 55.52,
    ttivmt: 1145525.0,
    trkttivmt: 130791.0,
  },
  {
    agglevel: "mpo",
    regioncode: 174,
    year: 2017,
    count: 1772,
    len_sec: 909.4519999999997,
    lanemiles: 2573.2329999999943,
    delay: 9384239.0,
    trkdelay: 457852.0,
    pti80: 1.14,
    trkpti80: 1.11,
    pti95: 1.18,
    trkpti95: 1.15,
    tti: 1.07,
    trktti: 1.04,
    ghg: 40224.0,
    congcost: 1.88562572e8,
    allspeed: 46.64,
    trkspeed: 54.17,
    ttivmt: 4802275.0,
    trkttivmt: 457286.0,
  },
  {
    agglevel: "mpo",
    regioncode: 174,
    year: 2018,
    count: 1445,
    len_sec: 907.082999999998,
    lanemiles: 2558.4669999999974,
    delay: 7864974.0,
    trkdelay: 590518.0,
    pti80: 1.16,
    trkpti80: 1.19,
    pti95: 1.19,
    trkpti95: 1.24,
    tti: 1.08,
    trktti: 1.09,
    ghg: 37892.0,
    congcost: 1.68595958e8,
    allspeed: 49.34,
    trkspeed: 54.17,
    ttivmt: 3200618.0,
    trkttivmt: 300000.0,
  },
  {
    agglevel: "mpo",
    regioncode: 174,
    year: 2019,
    count: 1093,
    len_sec: 892.5080000000002,
    lanemiles: 2540.2480000000005,
    delay: 9998942.0,
    trkdelay: 1074936.0,
    pti80: 1.21,
    trkpti80: 1.22,
    pti95: 1.24,
    trkpti95: 1.28,
    tti: 1.11,
    trktti: 1.11,
    ghg: 53410.0,
    congcost: 2.34371216e8,
    allspeed: 47.53,
    trkspeed: 50.65,
    ttivmt: 3447314.0,
    trkttivmt: 455123.0,
  },
  {
    agglevel: "mpo",
    regioncode: 174,
    year: 2020,
    count: 2269,
    len_sec: 905.6749999999961,
    lanemiles: 2564.7889999999934,
    delay: 1.0139087e7,
    trkdelay: 1262042.0,
    pti80: 1.2,
    trkpti80: 1.22,
    pti95: 1.23,
    trkpti95: 1.27,
    tti: 1.11,
    trktti: 1.11,
    ghg: 58295.0,
    congcost: 2.51878565e8,
    allspeed: 47.6,
    trkspeed: 52.99,
    ttivmt: 3557251.0,
    trkttivmt: 515919.0,
  },
  {
    agglevel: "mpo",
    regioncode: 174,
    year: 2022,
    count: 2289,
    len_sec: 904.5409999999964,
    lanemiles: 2563.2629999999895,
    delay: 6730975.0,
    trkdelay: 597330.0,
    pti80: 1.16,
    trkpti80: 1.13,
    pti95: 1.2,
    trkpti95: 1.19,
    tti: 1.07,
    trktti: 1.05,
    ghg: 35170.0,
    congcost: 1.7807466e8,
    allspeed: 47.62,
    trkspeed: 55.31,
    ttivmt: 3231758.0,
    trkttivmt: 481378.0,
  },
  {
    agglevel: "mpo",
    regioncode: 205,
    year: 2017,
    count: 1051,
    len_sec: 430.80700000000013,
    lanemiles: 1156.3410000000001,
    delay: 8242355.0,
    trkdelay: 332379.0,
    pti80: 1.24,
    trkpti80: 1.24,
    pti95: 1.3,
    trkpti95: 1.31,
    tti: 1.11,
    trktti: 1.09,
    ghg: 33589.0,
    congcost: 1.6321731e8,
    allspeed: 39.94,
    trkspeed: 43.8,
    ttivmt: 2486777.0,
    trkttivmt: 135814.0,
  },
  {
    agglevel: "mpo",
    regioncode: 205,
    year: 2018,
    count: 920,
    len_sec: 432.1979999999999,
    lanemiles: 1181.2120000000007,
    delay: 7291050.0,
    trkdelay: 573393.0,
    pti80: 1.29,
    trkpti80: 1.31,
    pti95: 1.35,
    trkpti95: 1.39,
    tti: 1.16,
    trktti: 1.15,
    ghg: 35078.0,
    congcost: 1.57032416e8,
    allspeed: 41.62,
    trkspeed: 47.11,
    ttivmt: 1483614.0,
    trkttivmt: 140011.0,
  },
  {
    agglevel: "mpo",
    regioncode: 205,
    year: 2019,
    count: 342,
    len_sec: 212.70400000000012,
    lanemiles: 724.4739999999999,
    delay: 7723853.0,
    trkdelay: 624386.0,
    pti80: 1.3,
    trkpti80: 1.28,
    pti95: 1.35,
    trkpti95: 1.35,
    tti: 1.17,
    trktti: 1.13,
    ghg: 38245.0,
    congcost: 1.7361842e8,
    allspeed: 40.02,
    trkspeed: 45.33,
    ttivmt: 1412687.0,
    trkttivmt: 147446.0,
  },
  {
    agglevel: "mpo",
    regioncode: 205,
    year: 2020,
    count: 1346,
    len_sec: 432.543999999999,
    lanemiles: 1179.945999999998,
    delay: 7904907.0,
    trkdelay: 625256.0,
    pti80: 1.26,
    trkpti80: 1.27,
    pti95: 1.32,
    trkpti95: 1.34,
    tti: 1.14,
    trktti: 1.13,
    ghg: 39791.0,
    congcost: 1.82540216e8,
    allspeed: 39.02,
    trkspeed: 47.26,
    ttivmt: 1680082.0,
    trkttivmt: 161194.0,
  },
  {
    agglevel: "mpo",
    regioncode: 205,
    year: 2022,
    count: 1415,
    len_sec: 435.9319999999991,
    lanemiles: 1194.4639999999956,
    delay: 7121658.0,
    trkdelay: 639097.0,
    pti80: 1.24,
    trkpti80: 1.22,
    pti95: 1.34,
    trkpti95: 1.34,
    tti: 1.12,
    trktti: 1.09,
    ghg: 35263.0,
    congcost: 1.88035397e8,
    allspeed: 38.25,
    trkspeed: 46.7,
    ttivmt: 1642741.0,
    trkttivmt: 183294.0,
  },
  {
    agglevel: "mpo",
    regioncode: 208,
    year: 2017,
    count: 596,
    len_sec: 247.0549999999997,
    lanemiles: 718.2479999999994,
    delay: 2599451.0,
    trkdelay: 139082.0,
    pti80: 1.17,
    trkpti80: 1.18,
    pti95: 1.2,
    trkpti95: 1.24,
    tti: 1.08,
    trktti: 1.07,
    ghg: 12031.0,
    congcost: 5.2769356e7,
    allspeed: 41.71,
    trkspeed: 46.01,
    ttivmt: 813682.0,
    trkttivmt: 57508.0,
  },
  {
    agglevel: "mpo",
    regioncode: 208,
    year: 2018,
    count: 526,
    len_sec: 244.66099999999977,
    lanemiles: 717.249,
    delay: 2378580.0,
    trkdelay: 116408.0,
    pti80: 1.17,
    trkpti80: 1.22,
    pti95: 1.2,
    trkpti95: 1.29,
    tti: 1.09,
    trktti: 1.1,
    ghg: 11044.0,
    congcost: 4.9039417e7,
    allspeed: 44.08,
    trkspeed: 47.89,
    ttivmt: 634691.0,
    trkttivmt: 39117.0,
  },
  {
    agglevel: "mpo",
    regioncode: 208,
    year: 2019,
    count: 306,
    len_sec: 188.2960000000001,
    lanemiles: 594.7679999999996,
    delay: 2301136.0,
    trkdelay: 150940.0,
    pti80: 1.18,
    trkpti80: 1.22,
    pti95: 1.2,
    trkpti95: 1.29,
    tti: 1.09,
    trktti: 1.1,
    ghg: 11350.0,
    congcost: 5.0616591e7,
    allspeed: 44.35,
    trkspeed: 45.27,
    ttivmt: 607601.0,
    trkttivmt: 43983.0,
  },
  {
    agglevel: "mpo",
    regioncode: 208,
    year: 2020,
    count: 824,
    len_sec: 248.314,
    lanemiles: 723.5549999999989,
    delay: 2437738.0,
    trkdelay: 150246.0,
    pti80: 1.17,
    trkpti80: 1.21,
    pti95: 1.2,
    trkpti95: 1.27,
    tti: 1.09,
    trktti: 1.11,
    ghg: 11901.0,
    congcost: 5.4747265e7,
    allspeed: 43.7,
    trkspeed: 46.67,
    ttivmt: 665776.0,
    trkttivmt: 41720.0,
  },
  {
    agglevel: "mpo",
    regioncode: 208,
    year: 2022,
    count: 825,
    len_sec: 245.71100000000018,
    lanemiles: 720.5550000000005,
    delay: 1994474.0,
    trkdelay: 125543.0,
    pti80: 1.16,
    trkpti80: 1.23,
    pti95: 1.23,
    trkpti95: 1.4,
    tti: 1.07,
    trktti: 1.1,
    ghg: 9560.0,
    congcost: 5.0827805e7,
    allspeed: 42.43,
    trkspeed: 45.06,
    ttivmt: 657288.0,
    trkttivmt: 37608.0,
  },
  {
    agglevel: "mpo",
    regioncode: 211,
    year: 2017,
    count: 734,
    len_sec: 256.15799999999984,
    lanemiles: 672.2739999999997,
    delay: 2140800.0,
    trkdelay: 65263.0,
    pti80: 1.15,
    trkpti80: 1.1,
    pti95: 1.2,
    trkpti95: 1.14,
    tti: 1.07,
    trktti: 1.03,
    ghg: 8864.0,
    congcost: 4.1807563e7,
    allspeed: 44.86,
    trkspeed: 55.86,
    ttivmt: 924381.0,
    trkttivmt: 84763.0,
  },
  {
    agglevel: "mpo",
    regioncode: 211,
    year: 2018,
    count: 630,
    len_sec: 250.6379999999998,
    lanemiles: 657.2030000000001,
    delay: 1393551.0,
    trkdelay: 93700.0,
    pti80: 1.14,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.15,
    tti: 1.07,
    trktti: 1.05,
    ghg: 6977.0,
    congcost: 2.959393e7,
    allspeed: 47.76,
    trkspeed: 56.06,
    ttivmt: 624808.0,
    trkttivmt: 75122.0,
  },
  {
    agglevel: "mpo",
    regioncode: 211,
    year: 2019,
    count: 473,
    len_sec: 251.16600000000003,
    lanemiles: 665.6450000000002,
    delay: 1736457.0,
    trkdelay: 135669.0,
    pti80: 1.16,
    trkpti80: 1.16,
    pti95: 1.2,
    trkpti95: 1.2,
    tti: 1.08,
    trktti: 1.07,
    ghg: 9181.0,
    congcost: 3.9035905e7,
    allspeed: 47.04,
    trkspeed: 53.08,
    ttivmt: 671026.0,
    trkttivmt: 73771.0,
  },
  {
    agglevel: "mpo",
    regioncode: 211,
    year: 2020,
    count: 1047,
    len_sec: 253.75999999999985,
    lanemiles: 670.0369999999991,
    delay: 1855530.0,
    trkdelay: 83083.0,
    pti80: 1.16,
    trkpti80: 1.11,
    pti95: 1.2,
    trkpti95: 1.15,
    tti: 1.08,
    trktti: 1.04,
    ghg: 8588.0,
    congcost: 4.0471449e7,
    allspeed: 46.54,
    trkspeed: 57.93,
    ttivmt: 677490.0,
    trkttivmt: 71112.0,
  },
  {
    agglevel: "mpo",
    regioncode: 211,
    year: 2022,
    count: 1077,
    len_sec: 255.12599999999998,
    lanemiles: 672.4619999999999,
    delay: 1579443.0,
    trkdelay: 78769.0,
    pti80: 1.15,
    trkpti80: 1.08,
    pti95: 1.2,
    trkpti95: 1.12,
    tti: 1.06,
    trktti: 1.03,
    ghg: 7397.0,
    congcost: 3.9521303e7,
    allspeed: 46.18,
    trkspeed: 58.85,
    ttivmt: 691392.0,
    trkttivmt: 93984.0,
  },
  {
    agglevel: "mpo",
    regioncode: 213,
    year: 2017,
    count: 1694,
    len_sec: 821.7399999999989,
    lanemiles: 2072.685999999997,
    delay: 9339952.0,
    trkdelay: 460981.0,
    pti80: 1.19,
    trkpti80: 1.17,
    pti95: 1.23,
    trkpti95: 1.22,
    tti: 1.09,
    trktti: 1.07,
    ghg: 41871.0,
    congcost: 1.8814865e8,
    allspeed: 43.03,
    trkspeed: 52.35,
    ttivmt: 3392776.0,
    trkttivmt: 265455.0,
  },
  {
    agglevel: "mpo",
    regioncode: 213,
    year: 2018,
    count: 1348,
    len_sec: 827.1489999999997,
    lanemiles: 2089.47,
    delay: 6848002.0,
    trkdelay: 600690.0,
    pti80: 1.2,
    trkpti80: 1.23,
    pti95: 1.24,
    trkpti95: 1.28,
    tti: 1.11,
    trktti: 1.11,
    ghg: 35126.0,
    congcost: 1.49758689e8,
    allspeed: 47.39,
    trkspeed: 51.66,
    ttivmt: 2321188.0,
    trkttivmt: 248032.0,
  },
  {
    agglevel: "mpo",
    regioncode: 213,
    year: 2019,
    count: 982,
    len_sec: 811.4639999999999,
    lanemiles: 2073.18,
    delay: 6735863.0,
    trkdelay: 613559.0,
    pti80: 1.19,
    trkpti80: 1.22,
    pti95: 1.22,
    trkpti95: 1.28,
    tti: 1.1,
    trktti: 1.1,
    ghg: 35466.0,
    congcost: 1.54156862e8,
    allspeed: 47.12,
    trkspeed: 50.71,
    ttivmt: 2429155.0,
    trkttivmt: 247764.0,
  },
  {
    agglevel: "mpo",
    regioncode: 213,
    year: 2020,
    count: 2887,
    len_sec: 837.3189999999962,
    lanemiles: 2099.285999999992,
    delay: 6531866.0,
    trkdelay: 551127.0,
    pti80: 1.17,
    trkpti80: 1.19,
    pti95: 1.21,
    trkpti95: 1.25,
    tti: 1.09,
    trktti: 1.09,
    ghg: 34552.0,
    congcost: 1.52436636e8,
    allspeed: 47.3,
    trkspeed: 53.24,
    ttivmt: 2483242.0,
    trkttivmt: 244037.0,
  },
  {
    agglevel: "mpo",
    regioncode: 213,
    year: 2022,
    count: 2989,
    len_sec: 838.8299999999949,
    lanemiles: 2107.201999999991,
    delay: 6240828.0,
    trkdelay: 482417.0,
    pti80: 1.19,
    trkpti80: 1.15,
    pti95: 1.25,
    trkpti95: 1.23,
    tti: 1.09,
    trktti: 1.06,
    ghg: 29906.0,
    congcost: 1.6194709e8,
    allspeed: 47.0,
    trkspeed: 54.73,
    ttivmt: 2475297.0,
    trkttivmt: 305853.0,
  },
  {
    agglevel: "mpo",
    regioncode: 230,
    year: 2017,
    count: 5200,
    len_sec: 2360.3020000000006,
    lanemiles: 6187.91199999998,
    delay: 3.6325666e7,
    trkdelay: 884662.0,
    pti80: 1.2,
    trkpti80: 1.17,
    pti95: 1.24,
    trkpti95: 1.22,
    tti: 1.1,
    trktti: 1.07,
    ghg: 145708.0,
    congcost: 7.02072781e8,
    allspeed: 41.04,
    trkspeed: 51.47,
    ttivmt: 1.173557e7,
    trkttivmt: 581927.0,
  },
  {
    agglevel: "mpo",
    regioncode: 230,
    year: 2018,
    count: 3973,
    len_sec: 2392.870000000002,
    lanemiles: 6267.395000000003,
    delay: 3.0506818e7,
    trkdelay: 1222329.0,
    pti80: 1.23,
    trkpti80: 1.25,
    pti95: 1.28,
    trkpti95: 1.32,
    tti: 1.12,
    trktti: 1.11,
    ghg: 133840.0,
    congcost: 6.18994108e8,
    allspeed: 42.43,
    trkspeed: 48.17,
    ttivmt: 8421017.0,
    trkttivmt: 449233.0,
  },
  {
    agglevel: "mpo",
    regioncode: 230,
    year: 2019,
    count: 2182,
    len_sec: 1489.1759999999997,
    lanemiles: 4416.047999999994,
    delay: 2.7393263e7,
    trkdelay: 1162583.0,
    pti80: 1.22,
    trkpti80: 1.22,
    pti95: 1.26,
    trkpti95: 1.28,
    tti: 1.12,
    trktti: 1.1,
    ghg: 124366.0,
    congcost: 5.79352025e8,
    allspeed: 43.36,
    trkspeed: 49.49,
    ttivmt: 7678622.0,
    trkttivmt: 434105.0,
  },
  {
    agglevel: "mpo",
    regioncode: 230,
    year: 2020,
    count: 6489,
    len_sec: 2424.053999999992,
    lanemiles: 6409.828000000002,
    delay: 3.1944676e7,
    trkdelay: 1297625.0,
    pti80: 1.22,
    trkpti80: 1.22,
    pti95: 1.27,
    trkpti95: 1.29,
    tti: 1.11,
    trktti: 1.11,
    ghg: 145788.0,
    congcost: 6.91627654e8,
    allspeed: 42.2,
    trkspeed: 48.98,
    ttivmt: 9262931.0,
    trkttivmt: 450516.0,
  },
  {
    agglevel: "mpo",
    regioncode: 230,
    year: 2022,
    count: 7690,
    len_sec: 2692.118000000009,
    lanemiles: 7134.5650000000305,
    delay: 2.8162302e7,
    trkdelay: 1535975.0,
    pti80: 1.19,
    trkpti80: 1.22,
    pti95: 1.26,
    trkpti95: 1.35,
    tti: 1.09,
    trktti: 1.09,
    ghg: 133113.0,
    congcost: 7.09335655e8,
    allspeed: 42.38,
    trkspeed: 48.9,
    ttivmt: 9640134.0,
    trkttivmt: 565413.0,
  },
  {
    agglevel: "mpo",
    regioncode: 232,
    year: 2017,
    count: 1112,
    len_sec: 418.2829999999996,
    lanemiles: 992.8389999999996,
    delay: 2003144.0,
    trkdelay: 92249.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.15,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.02,
    ghg: 7357.0,
    congcost: 3.9861289e7,
    allspeed: 53.74,
    trkspeed: 58.77,
    ttivmt: 1548782.0,
    trkttivmt: 145239.0,
  },
  {
    agglevel: "mpo",
    regioncode: 232,
    year: 2018,
    count: 895,
    len_sec: 405.7540000000001,
    lanemiles: 964.9319999999987,
    delay: 1400533.0,
    trkdelay: 91898.0,
    pti80: 1.11,
    trkpti80: 1.09,
    pti95: 1.13,
    trkpti95: 1.11,
    tti: 1.04,
    trktti: 1.03,
    ghg: 5996.0,
    congcost: 2.9459018e7,
    allspeed: 56.05,
    trkspeed: 59.7,
    ttivmt: 1139352.0,
    trkttivmt: 122614.0,
  },
  {
    agglevel: "mpo",
    regioncode: 232,
    year: 2019,
    count: 699,
    len_sec: 596.835,
    lanemiles: 1388.2840000000008,
    delay: 1768900.0,
    trkdelay: 153577.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.14,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.04,
    ghg: 8094.0,
    congcost: 3.9934471e7,
    allspeed: 56.34,
    trkspeed: 57.27,
    ttivmt: 1424489.0,
    trkttivmt: 133005.0,
  },
  {
    agglevel: "mpo",
    regioncode: 232,
    year: 2020,
    count: 2176,
    len_sec: 612.5029999999983,
    lanemiles: 1415.5099999999925,
    delay: 2042082.0,
    trkdelay: 139902.0,
    pti80: 1.11,
    trkpti80: 1.1,
    pti95: 1.14,
    trkpti95: 1.13,
    tti: 1.04,
    trktti: 1.03,
    ghg: 8684.0,
    congcost: 4.6058444e7,
    allspeed: 56.84,
    trkspeed: 60.1,
    ttivmt: 1599080.0,
    trkttivmt: 155228.0,
  },
  {
    agglevel: "mpo",
    regioncode: 232,
    year: 2022,
    count: 2195,
    len_sec: 611.8089999999964,
    lanemiles: 1417.275999999995,
    delay: 1903491.0,
    trkdelay: 150302.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.18,
    trkpti95: 1.16,
    tti: 1.04,
    trktti: 1.03,
    ghg: 7753.0,
    congcost: 4.9128786e7,
    allspeed: 56.13,
    trkspeed: 59.51,
    ttivmt: 1620898.0,
    trkttivmt: 178540.0,
  },
  {
    agglevel: "mpo",
    regioncode: 249,
    year: 2017,
    count: 1213,
    len_sec: 608.0770000000002,
    lanemiles: 1541.2289999999998,
    delay: 8010875.0,
    trkdelay: 239638.0,
    pti80: 1.21,
    trkpti80: 1.16,
    pti95: 1.27,
    trkpti95: 1.22,
    tti: 1.11,
    trktti: 1.06,
    ghg: 34260.0,
    congcost: 1.56541463e8,
    allspeed: 43.38,
    trkspeed: 51.71,
    ttivmt: 2473142.0,
    trkttivmt: 154663.0,
  },
  {
    agglevel: "mpo",
    regioncode: 249,
    year: 2018,
    count: 1112,
    len_sec: 605.0469999999999,
    lanemiles: 1546.7270000000005,
    delay: 6171422.0,
    trkdelay: 341635.0,
    pti80: 1.23,
    trkpti80: 1.23,
    pti95: 1.29,
    trkpti95: 1.31,
    tti: 1.12,
    trktti: 1.1,
    ghg: 29891.0,
    congcost: 1.28633493e8,
    allspeed: 46.6,
    trkspeed: 51.59,
    ttivmt: 1795283.0,
    trkttivmt: 131352.0,
  },
  {
    agglevel: "mpo",
    regioncode: 249,
    year: 2019,
    count: 479,
    len_sec: 389.8529999999994,
    lanemiles: 1109.6059999999998,
    delay: 6495684.0,
    trkdelay: 386028.0,
    pti80: 1.23,
    trkpti80: 1.22,
    pti95: 1.28,
    trkpti95: 1.28,
    tti: 1.11,
    trktti: 1.1,
    ghg: 31887.0,
    congcost: 1.41510166e8,
    allspeed: 47.07,
    trkspeed: 50.86,
    ttivmt: 1823974.0,
    trkttivmt: 138814.0,
  },
  {
    agglevel: "mpo",
    regioncode: 249,
    year: 2020,
    count: 1792,
    len_sec: 606.9010000000002,
    lanemiles: 1545.8079999999975,
    delay: 5887934.0,
    trkdelay: 275426.0,
    pti80: 1.2,
    trkpti80: 1.17,
    pti95: 1.25,
    trkpti95: 1.22,
    tti: 1.1,
    trktti: 1.08,
    ghg: 27342.0,
    congcost: 1.2885005e8,
    allspeed: 46.42,
    trkspeed: 54.1,
    ttivmt: 2010699.0,
    trkttivmt: 139999.0,
  },
  {
    agglevel: "mpo",
    regioncode: 249,
    year: 2022,
    count: 1822,
    len_sec: 601.5189999999982,
    lanemiles: 1542.2309999999982,
    delay: 3999019.0,
    trkdelay: 197827.0,
    pti80: 1.17,
    trkpti80: 1.15,
    pti95: 1.24,
    trkpti95: 1.25,
    tti: 1.07,
    trktti: 1.05,
    ghg: 18641.0,
    congcost: 9.9992536e7,
    allspeed: 46.84,
    trkspeed: 54.08,
    ttivmt: 1779109.0,
    trkttivmt: 131101.0,
  },
  {
    agglevel: "mpo",
    regioncode: 277,
    year: 2017,
    count: 1884,
    len_sec: 1005.1670000000008,
    lanemiles: 2530.9899999999984,
    delay: 9576479.0,
    trkdelay: 447198.0,
    pti80: 1.16,
    trkpti80: 1.11,
    pti95: 1.22,
    trkpti95: 1.2,
    tti: 1.07,
    trktti: 1.04,
    ghg: 37814.0,
    congcost: 1.91220242e8,
    allspeed: 47.94,
    trkspeed: 57.01,
    ttivmt: 4927534.0,
    trkttivmt: 506024.0,
  },
  {
    agglevel: "mpo",
    regioncode: 277,
    year: 2018,
    count: 1852,
    len_sec: 1009.475999999998,
    lanemiles: 2554.007999999998,
    delay: 7526249.0,
    trkdelay: 444835.0,
    pti80: 1.16,
    trkpti80: 1.13,
    pti95: 1.22,
    trkpti95: 1.21,
    tti: 1.07,
    trktti: 1.05,
    ghg: 31462.0,
    congcost: 1.56678794e8,
    allspeed: 52.18,
    trkspeed: 57.6,
    ttivmt: 3741694.0,
    trkttivmt: 369965.0,
  },
  {
    agglevel: "mpo",
    regioncode: 277,
    year: 2019,
    count: 747,
    len_sec: 623.3350000000004,
    lanemiles: 1724.352999999999,
    delay: 5884956.0,
    trkdelay: 468823.0,
    pti80: 1.14,
    trkpti80: 1.13,
    pti95: 1.19,
    trkpti95: 1.2,
    tti: 1.06,
    trktti: 1.05,
    ghg: 26609.0,
    congcost: 1.31478607e8,
    allspeed: 54.07,
    trkspeed: 55.22,
    ttivmt: 3440105.0,
    trkttivmt: 359072.0,
  },
  {
    agglevel: "mpo",
    regioncode: 277,
    year: 2020,
    count: 2961,
    len_sec: 1011.2219999999962,
    lanemiles: 2565.4309999999896,
    delay: 6746410.0,
    trkdelay: 378033.0,
    pti80: 1.13,
    trkpti80: 1.11,
    pti95: 1.18,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.04,
    ghg: 29814.0,
    congcost: 1.4945881e8,
    allspeed: 52.42,
    trkspeed: 59.33,
    ttivmt: 4050722.0,
    trkttivmt: 423811.0,
  },
  {
    agglevel: "mpo",
    regioncode: 277,
    year: 2022,
    count: 3092,
    len_sec: 1013.127999999995,
    lanemiles: 2655.0059999999967,
    delay: 6896142.0,
    trkdelay: 405166.0,
    pti80: 1.15,
    trkpti80: 1.13,
    pti95: 1.23,
    trkpti95: 1.24,
    tti: 1.06,
    trktti: 1.05,
    ghg: 30451.0,
    congcost: 1.74062774e8,
    allspeed: 52.02,
    trkspeed: 58.44,
    ttivmt: 3903636.0,
    trkttivmt: 352662.0,
  },
  {
    agglevel: "mpo",
    regioncode: 282,
    year: 2017,
    count: 22692,
    len_sec: 12095.404000000017,
    lanemiles: 36101.846000000274,
    delay: 2.68455147e8,
    trkdelay: 1.530874e7,
    pti80: 1.39,
    trkpti80: 1.43,
    pti95: 1.5,
    trkpti95: 1.55,
    tti: 1.19,
    trktti: 1.2,
    ghg: 1077675.0,
    congcost: 5.444660435e9,
    allspeed: 45.1,
    trkspeed: 51.54,
    ttivmt: 8.7412837e7,
    trkttivmt: 7121440.0,
  },
  {
    agglevel: "mpo",
    regioncode: 282,
    year: 2018,
    count: 23048,
    len_sec: 12305.888000000126,
    lanemiles: 36844.88500000023,
    delay: 2.74845861e8,
    trkdelay: 1.4081595e7,
    pti80: 1.46,
    trkpti80: 1.46,
    pti95: 1.57,
    trkpti95: 1.59,
    tti: 1.24,
    trktti: 1.23,
    ghg: 1113737.0,
    congcost: 5.648621931e9,
    allspeed: 44.23,
    trkspeed: 49.42,
    ttivmt: 7.1325086e7,
    trkttivmt: 4709246.0,
  },
  {
    agglevel: "mpo",
    regioncode: 282,
    year: 2019,
    count: 15315,
    len_sec: 11808.488000000129,
    lanemiles: 36201.5270000004,
    delay: 2.47244153e8,
    trkdelay: 1.287813e7,
    pti80: 1.41,
    trkpti80: 1.4,
    pti95: 1.51,
    trkpti95: 1.52,
    tti: 1.21,
    trktti: 1.19,
    ghg: 981248.0,
    congcost: 5.268272388e9,
    allspeed: 44.96,
    trkspeed: 49.28,
    ttivmt: 7.0273121e7,
    trkttivmt: 4599743.0,
  },
  {
    agglevel: "mpo",
    regioncode: 282,
    year: 2020,
    count: 33890,
    len_sec: 12201.262000000334,
    lanemiles: 36889.24000000098,
    delay: 2.50382758e8,
    trkdelay: 1.1571175e7,
    pti80: 1.41,
    trkpti80: 1.39,
    pti95: 1.52,
    trkpti95: 1.51,
    tti: 1.21,
    trktti: 1.19,
    ghg: 961329.0,
    congcost: 5.42637822e9,
    allspeed: 45.37,
    trkspeed: 51.52,
    ttivmt: 7.3112154e7,
    trkttivmt: 4604167.0,
  },
  {
    agglevel: "mpo",
    regioncode: 282,
    year: 2022,
    count: 37828,
    len_sec: 12351.224000000508,
    lanemiles: 37687.02600000147,
    delay: 2.02068698e8,
    trkdelay: 1.1802611e7,
    pti80: 1.3,
    trkpti80: 1.31,
    pti95: 1.37,
    trkpti95: 1.43,
    tti: 1.15,
    trktti: 1.14,
    ghg: 800146.0,
    congcost: 5.069979175e9,
    allspeed: 46.68,
    trkspeed: 53.04,
    ttivmt: 7.3944876e7,
    trkttivmt: 5242431.0,
  },
  {
    agglevel: "mpo",
    regioncode: 361,
    year: 2017,
    count: 860,
    len_sec: 368.9389999999999,
    lanemiles: 1079.7799999999995,
    delay: 4710275.0,
    trkdelay: 124682.0,
    pti80: 1.17,
    trkpti80: 1.14,
    pti95: 1.21,
    trkpti95: 1.19,
    tti: 1.08,
    trktti: 1.05,
    ghg: 18191.0,
    congcost: 9.1173339e7,
    allspeed: 40.77,
    trkspeed: 51.98,
    ttivmt: 1908584.0,
    trkttivmt: 101247.0,
  },
  {
    agglevel: "mpo",
    regioncode: 361,
    year: 2018,
    count: 697,
    len_sec: 371.57299999999924,
    lanemiles: 1087.7469999999985,
    delay: 3152070.0,
    trkdelay: 189154.0,
    pti80: 1.18,
    trkpti80: 1.19,
    pti95: 1.21,
    trkpti95: 1.24,
    tti: 1.1,
    trktti: 1.08,
    ghg: 14151.0,
    congcost: 6.5888179e7,
    allspeed: 46.12,
    trkspeed: 53.15,
    ttivmt: 1188697.0,
    trkttivmt: 98120.0,
  },
  {
    agglevel: "mpo",
    regioncode: 361,
    year: 2019,
    count: 526,
    len_sec: 364.793,
    lanemiles: 1084.4899999999986,
    delay: 3835930.0,
    trkdelay: 240678.0,
    pti80: 1.19,
    trkpti80: 1.2,
    pti95: 1.22,
    trkpti95: 1.26,
    tti: 1.1,
    trktti: 1.09,
    ghg: 18235.0,
    congcost: 8.3820104e7,
    allspeed: 44.36,
    trkspeed: 50.65,
    ttivmt: 1287945.0,
    trkttivmt: 105568.0,
  },
  {
    agglevel: "mpo",
    regioncode: 361,
    year: 2020,
    count: 1412,
    len_sec: 374.23499999999916,
    lanemiles: 1097.3549999999987,
    delay: 3723861.0,
    trkdelay: 209972.0,
    pti80: 1.17,
    trkpti80: 1.18,
    pti95: 1.21,
    trkpti95: 1.24,
    tti: 1.1,
    trktti: 1.08,
    ghg: 17121.0,
    congcost: 8.2693386e7,
    allspeed: 44.47,
    trkspeed: 52.8,
    ttivmt: 1358405.0,
    trkttivmt: 104370.0,
  },
  {
    agglevel: "mpo",
    regioncode: 361,
    year: 2022,
    count: 1417,
    len_sec: 372.15099999999904,
    lanemiles: 1095.2509999999959,
    delay: 3699133.0,
    trkdelay: 175320.0,
    pti80: 1.18,
    trkpti80: 1.14,
    pti95: 1.25,
    trkpti95: 1.21,
    tti: 1.08,
    trktti: 1.05,
    ghg: 17335.0,
    congcost: 9.2258764e7,
    allspeed: 43.67,
    trkspeed: 54.1,
    ttivmt: 1326959.0,
    trkttivmt: 129535.0,
  },
  {
    agglevel: "mpo",
    regioncode: 363,
    year: 2017,
    count: 537,
    len_sec: 362.1589999999999,
    lanemiles: 1083.008999999999,
    delay: 2574015.0,
    trkdelay: 106052.0,
    pti80: 1.14,
    trkpti80: 1.1,
    pti95: 1.18,
    trkpti95: 1.14,
    tti: 1.06,
    trktti: 1.02,
    ghg: 10921.0,
    congcost: 5.1143028e7,
    allspeed: 48.94,
    trkspeed: 59.24,
    ttivmt: 1299110.0,
    trkttivmt: 181289.0,
  },
  {
    agglevel: "mpo",
    regioncode: 363,
    year: 2018,
    count: 545,
    len_sec: 368.8029999999997,
    lanemiles: 1089.0870000000004,
    delay: 2449297.0,
    trkdelay: 227489.0,
    pti80: 1.15,
    trkpti80: 1.16,
    pti95: 1.19,
    trkpti95: 1.2,
    tti: 1.07,
    trktti: 1.07,
    ghg: 13499.0,
    congcost: 5.4173008e7,
    allspeed: 50.94,
    trkspeed: 55.61,
    ttivmt: 1013128.0,
    trkttivmt: 134264.0,
  },
  {
    agglevel: "mpo",
    regioncode: 363,
    year: 2019,
    count: 241,
    len_sec: 223.04299999999998,
    lanemiles: 762.6760000000003,
    delay: 1640725.0,
    trkdelay: 197991.0,
    pti80: 1.14,
    trkpti80: 1.13,
    pti95: 1.17,
    trkpti95: 1.17,
    tti: 1.05,
    trktti: 1.05,
    ghg: 9655.0,
    congcost: 3.93857e7,
    allspeed: 52.97,
    trkspeed: 55.83,
    ttivmt: 912434.0,
    trkttivmt: 139566.0,
  },
  {
    agglevel: "mpo",
    regioncode: 363,
    year: 2020,
    count: 928,
    len_sec: 368.9449999999995,
    lanemiles: 1096.5449999999992,
    delay: 2076869.0,
    trkdelay: 132196.0,
    pti80: 1.13,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.16,
    tti: 1.06,
    trktti: 1.04,
    ghg: 10285.0,
    congcost: 4.6830642e7,
    allspeed: 51.19,
    trkspeed: 59.25,
    ttivmt: 1068892.0,
    trkttivmt: 131406.0,
  },
  {
    agglevel: "mpo",
    regioncode: 363,
    year: 2022,
    count: 956,
    len_sec: 376.28299999999956,
    lanemiles: 1113.046,
    delay: 2083603.0,
    trkdelay: 137108.0,
    pti80: 1.13,
    trkpti80: 1.11,
    pti95: 1.19,
    trkpti95: 1.18,
    tti: 1.05,
    trktti: 1.03,
    ghg: 10201.0,
    congcost: 5.3365824e7,
    allspeed: 51.69,
    trkspeed: 58.44,
    ttivmt: 1208974.0,
    trkttivmt: 150451.0,
  },
  {
    agglevel: "region",
    regioncode: 1,
    year: 2017,
    count: 53705,
    len_sec: 29681.0790000002,
    lanemiles: 84523.85399999906,
    delay: 7.21070647e8,
    trkdelay: 4.3851477e7,
    pti80: 1.42,
    trkpti80: 1.46,
    pti95: 1.53,
    trkpti95: 1.6,
    tti: 1.2,
    trktti: 1.21,
    ghg: 2967964.0,
    congcost: 1.4718707432e10,
    allspeed: 44.4,
    trkspeed: 51.39,
    ttivmt: 2.12817328e8,
    trkttivmt: 1.8390639e7,
  },
  {
    agglevel: "region",
    regioncode: 1,
    year: 2018,
    count: 54881,
    len_sec: 30215.22400000071,
    lanemiles: 86403.76699999886,
    delay: 7.45589221e8,
    trkdelay: 3.5685025e7,
    pti80: 1.5,
    trkpti80: 1.46,
    pti95: 1.62,
    trkpti95: 1.6,
    tti: 1.26,
    trktti: 1.23,
    ghg: 2979535.0,
    congcost: 1.5238792734e10,
    allspeed: 44.18,
    trkspeed: 50.19,
    ttivmt: 1.73700062e8,
    trkttivmt: 1.173949e7,
  },
  {
    agglevel: "region",
    regioncode: 1,
    year: 2019,
    count: 32194,
    len_sec: 25127.39200000053,
    lanemiles: 75910.02200000019,
    delay: 6.6583259e8,
    trkdelay: 3.444732e7,
    pti80: 1.44,
    trkpti80: 1.4,
    pti95: 1.55,
    trkpti95: 1.52,
    tti: 1.23,
    trktti: 1.19,
    ghg: 2665760.0,
    congcost: 1.4185954925e10,
    allspeed: 45.27,
    trkspeed: 49.96,
    ttivmt: 1.72549797e8,
    trkttivmt: 1.1980736e7,
  },
  {
    agglevel: "region",
    regioncode: 1,
    year: 2020,
    count: 82506,
    len_sec: 30123.29200000261,
    lanemiles: 86866.29199999878,
    delay: 6.9210978e8,
    trkdelay: 3.2705529e7,
    pti80: 1.44,
    trkpti80: 1.38,
    pti95: 1.56,
    trkpti95: 1.51,
    tti: 1.23,
    trktti: 1.18,
    ghg: 2723497.0,
    congcost: 1.5039912555e10,
    allspeed: 45.23,
    trkspeed: 52.09,
    ttivmt: 1.81717662e8,
    trkttivmt: 1.2389309e7,
  },
  {
    agglevel: "region",
    regioncode: 1,
    year: 2021,
    count: 85584,
    len_sec: 30208.607000002572,
    lanemiles: 87425.69899999506,
    delay: 3.43143624e8,
    trkdelay: 2.1707e7,
    pti80: 1.24,
    trkpti80: 1.25,
    pti95: 1.31,
    trkpti95: 1.36,
    tti: 1.12,
    trktti: 1.11,
    ghg: 1411532.0,
    congcost: 7.841868036e9,
    allspeed: 47.56,
    trkspeed: 53.87,
    ttivmt: 1.61842463e8,
    trkttivmt: 1.137097e7,
  },
  {
    agglevel: "region",
    regioncode: 1,
    year: 2022,
    count: 90858,
    len_sec: 30507.190000002778,
    lanemiles: 89089.7399999979,
    delay: 5.04627331e8,
    trkdelay: 2.9642793e7,
    pti80: 1.3,
    trkpti80: 1.3,
    pti95: 1.38,
    trkpti95: 1.42,
    tti: 1.15,
    trktti: 1.14,
    ghg: 2006391.0,
    congcost: 1.2669783005e10,
    allspeed: 47.02,
    trkspeed: 53.2,
    ttivmt: 1.7699151e8,
    trkttivmt: 1.294994e7,
  },
  {
    agglevel: "region",
    regioncode: 2,
    year: 2017,
    count: 11646,
    len_sec: 7417.217000000005,
    lanemiles: 18515.385999999995,
    delay: 7.6205151e7,
    trkdelay: 2607040.0,
    pti80: 1.21,
    trkpti80: 1.14,
    pti95: 1.27,
    trkpti95: 1.19,
    tti: 1.1,
    trktti: 1.05,
    ghg: 311566.0,
    congcost: 1.495888573e9,
    allspeed: 42.91,
    trkspeed: 56.27,
    ttivmt: 2.6674e7,
    trkttivmt: 2685289.0,
  },
  {
    agglevel: "region",
    regioncode: 2,
    year: 2018,
    count: 10802,
    len_sec: 7451.681999999987,
    lanemiles: 18662.486000000055,
    delay: 6.4549376e7,
    trkdelay: 3247143.0,
    pti80: 1.23,
    trkpti80: 1.21,
    pti95: 1.29,
    trkpti95: 1.28,
    tti: 1.12,
    trktti: 1.09,
    ghg: 284751.0,
    congcost: 1.329880213e9,
    allspeed: 44.84,
    trkspeed: 53.02,
    ttivmt: 1.9455043e7,
    trkttivmt: 1588502.0,
  },
  {
    agglevel: "region",
    regioncode: 2,
    year: 2019,
    count: 5130,
    len_sec: 5255.653000000002,
    lanemiles: 14180.776000000025,
    delay: 6.3471398e7,
    trkdelay: 3762479.0,
    pti80: 1.22,
    trkpti80: 1.19,
    pti95: 1.27,
    trkpti95: 1.25,
    tti: 1.11,
    trktti: 1.08,
    ghg: 294393.0,
    congcost: 1.378084913e9,
    allspeed: 45.52,
    trkspeed: 52.3,
    ttivmt: 1.9087795e7,
    trkttivmt: 1713271.0,
  },
  {
    agglevel: "region",
    regioncode: 2,
    year: 2020,
    count: 16662,
    len_sec: 7535.21600000006,
    lanemiles: 18949.22900000022,
    delay: 6.9364051e7,
    trkdelay: 3523419.0,
    pti80: 1.21,
    trkpti80: 1.17,
    pti95: 1.26,
    trkpti95: 1.23,
    tti: 1.1,
    trktti: 1.07,
    ghg: 315645.0,
    congcost: 1.526041934e9,
    allspeed: 44.85,
    trkspeed: 54.39,
    ttivmt: 2.1659962e7,
    trkttivmt: 1791872.0,
  },
  {
    agglevel: "region",
    regioncode: 2,
    year: 2021,
    count: 17044,
    len_sec: 7550.2620000001325,
    lanemiles: 18999.03300000036,
    delay: 4.1989098e7,
    trkdelay: 2726357.0,
    pti80: 1.16,
    trkpti80: 1.15,
    pti95: 1.21,
    trkpti95: 1.23,
    tti: 1.07,
    trktti: 1.06,
    ghg: 198748.0,
    congcost: 9.66900236e8,
    allspeed: 45.63,
    trkspeed: 55.33,
    ttivmt: 1.8971013e7,
    trkttivmt: 1721395.0,
  },
  {
    agglevel: "region",
    regioncode: 2,
    year: 2022,
    count: 17611,
    len_sec: 7573.243000000153,
    lanemiles: 19126.35400000045,
    delay: 5.5864367e7,
    trkdelay: 3657210.0,
    pti80: 1.19,
    trkpti80: 1.17,
    pti95: 1.25,
    trkpti95: 1.27,
    tti: 1.08,
    trktti: 1.06,
    ghg: 258981.0,
    congcost: 1.425712225e9,
    allspeed: 44.73,
    trkspeed: 54.36,
    ttivmt: 2.0769622e7,
    trkttivmt: 1943736.0,
  },
  {
    agglevel: "region",
    regioncode: 3,
    year: 2017,
    count: 18593,
    len_sec: 18681.77800000003,
    lanemiles: 42786.25900000026,
    delay: 2.9837563e7,
    trkdelay: 1963620.0,
    pti80: 1.12,
    trkpti80: 1.08,
    pti95: 1.16,
    trkpti95: 1.12,
    tti: 1.04,
    trktti: 1.01,
    ghg: 124223.0,
    congcost: 6.13895166e8,
    allspeed: 52.26,
    trkspeed: 62.02,
    ttivmt: 2.1865472e7,
    trkttivmt: 5756678.0,
  },
  {
    agglevel: "region",
    regioncode: 3,
    year: 2018,
    count: 19564,
    len_sec: 18666.94500000022,
    lanemiles: 42890.89000000042,
    delay: 2.413599e7,
    trkdelay: 2264739.0,
    pti80: 1.12,
    trkpti80: 1.11,
    pti95: 1.15,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.03,
    ghg: 115740.0,
    congcost: 5.31044066e8,
    allspeed: 55.27,
    trkspeed: 59.91,
    ttivmt: 1.8607009e7,
    trkttivmt: 2995237.0,
  },
  {
    agglevel: "region",
    regioncode: 3,
    year: 2019,
    count: 10130,
    len_sec: 17115.08000000008,
    lanemiles: 39772.33500000026,
    delay: 2.7015853e7,
    trkdelay: 3463845.0,
    pti80: 1.13,
    trkpti80: 1.12,
    pti95: 1.17,
    trkpti95: 1.15,
    tti: 1.05,
    trktti: 1.04,
    ghg: 142916.0,
    congcost: 6.51076214e8,
    allspeed: 55.92,
    trkspeed: 58.6,
    ttivmt: 1.9747695e7,
    trkttivmt: 3708438.0,
  },
  {
    agglevel: "region",
    regioncode: 3,
    year: 2020,
    count: 27738,
    len_sec: 18478.309000000256,
    lanemiles: 42643.050000001,
    delay: 2.8219704e7,
    trkdelay: 3459584.0,
    pti80: 1.13,
    trkpti80: 1.12,
    pti95: 1.16,
    trkpti95: 1.16,
    tti: 1.05,
    trktti: 1.04,
    ghg: 150645.0,
    congcost: 6.96781328e8,
    allspeed: 55.69,
    trkspeed: 60.19,
    ttivmt: 2.0403695e7,
    trkttivmt: 3827604.0,
  },
  {
    agglevel: "region",
    regioncode: 3,
    year: 2021,
    count: 27538,
    len_sec: 18488.775000000365,
    lanemiles: 42672.64200000104,
    delay: 1.8959354e7,
    trkdelay: 2774190.0,
    pti80: 1.11,
    trkpti80: 1.11,
    pti95: 1.16,
    trkpti95: 1.17,
    tti: 1.04,
    trktti: 1.03,
    ghg: 103399.0,
    congcost: 4.8414645e8,
    allspeed: 55.48,
    trkspeed: 60.56,
    ttivmt: 1.8005504e7,
    trkttivmt: 3507030.0,
  },
  {
    agglevel: "region",
    regioncode: 3,
    year: 2022,
    count: 28042,
    len_sec: 18517.657000000378,
    lanemiles: 42720.99600000105,
    delay: 2.2736988e7,
    trkdelay: 2679562.0,
    pti80: 1.12,
    trkpti80: 1.1,
    pti95: 1.17,
    trkpti95: 1.15,
    tti: 1.04,
    trktti: 1.03,
    ghg: 111178.0,
    congcost: 6.21588723e8,
    allspeed: 55.11,
    trkspeed: 60.51,
    ttivmt: 1.94102e7,
    trkttivmt: 3842024.0,
  },
  {
    agglevel: "region",
    regioncode: 4,
    year: 2017,
    count: 16582,
    len_sec: 9722.157000000068,
    lanemiles: 27395.302000000098,
    delay: 2.83003566e8,
    trkdelay: 1.9540196e7,
    pti80: 1.44,
    trkpti80: 1.54,
    pti95: 1.56,
    trkpti95: 1.7,
    tti: 1.22,
    trktti: 1.26,
    ghg: 1210955.0,
    congcost: 5.854077809e9,
    allspeed: 43.48,
    trkspeed: 48.98,
    ttivmt: 7.4872534e7,
    trkttivmt: 6561776.0,
  },
  {
    agglevel: "region",
    regioncode: 4,
    year: 2018,
    count: 17472,
    len_sec: 10059.971000000081,
    lanemiles: 28376.575000000306,
    delay: 3.08556332e8,
    trkdelay: 1.4293617e7,
    pti80: 1.53,
    trkpti80: 1.52,
    pti95: 1.65,
    trkpti95: 1.66,
    tti: 1.29,
    trktti: 1.26,
    ghg: 1236166.0,
    congcost: 6.2929371e9,
    allspeed: 43.03,
    trkspeed: 48.56,
    ttivmt: 6.2718753e7,
    trkttivmt: 3949654.0,
  },
  {
    agglevel: "region",
    regioncode: 4,
    year: 2019,
    count: 9246,
    len_sec: 7233.042000000039,
    lanemiles: 22567.84600000013,
    delay: 2.66487351e8,
    trkdelay: 1.3968813e7,
    pti80: 1.46,
    trkpti80: 1.44,
    pti95: 1.57,
    trkpti95: 1.56,
    tti: 1.25,
    trktti: 1.22,
    ghg: 1088653.0,
    congcost: 5.689193237e9,
    allspeed: 44.41,
    trkspeed: 48.59,
    ttivmt: 6.1246428e7,
    trkttivmt: 4153934.0,
  },
  {
    agglevel: "region",
    regioncode: 4,
    year: 2020,
    count: 25734,
    len_sec: 10045.091000000344,
    lanemiles: 28828.739000000714,
    delay: 2.82010201e8,
    trkdelay: 1.3510073e7,
    pti80: 1.46,
    trkpti80: 1.42,
    pti95: 1.57,
    trkpti95: 1.53,
    tti: 1.25,
    trktti: 1.21,
    ghg: 1135830.0,
    congcost: 6.140745646e9,
    allspeed: 44.07,
    trkspeed: 50.23,
    ttivmt: 6.5862463e7,
    trkttivmt: 4248429.0,
  },
  {
    agglevel: "region",
    regioncode: 4,
    year: 2021,
    count: 26749,
    len_sec: 10087.239000000414,
    lanemiles: 29140.98500000117,
    delay: 1.36496829e8,
    trkdelay: 8469094.0,
    pti80: 1.25,
    trkpti80: 1.26,
    pti95: 1.31,
    trkpti95: 1.38,
    tti: 1.12,
    trktti: 1.12,
    ghg: 569854.0,
    congcost: 3.11623663e9,
    allspeed: 46.45,
    trkspeed: 52.2,
    ttivmt: 5.9176401e7,
    trkttivmt: 3755850.0,
  },
  {
    agglevel: "region",
    regioncode: 4,
    year: 2022,
    count: 28167,
    len_sec: 10208.424000000452,
    lanemiles: 29788.15300000117,
    delay: 1.93072039e8,
    trkdelay: 1.1268305e7,
    pti80: 1.32,
    trkpti80: 1.31,
    pti95: 1.4,
    trkpti95: 1.43,
    tti: 1.16,
    trktti: 1.14,
    ghg: 770470.0,
    congcost: 4.846118769e9,
    allspeed: 46.35,
    trkspeed: 52.18,
    ttivmt: 6.2170882e7,
    trkttivmt: 4536985.0,
  },
  {
    agglevel: "region",
    regioncode: 5,
    year: 2017,
    count: 24600,
    len_sec: 13596.644000000104,
    lanemiles: 39409.02000000019,
    delay: 2.71028959e8,
    trkdelay: 1.5576976e7,
    pti80: 1.39,
    trkpti80: 1.41,
    pti95: 1.49,
    trkpti95: 1.53,
    tti: 1.19,
    trktti: 1.19,
    ghg: 1088827.0,
    congcost: 5.500591387e9,
    allspeed: 45.34,
    trkspeed: 52.13,
    ttivmt: 8.9430808e7,
    trkttivmt: 7564009.0,
  },
  {
    agglevel: "region",
    regioncode: 5,
    year: 2018,
    count: 25233,
    len_sec: 13810.274000000092,
    lanemiles: 40161.57100000062,
    delay: 2.76885854e8,
    trkdelay: 1.4338511e7,
    pti80: 1.45,
    trkpti80: 1.44,
    pti95: 1.55,
    trkpti95: 1.56,
    tti: 1.23,
    trktti: 1.21,
    ghg: 1122937.0,
    congcost: 5.695357765e9,
    allspeed: 44.54,
    trkspeed: 50.08,
    ttivmt: 7.3235955e7,
    trkttivmt: 5044756.0,
  },
  {
    agglevel: "region",
    regioncode: 5,
    year: 2019,
    count: 17220,
    len_sec: 13627.460000000172,
    lanemiles: 39893.24500000037,
    delay: 2.54906166e8,
    trkdelay: 1.3377949e7,
    pti80: 1.4,
    trkpti80: 1.38,
    pti95: 1.5,
    trkpti95: 1.5,
    tti: 1.21,
    trktti: 1.18,
    ghg: 1009566.0,
    congcost: 5.434212497e9,
    allspeed: 45.33,
    trkspeed: 49.93,
    ttivmt: 7.3776346e7,
    trkttivmt: 4980964.0,
  },
  {
    agglevel: "region",
    regioncode: 5,
    year: 2020,
    count: 37040,
    len_sec: 13701.724000000375,
    lanemiles: 40263.2950000014,
    delay: 2.52556249e8,
    trkdelay: 1.1748155e7,
    pti80: 1.41,
    trkpti80: 1.37,
    pti95: 1.51,
    trkpti95: 1.48,
    tti: 1.21,
    trktti: 1.18,
    ghg: 970939.0,
    congcost: 5.476476777e9,
    allspeed: 45.7,
    trkspeed: 52.2,
    ttivmt: 7.5110586e7,
    trkttivmt: 4929563.0,
  },
  {
    agglevel: "region",
    regioncode: 5,
    year: 2021,
    count: 38967,
    len_sec: 13738.54300000063,
    lanemiles: 40462.49400000146,
    delay: 1.39180222e8,
    trkdelay: 8431827.0,
    pti80: 1.24,
    trkpti80: 1.24,
    pti95: 1.3,
    trkpti95: 1.35,
    tti: 1.11,
    trktti: 1.11,
    ghg: 559701.0,
    congcost: 3.167420375e9,
    allspeed: 47.87,
    trkspeed: 54.14,
    ttivmt: 6.9070726e7,
    trkttivmt: 4785600.0,
  },
  {
    agglevel: "region",
    regioncode: 5,
    year: 2022,
    count: 41235,
    len_sec: 13852.420000000673,
    lanemiles: 41078.016000001946,
    delay: 2.04158907e8,
    trkdelay: 1.1990189e7,
    pti80: 1.29,
    trkpti80: 1.3,
    pti95: 1.37,
    trkpti95: 1.41,
    tti: 1.15,
    trktti: 1.14,
    ghg: 808258.0,
    congcost: 5.124598527e9,
    allspeed: 46.94,
    trkspeed: 53.5,
    ttivmt: 7.5941038e7,
    trkttivmt: 5562695.0,
  },
  {
    agglevel: "region",
    regioncode: 6,
    year: 2017,
    count: 6639,
    len_sec: 3500.2140000000027,
    lanemiles: 8833.106000000025,
    delay: 3.8493021e7,
    trkdelay: 999768.0,
    pti80: 1.19,
    trkpti80: 1.15,
    pti95: 1.23,
    trkpti95: 1.2,
    tti: 1.09,
    trktti: 1.06,
    ghg: 154831.0,
    congcost: 7.45869645e8,
    allspeed: 42.32,
    trkspeed: 53.89,
    ttivmt: 1.3333812e7,
    trkttivmt: 814281.0,
  },
  {
    agglevel: "region",
    regioncode: 6,
    year: 2018,
    count: 5443,
    len_sec: 3540.7899999999936,
    lanemiles: 8929.381000000014,
    delay: 3.2445652e7,
    trkdelay: 1348554.0,
    pti80: 1.21,
    trkpti80: 1.2,
    pti95: 1.26,
    trkpti95: 1.27,
    tti: 1.11,
    trktti: 1.09,
    ghg: 142188.0,
    congcost: 6.59762171e8,
    allspeed: 43.89,
    trkspeed: 50.99,
    ttivmt: 9879591.0,
    trkttivmt: 619862.0,
  },
  {
    agglevel: "region",
    regioncode: 6,
    year: 2019,
    count: 2608,
    len_sec: 2070.219999999997,
    lanemiles: 5938.039999999992,
    delay: 2.8861059e7,
    trkdelay: 1288477.0,
    pti80: 1.2,
    trkpti80: 1.18,
    pti95: 1.24,
    trkpti95: 1.23,
    tti: 1.1,
    trktti: 1.08,
    ghg: 131231.0,
    congcost: 6.12486934e8,
    allspeed: 45.04,
    trkspeed: 52.09,
    ttivmt: 8935144.0,
    trkttivmt: 604974.0,
  },
  {
    agglevel: "region",
    regioncode: 6,
    year: 2020,
    count: 8577,
    len_sec: 3543.921000000015,
    lanemiles: 9054.865000000065,
    delay: 3.3644516e7,
    trkdelay: 1433685.0,
    pti80: 1.2,
    trkpti80: 1.18,
    pti95: 1.25,
    trkpti95: 1.24,
    tti: 1.1,
    trktti: 1.08,
    ghg: 153573.0,
    congcost: 7.30775297e8,
    allspeed: 43.97,
    trkspeed: 52.55,
    ttivmt: 1.0744205e7,
    trkttivmt: 655315.0,
  },
  {
    agglevel: "region",
    regioncode: 6,
    year: 2021,
    count: 9041,
    len_sec: 3552.9810000000484,
    lanemiles: 9091.279000000059,
    delay: 2.08767e7,
    trkdelay: 1086008.0,
    pti80: 1.16,
    trkpti80: 1.16,
    pti95: 1.21,
    trkpti95: 1.24,
    tti: 1.07,
    trktti: 1.06,
    ghg: 97682.0,
    congcost: 4.72775445e8,
    allspeed: 44.62,
    trkspeed: 53.33,
    ttivmt: 9329475.0,
    trkttivmt: 620107.0,
  },
  {
    agglevel: "region",
    regioncode: 6,
    year: 2022,
    count: 9252,
    len_sec: 3553.8530000000383,
    lanemiles: 9139.163000000097,
    delay: 2.8749633e7,
    trkdelay: 1632751.0,
    pti80: 1.18,
    trkpti80: 1.19,
    pti95: 1.25,
    trkpti95: 1.3,
    tti: 1.08,
    trktti: 1.08,
    ghg: 136047.0,
    congcost: 7.26319751e8,
    allspeed: 43.83,
    trkspeed: 52.08,
    ttivmt: 1.0532864e7,
    trkttivmt: 748663.0,
  },
  {
    agglevel: "region",
    regioncode: 7,
    year: 2017,
    count: 2009,
    len_sec: 1072.0119999999984,
    lanemiles: 3506.7139999999927,
    delay: 3.2815589e7,
    trkdelay: 2716071.0,
    pti80: 1.33,
    trkpti80: 1.44,
    pti95: 1.42,
    trkpti95: 1.57,
    tti: 1.16,
    trktti: 1.21,
    ghg: 138637.0,
    congcost: 6.91844687e8,
    allspeed: 46.45,
    trkspeed: 49.93,
    ttivmt: 1.180544e7,
    trkttivmt: 1110816.0,
  },
  {
    agglevel: "region",
    regioncode: 7,
    year: 2018,
    count: 2118,
    len_sec: 1080.0840000000019,
    lanemiles: 3548.5209999999993,
    delay: 3.2820832e7,
    trkdelay: 1754702.0,
    pti80: 1.38,
    trkpti80: 1.41,
    pti95: 1.49,
    trkpti95: 1.55,
    tti: 1.21,
    trktti: 1.21,
    ghg: 133762.0,
    congcost: 6.77022471e8,
    allspeed: 44.77,
    trkspeed: 48.79,
    ttivmt: 8689765.0,
    trkttivmt: 601134.0,
  },
  {
    agglevel: "region",
    regioncode: 7,
    year: 2019,
    count: 1208,
    len_sec: 870.6299999999999,
    lanemiles: 3067.3269999999934,
    delay: 2.8637109e7,
    trkdelay: 1951040.0,
    pti80: 1.33,
    trkpti80: 1.36,
    pti95: 1.41,
    trkpti95: 1.46,
    tti: 1.18,
    trktti: 1.18,
    ghg: 123135.0,
    congcost: 6.27478504e8,
    allspeed: 45.97,
    trkspeed: 48.6,
    ttivmt: 8656648.0,
    trkttivmt: 670841.0,
  },
  {
    agglevel: "region",
    regioncode: 7,
    year: 2020,
    count: 2984,
    len_sec: 1052.9179999999976,
    lanemiles: 3364.700999999984,
    delay: 3.117361e7,
    trkdelay: 1972009.0,
    pti80: 1.37,
    trkpti80: 1.4,
    pti95: 1.45,
    trkpti95: 1.51,
    tti: 1.2,
    trktti: 1.2,
    ghg: 133445.0,
    congcost: 6.9735202e8,
    allspeed: 43.83,
    trkspeed: 47.74,
    ttivmt: 8251803.0,
    trkttivmt: 596212.0,
  },
  {
    agglevel: "region",
    regioncode: 7,
    year: 2021,
    count: 3140,
    len_sec: 1051.7269999999933,
    lanemiles: 3364.075999999989,
    delay: 1.5166662e7,
    trkdelay: 1176789.0,
    pti80: 1.21,
    trkpti80: 1.23,
    pti95: 1.26,
    trkpti95: 1.35,
    tti: 1.1,
    trktti: 1.11,
    ghg: 66914.0,
    congcost: 3.53795833e8,
    allspeed: 45.57,
    trkspeed: 50.2,
    ttivmt: 7333092.0,
    trkttivmt: 537381.0,
  },
  {
    agglevel: "region",
    regioncode: 7,
    year: 2022,
    count: 3339,
    len_sec: 1057.5139999999956,
    lanemiles: 3383.3419999999987,
    delay: 2.0773478e7,
    trkdelay: 1391487.0,
    pti80: 1.27,
    trkpti80: 1.27,
    pti95: 1.35,
    trkpti95: 1.4,
    tti: 1.14,
    trktti: 1.13,
    ghg: 83986.0,
    congcost: 5.27693745e8,
    allspeed: 45.98,
    trkspeed: 51.23,
    ttivmt: 7612071.0,
    trkttivmt: 647905.0,
  },
  {
    agglevel: "state",
    regioncode: 1,
    year: 2017,
    count: 157369,
    len_sec: 105300.60499999422,
    lanemiles: 259902.38699998514,
    delay: 9.61624739e8,
    trkdelay: 5.6769906e7,
    pti80: 1.31,
    trkpti80: 1.26,
    pti95: 1.4,
    trkpti95: 1.35,
    tti: 1.15,
    trktti: 1.11,
    ghg: 3969840.0,
    congcost: 1.9582175966e10,
    allspeed: 46.57,
    trkspeed: 56.01,
    ttivmt: 3.4382804e8,
    trkttivmt: 4.0105255e7,
  },
  {
    agglevel: "state",
    regioncode: 1,
    year: 2018,
    count: 161175,
    len_sec: 105972.75099999727,
    lanemiles: 262318.14799999015,
    delay: 9.4906767e8,
    trkdelay: 5.0990568e7,
    pti80: 1.36,
    trkpti80: 1.28,
    pti95: 1.45,
    trkpti95: 1.37,
    tti: 1.19,
    trktti: 1.13,
    ghg: 3913532.0,
    congcost: 1.9591693954e10,
    allspeed: 47.33,
    trkspeed: 54.25,
    ttivmt: 2.83322876e8,
    trkttivmt: 2.5674406e7,
  },
  {
    agglevel: "state",
    regioncode: 1,
    year: 2019,
    count: 81540,
    len_sec: 89627.51299999926,
    lanemiles: 229127.90299999376,
    delay: 8.64793515e8,
    trkdelay: 5.2427268e7,
    pti80: 1.33,
    trkpti80: 1.25,
    pti95: 1.41,
    trkpti95: 1.33,
    tti: 1.16,
    trktti: 1.11,
    ghg: 3638489.0,
    congcost: 1.8717549681e10,
    allspeed: 48.44,
    trkspeed: 53.93,
    ttivmt: 2.81997946e8,
    trkttivmt: 2.6942254e7,
  },
  {
    agglevel: "state",
    regioncode: 1,
    year: 2020,
    count: 241123,
    len_sec: 106112.24999999288,
    lanemiles: 264032.758999977,
    delay: 9.1041504e8,
    trkdelay: 4.9359776e7,
    pti80: 1.33,
    trkpti80: 1.24,
    pti95: 1.41,
    trkpti95: 1.32,
    tti: 1.16,
    trktti: 1.11,
    ghg: 3750696.0,
    congcost: 2.0045341018e10,
    allspeed: 48.24,
    trkspeed: 55.81,
    ttivmt: 3.00208324e8,
    trkttivmt: 2.7659293e7,
  },
  {
    agglevel: "state",
    regioncode: 1,
    year: 2021,
    count: 245041,
    len_sec: 106197.64099999868,
    lanemiles: 264796.82899994875,
    delay: 4.79671659e8,
    trkdelay: 3.5105976e7,
    pti80: 1.19,
    trkpti80: 1.17,
    pti95: 1.25,
    trkpti95: 1.26,
    tti: 1.09,
    trktti: 1.07,
    ghg: 2079209.0,
    congcost: 1.1122338563e10,
    allspeed: 49.85,
    trkspeed: 56.95,
    ttivmt: 2.69530912e8,
    trkttivmt: 2.6370751e7,
  },
  {
    agglevel: "state",
    regioncode: 1,
    year: 2022,
    count: 253675,
    len_sec: 106608.76399999135,
    lanemiles: 266830.6109999676,
    delay: 6.94900813e8,
    trkdelay: 4.5994377e7,
    pti80: 1.24,
    trkpti80: 1.19,
    pti95: 1.31,
    trkpti95: 1.28,
    tti: 1.11,
    trktti: 1.08,
    ghg: 2863714.0,
    congcost: 1.7648637051e10,
    allspeed: 49.29,
    trkspeed: 56.45,
    ttivmt: 2.94701563e8,
    trkttivmt: 2.9782809e7,
  },
];
