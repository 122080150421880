import React, { useState, useRef } from "react";
import {
    Map,
    TileLayer,
    GeoJSON,
    LayersControl
} from "react-leaflet";

import {
    MAPBOX_ACCESSTOKEN,
    MAPBOX_ATTRIBUTION,
    MAPBOX_URL,
    //styles,
} from "../variables/constants";

import bbox from '@turf/bbox';

//import formatPopup_County from "./formatPopUp_County"
//import formatPopUp_District from "./formatPopUp_District"
//import formatPopUp_TX100 from "./formatPopUp_TX100"

import districtGeom from '../data/districtGeom.json';

export const styles = {
    container: {
        minHeight: "100vh",
        //minHeight: "calc(100vh - 50px)",
        //        backgroundColor: "#808080",
        padding: "10px",
        color: "black",
    },
    sectionHeader: {
        //color: '#fff',
        fontSize: "40px",
        marginBottom: "20px",
    },
    selectionSection: {
        color: "#fff",
        fontSize: "calc(10px + 2vmin)",
    },
    map_div: {
        position: "relative",
        display: "inline-block",
        width: "45%",
        color: "#590d0d",

    },
    data_div: {
        position: "relative",
        display: "inline-block",
        width: "55%",
        verticalAlign: "top",
    },
    layersControl: {
        padding: '5px',
        fontSize: "calc(5px + 1vmin)",
        height: "300px"
    },
    table_div: {
        position: "relative",
        display: "inline-block",
        width: "55%",
        verticalAlign: "top",
    }

};

function MapTemp(props) {

    //const [activeGeom, setactiveGeom] = useState(districtGeom.filter((a) => a.properties.dist_nbr === props.regionCode & a.properties.year === 2019))

    /*
    const areaScale = props.areaScale;

    const onEachFeature = (feature, layer) => {
        layer.on({
            mouseover: handleMouseOver.bind(null, feature, layer),
            //mouseout: handleMouseOut.bind(null, feature),
            click: handleClick.bind(null, feature, layer)
        });
    };

    const handleMouseOver = (feature, layer, e) => {
        const props = feature.properties;
        var text = null;
        if (areaScale === 'County') { text = formatPopup_County(props); }
        if (areaScale === 'District') { text = formatPopUp_District(props); }
        if (areaScale === "Texas 100") { text = formatPopUp_TX100(props); }
        layer.bindTooltip(text).openTooltip();
    };

    const handleClick = (feature, layer, e) => {
        const props = feature.properties;
        var text = null;
        if (areaScale === 'County') { text = formatPopup_County(props); }
        if (areaScale === 'District') { text = formatPopUp_District(props); }
        if (areaScale === "Texas 100") { text = formatPopUp_TX100(props); }
        layer.bindPopup(text).openPopup();
    };

    */

    console.log("props.activeGeom");
    console.log(props.activeGeom);

    const mapBounds_bbox_initial = (
        bbox(
            { type: "FeatureCollection", features: props.activeGeom }
        )
    );

    const [mapBounds, setmapBounds] = useState(
        [[mapBounds_bbox_initial[1] + props.bboxBuffer, mapBounds_bbox_initial[0] - props.bboxBuffer],
        [mapBounds_bbox_initial[3] - props.bboxBuffer, mapBounds_bbox_initial[2] + props.bboxBuffer]]
    );

    const fitToCustomLayer = () => {
        if (mapRef.current && customAreaRef.current) { //we will get inside just once when loading
            const map = mapRef.current.leafletElement
            const layer = customAreaRef.current.leafletElement
            map.fitBounds(layer.getBounds().pad(0.5))
        }
    };
    const mapRef = useRef(null);
    const customAreaRef = useRef(null);
    //<Map center={[31.243333, -99.458333]} zoom={5} style={styles.layersControl} bounds={props.mapBounds}></Map>

    return (

        <Map id="map" ref={mapRef}
            //zoom={10} 
            style={styles.layersControl}
            bounds={mapBounds}
            //center={[31.243333, -99.458333]} zoom={5}
            zoomControl={false}
            doubleClickZoom={false}
            closePopupOnClick={false}
            dragging={true}
            zoomSnap={true}
            zoomDelta={true}
            trackResize={true}
            touchZoom={false}
            scrollWheelZoom={false}
        //onlayeradd={fitToCustomLayer}



        >   {/* <Map zoom={props.initialZoom} style={props.layerControlStyle} bounds={props.mapBounds}> */}

            <GeoJSON
                data={props.activeGeom} //data={props.stateGeom}
                ref={customAreaRef}
                style={() => ({
                    color: props.mapColor, // color: 'grey',
                    weight: props.lineWeight, // weight: 0.5,
                    //fillColor: "#1a1d62",
                    fillOpacity: 0.25,//fillOpacity: props.stateOpacity,
                })}
            //onEachFeature={onEachFeature}

            >

                {/* 
                <TileLayer
                    //url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
                    //url={'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'}
                    url={'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'}
                    attribution={'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'}

                //url={MAPBOX_URL}
                //attribution={MAPBOX_ATTRIBUTION}
                //id="mapbox.light"
                //id="mapbox.streets"
                //access_token={MAPBOX_ACCESSTOKEN}
                />
*/}

                <TileLayer
                    url={"https://api.tiles.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}?access_token={access_token}"}
                    attribution={'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'}
                    id="mapbox.streets"
                    access_token={"pk.eyJ1IjoiamlzdW5nIiwiYSI6ImNqaXZ1YW9pNDJ4Nzkza3A4cGRtczhkNnUifQ.l8GpDnBNJtRoFMcId2hpSw"}
                />


            </GeoJSON>

        </Map>
    )
};

export default MapTemp;
