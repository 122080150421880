import React, { useState } from "react";
import fetchDataValue from "utils/fetchDataValue.js"

export default function fetchTruckDataYrDiff(data, areaCode, currYear) {
    //function fetchTruckDataYrDiff(data, areaCode) {
    // console.log("data");
    // console.log(data);

    //let areaData = data.filter((a) => a.di === areaCode);
    // console.log("areaData");
    // console.log(areaData);

    let currValue = fetchDataValue(data, currYear, 'trkdelay')
    let priorValue = fetchDataValue(data, (currYear - 1), 'trkdelay')
    let diffValue = (currValue - priorValue)
    let pcDiffValue = ((currValue - priorValue) / priorValue * 100).toFixed(1);
    let tempDataArray = [{
        "metric": "Annual Person-Hours of Delay",
        "currValue": currValue.toLocaleString(),
        "priorValue": priorValue.toLocaleString(),
        "diff": diffValue.toLocaleString(),
        "pcDiffValue": pcDiffValue,
    }]

    currValue = fetchDataValue(data, currYear, 'trktti').toFixed(2)
    priorValue = fetchDataValue(data, (currYear - 1), 'trktti').toFixed(2)
    diffValue = (currValue - priorValue).toFixed(2)
    pcDiffValue = ((currValue - priorValue) / priorValue * 100).toFixed(1);
    tempDataArray = [...tempDataArray, {
        "metric": "TTI",
        "currValue": currValue.toLocaleString(),
        "priorValue": priorValue.toLocaleString(),
        "diff": diffValue.toLocaleString(),
        "pcDiffValue": pcDiffValue,
    }]

    currValue = fetchDataValue(data, currYear, 'trkpti95').toFixed(2)
    priorValue = fetchDataValue(data, (currYear - 1), 'trkpti95').toFixed(2)
    diffValue = (currValue - priorValue).toFixed(2)
    pcDiffValue = ((currValue - priorValue) / priorValue * 100).toFixed(1);
    tempDataArray = [...tempDataArray, {
        "metric": "PTI - 95th Percentile",
        "currValue": currValue.toLocaleString(),
        "priorValue": priorValue.toLocaleString(),
        "diff": diffValue.toLocaleString(),
        "pcDiffValue": pcDiffValue,
    }]

    currValue = fetchDataValue(data, currYear, 'trkpti80').toFixed(2)
    priorValue = fetchDataValue(data, (currYear - 1), 'trkpti80').toFixed(2)
    diffValue = (currValue - priorValue).toFixed(2)
    pcDiffValue = ((currValue - priorValue) / priorValue * 100).toFixed(1);
    tempDataArray = [...tempDataArray, {
        "metric": "PTI - 80th Percentile",
        "currValue": currValue.toLocaleString(),
        "priorValue": priorValue.toLocaleString(),
        "diff": diffValue.toLocaleString(),
        "pcDiffValue": pcDiffValue,
    }]

    currValue = fetchDataValue(data, currYear, 'trkspeed').toFixed(2)
    priorValue = fetchDataValue(data, (currYear - 1), 'trkspeed').toFixed(2)
    diffValue = (currValue - priorValue).toFixed(2)
    pcDiffValue = ((currValue - priorValue) / priorValue * 100).toFixed(1);
    tempDataArray = [...tempDataArray, {
        "metric": "Speed (mph)",
        "currValue": currValue.toLocaleString(),
        "priorValue": priorValue.toLocaleString(),
        "diff": diffValue.toLocaleString(),
        "pcDiffValue": pcDiffValue,
    }]

    //NOTE: USING 'lanemiles' FOR NOW; WILL NEED TO UPDATE TO 'trkttivmt'
    currValue = fetchDataValue(data, currYear, 'trkttivmt')
    priorValue = fetchDataValue(data, (currYear - 1), 'trkttivmt')
    diffValue = (currValue - priorValue)
    pcDiffValue = ((currValue - priorValue) / priorValue * 100).toFixed(1);
    tempDataArray = [...tempDataArray, {
        "metric": "VMT",
        "currValue": currValue.toLocaleString(),
        "priorValue": priorValue.toLocaleString(),
        "diff": diffValue.toLocaleString(),
        "pcDiffValue": pcDiffValue,
    }]

    return (tempDataArray)

};