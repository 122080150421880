import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import fetchDataValue from "utils/fetchDataValue.js"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles(styles);

export default function SetPcDiffYrTextSpeed(props) {

    const classes = useStyles();

    const setText = (yrDiff, yrDiffPC) => {
        return (yrDiff + " (" + yrDiffPC + "%)")
    };

    //function SetPcDiffYrText(props) {               //const SetPcDiffYrText = (yrDiffPC) => {
    // console.log("props.yrDiffPC");
    // console.log(props.yrDiffPC);

    // console.log("truckDataYrDiff");
    // console.log(truckDataYrDiff);

    if (props.yrDiffPC > 0) {
        return (
            <span className={classes.dangerText}>
                <ArrowDownward className={classes.upArrowCardCategory} /> {setText(props.yrDiff, props.yrDiffPC)}
            </span>
        )
    } else {
        return (
            <span className={classes.successText} color="warning">
                <ArrowUpward className={classes.upArrowCardCategory} /> {setText(props.yrDiff, props.yrDiffPC)}
            </span>
        )
    }
};
