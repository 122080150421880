import React, { useState, useEffect } from "react";
import { PDFDownloadLink, Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer'

//import domtoimage from 'dom-to-image';

//import html2canvas from 'html2canvas';
//import html2canvaspng from './html2canvaspng'

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff',
        width: '100%',
        padding: 0,
        margin: 3,
        orientation: 'landscape',
    },
    view: {
        //width: '100%',
        //height: '100%',
        width: '95%',
        height: '95%',
        padding: 0,
        marginLeft: 20,
        marginTop: 5,
        backgroundColor: 'white',
        objectPosition: 'right top',
    },
    image: {
        //objectFit: 'cover',
        objectFit: 'fill',
        //objectPosition: '50% 50% 50% 50%',
        //objectPosition: 'right top',
    },
    objectPosition: {
        objectPosition: '10px'
    },
    rowContainer1: {
        height: "325",
        flexDirection: 'row',
        //margin: 3,
        marginLeft: 15,
        marginRight: 15,

    },
    tableContainer1: {
        width: "400",
        height: "225",
        //paddingLeft: 10,
        marginLeft: 10,
        marginTop: 10,
    },
    tableContainer2: {
        width: "400",
        height: "225",
        //paddingLeft: 10,
        //paddingRight: 10,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10,
        display: "flex",
        flexDirection: "column",

    },
    tableCard1: {
        width: "390",
        //height: "225",
        //paddingLeft: 10,
        marginRight: 10,
        marginTop: 10,
        //borderStyle: "solid", borderWidth: 0.5,
        //border: "25",
        //marginBottom: "30px",
        //marginTop: "30px",
        //borderRadius: "6",
        //color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
        //boxShadow: "4 4 4 4 rgba(" + hexToRgb(blackColor) + ", 0.55)",

    },
    table: {
        display: "table", width: "50%", textAlign: 'justify'
    }, //, borderStyle: "solid", borderWidth: 1, width: "auto", borderRightWidth: 0, borderBottomWidth: 0 },
    tableRow: { margin: "auto", marginLeft: 5, flexDirection: "row", borderStyle: "solid", borderBottomWidth: 0.5 }, //, borderWidth: 1 
    tableCol: { width: "100%" }, //, borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    //tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },
    tableCellLeft: {
        //margin: "auto", 
        //marginTop: 5,
        fontSize: 11,
        //...defaultFont,
        //lineHeight: "1.42857143",
        //padding: "12px 8px",
        //verticalAlign: "middle",
        textAlign: 'justify',
        //fontSize: "0.8125rem"
        //fontSize: "1.15rem",
        //fontWeight: 400
        marginTop: 5,
        marginBottom: 5,
    },
    tableCell: {
        margin: "auto",
        //marginTop: 5, 
        fontSize: 11,
        //...defaultFont,
        //lineHeight: "1.42857143",
        //padding: "12px 8px",
        //verticalAlign: "middle",
        //fontSize: "0.8125rem"
        //fontSize: "1.15rem",
        //fontWeight: 400
        marginTop: 5,
        marginBottom: 5,
    },
    tableCellUp: {
        margin: "auto",
        //marginTop: 5, 
        fontSize: 11,
        //...defaultFont,
        //lineHeight: "1.42857143",
        //padding: "12px 8px",
        //verticalAlign: "middle",
        //fontSize: "0.8125rem"
        //fontSize: "1.15rem",
        //fontWeight: 400
        color: 'red',
        marginTop: 5,
        marginBottom: 5,
    },
    tableCellDown: {
        margin: "auto",
        //marginTop: 5, 
        fontSize: 11,
        //...defaultFont,
        //lineHeight: "1.42857143",
        //padding: "12px 8px",
        //verticalAlign: "middle",
        //fontSize: "0.8125rem"
        //fontSize: "1.15rem",
        //fontWeight: 400
        color: 'green',
        //horizontalAlign: "middle",
        marginTop: 5,
        marginBottom: 5,
    },

});

//<Page size="A4" styles={styles.page} orientation="landscape" ruler='true'>

function TruckCongRepCardPDF(props) {
    //alert("Hello");
    //    const [testGraphic, settestGraphic] = useState();
    //
    //    var node = document.querySelector("#maptest"); //document.querySelector("#graphics1Yr");
    //    //console.log("node");
    //    //console.log(node);
    //    //if (props.map){
    //        domtoimage.toPng(node)
    //            .then(function (dataUrl) {
    //                //var img = new Image();
    //                //img.src = dataUrl;
    //                //console.log('Image TEST');
    //                //console.log(dataUrl);
    //                //document.body.appendChild(img);
    //                settestGraphic(dataUrl);
    //                //console.log("testGraphic");
    //                //console.log(testGraphic);
    //            })
    //            .catch(function (error) {
    //                console.error('oops, something went wrong!', error);
    //            });

    //}

    //    const mapToPdf = () => {
    //        html2canvas(document.getElementById("Map")).then(canvas => {
    //           //var data = canvas.toDataURL();
    //            //var pdfExportSetting = {
    //            //    content: [
    //            //        {
    //            //            image: data,
    //            //            width: 500
    //            //        }
    //            //    ]
    //            //};
    //            //pdfMake.createPdf(pdfExportSetting).download("test_file.pdf");
    //            const imgData = canvas.toDataURL('image/png');
    //            return imgData;
    //        });
    //    };

    //    const graphics1YrToPdf = () => {
    //        html2canvas(document.getElementById("graphics1Yr")).then(canvas => {
    //            //var data = canvas.toDataURL();
    //            //var pdfExportSetting = {
    //            //    content: [
    //            //        {
    //            //            image: data,
    //            //            width: 500
    //            //        }
    //            //    ]
    //            //};
    //            //pdfMake.createPdf(pdfExportSetting).download("test_file.pdf");
    //            var imgData = canvas.toDataURL('image/png');
    //            //console.log("imgData");
    //            //console.log(imgData);
    //            return imgData;
    //        });
    //        //const imgData = canvas.toDataURL('image/png');
    //     };

    //console.log("html2canvaspng");
    //    const html2canvaspngTEST = html2canvaspng('graphics1Yr');
    //console.log(html2canvaspngTEST);
    //console.log("html2canvaspng.value");
    //console.log(html2canvaspng('graphics1Yr').value);

    //    const [mapPNG, setmapPNG] = useState(
    //        html2canvas(document.getElementById("graphics1Yr"))
    //            .then(canvas => {
    //                //var data = canvas.toDataURL();
    //                //var pdfExportSetting = {
    //                //    content: [
    //                //        {
    //                //            image: data,
    //                //            width: 500
    //                //        }
    //                //    ]
    //                //};
    //                //pdfMake.createPdf(pdfExportSetting).download("test_file.pdf");
    //                var imgData = canvas.toDataURL('image/png');
    //
    //                console.log("imgData.state");
    //                console.log(imgData);
    //                //window.open(imgData);
    //                return imgData;
    //            })
    //    );

    //    const pngTest = (
    //    html2canvas(document.querySelector("#capture")).then(canvas => {
    //        document.body.appendChild(canvas)
    //        //console.log("canvas.test");
    //        //console.log(canvas);
    //        var imgData = canvas.toDataURL('image/png');
    //        //console.log("imgData.state2");
    //        //console.log(imgData);
    //        return imgData;
    //
    //    })
    //    );

    //    <View style={styles.mapContainer}>
    //        <Text >1 yr diff</Text>
    //        <Image src={pngTest} />
    //    </View>

    //    <Image style={styles.mapContainer} src={testGraphic} />
    //<Image style={styles.mapContainer} src={`data:${props.mapGraphic}`} />
    //console.log("pdf trendPlot1");
    //console.log(props.trendPlot1);


    //    return (
    //        <Document>
    //
    //            <Page size="A4" styles={styles.page} orientation="landscape">
    //                <View style={styles.titleContainer}>
    //                    <Text style={styles.reportTitle}>{props.districtName + " 2019 Truck Congestion Report"}</Text>
    //                </View>
    //
    //                <View style={styles.rowContainer1}>
    //                    <View style={styles.mapContainer}>
    //                        
    //                        <Image src={`data:${props.mapGraphic}`} />
    //
    //                    </View>
    //                    <View style={styles.mapContainer}>
    //
    //                        <Text >Year/1 Year Trends</Text>
    //                        <Image style={styles.tableContainer} src={`data:${props.graphics1Yr}`} />
    //
    //                    </View>
    //                </View>
    //  
    //                <View style={styles.rowContainer}>
    //                    <Image style={styles.rowContainer2} src={`data:${props.trendPlot1}`} />
    //
    //                </View>
    //
    //                <View style={styles.rowContainer}>
    //                    <Image style={styles.rowContainer2} src={`data:${props.trendPlot2}`} />
    //
    //                </View>
    //
    //                <View style={styles.logo1Container}>
    //                    <Image style={styles.logo1} src={TxDOTlogo} />
    //                </View>
    //
    //            </Page>
    //        </Document>
    //    )

    //LETTER

    function SetTableDiffYrText(props) {

        if (props.trend === 'up') {
            return (
                < Text style={styles.tableCellUp} >
                    {props.yrDiff}
                </Text >
            )
        } else {
            return (
                < Text style={styles.tableCellDown} >
                    {props.yrDiff}
                </Text >
            )
        }

    };

    return (

        <Document >
            <Page object-fit="fill" style={styles.page} size="A4" orientation="landscape">
                <View style={styles.view}>
                    <Image id="objectPosition" style={styles.image} src={`data:${props.docImage}`} alt="images" />
                </View>
            </Page>

        </Document>

    )
};



export default TruckCongRepCardPDF;
